/**
 * This file is used for dependency injections
 */
import { ServiceStore } from '@src/types/redux';
import { Services as ServiceType, ServiceName, ServiceCast } from '../types/Services';
import { bigStore } from '../store';
import { GlobalErrorService } from './GlobalError';
import { api } from './api';
import StartService from './Start';
import { LoginService } from './Login';
import { BrowsingService } from './Browsing';
import { VideoMakerService } from './VideoMaker';
import { MediaService } from './Media';
import { PopupsService } from './Popups';
import { ProductService } from './Product';

class ServicesClass implements ServiceType {
  private services: { [key in ServiceName]: () => ServiceCast };

  private instances: { [key in ServiceName]?: ServiceCast } = {};

  public constructor() {
    this.services = {
      [ServiceName.Api]: () => api,
      [ServiceName.Browsing]: () => new BrowsingService(),
      [ServiceName.GlobalError]: () => new GlobalErrorService(),

      [ServiceName.Login]: () => new LoginService(this.selfReference()),
      [ServiceName.Start]: () => new StartService(this.selfReference()),
      [ServiceName.VideoMaker]: () => new VideoMakerService(this, bigStore),
      [ServiceName.MediaService]: () => new MediaService(this.selfReference(), bigStore),
      [ServiceName.Popups]: () => new PopupsService(this.selfReference(), bigStore),
      [ServiceName.Product]: () => new ProductService(this.selfReference(), bigStore),
    };
  }

  public get<T extends ServiceCast>(name: ServiceName): T {
    if (this.instances[name]) {
      return this.instances[name] as T;
    }
    return this.instances[name] = this.services[name]() as T;
  }

  public getStore(): ServiceStore {
    return bigStore;
  }

  private selfReference(): Promise<ServiceType> {
    // eslint-disable-next-line @typescript-eslint/no-this-alias
    const self = this;
    return new Promise((resolve) => setTimeout(() => {
      resolve(self);
    }, 0));
  }
}

const Services = new ServicesClass();

export { Services };
