import { QUERY_KEYS } from '@src/query';
import { MediaView } from 'common';
import { UseQueryResult, useQuery } from 'react-query';
import { searchMedias } from './api';
import { MediaConstraints, MediaFilterBuilder } from '@src/fragments/Filters/utils/MediaFilterBuilder';

export const useQueryMediaFindAll = (
    mc: MediaConstraints,
    limit: number = 10000
  ): UseQueryResult<MediaView[]> => {
    const query = MediaFilterBuilder.buildMediaFilter(mc);
    query.rawOnly = true;
  
    return useQuery({
      queryKey: QUERY_KEYS.mediaFindParams(query),
      queryFn: async () => {
        let allMedia: MediaView[] = [];
        let cursor: string | undefined = undefined;
        let resultsLength = 0;
  
        do {
          const res = await searchMedias({
            ...query,
            cursor,
            limit: 1000,
          });
          allMedia = [...allMedia, ...res.media];
          cursor = res.cursor;
          resultsLength += res.media.length;
        } while (cursor && resultsLength < limit);
  
        return allMedia;
      },
      cacheTime: 0,
      staleTime: 0,
      enabled: false,
    });
  };
  