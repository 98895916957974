import React, { Component } from 'react';
import { connect } from 'react-redux';

import 'react-perfect-scrollbar/dist/css/styles.css';

import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import { VIDEO_ACTIONS } from 'actions/video';
import Tooltip from '@mui/material/Tooltip';
import LocalizedStrings from 'react-localization';
import { WarningIcon } from '@src/components/WarningIcon';
import { style } from 'typestyle';
import { ICombinedReducers } from '@src/types/redux';
import { IVideoMakerService } from '@src/types/VideoMaker';
import { VideoFacebookPublishing } from './components/VideoFacebookPublishing';
import { VideoSettings } from './components/VideoSettings';
import { CanvasResizer } from './components/Resizer/Resizer';
import { DndList } from './components/DndList';
import styles from './styles.scss';

const tabs: Props['page'][] = ['create', 'preview', 'publish'];

const mapStateToProps = (state: ICombinedReducers) => ({
  page: state.video.page,
  gif: state.video.gif,
  resolvedImages: state.video.resolvedImages,
  current_index: state.video.current_index,
});

const dispatchToProps = {
  selectImage: VIDEO_ACTIONS.selectImage,
  widthUpdate: VIDEO_ACTIONS.widthUpdate,
};

interface OwnProps {
  videoMakerService: IVideoMakerService
}

type Props = OwnProps & Readonly<typeof dispatchToProps> & Readonly<ReturnType<typeof mapStateToProps>>;

interface IState {
  width: number
  height: number
  idx: number
  facebookAdsAccount: string[]
}

class RightContainer extends Component<Props, IState> {
  public canvasHolder?: HTMLDivElement;

  public constructor(props: Props) {
    super(props);
    this.state = {
      width: 0,
      height: 0,
      idx: 0,
      facebookAdsAccount: [],
    };
  }

  public render() {
    const unavailableMedia = this.props.gif.elements.find((element) => !this.props.resolvedImages[String(element.image_url)] && !element.brandcontent);

    if (this.state.width === 0) { this.updateDimensions(); }
    return (
      <div className={styles.display}>
        <VideoSettings
          duration={this.props.gif.duration}
          ratio={this.props.gif.ratio}
          handlers={this.props.videoMakerService}
        >
          <Tabs value={this.props.page} style={{ height: 'fit-content' }}>
            {tabs.map((tab) => (tab === 'publish' && unavailableMedia ? (
              <Tooltip title={lang.unavailableMedia}>
                <div className={publishTabClass}>
                  {this.renderTab(tab, true)}
                  <WarningIcon className={warningIconClass} fontSize="small" />
                </div>
              </Tooltip>
            ) : (
              this.renderTab(tab)
            )))}
          </Tabs>
        </VideoSettings>
        {
                    this.props.page !== 'publish'
                      ? (
                        <div
                          className={canvasContainerClass}
                          ref={(node) => this.canvasHolder = node || undefined}
                        >
                          <CanvasResizer
                            width={this.canvasHolder ? this.canvasHolder.clientWidth : this.state.width}
                            height={this.canvasHolder ? this.canvasHolder.clientHeight : this.state.height}
                            isResize={this.props.page === 'create'}
                            current_index={this.props.current_index}
                            gif={this.props.gif}
                            selectImage={this.props.selectImage}
                            widthUpdate={this.props.widthUpdate}
                            videoMakerService={this.props.videoMakerService}
                            resolvedImages={this.props.resolvedImages}
                          />
                        </div>
                      )
                      : (
                        <VideoFacebookPublishing
                          gif={this.props.gif}
                          onProcess={(vm) => this.props.videoMakerService.processVideoMaker(vm)}
                        />
                      )
                }
        {this.props.page !== 'publish' && (
        <div className={dndListClass}>
          <DndList
            resolvedImages={this.props.resolvedImages}
            current_index={this.props.current_index}
            gif_elements={this.props.gif.elements}
            selectImage={this.props.selectImage}
            handlers={this.props.videoMakerService}
          />
        </div>
        )}
      </div>
    );
  }

  public componentDidMount() {
    this.updateDimensions = this.updateDimensions.bind(this);
    window.addEventListener('resize', this.updateDimensions);
  }

  public componentWillUnmount() {
    window.removeEventListener('resize', this.updateDimensions);
  }

  public updateDimensions() {
    try {
      this.setState({
        width: this.canvasHolder ? this.canvasHolder.clientWidth : 500,
        height: this.canvasHolder ? this.canvasHolder.clientHeight : 340,
      });
    } catch (_) {

    }
  }

  public handleTabSelection(page: Props['page']) {
    this.props.videoMakerService.changePage(page);
  }

  private renderTab(tab: Props['page'], disabled?: boolean): JSX.Element {
    return (
      <Tab
        key={tab}
        label={tab}
        value={tab}
        style={{ fontSize: 14, fontWeight: 'bold' }}
        onClick={() => this.handleTabSelection(tab)}
        disabled={disabled}
      />
    );
  }
}

const lang = new LocalizedStrings({
  en: {
    unavailableMedia: 'There is an unavailable content in the timeline',
  },
});

const canvasContainerClass = style({
  display: 'flex',
  flex: 1,
  flexDirection: 'column',
  textAlign: 'center',
  maxWidth: '100%',
  overflow: 'hidden',
});

const publishTabClass = style({
  position: 'relative',
});

const warningIconClass = style({
  position: 'absolute',
  right: 19,
  top: 13,
  color: '#8b8274',
});

const dndListClass = style({
  flexShrink: 0,
  overflow: 'hidden',
});

export default connect(mapStateToProps, dispatchToProps)(RightContainer);
