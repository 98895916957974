import React from 'react';
import { SearchSuggestion, SourceSearchSuggestion } from '@src/types/Filters';
import { style } from 'typestyle';
import { Suggestions } from '../Suggestions';
import { SourceItem } from '../FilterItem/SourceItem';
import { SetterOrUpdater, useRecoilState, useRecoilValue } from 'recoil';
import { FacetState, FilterState } from "@src/pages/Discover/components/Atoms/Atoms";
import {SourceUrlKey, SourceUtility} from '@src/fragments/Filters/utils/SourceUtility';
import { CommonState } from '@src/types/redux/common';
import { onFilterChange } from '@src/fragments/Filters/utils/FilterStateUtility';
import { SourceNetwork } from '@src/types/SourceNetwork';
import { DiscoverQs } from '@src/types/Discover';
import { sourceInput } from '@src/pages/Discover/components/Selectors/Selectors';
import { XclusionUtility } from '@src/fragments/Filters/managers/XclusionUtility';

export const getSuggestions = (sourceState: Pick<CommonState, 'user' | 'groupMentionPages'>, input: string): SearchSuggestion<SourceSearchSuggestion>[] => {
  const sources = SourceUtility.getAllDetailedSourcesFromState(sourceState);
  const sourcesWithSearchApplied = sources.filter((s) => s.value.indexOf(input) > -1);
  return sourcesWithSearchApplied.map(
      (source) => ({
        key: SourceUtility.sourceToUrlKey(source),
        display: source.value,
        network: source.network,
        type: source.type,
      }),
  );
}

export const getSelectedSuggestion = (sourceFilter: string[]): SearchSuggestion<SourceSearchSuggestion>[] => {
  const suggestions = sourceFilter.map((val) => ({ key: val, display: val }))
  return suggestions.map((suggestion) => {
    const key = XclusionUtility.getRawKey(suggestion.key);
    const source = SourceUtility.urlKeyToSource(key);
    if (!source) {
      return null;
    }
    return {
      ...suggestion,
      key: key,
      display: source?.value,
      network: source?.network,
      type: source.type,
    };
  }).filter((s): s is SearchSuggestion<SourceSearchSuggestion> => s !== null)
}

export function selectNetwork(
    network: SourceNetwork,
    sourceState: Pick<CommonState, 'user' | 'groupMentionPages'>,
    sourceFilter: string[],
    setFilters: SetterOrUpdater<DiscoverQs>
    ): void {
  const sources = SourceUtility.getAllDetailedSourcesFromState(sourceState);
  const allSelectedSource = sourceFilter || [];
  const alreadySelected = allSelectedSource.filter((s) => SourceUtility.urlKeyToSource(s)?.network === network);
  let finalSources: SourceUrlKey[];

  if (alreadySelected.length) {
    finalSources = allSelectedSource.filter((s) => !alreadySelected.includes(s));
  } else {
    const newSelect = sources.filter((s) => s.network === network).map((s) => SourceUtility.sourceToUrlKey(s));
    finalSources = [...allSelectedSource, ...newSelect];
  }
  setFilters((filters) => ({
    ...filters,
    source: finalSources
  }))
}

export function SourcesSuggestion() {
  const [filters, setFilters] = useRecoilState(FilterState)
  const [facetState, ] = useRecoilState(FacetState)
  const filterSourceInput = useRecoilValue(sourceInput)
  const sourceState = facetState.mediaConstraints.state

  const values = getSuggestions(sourceState, filterSourceInput).filter((s) => s.type !== 'ownContent');
  const selectedValues = getSelectedSuggestion(filters.source)

  return (
    <Suggestions
      onChange={onFilterChange(setFilters, 'source')}
      selectedValues={renderValues(selectedValues)}
      values={renderValues(values)}
    />
  );
}

function renderValues(suggestions: SearchSuggestion<SourceSearchSuggestion>[]) {
  return suggestions.map((suggestion) => ({
    key: suggestion.key,
    children: (
      <SourceItem
        suggestion={suggestion}
        classNames={[sourceItemClass]}
        style={{
          typeNameSpacing: 5,
        }}
      />
    ),
  }));
}

const sourceItemClass = style({
  marginRight: 15,
});
