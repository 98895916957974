import { EditedWidget } from '@src/pages/Widget/EditPage';
import { WidgetView } from 'common';

export type ChangeSettingsFunction = (newSettings: Partial<WidgetView['settings']>) => void;

export const createChangeSettings = (
  editedWidget: EditedWidget,
  setEditedWidget: (widget: EditedWidget) => void,
): ChangeSettingsFunction => {
  return (newSettings) => {
    const mergedSettings = {
      ...editedWidget.settings,
      ...newSettings,
    };
    setEditedWidget({
      ...editedWidget,
      settings: mergedSettings,
    });
  };
};
