/*
**	Component presenting the list of collections
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';

import Button from '@mui/material/Button';
import { ServiceName, Services } from '@src/types/Services';
import { Pathname, ArgsName, Browsing } from '@src/types/Browsing';
import { IVideoMakerService } from '@src/types/VideoMaker';
import { ICombinedReducers } from '@src/types/redux';
import { PageTitle } from '@src/components/PageTitle';
import { PageHeader } from '@src/components/PageHeader';
import LocalizedStrings from 'react-localization';
import { style } from 'typestyle';
import AuthorizedPage from '@src/components/AuthorizedPage';
import { VideoMakerList } from './VideoMakerList';
import NavBar from '../../components/NavBar';

const mapStateToProps = (state: ICombinedReducers) => ({
  gif: state.video.gif,
  page: state.video.page,
  windowHeight: state.common.windowSize.height,
  user: state.common.user,
  list: state.video.list,
  loading: state.video.loading,
});

interface RouterProps {
  match: {
    params: {
      id?: string
    }
  }
}

interface OwnProps {
  services: Services
}

type Props = OwnProps & Readonly<ReturnType<typeof mapStateToProps>> & RouterProps;

class VideoMakersPage extends Component<Props> {
  public needsLoading = true;

  private videoMakerService: IVideoMakerService;

  private browsingService: Browsing;

  public constructor(props: Props) {
    super(props);
    this.videoMakerService = props.services.get<IVideoMakerService>(ServiceName.VideoMaker);
    this.browsingService = props.services.get<Browsing>(ServiceName.Browsing);
  }

  public render() {
    return (
      <AuthorizedPage tab="create" user={this.props.user}>
        <div style={{
          display: 'flex', flexDirection: 'column', height: '100%', position: 'relative',
        }}
        >
          <NavBar active={Pathname.create} />
          <div className={contentClass}>
            <PageHeader>
              <PageTitle>
                {lang.title}
                <Button
                  style={{ marginLeft: 30 }}
                  className="button button-blue"
                  onClick={() => this.videoMakerService.createVideoMaker()}
                >
                  {lang.create}
                </Button>
              </PageTitle>
            </PageHeader>
            <VideoMakerList
              videoMakers={this.props.list}
              onRemove={(vm) => this.videoMakerService.removeVideoMaker(vm._id)}
              loading={this.props.loading}
              onOpen={(videoMaker) => {
                this.browsingService.goToPage({
                  root: Pathname.create,
                  args: new Map().set(ArgsName.create.id, videoMaker._id),
                });
              }}
            />
          </div>
        </div>
      </AuthorizedPage>
    );
  }

  public componentDidMount() {
    this.videoMakerService.loadVideoMakers();
  }
}

const lang = new LocalizedStrings({
  en: {
    title: 'Video',
    create: 'Create a new video',
  },
});

const contentClass = style({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  position: 'relative',
  height: 400,
});

export const VideoMakers = connect(mapStateToProps)(VideoMakersPage);
