import React, { Component } from 'react';
import { MediaView } from 'common';
import { stylesheet } from 'typestyle';

interface IProps {
  onError?: () => any
  onLoad?: Function
  src?: string
  media?: MediaView
  onClick?: Function
  style?: React.CSSProperties
}

interface IState {
  error: boolean | null
  img_width: number
  real_width: number
  real_height: number
  div_width: number
}

export class MediaImage extends Component<IProps, IState> {
  public divElement: any;

  public display_yolo = false;

  constructor(props: IProps) {
    super(props);
    this.state = {
      error: null,
      img_width: 100000,
      real_width: 10000,
      real_height: 10000,
      div_width: 10000,
    };
  }

  public onError() {
    this.setState({ error: true });
    if (this.props.onError) {
      this.props.onError();
    }
  }

  public onLoad(e: React.SyntheticEvent<HTMLImageElement, Event>) {
    const img = e.target as HTMLImageElement;
    this.setState({
      img_width: img.offsetWidth,
      real_width: img.naturalWidth,
      real_height: img.naturalHeight,
    });
    if (this.props.onLoad) {
      this.props.onLoad();
    }
  }

  public hashClass(name: string) {
    let res = name.split('').reduce((prev, current) => {
      const num = current.charCodeAt(0);
      prev *= 16;
      prev = (num + prev) % 16777216;
      return prev;
    }, 0).toString(16).slice(0, 6);
    while (res.length < 6) { res += res[0]; }
    return res;
  }

  public componentDidMount() {
    this.setState({
      div_width: this.divElement.clientWidth,
    });
  }

  public render() {
    if (this.state.error) {
      return null;
    }
    return (
      <div style={{ position: 'relative' }} ref={(divElement) => this.divElement = divElement}>
        <img
          className={sheet.image}
          style={{ ...this.props.style }}
          src={this.props.src}
          onLoad={(ev) => this.onLoad(ev)}
          onError={this.onError.bind(this)}
        />
      </div>
    );
  }
}

const sheet = stylesheet({
  image: {
    display: 'block',
  },
});
