import React from 'react';

// Content
import ReactLocalizedString from 'react-localization';

// Icons
import { MediaRightsApprovedIcon } from '../SvgIcons/MediaRightsApprovedIcon';
import { MediaRightsRequestedIcon } from '../SvgIcons/MediaRightsRequestedIcon';
import { MediaRightsUnrequestedIcon } from '../SvgIcons/MediaRightsUnrequestedIcon';

// Types
import { MediaView } from '@src/../common/dist';
import { Tooltip } from '@mui/material';

export interface Props {
  media: MediaView;
}

/**
 * The rights icon will dynamically change depending on the right status.
 */

export function MediaRightsIcon(props: Props) {
  const showRightsIcon = (rights?: string) => {
    if (rights === 'agreed') return <MediaRightsApprovedIcon />;
    if (rights === 'requested') return <MediaRightsRequestedIcon />;
    if (rights === 'unrequested') return <MediaRightsUnrequestedIcon media={props.media} />;
  };
  const rightsTooltip = (rights?: string) => {
    if (rights === 'agreed') return lang.rights.agreed;
    if (rights === 'requested') return lang.rights.requested;
    if (rights === 'unrequested') return lang.rights.unrequested;
  };
  return (
    <>
      <Tooltip title={rightsTooltip(props.media.rightsRequestStatus)} placement='top'>
        <span>{showRightsIcon(props.media.rightsRequestStatus)}</span>
      </Tooltip>
    </>
  );
}

export const lang = new ReactLocalizedString({
  en: {
    rights: {
      agreed: 'Rights agreed',
      requested: 'Rights requested',
      unrequested: 'Rights not requested',
    },
  },
});
