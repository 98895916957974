import React, { FC, useState, useEffect } from 'react';
import { connect } from 'react-redux';
import { ICombinedReducers } from '@src/types/redux';
import { EPopup } from '@src/types/redux/common';
import { MediaView } from 'common';
import * as CommonActions from '../../../../actions/common';
import styles from './styles.scss';
import { useMutationCreatorCountriesUpdate } from '@src/queries/media/useMutationCreatorCountriesUpdate';

interface IProps {
  mediaList?: MediaView[];
  close: () => void;
  show: boolean | null;
  catalogCountries?: string[];
}

const SetCreatorCountriesPopup: FC<IProps> = ({ mediaList, close, show, catalogCountries }) => {
  const [creatorCountries, setCreatorCountries] = useState<string[]>([]);
  const { mutate: mutateCreatorCountries, isLoading: isSettingCreatorCountries } =
    useMutationCreatorCountriesUpdate();

  useEffect(() => {
    if (mediaList && mediaList.length > 1) {
      setCreatorCountries(catalogCountries ?? []);
    } else if (mediaList && mediaList.length === 1) {
      const allCreatorCountries = mediaList.flatMap(media => media.creatorCountries || []);
      const uniqueCreatorCountries = Array.from(new Set(allCreatorCountries));
      if (uniqueCreatorCountries.length === 0) {
        // If the media has no creator countries set, we want to check all countries by default
        setCreatorCountries(catalogCountries ?? []);
        return;
      }
      setCreatorCountries(uniqueCreatorCountries);
    } else {
      // By default, we want to check all countries when the media has no creator countries set
      setCreatorCountries(catalogCountries ?? []);
    }
  }, [mediaList]);

  const handleCountryToggle = (country: string) => {
    setCreatorCountries((prev) =>
      prev.includes(country) ? prev.filter((c) => c !== country) : [...prev, country]
    );
  };

  const handleSubmit = () => {
    const excludedCountries = catalogCountries?.filter((country) => !creatorCountries.includes(country));
    const mediaUsernames = Array.from(
      new Set(
        mediaList?.map(
          media => media.source.user_name
        ).filter(
          (username): username is string => username !== undefined && username !== ''
        ) ?? []
      )
    );

    mutateCreatorCountries(
      {
        updates: mediaUsernames.map(username => ({
          username,
          countries: creatorCountries,
          countriesToExclude: excludedCountries ?? [],
        })),
      },
      {
        onSuccess: () => {
          close();
        },
        onError: (error) => {
          console.error('Failed to update creator countries:', error);
        },
      }
    );
  };

  if (!mediaList || !catalogCountries) {
    return null;
  }

  return (
    <div
      className={styles.creatorCountriesOverlay}
      data-show={!!show}
      onClick={() => close()}
    >
      <div className="frame" onClick={(e) => e.stopPropagation()}>
        <div className="title">
          Set Creator Countries
          <div className="remove" onClick={() => close()}>
            <i className="material-icons notranslate">close</i>
          </div>
        </div>
        <div className="inputs">
          <label htmlFor="countries-select" className="label">
            Select countries:
          </label>
          <div className="checkbox-group">
            {catalogCountries.map((country) => (
              <label key={country} className="checkbox-label">
                <input
                  type="checkbox"
                  value={country}
                  checked={creatorCountries.includes(country)}
                  onChange={() => handleCountryToggle(country)}
                  disabled={isSettingCreatorCountries}
                />
                {country}
              </label>
            ))}
          </div>
        </div>
        <div className="buttons">
          <button
            className="button button-border"
            onClick={() => close()}
            disabled={isSettingCreatorCountries}
          >
            Cancel
          </button>
          <button
            className="button button-blue"
            onClick={handleSubmit}
            disabled={isSettingCreatorCountries}
          >
            {isSettingCreatorCountries ? 'Submitting...' : 'Submit'}
          </button>
        </div>
      </div>
    </div>
  );
};

export default connect(
  (state: ICombinedReducers) => ({
    show: state.common.popups[EPopup.setCreatorCountriesOverlay],
    mediaList: state.common.setCreatorCountriesOverlay.mediaList,
    catalogCountries: state.common.user?.catalogCountries,
  }),
  (dispatch) => ({
    close: () => dispatch(CommonActions.setPopup(EPopup.setCreatorCountriesOverlay, false)),
  })
)(SetCreatorCountriesPopup);
