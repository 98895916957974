import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ICombinedReducers } from '@src/types/redux';
import { ThunkDispatch } from 'redux-thunk';
import * as CommonActions from 'actions/common';
import { OverlayContainer } from 'components/OverlayContainer';
import { CommonState, EPopup } from '@src/types/redux/common';
import { ActionButton } from '@src/components/ActionButton';
import { Loader } from '@src/components/Loader';
import { stylesheet } from 'typestyle';
import styles from './styles.scss';

interface StateToProps {
  data: CommonState['popups']['promptconfirm']
}

interface DispatchToProps {
  close: () => any
}

type Props = StateToProps & DispatchToProps;

interface State {
  loading: boolean
}

class PromptConfirm extends Component <Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      loading: false,
    };
  }

  public onAnswer(yes: boolean) {
    this.setState({ loading: true });
    Promise.resolve(this.props.data!.answer(yes)).catch((e) => {
      throw e;
    }).then(() => {
      this.setState({ loading: false });
      this.props.close();
    });
  }

  public render() {
    const { data } = this.props;
    if (!data) {
      return null;
    }
    const text = data.text || {};
    const yesColor = data.yesColor || 'blue';

    return (
      <OverlayContainer
        containerClasses={[styles.prompt]}
        frameClasses={[styles.frame]}
        onClose={() => this.onAnswer(false)}
      >
        <div className={styles.question}>{data.question}</div>
        {!this.state.loading ? (
          <div className={styles.answers}>
            <div className="no">
              <ActionButton variant="transparent" onAction={() => this.onAnswer(false)}>
                {(text.no || window.T('common.prompt.no')).toUpperCase()}
              </ActionButton>
            </div>
            <div className="yes">
              <ActionButton variant={yesColor} onAction={() => this.onAnswer(true)}>
                {(text.yes || window.T('common.prompt.yes')).toUpperCase()}
              </ActionButton>
            </div>
          </div>
        ) : (
          <div className={sheet.loader}>
            <Loader size={40} />
          </div>
        )}
      </OverlayContainer>
    );
  }
}

const sheet = stylesheet({
  loader: {
    textAlign: 'center',
    marginTop: 4,
  },
});

const mapStateToProps = (state: ICombinedReducers): StateToProps => ({
  data: state.common.popups.promptconfirm,
});

const mapDispatchToProps = (dispatch: ThunkDispatch<{}, {}, any>): DispatchToProps => ({
  close: () => dispatch(CommonActions.setPopup(EPopup.promptconfirm, null)),
});

export default connect(mapStateToProps, mapDispatchToProps)(PromptConfirm);
