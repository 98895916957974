import React, { createRef, PropsWithChildren, useEffect, useState, ReactElement } from 'react';

import { PerfectScrollbar } from 'components/PerfectScrollbar';
import * as reduxHelpers from '@src/services/helpers/redux';
import Draggable from 'components/Draggable';
import { stylesheet } from 'typestyle';
import translation from './translation';

const lang = reduxHelpers.loadTranslation(translation);

const DEFAULT_EMPTY_LINES = 3;

interface Props {
  maxColumn?: number;
  onInsert: (item: any, position: number) => void;
  onMoveItem: (itemIndex: number, newPosition: number) => void;
  validInsertInfo: (info: any) => boolean;
  gridElements: ReactElement[] | null;
}

export default function DnDGrid(props: PropsWithChildren<Props>): JSX.Element {

  const [dragInsert, setDragInsert] = useState<number>(-1);
  const [draggingItem, setDragdraggingItem] = useState<number>(-1);
  const [mousePosition, setMousePosition] = useState<{ x: number, y: number }>({ x: 0, y: 0 });

  const maxColumn = props.maxColumn || 3;

  const componentRef = createRef<HTMLDivElement>();

  const gridTemplateColumns = new Array(maxColumn).fill('auto').join(' ');
  const childrens = props.gridElements || [];

    return (
      <div
        ref={componentRef}
        className={sheet.dndSquareGrid}
                // OnDragOver={this.onMouseMove.bind(this)}
        onDragLeave={() => onDragLeave}
      >
        <PerfectScrollbar
          className={sheet.container}
          onDragEnter={(e) => onDragEnter(childrens.length, e)}
          onDragOver={(e) => onDragOver(e)}
                    // Data-dragover={this.state.dragOver > -1}
          onDrop={(e) => onDrop(childrens.length, e)}
          options={{
            maxScrollbarLength: 250,
          }}
        >
          <div
            data-empty={!childrens.length ? lang.empty : false}
            className={sheet.grid}
            style={{ gridTemplateColumns }}
          >
            {renderBoxes(childrens)}
          </div>
        </PerfectScrollbar>
      </div>
    );

  // TODO: refactor boxescreator
  function renderBoxes(childrens: React.ReactNode[]) {
    const boxesCreators = childrens.map((item, originalIndex) => ((i: number) => {
      const dragging = draggingItem === originalIndex;
      const removing = dragging && dragInsert === -1;
      return (
        <Draggable
          key={i}
          info={{ origin: 'grid', type: 'media' }}
          className={[sheet.item, 'item-container'].join(' ')}
          data-dragging={dragging}
          onDragEnter={(e) => onDragEnter(i, e)}
          onDrop={(e) => onDrop(i, e)}
          onDragStart={() => {
            setDragdraggingItem(originalIndex);
            setDragInsert(originalIndex)
          }}
          onDragEnd={() => onItemDragEnd()}
        >
          <div className={sheet.itemDelete} data-removing={removing} />
          {item}
        </Draggable>
      );
    }));

    const isInserting = dragInsert > -1;
    const isDraggingItem = draggingItem > -1;
    const isNotDraggingItemToSamePosition = draggingItem !== dragInsert;
    const isNotDraggingToANewPosition = dragInsert !== childrens.length;
    const isNotDraggingTheLastItem = draggingItem + 1 !== childrens.length;

    if (isInserting) {
      if (!isDraggingItem) {
        // Adding new item
        const index = dragInsert;
        const emptyBox = (i: number) => (
          <div
            key={i}
            className={sheet.item}
            onDragEnter={(e) => onDragEnter(index, e)}
            onDrop={(e) => onDrop(index, e)}
          >
            <div className={sheet.emptyItem} />
          </div>
        );
        boxesCreators.splice(index, 0, emptyBox);
      } else if (
        isNotDraggingItemToSamePosition && (
          isNotDraggingToANewPosition
                    || isNotDraggingTheLastItem
        )
      ) {
        // Moving item
        const [item] = boxesCreators.splice(draggingItem, 1);
        boxesCreators.splice(dragInsert, 0, item);
      }
    }

    // Create an empty grid if necessary
    let toCreate = (DEFAULT_EMPTY_LINES * maxColumn) - boxesCreators.length;
    const r = (boxesCreators.length % maxColumn);
    if (toCreate < 0 && r) {
      toCreate = maxColumn - r;
    }
    while (toCreate-- > 0) {
      boxesCreators.push((i: number) => (
        <div
          key={i}
          className={sheet.item}
        >
          <div className={[sheet.itemForEmptyGrid, sheet.emptyItem].join(' ')} />
        </div>
      ));
    }

    useEffect(() => {
      window.addEventListener('dragover', onMouseMove);
      return function cleanup() {
        window.removeEventListener('dragover', onMouseMove);
      }
    })

    return boxesCreators.map((f, i) => f(i));
  }

  function getDragInfo(event: React.DragEvent<HTMLDivElement>) {
    const message = event.dataTransfer.getData('text');
    if (!message) {
      return null;
    }
    let info: any | null = null;
    try {
      info = JSON.parse(message);
    } catch (e) { }
    return info;
  }

  function putDragInsert(index: number) {
    if (dragInsert === index) {
      return;
    }
    setDragInsert(index);
    // this.dragInsert = index;
    // this.setState({ dragInsert: this.dragInsert });
  }

  function mouseIsOut() {
    const {
      left, top, width, height,
    } = componentRef.current!.getBoundingClientRect();
    const right = left + width;
    const bottom = top + height;
    const { x, y } = mousePosition;
    const offset = 5;
    return x <= left + offset || y <= top + offset || y >= bottom - offset || x >= right - offset;
  }

  function onMouseMove(event: DragEvent) {
    setMousePosition({
      x: event.pageX,
      y: event.pageY,
    })
  }

  function onDragOver(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
  }

  function onDragEnter(index: number, event: React.DragEvent<HTMLDivElement>) {
    event.stopPropagation();
    putDragInsert(index);
  }

  function onDragLeave(e: React.DragEvent<HTMLDivElement>) {
    e.persist();
    setTimeout(() => {
      if (mouseIsOut()) {
        putDragInsert(-1);
      }
    }, 20);
  }

  function onDrop(index: number, event: React.DragEvent<HTMLDivElement>) {
    event.stopPropagation();

    const numberOfChildrens = props.gridElements?.length || 0;

    const info = getDragInfo(event);
    if (props.validInsertInfo(info)) {
      const position = index === -1 ? numberOfChildrens : index;
      props.onInsert(info, position);
    } else if (info.origin === 'grid') {
      const position = index === numberOfChildrens ? numberOfChildrens - 1 : index;
      props.onMoveItem(draggingItem, position);
    }

    putDragInsert(-1);
    setDragdraggingItem(-1)
  }

  function onItemDragEnd() {
    if (mouseIsOut()) {
      setDragdraggingItem(-1);
    }
  }
}

const sheet = stylesheet({
  dndSquareGrid: {
    width: '75%',
    maxWidth: 600,
    margin: 'auto',
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  container: {
    padding: '0 7px 0 0',
    flex: 1,
    backgroundColor: 'transparent',
    transition: 'background-color 0.2s',
    $nest: {
      '.container[data-dragover=true]': {
        backgroundColor: '#4444441f',
      },
    },
  },
  grid: {
    display: 'grid',
    position: 'relative',
    $nest: {
      '&:not([data-empty=false]):after': {
        content: 'attr(data-empty)',
        color: '#ececec',
        fontSize: 22,
        position: 'absolute',
        top: 0,
        paddingTop: 185,
        height: '100%',
        width: '100%',
        textAlign: 'center',
      },
    },
  },
  item: {
    padding: 7,
    textAlign: 'center',
    position: 'relative',
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-around',
    cursor: 'pointer',
    borderTop: '2px solid #292929',
    borderRight: '2px solid #292929',
    $nest: {
      '&[data-dragging=true] img': {
        border: '2px solid #f1b371ad',
      },
      '&:nth-child(-n+3)': {
        borderTop: 'none',
      },
      '&:nth-child(3n)': {
        borderRight: 'none',
      },
    },
  },
  itemDelete: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    display: 'none',
    textAlign: 'center',
    justifyContent: 'center',
    flexDirection: 'column',
    zIndex: 1,
    backgroundColor: '#0000008a',
    $nest: {
      svg: {
        margin: 'auto',
        fontSize: 46,
        color: '#b7b7b799',
      },
      '&[data-removing=true]': {
        display: 'flex',
      },
    },
  },
  emptyItem: {
    width: '100%',
    backgroundColor: '#272727',
    paddingBottom: '100%',
  },
  itemForEmptyGrid: {
    backgroundColor: 'transparent',
  },
});
