import React, { HTMLAttributes, PropsWithChildren, useRef, useState } from 'react';

import LocalizedStrings from 'react-localization';
import { Services } from '@src/services';
import { ServiceName } from '@src/types/Services';
import { classes, style } from 'typestyle';
import { ADALONG_COLORS } from '@src/styles';
import { color } from 'csx';
import { IMediaService } from '@src/types/Media';
import { CollectionView, CreatorView, MediaView } from 'common';
import { Browsing } from '@src/types/Browsing';
import { goToMedia } from '@src/pages/Media/utils/mediaRoute';
import { useWindowSize } from '@src/utils/hooks/useWindowSize';
import { MediaType } from '@adalong/schemas';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import TextContent from './TextContent';
import { ProductsLinkedExactIcon } from '../SvgIcons/ProductsLinkedExact';
import { ProductsLinkedSimilarIcon } from '../SvgIcons/ProductsLinkedSimilar';
import { Column } from '../Utils';
import { MediaMark } from '../MediaInfo/MediaMark';
import { MediaLittleData } from '../MediaInfo/MediaLittleData';
import { MediaUsername } from '../MediaInfo/MediaUsername';
import { MediaInfo } from '../MediaInfo';
import translation from './translation';
import { MediaImage } from '../MediaImage';
import { ShortcutActionRow } from './ShortcutActionRow';
import { useQueryInfluencersCreators } from '@src/queries/influencers/useQueryCreators';
import { MediaDiffusionIcon } from '../MediaStatus/MediaDiffusionIcon';
import { MediaRightsIcon } from '../MediaStatus/MediaRightsIcon';

const DEFAULT_NUMBER_PER_ROW = 4;

// TODO: move in the Media folder in pages
interface Props {
  media: MediaView & {
    collection?: CollectionView;
  };
  key: string;
  onImageError?: () => void;
  onSelect?: Function;
  blur?: boolean;
  selected?: boolean;
  isViewedByGuest: boolean;
  lightened?: boolean;
  hideOnImageFailed?: boolean;
  /**
   * Button to access to media actions
   */
  actionButton: React.ReactNode;
  /**
   * A callback to render a shortcut button to the main action
   * depending on the context
   * failed indicate if the media failed to load
   */
  renderShortcutActionButton: (media: MediaView, failed: boolean) => React.ReactNode;
}

export default function MediaPreview({ media: { ...media }, hideOnImageFailed, actionButton, renderShortcutActionButton, ...props }: Props) {
  const [loaded, setLoaded] = useState(false);
  const [imgFailed, setImgFailed] = useState<boolean>(media.isDeleted);
  const [mouseOver, setMouseOver] = useState<boolean>(false);
  const failed = imgFailed ? lang.failed : undefined;

  const [{ mediaService, browsingService }] = useState(() => ({
    mediaService: Services.get<IMediaService>(ServiceName.MediaService),
    browsingService: Services.get<Browsing>(ServiceName.Browsing),
  }));

  // Checking if a creator is saved as an active influencer
  const { data: influencers = [], error: errorInfluencers, isLoading: loadingInfluencers } = useQueryInfluencersCreators();

  function checkActiveInfluencer(username: string | undefined, creators: CreatorView[]): boolean {
    return creators.some((creator) => creator.username === username && creator.isInfluencer);
  }

  return (
    <MediaContainer
      data-id={media._id}
      data-failed={failed}
      data-hideonfailed={hideOnImageFailed}
      data-blur={!!props.blur}
      onMouseEnter={() => setMouseOver(true)}
      onMouseLeave={() => setMouseOver(false)}
    >
      <div className={checkActiveInfluencer(media.source.user_name, influencers) ? previewContainerClass : ''}>
        <div className={mediaContainerClass} onClick={handleClick} data-selected={props.selected}>
          {media.type == MediaType.Text ? (
            <TextContent caption={media.caption} />
          ) : (
            <MediaImage src={media.cdn_image} media={media} onLoad={onLoad} style={{ width: '100%' }} onError={onImageError} />
          )}
          <div className={overlayClass} data-failed={failed} onClick={onOverlayClick}>
            {props.isViewedByGuest ? (
              <div className={headerClass} />
            ) : (
              mouseOver && (
                <div className={classes(headerClass)}>
                  <ShortcutActionRow>{renderShortcutActionButton(media, failed !== undefined)}</ShortcutActionRow>
                </div>
              )
            )}
          </div>
          {media.type === 'video' && !failed && loaded && (
            <div className={playButtonClass}>
              <PlayArrowRoundedIcon className={faPlayClass} style={{ fontSize: '5rem' }} />
            </div>
          )}
        </div>
        {!failed && (
          <div className={footerClass}>
            <MediaInfo
              autoHeight
              titleLine={
                <Column>
                  <MediaUsername username={media.source.user_name} activeInfluencer={checkActiveInfluencer(media.source.user_name, influencers)} />
                  <span className={mediaStatusClass(mouseOver, props.isViewedByGuest)}>
                    <MediaDiffusionIcon media={media} />
                    <MediaRightsIcon media={media} />
                  </span>
                </Column>
              }
              actionButtons={mouseOver && !props.isViewedByGuest ? actionButton : <></>}
              littleDataLine={
                <MediaLittleData
                  source={media.source.name}
                  location={media.info?.location?.name}
                  likes={media.info?.social?.likes}
                  date={media.published_at}
                />
              }
              marksLine={
                <>
                  {media.hasActiveExactProducts ? <ProductsLinkedExactIcon style={shopIconStyle} /> : null}

                  {media.hasActiveSimilarProducts ? <ProductsLinkedSimilarIcon style={shopIconStyle} /> : null}

                  <MediaMark
                    marks={mediaService.getMarks(
                      {
                        ...media,
                        tags: mediaService.filterTagsByGroup(media.tags),
                      },
                      ['mentions', 'hashtags', 'tagged', 'ownContent']
                    )}
                  />
                </>
              }
            />
          </div>
        )}
      </div>
    </MediaContainer>
  );

  function onOverlayClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (!e.shiftKey) {
      if (!props.isViewedByGuest && !failed) {
        browsingService.goToPage(goToMedia(window.location, media._id));
      } else {
        window.open(media.url.post, '_blank');
      }
    }
  }

  function handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    if (e.shiftKey && props.onSelect) {
      e.stopPropagation();
      props.onSelect(media);
    }
  }

  function onImageError() {
    setImgFailed(true);
    if (props.onImageError) {
      props.onImageError();
    }
  }

  function onLoad() {
    setLoaded(true);
  }
}

function MediaContainer({ children, ...props }: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  // lets rerender when the window size change
  useWindowSize();

  const ref = useRef<HTMLDivElement>(null);
  const width = bestWidthPercent(ref.current?.parentElement?.offsetWidth);

  return (
    <div className={mediaelementClass} ref={ref} style={{ width }} {...props}>
      {children}
    </div>
  );
}

function bestWidthPercent(parentWidth?: number): string {
  const MIN_WIDTH_MEDIA = 240;
  let r = 100 / DEFAULT_NUMBER_PER_ROW;
  if (parentWidth) {
    if (parentWidth < MIN_WIDTH_MEDIA * 2) {
      r = 100;
    } else if (parentWidth < MIN_WIDTH_MEDIA * 3) {
      r = 100 / 2;
    } else if (parentWidth < MIN_WIDTH_MEDIA * 4) {
      r = 100 / 3;
    }
  }
  return `${r}%`;
}

const playButtonClass = style({
  display: 'flex',
  top: 0,
  left: 0,
  flexDirection: 'column',
  justifyContent: 'center',
  textAlign: 'center',
  zIndex: 1,
  position: 'absolute',
  width: '100%',
  height: '100%',
  color: 'white',
});

const faPlayClass = style({
  margin: 'auto',
});

const lang = new LocalizedStrings(translation);

const footerClass = style({
  backgroundColor: color(ADALONG_COLORS.ABSOLUTE_BLACK).fade(0.8).toString(),
  padding: '2px 6px',
  overflow: 'hidden',
});

const shopIconStyle: React.CSSProperties = {
  verticalAlign: 'text-bottom',
  marginRight: 7,
};

const previewContainerClass = style({
  border: 'green 1px solid',
});

const mediaStatusClass = (mouseOver: boolean, currentlyViewed: boolean) =>
  style({
    position: 'absolute',
    top: 4,
    right: mouseOver && !currentlyViewed ? 30 : 2,
  });

const mediaelementClass = style({
  position: 'relative',
  padding: '0 6px 12px',
  transition: 'opacity 0.13s, filter 0.13s',
  borderRadius: 5,
  $nest: {
    '&[data-blur="true"]': {
      opacity: 0.6,
      filter: 'blur(1px)',
    },
    '&[data-failed]': {
      $nest: {
        '&[data-hideonfailed="true"]': {
          padding: 0,
          height: 0,
          overflow: 'hidden',
        },
        '&:before': {
          display: 'flex',
          justifyContent: 'center',
          flexDirection: 'column',
          position: 'absolute',
          top: 0,
          content: 'attr(data-failed)',
          color: '#8a786f',
          textAlign: 'center',
          height: '100%',
          width: '100%',
          left: 0,
        },
      },
      height: 150,
      cursor: 'default',
    },
  },
});

const mediaContainerClass = style({
  userSelect: 'none',
  '-moz-user-select': 'none',
  '-webkit-user-select': 'none',
  '-ms-user-select': 'none',
  position: 'relative',
  height: '100%',
  background: '#77777712',
  $nest: {
    '&[data-selected="true"]': {
      outline: '2px solid #4E70C8',
      filter: 'brightness(80%)',
    },
  },
});

const overlayClass = style({
  fontSize: 13,
  flexDirection: 'column',
  justifyContent: 'space-between',
  cursor: 'pointer',
  zIndex: 2,
  opacity: 1,
  visibility: 'visible',
  transition: 'opacity 0.3s',
  position: 'absolute',
  display: 'flex',
  top: 0,
  left: 0,
  width: '100%',
  height: '100%',
  color: 'white',
  textAlign: 'center',
});

const headerClass = style({
  display: 'flex',
  justifyContent: 'flex-end',
});
