import { ApiTypes } from 'common';
import { Call } from '@src/services/api';

export const getWidget = async (id: string): Promise<ApiTypes.ResGetWidget> => Call.get(`/api/widget/${id}`);

export const getLocalizedWidget = async (id: string): Promise<ApiTypes.ResGetWidgetList> => Call.get(`/api/widget/localized/${id}`);

export const updateWidget = (id: string, doc: ApiTypes.BodyUpdateWidget['doc']): Promise<ApiTypes.ResUpdateWidget> => Call.put<ApiTypes.ResUpdateWidget, ApiTypes.BodyUpdateWidget>(`/api/widget/${id}`, { doc });

export const getWidgets = (): Promise<ApiTypes.ResGetWidgetList> => Call.get('/api/widget');

export const removeWidget = (id: string): Promise<ApiTypes.ResDeleteWidget> => Call.delete(`/api/widget/${id}`);

export const createWidget = (widget: ApiTypes.BodyCreateWidget['widget']): Promise<ApiTypes.ResCreateWidget> => Call.post<ApiTypes.ResCreateWidget, ApiTypes.BodyCreateWidget>('/api/widget/', { widget });
