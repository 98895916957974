import i18next from 'i18next';
import { HubSpotConversations, HubSpotConversationsSettings } from './types/hubspot';
import { Turnstile } from './types/CloudflareTurnstile';
import { CommonState } from './types/redux/common';
import { GAnalytics } from './utils/GoogleAnalytics';

declare global {
  interface Window {
    capitalize: (input: string) => string
    copyToClipboard: (input: string) => void
    uniqBy: (arr: string[], predicate: Function) => string[]
    reactSwitch: (bool: any, elem: any[]) => any
    loadLiveChat: (user: CommonState['user'], token: string) => void
    T: i18next.TranslationFunction
    LOCALS: any
    Tether: any
    setTracking: any
    analytics?: GAnalytics
    HubSpotConversations?: HubSpotConversations
    hsConversationsSettings?: HubSpotConversationsSettings
    turnstile?: Turnstile
  }
}

window.capitalize = function (str) {
  return str.charAt(0).toUpperCase() + str.slice(1);
};

window.uniqBy = (arr, predicate) => {
  const cb = typeof predicate === 'function' ? predicate : (o: any) => o[predicate];

  return [...arr.reduce((map, item) => {
    const key = cb(item);

    if (!map.has(key)) {
      map.set(key, item);
    }

    return map;
  }, new Map()).values()];
};

window.reactSwitch = function <T>(
  val: T,
  array: { val: T; elem: (val: T) => JSX.Element }[],
) {
  const a = array.find((elm) => {
    if (Array.isArray(elm.val)) {
      return elm.val.find((v: any) => v === val);
    }
    return elm.val === val;
  });
  if (a) {
    return typeof a.elem === 'function' ? a.elem(val) : a.elem;
  }
  return null;
};

window.copyToClipboard = (text: string) => {
  const span = document.createElement('span');
  span.textContent = text;

  // Preserve consecutive spaces and newlines
  span.style.whiteSpace = 'pre';

  // Add the <span> to the page
  document.body.appendChild(span);

  // Make a selection object representing the range of text selected by the user
  const selection = window.getSelection();
  const range = window.document.createRange();
  if (!selection) { return; }
  selection.removeAllRanges();
  range.selectNode(span);
  selection.addRange(range);

  // Copy text to the clipboard
  try {
    window.document.execCommand('copy');
  } catch (err) {
    console.error('error', err);
  }

  // Cleanup
  selection.removeAllRanges();
  window.document.body.removeChild(span);
};
