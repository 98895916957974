import {
  useInfiniteQuery,
  UseInfiniteQueryOptions,
} from 'react-query';
import { QUERY_KEYS } from '@src/query';
import { getConversationMessages } from './api';
import { ApiTypes } from 'common';
import { conversationUId } from '@src/pages/CRM/Messages/MessagesPage';

/**
 * Get config
 */
export const useQueryConversationMessage = (
  params: ApiTypes.ParamsGetConversationMessages,
  config?: UseInfiniteQueryOptions<ApiTypes.ResGetDms>
) => {
  return useInfiniteQuery<ApiTypes.ResGetDms>(
    QUERY_KEYS.conversationMessages(
      conversationUId(params.targetAccountId, params.interlocutorId)
    ),
    ({ pageParam: cursor }) => getConversationMessages(params, cursor),
    {
      getNextPageParam: (lastPage, pages) => lastPage.cursor,
      ...config,
    }
  );
};
