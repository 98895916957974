import React, { useState, useEffect, useCallback } from 'react';

/* Redux */
import { connect } from 'react-redux';
import { ICombinedReducers } from '@src/types/redux';

/* Api */
import { useMutationCreateCreator } from '@src/queries/influencers/useMutationCreateCreator';
import { useQueryProductsAvailableCountries } from '@src/queries/products/useQueryProducts';

/* Styling */
import { style, stylesheet } from 'typestyle';
import styles from '../styles.scss';
import { ADALONG_COLORS } from '@src/styles';

import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import Checkbox from '@mui/material/Checkbox';

/* Misc */
import * as CommonActions from '../../../../../actions/common';
import { EPopup } from '@src/types/redux/common';
import { DispatchType } from '../../../../../store';
import LocalizedStrings from 'react-localization';

/* Components */
import { OverlayContainer } from '@src/components/OverlayContainer';
import { CloseButton } from '@src/components/CloseButton';
import { Row } from '@src/components/Utils';
import { Input } from 'components/Input';
import { ActionButton } from '@src/components/ActionButton';
import { SectionProgressLoader } from '@src/components/SectionProgressLoader';

/* Types */
import { UserView } from 'common';
import { Services } from '@src/types/Services';

interface OwnProps {
  services: Services;
}

interface IProps {
  user: UserView | null;
  focus?: boolean;
  loadUserInfo: () => Promise<{
    user: UserView;
  }>;
  close: () => void;
  catalogCountries?: string[];
}

type Props = OwnProps & IProps;

export function AddInfluencer(props: Props) {
  const [mounted, setMounted] = useState(false);
  const [influencerData, setInfluencerData] = useState<{ name: string, countries?: string[] }>({ name: '', countries: [] });

  const { mutateAsync: mutateInfluencer, isLoading: saving } = useMutationCreateCreator();

  const handleLocalizationChange = (event: React.ChangeEvent<HTMLInputElement>, name: string) => {
    if (event.target.checked) {
      setInfluencerData({
        ...influencerData,
        countries: [...influencerData.countries || [], name],
      });
    } else {
      setInfluencerData({
        ...influencerData,
        countries: influencerData.countries ? [...influencerData.countries?.filter((country) => country !== name)] : [],
      });
    }
  };

  const onSaveClick = async () => {
    const countriesToExclude = (props.catalogCountries ?? []).filter((country) => !influencerData.countries?.includes(country));
    await mutateInfluencer({
      username: influencerData.name,
      countries: influencerData.countries,
      countriesToExclude: countriesToExclude,
      isInfluencer: true,
      isInfluencerDisabled: false,
      isBlacklisted: false,
      upsert: true,
    });
    close();
  };

  const refresh = (): void | NodeJS.Timeout => {
    if (!mounted) {
      return;
    }
    const loop = () => setTimeout(() => refresh(), 3000);
    if (!props.focus) {
      return loop();
    }
  };

  const close = useCallback(() => {
    props.close();
  }, []);

  useEffect(() => {
    setMounted(true);
    refresh();
    return () => {
      setMounted(false);
    };
  }, []);

  const disableSubmit = influencerData.name.length < 1 || influencerData.name.length > 30;

  if (!props.user) {
    return null; // TODO: loading
  }
  return (
    <>
      <OverlayContainer
        containerClasses={[styles.settingsContainer]}
        frameClasses={[styles.settings]}
        onClose={close}
      >
        <Row className={headClass}>
          <div className={sheet.title}>{lang.title}</div>
          <CloseButton size='large' classNames={[sheet.closeButton]} onClick={close} />
        </Row>

        <Input
          onChange={({ target: { value } }) =>
            setInfluencerData({ ...influencerData, name: value })
          }
          value={influencerData.name}
          placeholder={lang.name}
        />

        {props.catalogCountries?.length ? (
          <div className={sheet.text}>
            {lang.localizations}

            <FormGroup row={true}>
              {props.catalogCountries.map((country, i) => (
                <FormControlLabel
                  key={i}
                  control={
                    <Checkbox
                      sx={{
                        color: ADALONG_COLORS.LIGHT_GRAY,
                        '&.Mui-checked': {
                          color: ADALONG_COLORS.LIGHT_GRAY,
                        },
                      }}
                      onChange={(e) => handleLocalizationChange(e, country)}
                    />
                  }
                  label={country}
                />
              ))}
            </FormGroup>
          </div>
        ) : undefined}

        <div className={sheet.text}>{lang.desc}</div>

        <div className={sheet.buttonContainer}>
          <ActionButton
            variant='blue'
            onAction={onSaveClick}
            disabled={disableSubmit}
            data-size='medium'
          >
            {lang.save.toUpperCase()}
          </ActionButton>
        </div>

        {saving ? <SectionProgressLoader indeterminate opacity={0.6} /> : null}
      </OverlayContainer>
    </>
  );
}

const lang = new LocalizedStrings({
  en: {
    title: 'Add an Influencer',
    name: 'Enter an instagram name',
    desc:
      '⚠️ I am aware that by adding this influencer to my whitelist, I do have a written agreement about collecting and using their content for marketing purposes. See more on https://www.adalong.com/yesadalong/.',
    error: 'An error occured, please try later or contact us',
    save: 'Add to my whitelist',
    localizations: 'For which market(s) is this influencer contracted?',
  },
});

const headClass = style({
  flexShrink: 0,
});

const sheet = stylesheet({
  title: {
    flex: 1,
    textAlign: 'center',
    fontSize: 25,
    marginBottom: 25,
  },
  closeButton: {
    flexShrink: 0,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  text: {
    margin: '20px 0',
  },
});
export default connect(
  (state: ICombinedReducers) => ({
    focus: state.common.windowFocus,
    opened: state.common.settingsPopup,
    user: state.common.user,
    admin: state.common.admin,
    catalogCountries: state.common.user?.catalogCountries,
  }),
  (dispatch: DispatchType) => ({
    loadUserInfo: () => dispatch(CommonActions.loadUserInfo()),
    close: () => dispatch(CommonActions.setPopup(EPopup.addInfluencer, false)),
  })
)(AddInfluencer);
