// Css
import ReactDOM from 'react-dom/client';
import * as React from 'react';

import '@src/assets/img/icons/favicon.ico';
import 'perfect-scrollbar/css/perfect-scrollbar.css';
import '@src/assets/scss/vendor.scss';
import '@src/assets/scss/app.scss';

// Js
import * as Fullstory from '@fullstory/browser';
import { IAdmin, IUser } from 'common';
import { Root as App } from './Root';
import { datadogRum } from '@datadog/browser-rum';

import Tex from './locales';
import './utils';

import {
  DATADOG_BROWSER_RUM_APPID,
  DATADOG_BROWSER_RUM_CLIENTID,
  NODE_ENV,
  GA_KEY
} from './config';
import { GAnalytics } from './utils/GoogleAnalytics';
import { CommonState } from './types/redux/common';

declare let module: any;

window.Tether = require('tether');

if (module.hot) {
  module.hot.accept();
}
Tex((err, t) => {
  if (err) { console.error('tex', err); }
  window.T = t;

  window.loadLiveChat = loadLiveChat;

  window.setTracking = function (user: IUser, admin: IAdmin) {
    // Datadog Tracking
    if (DATADOG_BROWSER_RUM_APPID && DATADOG_BROWSER_RUM_CLIENTID) {
      datadogRum.init({
        applicationId: DATADOG_BROWSER_RUM_APPID,
        clientToken: DATADOG_BROWSER_RUM_CLIENTID,
        site: 'datadoghq.eu',
        service: 'adalong-app',

        sessionSampleRate: 100, //How many sessions (in %) we want to track
        sessionReplaySampleRate: 100, //How many sessions (in %) we want to track for replay
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: 'mask-user-input'
      });
      datadogRum.setUser({
        id: user._id,
        isAdmin: admin ? true : false,
      })
      datadogRum.startSessionReplayRecording();
    }
    else {
      console.warn('DataDog Tracking disabled: Missing APP ID and/or CLIENT ID');
    }
    if (NODE_ENV === 'production') {
      // Google analytics
      window.analytics = new GAnalytics(GA_KEY, user);

      // Fullstory tracking
      const adminPrefix = admin ? `Admin ${admin.username} as ` : '';
      const userName = `${adminPrefix}${user.displayName}`;
      const userId = admin ? admin._id : user._id;
      Fullstory.init({ orgId: '851C8' });
      Fullstory.identify(userId, {
        name: userName,
        email: user.email,
        admin: !!admin,
      });
    }
  };

  // Start react
  const rootElement = document.getElementById('root');
  if (rootElement) {
    const root = ReactDOM.createRoot(rootElement);
    root.render(<App />);
  }
});

// load hubspot live chat
function loadLiveChat(user: CommonState['user'], token: string) {
  window.hsConversationsSettings = window.hsConversationsSettings || {};
  window.hsConversationsSettings.identificationEmail = user?.email;
  window.hsConversationsSettings.identificationToken = token;
  window.HubSpotConversations?.widget.load();
}
