import React from 'react';
import { ADALONG_COLORS } from '@src/styles';
import { style } from 'typestyle';

interface Props {
  childrenRender: (color: string) => JSX.Element;
}

export interface InheritProps {
  selected?: boolean;
  onClick: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => any;
}

export function ShopThisLookIcon({ onClick, selected, childrenRender }: Props & InheritProps) {
  const strokeColor = selected ? ADALONG_COLORS.FLASH_BLUE : ADALONG_COLORS.DARKER_GRAY;

  return (
    <svg className={svgClass} viewBox='0 0 120 70' onClick={onClick}>
        {childrenRender(strokeColor)}
    </svg>
  );
}

const svgClass = style({
  cursor: 'pointer',
  width: 60,
  height: 45,
});
