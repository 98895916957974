/*
**	Share Action Creators
*/

import { DisplayPreviewContentView, DisplayPreviewView, OldApiTypes } from 'common';
import { ActionCreator, AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';

import {
  TShareCollection, SharePopupInfo, ShareNetworkType, ShareInfo,
} from '@src/types/redux/share';
import { InfoInsert } from '@src/types/VideoMaker';
import { bigStore } from '../store';
import { api } from '../services/api';
import * as ACTIONS from './_types';

const { dispatch } = bigStore;

export function loadingCollection(id: string, loading: boolean) {
  return { type: ACTIONS.LOADING_SHARE_COLLECTION, id, loading };
}

export function loadCollectionSuccess(collection: TShareCollection, id: string) {
  return { type: ACTIONS.LOAD_SHARE_COLLECTION, collection, id };
}

export function openCollection(id: string, open = true) {
  return { type: ACTIONS.OPEN_SHARE_COLLECTION, id, open };
}

export function openSharePrompt(info: ShareInfo) {
  return { type: ACTIONS.SET_SHARE_PROMPT, info };
}

export function closeSharePrompt() {
  return { type: ACTIONS.SET_SHARE_PROMPT, info: null };
}

export function openSharePopup(target: ShareNetworkType, info: SharePopupInfo) {
  return { type: ACTIONS.OPEN_SHARE_POPUP, target, info };
}

export function closeSharePopup() {
  return { type: ACTIONS.CLOSE_SHARE_POPUP };
}

export function publishing() {
  return { type: ACTIONS.PUBLISHING, publishing };
}

export function publishedFailed(error: Error) {
  return { type: ACTIONS.PUBLISH_FAILED, error };
}

export function setDisplayPreview(displayPreview: DisplayPreviewView) {
  return { type: ACTIONS.SET_DISPLAY_PREVIEW, displayPreview };
}

export function setInsertedDisplayPreviewContent(content: DisplayPreviewContentView) {
  return { type: ACTIONS.SET_INSERTED_DISPLAY_PREVIEW_CONTENT, content };
}

/* Async */

export function publish(data: OldApiTypes.Share.IBodyPublish) {
  dispatch(publishing());
  return api.publish(data)
    .then((res) => {
      dispatch(publishing());
      if (res !== 'ok') {
        throw new Error(res as string);
      }
    });
}

export function loadDisplayPreview(): ActionCreator<any> {
  return async (disp: ThunkDispatch<Promise<void>, {}, AnyAction>) => api.getDisplayPreview().then((displayPreview) => {
    disp(setDisplayPreview(displayPreview));
  });
}

export function insertItemInToDisplay(info: InfoInsert, position: number): Promise<DisplayPreviewContentView> {
  return async function (disp: ThunkDispatch<Promise<void>, {}, AnyAction>) {
    return api.insertItemInToDisplay(info, position).then((content) => {
      disp(setInsertedDisplayPreviewContent(content));
    });
  } as any;
}
