import React from 'react';
import { style } from 'typestyle';

export function DotSeparator() {
  return (
    <div className={separatorClass}>
      <span>·</span>
    </div>
  );
}

const separatorClass = style({
  display: 'inline-block',
  margin: '0 5px',
  fontSize: 14,
  verticalAlign: 'middle',
  fontWeight: 'bold',
});
