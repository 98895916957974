type PredicateFunction<T> = (item: T) => boolean;

export class ArrayHelper {
  /**
     * Remove an element from the array
     * /!\ This mutate the original array
     * @param {*} array The array to modify
     * @param {*} identity Item to remove or function to call per iteration to determine the item to remove.
     */
  public static removeOneFrom<T>(
    array: T[] = [],
    identity: PredicateFunction<T> | T,
  ): T[] {
    let predicate: PredicateFunction<T> = (v) => v === identity;
    if (typeof identity === 'function') {
      predicate = identity as PredicateFunction<T>;
    }
    const index = array.findIndex(predicate);
    if (index > -1) {
      array.splice(index, 1);
    }
    return array;
  }

  /**
     * Remove duplicates whose uniqueness is defined by the uniqKey
     * function which should return the value to compare
     * WARNING: A wrong function can remove false duplicates
     */
  public static uniqBy<T, K>(array: T[], uniqKey: (v: T) => K = ((v: T) => v as unknown as K)) {
    const keys: K[] = []; let u: K;
    return (array || []).filter((v) => (!keys.includes(u = uniqKey(v)) ? !!keys.push(u) : false));
  }

  public static filterNulls<T>(array: (T | undefined | null)[]): T[] {
    return array.filter(<R>(x: R | undefined | null): x is R => x !== undefined && x !== null);
  }

  /**
     * Compare two arrays and return true if they are considered equals
     * The comparator should return true if two values are considered equal
     */
  public static compareArrays<T>(a: T[], b: T[], comparator: (a: T, b: T) => boolean) {
    if (a.length !== b.length) {
      return false;
    }
    const minLength = Math.min(a.length, b.length);
    for (let i = 0; i < minLength; i++) {
      if (!comparator(a[i], b[i])) {
        return false;
      }
    }
    return true;
  }

  /**
     * Convert an array into an object
     * @param arr Array to convert
     * @param key Function to get the value to use as keu
     */
  public static mapToObject<T>(
    arr: T[],
    key: (elem: T) => string,
  ): { [key: string]: T } {
    return Object.fromEntries(
      arr.map((a) => [key(a), a]),
    );
  }
}
