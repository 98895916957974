import React, { PropsWithChildren } from 'react';
import { stylesheet } from 'typestyle';
import { InfiniteScroller } from '../InfiniteScroller';
import { PerfectScrollbar } from '../PerfectScrollbar';

interface Props {
  noMore: boolean;
  fetchMore: () => Promise<void>;
}

export function ProductGroupScroller(props: PropsWithChildren<Props>) {
  return (
    <InfiniteScroller
      load={props.fetchMore}
      noMore={props.noMore}
      container={(props, children) => (
        <PerfectScrollbar className={sheet.searchResult} {...props}>
          {children}
        </PerfectScrollbar>
      )}
    >
      <div className={sheet.productGroupContainer}>{props.children}</div>
    </InfiniteScroller>
  );
}

const sheet = stylesheet({
  searchResult: {
    textAlign: 'center',
    position: 'relative',
    overflow: 'hidden',
    flex: 1,
    marginRight: 5,
  },
  productGroupContainer: {
    marginTop: 20,
  },
});
