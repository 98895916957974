import { ImageResolverResponse } from '@src/types/Image';

export class ImageResolver {
  /**
     * Load an image from a url and returns
     * an Image element and its redirected URL
     */
  public static load(url: string): Promise<ImageResolverResponse> {
    return fetch(url).then((response) => response.url).then((newURL) => new Promise((resolve, reject) => {
      const img = new Image();
      img.onload = function () {
        resolve({ imgElement: img, finalUrl: newURL });
      };
      img.onerror = reject;
      img.crossOrigin = 'Anonymous';
      img.src = url;
    }));
  }
}
