import { useInfiniteQuery } from 'react-query';
import { QUERY_KEYS } from '@src/query';
import { getRemoteConversations } from './api';
import { ApiTypes } from 'common';

/**
 * Return the list of conversations, with up to date data from Meta API.
 */
export const useQueryRemoteConversations = () => {
  return useInfiniteQuery<ApiTypes.ResGetRemoteConversations>(
    QUERY_KEYS.remoteConversations(),
    ({ pageParam: cursor }) => getRemoteConversations(cursor)
  );
};
