import React from 'react';
import { ShopThisLookIcon, InheritProps } from './ShopThisLook';

export function NoneIcon(props: InheritProps) {
  return (
    <ShopThisLookIcon
      {...props}
      childrenRender={(color: string) => (
      <svg width="120" height="70" viewBox="0 0 120 70" fill="none" xmlns="http://www.w3.org/2000/svg">
      <rect x="2" y="2" width="116" height="66" rx="8" fill="#343434" stroke={color} stroke-width="4"/>
      <circle cx="60" cy="35" r="19" fill="#343434" stroke="#D9D9D9" stroke-width="4"/>
      <line x1="74.2695" y1="23.5455" x2="46.2695" y2="46.5455" stroke="#D9D9D9" stroke-width="4"/>
      </svg>
      )}
    />
  );
}





