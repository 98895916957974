import React, { useState } from 'react';
import { SectionFolder } from '@src/components/SectionFolder';
import { SectionTitle } from '@src/components/SectionTitle';
import { SourcesIcon } from '../../Icons/DropDownIcons/Sources';
import { SectionProps } from '../types/Settings';
import { ThemeProvider, Tooltip } from '@mui/material';
import { InfoSvgIcon } from '@src/components/SvgIcons/Info';
import { Hide } from '@src/components/Hide';
import { AutoComplete, RenderTag } from '@src/components/AutoComplete';
import LocalizedStrings from 'react-localization';
import { CollectionView, ProductView, WidgetView } from 'common';
import { AutoCompleteOption } from '@src/types/AutoComplete';
import { getSourceType } from '@src/pages/Widget/utils/sourceType';
import { ArrayHelper } from '@src/services/helpers/array';
import { style } from 'typestyle';
import { ADALONG_COLORS } from '@src/styles';
import { LabelPill } from '@src/components/LabelPill';
import { useQueryMediaProduct } from '@src/queries/media/useQueryMediaProduct';
import { useQueryProducts } from '@src/queries/products/useQueryProducts';
import { useQueryCollections } from '@src/queries/collections/useQueryCollections';
import { SECTION_TITLE_SIZE } from '../utils/variables';
import { SectionContent } from '../subcomponents/SectionContent';
import { materialTheme } from '../subcomponents/Theme';
import { createChangeSettings } from '../utils/createChangeSettings';

interface SourcesProps extends SectionProps {
  remoteWidget: WidgetView;
}

export const Sources = ({
  opened,
  onOpen,
  editedWidget,
  setEditedWidget,
  remoteWidget,
}: SourcesProps) => {
  type SourceField = 'product_list' | 'collection_list';
  const [searchProductValue, setSearchProductValue] = useState('');
  const sourceType = getSourceType(remoteWidget);
  const isCollectionType = sourceType === 'collection';
  const isProductType = sourceType === 'product';
  const changeSettings = createChangeSettings(editedWidget, setEditedWidget);

  // search products
  const { data: productsResult, isLoading: isSearchingProducts } = useQueryMediaProduct(
    searchProductValue,
    { enabled: isProductType },
  );

  // get products to translate selected options (ids to titles)
  const { data: selectedProducts = [] } = useQueryProducts(
    editedWidget.settings?.default_sources?.product_list || [],
    { enabled: isProductType },
  );

  const selectedProductsMap = ArrayHelper.mapToObject(selectedProducts, (c) => c.id);

  // get all collections
  const { data: allCollections, isLoading: isLoadingCollections } = useQueryCollections();
  const collectionsMap = ArrayHelper.mapToObject(allCollections || [], (c) => c._id);

  function renderCollectionTag(id: string, onRemove: Parameters<RenderTag>[1]) {
    let label: string = '';

    if (allCollections) {
      label = collectionsMap[id]?.name;
    }

    if (!label) {
      return (
        <Tooltip title={lang.tooltips.unauthorizedCollection} key={id}>
          <span>{renderTag(id, lang.fields.unauthorizedCollectionName, onRemove)}</span>
        </Tooltip>
      );
    }
    return renderTag(id, label, onRemove);
  }

  function renderProductTag(
    id: Parameters<RenderTag>[0],
    onRemove: Parameters<RenderTag>[1],
  ): JSX.Element {
    return renderTag(id, `${id} - ${selectedProductsMap[id]?.title || ''}`, onRemove);
  }

  const renderTag = (key: string, label: string, onRemove: () => void) => (
    <LabelPill onRemove={onRemove} key={key}>
      {label}
    </LabelPill>
  );

  function handleAddSource(field: SourceField) {
    return (id: string) => {
      changeSettings({
        default_sources: {
          ...(editedWidget.settings.default_sources || {}),
          [field]: [...(editedWidget.settings.default_sources?.[field] || []), id],
        },
      });
    };
  }

  function handleRemoveSource(field: SourceField) {
    return (id: string) => {
      const sourceCopy = [...(editedWidget.settings.default_sources?.[field] || [])];
      ArrayHelper.removeOneFrom(sourceCopy, id);
      changeSettings({
        default_sources: {
          ...(editedWidget.settings.default_sources || {}),
          [field]: sourceCopy,
        },
      });
    };
  }

  function productsToOptions(products: ProductView[] = []): AutoCompleteOption[] {
    return products.map((s) => ({ id: s.id, label: `${s.id} ${s.title}` }));
  }

  function collectionsToOptions(collections: CollectionView[] = []): AutoCompleteOption[] {
    return collections.map((s) => ({ id: s._id, label: s.name }));
  }

  const onAddCollection = handleAddSource('collection_list');
  const onAddProduct = handleAddSource('product_list');
  const onRemoveCollection = handleRemoveSource('collection_list');
  const onRemoveProduct = handleRemoveSource('product_list');

  return (
    <SectionFolder
      title={
        <SectionTitle size={SECTION_TITLE_SIZE}>
          <SourcesIcon />
          {lang.sources}
        </SectionTitle>
      }
      opened={opened}
      afterTitle={
        <Tooltip
          title={isCollectionType ? lang.tooltips.collectionInfo : lang.tooltips.productInfo}
          placement='top'
        >
          <span className={infoSourceClass}>
            <InfoSvgIcon />
          </span>
        </Tooltip>
      }
      onOpen={onOpen}
    >
      <SectionContent>
        <ThemeProvider theme={materialTheme}>
          <Hide hide={!isCollectionType}>
            <AutoComplete
              id='collections'
              title={lang.fields.collectionPlaceHolder}
              loading={isLoadingCollections}
              onAdd={onAddCollection}
              onRemove={onRemoveCollection}
              options={collectionsToOptions(allCollections)}
              selected={editedWidget.settings.default_sources?.collection_list}
              renderTag={renderCollectionTag}
            />
          </Hide>
          <Hide hide={!isProductType}>
            <AutoComplete
            id='products'
              title={lang.fields.productsPlaceHolder}
              loading={isSearchingProducts}
              onAdd={onAddProduct}
              onRemove={onRemoveProduct}
              onInputChange={setSearchProductValue}
              options={productsToOptions(productsResult)}
              selected={editedWidget.settings.default_sources?.product_list}
              renderTag={renderProductTag}
            />
          </Hide>
        </ThemeProvider>
      </SectionContent>
      <Hide hide={!isProductType}>
        <SectionContent>
          <ThemeProvider theme={materialTheme}>
            <AutoComplete
              id='collections-filter'
              title={lang.fields.collectionPlaceHolder}
              loading={isLoadingCollections}
              onAdd={onAddCollection}
              onRemove={onRemoveCollection}
              options={collectionsToOptions(allCollections)}
              selected={editedWidget.settings.default_sources?.collection_list}
              renderTag={renderCollectionTag}
            />
          </ThemeProvider>
        </SectionContent>
      </Hide>
    </SectionFolder>
  );
};

const lang = new LocalizedStrings({
  en: {
    sources: 'Sources',
    tooltips: {
      collectionInfo:
        "If you don't specify any collections when you call the widget, these will be displayed as a fallback",
      productInfo:
        "If you don't specify any products when you call the widget, these will be displayed as a fallback",
      collectionFilter:
        'Use this field if you want to display UGCs that are only present in the specified collection(s)',
      unauthorizedCollection:
        'To modify this collection, request access to the collection from the widget owner',
    },
    fields: {
      collectionPlaceHolder: 'choose a collection',
      unauthorizedCollectionName: 'Private collection',
      productsPlaceHolder: 'choose a product',
    },
  },
});

const infoSourceClass = style({
  marginLeft: 10,
  color: ADALONG_COLORS.LIGHT_GRAY,
});
