import React, { PropsWithChildren } from 'react';
import { PerfectScrollbar } from 'components/PerfectScrollbar';
import { style } from 'typestyle';

const LENGTH = 774;
const WIDTH = 375;

interface Props {
  landscape: boolean
  isMobile: boolean
}

export function Frame({
  landscape,
  isMobile,
  children,
}: PropsWithChildren<Props>) {
  const size = {
    width: landscape ? LENGTH : WIDTH,
    height: landscape ? WIDTH : LENGTH,
  };
  return (
    <div
      className={isMobile ? frameClass : undefined}
      data-landscape={landscape}
      style={isMobile ? { ...size } : undefined}
    >
      {isMobile ? renderIphone(landscape) : undefined}
      <PerfectScrollbar
        options={{ wheelPropagation: true }}
        className={isMobile ? mobileScreenClass : undefined}
        data-landscape={landscape}
      >
        {children}
      </PerfectScrollbar>
    </div>
  );
}

function renderIphone(landscape: boolean) {
  const transform = landscape ? 'rotate(-90deg) translate(199px, 200px)' : '';
  const style = {
    width: WIDTH,
    height: LENGTH,
    transform,
  };
  return (
    <svg style={style} viewBox="0 0 725 1469">
      <g stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-897.000000, -168.000000)">
          <g transform="translate(897.000000, 168.000000)">
            <rect fill="#383A41" x="0" y="0" width="725" height="1469" rx="106" />
            <rect fill="#18181C" x="10" y="9" width="706" height="1452" rx="100" />
            <path
              d="M107,40 C130.333333,40 153.666667,40 177,40 C180.144043,40 184.507966,41.7774397 185.088456,49.0544913 C185.668945,56.331543 191.66241,90 218,90 C389.333333,90 486,90 508,90 C535.472007,90 540.189941,55.3422852 541.008268,48.9503434 C541.826595,42.5584016 546.150391,40 552.460938,40 C574.640625,40 596.820312,40 619,40 C654.346224,40 683,68.653776 683,104 L683,1362 C683,1397 632,1426 619,1426 L107,1426 C71.653776,1426 43,1397.34622 43,1362 L43,104 C43,68.653776 71.653776,40 107,40 Z"
              fillOpacity="0.8"
              fill="#383A41"
            />
            <rect fill="#383A41" x="319" y="49" width="90" height="14" rx="7" />
            <circle fill="#383A41" cx="441" cy="56" r="12" />
            <rect fill="#18181C" x="248" y="1403" width="230" height="9" rx="4.5" />
          </g>
        </g>
      </g>
    </svg>
  );
}

const frameClass = style({
  width: 375,
  margin: 'auto',
  position: 'relative',
  paddingBottom: 15,
});
const mobileScreenClass = style({
  position: 'absolute',
  top: 60,
  left: 35,
  bottom: 60,
  right: 35,
  overflow: 'hidden',
  $nest: {
    '&[data-landscape=true]': {
      top: 35,
      left: 60,
      bottom: 35,
      right: 60,
    },
  },
});
