import React, { useState, useCallback, useEffect } from 'react';
import { VideoPlayer } from '@src/components/VideoPlayer';
import { style } from 'typestyle';
import { DisplayableContent } from '@src/types/Media';
import { Dimensions } from '@src/types/Dimensions';
import { ADALONG_COLORS } from '@src/styles';
import { MediaType } from '@adalong/schemas';
import TextContent from './TextContent';

interface Props {
  media: DisplayableContent & { _id: string }
  minWidth?: number
  maxWidth?: number
  overlay?: JSX.Element | null
  overlayConfig?: {
    displayOnHover?: boolean // Display only when hovering over
  }
  onContentWidth: (width: number | null) => void
}

/**
 * Display image or video
 */
export function MediaContentView({
  media,
  minWidth,
  maxWidth,
  overlay,
  overlayConfig = {
    displayOnHover: false,
  },
  onContentWidth,
}: Props) {
  const [current, setCurrent] = useState<HTMLDivElement | null>(null);

  // Will trigger re-render when the ref change to give parent dimensions to getSize
  const onRefChange = useCallback((node: HTMLDivElement) => {
    setCurrent(node);
  }, []);

  const size = getSize(media.dimensions, current, { minWidth, maxWidth });
  useEffect(() => {
    if (size) {
      const width = (media.type === MediaType.Image ? size.width : maxWidth) || null;
      onContentWidth(width); // Give size to the parent
    }
  }, [size?.width]);

  return (
    <div
      className={mediaViewerClass}
      ref={onRefChange}
      style={!size ? { minWidth } : {}} // Display a black container while loading
      data-displayonhover={overlayConfig.displayOnHover}
    >
      {renderContent(media, maxWidth, overlay, size)}
    </div>
  );
}

function renderContent(
  media: DisplayableContent,
  maxWidth?: number,
  overlay?: JSX.Element | null,
  size?: Dimensions | null,
) {
  if (media.type === MediaType.Video) {
    return renderVideo(
      media,
      maxWidth,
      renderOverlay(overlay),
    );
  } if (media.type === MediaType.Image) {
    // Will not render the first, have to wait for the parent to be rendered first
    return renderImage(
      media.image_large,
      size,
      renderOverlay(overlay),
    );
  } if (media.type === MediaType.Text) {
    return renderText(
      media.caption!,
      renderOverlay(overlay),
      maxWidth,
    );
  }
  return null;
}

function renderText(
  caption: string,
  overlay?: JSX.Element | null,
  maxWidth?: number,
) {
  return (
    <>
      <TextContent caption={caption} style={{ maxWidth }} />
      {overlay}
    </>
  );
}

function renderImage(
  src: string | undefined,
  size?: Dimensions | null,
  overlay?: JSX.Element,
) {
  if (!size || !src) {
    return null;
  }

  return (
    <div
      className={containerClass}
      style={{ width: size.width, height: size.height }}
    >
      <img
        className={imageClass}
        src={src}
        onError={() => {}}
      />
      {overlay}
    </div>
  );
}

function renderVideo(
  media: DisplayableContent,
  maxWidth?: number,
  overlay?: JSX.Element,
) {
  return (
    <div
      className={containerClass}
      style={{ width: maxWidth, height: '100%' }}
    >
      <VideoPlayer media={media} />
      {overlay}
    </div>
  );
}

function renderOverlay(
  overlay: Props['overlay'],
) {
  return (
    <div
      className={overlayClass}
    >
      {overlay}
    </div>
  );
}

/**
 * Try to find the best size for the content
 * using the parent size and the max/min constraints
 */
function getSize(
  dimensions: Dimensions | undefined,
  parent: HTMLDivElement | null,
  { minWidth, maxWidth }: Pick<Props, 'minWidth' | 'maxWidth'>,
): Dimensions | null {
  if (!parent || !dimensions) {
    return null;
  }

  const {
    clientHeight: parentHeight,
  } = parent;
  const imageRatio = dimensions.width / dimensions.height;
  let parentWidth = maxWidth || 0; // If a maxWidth is given, use max space as possible

  if (parentWidth === 0) {
    // No given width, with imagine it based on the image ratio
    parentWidth = parentHeight * imageRatio;
    if (minWidth && parentWidth < minWidth) {
      parentWidth = minWidth;
    }
  }

  const parentRatio = parentWidth / parentHeight;
  let size: Dimensions | null = null;

  if (imageRatio > parentRatio) {
    size = { width: parentWidth, height: parentWidth / imageRatio };
  } else {
    size = { width: parentHeight * imageRatio, height: parentHeight };
  }
  return size;
}

const overlayClass = style({
  position: 'absolute',
  top: 0,
  bottom: 0,
  left: 0,
  right: 0,
  pointerEvents: 'none',
  zIndex: 10,
  $nest: {
    '& > *': {
      pointerEvents: 'all',
    },
  },
});

const mediaViewerClass = style({
  backgroundColor: ADALONG_COLORS.DARK_BLACK,
  height: '60%',
  width: '100%',
  overflow: 'hidden',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
  position: 'relative',
  $nest: {
    '&[data-displayonhover="true"]': {
      $nest: {
        [`& .${overlayClass}`]: {
          transition: 'visibility 0s linear 150ms, opacity 150ms',
          visibility: 'hidden',
          opacity: 0,
        },
        [`&:hover .${overlayClass}`]: {
          visibility: 'visible',
          opacity: 1,
        },
      },
    },
  },
});

const containerClass = style({
  display: 'inline-block',
  margin: 'auto',
  position: 'relative',
  $debugName: 'container',
});

const imageClass = style({
  width: '100%',
  height: '100%',
});
