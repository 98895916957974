export const tree2string = (input: Record<string, any> | string, prefix = '') => {
  const DELIM = '.';
  if (typeof input === 'string') {
    return prefix;
  }
  Object.keys(input).map((key) => {
    input[key] = tree2string(input[key], prefix + ([key, prefix].indexOf('') >= 0 ? '' : DELIM) + key);
  });
  return input;
};

// Login
export const LOGIN_SUBMITTING = 'LOGIN_SUBMITTING';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const LOGOUT = 'LOGOUT';
export const LOGIN_FIELD_CHANGED = 'LOGIN_FIELD_CHANGED';
export const INIT_LOGOUT = 'INIT_LOGOUT';

// GIFMaker
const reduxActions = {
  GIF: {
    MEDIAS: {
      LOAD_ALL: '',
      LOADED: '',
      SELECT_COLLECTION: '',
    },
    BRANDCONTENTS: {
      LOAD_ALL: '',
      LOADED: '',
      STORE: '',
    },
    EDITOR: {
      SELECT_CURRENT_IMAGE: '',
      CHANGE_RATIO: '',
      CHANGE_DURATION: '',
      RESIZE_IMAGE: '',
      WIDTH_UPDATE: '',
      DUPLICATE_FRAME: '',
      EDIT_VIDEO_MAKER: '',
      CHANGE_PAGE: '',
      WAS_SAVED: '',
      SET_RESOLVED_IMAGES: '',
      UPDATE_GIF: '',
    },
    LOAD_LIST: '',
    LOAD_COLLECTION_SUCCESS: '',
    LOADING_COLLECTION: '',
    OPEN_COLLECTION: '',
    SET_COLLECTION: '',
    SET_COLLECTION_CONTENT: '',
    ADD_BRANDCONTENT_TO_COLLECTION: '',
    REMOVE_BRANDCONTENT_FROM_COLLECTION: '',
    SET_VIDEOMAKER_LIST: '',
    SET_LOADING: '',
    SET_LOADING_EDIT: '',
  },
  RIGHTS_REQUESTS: {
    SET_RIGHTSTEMPLATE_FR: '',
  },
  COLLECTIONS: {
    SET_COLLECTIONS: '',
    SET_COLLECTION_LOADING: '',
    SET_CURRENT_COLLECTION_INFO: '',
    SET_RIGHTS_STATUS_FILTER: '',
    SET_CURRENT_COLLECTION_TOTAL_RESULTS: '',
    SET_EDITED_CURRENT_COLLECTION: '',
    SET_CURRENT_COLLECTION_NO_MORE: '',
    RESET_CURRENT_COLLECTION: '',
    RESET_CURRENT_COLLECTION_MEDIAS: '',
    ADD_COLLECTION_MEDIAS: '',
    SET_COLLECTION_LOADED: '',
    SET_COLLECTIONS_SORTBY: '',
    COLLECTION_DELETED: '',
    SET_UNCOLLECTED_COLLECTION_ELEMENT: '',
    SET_COLLECTION_CONTENT_NUMBER: '',
  },
  DISCOVER: {
    SET_LIBRARY_REFRESH_TIME: '',
    SET_LIBRARY: '',
    ADD_MEDIAS_TO_LIBRARY: '',
    NO_MORE: '',
    SET_LIBRARY_LOADED: '',
    SET_TOTAL_MEDIAS: '',
    SET_TOTAL_EXACT_MEDIAS: '',
    SET_FILTERS_UNFOLD: '',
    SET_SEARCHFILTER_SUGGESTION: '',
    SAVE_SEARCHFILTER_VALUE: '',
    SET_ML_LABELS_COMPLETION: '',
    SET_MEDIA_NOT_FOUND: '',
    SET_MASONRY_IMAGES_LOADED: '',
    SET_LIBRARY_CURSOR: '',
  },
  COMMON: {
    SET_FACEBOOK_PAGES: '',
    SET_FACEBOOK_AD_ACCOUNTS: '',
    SET_POPUP: '',
    SET_POPUP_LOADING: '',
    SET_USER_INFO: '',
    SET_ADMIN_INFO: '',
    SET_CONFIG: '',
    SET_OAUTH: '',
    ADD_ALERT: '',
    REMOVE_ALERT: '',
    SET_URL_PARAM: '',
    WINDOW_RESIZE: '',
    SET_WINDOW_FOCUS: '',
    MOUSE_CLICK: '',
    SELECT_MEDIA: '',
    UNSELECT_MEDIA: '',
    REMOVE_ALL_SELECTED: '',
    COLLECT_OVERLAY: '',
    SET_CREATOR_COUNTRIES_OVERLAY: '',
    SET_GROUP_MENTION_PAGES: '',
    SET_GROUP_MENTION_USERS: '',
  },
  MEDIA: {
    SET_MEDIAS: '',
    SET_DIMENSIONS: '',
  },
  PRODUCT: {
    SET_PRODUCTS: '',
  },
  WIDGET: {
    SET_WIDGET_LIST: '',
    REMOVE_WIDGET: '',
    SET_WIDGET: '',
    SET_WIDGET_PREVIEW_SETTINGS: '',
  },
};

export const REDUX_ACTIONS: Readonly<typeof reduxActions> = tree2string(reduxActions) as any;

// Share
export const LOAD_SHARE_COLLECTION = 'LOAD_SHARE_COLLECTION';
export const LOADING_SHARE_COLLECTION = 'LOADING_SHARE_COLLECTION';
export const OPEN_SHARE_COLLECTION = 'OPEN_SHARE_COLLECTION';
export const SET_SHARE_PROMPT = 'OPEN_SHARE_PROMPT';
export const OPEN_SHARE_POPUP = 'OPEN_SHARE_POPUP';
export const CLOSE_SHARE_POPUP = 'CLOSE_SHARE_POPUP';
export const PUBLISHING = 'PUBLISHING';
export const PUBLISH_FAILED = 'PUBLISH_FAILED';
export const OPEN_POPUP = 'OPEN_POPUP';
export const SET_DISPLAY_PREVIEW = 'SET_DISPLAY_PREVIEW';
export const SET_INSERTED_DISPLAY_PREVIEW_CONTENT = 'SET_INSERTED_DISPLAY_PREVIEW_CONTENT';
