import React, { useEffect } from 'react';
import { Library } from '@src/pages/Discover/components/Library';
import { MediaView } from 'common';
import { useQueryUserConfig } from '@src/queries/users/useQueryUserConfig';
import MediaPreview from '../../components/Media/MediaPreview';

interface Props {
  mediaList: MediaView[];
  loadMoreMedia: () => Promise<any>;
  selection?: MediaView[];
  noMore: boolean;
  libraryLoaded?: boolean;
  /**
   * Hide a media when the image is not available
   */
  hideOnImageFailed: boolean;
  /**
   * Button to access to media actions
   */
  onImageError?: (mediaID: string) => void;
  onUnlinkProduct?: (mediaId: string) => void;
  setSelection?: (selection: MediaView[]) => void;

  /**
   * Button to access to media actions
   */
  renderMediaActionButton?: (media: MediaView) => React.ReactNode;
  /**
   * A callback to render a shortcut button to the main action
   * depending on the context
   * failed indicate if the media failed to load
   */
  renderShortcutActionButton: (media: MediaView, failed: boolean) => React.ReactNode;
}

/**
 * Specialize the Library component to be used with MediaElement
 */
export const MediaLibrary = ({
  mediaList,
  libraryLoaded = true,
  noMore,
  selection = [],
  hideOnImageFailed,
  loadMoreMedia,
  onImageError,
  onUnlinkProduct,
  setSelection,
  renderMediaActionButton = () => null,
  renderShortcutActionButton,
}: Props) => {
  const { data: user } = useQueryUserConfig();

  useEffect(() => {
    if (setSelection) {
      const stillVisible = selection.filter((s) => isMediaIn(s, mediaList));
      setSelection(stillVisible);
    }
  }, [mediaList.map((m) => m._id).join('')]);

  return (
    <Library
      loadMore={loadMoreMedia}
      elements={mediaList.map((media) => ({
        node: (
          <MediaPreview
            key={media._id}
            media={media}
            isViewedByGuest={user === undefined}
            onSelect={user && setSelection ? handleSelection : undefined}
            onImageError={() => onImageError && onImageError(media._id)}
            hideOnImageFailed={hideOnImageFailed}
            selected={isMediaIn(media, selection)}
            actionButton={renderMediaActionButton(media)}
            renderShortcutActionButton={renderShortcutActionButton}
          />
        ),
        dimensions: media.dimensions,
      }))}
      libraryLoaded={libraryLoaded}
      noMore={noMore}
    />
  );

  function handleSelection(media: MediaView) {
    const index = selection.findIndex((m) => m._id === media._id);
    const output = [...selection];
    if (index === -1) {
      output.push(media);
    } else {
      output.splice(index, 1);
    }
    setSelection?.(output);
  }
};

function isMediaIn(media: MediaView, mediaList: MediaView[]): boolean {
  return !!mediaList.find((m) => media._id === m._id);
}
