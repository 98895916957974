import React, { PropsWithChildren, useEffect, useRef, useState, useCallback } from 'react';
import { AdalongWidgetInstance, initializeAdalongWidget } from '@adalong/widget';
import { WidgetView } from 'common';
import { PerfectScrollbar } from 'components/PerfectScrollbar';
import { classes, style } from 'typestyle';
import { ADALONG_COLORS, ThemeStyle } from '@src/styles';
import { Column, Row } from '@src/components/Utils';
import LocalizedStrings from 'react-localization';
import { BarelyVisibleText } from '@src/components/BarelyVisibleText';
import debounce from 'lodash.debounce';
import { API_URL, SESSION_STORAGE_KEYS } from '@src/config';
import { CollectionDataString, ProductDataString } from '../EditPage';
import { Frame } from './Frame';
import { LandscapeIcon } from './Icons/PlatformIcons/Landscape';
import { PortraitIcon } from './Icons/PlatformIcons/Portrait';
import { DesktopIcon } from './Icons/PlatformIcons/Desktop';

interface Props {
  token?: string;
  settings?: WidgetView['settings'];
  products?: ProductDataString;
  collections?: CollectionDataString;
  displaySelectMessage: boolean;
}

const PLATFORMS = ['desktop', 'mobile_portrait', 'mobile_landscape'] as const;
type Platform = (typeof PLATFORMS)[number];

export function Preview(props: Props) {
  const [isInitialized, setIsInitialized] = useState(false);
  const [minContentReachedError, setMinContentError] = useState(false);
  const scrollbarRef = useRef<PerfectScrollbar>(null);
  const previewRef = useRef<HTMLDivElement>(null);
  const adalongWidget = useRef<AdalongWidgetInstance>();

  const storedPlatform = window.sessionStorage.getItem(SESSION_STORAGE_KEYS.WIDGET_PLATFORM);
  const [platform, setPlatform] = useState<Platform>(
    PLATFORMS.find((p) => p === storedPlatform) || 'desktop',
  );

  useEffect(() => {
    if (!props.token) return;
    const token = props.token;

    const initWidget = async () => {
      try {
        const widget = await initializeAdalongWidget({
          token,
          config: {
            debug: {
              apiUrl: API_URL,
            },
          },
        });
        
        adalongWidget.current = widget;
        widget.onEvent('minContentNotReached', () => setMinContentError(true));
        widget.onEvent('widgetLoaded', () => setMinContentError(false));
        setIsInitialized(true);
      } catch (error) {
        console.error('Failed to initialize widget:', error);
      }
    };

    initWidget();

    return () => {
      setIsInitialized(false);
      adalongWidget.current = undefined;
    };
  }, [props.token]);

  useEffect(() => {
    if (!isInitialized) return;
    scrollbarRef.current?.scrollTop(0);
    loadCallback(props.settings);
  }, [props.settings, isInitialized]);

  useEffect(() => {
    if (!isInitialized || !previewRef.current) return;
    loadCallback();
  }, [previewRef.current, isInitialized]);

  const loadCallback = useCallback(
    async (settings?: Props['settings']) => {
      if (!previewRef.current) {
        console.warn('Widget not loaded because no referenced div found');
        return;
      }
      if (!adalongWidget.current || !isInitialized) {
        console.warn('Widget not yet initialized');
        return;
      }
      
      setMinContentError(false);
      await load(adalongWidget.current, previewRef.current, settings || props.settings);
    },
    [props.settings, isInitialized]
  );

  const changePlatform = (target: Platform) => {
    window.sessionStorage.setItem(SESSION_STORAGE_KEYS.WIDGET_PLATFORM, target);
    setPlatform(target);
    setTimeout(() => loadCallback(), 30);
  };

  const infoMessage = props.displaySelectMessage || minContentReachedError;
  const renderContainer = () => (
    <>
      {infoMessage && (
        <div className={infoMessageClass}>
          <BarelyVisibleText size='large'>
            {props.displaySelectMessage ? lang.noSource : lang.minContentError}
          </BarelyVisibleText>
        </div>
      )}
      <div
        ref={previewRef}
        className={classes({
          [hideWidget]: infoMessage,
        })}
        data-forcemobile={isMobile(platform)}
        data-products={props.products}
        data-collections={props.collections}
      />
    </>
  );

  return (
    <Column className={previewClass}>
      <Row className={previewOptionsClass}>
        <div className={platformClass}>
          <PlatformIconContainer>
            <DesktopIcon
              className={platformIconClass}
              selected={platform === 'desktop'}
              onClick={() => changePlatform('desktop')}
            />
          </PlatformIconContainer>
          <div className={buttonSeparationClass} />
          <PlatformIconContainer>
            <PortraitIcon
              className={platformIconClass}
              selected={platform === 'mobile_portrait'}
              onClick={() => changePlatform('mobile_portrait')}
            />
          </PlatformIconContainer>
          <div className={buttonSeparationClass} />
          <PlatformIconContainer>
            <LandscapeIcon
              className={platformIconClass}
              selected={platform === 'mobile_landscape'}
              onClick={() => changePlatform('mobile_landscape')}
            />
          </PlatformIconContainer>
        </div>
      </Row>
      <PerfectScrollbar
        options={{
          wheelSpeed: 0.6,
          minScrollbarLength: 30,
        }}
        ref={scrollbarRef}
      >
        <div className={widgetClass}>
          <Frame isMobile={isMobile(platform)} landscape={platform === 'mobile_landscape'}>
            {renderContainer()}
          </Frame>
        </div>
      </PerfectScrollbar>
    </Column>
  );
}

const load = debounce(
  async (
    adalongWidgetInstance: AdalongWidgetInstance,
    previewRefCurrent: HTMLDivElement,
    settings: Props['settings'],
  ) => {
    await adalongWidgetInstance.load(previewRefCurrent, settings);
  },
  120,
);

/**
 * Containerize icons to avoid colors to fade
 * due to svg childrens and align perfectly
 */
function PlatformIconContainer(props: PropsWithChildren<{}>) {
  return <Column className={platformIconContainerClass}>{props.children}</Column>;
}

function isMobile(platform: Platform) {
  return platform === 'mobile_portrait' || platform === 'mobile_landscape';
}

const lang = new LocalizedStrings({
  en: {
    noSource: 'Select a source to preview widget',
    minContentError: (
      <>
        <div>Minimum content to display not reached.</div>
        <div>In production, the widget would be hidden.</div>
      </>
    ),
  },
});

const previewClass = style({
  height: '100%',
});

const previewOptionsClass = style({
  $debugName: 'previewOptions',
  justifyContent: 'center',
  padding: '15px 0 35px',
  background: ThemeStyle.background.main,
  zIndex: 2,
  flexGrow: 0,
});

const platformClass = style({
  $debugName: 'platform',
  position: 'relative',
  background: 'transparent',
  display: 'inline-flex',
  flexDirection: 'row',
  borderRadius: 10,
  marginLeft: 30,
  marginTop: 10,
  height: 57,
});

const platformIconClass = style({
  cursor: 'pointer',
  transition: 'stroke 0.2s',
  height: 30,
  margin: '8px 15px',
});

const platformIconContainerClass = style({
  padding: '0 1px',
  justifyContent: 'center',
});

const buttonSeparationClass = style({
  display: 'block',
  width: 1,
  background: ADALONG_COLORS.LIGHT_GRAY,
});

const widgetClass = style({
  maxWidth: 950,
  margin: 'auto',
  $nest: {
    '.adl-wdgt-layout-carousel > .adl-wdgt-arrow': {
      color: 'white',
    },
  },
});

const infoMessageClass = style({
  textAlign: 'center',
  paddingTop: 20,
});

const hideWidget = style({
  display: 'none',
});
