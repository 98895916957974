import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import uniqid from 'uniqid';
import * as reduxHelpers from '@src/services/helpers/redux';
import { api } from '@src/services/api';
import FormControl from '@mui/material/FormControl';
import NativeSelect from '@mui/material/NativeSelect';
import PerfectScrollbar from 'react-perfect-scrollbar';
import Button from '@mui/material/Button';
import { style, classes } from 'typestyle';
import { Input } from '@src/components/Input';
import { TextArea } from '@src/components/TextArea';
import { CollectionView } from 'common';
import { ICombinedReducers } from '@src/types/redux';
import { VideoMakerHandlers } from '@src/types/VideoMaker';
import { useQueryCollections } from '@src/queries/collections/useQueryCollections';
import { useQueryCollectionMedias } from '@src/queries/collections/useQueryCollectionMedias';
import { addBrandContentToQueryData } from '@src/queries/brandcontent/useQueryBrandContents';
import { IUploadingFile } from './components/Upload/components/UploadingFile';
import BrandAssets from './components/BrandAssets';
import { CollectionList } from './components/CollectionList';
import translation from './translation';
import styles from './styles.scss';

const lang = reduxHelpers.loadTranslation(translation);

const MAX_IMG_BYTES = 8 * 10 ** 6; // 8MB
const MAX_VIDEO_BYTES = 1 * 10 ** 9; // 1GB

interface UploadingFiles {
  [key: string]: IUploadingFile | undefined
}

interface Props {
  handlers: VideoMakerHandlers
}

const titleStyle: React.CSSProperties = {
  paddingLeft: 35,
  fontSize: 15,
  fontWeight: 'bold',
  textAlign: 'left',
  paddingTop: 21,
  alignItems: 'center',
  display: 'flex',
};

const creatorsStyle: React.CSSProperties = {
  paddingLeft: 35,
  fontSize: 12,
  textAlign: 'left',
  paddingTop: 21,
  alignItems: 'center',
  display: 'flex',
};

export function LeftContainer(props: Props) {
  const [openedCollectionId, setOpenedCollectionId] = useState<string>();
  const [uploadingFiles, setUploadingFiles] = useState<UploadingFiles>({});

  const { data: collections = [] } = useQueryCollections({
    onSuccess: (collections) => collections.length && setOpenedCollectionId(collections[0]._id),
  });
  const { data: collectionMediaResponses, fetchNextPage, hasNextPage } = useQueryCollectionMedias(openedCollectionId || '', undefined, { enabled: !!openedCollectionId });

  const collectionMediaList = collectionMediaResponses?.pages.flatMap((r) => r.medias);

  const page = useSelector((state: ICombinedReducers) => state.video.page);
  const videoName = useSelector((state: ICombinedReducers) => state.video.gif.name);
  const videoDescription = useSelector((state: ICombinedReducers) => state.video.gif.description);
  const videoElems = useSelector((state: ICombinedReducers) => state.video.gif.elements);

  const handleDescriptionChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    props.handlers.onUpdateParams({ description: e.target.value });
  };

  const handleNameChange = (e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) => {
    props.handlers.onUpdateParams({ name: e.target.value });
  };

  const getCreators = () => {
    const creators = videoElems
      .map((media) => media.media)
      .filter((media) => !!media)
      .reduce((prev: string[], curr) => {
        if (!curr) { return prev; }
        const uname = curr.source?.user_name;
        if (uname && prev.indexOf(uname) === -1) {
          prev.push(uname);
        }
        return prev;
      }, []);
    return creators.map((uname) => `@${uname}`).join(' ');
  };

  const changeCollection = async (e: React.ChangeEvent<HTMLSelectElement>) => {
    e.preventDefault();
    const elem = collections.find((c) => c._id === e.target.value);
    if (elem) {
      setOpenedCollectionId(elem._id);
    }
  };

  /**
     * Create a new BrandContent
     */
  const uploadFile = async (file: File, collection?: CollectionView) => {
    if (!validUploadFile(file)) {
      return;
    }
    const id = uniqid();
    const localUrl = URL.createObjectURL(file);
    const brandContent = await api.uploadBrandContent(file, undefined, (percentage) => {
      const uploading: IUploadingFile = {
        localUrl,
        progress: percentage,
        id,
        collectionId: undefined,
      };
      setUploadingFiles({ ...uploadingFiles, [id]: uploading });
    }).catch((err) => {
      console.error('Upload Error', err);
      if (err && err.type === 'badformat') {
        reduxHelpers.addAlert({
          type: 'danger',
          text: lang.formatString(lang.error.badformat, {
            name: file.name,
            formats: err.data.formats.join(', '),
          }),
        });
      } else {
        reduxHelpers.addAlert({ type: 'danger', text: lang.error.upload });
      }
    });

    setUploadingFiles({ ...uploadingFiles, [id]: undefined });

    if (brandContent) {
      // optimistically update brand content query
      addBrandContentToQueryData(brandContent, undefined);
    }
  };

  const validUploadFile = (file: File) => {
    if (!file) { return; }
    if (/image/.test(file.type) && file.size > MAX_IMG_BYTES) {
      reduxHelpers.addAlert({
        type: 'danger',
        text: lang.formatString(lang.error.fileTooBig, {
          type: 'Images',
          max: MAX_IMG_BYTES / 10 ** 6,
          name: file.name,
          unit: 'MB',
        }),
      });
      return false;
    } if (/video/.test(file.type) && file.size > MAX_VIDEO_BYTES) {
      reduxHelpers.addAlert({
        type: 'danger',
        text: lang.formatString(lang.error.fileTooBig, {
          type: 'Videos',
          max: MAX_VIDEO_BYTES / 10 ** 9,
          name: file.name,
          unit: 'GB',
        }),
      });
      return false;
    }
    return true;
  };

  return (
    <PerfectScrollbar style={{ width: 350, borderRight: 'solid 1px #fff', overflowX: 'hidden' }}>
      <div className={classes(styles.shareCollections, containerClass)}>
        {
                page === 'create' ? (
                  <>
                    <div style={titleStyle}>
                      {window.T('createvideo.collections')}
                      <div style={{ flexGrow: 1, display: 'flex' }} />
                      <FormControl style={{ width: '60%' }}>
                        <NativeSelect
                          onClick={(e) => e.stopPropagation()}
                          value={openedCollectionId}
                          onChange={(e) => changeCollection(e)}
                          name="Collections"
                          inputProps={{ 'aria-label': 'Collections' }}
                          className="selectCollection"
                        >
                          {collections.reverse().map((col) => (
                            <option value={col._id} key={col._id}>
                              {col.name}
                            </option>
                          ))}
                        </NativeSelect>
                      </FormControl>
                    </div>
                    <CollectionList
                      content={collectionMediaList || []}
                      loadMedias={async (): Promise<void> => {
                        await fetchNextPage();
                      }}
                      noMore={!hasNextPage}
                    />
                    <p style={titleStyle}>{window.T('createvideo.brandcontents')}</p>
                    <BrandAssets
                      onUpload={(files: File[]) => files.forEach((file) => uploadFile(file))}
                      uploadingFiles={Object.values(uploadingFiles).filter((u): u is IUploadingFile => u !== undefined && !u.collectionId)}
                    />
                  </>
                ) : <></>
            }

        <p style={titleStyle}>{window.T('createvideo.name')}</p>
        <Input
          value={videoName}
          onChange={handleNameChange}
          className={inputClass}
        />
        <p style={titleStyle}>{window.T('createvideo.description')}</p>
        <TextArea
          value={videoDescription}
          onChange={handleDescriptionChange}
          className={inputClass}
        />
        <p style={titleStyle}>{window.T('createvideo.creators')}</p>
        <p style={creatorsStyle}>
          {' '}
          {getCreators()}
          {' '}
        </p>
        <Button style={{ marginLeft: 35 }} color="primary" variant="contained" onClick={() => { window.copyToClipboard(getCreators()); }}>copy</Button>
      </div>
    </PerfectScrollbar>
  );
}

const containerClass = style({
  paddingBottom: 15,
});

const inputClass = style({
  margin: 'auto',
  width: '80%',
  color: '#fff',
  fontSize: 13,
  textAlign: 'left',
  display: 'flex',
  backgroundColor: 'transparent',
});
