import React, { useState } from 'react';
import * as reduxHelpers from '@src/services/helpers/redux';
import { connect } from 'react-redux';
import { ICombinedReducers } from '@src/types/redux';
import { EPopup } from '@src/types/redux/common';
import { ApiError } from '@src/services/api';
import { AddToCollection } from '@src/types/Popups';
import { useMutationCollectionCreate } from '@src/queries/collections/useMutationCollectionCreate';
import { useMutationCollectionAddMedia } from '@src/queries/collections/useMutationCollection';
import { getCollectionLangError } from '@src/pages/Collection/collectionErrors';
import TextContent from '@src/components/Media/TextContent';
import { MediaType } from '@adalong/schemas';
import { Loader } from '@src/components/Loader';
import { collectionCategories } from '@src/pages/Collection/ListPage';
import { Autocomplete, MenuItem, Select, Typography } from '@mui/material';
import TextField from '@mui/material/TextField';
import { ADALONG_COLORS } from '@src/styles';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { useAddAlert } from '@src/fragments/Alerting/alertAtom';
import { MediaImage } from '../../../../../../components/MediaImage';
import * as CommonActions from '../../../../../../actions/common';
import styles from './styles.scss';
import { COLLECTION_NAME_MAX_LENGTH } from '../../../../../../config';

const mapStateToProps = (state: ICombinedReducers) => ({});

interface DispatchToProps {
  goadd?: () => void;
  close: () => void;
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  close: () => dispatch(CommonActions.setPopup(EPopup.collectOverlay, false)),
  goadd: () => dispatch(CommonActions.collectOverlay('add')),
});

interface OwnProps {
  media?: AddToCollection;
  onClose: Function;
}

type IProps = DispatchToProps & OwnProps;

interface IState {
  error: ApiError | null;
  loading: boolean;
}

function CreateCollection(props: IProps) {
  const [name, setName] = useState('');
  const [description, setDescription] = useState('');
  const [category, setCategory] = useState('My selection');
  const addAlert = useAddAlert();

  const {
    mutateAsync: createCollection,
    isLoading: isCreating,
    reset: resetCreate,
  } = useMutationCollectionCreate();
  const {
    mutateAsync: addMedias,
    isLoading: isAdding,
    reset: resetAddMedia,
  } = useMutationCollectionAddMedia();

  const onCreate = () => {
    createCollection(
      {
        name,
        description,
        category,
      },
      {
        onSuccess: (collection) => {
          addMedias(
            {
              collectionId: collection._id,
              mediaIds: props.media?.map((m) => m._id) || [],
            },
            {
              onSuccess: () => {
                props.close();
                reduxHelpers.addAlert({ text: 'collect.collectoverlay.success', type: 'success' });
              },
              onError: (error) =>
                addAlert({
                  text: getCollectionLangError(error),
                  close: resetAddMedia,
                }),
            },
          ).catch(console.error);
        },
        onError: (error) =>
          addAlert({
            text: getCollectionLangError(error),
            close: resetCreate,
          }),
      },
    ).catch(console.error);
  };

  const loadingClassName = isCreating || isAdding ? ' collect-frame__loading  ' : '';
  return (
    <div>
      {loadingClassName && (
        <div className='loader-centered'>
          <Loader size={40} />
        </div>
      )}
      <div className={`${styles.createCollection} collect-frame ${loadingClassName}`}>
        {props.media && (
          <div className='left'>
            <MediaImage src={props.media[0]?.cdn_image} />
          </div>
        )}
        {props.media && props.media[0].type === MediaType.Text && (
          <TextContent caption={props.media[0].caption} />
        )}
        <div className='right'>
          <div>
            {props.media && (
              <div className='title'>
                {window.T('collect.collectoverlay.create.title')}
                <div className='remove' onClick={() => (props.close ? props.close() : null)}>
                  <i className='material-icons notranslate'>close</i>
                </div>
              </div>
            )}
            {!props.media && (
              <Typography fontFamily={'Public Sans, sans-serif'} fontSize={19}>
                {window.T('collect.collectoverlay.create.title')}
              </Typography>
            )}
            <div className='inputs'>
              <div>
                <input
                  className='input'
                  name='collection-name'
                  placeholder='Name'
                  maxLength={COLLECTION_NAME_MAX_LENGTH}
                  value={name}
                  onChange={({ target }) => setName(target.value)}
                />
              </div>
              <div>
                <input
                  className='input'
                  name='collection-description'
                  placeholder='Description (optional)'
                  value={description}
                  onChange={({ target }) => setDescription(target.value)}
                />
              </div>
              <div>
                <Autocomplete
                  className='input'
                  options={collectionCategories}
                  id='disable-clearable'
                  disableClearable
                  popupIcon={<KeyboardArrowDownIcon sx={{ color: ADALONG_COLORS.LIGHT_WHITE }} />}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      variant='standard'
                      InputProps={{ ...params.InputProps, disableUnderline: true }}
                      placeholder={'Category'}
                      sx={{
                        input: {
                          color: ADALONG_COLORS.LIGHT_GRAY,
                          borderColor: 'white',
                          '&::placeholder': {
                            color: ADALONG_COLORS.LIGHT_WHITE,
                          },
                        },
                      }}
                    />
                  )}
                  onChange={(_, value) => setCategory(value)}
                />
              </div>
            </div>
          </div>
          <div className='buttons row'>
            <div className='col-6'>
              <button className='button button-border' onClick={() => props.onClose()}>
                {window.T('collect.collectoverlay.create.cancel').toUpperCase()}
              </button>
            </div>
            <div className='col-6'>
              <button className='button button-blue' onClick={onCreate}>
                {window.T('collect.collectoverlay.create.add').toUpperCase()}
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default connect<{}, DispatchToProps, OwnProps, ICombinedReducers>(
  mapStateToProps,
  mapDispatchToProps,
)(CreateCollection);
