import { CombinedState, Store, AnyAction } from 'redux';
import { ICombinedReducers } from './types/redux';

type ActionListener = string;
type ActionListenerFunction = (
  store: Store<CombinedState<ICombinedReducers>>,
  action: AnyAction, state: CombinedState<ICombinedReducers>
) => void;

const listeners: Record<ActionListener, ActionListenerFunction[]> = {};

export function middleware(store: Store<CombinedState<ICombinedReducers>>) {
  return (next: (action: AnyAction) => any) => (action: AnyAction) => {
    const l = listeners[action.type];
    if (l) {
      l.forEach((fn: ActionListenerFunction) => fn(store, action, store.getState()));
    }
    return next(action);
  };
}

export function on(action: ActionListener, fn: ActionListenerFunction) {
  listeners[action] = listeners[action] || [];
  const listener = listeners[action].find((f: ActionListenerFunction) => f === fn);
  if (listener) {
    return;
  }
  listeners[action].push(fn);
}

export function off(action: ActionListener, fn: Store<CombinedState<ICombinedReducers>, AnyAction>) {
  const listener: ActionListenerFunction[] = listeners[action];
  if (!listener) {
    return;
  }
  if (!fn) {
    listeners[action] = [];
    return;
  }
  const f = listener.indexOf((listenerFunction) => listenerFunction === fn);
  if (f !== -1) {
    listener.splice(f, 1);
  }
}
