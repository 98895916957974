import React, { PropsWithChildren } from "react";
import { Redirect } from "react-router-dom";
import { ROOT_PATH } from "@src/config";
import { ApiTypes } from "common";

interface OwnProps {
  user?: ApiTypes.ResGetMe["user"] | null;
  tab: keyof Pick<
    ApiTypes.ResGetMe["user"]["groupFeatures"],
    "create" | "product_catalog" | "share" | "widget" | "crm"
  >;
}

function AuthorizedPage({ user, tab, children }: PropsWithChildren<OwnProps>) {
  // Not (yet) authenticated
  if (!user) {
    return null;
  }

  if (user.groupFeatures[tab]) {
    return <>{children}</>;
  }
  return <Redirect to={ROOT_PATH} />;
}

export default AuthorizedPage;
