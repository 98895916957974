/* eslint-disable import/prefer-default-export */
import { Pathname, ComposedPathName, Browsing } from '@src/types/Browsing';
import { UrlQSSerializer } from '@src/services/helpers/UrlSerializer';
import { setRoute } from './helpers/redux';

export class BrowsingService implements Browsing {
  public goToPage(p: Pathname | ComposedPathName) {
    if (typeof p === 'string') {
      setRoute(p);
    } else {
      const route = this.composedPathToRoute(p);
      setRoute(route);
    }
  }

  public setRoute(route: Pathname) {
    setRoute(route);
  }

  private composedPathToRoute({ root, args, qs }: ComposedPathName): Pathname {
    const keys = Array.from(args.values());
    // const qs = (qs || {}).entries()
    let pathname = `${root}/${keys.join('/')}`;
    if (qs) {
      pathname += `?${UrlQSSerializer.serialize(qs)}`;
    }
    return pathname as Pathname;
  }
}
