import React, { useState } from 'react';
import { UrlDate, RelativeDate, DateRange } from '@src/types/Filters';
import Grid from '@mui/material/Grid';
import LocalizedStrings from 'react-localization';
import { FiltersUtility } from '@src/fragments/Filters/managers/FiltersUtility';
import { ADALONG_COLORS } from '@src/styles';
import { DateRangePicker } from '@src/components/DateRangePicker/DateRangePicker';
import { stylesheet } from 'typestyle';
import { SelectableButton, SelectableButtonStyle } from '@src/components/SelectableButton';
import {useRecoilState} from "recoil";
import {FilterState} from "@src/pages/Discover/components/Atoms/Atoms";

const RELATIVE_DATES: RelativeDate[] = ['days7', 'days30', 'months2', 'months6'];

export function DateFilter() {
  const [filters, setFilters] = useRecoilState(FilterState)
  const [pickerIsOpen, setPickerIsOpen] = useState(false);
  const date = filters.date[0];
  let range: DateRange | null = null;
  const onChange = (relativeDate: UrlDate) => setFilters((filters) => ({
    ...filters,
    date: [relativeDate]
  }))

  if (date) {
    range = FiltersUtility.urlDateToRange(date);
  }
  const customButton: boolean = !!(date && FiltersUtility.parseCustomDate(date));

  return (
    <div>
      <Grid container spacing={2}>
        {RELATIVE_DATES.map((relativeDate) => (
          <Grid item xs={4} key={relativeDate}>
            <SelectableButton
              selected={date === relativeDate}
              onClick={() => onChange(relativeDate)}
              buttonStyle={buttonsStyle}
            >
              {lang.buttons[relativeDate]}
            </SelectableButton>
          </Grid>
        ))}
        <Grid item xs={4}>
          <SelectableButton
            selected={customButton}
            onClick={() => onCustom(date)}
            buttonStyle={buttonsStyle}
          >
            {lang.buttons.custom}
          </SelectableButton>
          <DateRangePicker
            calendarClassName={sheet.calendar}
            range={range}
            isOpen={pickerIsOpen}
            onChange={onPickerChange}
            onClose={() => setPickerIsOpen(false)}
          />
        </Grid>
      </Grid>
    </div>
  );

  function onPickerChange(range: DateRange) {
    const urlDate = FiltersUtility.customDateToUrlDate(range);
    onChange(urlDate);
    setPickerIsOpen(false);
  }

  function onCustom(
    currentDate?: UrlDate,
  ) {
    let range: DateRange | null = null;
    if (currentDate) {
      range = FiltersUtility.urlDateToRange(currentDate);
    }
    if (!currentDate || currentDate === 'all' || !range) {
      range = FiltersUtility.urlDateToRange('days30');
    }
    if (!range) {
      return;
    }
    const newUrlDate = FiltersUtility.customDateToUrlDate(range);
    onChange(newUrlDate);
    setPickerIsOpen(true);
  }
}

export const lang = new LocalizedStrings({
  en: {
    buttons: {
      days7: '7 days',
      days30: '30 days',
      months2: '2 months',
      months6: '6 months',
      custom: 'Custom',
    },
    noDates: 'All time',
  },
});

const buttonsStyle: SelectableButtonStyle = {
  size: 14,
  textColor: ADALONG_COLORS.LIGHT_GRAY,
  borderColor: 'transparent',
  weightWhenSelected: 'inherit',
};

const sheet = stylesheet({
  calendar: {
    // move the calendar to the right of filters
    transform: 'translate(148px, -90px)',
  },
});
