import React, { Component, ReactNode } from 'react';
import { classes } from 'typestyle';
import styles from './styles.scss';

interface Props {
  children?: ReactNode
  title: string
  style?: React.HTMLAttributes<HTMLDivElement>['style']
  onSelect?: () => any
  classes?: string[]
}

class TitleBar extends Component<Props, {}> {
  public render() {
    const {
      classes: cls = [],
    } = this.props;
    return (
      <div className={classes(styles.titleBar, ...cls)}>
        <div
          className="element-background"
          style={this.props.style}
        />
        <div className="overlay" />
        <div className="element" onClick={this.props.onSelect}>
          <div className="title">
            {this.props.title}
          </div>
          {this.props.children}
        </div>
      </div>
    );
  }
}

export default TitleBar;
