import React from 'react';
import { classes, style } from 'typestyle';
import {
  FilledCheckBoxSvgIcon, NonFilledCheckBoxSvgIcon, NonFilledRedCheckBoxSvgIcon, FilledRedCheckBoxSvgIcon,
} from './SvgIcons/CheckBox';

type Variant = 'classic' | 'redcross';

interface Props {
  id?: string
  className?: string
  checked: boolean
  onChange?: (checked: boolean) => void
  size?: number
  variant?: Variant
}

const ICON_VARIANT: {
  [iconkey in Variant]: {
    [key in 'checked' | 'unchecked']: ({ size }: { size?: number }) => JSX.Element
  }
} = {
  classic: {
    checked: FilledCheckBoxSvgIcon,
    unchecked: NonFilledCheckBoxSvgIcon,
  },
  redcross: {
    checked: FilledRedCheckBoxSvgIcon,
    unchecked: NonFilledRedCheckBoxSvgIcon,
  },
};

export function CheckBox({
  id,
  onChange,
  checked,
  size,
  variant = 'classic',
  className,
}: Props) {
  const Checked = ICON_VARIANT[variant].checked;
  const Unchecked = ICON_VARIANT[variant].unchecked;
  return (
    <div
      id={id}
      className={classes(checkBoxClass, className)}
      onClick={onChange ? () => onChange(!checked) : undefined}
    >
      {checked ? (
        <Checked size={size} />
      ) : (
        <Unchecked size={size} />
      )}
    </div>
  );
}

const checkBoxClass = style({
  display: 'inline-block',
  cursor: 'pointer',
});
