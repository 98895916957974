import { selector } from 'recoil';
import {
  filterCollectionInputState,
  filterCollectionXclusionState,
  filterCreatorInputState,
  filterCreatorXclusionState,
  filterLanguageInputState,
  filterLanguageXclusionState,
  filterProductExactInputState,
  filterProductExactXclusionState,
  filterProductSimilarInputState,
  filterProductSimilarXclusionState,
  filterSourceInputState,
  allCollectionState,
  allProductState,
  FilterState,
  filterOwnContentInputState,
  filterOwnContentXclusionState,
  filterCreatorCountriesInputState,
  filterCreatorCountriesXclusionState,
} from '@src/pages/Discover/components/Atoms/Atoms';
import { DiscoverQs } from '@src/types/Discover';

export const productExactExcludeState = selector({
  key: 'productExactExcludeState',
  get: ({ get }) => {
    const xclusion = get(filterProductExactXclusionState);
    return xclusion === 'exclusion';
  },
});

export const productExactInput = selector({
  key: 'productExactInputState',
  get: ({ get }) => {
    return get(filterProductExactInputState);
  },
});

export const productSimilarExcludeState = selector({
  key: 'productSimilarExcludeState',
  get: ({ get }) => {
    const xclusion = get(filterProductSimilarXclusionState);
    return xclusion === 'exclusion';
  },
});

export const productSimilarInput = selector({
  key: 'similarProductExactInputState',
  get: ({ get }) => {
    return get(filterProductSimilarInputState);
  },
});

export const languageInput = selector({
  key: 'languageInputState',
  get: ({ get }) => {
    return get(filterLanguageInputState);
  },
});

export const languageExcludeState = selector({
  key: 'languageExcludeState',
  get: ({ get }) => {
    const xclusion = get(filterLanguageXclusionState);
    return xclusion === 'exclusion';
  },
});

export const creatorInput = selector({
  key: 'creatorInputState',
  get: ({ get }) => {
    return get(filterCreatorInputState);
  },
});

export const creatorExcludeState = selector({
  key: 'creatorExcludeState',
  get: ({ get }) => {
    const xclusion = get(filterCreatorXclusionState);
    return xclusion === 'exclusion';
  },
});

export const ownContentInput = selector({
  key: 'ownContentInputState',
  get: ({ get }) => {
    return get(filterOwnContentInputState);
  },
});

export const ownContentExcludeState = selector({
  key: 'ownContentExcludeState',
  get: ({ get }) => {
    const xclusion = get(filterOwnContentXclusionState);
    return xclusion === 'exclusion';
  },
});

export const creatorCountriesInput = selector({
  key: 'creatorCountriesInputState',
  get: ({ get }) => {
    return get(filterCreatorCountriesInputState);
  },
});

export const creatorCountriesExcludeState = selector({
  key: 'creatorCountriesExcludeState',
  get: ({ get }) => {
    const xclusion = get(filterCreatorCountriesXclusionState);
    return xclusion === 'exclusion';
  },
});

export const collectionInput = selector({
  key: 'collectionInputState',
  get: ({ get }) => {
    return get(filterCollectionInputState);
  },
});

export const collectionExcludeState = selector({
  key: 'collectionExcludeState',
  get: ({ get }) => {
    const xclusion = get(filterCollectionXclusionState);
    return xclusion === 'exclusion';
  },
});

export const sourceInput = selector({
  key: 'sourceInputState',
  get: ({ get }) => {
    return get(filterSourceInputState);
  },
});

export const allCollections = selector({
  key: 'allCollections',
  get: ({ get }) => {
    return get(allCollectionState);
  },
});

export const allProducts = selector({
  key: 'allProducts',
  get: ({ get }) => {
    return get(allProductState);
  },
});

export const getFilter = selector({
  key: 'filters',
  get: ({ get }): DiscoverQs => {
    return get(FilterState);
  },
});
