import React, { HTMLAttributes, PropsWithChildren } from 'react';

import { style, classes } from 'typestyle';
import { ThemeStyle } from '@src/styles';

/**
 * This component give a styled container to be placed in a Modal
 *
 */
export function ModalContainer({
  children,
  className,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  const onClick = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
  };

  return (
    <div
      className={classes(modalContainerClass, className)}
      onClick={onClick}
      {...props}
    >
      {children}
    </div>
  );
}

const modalContainerClass = style({
  backgroundColor: ThemeStyle.container.color,
  maxHeight: '100%',
  maxWidth: '100%',
});
