import React from 'react';
import { SearchSuggestion } from '@src/types/Filters';

interface Props {
  suggestion: SearchSuggestion
}

export function CollectionItem({
  suggestion,
}: Props) {
  return (
    <div>
      <span>{suggestion.display}</span>
    </div>
  );
}
