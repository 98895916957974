import { XclusionSelection } from '@src/types/Filters';

const exclusionRgx = /^-/;

export class XclusionUtility {
  /**
     * Tell if the key is inclusive or exclusive
     */
  public static isInclusion(key: string): boolean {
    return !exclusionRgx.test(key);
  }

  /**
     * Define how to include or exclude
     */
  public static xcludeKey(suggestionKey: string, xclusion: XclusionSelection): string {
    switch (xclusion) {
      case 'inclusion': return suggestionKey;
      case 'exclusion': return `-${suggestionKey}`;
      default: return suggestionKey;
    }
  }

  /**
     * Return keys without the xclude symbol
     */
  public static getRawKeys(keys: string[]): string[] {
    return [...keys].map((k) => this.getRawKey(k));
  }

  /**
     * Return key without the xclude symbol
     */
  public static getRawKey(key: string): string {
    return key.replace(exclusionRgx, '');
  }

  /**
     * Tell if we are in "include" or "exclude" mode
     * based on an array of values
     * Default to inclusion
     */
  public static prevailing(keys: string[] = []): XclusionSelection {
    if (!keys.length) {
      return 'inclusion';
    }
    return this.isInclusion(keys[0]) ? 'inclusion' : 'exclusion';
  }
}
