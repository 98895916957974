import { useQuery, UseQueryOptions } from 'react-query';
import { DEPRECATED_QUERY_KEYS, QUERY_KEYS } from '@src/query';
import { ProductView, ApiTypes } from 'common';
import { getProducts, getProductsAvailablesLocalizations } from './api';

export const useQueryProducts = (productIds: string[], config?: UseQueryOptions<ProductView[]>) => useQuery<ProductView[]>([DEPRECATED_QUERY_KEYS.PRODUCT, productIds], async (): Promise<ProductView[]> => {
  if (!productIds.length) {
    return [];
  }
  const products = await getProducts({
    ids: productIds,
  });
  return products;
}, config);

export const useQueryAllProducts = (config?: UseQueryOptions<ProductView[]>) =>
    useQuery<ProductView[]>([DEPRECATED_QUERY_KEYS.PRODUCT], async (): Promise<ProductView[]> => {
        const products = await getProducts({});
        return products

    }, config);

export const useQueryProductsAvailableCountries = () => useQuery<ApiTypes.ResGetLocalizationMappings>([QUERY_KEYS.productsAvailableLocalizations],
  async (): Promise<ApiTypes.ResGetLocalizationMappings> => {
    return getProductsAvailablesLocalizations();
});
