import React, { PropsWithChildren } from 'react';
import { stylesheet } from 'typestyle';

interface Props {
  header?: JSX.Element
}

export function LoginFrameContainer({
  header,
  children,
}: PropsWithChildren<Props>) {
  return (
    <div className={sheet.frameContainer}>
      {header}
      <div className={sheet.frame}>
        {children}
      </div>
    </div>
  );
}

const sheet = stylesheet({
  frameContainer: {
    display: 'flex',
    flexGrow: 1,
    flexDirection: 'column',
    justifyContent: 'space-evenly',
    minHeight: 680,
  },
  frame: {
    margin: 'auto',
    background: '#272727',
    width: 350,
    textAlign: 'center',
    padding: '25px 40px',
    $nest: {
      '& .panel-buttons': {
        fontWeight: 700,
        marginBottom: 15,
      },
      '& .input': {
        margin: '15px 0 12px',
        borderColor: '#c5c5c5',
      },
      '& .button': {
        marginTop: 8,
        marginBottom: 16,
      },
      '& .alert': {
        marginTop: 5,
        marginBottom: 10,
      },
    },
  },
});
