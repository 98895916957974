import { useMutation } from 'react-query';
import { ApiError } from '@src/services/api';
import { CollectionView } from 'common';
import { DEPRECATED_QUERY_KEYS, queryClient } from '@src/query';
import { createCollection } from './api';

export const useMutationCollectionCreate = () => useMutation<CollectionView, ApiError, Pick<CollectionView, 'name' | 'description' | 'category'>>((partialCollection) => createCollection({
  name: partialCollection.name,
  desc: partialCollection.description,
  category: partialCollection.category,
}), {
  onSuccess: async () => {
    const key = [DEPRECATED_QUERY_KEYS.COLLECTIONS];
    queryClient.invalidateQueries(key);
  },
});
