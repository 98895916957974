import React, { PropsWithChildren } from 'react';
import { style } from 'typestyle';
import { ADALONG_COLORS, TTitleSizes } from '@src/styles';

interface Props {
  size?: TTitleSizes
  italic?: boolean
}

export function BarelyVisibleText({
  children,
  size = 'small',
  italic = false,
}: PropsWithChildren<Props>) {
  return (
    <div
      className={textClass}
      style={{
        fontSize: size,
        fontStyle: italic ? 'italic' : 'normal',
      }}
    >
      {children}
    </div>
  );
}

const textClass = style({
  color: ADALONG_COLORS.DARK_GRAY,
});
