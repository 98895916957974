import React, { Component } from 'react';
import LocalizedStrings from 'react-localization';
import {
  SearchMarkDef, SearchTheme, SearchEventHandlers, SearchUtilityInterface, SearchElementRef,
} from '../../types/Search';
import { ActiveMark } from './ActiveMark';
import { Mark } from '../../components/Mark';
import { SearchAndSelectBar } from '../../components/SearchAndSelectBar';

interface Props {
  theme: SearchTheme
  inputValue: string
  markDefs: SearchMarkDef[]
  handlers: SearchEventHandlers
  elementRefs: SearchElementRef
  formatExclusion: SearchUtilityInterface['parseExclusion']
}

export class Bar extends Component<Props> {
  public render() {
    const {
      markDefs, inputValue, handlers, elementRefs,
    } = this.props;
    return (
      <SearchAndSelectBar
        handlers={{
          onText: (v) => handlers.onText(v),
          onCloseBar: () => handlers.onCloseBar(),
          onInputFocus: () => handlers.onInputFocus(),
        }}
        elementRefs={elementRefs}
        inputValue={inputValue}
        description={lang.searchDescription}
      >
        {this.renderActiveMarks(markDefs)}
      </SearchAndSelectBar>
    );
  }

  private renderActiveMarks(markDefs: SearchMarkDef[]) {
    const { handlers } = this.props;
    return markDefs.map((m) => {
      const [val, isExclusion] = this.props.formatExclusion(m.text);
      const formatedMark = { ...m, text: val };
      return (
        <ActiveMark
          key={`${m.type} + ${m.text}`}
          isExclusion={isExclusion}
          onClose={() => handlers.onCloseMark(m)}
        >
          <Mark markDef={formatedMark} />
        </ActiveMark>
      );
    });
  }
}

const lang = new LocalizedStrings({
  en: {
    searchDescription: 'Search by caption, hashtag or visual label',
  },
});
