import React, { PropsWithChildren, HTMLAttributes } from 'react';
import { style, classes } from 'typestyle';

interface Props {
  onClose?: () => void
}

export function Modal({
  children,
  onClose,
  className,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLDivElement> & Props>) {
  return (
    <div
      className={classes(modalClass, className)}
      onClick={() => onClose?.()}
      {...props}
    >
      {children}
    </div>
  );
}

const modalClass = style({
  position: 'fixed',
  zIndex: 4,
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: 'rgb(0 0 0 / 50%)',
  justifyContent: 'center',
  flexDirection: 'column',
  display: 'flex',
});
