import React, { PureComponent } from 'react';
import { LoginSubmitButton } from './LoginSubmitButton';
import { LoginSigninLabel } from './SigninLabel';

export class ForgotEmailSent extends PureComponent<{ email: string }> {
  public render() {
    return (
      <>
        <LoginSigninLabel title={window.T('changepassword.emailsent')} />
        <div className="col-sm-10 offset-sm-1">
          <span style={{ fontSize: 12, fontWeight: 'bold', color: '#CCC' }}>
            {window.T('changepassword.emailsentdescription')}
          </span>
        </div>
        <div className="col-sm-10 offset-sm-1">
          <span style={{ fontSize: 12, fontWeight: 'bold', color: '#CCC' }}>
            {this.props.email}
          </span>
        </div>
        <div className="col-sm-10 offset-sm-1">
          <span style={{ fontSize: 12, fontWeight: 'bold', color: '#CCC' }}>
            {window.T('changepassword.emailsentinstructions')}
          </span>
        </div>
        <LoginSubmitButton
          text={window.T('changepassword.backtologin').toUpperCase()}
          onClick={() => window.location.assign('/login')}
        />
      </>
    );
  }
}
