import React, { useState } from 'react';
import { ICombinedReducers } from '@src/types/redux';
import { Row } from '@src/components/Utils';
import { FormError } from '@src/components/Form/FormError';
import { stylesheet } from 'typestyle';
import { api } from '@src/services/api';
import logo from '../../assets/img/site/logo_alone_white.png';
import { LoginContainer } from './container/LoginContainer';
import { ForgotEmailSent } from './ForgotEmailSent';
import FormPasswordInput from './FormPasswordInput';
import { LoginTextInput } from './TextInput';
import { LoginFrameContainer } from './container/FrameContainer';
import { LoginSigninLabel } from './SigninLabel';
import { LoginSubmitButton } from './LoginSubmitButton';
import Captcha from './Captcha';
import { useSelector } from 'react-redux';

export default function ChangePassword(): JSX.Element {
  const oobCode: string | undefined = useSelector((state: ICombinedReducers) => state.common.urlParams.oobCode ?? undefined);
  const [email, setemail] = useState<string>("");
  const [error, seterror] = useState<string | undefined>(undefined);
  const [status, setstatus] = useState<'onpage' | 'emailsent' | 'changepassword'>(oobCode ? 'changepassword' : 'onpage');
  const [captchaToken, setCaptchaToken] = useState<string | undefined>(undefined);


  function handleUsername(event: { target: { value: string } }) {
    setemail(event.target.value);
  }

  function handleCaptchaTokenChange(token: string) {
    setCaptchaToken(token);
  }

  function requestPassChange() {
    if (!captchaToken) {
      seterror("Invalid Captcha");
    }
    else {
      api.resetPassword(email, captchaToken).then(() => {
        seterror(undefined);
        setstatus('emailsent');
      }).catch((e) => {
        seterror(e.message);
      });
    }
  }

  return (
    <LoginContainer>
      <div className="logo">
        <img src={logo} />
      </div>
      <LoginFrameContainer>
        {status === 'onpage'
          && (
            <>
              <LoginSigninLabel title={window.T('changepassword.title')} />
              <Row>
              <div id="script_cf" ></div>
              <div className="col-sm-10 offset-sm-1">
                <span style={{
                  fontSize: 12, fontWeight: 'bold', color: '#CCC', lineHeight: '15px', display: 'block',
                }}
                >
                  {window.T('changepassword.description')}
                </span>
              </div>
            </Row>
            <Row>
              <div className="col-sm-10 offset-sm-1">
                <LoginTextInput
                  label="email"
                  type="email"
                  fullWidth
                  onChange={handleUsername}
                />
              </div>
            </Row>
            <Row>
              <Captcha
                onTokenChange={handleCaptchaTokenChange}
              />
            </Row>
            <Row className={sheet.submitRow}>
              <LoginSubmitButton
                text={window.T('submit').toUpperCase()}
                onClick={requestPassChange}
              />
            </Row>
            {error
              ? (
                <FormError
                    error={error && window.T(`${error}`)}
                    level="error"
                  />
                )
                : null}
            </>
          )}
        {status === 'emailsent'
          && <ForgotEmailSent email={email} />}
        {oobCode && status === 'changepassword'
          && (
            <FormPasswordInput oobCode={oobCode} />
          )}
      </LoginFrameContainer>
    </LoginContainer>
  );
}

const sheet = stylesheet({
  submitRow: {
    margin: '7px 0',
  },
});
