import { ICombinedReducers } from '@src/types/redux';
import { keepUrlParams, triggerResize } from '@src/services/helpers/redux';
import { bigStore } from '@src/store';
import { loadUserInfo, loadGroupMentionPages, loadGroupUsers } from '@src/actions/common';
import { Start } from '@src/types/Start';
import { ServiceName, ServiceReference } from '@src/types/Services';
import { Login } from '@src/types/Login';
import { Browsing, Pathname } from '@src/types/Browsing';
import { prefetchQueryUserConfig } from '@src/queries/users/useQueryUserConfig';
import { ApiTypes } from '@src/../common/dist';
import { CommonState } from '@src/types/redux/common';
import * as LoginActions from '../actions/login';
import { Call } from './api';
import { auth } from '../config/firebaseAuth';
import { onAuthStateChanged } from 'firebase/auth';

export default class StartService implements Start {
  private loginService?: Login;

  private browsingService?: Browsing;

  private started = false;

  constructor(servicesPromise: ServiceReference) {
    servicesPromise.then((services) => {
      this.loginService = services.get<Login>(ServiceName.Login);
      this.browsingService = services.get<Browsing>(ServiceName.Browsing);
    });
  }

  public init() {
    keepUrlParams();

    onAuthStateChanged(auth, async (fireuser) => {
      if (this.loginService?.loggingOut) {
        return;
      }
      const res = await this.loadUser();

      if (res) {
        if ((fireuser || res.admin) && res.user && res.user.email) {
          this.loginSuccess();
        } else if (res.user?.email && !fireuser) {
          // Session badly cleaned, so we redirect to login page
          this.browsingService?.goToPage(Pathname.login);
        }
      }
    });
  }

  public loadUser() {
    return loadUserInfo()(bigStore.dispatch).catch((e) => {
      if (e && e.type === 'maintenance') {
        return;
      }
      const state: ICombinedReducers = bigStore.getState();
      if (/\/collections\/\w+/.test(window.location.pathname) && state.common.urlParams.token) {
        return;
      }
      if (this.loginService?.checkPasswordRoute()) {
        return;
      }
      this.browsingService?.goToPage(Pathname.login);
    });
  }

  public loginSuccess() {
    bigStore.dispatch(LoginActions.loginSuccess());
    const login = /\/login/.exec(window.location.href);
    if (login) {
      this.start(Pathname.discover);
    } else {
      this.start();
    }
  }

  /**
     * Once the user is logged, we load discover
     * Note: this function should be called only once
     */
  public async start(redirect?: Pathname) {
    this.started = true;

    // Make the user config available for hooks
    // This should be the preferred way to fetch and use these data
    // and totally replace the loadUserInfo function of redux
    prefetchQueryUserConfig();

    const { common: { user, admin } } = bigStore.getState();
    if (!user) {
      return console.error("Can't start: user not loaded");
    }
    window.LOCALS.me = user;
    window.setTracking(user, admin);
    await Promise.all([
      loadGroupMentionPages()(bigStore.dispatch),
      loadGroupUsers()(bigStore.dispatch),
    ]);

    // Redirect to the given page
    if (redirect) {
      this.browsingService?.goToPage(redirect || Pathname.discover);
    }
    setTimeout(() => triggerResize(), 20);
    await StartService.loadLiveChat(user);
  }

  /**
   * Identify the user by getting the token and load the live chat
   */
  static async loadLiveChat(user: CommonState['user']) {
    try {
      const { token } = await Call.get<ApiTypes.ResGetLiveChatToken>('/api/user/livechat/token');
      window.loadLiveChat(user, token);
    } catch (e) {
      throw Error('Can\'t get livechat token', { cause: e });
    }
  }
}
