import { Browsing, Pathname } from '@src/types/Browsing';
import React, { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { ICombinedReducers } from '@src/types/redux';
import { useQueryRemoteConversations } from '@src/queries/messaging/useQueryRemoteConversations';
import { useQueryStoredConversations } from '@src/queries/messaging/useQueryStoredConversations';
import { PageTitle } from '@src/components/PageTitle';
import { Column, Row } from '@src/components/Utils';
import LocalizedStrings from 'react-localization';
import AuthorizedPage from '@src/components/AuthorizedPage';
import { Page } from '@src/components/Page';
import { stylesheet } from 'typestyle';
import { percent, px } from 'csx';
import { ADALONG_COLORS } from '@src/styles';
import { ConversationsGrid } from '@src/pages/CRM/Grid/ConversationsGrid';
import { ServiceName, Services } from '@src/types/Services';
import { Modal } from '@mui/material';
import { MessagesPage } from '@src/pages/CRM/Messages/MessagesPage';
import Box from '@mui/material/Box';

const lang = new LocalizedStrings({
  en: {
    title: 'CRM Board'
  }
});

const sheet = stylesheet({
  page: {
    padding: 0
  },
  pageContainer: {
    height: percent(100),
    padding: `0 ${px(10)}`
  },
  sectionsContainer: {
    height: percent(100)
  },
  messageSection: {
    justifyContent: 'space-between',
    paddingLeft: 10,
    borderLeft: `1px solid ${ADALONG_COLORS.LIGHT_BLACK}`
  }
});

export function CRMPage(props: {
  conversation?: {
    conversationId: string;
    target: string;
    interlocutor: string;
  };
  services: Services;
}) {
  // Fetch the conversation list
  const user = useSelector((state: ICombinedReducers) => state.common.user);
  const {
    data: storedConversationListRes,
    isLoading: isLoadingStoredConversations
  } = useQueryStoredConversations();
  const storedConversationList = storedConversationListRes?.pages.flatMap(p => p);

  const {
    data: remoteConversationListRes,
    isLoading: isLoadingRemoteConversations
  } = useQueryRemoteConversations();
  const remoteConversationList = remoteConversationListRes?.pages.flatMap(p => p);

  // Handle conversation change
  const browsingService: Browsing = props.services.get(ServiceName.Browsing);
  const onConversationChange = useCallback(
    (targetId: string, interlocutorId: string, conversationId: string) => {
      browsingService.goToPage(
        `${Pathname.crm}/${targetId}/${interlocutorId}/${conversationId}`
      );
    },
    []
  );

  const conversationId = props.conversation?.conversationId;
  const interlocutorId = props.conversation?.interlocutor;
  const targetId = props.conversation?.target;
  const isModalOpen = !!targetId && !!interlocutorId && !!conversationId;

  return (
      <AuthorizedPage tab='crm' user={user}>
        <Page path={Pathname.crm} contentClassNames={[sheet.page]}>
          <Column className={sheet.pageContainer}>
            <PageTitle>{lang.title}</PageTitle>
            <Row className={sheet.sectionsContainer}>
              <ConversationsGrid
                  storedConversations={storedConversationList}
                  isLoadingStoredConvs={isLoadingStoredConversations}
                  remoteConversations={remoteConversationList}
                  isLoadingRemoteConvs={isLoadingRemoteConversations}
                  onConversationChange={onConversationChange}
              />
              {isModalOpen ? (
                  <Modal
                      open={isModalOpen}
                      onClose={() => browsingService.goToPage(Pathname.crm)}
                      aria-labelledby='conversation-details-modal-title'
                      aria-describedby='conversation-details-modal-description'
                  >
                    <Box sx={{
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        transform: 'translate(-50%, -50%)',
                        width: '80vw',
                        maxWidth: 960,
                        maxHeight: '80vh',
                        overflowY: 'auto',
                        backgroundColor: ADALONG_COLORS.SMOOTH_BLACK,
                        boxShadow: 24,
                        padding: '20px',
                        borderRadius: '4px',
                        border: `1px solid ${ADALONG_COLORS.LIGHT_BLACK}`
                    }}>
                      <MessagesPage
                          conversation={props.conversation}
                          services={props.services}
                      />
                    </Box>
                  </Modal>
              ) : null}
            </Row>
          </Column>
        </Page>
      </AuthorizedPage>
  );
}
