import React from 'react';
import { classes, style } from 'typestyle';
import ReactLocalizedString from 'react-localization';

export function MediaRightsRequestedIcon() {
  return (
    <>
      <div className={iconClass}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          version='1.1'
          width='18'
          height='18'
          viewBox='0 0 1000 1000'
          xmlSpace='preserve'
        >
          <g transform='matrix(12.469 0 0 12.469 500.0009 499.6952)' id='877718'>
            <path
              style={{
                stroke: 'none',
                strokeWidth: 1,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeDashoffset: 0,
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                fill: 'rgb(205, 127, 50)',
                fillRule: 'nonzero',
                opacity: 1,
              }}
              transform=' translate(-49.9356, -50.025)'
              d='M 83.2 30.8 c 0 -2.1 -0.1 -4.2 -0.1 -6.1 c 0 -1.5 -1.2 -2.8 -2.8 -2.8 c -11.8 0 -20.9 -3.4 -28.4 -10.7 c -1.1 -1 -2.8 -1 -3.9 0 c -7.5 7.3 -16.5 10.7 -28.4 10.7 c -1.5 0 -2.8 1.2 -2.8 2.8 c 0 1.9 0 4 -0.1 6.1 c -0.4 19.9 -0.9 47.1 32.2 58.6 c 0.3 0.1 0.6 0.2 0.9 0.2 c 0.3 0 0.6 0 0.9 -0.2 C 84 77.9 83.5 50.7 83.2 30.8 z M 63.4 44.3 L 48.1 59.6 c -0.5 0.5 -1.2 0.8 -2 0.8 c -0.7 0 -1.4 -0.3 -2 -0.8 L 36.5 52 c -1.1 -1.1 -1.1 -2.8 0 -3.9 c 1.1 -1.1 2.8 -1.1 3.9 0 l 5.7 5.7 l 13.4 -13.4 c 1.1 -1.1 2.8 -1.1 3.9 0 C 64.5 41.5 64.5 43.2 63.4 44.3 z'
              stroke-linecap='round'
            />
          </g>
          <g transform='matrix(6.5818 0 0 2.2097 473.3104 483.4964)' id='468171'>
            <path
              style={{
                stroke: 'none',
                strokeWidth: 0,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeDashoffset: 0,
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                fill: 'rgb(205, 127, 50)',
                fillRule: 'nonzero',
                opacity: 1,
              }}
              vector-effect='non-scaling-stroke'
              transform=' translate(-46.5001, -78.5)'
              d='M 2.6167 78.5 C 2.6167 57.2341 7.7391 38.064 15.8764 24.2946 C 24.0482 10.4665 34.9664 2.5 46.5001 2.5 C 58.0338 2.5 68.952 10.4665 77.1238 24.2946 C 85.2611 38.064 90.3835 57.2341 90.3835 78.5 C 90.3835 99.7659 85.2611 118.936 77.1238 132.705 C 68.952 146.533 58.0338 154.5 46.5001 154.5 C 34.9664 154.5 24.0482 146.533 15.8764 132.705 C 7.7391 118.936 2.6167 99.7659 2.6167 78.5 Z'
              stroke-linecap='round'
            />
          </g>
          <g transform='matrix(1.0484 0 0 1.0484 313.9844 520.2471)' id='692231'>
            <path
              style={{
                stroke: 'none',
                strokeWidth: 0,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeDashoffset: 0,
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                fill: 'rgb(0, 0, 0)',
                fillRule: 'nonzero',
                opacity: 1,
              }}
              vector-effect='non-scaling-stroke'
              transform=' translate(-67.5, -67.5)'
              d='M 132.5 67.5 C 132.5 103.399 103.399 132.5 67.5 132.5 C 31.6015 132.5 2.5 103.399 2.5 67.5 C 2.5 31.6015 31.6015 2.5 67.5 2.5 C 103.399 2.5 132.5 31.6015 132.5 67.5 Z'
              stroke-linecap='round'
            />
          </g>
          <g transform='matrix(1.0484 0 0 1.0484 500 519.8909)' id='613661'>
            <path
              style={{
                stroke: 'none',
                strokeWidth: 0,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeDashoffset: 0,
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                fill: 'rgb(0, 0, 0)',
                fillRule: 'nonzero',
                opacity: 1,
              }}
              vector-effect='non-scaling-stroke'
              transform=' translate(-67.5, -67.5)'
              d='M 132.5 67.5 C 132.5 103.399 103.399 132.5 67.5 132.5 C 31.6015 132.5 2.5 103.399 2.5 67.5 C 2.5 31.6015 31.6015 2.5 67.5 2.5 C 103.399 2.5 132.5 31.6015 132.5 67.5 Z'
              stroke-linecap='round'
            />
          </g>
          <g transform='matrix(1.0484 0 0 1.0484 685.3944 520.2471)' id='736910'>
            <path
              style={{
                stroke: 'none',
                strokeWidth: 0,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeDashoffset: 0,
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                fill: 'rgb(0, 0, 0)',
                fillRule: 'nonzero',
                opacity: 1,
              }}
              vector-effect='non-scaling-stroke'
              transform=' translate(-67.5, -67.5)'
              d='M 132.5 67.5 C 132.5 103.399 103.399 132.5 67.5 132.5 C 31.6015 132.5 2.5 103.399 2.5 67.5 C 2.5 31.6015 31.6015 2.5 67.5 2.5 C 103.399 2.5 132.5 31.6015 132.5 67.5 Z'
              stroke-linecap='round'
            />
          </g>
        </svg>
      </div>
    </>
  );
}

export const lang = new ReactLocalizedString({
  en: {},
});

const iconClass = style({
  display: 'inline',
});
