import React from 'react';
import { style, classes } from 'typestyle';
import NavBar from '@src/components/NavBar';
import { Pathname } from '@src/types/Browsing';
import { ADALONG_COLORS } from '@src/styles';

interface Props {
  path: Pathname
  contentClassNames?: string[]
}

export function Page({
  children,
  path,
  contentClassNames = [],
}: React.PropsWithChildren<Props>) {
  return (
    <div className={pageClass}>
      <div className={navBarClass}>
        <NavBar active={path} />
      </div>
      <div className={classes(pageContentClass, ...contentClassNames)}>
        {children}
      </div>
    </div>
  );
}

const pageClass = style({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const navBarClass = style({
  flexShrink: 0,
  backgroundColor: ADALONG_COLORS.DARK_BLACK,
});

const pageContentClass = style({
  overflow: 'hidden',
  flex: 1,
  paddingTop: 25,
  backgroundColor: ADALONG_COLORS.SMOOTH_BLACK,
});
