import React, { useEffect, useState } from 'react';

import * as reduxHelpers from '@src/services/helpers/redux';
import { connect } from 'react-redux';

import { ICombinedReducers } from '@src/types/redux';
import { CollectionView, MediaView } from 'common';
import { EPopup } from '@src/types/redux/common';

import { AddToCollection } from '@src/types/Popups';
import { useQueryCollections } from '@src/queries/collections/useQueryCollections';
import { useMutationCollectionAddMedia } from '@src/queries/collections/useMutationCollection';
import { getLangError } from '@src/fragments/Alerting/AlertSnackbar';
import TextContent from '@src/components/Media/TextContent';
import { style, classes } from 'typestyle';
import { MediaType } from '@adalong/schemas';
import { Loader } from '@src/components/Loader';
import { SearchAndSelectBar } from '@src/components/SearchAndSelectBar';
import { useAddAlert } from '@src/fragments/Alerting/alertAtom';
import * as CommonActions from '../../../../../../actions/common';
import { PerfectScrollbar } from '../../../../../../components/PerfectScrollbar';
import { MediaImage } from '../../../../../../components/MediaImage';
import { CollectionThumb } from '../../../../../../components/CollectionThumb';

interface IProps {
  media?: AddToCollection
  close: () => void
  newCollectionPopup: (medias: MediaView[]) => void
}

export function AddTo(props: IProps) {
  const addAlert = useAddAlert();
  const { data: collections = [], isLoading: isLoadingCollections } = useQueryCollections();
  const {
    addMediaInBatches,
    isLoading: isAddingMedia,
    reset,
  } = useMutationCollectionAddMedia();
  const [filter, setFilter] = useState<string>('');
  const [filteredCollections, setFilteredCollections] = useState<CollectionView[]>(collections);
  // Filter collections depending on searchBar input
  const filterCollections = (): CollectionView[] => {
    if (filter.length < 1) {
      return collections;
    }
    return collections.filter(
      (collection) => collection.name.toLowerCase().includes(filter.toLowerCase()),
    );
  };
  useEffect(() => {
    setFilteredCollections(filterCollections());
  }, [filter]);

  const onClickCollection = async (collection: CollectionView) => {
    if (!props.media || collection.locked) {
      return;
    }

    try {
      await addMediaInBatches(collection._id, props.media?.map((m) => m._id));
      reduxHelpers.addAlert({
        text: 'collect.collectoverlay.success',
        type: 'success',
      });

      props.close();
    } catch (error) {
      if (error instanceof Error) {
        addAlert({
          text: getLangError(error.message),
          close: reset,
        });
      } else {
        addAlert({
          text: 'An unknown error occurred',
          close: reset,
        });
      }
    }
  };

  const collectionsICanEdit = filteredCollections.filter((c) => c.i_can_edit && !c.locked);
  const { media = [] } = props;

  const loading = isLoadingCollections || isAddingMedia;
  const loadingClassName = loading ? 'collect-frame__loading' : '';

  return (
    <div>
      {loading && <div className={loaderCenteredClass}><Loader size={40} /></div>}
      <div className={classes(addToClass, 'collect-frame', loadingClassName)}>
        {media.length === 1
                    && (
                    <div className="left">
                      <MediaImage src={media[0].cdn_image} />
                    </div>
                    )}
        {media[0].type === MediaType.Text
                    && <TextContent caption={media[0].caption} />}
        <div className="right">
          <div className="title">
            {window.T('collect.collectoverlay.addto.title')}
            <div className="remove" onClick={() => props.close()}>
              <i className="material-icons notranslate">close</i>
            </div>
          </div>
          <div className={newClass}>
            <button className="button button-border" onClick={() => props.newCollectionPopup(media)}>
              {window.T('collect.collectoverlay.addto.new')}
            </button>
          </div>
          <div className={searchBar}>
            <SearchAndSelectBar
              inputValue={filter}
              handlers={{
                onText: (val) => setFilter(val),
                onCloseBar: () => setFilter(''),
              }}
            />
          </div>
          <PerfectScrollbar
            className={collectionsClass}
            options={{
              wheelSpeed: 0.3,
              minScrollbarLength: 30,
            }}
          >
            <div>
              {collectionsICanEdit.map((collection) => (
                <CollectionThumb
                  className={collectionThumbClass}
                  collection={collection}
                  key={collection._id}
                  onClick={() => onClickCollection(collection)}
                  backgroudImageUrl={collection.preview_media_url ?? undefined}
                />
              ))}
            </div>
          </PerfectScrollbar>
        </div>
      </div>
    </div>

  );
}

const width = '93%';
const addToClass = style({
  width,
});
const loaderCenteredClass = style({
  position: 'absolute',
  zIndex: 2,
  top: '50%',
  left: '50%',
});
const collectionsClass = style({
  maxHeight: 293,
  overflow: 'hidden',
  position: 'relative',
});
const newClass = style({
  padding: '20px 0',
  $nest: {
    button: {
      width,
    },
  },
});
const searchBar = style({
  width: '93%',
  marginLeft: '15px',
  marginBottom: '15px',
});
const collectionThumbClass = style({
  width,
  margin: 'auto',
  marginBottom: 15,
});

export default connect((state: ICombinedReducers) => ({}), (dispatch) => ({
  close: () => dispatch(CommonActions.setPopup(EPopup.collectOverlay, false)),
  newCollectionPopup: (medias: MediaView[]) => dispatch(CommonActions.collectOverlay('new', medias)),
}))(AddTo);
