import React from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import { grey } from '@mui/material/colors';
import { ThemeProvider, createTheme } from '@mui/material';

const DEFAULT_SIZE = 40;
const DEFAULT_COLOR = grey[200];

interface Props {
  size?: number
  color?: string
}

const getMaterialTheme = (color: string) => createTheme({
  components: {
    MuiCircularProgress: {
      styleOverrides: {
        svg: {
          color: color || grey[200],
        },
      },
    },
  },
});

export function Loader(props: Props) {
  const { size = DEFAULT_SIZE, color = DEFAULT_COLOR } = props;

  return (
    <ThemeProvider theme={getMaterialTheme(color)}>
      <CircularProgress style={{ height: size, width: size }} />
    </ThemeProvider>
  );
}
