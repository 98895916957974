import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { ADALONG_COLORS } from '@src/styles';
import { style } from 'typestyle';

interface Props {
  linked?: boolean;
  // When a button is unselected, make it grey
  inactive?: boolean;
  disabled?: boolean | string;
  onClick?: React.MouseEventHandler<HTMLButtonElement>;
  tooltip?: string;
  svg: React.ComponentType<{ fill: string }>;
}

/**
 * The exact linking icon is to be activated/deactivated by the user
 * and allows them to link a product that is an exact match to a given UGC.
 */

export function LinkButton({ linked, onClick, inactive, disabled, tooltip, svg: SvgComponent }: Props) {
  let fill = ADALONG_COLORS.LIGHT_WHITE;
  if (disabled || inactive) {
    fill = ADALONG_COLORS.DARK_GRAY;
  } else if (linked) {
    fill = ADALONG_COLORS.LIGHT_YELLOW;
  }
  return (
    <Tooltip title={tooltip}>
      <button className={buttonIconClass} onClick={!disabled ? onClick : undefined}>
        {<SvgComponent fill={fill} />}
      </button>
    </Tooltip>
  );
}

const buttonIconClass = style({
  cursor: 'pointer',
  background: 'transparent',
  border: 'none',
});
