import React from 'react';
import { IAlert } from '@src/types/alert';
import { Alert } from '@src/components/Alert';
import { style } from 'typestyle';

const ALERT_TIMEOUT = 6000;

interface Props {
  remove: (alert: IAlert['id']) => void
  alerts: IAlert[]
}

export function AlertUser({
  alerts,
  remove,
}: Props) {
  return (
    <div className={alertsClass}>
      {alerts.map((alert) => (
        <Alert
          key={alert.id}
          alert={alert}
          onRemove={() => remove(alert.id)}
          timeout={ALERT_TIMEOUT}
        />
      ))}
    </div>
  );
}

const alertsClass = style({
  position: 'fixed',
  width: '100%',
  paddingTop: 35,
  top: 0,
  left: 0,
  height: 0,
  zIndex: 6,
  fontSize: 15,
  fontFamily: 'Raleway',
  fontWeight: 600,
});
