import React from 'react';
import Information from './components/Information';
import { useSettings } from './hooks/useSettings';
import { FormButtons } from '@src/components/Form/FormButtons';
import { ActionButton } from '@src/components/ActionButton';
import { PerfectScrollbar } from 'components/PerfectScrollbar';
import { Sources } from './components/Sources';
import { SettingsProps } from './types/Settings';
import { style } from 'typestyle';
import Localization from './components/Localization';
import ContentFilters from './components/ContentFilters';
import Layout from './components/Layout';
import ThumbnailDesign from './components/ThumbnailDesign';
import ShopThisLook from './components/ShopThisLook';
import ProductDisplay from './components/ProductDisplay';
import { SectionProgressLoader } from '@src/components/SectionProgressLoader';
import LocalizedStrings from 'react-localization';
import { Error } from '../../Error';


export function Settings({
  remoteWidget,
  editedWidget,
  setEditedWidget,
  productCountryMappings,
  setCurrentLocalization,
}: SettingsProps) {
  const {
    sectionOpened,
    setSectionOpened,
    onResetClick,
    onSaveClick,
    isLoading,
    error,
    disableSubmit,
  } = useSettings({ remoteWidget, editedWidget, setEditedWidget, setCurrentLocalization });

  return (
    <PerfectScrollbar>
      <div className={settingsClass}>
        <Information
          opened={sectionOpened.info}
          onOpen={(info) => setSectionOpened({ ...sectionOpened, info })}
          editedWidget={editedWidget}
          setEditedWidget={setEditedWidget}
        />

        <Localization
          opened={sectionOpened.localization}
          onOpen={(localization) => setSectionOpened({ ...sectionOpened, localization })}
          editedWidget={editedWidget}
          productCountryMappings={productCountryMappings}
          setEditedWidget={setEditedWidget}
          setCurrentLocalization={setCurrentLocalization}
        />

        <Sources
          opened={sectionOpened.sources}
          onOpen={(sources) => setSectionOpened({ ...sectionOpened, sources })}
          editedWidget={editedWidget}
          setEditedWidget={setEditedWidget}
          remoteWidget={remoteWidget}
        />

        <ContentFilters
          opened={sectionOpened.contentFilters}
          onOpen={(contentFilters) => setSectionOpened({ ...sectionOpened, contentFilters })}
          editedWidget={editedWidget}
          setEditedWidget={setEditedWidget}
        />

        <Layout
          opened={sectionOpened.layout}
          onOpen={(layout) => setSectionOpened({ ...sectionOpened, layout })}
          editedWidget={editedWidget}
          setEditedWidget={setEditedWidget}
        />

        <ThumbnailDesign
          opened={sectionOpened.thumbnailDesign}
          onOpen={(thumbnailDesign) => setSectionOpened({ ...sectionOpened, thumbnailDesign })}
          editedWidget={editedWidget}
          setEditedWidget={setEditedWidget}
        />

        <ShopThisLook
          opened={sectionOpened.shopThisLook}
          onOpen={(shopThisLook) => setSectionOpened({ ...sectionOpened, shopThisLook })}
          editedWidget={editedWidget}
          setEditedWidget={setEditedWidget}
        />

        <ProductDisplay
          opened={sectionOpened.productDisplay}
          onOpen={(productDisplay) => setSectionOpened({ ...sectionOpened, productDisplay })}
          editedWidget={editedWidget}
          setEditedWidget={setEditedWidget}
        />

        {error && <Error error={error} />}

        <FormButtons className={buttonsClass}>
          <ActionButton variant='transparent' onAction={onResetClick}>
            {lang.reset.toUpperCase()}
          </ActionButton>
          <ActionButton variant='blue' onAction={onSaveClick} disabled={disableSubmit}>
            {lang.save.toUpperCase()}
          </ActionButton>
        </FormButtons>
        {isLoading ? <SectionProgressLoader indeterminate opacity={0.6} /> : null}
      </div>
    </PerfectScrollbar>
  );
}

const settingsClass = style({
  padding: '10px 10px 0 0',
  position: 'relative',
  width: 326,
});

const buttonsClass = style({
  $debugName: 'buttons',
  marginTop: 50,
  position: 'relative',
  paddingRight: 12,
  paddingBottom: 40,
});

const lang = new LocalizedStrings({
  en: {
    save: 'save',
    reset: 'reset',
  },
});
