import { useQuery, UseQueryOptions } from 'react-query';
import { DEPRECATED_QUERY_KEYS } from '@src/query';
import { getCollectionContentNumber } from './api';

/**
 * Find number of content in a collection
 * @param id collection id
 * @param enabled will prevent from fetching until true
 */
export const useQueryCollectionContentNumber = (id: string, config?: UseQueryOptions<number>) => useQuery({
  queryKey: [DEPRECATED_QUERY_KEYS.COLLECTION_CONTENT_NUMBER, id],
  queryFn: () => getCollectionContentNumber(id),
  ...config,
});
