import { useQuery } from 'react-query';
import { QUERY_KEYS } from '@src/query';
import { ApiTypes } from 'common';
import { ApiError } from '@src/services/api';
import { getInfluencersCreators, getBlacklistedCreators } from './api';

/**
 * Get influencers
 */

export const useQueryInfluencersCreators = () =>
  useQuery<ApiTypes.ResGetCreatorsList, ApiError | Error>(
    QUERY_KEYS.influencers(),
    getInfluencersCreators
  );

export const useQueryBlacklistedCreators = () =>
  useQuery<ApiTypes.ResGetCreatorsList, ApiError | Error>(
    QUERY_KEYS.blacklistedCreators(),
    getBlacklistedCreators
  );
