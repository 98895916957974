import React from 'react';
import { style } from 'typestyle';
import { color } from 'csx';
import { ADALONG_COLORS } from '@src/styles';
import CircularProgress from '@mui/material/CircularProgress';
import { Column } from './Utils';

interface Props {
  /**
     * Value between 0-100
     */
  value?: number
  /**
     * Background opacity between 0-1. Default: 0.3
     */
  opacity?: number
  indeterminate?: boolean
}

export function SectionProgressLoader({
  value = 0,
  opacity = 0.3,
  indeterminate = false,
}: Props) {
  return (
    <Column
      className={loaderClass}
      style={{
        backgroundColor: color(ADALONG_COLORS.DARK_BLACK).fade(opacity).toString(),
      }}
    >
      <CircularProgress
        variant={indeterminate ? 'indeterminate' : 'determinate'}
        value={value}
        style={{ margin: 'auto' }}
      />
    </Column>
  );
}

const loaderClass = style({
  position: 'absolute',
  top: 0,
  bottom: 0,
  right: 0,
  left: 0,
  justifyContent: 'center',
  zIndex: 20,
});
