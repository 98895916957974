import React from 'react';
import { LinearLoader } from '@src/components/LinearLoader';
import { style, classes } from 'typestyle';
import { ThemeStyle } from '@src/styles';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  value: string
  title?: string
  isLoading?: boolean
  onText(val: string): void
}

export function LightSearchBar({
  value,
  title,
  onText,
  isLoading = false,
  className,
  ...props
}: Props) {
  return (
    <div
      className={classes(searchBarClass, className)}
      {...props}
    >
      <input
        className={inputClass}
        placeholder={title || ''}
        onChange={(e) => onText(e.target.value)}
        value={value}
      />
      <LinearLoader
        classes={[loaderClass]}
        height={2}
        display={isLoading}
      />
    </div>
  );
}

const searchBarClass = style({
  position: 'relative',
  flex: 1,
  paddingRight: 8,
});

const loaderClass = style({
  position: 'absolute',
  left: 0,
  bottom: 0,
  width: '100%',
});

const inputClass = style({
  width: '100%',
  fontSize: ThemeStyle.textSizes.medium,
  backgroundColor: 'transparent',
  color: '#fff',
  letterSpacing: 0.7,
  padding: '0px 20px 5px 0',
  border: 'none',
  borderBottom: '1px solid #333333',
  outline: 'none',
  transition: 'borderColor 0.5s',
  $nest: {
    '&:focus': {
      borderBottomColor: '#818181',
    },
  },
});
