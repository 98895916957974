import { useMutation } from 'react-query';
import { DEPRECATED_QUERY_KEYS, queryClient } from '@src/query';
import { ApiError } from '@src/services/api';
import { removeWidget } from './api';

export const useMutationRemoveWidget = () => useMutation<boolean, ApiError, {
  id: string
}>(({ id }) => removeWidget(id), {
  onSuccess: async () => {
    const key = [DEPRECATED_QUERY_KEYS.WIDGETS];
    queryClient.invalidateQueries(key);
    queryClient.refetchQueries(key);
  },
});
