import React, { PropsWithChildren } from 'react';
import { style, classes } from 'typestyle';
import { ADALONG_COLORS, ThemeStyle } from '@src/styles';
import { SearchElementRef } from '@src/types/Search';
import { color } from 'csx';
import { SearchSvgIcon } from './SvgIcons/SearchIcon';
import { CloseButton } from './CloseButton';

interface Props {
  handlers: {
    onBarRef?(elem: HTMLElement | null): void
    onInputRef?(elem: HTMLElement | null): void
    onText(val: string): void
    onInputFocus?(): void
    onCloseBar(): void
  }
  elementRefs?: SearchElementRef
  inputValue: string
  description?: string
  className?: string | string[]
}

/**
 * Big white search bar with
 * Give selected content as children
 */
export function SearchAndSelectBar({
  children,
  handlers,
  elementRefs,
  inputValue,
  description = 'Search',
  className,
}: PropsWithChildren<Props>) {
  return (
    <div className={classes(barClass, className)} ref={elementRefs?.bar}>
      <div className={searchIconClass}>
        <SearchSvgIcon size={14} style={{ marginBottom: 2 }} />
      </div>
      <div className={coreAreaClass}>
        <>
          {children}
        </>
        <span className={inputClass}>
          <input
            placeholder={description}
            ref={elementRefs?.text}
            value={inputValue}
            onChange={(e) => handlers.onText(e.target.value)}
            onFocus={() => handlers.onInputFocus?.()}
          />
        </span>
      </div>
      <div className={deleteContainerClass}>
        <CloseButton
          size={28}
          color={ADALONG_COLORS.LIGHT_GRAY}
          hoverColor={color(ADALONG_COLORS.LIGHT_GRAY).darken(0.25).toHexString()}
          onClick={() => handlers.onCloseBar()}
          classNames={[deleteButtonClass]}
        />
      </div>
    </div>
  );
}

const barClass = style({
  display: 'flex',
  maxHeight: 104,
  backgroundColor: ADALONG_COLORS.SMOOTH_WHITE,
  color: ADALONG_COLORS.ABSOLUTE_BLACK,
  borderRadius: 8,
  lineHeight: 1.8,
  fontSize: ThemeStyle.textSizes.medium,
  fontWeight: 200,
  padding: '1px 0px',
});

const searchIconClass = style({
  flexShrink: 0,
  padding: '0 9px 0 15px',
  $nest: {
    svg: {
      verticalAlign: 'bottom',
    },
  },
});

const coreAreaClass = style({
  flex: 1,
  display: 'inline-flex',
  flexWrap: 'wrap',
  overflow: 'auto',
});

const inputClass = style({
  display: 'inline-block',
  flex: 1,
  minWidth: 80,
  $nest: {
    input: {
      verticalAlign: 'text-top',
      width: '100%',
      backgroundColor: 'transparent',
      border: 'none',
      textOverflow: 'ellipsis',
      overflow: 'hidden',
      whiteSpace: 'nowrap',
      $nest: {
        '&:focus': {
          outline: 'none',
        },
      },
    },
  },
});

const deleteContainerClass = style({
  flexShrink: 0,
});

const deleteButtonClass = style({
  verticalAlign: 'middle',
  padding: '4px 6px',
});
