import React, { Component } from 'react';

import { bigStore } from 'store';
import { ICombinedReducers } from '@src/types/redux';
import * as reduxHelpers from '@src/services/helpers/redux';
import { style, classes } from 'typestyle';
import Alert from '@mui/material/Alert';
import LocalizedStrings from 'react-localization';
import { API_URL } from '../config';

interface IProps {
  collectionId: string
  hide: Function
}

export class DownloadPrompt extends Component<IProps, any> {
  constructor(props: IProps) {
    super(props);
    this.state = {};
  }

  public download(target?: string) {
    const state: ICombinedReducers = bigStore.getState();
    const disable = state.common.user?.role === 'demo';
    if (disable) {
      reduxHelpers.addAlert({ text: 'common.error.role', type: 'danger' });
    } else {
      const whatToDownload = (target == 'all content') ? 'all' : 'agreed';
      const { collectionId } = this.props;
      window.location.assign(`${API_URL}/api/share/ugcs/download/${collectionId}/${whatToDownload}`);
    }
  }

  public render() {
    const agreed = true;
    return (
      <div
        className={`${promptClass} popup-overlay`}
        onClick={() => this.props.hide()}
      >
        <div className={frameClass} onClick={(e) => e.stopPropagation()}>
          <div className={titleClass}>
            {window.T('create.collections.downloadchoice.prompt')}
            <div className={closeClass} onClick={() => this.props.hide()}>
              <i className="material-icons notranslate">close</i>
            </div>
          </div>
          <Alert
            className={videoWarningClass}
            variant="filled"
            severity="warning"
          >
            {lang.videoWarning}
          </Alert>
          <div className={classes(buttonsClass, 'row')}>
            <div className="col-6">
              <button
                className="button button-border"
                onClick={() => this.download(window.T('create.collections.downloadchoice.all'))}
              >
                {window.T('create.collections.downloadchoice.all').toUpperCase()}
              </button>
            </div>
            <div className="col-6">
              <button
                disabled={!agreed}
                className={`button button-blue facebook-ads ${
                  !agreed ? 'button-disabled' : ''
                } `}
                onClick={() => this.download()}
              >
                {window.T(
                  'create.collections.downloadchoice.rightapproved',
                ).toUpperCase()}
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }
}

const lang = new LocalizedStrings({
  en: {
    videoWarning: 'Due to instagram limitations, video coming from this network shall not be available.',
  },
});

const promptClass = style({
  zIndex: 4,
});

const frameClass = style({
  backgroundColor: '#222222',
  width: 500,
  position: 'relative',
  padding: '29px 35px',
  color: 'white',
  textAlign: 'center',
});

const titleClass = style({
  textAlign: 'left',
  position: 'relative',
  fontSize: 20,
  fontWeight: 700,
  paddingBottom: 11,
  marginBottom: 25,
  borderBottom: '1px solid #3a3a3a',
});

const closeClass = style({
  color: '#c2c2c2',
  float: 'right',
  marginTop: 6,
  $nest: {
    '&:hover': {
      color: 'white',
    },
  },
});

const buttonsClass = style({
  $nest: {
    '.button': {
      height: 58,
      wordWrap: 'break-word',
      fontWeight: 600,
      width: '100%',
      padding: 11,
      fontSize: 12,
      $nest: {
        a: {
          color: 'inherit',
        },
      },
    },
  },
});

const videoWarningClass = style({
  marginBottom: 25,
});
