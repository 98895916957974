import React from 'react';
import LocalizedStrings from 'react-localization';
import { RightRequestMQLStatus } from '@adalong/schemas';
import { Select, SelectOption } from './Select';

export type RightsRequestStatusValue = RightRequestMQLStatus | 'all';
export const RIGHTS_REQUEST_VALUES: RightsRequestStatusValue[] = [
  'all',
  RightRequestMQLStatus.Agreed,
  RightRequestMQLStatus.Requested,
  RightRequestMQLStatus.Unrequested,
];

interface Props {
  value: RightsRequestStatusValue
  onChange: (value: RightsRequestStatusValue) => void
}

export function RightsRequestStatusSelect({
  value,
  onChange,
}: Props) {
  const options: SelectOption<RightsRequestStatusValue>[] = RIGHTS_REQUEST_VALUES.map((v) => ({
    value: v,
    text: lang[v],
  }));

  return (
    <Select
      options={options}
      value={value}
      variant="adalong"
      onChange={onChange}
    />
  );
}

const lang = new LocalizedStrings({
  en: {
    agreed: 'Rights agreed',
    requested: 'Rights requested',
    unrequested: 'Rights not requested',
    all: 'All rights',
  },
});
