import React, { PropsWithChildren, HTMLAttributes } from 'react';
import { style, classes } from 'typestyle';
import { ADALONG_COLORS } from '@src/styles';

interface Props {
  variant?: 'blue' | 'gray' | 'green' | 'red' | 'transparent';
  disabled?: boolean;
  minWidth?: number | string | 'initial';
  size?: 'medium' | 'small';
  onAction?: (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
}

export type ActionProps = HTMLAttributes<HTMLButtonElement> & Props;

/**
 * Button to confirm, cancel and to other behaviors
 */
export function ActionButton({
  children,
  variant = 'blue',
  disabled = false,
  minWidth = 80,
  className,
  onAction,
  onClick,
  size = 'medium',
  ...props
}: PropsWithChildren<ActionProps>) {
  const handler = onAction || onClick;
  const click = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation();
    handler?.(e);
  };
  if (disabled) {
    variant = 'gray';
  }
  return (
    <button
      className={classes(buttonClass, variantClass[variant], className)}
      style={{ minWidth }}
      disabled={disabled}
      onClick={click}
      data-size={size}
      {...props}
    >
      <span className={childClass}>{children}</span>
    </button>
  );
}

const buttonClass = style({
  outline: 'none',
  cursor: 'pointer',
  border: '2px solid transparent',
  borderRadius: 33,
  fontFamily: 'Helvetica, sans-serif',
  transition: 'background-color 0.2s, color 0.2s, border-color 0.2s',
  color: ADALONG_COLORS.LIGHT_WHITE,
  textTransform: 'uppercase',
  $nest: {
    '&[data-size="medium"]': {
      padding: '7px 18px',
      fontSize: 12,
    },
    '&[data-size="small"]': {
      padding: '5px 15px',
      fontSize: 10,
    },
    '&:focus': {
      outline: 'none',
      borderColor: ADALONG_COLORS.LIGHT_WHITE,
    },
    '&:disabled': {
      pointerEvents: 'none',
      backgroundColor: '#5b5b5b',
      color: '#bcbcbc',
      opacity: 0.7,
      $nest: {
        '&:focus': {
          textDecoration: 'none',
        },
      },
    },
    '&:hover:not(:disabled)': {
      color: 'black',
      backgroundColor: 'white',
      borderColor: 'white !important',
    },
  },
});

const childClass = style({
  verticalAlign: 'sub',
  letterSpacing: 3,
  marginRight: -3, // Cause letterSpacing add 3px space after the last letter
  whiteSpace: 'nowrap', // On firefox button appears on 2 lines when the text i too long
});

const variantClass = {
  blue: style({
    backgroundColor: ADALONG_COLORS.FLASH_BLUE,
    borderColor: ADALONG_COLORS.FLASH_BLUE,
  }),
  red: style({
    backgroundColor: ADALONG_COLORS.FLASH_RED,
    borderColor: ADALONG_COLORS.FLASH_RED,
  }),
  green: style({
    backgroundColor: ADALONG_COLORS.GREEN,
    borderColor: ADALONG_COLORS.GREEN,
  }),
  gray: style({
    color: ADALONG_COLORS.SMOOTH_WHITE,
    backgroundColor: ADALONG_COLORS.DARK_GRAY,
  }),
  transparent: style({
    borderColor: 'white !important',
    background: 'transparent',
  }),
};
