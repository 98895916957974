import React, { useState, useEffect, useCallback } from 'react';

/* Redux */
import { connect } from 'react-redux';
import { ICombinedReducers } from '@src/types/redux';

/* Api */
import { useMutationCreateCreator } from '@src/queries/influencers/useMutationCreateCreator';

/* Styling */
import { style, stylesheet } from 'typestyle';
import styles from '../styles.scss';

/* Misc */
import * as CommonActions from '../../../../../actions/common';
import { EPopup } from '@src/types/redux/common';
import { DispatchType } from '@src/store';
import LocalizedStrings from 'react-localization';

/* Components */
import { OverlayContainer } from '@src/components/OverlayContainer';
import { CloseButton } from '@src/components/CloseButton';
import { Row } from '@src/components/Utils';
import { Input } from 'components/Input';
import { ActionButton } from '@src/components/ActionButton';
import { SectionProgressLoader } from '@src/components/SectionProgressLoader';

/* Types */
import { UserView } from 'common';
import { Services } from '@src/types/Services';

interface OwnProps {
  services: Services;
}

interface IProps {
  user: UserView | null;
  focus?: boolean;
  loadUserInfo: () => Promise<{
    user: UserView;
  }>;
  close: () => void;
}

type Props = OwnProps & IProps;

export function Blacklist(props: Props) {
  const [mounted, setMounted] = useState(false);
  const [influencerData, setInfluencerData] = useState({ name: '',
    localizations: [] as string[],
    blacklisted: true });

  const { mutateAsync: mutateInfluencer, isLoading: saving } = useMutationCreateCreator();

  const onSaveClick = async () => {
    // @ts-ignore
    await mutateInfluencer({
      username: influencerData.name,
      isBlacklisted: true,
      upsert: true,
    });
    close();
  };

  const refresh = (): void | NodeJS.Timeout => {
    if (!mounted) {
      return;
    }
    const loop = () => setTimeout(() => refresh(), 3000);
    if (!props.focus) {
      return loop();
    }
  };

  const close = useCallback(() => {
    props.close();
  }, []);

  useEffect(() => {
    setMounted(true);
    refresh();
    return () => {
      setMounted(false);
    };
  }, []);

  const disableSubmit = influencerData.name.length < 1 || influencerData.name.length > 30;

  if (!props.user) {
    return null; // TODO: loading
  }
  return (
    <>
      <OverlayContainer
        containerClasses={[styles.settingsContainer]}
        frameClasses={[styles.settings]}
        onClose={close}
      >
        <Row className={headClass}>
          <div className={sheet.title}>{lang.title}</div>
          <CloseButton size='large' classNames={[sheet.closeButton]} onClick={close} />
        </Row>

        <Input
          onChange={({ target: { value } }) =>
            setInfluencerData({ ...influencerData, name: value })
          }
          value={influencerData.name}
          placeholder={lang.name}
        />

        <div className={sheet.buttonContainer}>
          <ActionButton
            variant='blue'
            onAction={onSaveClick}
            disabled={disableSubmit}
            data-size='medium'
          >
            {lang.save.toUpperCase()}
          </ActionButton>
        </div>

        {saving ? <SectionProgressLoader indeterminate opacity={0.6} /> : null}
      </OverlayContainer>
    </>
  );
}

const lang = new LocalizedStrings({
  en: {
    title: 'Blacklist a creator',
    name: 'Enter an instagram name',
    error: 'An error occured, please try later or contact us',
    save: 'Add to blacklist',
  },
});

const headClass = style({
  flexShrink: 0,
});

const sheet = stylesheet({
  title: {
    flex: 1,
    textAlign: 'center',
    fontSize: 25,
    marginBottom: 25,
  },
  closeButton: {
    flexShrink: 0,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    marginTop: '20px',
  },
  text: {
    margin: '20px 0',
  },
});
export default connect(
  (state: ICombinedReducers) => ({
    focus: state.common.windowFocus,
    opened: state.common.settingsPopup,
    user: state.common.user,
    admin: state.common.admin,
  }),
  (dispatch: DispatchType) => ({
    loadUserInfo: () => dispatch(CommonActions.loadUserInfo()),
    close: () => dispatch(CommonActions.setPopup(EPopup.addBlacklist, false)),
  })
)(Blacklist);
