import React, { useState } from 'react';
import { style } from 'typestyle';
import LocalizedStrings from 'react-localization';
import TextContent from './Media/TextContent';
import TextLineBreak from './TextLineBreak';

interface Props {
  text: string
  maxChar: number
  clickable?: boolean
  offset?: number
}

const DEFAULT_OFFSET = 25;

export function TextEllipsis({
  text,
  maxChar,
  clickable = true,
  offset = DEFAULT_OFFSET,
}: Props): JSX.Element {
  const [hide, setHide] = useState(true);

  if (text.length < maxChar || !hide) {
    return <span><TextLineBreak text={text} /></span>;
  }
  const visible = <TextLineBreak text={text.slice(0, maxChar - offset).trimEnd()} />;

  return (
    <>
      <span>{visible}</span>
      <span
        className={moreButtonClass}
        data-clickable={clickable}
        onClick={() => setHide(false)}
      >
        ...
        {' '}
        {clickable && lang.more}
      </span>
    </>
  );
}

const lang = new LocalizedStrings({
  en: {
    more: 'more',
  },
});

const moreButtonClass = style({
  // MarginLeft: 4,
  cursor: 'pointer',
  $nest: {
    '&[data-clickable="true"]:hover': {
      textDecoration: 'underline',
    },
  },
});
