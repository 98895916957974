import React, { Component, createRef } from 'react';
import ReactDOM from 'react-dom';
import Slider from '@mui/material/Slider';
import { DomHelper } from '@src/services/helpers/dom';
import Hls from 'hls.js/dist/hls.light';
import { style, classes, stylesheet } from 'typestyle';
import { DisplayableContent } from '@src/types/Media';
import { Dimensions } from '@src/types/Dimensions';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';
import { Column } from '../Utils';
import { VideoTime } from '../Common/VideoTime';

const INSTAGRAM_IFRAME_HEADER = 54;

interface IProps {
  media: DisplayableContent
  url?: string
}

interface IState {
  height: number
  width: number
  size: number
  error: boolean
  loaded: boolean
  playing: boolean
  hidebar: boolean
  duration: number
  currentTime: number
  containerHeight: number
  containerWidth: number
  sliderVal: number
}

export class VideoPlayer extends Component<IProps, IState> {
  public videoInterval?: NodeJS.Timeout;

  public hideBarMove?: NodeJS.Timeout;

  private videoRef = createRef<HTMLVideoElement>();

  private containerRef = createRef<HTMLDivElement>();

  private changingTime: number | null = null;

  private firstChange = false;

  public constructor(props: IProps) {
    super(props);
    this.state = {
      error: false,
      loaded: false,
      playing: false,
      hidebar: false,
      duration: 0,
      currentTime: 0,
      height: -1,
      width: -1,
      containerHeight: 1,
      containerWidth: 1,
      size: 700,
      sliderVal: 0,
    };
  }

  public updateContainerDimensions() {
    if (!this.containerRef.current) {
      return;
    }
    this.setState({
      containerWidth: this.containerRef.current.clientWidth,
      containerHeight: this.containerRef.current.clientHeight,
    });
  }

  public componentDidMount() {
    /* Some twitter videos have m3u8 format. They need Http Live Streaming (HLS) protocol to be read. */
    const videoUrl = this.props.url || this.props.media.video;
    if (videoUrl && this.isM3U8(videoUrl)) {
      this.useHls(videoUrl);
    }

    this.updateContainerDimensions();
    window.addEventListener('resize', this.updateContainerDimensions.bind(this));
    this.videoInterval = setInterval(() => {
      if (!this.videoRef.current) {
        return;
      }
      const { currentTime } = this.videoRef.current;
      const { duration } = this.videoRef.current;

      if (this.changingTime) {
        if (Date.now() - this.changingTime > 100) {
          this.changingTime = null;
        }
        return;
      }

      if (this.state.loaded) {
        this.setState({
          sliderVal: currentTime / duration * 100,
        });
        this.setState({
          duration: Math.floor(duration),
          currentTime: Math.floor(currentTime),
        });
      }
    }, 1000 / 24);
    this.onMouseMove();
  }

  public componentWillUnmount() {
    if (this.videoInterval) {
      clearInterval(this.videoInterval);
    }
    if (this.hideBarMove) {
      clearTimeout(this.hideBarMove);
    }
    window.removeEventListener('resize', this.updateContainerDimensions);
  }

  public onPlayButton(playing: boolean) {
    if (!this.videoRef.current) {
      return;
    }
    this.setState({
      playing,
      hidebar: !playing ? false : this.state.hidebar,
    });
    this.videoRef.current[playing ? 'play' : 'pause']();
  }

  public setVideoTime(val: number) {
    if (this.videoRef.current) {
      this.videoRef.current.currentTime = this.videoRef.current.duration * (val / 100);
    }
  }

  public onChangeTime(e: Event, val: number | number[]): void {
    const sliderVal = Array.isArray(val) ? val[0] : val;
    this.changingTime = Date.now();
    this.setState({
      sliderVal,
    });
    this.setVideoTime(sliderVal);
  }

  public onEnd() {
    this.onPlayButton(false);
  }

  public onError() {
    this.onPlayButton(false);
    return this.setState({ error: true });
  }

  public onLoaded() {
    this.setState({ loaded: true });
    this.onPlayButton(true);
  }

  public onMouseMove() {
    if (this.hideBarMove) {
      clearTimeout(this.hideBarMove);
    }
    this.setState({
      hidebar: false,
    });
    this.hideBarMove = setTimeout(() => {
      if (this.state.playing && DomHelper.elementIsHovered(ReactDOM.findDOMNode(this.refs.bar))) {
        this.setState({
          hidebar: true,
        });
      }
    }, 2000);
  }

  public onMouseLeave() {
    if (this.state.playing) {
      this.setState({
        hidebar: true,
      });
    }
  }

  public render() {
    const { image_large: imageURL, dimensions } = this.props.media;
    return this.props.media
            && (
            <div
              className={sheet.videoPlayer}
              ref={this.containerRef}
              onMouseMove={this.onMouseMove.bind(this)}
              onMouseLeave={this.onMouseLeave.bind(this)}
              data-loaded={this.state.loaded}
              style={{
                background: `linear-gradient(rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.7)), url(${imageURL})`,
                backgroundRepeat: 'none',
                backgroundSize: 'cover',
                backgroundPosition: 'center',
              }}
            >
              {this.props.media.instagramHack ? (
                this.instagramHack({
                  height: this.state.containerHeight,
                  width: this.state.containerWidth,
                }, dimensions, this.props.media.isVertical, this.props.media.isReel)
              ) : (
                <>
                  <div
                    className={classes(sheet.playPause, {
                      [sheet.noMouse]: this.state.hidebar,
                    })}
                    onClick={() => this.onPlayButton(!this.state.playing)}
                  >
                    {!this.state.playing && (
                    <PlayArrowRoundedIcon
                      className={playIconClass}
                      style={{ fontSize: '6rem' }}
                    />
                    )}
                  </div>
                  {this.state.loaded && (
                  <div className={sheet.bar} data-hide={this.state.hidebar.toString()} ref="bar">
                    <div className={sheet.progression}>
                      <Slider
                        className={sheet.slider}
                        aria-labelledby="continuous-slider"
                        value={this.state.sliderVal}
                        onChange={this.onChangeTime.bind(this)}
                      />
                    </div>
                    <div className={sheet.time}>
                      <VideoTime value={this.state.currentTime} />
                      <span className={sheet.separator}>/</span>
                      <VideoTime value={this.state.duration} />
                    </div>
                  </div>
                  )}
                  {!!this.props.media
                            && (
                            <video
                              ref={this.videoRef}
                              onEnded={this.onEnd.bind(this)}
                              onLoadedMetadata={this.onLoaded.bind(this)}
                              src={this.props.url || this.props.media.video}
                              onError={this.onError.bind(this)}
                            />
                            )}
                  <div className="error">
                    {window.T('collect.media.fullscreen.videoerror')}
                  </div>
                </>
              )}
            </div>
            );
  }

  private instagramHack(
    containerDimensions: Dimensions,
    videoDimensions?: Dimensions,
    isVertical?: Boolean,
    isReel?: Boolean,
  ) {
    if (!videoDimensions || containerDimensions.height === -1) {
      return null;
    }
    const containerRatio = containerDimensions.width / containerDimensions.height;
    const videoRatio = videoDimensions.width / videoDimensions.height;

    let size: Dimensions | null;

    if (videoRatio > containerRatio) {
      size = {
        width: containerDimensions.width,
        height: containerDimensions.width / videoRatio,
      };
    } else {
      size = {
        width: containerDimensions.height * videoRatio,
        height: containerDimensions.height,
      };
    }

    const instagramEmbed = `https://www.instagram.com/` + (isReel ? `reel` : `p`) + `/${this.props.media.instagramHack}/embed/captioned/`;

    if (!size) {
      return null;
    }
    const videoStyle = {
      width: size.width,
      height: size.height,
    };

    const transformStyle = videoRatio < 1 ? { transform: 'scale(1.5) translateY(78px)' } : { transform: 'translateY(-35px) scale(1.1)' };

    return (
      <Column className={instagramIframeContainerClass}>
        <Column
          className={instagramIframeSubContainerClass}
          style={isVertical ? { ...videoStyle, ...transformStyle } : videoStyle}
        >
          <iframe
            id="instagram-embed-0"
            data-instgrm-payload-id="instagram-media-payload-0"
            allowFullScreen
            className={classes(['instagram-media instagram-media-rendered', instagramIframeClass])}
            scrolling="no"
            style={{
              position: 'absolute',
              width: '100%',
              height: size.height + INSTAGRAM_IFRAME_HEADER - 2, // Sometimes there is one pixel line at bottom
              top: -INSTAGRAM_IFRAME_HEADER,
            }}
            src={instagramEmbed}
            frameBorder={0}
          />
        </Column>
      </Column>
    );
  }

  private useHls(videoUrl: string): void {
    const hls = new Hls();
    // Bind them together
    hls.attachMedia(this.videoRef.current!);
    // MEDIA_ATTACHED event is fired by hls object once MediaSource is ready
    hls.on(Hls.Events.MEDIA_ATTACHED, () => {
      hls.loadSource(videoUrl);
    });
  }

  private isM3U8(videoUrl: string): boolean {
    /*
        Remove params from url to easily get file type:
        https://someurl.m3u8?tags=10 --> https://someurl.m3u8
        */
    const urlPathname: string = new URL(videoUrl).pathname;
    const isM3u8: boolean = urlPathname.split('.').pop() === 'm3u8';
    if (isM3u8) {
      return true;
    }
    return false;
  }
}

const instagramIframeContainerClass = style({
  height: '100%',
  width: '100%',
  justifyContent: 'center',
});

const instagramIframeSubContainerClass = style({
  position: 'relative',
  overflow: 'hidden',
  flexGrow: 0,
  margin: 'auto',
});

const instagramIframeClass = style({
  width: '100%',
  position: 'absolute',
});

const playIconClass = style({
  margin: 'auto',
});

const sheet = (() => {
  const $height = 20;
  const $size = 100;
  const $padding = 12;
  const $colorButtons = '#3a76ff';
  return stylesheet({
    videoPlayer: {
      position: 'relative',
      overflow: 'hidden',
      height: '100%',
      width: '100%',
      $nest: {
        video: {
          width: '100%',
          height: '100%',
        },
        '.error': {
          display: 'none',
          padding: '80px 0',
        },
        '&[data-error="true"], &[data-loaded="false"]': {
          overflow: 'visible',
          $nest: {
            '&video, &.play-pause': {
              display: 'none',
            },
          },
        },
        '&[data-error="true"] .error': {
          display: 'block',
        },
      },
    },
    slider: {
      marginTop: -3,
    },
    playPause: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      zIndex: 2,
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      textAlign: 'center',
      fontSize: '3.4rem',
      cursor: 'pointer',
      $nest: {
      },
    },
    noMouse: {
      cursor: 'none',
    },
    bar: {
      overflow: 'hidden',
      position: 'absolute',
      bottom: 0,
      left: 0,
      width: '100%',
      zIndex: 3,
      height: $height,
      backgroundColor: 'rgba(0, 0, 0, 0.35)',
      transition: 'bottom 0.6s',
      $nest: {
        '&[data-hide="true"]': {
          bottom: -$height,
        },
      },
    },
    progression: {
      height: '100%',
      paddingRight: $size + $padding,
      paddingLeft: $padding,
      $nest: {
        '.MuiSlider-rail': {
          backgroundColor: 'rgba(233, 233, 233, 0.23)',
        },
        '.MuiSlider-track': {
          color: $colorButtons,
        },
        '.MuiSlider-thumb': {
          color: '#e9e9e9',
        },
      },
    },
    time: {
      width: $size,
      height: '100%',
      position: 'absolute',
      right: 0,
      top: 0,
      fontSize: 13,
      padding: '1px 8px',
      color: '#dfdfdf',
      letterSpacing: 0,
    },
    separator: {
      margin: 3,
      fontSize: 10,
      verticalAlign: 'text-bottom',
    },
  });
})();
