import React, { Component } from 'react';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';
import { PerfectScrollbar } from 'components/PerfectScrollbar';
import { style } from 'typestyle';
import { ThemeStyle } from '@src/styles';
import { ThemeProvider, createTheme, CircularProgress } from '@mui/material';

const materialTheme = createTheme({
  components: {
    MuiTable: {
      styleOverrides: {
        root: {
          width: '95%',
          margin: 'auto',
        },
      },
    },
    MuiTableRow: {
      styleOverrides: {
        root: {
          borderBottom: 'none',
        },
      },
    },
    MuiTableCell: {
      styleOverrides: {
        root: {
          padding: 5,
          top: 0,
          color: 'white !important',
          fontSize: `${ThemeStyle.textSizes.big}px !important`,
          fontWeight: '300 !important',
          fontFamily: ThemeStyle.fonts.primary,
          borderBottom: 'none',
        },
        head: {
          position: 'sticky',
          boxShadow: '0px 1px #979797',
          background: '#151515',
          textTransform: 'uppercase',
          fontWeight: '700 !important',
          fontSize: `${ThemeStyle.textSizes.small}px !important`,
        },
        body: {
          borderBottom: 'none',
        },
      },
    },
  },
} as any);

export interface ListColumn<T> {
  label: string
  displayName: (elem: T) => string | JSX.Element
  onClick?: (elem: T) => any
  minWidth?: string | number
}

interface Props<T> {
  list: T[]
  columns: ListColumn<T>[]
  emptyMessage: string
  loading?: boolean
}

export class TableList<T> extends Component<Props<T>> {
  public render() {
    return (
        <div className={contentClass}>
          <ThemeProvider theme={materialTheme}>
            {this.props.list.length ? (
                <PerfectScrollbar>
                  <Table>

                    <TableHead>
                      <TableRow>
                        {this.props.columns.map((element) => (
                            <TableCell
                                key={element.label}
                                align="left"
                                style={{ minWidth: element.minWidth || 10 }}
                            >
                              {element.label}
                            </TableCell>
                        ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {this.props.list.map((elem, idx) => (
                          <TableRow
                              className={listClass}
                              hover role="checkbox"
                              key={idx}
                          >
                            {this.props.columns.map((column, col_idx) => {
                              const child = column.displayName(elem);
                              const { onClick } = this.props.columns[col_idx];
                              return (
                                  <TableCell onClick={() => onClick && onClick(elem)} key={`${idx}-${col_idx}`} align="left">
                                    {child}
                                  </TableCell>
                              );
                            })}
                          </TableRow>
                      ))}

                    </TableBody>

                  </Table>
                </PerfectScrollbar>
            ) : (
              !this.props.loading && <div className={nothingClass}>{this.props.emptyMessage}</div>
            )}
            {this.props.loading && (
                <div style={{
                  display: 'flex',
                  alignContent: 'center',
                  justifyContent: 'center',
                  textAlign: 'center',
                  paddingTop: 80,
                }}
                >
                  <CircularProgress/>
                </div>
            )}
          </ThemeProvider>
        </div>
    );
  }
}

const contentClass = style({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
  height: 400,
});

const listClass = style({
  cursor: 'pointer',
  transition: 'background-color 0.2s',
  $nest: {
    '&:hover': {
      backgroundColor: '#313131 !important',
      color: 'gray !important',
    },
  },
});

const nothingClass = style({
  textAlign: 'center',
  fontSize: 20,
  color: 'gray',
  marginTop: 12,
});
