import React from 'react';
import { SearchSuggestion } from '@src/types/Filters';
import { Suggestions } from '../Suggestions';
import { LanguageItem } from '../FilterItem/LanguageItem';
import {useMediaFindFacet} from "@src/queries/media/useQueryMediaFind";
import { useRecoilState, useRecoilValue } from "recoil";
import {
  FacetState,
  FilterState
} from "@src/pages/Discover/components/Atoms/Atoms";
import {XclusionUtility} from "@src/fragments/Filters/managers/XclusionUtility";
import {onFilterChange} from "@src/fragments/Filters/utils/FilterStateUtility";
import {languageExcludeState, languageInput} from "@src/pages/Discover/components/Selectors/Selectors";

export function LanguageSuggestion() {

  const [filters, setFilters ] = useRecoilState(FilterState)
  const [facetState, ] = useRecoilState(FacetState)
  const matchesSearch = (display: string, filterInput: string): boolean => filterInput === '' || display.toLowerCase().includes(filterInput.toLowerCase());

  const filterLanguageInput = useRecoilValue(languageInput)

  const exclude = useRecoilValue(languageExcludeState)

  // Language
  const {
    data: resFilterLanguage,
    isLoading: isLoadingFilterLanguage,
  } = useMediaFindFacet(
      facetState.mediaConstraints,
      '', // filtering language will done locally after the response
      'languages',
      0,
      facetState.enableFacet,
  );

  const allLanguages = resFilterLanguage?.pages[0].facets?.languages ?? []
  const selectedLanguages = filters.lang.map((val) => ({key: val, display: XclusionUtility.getRawKey(val)})).filter(
      (lang) => matchesSearch(lang.display, filterLanguageInput)
  )
  const filteredLanguages = allLanguages.map(
      (value) => ({
        key: value,
        display: `${value}`,
      }),
  ).filter(
      (lang) => matchesSearch(lang.display, filterLanguageInput)
  )


  return (
    <Suggestions
      onChange={onFilterChange(setFilters, 'lang')}
      selectedValues={renderValues(selectedLanguages)}
      values={renderValues(filteredLanguages)}
      exclude={exclude}
    />
  );
}

function renderValues(suggestions: SearchSuggestion[]) {
  return suggestions.map((suggestion) => ({
    key: suggestion.key,
    children: (
      <LanguageItem suggestion={suggestion} />
    ),
  }));
}
