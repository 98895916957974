import React, { Component } from 'react';
import { Row } from '@src/components/Utils';
import LocalizedStrings from 'react-localization';
import { SelectableButton } from '@src/components/SelectableButton';
import { XclusionSelection, XclusionManager } from '@src/types/Filters';
import { style } from 'typestyle';

interface Props {
  selected: XclusionSelection
  onChange: XclusionManager['onXclusionSwitch']
}

export class XclusionSwitch extends Component<Props> {
  public render() {
    const { selected, onChange } = this.props;
    return (
      <div>
        <Row className={switchClass}>
          {this.renderButton('inclusion', selected === 'inclusion', onChange)}
          <span>
            {lang.or}
          </span>
          {this.renderButton('exclusion', selected === 'exclusion', onChange)}
        </Row>
      </div>
    );
  }

  private renderButton(
    selection: XclusionSelection,
    selected: boolean,
    onChange: (selection: XclusionSelection) => void,
  ) {
    return (
      <SelectableButton
        key={selection}
        buttonStyle={{ size: 12 }}
        selected={selected}
        onClick={() => onChange(selection)}
      >
        {lang[selection].toUpperCase()}
      </SelectableButton>
    );
  }
}

const lang = new LocalizedStrings({
  en: {
    inclusion: 'Inclusion',
    exclusion: 'Exclusion',
    or: 'OR',
  },
});

const switchClass = style({
  width: '85%',
  justifyContent: 'space-between',
  marginBottom: 13,
});
