import { useQuery } from 'react-query';
import { queryClient, QUERY_KEYS } from '@src/query';
import { getUserConfig } from './api';

/**
 * Get config
 */
export const useQueryUserConfig = () => useQuery(
  QUERY_KEYS.userConfig(), 
  getUserConfig, {
    staleTime: Infinity,
    cacheTime: Infinity,
  }
);

export function prefetchQueryUserConfig() {
  return queryClient.prefetchQuery(QUERY_KEYS.userConfig(), getUserConfig);
}
