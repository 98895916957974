import React, { useEffect, useState } from 'react';

import { useDispatch, useSelector } from 'react-redux';
import * as ShareActions from 'actions/share';
import { MissingSocial } from 'components/MissingSocial';
import {
  FacebookPageInterface, FacebookAdsInterface, InstagramPageInterface, IPublishPage,
} from '@src/types/Business';

import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { addAlert } from '@src/services/helpers/redux';
import { PerfectScrollbar } from '@src/components/PerfectScrollbar';
import { ICombinedReducers } from '@src/types/redux';
import { NetworkType, ShareNetworkType, SharePopupInfo } from '@src/types/redux/share';
import LocalizedStrings from 'react-localization';
import { ActionButton } from '@src/components/ActionButton';
import FacebookIcon from '@mui/icons-material/Facebook';
import InstagramIcon from '@mui/icons-material/Instagram';
import { stylesheet } from 'typestyle';
import { Loader } from '@src/components/Loader';
import { FormButtons } from '@src/components/Form/FormButtons';
import { useQueryUserConfig } from '@src/queries/users/useQueryUserConfig';
import { DisplayPreviewView, MetaPermission, OldApiTypes, UserView } from '@src/../common/dist';
import DisplayPreview from './components/DisplayPreview';
import { bigStore } from '../../../../store';
import styles from './styles.scss';
import FacebookAds from './components/FacebookAds';
import FacebookPage from './components/FacebookPage';
import InstagramPage from './components/InstagramPage';
import { useQueryFacebookPages } from '@src/queries/users/useQueryFacebookPages';
import { useQueryFacebookAdAccounts } from '@src/queries/users/useQueryFacebookAds';
import { ArrayHelper } from '@src/services/helpers/array';
import { useQueryMetaPermission } from '@src/queries/users/useQueryUserPermissions';
import { MissingPermission } from '@src/components/MissingPermission';

type PublishData = OldApiTypes.Share.IBodyPublish;

// ideally, this component should be rendered by Root.tsx and be displayed when the
// url query allow it
export default function SharePopup(): JSX.Element | null {
  const dispatch = useDispatch();

  const info = useSelector((state: ICombinedReducers) => state.share.sharePopup);
  const displayPreview = useSelector((state: ICombinedReducers) => state.share.displayPreview);
  const error = useSelector(
    (state: ICombinedReducers) => state.share.publishError,
  );
  const publishing = useSelector(
    (state: ICombinedReducers) => state.share.publishing,
  );

  const [anchorEl, setAnchorEl] = useState<EventTarget & HTMLElement>();
  const [scheduler, setScheduler] = useState<boolean>(false);
  const [scheduledDate, setScheduledDate] = useState<Date | null>(
    () => (info?.date ? new Date(info?.date) : null),
  );
  const [selectedFacebookPage, setSelectedFacebookPage] = useState<FacebookPageInterface>();
  const [selectedFacebookAds, setSelectedFacebookAds] = useState<FacebookAdsInterface>();
  const [selectedInstagramPage, setSelectedInstagramPage] = useState<InstagramPageInterface>();
  const [message, setMessage] = useState<string>(info?.comment || '');
  const [shareFeed, setShareFeed] = useState<boolean>(info?.shareFeed || false);
  const [title, setTitle] = useState<string>('');

  const { data: userConfig } = useQueryUserConfig();
  const { data: facebookPages = [] } = useQueryFacebookPages();
  const { data: facebookAds = [] } = useQueryFacebookAdAccounts();
  const { data: metaPermissions = [] } = useQueryMetaPermission();

  // Keep only instagram pages from facebook pages
  const filteredInstagramPages = ArrayHelper.filterNulls(
    facebookPages.map((fbp) => {
      let f = fbp as any;
      if (f.instagram) {
        return (f.instagram);
      }
      return null;
    }),
  );

  if (!info || !info.target) {
    return null;
  }

  // Check if user is connected to required SocialNetwork  + if Social Network exist
  const isMissingSocial = missingSocial(info.target, userConfig?.user.oauth);

  // Check if user granted sharing permissions
  const isMissingMetaPermission = missingMetaPermission(info.target);

  // Use for Instagram with Buffer Publishing Menu
  const open = Boolean(anchorEl);
  const options = [
    'Share now',
    'Add to Queue',
  ];

  // If it's a content from the displaypreview grid, check the order
  const incorrectOrder = checkIncorrectScheduledOrder(
    scheduledDate,
    info,
    displayPreview,
  );
  const dateInTheFuture = !scheduledDate ? true : scheduledDate?.getTime() > Date.now();

  return (
    <div className={`${styles.sharePopup} popup-overlay`}>
      <div className="frame" onClick={(e) => e.stopPropagation()}>
        <div className="left">
          <img src={info.image} />
        </div>
        <PerfectScrollbar className="right">
          <TopTitle target={info.target} handleClose={close} />
          {isMissingSocial ? (
            <MissingSocial
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              text={window.T('share.popup.missingsocial')}
            />
          ) : null}
          {!isMissingSocial && isMissingMetaPermission ? (
            <MissingPermission
              // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment
              text={window.T('share.popup.missing_permission')}
            />
          ) : null}
          <>
            {info.target === 'facebookpage'
              && <FacebookPage
                disable={isMissingSocial}
                creator={info?.creator}
                pages={facebookPages}
                selectedPage={selectedFacebookPage}
                selectPage={setSelectedFacebookPage}
                onMessageChange={setMessage}
                message={message}
                title={title}
                onTitleChange={setTitle}
                isVideo={info?.fileType === 'video'}
                missingSharePerm={isMissingMetaPermission}
              />
            }
            {info.target === 'facebookads'
              && <FacebookAds
                disable={isMissingSocial}
                adAccounts={facebookAds}
                selectedAdAccount={selectedFacebookAds}
                selectAdAccount={setSelectedFacebookAds}
                title={title}
                onTitleChange={setTitle}
                missingSharePerm={isMissingMetaPermission}
              />
            }
            {info.target === 'instagrampage'
              && <InstagramPage
                disable={isMissingSocial}
                creator={info?.creator}
                pages={filteredInstagramPages}
                selectedPage={selectedInstagramPage}
                selectPage={setSelectedInstagramPage}
                message={message}
                onMessageChange={setMessage}
                shareFeed={shareFeed} //Share to feed selector
                onShareFeedChange={setShareFeed}
                isVideo={info?.fileType === 'video'}
                missingSharePerm={isMissingMetaPermission}
              />
            }
            {info.target === 'displaypreview'
              && <DisplayPreview
                disable={isMissingSocial}
                defaultComment={message}
                creator={info?.creator}
                message={message}
                onMessageChange={setMessage}
              handleDateChange={handleDateChange}
              scheduledDate={scheduledDate}
              />
            }
          </>
          {info.target != 'displaypreview' && (
            <div className="content">
              <div className="line">
                <div className="title">{window.T('share.popup.trackingIdTitle')}</div>
                <div className="field">
                  <input
                    className="input"
                    value={info?._id}
                    disabled={true}
                  />
                </div>
              </div>
            </div>
          )}
          <div className={sheet.state}>
            {(error || !dateInTheFuture) && (
              <div className="alert alert-danger ">
                {error || lang.dateInThePast}
              </div>
            )}
            {incorrectOrder ? (
              <div className="alert alert-warning ">
                {lang.dateOrderWarning}
              </div>
            ) : null}
            {(publishing) && (
              <><div className="alert alert-info ">
                {window.T('share.popup.publishprocessing')}
              </div>
                <Loader size={30} /></>
            )}
          </div>
          <FormButtons>
            <div className={sheet.button}>
              <RenderLeftButton
                info={info}
                target={info.target}
              />
            </div>
            <div className={sheet.button}>
              <RenderRightButton
                info={info}
                target={info.target}
              />
              <Menu
                id="simple-menu"
                anchorEl={anchorEl}
                open={open}
                onClose={() => handleClose()}
              >
                {options.map((option) => (
                  <MenuItem
                    key={option}
                    selected={option === 'Pyxis'}
                    onClick={() => handleClose(option)}
                  >
                    {option}
                  </MenuItem>
                ))}
              </Menu>
            </div>
          </FormButtons>
        </PerfectScrollbar>
      </div>
    </div>
  );

  function close() {
    dispatch(ShareActions.closeSharePopup());
  }

  function handleDateChange(date: Date | null) {
    if (date !== null) {
      setScheduledDate(date);
      setScheduler(true);
    }
  }

  async function onPublish() {
    publish().then(() => {
      close();
    });
  }
  
  async function onSave() {
    if (await save()) {
      addAlert({ text: lang.saveSuccess, type: 'success' });
      close();
    }
  }

  /**
   * Unlike the name can suggest, it only open Instagram Share modal and close the current one
   * It's mostly a quick solution since sharing UI require a huge refactor
   */
  async function onPublishNow(info: SharePopupInfo) {
    dispatch(ShareActions.closeSharePopup());
    dispatch(ShareActions.openSharePopup("instagrampage", info));
  }

  function RenderQuitButton({
    info,
    onClose
  }: {
    info: SharePopupInfo,
    onClose: () => void
  }) {
    let disableQuit = publishing;
    if (info && info.saveDraft) {
      return null;
    }
    return (
      <Button 
        className={`button button-border ${disableQuit ? 'button-disabled' : ''}`}
        onClick={onClose}
      >
        {lang.buttons.cancel.toUpperCase()}
      </Button>
    );
  }
  
  function RenderLeftButton({
    info,
    target,
  }: {
    info: SharePopupInfo,
    target: Target,
  }) {
    if (target === 'displaypreview') {
      return (
        <ActionButton
          variant="transparent"
          onClick={() => onPublishNow(info)}
          minWidth={200}
        >
          <InstagramIcon fontSize="small" />  {lang.buttons.publishNow.toUpperCase()}
        </ActionButton>
      )
    }
    else {
      return null
    }
  }

  function RenderRightButton({
    info,
    target,
  }: {
    info: SharePopupInfo,
    target: Target,
  }) {
    let disablePublish = isMissingSocial || isMissingMetaPermission || publishing;
    if (target === 'displaypreview' || !getTargetPage(target)) {
      disablePublish = true;
    }
  
    if (target === 'displaypreview') {
      if (info?.saveDraft) {
        return (
          <ActionButton
            variant="transparent"
            onClick={() => onSave()}
            minWidth={200}
          >
            {lang.buttons.save.toUpperCase()}
          </ActionButton>
        );
      } else {
        return (
          <Button
            className={`button button-blue ${disablePublish ? 'button-disabled' : ''}`}
            onClick={onPublish}
          >
            {lang.buttons.publish.toUpperCase()}
          </Button>
        );
      }
    } else {
      return (
        <Button
          className={`button button-blue ${disablePublish ? 'button-disabled' : ''}`}
          onClick={onPublish}
        >
          {lang.buttons.publish.toUpperCase()}
        </Button>
      );
    }
  }

  function getTargetPage(target: Target): IPublishPage | undefined {
    return {
      facebookpage: selectedFacebookPage,
      facebookads: selectedFacebookAds,
      instagrampage: selectedInstagramPage,
    }[target as NetworkType];
  }

  function handleClose(option?: string) {
    if (info === null) {
      return;
    }
    if (option) {
      const infoToPublish = { ...info };
      infoToPublish.publishOption = option;
      if (option !== 'Schedule') {
        publish(infoToPublish).then(() => {
          close();
        }).catch((e) => {
          throw e;
        });
      } else if (option === 'Schedule') {
        setScheduler(true);
      }
    }
    setAnchorEl(undefined);
  }

  /*
    Publish action
  */
  async function publish(customInfo?: SharePopupInfo) {
    if (info === null) {
      return;
    }
    let infoToPublish: SharePopupInfo = customInfo || info;
    // clone props
    infoToPublish = { ...infoToPublish };

    const { target }: SharePopupInfo = infoToPublish || info;
    if (target === undefined) {
      return;
    }

    const publishTo = getTargetPage(target);

    if (!publishTo) {
      return;
    }
    if (!infoToPublish) {
      infoToPublish = { ...info };
    }
    const publishData: PublishData = {
      target,
      publishTo,
      info: {
        file: infoToPublish.file || '',
        fileType: infoToPublish.fileType,
        image: infoToPublish.image || '',
        message,
        title,
        trackingId: infoToPublish._id || '',
        shareFeed,
      },
    };
    await save();
    await ShareActions.publish(publishData).then(() => {
      addAlert({ type: 'success', text: scheduledDate ? lang.scheduledSuccess : lang.publishSuccess });
    }).catch((err) => {
      bigStore.dispatch(ShareActions.publishedFailed(window.T(`share.popup.errors.${err.type || 'unknown'}`)));
      throw err;
    });
  }

  async function save(): Promise<boolean> {
    if (!info?.saveDraft) {
      return false;
    }
    return info.saveDraft(message, scheduledDate || undefined);
  }

  /*
    Return state for given Meta Permission

    @param perm string : Tested permission

    @return boolean : true = missing, false = granted
  */
  function missingMetaPermission(target: Target): boolean {
    if (target === "displaypreview") {
      return false;
    }
    let perm: string;
    if (target === "facebookpage") {
      perm = "pages_manage_posts";
    }
    else if (target === "instagrampage") {
      perm = "instagram_content_publish";
    }
    else if (target === "facebookads") {
      perm = "ads_management";
    }

    const reqPerm = metaPermissions.find((p) => {
      return p.permission === perm;
    });
    if (reqPerm && reqPerm.status === "granted") {
      return false;
    }
    return true;
  }

}

function checkIncorrectScheduledOrder(
  date: Date | null,
  info: SharePopupInfo,
  displayPreview: DisplayPreviewView | null,
): boolean {
  const content = displayPreview?.content || [];
  if (!info || !info.saveDraft || !date || !displayPreview || !info.displayContentId) {
    return false;
  }
  const index = content.findIndex((c) => c._id === info.displayContentId);
  if (index === -1) {
    return false;
  }

  return !!content.find((c, i) => {
    if (c._id === info.displayContentId || !c.date) {
      return false;
    }
    const infDate = new Date(c.date) < date;
    const supDate = new Date(c.date) > date;
    if ((i < index && infDate) || (i > index && supDate)) {
      return true;
    }
    return false;
  });
}

type Target = ShareNetworkType;

function TopTitle({
  target,
  handleClose,
}: {
  target: Target,
  handleClose: () => void
}) {
  return (
      <div className="title">
        {(target === 'facebookads' || target === 'facebookpage') && (
        <div className={sheet.socialNetworkIcon}>
          <FacebookIcon fontSize="inherit" />
        </div>
        )}
        {(target === 'instagrampage') && (
        <div className={sheet.socialNetworkIcon}>
          <InstagramIcon fontSize="inherit" />
        </div>
        )}
        <div className="remove" onClick={handleClose}>
          <i className="material-icons notranslate">close</i>
        </div>
        {lang[target].title}
      </div>
  );
}

function missingSocial(target: Target, oauth: UserView['oauth']) {
  if (target === 'displaypreview') {
    return false;
  }
  return {
    facebookpage: oauth?.facebook,
    facebookads: oauth?.facebook,
    instagrampage: oauth?.facebook,
  }[target] === undefined;
}

const lang = new LocalizedStrings({
  en: {
    facebookpage: {
      title: 'Share to Facebook',
    },
    facebookads: {
      title: 'Share to Facebook ',
    },
    instagrampage: {
      title: 'Share to Instagram',
    },
    displaypreview: {
      title: 'Edit Post information',
    },
    buttons: {
      cancel: 'CANCEL',
      save: 'Save',
      publish: 'Publish',
      schedule: 'Schedule',
      publishNow: "Publish Now"
    },
    saveSuccess: 'Successfully saved',
    publishSuccess: 'Content successfully published',
    scheduledSuccess: 'Content successfully scheduled',
    dateOrderWarning:
      'According to the order of the grid, this content’s publishing date is not correct. Please choose another time',
    dateInThePast:
      'The scheduling date cannot be earlier than the current date',
  },
});

const sheet = stylesheet({
  socialNetworkIcon: {
    position: 'absolute',
    top: -24,
    left: 19,
    fontSize: 50,
    width: 45,
    height: 45,
  },
  state: {
    textAlign: 'center',
    marginBottom: 25,
  },
  button: {
    width: '45%',
    $nest: {
      button: {
        width: '100%',
      },
    },
  },
});