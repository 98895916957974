import React from 'react';
import { SectionFolder } from '@src/components/SectionFolder';
import { SectionTitle } from '@src/components/SectionTitle';
import { SectionProps } from '../types/Settings';
import LocalizedStrings from 'react-localization';
import { FORM_VALUE_TITLE_SIZE, INPUT_WIDTH, SECTION_TITLE_SIZE } from '../utils/variables';
import { SectionContent } from '../subcomponents/SectionContent';
import { createChangeSettings } from '../utils/createChangeSettings';
import { SettingsCheckbox } from '../subcomponents/SettingsCheckbox';
import { WidgetView } from 'common';
import { ProductDisplayIcon } from '../../Icons/DropDownIcons/ProductDisplay';
import { SubSectionContent } from '../subcomponents/SubSectionContent';
import { FormLine } from '@src/components/Form/FormLine';
import { Input } from '@src/components/Input';
import { style } from 'typestyle';

const ProductDisplay = ({ opened, onOpen, editedWidget, setEditedWidget }: SectionProps) => {
  const changeSettings = createChangeSettings(editedWidget, setEditedWidget);

  function updateMaxExactProductsDisplayed(targetValue: HTMLInputElement['value']): void {
    const newValue = parseInt(targetValue, 10);
    changeSettings({
      max_exact_products_displayed: newValue > 0 ? newValue : null,
    });
  }

  function updateMaxSimilarProductsDisplayed(targetValue: HTMLInputElement['value']): void {
    const newValue = parseInt(targetValue, 10);
    changeSettings({
      max_similar_products_displayed: newValue > 0 ? newValue : null,
    });
  }

  // uncheck a value when its counterpart is checked
  const handleCheckboxClick = (
    checkedKey: keyof WidgetView['settings'],
    newValues: Partial<WidgetView['settings']>,
  ) => {
    const newSettings: Partial<WidgetView['settings']> = {
      [checkedKey]: !editedWidget.settings[checkedKey],
      ...newValues,
    };
  
    changeSettings(newSettings);
  };

  return (
    <SectionFolder
      title={
        <SectionTitle size={SECTION_TITLE_SIZE}>
          <ProductDisplayIcon />
          {lang.title}
        </SectionTitle>
      }
      opened={opened}
      onOpen={onOpen}
    >
      <SectionContent>
        <SubSectionContent>
          <p className={logicalConnectorClass}> {lang.fields.exactDisplayed.toUpperCase()} </p>
          <FormLine title={lang.fields.max} size={FORM_VALUE_TITLE_SIZE}>
            <Input
              type='number'
              onChange={({ target }) => updateMaxExactProductsDisplayed(target.value)}
              value={editedWidget.settings.max_exact_products_displayed || ''}
              placeholder={lang.fields.maxPlaceholder}
              title={lang.fields.maxPlaceholder}
              style={{ width: INPUT_WIDTH, marginRight: '10px' }}
            />
          </FormLine>
          <SettingsCheckbox
            label={lang.fields.displayExactOnly}
            checked={editedWidget.settings.display_exact_products_only}
            onClick={() =>
              handleCheckboxClick('display_exact_products_only', {
                display_similar_products_only: false,
              })
            }
          />
          <p className={logicalConnectorClass}> {lang.fields.similarDisplayed.toUpperCase()} </p>
          <FormLine title={lang.fields.max} size={FORM_VALUE_TITLE_SIZE}>
            <Input
              type='number'
              onChange={({ target }) => updateMaxSimilarProductsDisplayed(target.value)}
              value={editedWidget.settings.max_similar_products_displayed || ''}
              placeholder={lang.fields.maxPlaceholder}
              title={lang.fields.maxPlaceholder}
              style={{ width: INPUT_WIDTH, marginRight: '10px' }}
            />
          </FormLine>
          <SettingsCheckbox
            label={lang.fields.displaySimilarOnly}
            checked={editedWidget.settings.display_similar_products_only}
            onClick={() =>
              handleCheckboxClick('display_similar_products_only', {
                display_exact_products_only: false,
              })
            }
          />
        </SubSectionContent>
      </SectionContent>
    </SectionFolder>
  );
};

const lang = new LocalizedStrings({
  en: {
    title: 'Product Display',
    fields: {
      displayExactOnly: 'Show EXACT products only',
      displaySimilarOnly: 'Show SIMILAR products only',
      exactDisplayed: 'Exact products displayed',
      similarDisplayed: 'Similar products displayed',
      max: 'Max',
      maxPlaceholder: 'Infinite',
    },
  },
});

const logicalConnectorClass = style({
  fontSize: 12,
  fontWeight: 'bold',
});

export default ProductDisplay;
