import { useMutation } from 'react-query';
import { ApiError } from '@src/services/api';
import { DEPRECATED_QUERY_KEYS, queryClient } from '@src/query';
import { deleteCollection } from './api';

export const useMutationCollectionDelete = () => useMutation<void, ApiError, { collectionId: string }>(({ collectionId }) => deleteCollection(collectionId), {
  onSuccess: async () => {
    const key = [DEPRECATED_QUERY_KEYS.COLLECTIONS];
    queryClient.invalidateQueries(key);
  },
});
