import React from 'react';
import { DropDownIcon } from './DropDownIcon';

export function SourcesIcon() {
  return (
    <DropDownIcon
      childrenRender={() => (
        <>
          <rect width='50' height='50' rx='10' fill='#D9D9D9' />
          <path
            d='M38.1191 14.4805H22.9564C22.6972 14.4805 22.3084 14.3507 22.1788 14.0909L21.1421 12.5325C20.4941 11.3636 19.0685 10.7143 17.643 10.7143H12.7184C10.5152 10.7143 8.57129 12.5325 8.57129 14.8701V35.1299C8.57129 37.4675 10.5152 39.2857 12.7184 39.2857H37.9895C40.3223 39.2857 42.1366 37.3377 42.1366 35.1299V18.6364C42.2662 16.4286 40.3222 14.4805 38.1191 14.4805ZM30.3434 31.1039H20.4941V27.8571H30.3434V31.1039ZM33.5833 26.1688H17.2542V22.9221H33.5833V26.1688Z'
            fill='#343434'
          />
        </>
      )}
    />
  );
}
