import { useMutation } from "react-query";
import { ApiError } from "@src/services/api";
import { sendMessage } from "./api";
import { ApiTypes } from "common";
import { queryClient, QUERY_KEYS } from "@src/query";
import { conversationUId } from "@src/pages/CRM/Messages/MessagesPage";

export const useMutationMessageCreate = () =>
  useMutation<any, ApiError, ApiTypes.ParamsSendDm>(
    (params) => sendMessage(params),
    {
      onSuccess: async (_, params) => {
        queryClient.invalidateQueries(
          QUERY_KEYS.conversationMessages(
            conversationUId(params.senderAccountId, params.interlocutorId)
          )
        );
      },
    }
  );
