import * as React from 'react';

import { connect } from 'react-redux';
import { ICombinedReducers } from '@src/types/redux';
import { setRoute, loadTranslation } from '@src/services/helpers/redux';
import { style, stylesheet } from 'typestyle';
import { Services, ServiceName } from '@src/types/Services';
import { Login as LoginService } from '@src/types/Login';
import { Pathname } from '@src/types/Browsing';
import { Loader } from '@src/components/Loader';
import { Row } from '@src/components/Utils';
import { FormError } from '@src/components/Form/FormError';
import { Link } from '@src/components/Link';
import MailLink from '@src/components/MailLink';
import { ADALONG_COLORS, ThemeStyle } from '@src/styles';
import { LoginState } from '@src/types/redux/login';
import { LoginSigninLabel } from './SigninLabel';
import { LoginFrameContainer } from './container/FrameContainer';
import { LoginContainer } from './container/LoginContainer';
import UserLogin from './UserLogin';
import logo from '../../assets/img/site/logo_alone_white.png';
import { DispatchType } from '../../store';
import * as LoginActions from '../../actions/login';

const ADALONG_MAIL = 'hello@adalong.com';

interface IProps {
  fieldChange: () => void
  error: LoginState['error']
  submitting: boolean
  logged?: boolean
  username: string
  password: string
  login: (username: string, password: string, captchaToken: string) => void
}

let loginService: LoginService;

export default function Login(props: IProps): JSX.Element {

  let errorElement: string | JSX.Element | undefined;
  if (props.error !== null) {
    try {
      isValidError(props.error);
      errorElement = lang.errors[props.error] || lang.errors.unknown;
    } catch (e) {
      errorElement = lang.errors.unknown;
    }
  }

  return (
    <LoginContainer>
      <LoginFrameContainer
        header={(
          <>
            <div className={sheet.loginLogo}>
              <img src={logo} />
            </div>
          </>
        )}
      >
        <LoginSigninLabel title={window.T('login.signin')} />
        {!props.submitting && !props.logged
          ? (
            <UserLogin
              login={props.login}
              emailLogin={loginService.emailLogin.bind(loginService)}
              uiConfig={loginService.getFirebaseUiConfig()}
            />
          ) : (
            <div className={loaderClass}>
              <Loader size={30} />
            </div>
          )}
        <Row className={sheet.forgotten}>
          <Link onClick={() => setRoute(Pathname.changePassword)}>{window.T('login.changeyourpassword')}</Link>
        </Row>
        <Row className={sheet.links}>
          <span>
            By signing in you will accept our &nbsp;
            <br />
            <Link className={sheet.link} href="https://www.adalong.com/terms-of-use/" target="_blank" rel="noreferrer">
              Terms of Service
            </Link>
            &nbsp;and&nbsp;
            <Link className={sheet.link} href="https://www.adalong.com/privacy/" target="_blank" rel="noreferrer">
              Privacy Policy
            </Link>
          </span>
        </Row>
        <p className={sheet.signUp}>
          If you don't have an account, please contact us at
          {' '}
          <MailLink mail={ADALONG_MAIL}>{ADALONG_MAIL}</MailLink>
        </p>
        <FormError
          error={errorElement}
          level="error"
        />
      </LoginFrameContainer>
    </LoginContainer>
  );
}

const sheet = stylesheet({
  loginLogo: {
    flexBasis: 0,
    margin: '0 auto',
    $nest: {
      img: {
        height: 80,
        margin: '5 auto 20px',
      },
    },
  },
  forgotten: {
    cursor: 'pointer',
    fontSize: 10,
    color: '#007bff',
    marginBottom: 10,
    marginTop: 10,
    justifyContent: 'center',
  },
  links: {
    justifyContent: 'center',
    fontSize: 12,
    color: '#CCC',
  },
  link: {
    fontSize: 15,
  },
  signUp: {
    fontSize: ThemeStyle.textSizes.small,
    color: ADALONG_COLORS.LIGHT_GRAY,
  },
});

function isValidError(error: string): asserts error is ValidError {
  if (!(error in lang.errors)) {
    throw Error(`Invalid error ${error}`);
  }
}

const loaderClass = style({ margin: 15 });

const translation = {
  en: {
    errors: {
      authusernotfound: 'Invalid user account',
      unsufficientrights: 'Invalid user account',
      nonexistinguser: <div>
        Your account was not created yet, please contact us at
        {' '}
        <MailLink mail={ADALONG_MAIL}>{ADALONG_MAIL}</MailLink>
        {' '}
        to register
      </div>,
      unregistered: <div>
        Your account was not created yet, please contact us at
        {' '}
        <MailLink mail={ADALONG_MAIL}>{ADALONG_MAIL}</MailLink>
        {' '}
        to register
      </div>,
      invalid: 'Invalid username or password',
      unknownemail: 'Unknown email',
      unknown: 'An error occured, please try again later',
      unverifiedEmail: 'Your email address was not verified, please check your emails',
      cantSendEmail: 'Could not send a validation email, please try again later or contact us',
      resetexpired: 'This link no longer seems valid',
      sessionlocked: 'Your session was locked due to too many unsuccessful attempts, please wait for another 30 minutes',
      authwrongpassword: 'The password is invalid or the user does not have a password',
      authinvalidemail: 'The email address is badly formatted.',
    },
  },
};

const lang = loadTranslation(translation);
type ValidError = keyof typeof lang.errors;

const ConnectedComponent = connect((state: ICombinedReducers) => ({
  oauthFailed: state.common.urlParams.oauthFailed,
  submitting: state.login.submitting,
  error: state.login.error,
  logged: state.login.logged,
}),
(dispatch: DispatchType) => ({
  fieldChange: () => dispatch(LoginActions.fieldChange()),
}))(Login);

export function LoginDep(services: Services) {
  loginService = services.get(ServiceName.Login);
  return ConnectedComponent;
}
