import React, { Component } from 'react';

import DnDGrid from 'components/DnDGrid';
import { bigStore } from 'store';
import { ICombinedReducers } from '@src/types/redux';
import { connect } from 'react-redux';
import DateFnsUtils from '@date-io/date-fns';
import { insertItemInToDisplay, setDisplayPreview, openSharePopup } from 'actions/share';
import DeleteIcon from '@mui/icons-material/Delete';
import ScheduleIcon from '@mui/icons-material/Schedule';
import * as reduxHelpers from '@src/services/helpers/redux';

import { api } from '@src/services/api';
import { ShareCollectionContentOverlay } from 'components/ShareCollectionContentOverlay';
import { DisplayPreviewContentView } from 'common';
import { SharePopupInfo } from '@src/types/redux/share';
import { WarningIcon } from '@src/components/WarningIcon';
import { InfoInsert } from '@src/types/VideoMaker';
import { MediaType } from '@adalong/schemas';
import Tooltip from '@mui/material/Tooltip';
import LocalizedStrings from 'react-localization';
import { stylesheet } from 'typestyle';
import { Column } from '@src/components/Utils';
import { Hide } from '@src/components/Hide';
import cloneDeep from 'lodash.clonedeep';
import translation from './translation';
import styles from './styles.scss';

const dateFns = new DateFnsUtils();

const lang = reduxHelpers.loadTranslation(translation);

const mapStateToProps = (state: ICombinedReducers) => ({
  displayPreview: state.share.displayPreview,
});

const mapDispatchToProps = {
  insertItemInToDisplay,
  setDisplayPreview,
  openSharePopup,
};

interface OwnProps {
  incorrectContentOrder: { [key: string]: boolean }
}

interface State {
  unavailable: { [key: string]: boolean | undefined }
}

type Props = Readonly<typeof mapDispatchToProps> & Readonly<ReturnType<typeof mapStateToProps>> & OwnProps;

class Grid extends Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      unavailable: {},
    };
  }

  public render() {
    return (
      <div className={styles.grid}>
        <DnDGrid
          onInsert={this.onInsert.bind(this)}
          onMoveItem={this.onMoveItem.bind(this)}
          validInsertInfo={this.validInsertInfo}
          gridElements={this.renderContent()}
        >
          {this.renderContent()}
        </DnDGrid>
      </div>
    );
  }

  public renderContent() {
    if (!this.props.displayPreview?.content) {
      return null;
    }
    return this.props.displayPreview.content.map((content, i) => {
      const url = this.getContentInfo(content).image;
      const available = !this.state.unavailable[content._id];
      return (
        <div
          key={i}
          className={styles.item}
        >
          <div className={styles.imageContainer}>
            <div
              className={styles.image}
              onError={() => this.handleImageError(content)}
            >
              <Hide hide={!available}>
                <Column className={sheet.imageContainer}>
                  <img className={sheet.image} src={url} />
                </Column>
              </Hide>
              {this.props.incorrectContentOrder[content._id] ? (
                <div className={styles.warning}>
                  <WarningIcon className={styles.warningIcon} />
                </div>
              ) : false}
              {
                available
                ? (
                  <ShareCollectionContentOverlay
                    onShare={() => this.onShare(content)}
                    isPreview={true}
                  >
                    {content.date ? (
                      <div className={styles.date}>
                        <ScheduleIcon />
                        <span>
                          {dateFns.format(dateFns.date(content.date) as Date, 'MMM. d h:mma' as any)}
                        </span>
                      </div>
                    ) : null}
                    <DeleteIcon className={styles.delete} onClick={() => this.onRemoveItem(i)} />
                  </ShareCollectionContentOverlay>
                )
                : (
                  <>
                  <Tooltip title={texts.deadURL}>
                      <WarningIcon className={styles.warningIcon} />
                  </Tooltip>
                  <DeleteIcon className={styles.delete} onClick={() => this.onRemoveItem(i)} />
                  </>
                )
              }
            </div>
          </div>
        </div>
      );
    });
  }

  public getContentInfo(content: DisplayPreviewContentView) {
    const info: SharePopupInfo = {
      image: '',
      file: '',
      fileType: content.media?.type && content.media.type != "text" ? content.media.type : "image",
      isInstaVideo: content.media?.source.name === 'instagram' && content.media.type === 'video',
      comment: content.comment,
      _id: content._id,
    };
    if (content.date) {
      info.date = content.date.toString();
    }
    if (content.brandcontent) {
      info.image = content.brandcontent.urls.thumbnail;
      info.file = content.brandcontent.urls.file;
      info.fileType = content.brandcontent.type;
    } else if (content.media) {
      info.image = content.media.cdn_image;
      info.fileType = content.media.type === MediaType.Image ? 'image' : 'video';
      info.creator = content.media.source.user_name;
    }
    return info;
  }

  public onShare(content: DisplayPreviewContentView) {
    const info = this.getContentInfo(content);
    this.props.openSharePopup('displaypreview', {
      ...info,
      displayContentId: content._id,
      saveDraft: this.handleSaveDraft(content), // Give a callback to SharePopup to save draft
    });
  }

  public handleSaveDraft(content: DisplayPreviewContentView) {
    return async function (comment?: string, date?: Date) {
      return api.saveDraftInItemFromDisplay(content._id, comment, date).then((displayPreview) => {
        bigStore.dispatch(setDisplayPreview(displayPreview));
        return true;
      }).catch(() => {
        reduxHelpers.addAlert({ text: lang.error.unknown, type: 'danger' });
        return false;
      });
    };
  }

  public onInsert(info: InfoInsert, position: number) {
    if (!this.props.displayPreview?.content) {
      return;
    }

    // Update locally
    const displayPreview = { ...this.props.displayPreview };
    const content: DisplayPreviewContentView = {
      type: info.type,
      [info.type]: info.content,
    } as any;
    displayPreview.content!.splice(position, 0, content);
    this.props.setDisplayPreview(displayPreview);

    // Update remotely
    this.props.insertItemInToDisplay(info, position).catch((e) => {
      console.error(e);
      reduxHelpers.addAlert({ text: lang.error.insert, type: 'danger' });
    });
  }

  public onMoveItem(itemIndex: number, newPosition: number) {
    if (!this.props.displayPreview?.content) {
      return;
    }

    const originalDisplayPreview = cloneDeep(this.props.displayPreview);

    // Update locally
    const displayPreview = { ...this.props.displayPreview };
    const content = [...displayPreview.content!];
    const [item] = content.splice(itemIndex, 1);
    if (!item._id) {
      return;
    }
    content.splice(newPosition, 0, item);
    displayPreview.content = content;
    this.props.setDisplayPreview(displayPreview);

    // Update remotely
    api.moveItemInDisplay(item._id, newPosition).catch((e) => {
      console.error(e);
      this.props.setDisplayPreview(originalDisplayPreview);
      reduxHelpers.addAlert({ text: lang.error.unknown, type: 'danger' });
    });
  }

  public onRemoveItem(itemIndex: number) {
    if (!this.props.displayPreview?.content) {
      return;
    }

    const originalDisplayPreview = cloneDeep(this.props.displayPreview);

    // Update locally
    const displayPreview = { ...this.props.displayPreview };
    const content = [...displayPreview.content!];
    const [item] = content.splice(itemIndex, 1);
    if (!item._id) {
      return;
    }
    displayPreview.content = content;
    this.props.setDisplayPreview(displayPreview);

    // Update remotely
    api.removeItemFromDisplay(item._id).catch((e) => {
      console.error(e);
      this.props.setDisplayPreview(originalDisplayPreview);
      reduxHelpers.addAlert({ text: lang.error.unknown, type: 'danger' });
    });
  }

  public validInsertInfo(info: any) {
    if (!info || !['share'].includes(info.origin)) {
      return false;
    }
    return true;
  }

  private handleImageError(content: DisplayPreviewContentView) {
    this.setState((state) => ({
      ...state,
      unavailable: {
        ...state.unavailable,
        [content._id]: true,
      },
    }));
  }
}

const texts = new LocalizedStrings({
  en: {
    deadURL: 'Image not available',
  },
});

const sheet = stylesheet({
  imageContainer: {
    overflow: 'hidden',
    position: 'absolute',
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
    justifyContent: 'center',
  },
  image: {
    margin: 'auto',
    height: '100%',
  },
});

export default connect(mapStateToProps, mapDispatchToProps)(Grid);
