import React, { Component } from 'react';
import { style, classes } from 'typestyle';
import { MarkDef } from '@src/types/Mark';

interface Props<T> {
  markDef: MarkDef<T>
  highlight?: string
  onClick?: (mark: MarkDef<T>) => void
  classes?: string[]
  suggestColor?: string
  iconSize?: number
}

export class Mark<T> extends Component<Props<T>> {
  public render() {
    const {
      markDef,
      highlight,
      onClick,
      classes: clss = [],
      iconSize,
    } = this.props;
    return (
      <div
        className={classes(markClass, ...clss)}
        onClick={() => onClick && onClick(markDef)}
        title={markDef.text}
      >
        <span className={classes(alignClass, symbolClass)}>
          <markDef.symbol style={{ fontSize: 12 }} />
        </span>
        {this.renderText(markDef.text, highlight)}
      </div>
    );
  }

  private renderText(text: string, highlight?: string) {
    if (!highlight) {
      return <span className={alignClass}>{text}</span>;
    }
    const suggested = text.slice(highlight.length);
    return (
      <span className={alignClass}>
        <span>{highlight}</span>
        <span
          style={{ color: this.props.suggestColor || 'gray' }}
        >
          {suggested}
        </span>
      </span>
    );
  }
}

const markClass = style({
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  fontSize: 14,
});

const symbolClass = style({
  marginRight: 6,
});

const alignClass = style({
  verticalAlign: 'middle',
});
