import React from 'react';
import { SectionFolder } from '@src/components/SectionFolder';
import { SectionTitle } from '@src/components/SectionTitle';
import { LayoutIcon } from '../../Icons/DropDownIcons/Layout';
import { SectionProps } from '../types/Settings';
import LocalizedStrings from 'react-localization';
import {
  DEFAULT_SIZE_VALUE,
  FORM_LINE_TITLE_SIZE,
  MAX_SIZE_VALUE,
  MIN_SIZE_VALUE,
  SECTION_TITLE_SIZE,
} from '../utils/variables';
import { SectionContent } from '../subcomponents/SectionContent';
import { createChangeSettings } from '../utils/createChangeSettings';
import { LayoutSelector } from '../selectors/LayoutSelector';
import { SettingsCheckbox } from '../subcomponents/SettingsCheckbox';
import { FormLine } from '@src/components/Form/FormLine';
import { Input } from '@src/components/Input';
import { WidgetView } from 'common';

const Layout = ({ opened, onOpen, editedWidget, setEditedWidget }: SectionProps) => {
  const changeSettings = createChangeSettings(editedWidget, setEditedWidget);

  const mustDisplayContentSize = (): boolean => {
    const { type } = editedWidget.settings;
    return type === 'wall' || type === 'carousel';
  };

  const changeType = (type: WidgetView['settings']['type'] = 'wall') => {
    if (type === editedWidget.settings.type) {
      return;
    }
    if (['carousel', 'wall'].includes(type)) {
      changeSettings({
        content_size: DEFAULT_SIZE_VALUE,
      });
    }
    changeSettings({ type });
  };

  return (
    <SectionFolder
      title={
        <SectionTitle size={SECTION_TITLE_SIZE}>
          <LayoutIcon />
          {lang.title}
        </SectionTitle>
      }
      opened={opened}
      onOpen={onOpen}
    >
      <SectionContent>
        <LayoutSelector type={editedWidget.settings.type} onChange={(type) => changeType(type)} />

        {!mustDisplayContentSize() || (
          <FormLine title={lang.fields.contentsPerRow} size={FORM_LINE_TITLE_SIZE}>
            <Input
              type='number'
              onChange={({ target }) => {
                let value = Math.min(Number(target.value) || 0, MAX_SIZE_VALUE);
                value = Math.max(value, MIN_SIZE_VALUE);
                changeSettings({
                  content_size: value,
                });
              }}
              value={editedWidget.settings.content_size}
            />
          </FormLine>
        )}

        <SettingsCheckbox
          label={lang.fields.randomOrder}
          checked={editedWidget.settings.random}
          onClick={() =>
            changeSettings({
              random: !editedWidget.settings.random,
            })
          }
        />
      </SectionContent>
    </SectionFolder>
  );
};

const lang = new LocalizedStrings({
  en: {
    title: 'Layout',
    fields: { randomOrder: 'UGCs in random order', contentsPerRow: 'Content per row' },
  },
});

export default Layout;
