import { PathLocation, QsNames } from '@src/types/Browsing';
import { pathLocationToString, setQsParam } from '@src/utils/browsing';

export type SearchQsKey = typeof QsNames[keyof Pick<typeof QsNames, 'discoverSearchText' | 'discoverSearchHashtags' | 'discoverSearchLabels'>];

export function searchClear(location: PathLocation) {
  const changes: { field: SearchQsKey }[] = [
    { field: QsNames.discoverSearchText },
    { field: QsNames.discoverSearchHashtags },
    { field: QsNames.discoverSearchLabels },
  ];
  return pathLocationToString(
    setQsParam(location, changes),
  );
}
