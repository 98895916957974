import React, { PropsWithChildren, useState } from 'react';
import { FilterClearButton } from './FilterClearButton';
import { SectionFolder } from '../../components/SectionFolder';

interface Props {
  title: string
  indentation?: number
  displayClearButton?: boolean
  onClear?: () => void
  disabled?: boolean
}

interface State {
  opened: boolean
}

export function FilterSection({
  title,
  indentation,
  displayClearButton = false,
  onClear = () => {},
  children,
}: PropsWithChildren<Props>) {
  const [opened, setOpened] = useState(false);
  return (
    <SectionFolder
      title={title}
      afterTitle={
        <FilterClearButton display={displayClearButton} onClick={onClear} />
            }
      opened={opened}
      onOpen={setOpened}
      indentation={indentation}
    >
      {children}
    </SectionFolder>
  );
}
