import React, { useEffect, useRef, useState } from 'react';
import * as reduxHelpers from '@src/services/helpers/redux';
import * as RightsRequestActions from 'actions/rightsrequest';

import { SocialNetworkType } from '@src/components/SocialNetworkButton';
import { useLocationQS } from '@src/utils/useLocationQS';
import { Browsing, QsNames } from '@src/types/Browsing';
import { useQueryMedia } from '@src/queries/media/useQueryMedia';
import { useDispatch, useSelector } from 'react-redux';
import { ICombinedReducers } from '@src/types/redux';
import { useMutationRightsRequestCreate } from '@src/queries/media/useMutationMediaRightsRequestCreate';
import { useMutationRightsRequestUpdate } from '@src/queries/media/useMutationMediaRightsRequestUpdate';
import { useMutationUserRightsTemplate } from '@src/queries/users/useMutationUserRightsTemplate';
import { useQueryUserConfig } from '@src/queries/users/useQueryUserConfig';
import { useQueryUserRightsTemplate } from '@src/queries/users/useQueryUserRightsTemplate';
import LocalizedStrings from 'react-localization';
import { ServiceName, Services } from '@src/types/Services';
import { RightRequestStatus } from '@adalong/schemas';
import { ButtonTarget, GetRights } from './GetRights';
import { goToCloseGetRights } from '../utils/mediaRoute';

const CREATOR_TAG = '@[creator]';

interface Props {
  services: Services
}

export default function GetRightsController(props: Props) {
  const {
    [QsNames.getRightsModal]: [mediaId],
  } = useLocationQS([QsNames.getRightsModal]);

  const [{
    browsingService,
  }] = useState(() => ({
    browsingService: props.services.get<Browsing>(ServiceName.Browsing),
  }));

  const [maxChar, setMaxChar] = useState(0);
  const [remainingChar, setRemainingChar] = useState(0);
  const [postOpened, setPostOpened] = useState(false);
  const [errorCustom, setErrorCustom] = useState<{
    type: string
    data?: any
  } | null
  >(null);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  const finalCommentRef = useRef<HTMLTextAreaElement>(null);

  const dispatch = useDispatch();
  const rightsTemplateFr = useSelector((state: ICombinedReducers) => state.rightsrequests.rightsTemplateFr);
  const mentionPages = useSelector((state: ICombinedReducers) => state.common.groupMentionPages);
  const oauth = useSelector((state: ICombinedReducers) => state.common.oauth);

  const { data: media } = useQueryMedia(mediaId);
  const { data: rightsTemplate } = useQueryUserRightsTemplate();
  const { data: userConfig } = useQueryUserConfig();

  const {
    mutateAsync: createRightsRequest,
    isLoading: isCreating,
    error: errorCreating,
    reset: resetCreating,
  } = useMutationRightsRequestCreate();
  const {
    mutateAsync: updateRightsRequest,
    isLoading: isUpdating,
    error: errorUpdating,
    reset: resetUpdating,
  } = useMutationRightsRequestUpdate();
  const { mutateAsync: updateRightsTemplate } = useMutationUserRightsTemplate();

  const [hashtag, setHashtag] = useState<string>('');
  const [comment, setComment] = useState<string>('');

  useEffect(() => {
    if (!rightsTemplate) {
      return;
    }
    const finalComment = getfinalComment(comment);
    const max = getMaxChar(finalComment, userConfig?.config?.rightstemplate.maxComment);
    const maxCharOffset = finalComment.length - comment.length;
    // Due to the difference between the CREATOR_TAG and the true creator name
    // we need to compensate the number of character for the textarea maxLength
    setMaxChar(max.char - maxCharOffset);
    setRemainingChar(max.remaining);
  }, [rightsTemplate, hashtag, comment, media?.source.user_name]);
  useEffect(() => {
    setComment(
      (rightsTemplateFr ? rightsTemplate?.template.commentFR : rightsTemplate?.template.comment) || '',
    );
  }, [rightsTemplateFr]);
  useEffect(() => {
    setHashtag(rightsTemplate?.template.hashtag || '');
    setComment(
      (rightsTemplateFr ? rightsTemplate?.template.commentFR : rightsTemplate?.template.comment) || '',
    );
  }, [rightsTemplate]);

  const social = media?.source.name as SocialNetworkType;

  // Are mentions in user's group mention pages
  const mentions = !!(media?.mentions || []).find((id: string) => !!mentionPages[id.split('_')[1]]);

  /*
    Work around: Until July 1st 2020 the FB graph_id was badly stored.
    Thus, it's not possible to use the API to post a comment to request rights.
    --> The user must post the RR manually
    */
  const isOldMedia: boolean = media ? new Date(media?.published_at) < new Date(2020, 6, 1) : false;

  const mustConfirm = social === 'instagram' && (!mentions || isOldMedia);
  const error = errorCustom || errorCreating || errorUpdating;
  const loading = isCreating || isUpdating;
  return (
    <GetRights
      media={media}
      hashtag={hashtag}
      comment={comment}
      finalComment={getfinalComment(comment)}
      missingSocial={isMissingSocial(social, oauth)}
      rightsTemplate={rightsTemplate}
      french={rightsTemplateFr || false}
      commentChar={{
        max: maxChar,
        remaining: remainingChar,
      }}
      loading={loading}
      error={error}
      confirmButtonVariant={getConfirmButtonVariant(mustConfirm, postOpened)}
      textAreaRef={textAreaRef}
      finalCommentRef={finalCommentRef}
      handlers={{
        copyText,
        termsClick,
        setFrench,
        openPostClick,
        hashtagChange: setHashtag,
        commentChange: setComment,
        sendClick: handleSend,
        doneClick: handleDone,
        close,
      }}
    />
  );

  function handleSend(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    e.preventDefault();
    if (remainingChar < 0) {
      return setErrorCustom({ type: 'maxlength' });
    }
    const message = prepareCommentForPublishing(comment);
    if (message === null) {
      return;
    }
    setErrorCustom(null);
    resetCreating();

    createRightsRequest({
      mediaId,
      rightsRequest: {
        message,
        replyTag: hashtag,
        status: RightRequestStatus.Created,
      },
    }).then(() => {
      close();
      reduxHelpers.addAlert({ text: lang.success_done, type: 'success' });
    });
  }

  function handleDone(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void {
    e.preventDefault();
    const message = prepareCommentForPublishing(comment);
    if (message === null) {
      return;
    }
    setErrorCustom(null);
    resetUpdating();
    updateRightsRequest({
      mediaId,
      rightsRequest: {
        message,
        replyTag: hashtag,
        status: RightRequestStatus.Published,
      },
    }).then(() => {
      close();
      reduxHelpers.addAlert({ text: lang.success_done, type: 'success' });
    });
  }

  async function close(): Promise<void> {
    persistRightsTemplate();
    browsingService.goToPage(goToCloseGetRights(window.location));
  }

  /**
     * save rights template to be reused later
     */
  async function persistRightsTemplate(): Promise<unknown> {
    const doc = {
      ...rightsTemplate?.template,
      hashtag: hashtag.replace(/#|\s/g, ''),
      [rightsTemplateFr ? 'commentFR' : 'comment']: comment.trim(),
    };
    await updateRightsTemplate({
      rightsTemplate: doc,
    });
    return doc;
  }

  async function setFrench(french: boolean): Promise<void> {
    await persistRightsTemplate();
    dispatch(RightsRequestActions.setRightsTemplateFr(french));
  }

  /**
   * Insert hashtag, terms or creator in the comment
   */
  function copyText(target: ButtonTarget) {
    const node = textAreaRef.current;
    if (node) {
      const start = node.selectionStart;
      const end = node.selectionEnd;
      let replaceBy: string;
      switch (target) {
        case 'hashtag':
          replaceBy = rightsTemplate ? `#${rightsTemplate.template.hashtag}` : '#';
          break;
        case 'terms':
          replaceBy = rightsTemplate?.terms || '';
          break;
        case 'creator':
          replaceBy = media?.source.user_name ? CREATOR_TAG : '@';
          break;
        default:
          throw new Error('No button selected');
      }
      setComment(comment.slice(0, start || undefined) + replaceBy + (end ? comment.slice(end) : ''));
    }
  }

  /**
   * Return the publishable comment
   * If creator is not known, it displays an alert and return null
   */
  function prepareCommentForPublishing(cmt: string): string | null {
    try {
      const creator = media?.source.user_name;
      return formatComment(cmt, { creator });
    } catch (e) {
      reduxHelpers.addAlert({ text: lang.creator_missing, type: 'danger' });
      return null;
    }
  }

  /**
   * Return a comment as if it was ready to publish
   */
  function getfinalComment(cmt: string): string {
    try {
      const creator = media?.source.user_name;
      return formatComment(cmt, { creator });
    } catch (e) {
      return cmt;
    }
  }

  /**
   * Format the comment by:
   * - Replacing the creator tag by the creator name
   *
   * It returns the new string or throw a custom error
   */
  function formatComment(cmt: string, data: { creator: string | undefined }): string {
    const creatorTagPresent = cmt.indexOf(CREATOR_TAG) !== -1;
    if (!creatorTagPresent) {
      return cmt;
    }
    if (typeof data.creator !== 'string') {
      throw new Error(lang.creator_missing);
    }
    // replace all occurences
    return cmt.split(CREATOR_TAG).join(`@${data.creator}`);
  }

  function termsClick() {
    const link = rightsTemplate?.terms;
    if (link) {
      window.open(link, '_blank');
    }
  }

  /**
   * Force to copy the final version of the comment
   * And open the instagram post
   */
  function openPostClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    e.preventDefault();
    setPostOpened(true);
    // We try to prepare to comment to know if there's a format error
    // If so, the function called will handle it and we simply return to stop
    // opening the post
    const message = prepareCommentForPublishing(comment);
    if (message === null) {
      return;
    }
    // we use finalCommentRef to get the comment with the creator name replaced if necessary
    const textToCopy = finalCommentRef.current;
    if (textToCopy) {
      textToCopy.select();
    }
    document.execCommand('copy');
    window.open(media?.url.post, '_blank');
  }
}

function getConfirmButtonVariant(
  mustConfirm: boolean,
  postOpened: boolean,
) {
  if (mustConfirm) {
    if (postOpened) {
      return 'done';
    }
    return 'openPost';
  }
  return 'send';
}

function getMaxChar(comment?: string, maxComment: number = 280) {
  return {
    char: maxComment,
    remaining: maxComment - (comment || '').length,
  };
}

function isMissingSocial(
  social: SocialNetworkType,
  oauth: {
    twitter: boolean
  },
) {
  switch (social) {
    case 'twitter':
      return !oauth.twitter;
    case 'instagram':
      // We don't need user tokens for FB/IG mentions or instagram manual requests
      return false;
    default:
      return false;
  }
}

const lang = new LocalizedStrings({
  en: {
    success_done: 'Request saved, it will be processed soon',
    creator_missing: `A creator tag is present but the creator name is not available. Please remove the ${CREATOR_TAG} tag`,
  },
});
