import React, { Component } from 'react';
import { StringHelper } from '@src/services/helpers/string';
import { CollectionView } from 'common';
import { style } from 'typestyle';
import TitleBar from '../TitleBar';

interface IProps {
  collection: CollectionView
  onSelect: Function
}

interface IState {

}

export class ShareCollection extends Component <IProps, IState> {
  public render() {
    const { collection } = this.props;
   const bgImage = collection.preview_media_url ?? null;
    const style = bgImage ? {
      backgroundImage: `url("${bgImage}")`,
    } : { backgroundColor: 'gray' };

    return (
      <TitleBar
        style={style}
        classes={[titleBarClass]}
        title={StringHelper.upperFirst(collection.name)}
        onSelect={() => this.props.onSelect(collection)}
      />
    );
  }
}

const titleBarClass = style({
  position: 'relative',
});
