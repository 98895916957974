import React from 'react';
import HighlightOff from '@mui/icons-material/HighlightOff';
import { style } from 'typestyle';

interface Props {
  onClick: () => void
  display?: boolean
}

export function FilterClearButton({
  onClick,
  display = false,
}: Props) {
  if (!display) {
    return null;
  }
  return (
    <div className={clearButtonClass}>
      <HighlightOff
        fontSize="small"
        color="inherit"
        onClick={onClick}
      />
    </div>
  );
}

const clearButtonClass = style({
  display: 'inline-block',
  marginLeft: 12,
  color: '#bbbbbb',
  cursor: 'pointer',
});
