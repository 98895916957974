import { QUERY_KEYS } from "@src/query";
import { useQuery } from "react-query";
import { getStatistics } from "./api";
import { Analytics } from "common/dist/types/apiTypes";

export const useQueryStatistics = () =>
    useQuery<Analytics[] >(
      QUERY_KEYS.statistics(),
      () => getStatistics(),
    );
  