import React, { PropsWithChildren } from 'react';
import { Link } from './Link';

interface Props {
  mail: string
}

export default function MailLink({
  mail,
  children,
}: PropsWithChildren<Props>) {
  return (
    <Link
      href={`mailto:${mail}`}
    >
      {children}

    </Link>
  );
}
