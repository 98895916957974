import { Category } from '@adalong/schemas';
import { StringHelper } from '@src/services/helpers/string';

export interface CategoryWithParent extends Category {
  parent: CategoryWithParent | null
  children: CategoryWithParent[]
}

export function toFlatTaxonomy(categories: CategoryWithParent[] | null): CategoryWithParent[] {
  return (categories || []).reduce<CategoryWithParent[]>((expand, cat) => {
    const childrenNames = toFlatTaxonomy(cat.children);
    return [
      ...expand,
      cat,
      ...childrenNames,
    ];
  }, []);
}

export function getTaxonomyWithParents(
  node: Category,
  parent: CategoryWithParent | null,
): CategoryWithParent {
  const nodeWithParent: CategoryWithParent = {
    ...node,
    parent,
    children: [],
  };
  nodeWithParent.children = node.children.map((c) => getTaxonomyWithParents(c, nodeWithParent));
  return nodeWithParent;
}

export function categoryToPath(category: CategoryWithParent): CategoryWithParent[] {
  const path: CategoryWithParent[] = [];
  let n: CategoryWithParent | null | undefined = category;
  do {
    path.unshift(n);
    n = n?.parent;
  } while (n && n.name);
  return path;
}

export function equalPaths(pathA: string[], pathB: string[]): boolean {
  const pA = pathA.join();
  const pB = pathB.join();
  return pA === pB;
}

export function applyTextFilter(
  categories: CategoryWithParent[],
  filter: string = '',
): CategoryWithParent[] | null {
  if (!filter) {
    return categories;
  }
  const categoriesOrNulls = categories.map((cat) => {
    const currentMatch = findTextFilterIndex(cat.displayName, filter) !== -1;
    const filteredChildrens = applyTextFilter(cat.children, filter);
    if (!filteredChildrens?.length && !currentMatch) {
      return null;
    }
    return {
      ...cat,
      children: filteredChildrens,
    };
  });
  return categoriesOrNulls.filter((c): c is CategoryWithParent => !!c);
}

export function findTextFilterIndex(value: string, textFilter: string): number {
  const v = StringHelper.removeDiacritics(value).toLowerCase();
  const t = StringHelper.removeDiacritics(textFilter).toLowerCase();
  return v.indexOf(t);
}
