import React from 'react';
import ReactDateRangePicker from '@wojtekmaj/react-daterange-picker';
import { stylesheet } from 'typestyle';
import { color } from 'csx';
import { ADALONG_COLORS, ThemeStyle } from '@src/styles';

export type Range = [Date, Date];

interface Props {
  isOpen: boolean
  range: Range | null
  calendarClassName: string
  onChange: (range: Range) => void
  onClose: () => void
}

export function DateRangePicker(props: Props) {
  if (!props.isOpen) {
    return null;
  }
  return (
    <ReactDateRangePicker
      isOpen
      closeCalendar={false}
      className={sheet.dateRangePicker}
      calendarClassName={props.calendarClassName}
      value={props.range || undefined}
      onChange={props.onChange}
      onCalendarClose={props.onClose}
    />
  );
}

const CALENDAR_BLUE = '#0041fa';
const CALENDAR_ORANGE = '#ff9f88';
const sheet = stylesheet({
  dateRangePicker: {
    zIndex: 5,
    width: 0,
    height: 0,
    $nest: {
      '& .react-daterange-picker': {
        $nest: {
          '&__wrapper': {
            display: 'none',
          },
        },
      },
      '& .react-calendar': {
        lineHeight: '1.125em',
        fontSize: ThemeStyle.textSizes.large,
        position: 'fixed',
        backgroundColor: color(ADALONG_COLORS.SMOOTH_BLACK).lighten(0.05).toHexString(),
        border: 'none',
        borderRadius: 3,
        $nest: {
          '&--selectRange': {
            $nest: {
              '.react-calendar__tile--hover': {
                backgroundColor: ADALONG_COLORS.SMOOTH_WHITE,
                color: ADALONG_COLORS.LIGHT_BLACK,
              },
            },
          },
          '&__navigation': {
            $nest: {
              '&__arrow, &__label': {
                color: ADALONG_COLORS.SMOOTH_WHITE,
              },
              '& button:enabled:hover': {
                color: ADALONG_COLORS.LIGHT_BLACK,
              },
            },
          },
          '&__month-view': {
            $nest: {
              '&__days__day': {
                color: ADALONG_COLORS.SMOOTH_WHITE,
                $nest: {
                  '&--neighboringMonth': {
                    color: ADALONG_COLORS.DARK_GRAY,
                  },
                  '&:hover, &:focus': {
                    color: ADALONG_COLORS.DARK_BLACK,
                  },
                },
              },
              '&__weekdays__weekday > *': {
                fontSize: ThemeStyle.textSizes.small,
                textDecoration: 'none',
              },
            },
          },
          '&__tile': {
            maxWidth: '100%',
            textAlign: 'center',
            padding: '.75em .5em',
            background: 'none',
            $nest: {
              '&:enabled': {
                $nest: {
                  '&:focus': {
                    backgroundColor: CALENDAR_BLUE,
                    color: ADALONG_COLORS.SMOOTH_WHITE,
                  },
                },
              },
              '&:hover': {
                backgroundColor: ADALONG_COLORS.LIGHT_WHITE,
                color: ADALONG_COLORS.LIGHT_BLACK,
              },
              '&--active, &--rangeEnd': {
                background: CALENDAR_BLUE,
                color: ADALONG_COLORS.LIGHT_WHITE,
              },
              '&--now': {
                color: CALENDAR_ORANGE,
              },
            },
          },
        },
      },
    },
  },
});
