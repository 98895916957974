import React from 'react';
import { classes, stylesheet } from 'typestyle';
import { ThemeStyle } from '@src/styles';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconProps, SvgIconTypeMap } from '@mui/material';

interface Props extends SvgIconProps {
  icon: OverridableComponent<SvgIconTypeMap> & { muiName: string; }
  size?: number
}

/**
 * Wrap a material ui icon to style it like a button
 * If the size is not provided it will be inherited from
 * the parent fontSize
 */
export function ButtonIcon({
  icon,
  size,
  className,
  ...props
}: Props) {
  return React.createElement(icon, {
    className: classes(sheet.button, className),
    style: size ? { fontSize: size } : undefined,
    fontSize: 'inherit',
    ...props,
  });
}

const sheet = stylesheet({
  button: {
    color: ThemeStyle.button.color,
    transition: 'color 0.2s',
    cursor: 'pointer',
    $nest: {
      '&:hover': {
        color: ThemeStyle.button.hoveredColor,
      },
    },
  },
});
