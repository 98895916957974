import React from 'react';

import { style } from 'typestyle';
import { IBrandContent } from 'common';
import { ShareInfo } from '@src/types/redux/share';
import { useQueryCollectionMedias } from '@src/queries/collections/useQueryCollectionMedias';
import { useQueryBrandContents } from '@src/queries/brandcontent/useQueryBrandContents';
import { Loader } from '@src/components/Loader';
import { Hide } from '@src/components/Hide';
import { useMutationRemoveBrandContents } from '@src/queries/brandcontent/useMutationRemoveBrandContent';
import { bigStore } from '@src/store';
import { promptConfirm } from '@src/actions/common';
import Snackbar from '@mui/material/Snackbar';
import { Alert } from '@mui/material';
import LocalizedStrings from 'react-localization';
import { MediaType } from '@adalong/schemas';
import { ButtonIcon } from '@src/components/ButtonIcon';
import DownloadIcon from '@mui/icons-material/GetApp';
import styles from './styles.scss';
import { UploadingFile, IUploadingFile } from './components/UploadingFile';
import { UploadButton } from './components/UploadButton';
import OriginalUGC from './components/OriginalUGC';
import { BrandContent } from './components/BrandContent';
import { CollectionRow } from './components/ShareCollectionRow';

interface IProps {
  canEdit?: boolean
  collectionId?: string
  onShare: (info: ShareInfo) => void
  uploadingFiles: IUploadingFile[]
  onUpload: (files: File[]) => void
  onDownload?: () => void
  onLoadedContent?: () => void
}

export function ShareCollectionContent(props: IProps) {
  const {
    data: mediaResponses, fetchNextPage, isLoading: isLoadingMedia, hasNextPage,
  } = useQueryCollectionMedias(props.collectionId || '', undefined, {
    onSettled: () => props.onLoadedContent?.(),
    enabled: !!props.collectionId,
  });
  const { data: brandContents = [], isLoading: isLoadingBrandContents } = useQueryBrandContents(props.collectionId);
  const medias = mediaResponses?.pages.flatMap((r) => r.medias).filter((m) => m.type != MediaType.Text);

  const {
    mutateAsync: removeBrandContent,
    error: errorRemoveBrandContent,
  } = useMutationRemoveBrandContents(props.collectionId);

  const onUpload = (files: File[]) => {
    if (props.onUpload && files.length) {
      props.onUpload(files);
    }
  };

  const onDeleteBrandContent = (id: string) => {
    bigStore.dispatch(promptConfirm({
      question: lang.delete,
      answer: async (yes) => {
        if (!yes) {
          return;
        }
        return removeBrandContent(id);
      },
    }));
  };

  const isLoading = isLoadingMedia || isLoadingBrandContents;
  return (
    <div className={styles.collectionContent}>
      <Hide hide={!isLoading}>
        <Loader />
      </Hide>
      <Snackbar open={!!errorRemoveBrandContent} autoHideDuration={6000}>
        <Alert severity="error" variant="filled">{lang.error.delete}</Alert>
      </Snackbar>
      <Hide hide={isLoading}>
        {medias && (
        <CollectionRow
          title={(
            <>
              <span>{lang.medias.title}</span>
              {props.onDownload && (
              <ButtonIcon
                className={downloadButtonClass}
                icon={DownloadIcon}
                onClick={props.onDownload}
              />
              )}
            </>
                          )}
          empty={lang.medias.empty}
          isEmpty={!medias.length}
          collectionId={props.collectionId}
          noMore={!hasNextPage}
          loadCollectionMedia={async (): Promise<void> => {
            await fetchNextPage();
          }}
        >
          {medias.map((media) => <OriginalUGC key={media._id} media={media} onShare={props.onShare} />)}
        </CollectionRow>
        )}
        {props.canEdit && (
          <CollectionRow
            title={lang.brandcontents.title}
            leftSection={props.canEdit && (
            <UploadButton
              classNames={[styles.uploadButton]}
              onFiles={onUpload}
              accept={['video/*', 'image/*']}
            />
            )}
          >
            {props.uploadingFiles.map((file) => (
              <UploadingFile file={file} key={file.id} />
            ))}
            {brandContents.map((content: IBrandContent) => (
              <BrandContent
                content={content}
                key={content._id}
                onShare={props.onShare}
                onDelete={() => onDeleteBrandContent(content._id)}
              />
            ))}
          </CollectionRow>
        )}
      </Hide>
    </div>
  );
}

const lang = new LocalizedStrings({
  en: {
    delete: 'Do you really want to remove this content ?',
    error: {
      delete: 'An error occured during deletion. Please, try again or contact us',
    },
    medias: {
      title: 'Original UGC',
      empty: 'No media for this collection',
    },
    brandcontents: {
      title: 'Uploaded Content',
      empty: 'No content for this collection',
    },
  },
});

const downloadButtonClass = style({
  marginLeft: 10,
});
