import { atom } from 'recoil';
import { recoilKeys } from '@src/components/recoilKeys';
import { DiscoverQs } from '@src/types/Discover';
import { MediaConstraints } from '@src/fragments/Filters/utils/MediaFilterBuilder';
import { TYPING_DEBOUNCE } from '@src/pages/Discover/DiscoverController';
import { SearchSuggestion, XCLUSION_SELECTIONS, XclusionSelection } from '@src/types/Filters';
import { ProductView } from 'common';

export const defaultFilter = {
  exact: [],
  caption: [],
  hashtag: [],
  label: [],
  source: [],
  media: [],
  rrStatus: [],
  creator: [],
  date: [],
  lang: [],
  productExact: [],
  productSimilar: [],
  collection: [],
  ownContent: [],
  creatorCountries: [],
};

export const InitialLibraryState = atom<{
  total: number;
  shoppableUGCs: number;
}>({
  key: recoilKeys.initialLibrary,
  default: {
    total: 0,
    shoppableUGCs: 0,
  },
});

export const FilterState = atom<DiscoverQs>({
  key: recoilKeys.filters,
  default: defaultFilter,
});

export const filterSourceInputState = atom<string>({
  key: recoilKeys.filterSourceInput,
  default: '',
});

export const filterCollectionInputState = atom<string>({
  key: recoilKeys.filterCollectionInput,
  default: '',
});

export const allCollectionState = atom<SearchSuggestion[]>({
  key: recoilKeys.allCollections,
  default: [],
});

export const filterLanguageInputState = atom<string>({
  key: recoilKeys.filterLanguageInput,
  default: '',
});

export const filterProductExactInputState = atom<string>({
  key: recoilKeys.filterProductExactInput,
  default: '',
});

export const filterProductSimilarInputState = atom<string>({
  key: recoilKeys.filterProductSimilarInput,
  default: '',
});

export const allProductState = atom<ProductView[]>({
  key: recoilKeys.allProducts,
  default: [],
});

export const filterCreatorInputState = atom<string>({
  key: recoilKeys.filterCreatorInput,
  default: '',
});

export const filterCreatorXclusionState = atom<XclusionSelection>({
  key: recoilKeys.filterCreatorXclusion,
  default: XCLUSION_SELECTIONS[0],
});

export const filterOwnContentInputState = atom<string>({
  key: recoilKeys.filterOwnContentInput,
  default: '',
});

export const filterOwnContentXclusionState = atom<XclusionSelection>({
  key: recoilKeys.filterOwnContentXclusion,
  default: XCLUSION_SELECTIONS[0],
});

export const filterLanguageXclusionState = atom<XclusionSelection>({
  key: recoilKeys.filterLanguageXclusion,
  default: XCLUSION_SELECTIONS[0],
});

export const filterProductExactXclusionState = atom<XclusionSelection>({
  key: recoilKeys.filterProductExactXclusion,
  default: XCLUSION_SELECTIONS[0],
});

export const filterProductSimilarXclusionState = atom<XclusionSelection>({
  key: recoilKeys.filterProductSimilarXclusion,
  default: XCLUSION_SELECTIONS[0],
});

export const filterCollectionXclusionState = atom<XclusionSelection>({
  key: recoilKeys.filterCollectonXclusion,
  default: XCLUSION_SELECTIONS[0],
});

export const filterCreatorCountriesInputState = atom<string>({
  key: recoilKeys.filterCreatorCountriesInput,
  default: '',
});

export const filterCreatorCountriesXclusionState = atom<XclusionSelection>({
  key: recoilKeys.filterCreatorCountriesXclusion,
  default: XCLUSION_SELECTIONS[0],
});

export const FacetState = atom<{
  enableFacet: boolean;
  typingDebounce: number;
  mediaConstraints: MediaConstraints;
  productIds?: string[];
}>({
  key: recoilKeys.facetState,
  default: {
    enableFacet: false,
    typingDebounce: TYPING_DEBOUNCE,
    mediaConstraints: {
      qs: {
        exact: [],
        caption: [],
        hashtag: [],
        label: [],
        source: [],
        media: [],
        rrStatus: [],
        creator: [],
        date: [],
        lang: [],
        productExact: [],
        productSimilar: [],
        ownContent: [],
        creatorCountries: [],
      },
      isExactSearch: false,
      collections: [],
      filterCollectionXclusion: XCLUSION_SELECTIONS[0],
      filterOwnContentXclusion: XCLUSION_SELECTIONS[0],
      filterCreatorCountriesXclusion: XCLUSION_SELECTIONS[0],
      state: {
        user: null,
        groupMentionPages: {},
      },
    },
  },
});
