import React, { Component } from 'react';
import { StringHelper } from '@src/services/helpers/string';
import { ICombinedReducers } from '@src/types/redux';
import * as reduxHelpers from '@src/services/helpers/redux';
import InstagramIcon from '@mui/icons-material/Instagram';
import FacebookIcon from '@mui/icons-material/Facebook';
import TwitterIcon from '@mui/icons-material/Twitter';
import type { SvgIconTypeMap } from '@mui/material/SvgIcon';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { classes, stylesheet } from 'typestyle';
import { ADALONG_COLORS } from '@src/styles';
import { bigStore } from '@src/store';
import { API_URL } from '@src/config';
import { CloseButton } from '../CloseButton';

export type SocialNetworkType = 'facebook' | 'twitter' | 'instagram';

interface IProps {
  social: SocialNetworkType
  linked?: boolean
  onRemove?: (social: SocialNetworkType) => void
  type?: 'link' | 'login'
}

interface IState {

}

const ICONS: {
  [key in SocialNetworkType]: OverridableComponent<SvgIconTypeMap> & { muiName: string; }
} = {
  instagram: InstagramIcon,
  facebook: FacebookIcon,
  twitter: TwitterIcon,
};

export class SocialNetworkButton extends Component<IProps, IState> {
  public onClick(disable: string, url: string) {
    if (disable == 'disable') {
      reduxHelpers.addAlert({ text: 'common.error.role', type: 'danger' });
    } else {
      window.open(url);
    }
  }

  public render() {
    const state: ICombinedReducers = bigStore.getState();
    const disabled = (state.common.user?.role === 'demo') ? 'disable' : '';
    const { social, linked, onRemove } = this.props;
    if (!linked) {
      let { type } = this.props;
      type = type || 'link';
      const url = `${API_URL}/oauth/${social}?type=${type}`;
      return 	(
        <button
          className={`${sheet.socialNetworkButton} ${disabled}`}
          onClick={() => this.onClick(disabled, url)}
          data-social={social}
        >
          {React.createElement(ICONS[social])}
          <span>{window.T('common.settings.oauth.linksocial', { social: StringHelper.upperFirst(social) })}</span>
        </button>
      );
    }
    return (
      <div
        className={classes(sheet.socialNetworkButton, sheet.signedIds)}
        data-social={social}
        onClick={() => (onRemove ? onRemove(social) : null)}
      >
        {React.createElement(ICONS[social])}
        <span>{window.T('common.settings.oauth.signedsocial', { social: StringHelper.upperFirst(social) })}</span>
        {onRemove
                    && (
                    <CloseButton
                      classNames={[sheet.closeButton]}
                      size="large"
                    />
                    )}
      </div>
    );
  }
}

const sheet = stylesheet({
  socialNetworkButton: {
    textAlign: 'left',
    fontSize: 14,
    padding: '14px 30px',
    margin: 'auto',
    display: 'block',
    position: 'relative',
    width: 325,
    marginBottom: 15,
    borderRadius: 50,
    outline: 'none',
    letterSpacing: '2.5px',
    cursor: 'pointer',
    border: '2px solid transparent',
    fontFamily: 'Helvetica, sans-serif',
    transition: 'background-color 0.2s, color 0.2s',
    color: ADALONG_COLORS.LIGHT_WHITE,
    $nest: {
      span: {
        marginLeft: 35,
        verticalAlign: 'text-top',
      },
      i: {
        float: 'left',
        fontSize: 24,
      },
      '&[data-social=twitter]': {
        backgroundColor: '#55acee',
      },
      '&[data-social=instagram]': {
        backgroundColor: '#e4405f',
      },
      '&[data-social=facebook]': {
        backgroundColor: '#3b5999',
      },
      '&[data-social=google]': {
        backgroundColor: '#dd4b39',
      },
      '&:hover': {
        backgroundColor: ADALONG_COLORS.LIGHT_WHITE,
        color: ADALONG_COLORS.DARK_BLACK,
      },
    },
  },
  signedIds: {
    outline: 'none',
    backgroundColor: '#383838',
    opacity: 1,
    color: ADALONG_COLORS.LIGHT_WHITE,
    $nest: {
      '&:hover': {
        backgroundColor: '#3b3b3b',
      },
    },
  },
  closeButton: {
    position: 'absolute',
    right: 26,
    top: 17,
  },
});
