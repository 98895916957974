import React, { Component, ReactNode, createRef } from 'react';
import ReactDOM from 'react-dom';
import { style } from 'typestyle';
import { Loader } from './Loader';
import { PerfectScrollbar } from './PerfectScrollbar';

const DEFAULT_TRESHOLD = 500;

export interface Props {
  /**
     * Define how to render container
     * It will override the default PerfectScrollBar behavior
     */
  children?: ReactNode
  container?: (
    InfiniteScrollerProps: {
      onScroll: (e: React.UIEvent<HTMLDivElement>) => any
    },
    children: ReactNode[]
  ) => ReactNode
  threshold?: number
  load: (page: number) => Promise<void>
  loader?: boolean | ReactNode
  onScroll?: (e: React.UIEvent<HTMLDivElement>) => any // Get the original event
  noMore: boolean
  containerRef?: React.RefObject<HTMLElement> // To override default ref
  horizontal?: boolean
  initialPage?: number
}

interface State {
  displayLoader: boolean
}

type ScrollProps = {
  clientProp: 'clientWidth'
  scrollProp: 'scrollLeft'
} | {
  clientProp: 'clientHeight'
  scrollProp: 'scrollTop'
};

/**
 * Encapsulate a scroller or any other component
 * and check to load more content
 */
class InfiniteScroller extends Component<Props, State> {
  public state: State = {
    displayLoader: false,
  };

  private loading: boolean = false;

  private scrollProps: ScrollProps;

  private page: number;

  private loaderRef = createRef<HTMLDivElement>();

  public constructor(props: Props) {
    super(props);
    this.scrollProps = this.props.horizontal ? {
      clientProp: 'clientWidth',
      scrollProp: 'scrollLeft',
    } : {
      clientProp: 'clientHeight',
      scrollProp: 'scrollTop',
    };
    this.page = this.initialPage;
  }

  public render() {
    const {
      container = this.defaultContainer!,
      children,
    } = this.props;

    const scrollerProps = {
      onScroll: this.onScroll.bind(this),
    };

    const childrens = [children];
    if (this.state.displayLoader) {
      childrens.push(this.loaderComponent);
    }

    return container(scrollerProps, childrens);
  }

  public resetPage() {
    this.page = this.initialPage;
  }

  private get initialPage() {
    return typeof this.props.initialPage === 'number' ? this.props.initialPage : 1;
  }

  private get threshold() {
    return typeof this.props.threshold === 'number' ? this.props.threshold : DEFAULT_TRESHOLD;
  }

  private get loaderComponent() {
    if (this.props.loader === false) {
      return null;
    }
    if (React.isValidElement(this.props.loader)) {
      return this.props.loader;
    }
    return (
      <div className={loaderContainerClass} ref={this.loaderRef} key="infinite-loader">
        <Loader />
      </div>
    );
  }

  private onScroll(e: React.UIEvent<HTMLDivElement>) {
    this.listener(e.nativeEvent.target as any);
    this.props.onScroll?.(e);
  }

  private listener(targetElement: HTMLElement | null) {
    const containerNode = ReactDOM.findDOMNode(this) as HTMLElement;
    if (this.loading || this.props.noMore || !targetElement || !containerNode) {
      return;
    }

    // Use given containerRef or use infinite scroller's children
    const firstChild = this.props.containerRef?.current || containerNode.firstChild;

    if (firstChild?.nodeType !== Node.ELEMENT_NODE || this.loaderRef.current === firstChild) {
      return;
    }
    const child = firstChild as HTMLElement;
    const { clientProp, scrollProp } = this.scrollProps;

    const offset = (child[clientProp] || 0) - containerNode[clientProp] - containerNode[scrollProp];
    if (offset <= this.threshold) {
      this.setState({ displayLoader: this.loading = true });
      this.props.load(this.page).then(() => {
        this.page++;
      }).catch((error) => {
        console.error(error);
      }).finally(() => {
        this.setState({ displayLoader: this.loading = false });
      });
    }
  }

  // Override this default container with the container prop
  private defaultContainer: Props['container'] = (props, children) => (
    <PerfectScrollbar
      {...props}
    >
      {children}
    </PerfectScrollbar>
  );
}

const loaderContainerClass = style({
  textAlign: 'center',
  padding: 30,
});

export { InfiniteScroller };
