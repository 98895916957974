import { IBrandContent, DownloadBrandContentQuery, MetadataTaggedFile } from 'common';

import { Call } from '@src/services/api';

export const getBrandContents = (collectionId?: string): Promise<IBrandContent[]> => Call.get('/api/share/brandcontents', collectionId ? { collectionId } : {});

export const removeBrandContent = (brandContentId: string) => Call.post('/api/share/removebrandcontent', { brandContentId });

export const downloadBrandContent = (downloadParams: DownloadBrandContentQuery): Promise<MetadataTaggedFile> => Call.post<MetadataTaggedFile, {downloadParams: DownloadBrandContentQuery}>(
    '/api/brandcontent/download',
    { downloadParams }
)