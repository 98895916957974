import React from 'react';
import { FormError } from '@src/components/Form/FormError';
import { loadTranslation } from '@src/services/helpers/redux';
import { ApiError } from '@src/services/api';

const ErrorKeys = ['toolong', 'tooshort', 'unknown'] as const;
type ErrorKey = typeof ErrorKeys[number];

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  error?: ApiError | null
}

export function Error({
  error,
  ...props
}: Props) {
  if (!error) {
    return null;
  }
  const type = ErrorKeys.find((k) => k === error.type);
  const key: ErrorKey = type || 'unknown';
  return (
    <FormError
      error={lang.formatString(lang[key], error.data || {}) as string}
      {...props}
    />
  );
}

const lang = loadTranslation({
  en: {
    toolong: 'Your {field} is too long: {count} characters max',
    tooshort: 'Your {field} is too short: {count} characters min',
    unknown: 'An error occured',
  },
});
