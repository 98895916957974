import React from 'react';
import { ADALONG_COLORS } from '@src/styles';
import { style } from 'typestyle';

interface Props {
  childrenRender: (color: string) => JSX.Element;
}

export interface InheritProps {
  selected?: boolean;
  onClick: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => any;
}

export function LayoutIcon({ onClick, selected, childrenRender }: Props & InheritProps) {
  const strokeColor = selected ? ADALONG_COLORS.FLASH_BLUE : ADALONG_COLORS.DARKER_GRAY;
  const strokeWidth = selected ? 2 : 1;

  return (
    <svg className={svgClass} viewBox='0 0 72 62' onClick={onClick}>
      <g stroke='none' strokeWidth={strokeWidth} fill='none' fillRule='evenodd'>
        {childrenRender(strokeColor)}
      </g>
    </svg>
  );
}

const svgClass = style({
  cursor: 'pointer',
  width: 70,
  height: 60,
});
