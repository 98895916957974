import { useQuery } from 'react-query';
import { queryClient, QUERY_KEYS, QUERY_PREFIX } from '@src/query';
import { getMediaCarousel } from './api';

/**
 * Find products linked to any media
 */
export const useQueryMediaCarousel = (mediaId: string) => useQuery([QUERY_PREFIX.MEDIA_CAROUSEL, mediaId], async () => getMediaCarousel(mediaId), {
  onSuccess: (mediaList) => {
    mediaList.forEach((carouselMedia) => {
      if (carouselMedia._id === mediaId) {
        // do not prefetch the current media as it's already cached
        return;
      }
      queryClient.prefetchQuery(QUERY_KEYS.media(carouselMedia._id));
    });
  },
});
