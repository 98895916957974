import React from 'react';
import TimeAgo from 'react-timeago';
import LocalizedStrings from 'react-localization';
import { StringHelper } from '@src/services/helpers/string';
import { LittleDataDescription } from '../LittleDataDescription';

interface Props {
  source?: string
  date: string
  likes?: number
  comments?: number
  location?: string
}

const numberFormatter = new Intl.NumberFormat('en-US');

export function MediaLittleData({
  source,
  date,
  comments,
  likes,
  location,
}: Props) {
  const data: (string | JSX.Element)[] = [
    <TimeAgo key="timeagokey" date={date} />,
  ];

  if (typeof source !== 'undefined') {
    data.unshift(StringHelper.upperFirst(source));
  }
  if (typeof comments !== 'undefined') {
    data.push(`${numberFormatter.format(comments)} ${lang.comments}`);
  }
  if (typeof location !== 'undefined') {
    data.push(location);
  }

  return (
    <LittleDataDescription
      data={data}
    />
  );
}

const lang = new LocalizedStrings({
  en: {
    likes: 'likes',
    comments: 'comments',
  },
});
