import React, {createRef, RefObject, useState} from 'react';
import { SearchSuggestion, XCLUSION_SELECTIONS } from '@src/types/Filters';
import { Value } from '../Suggestions';
import { CreatorsSuggestion } from '@src/fragments/Filters/Search/Suggestions/CreatorsSuggestion';
import {PerfectScrollbar} from '@src/components/PerfectScrollbar';
import {style} from 'typestyle';
import {CheckBoxCustom} from '@src/fragments/Filters/Search/Suggestions/InfluencersComponents/CheckBoxCustom';
import {useRecoilState, useRecoilValue} from 'recoil';
import {
    FilterState
} from '@src/pages/Discover/components/Atoms/Atoms';
import {useQueryInfluencersCreators} from '@src/queries/influencers/useQueryCreators';
import {onFilterChange} from "@src/fragments/Filters/utils/FilterStateUtility";
import {creatorExcludeState} from "@src/pages/Discover/components/Selectors/Selectors";

interface LocaleSuggetion {
    key: string
    display: string,
    selected: boolean
}

export function InfluencerSuggestion() {
    const {
        data: ResInfluencers,
        isLoading: InfluencersLoading,
        error: ErrorInfuencers,
    } = useQueryInfluencersCreators()
    const countryValues = [...new Set(ResInfluencers?.flatMap((inf) => inf.countries || [])).add('ALL')].map((loc) => { return { key: loc, display: `${loc}` } })
    const [filters, setFilters] = useRecoilState(FilterState)
    const exclude = useRecoilValue(creatorExcludeState)
    const xclusion = exclude ? XCLUSION_SELECTIONS[1] : XCLUSION_SELECTIONS[0]
    const influencersByLocale = new Map<string, { key: string, display: string }[]>()
    ResInfluencers?.forEach((inf) => {
        // Add influencer to the "ALL" group
        const allValue = influencersByLocale.get('ALL');
        if (!allValue) {
            influencersByLocale.set('ALL', [{ key: inf.username, display: inf.username }]);
        } else {
            influencersByLocale.set('ALL', [...allValue, { key: inf.username, display: inf.username }]);
        }
    
        // Add influencer to each individual country group
        if (inf.countries) {
            inf.countries.forEach((country) => {
                const countryValue = influencersByLocale.get(country);
                if (!countryValue) {
                    influencersByLocale.set(country, [{ key: inf.username, display: inf.username }]);
                } else {
                    influencersByLocale.set(country, [...countryValue, { key: inf.username, display: inf.username }]);
                }
            });
        }
    });    
    const [selectedValues, setSelectedValues] = useState<LocaleSuggetion[]>([])
    const onLocalizationChange = (value: string, selected: boolean, ) => {
        const newValues = selected ? [...selectedValues, { key: value, display: `${value}`, selected: true }]
            : selectedValues.filter((val) => val.key !== value);
        const influencersToChange = influencersByLocale.get(value) ?? [];
        onFilterChange(setFilters, 'creator')(influencersToChange.map(inf => inf.key), selected, xclusion)
        setSelectedValues(newValues)
    }
    function renderValues(suggestions: SearchSuggestion[], selectedValues: LocaleSuggetion[]) {
        return suggestions.map((suggestion) => {
            const influencerList = influencersByLocale.get(suggestion.key) ?? [];
            return {
            key: suggestion.key,
            children: (
                <>
                <div>{`${suggestion.display} : ` }</div>
                <div style={{marginLeft: '4px'}}>
                <CreatorsSuggestion influencers={influencerList} />
                </div>
                </>
            ),
            selected: selectedValues?.find((val) => val.key === suggestion.key)?.selected
        }});
    }
    const scrollbarRef: RefObject<PerfectScrollbar> = createRef<PerfectScrollbar>();
    const renderLines = (valuesList: Value[]) => valuesList.map(({ children, key, selected = false }) => (
        <CheckBoxCustom
            key={key}
            checked={selected}
            onChange={() => onLocalizationChange(key, !selected)}
            negative={selected ? xclusion === XCLUSION_SELECTIONS[1] : false}
        >
            {children}
        </CheckBoxCustom>
    ));

    return (
        <PerfectScrollbar
            className={resultsClass}
            ref={scrollbarRef}
            options={{
                wheelSpeed: 0.6,
            }}
        >
            {renderLines(renderValues(countryValues, selectedValues))}
        </PerfectScrollbar>
    );
}

const resultsClass = style({
    marginTop: 10,
    maxHeight: 115,
    overflow: 'hidden',
    position: 'relative',
    fontWeight: 'inherit',
});


