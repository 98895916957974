import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ShareCollectionContentOverlay } from 'components/ShareCollectionContentOverlay';
import Draggable from 'components/Draggable';
import { Services } from '@src/services';
import { ServiceName } from '@src/types/Services';
import { IMediaService } from '@src/types/Media';
import { ICombinedReducers } from '@src/types/redux';
import { MediaView } from 'common';
import { ShareInfo } from '@src/types/redux/share';
import { MediaType, RightRequestMQLStatus } from '@adalong/schemas';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';

interface IProps {
  onShare: (info: ShareInfo) => any
  media: MediaView
}

interface IState {
  error?: boolean
}
class OriginalUGC extends Component <IProps, IState> {
  private downloading: boolean = false;

  private mediaService: IMediaService;

  public constructor(props: IProps) {
    super(props);
    this.state = {};
    // Note: Ideally, this service should be injected across the react props or context
    this.mediaService = Services.get<IMediaService>(ServiceName.MediaService);
    this.isURLDead(this.props.media.cdn_image ?? '').then((res) => {
      if (res) {
        this.onError();
      }
    }).catch(console.error);
  }

  public onShare() {
    this.props.onShare({
      image: this.props.media.cdn_image,
      file: this.props.media[this.props.media.type === 'video' ? 'cdn_video' : 'cdn_image'],
      fileType: this.props.media.type === MediaType.Image ? 'image' : 'video',
      _id: this.props.media._id,
      creator: this.props.media.source.user_name,
    });
  }

  public onError() {
    this.setState({ error: true });
  }

  public render() {
    if (this.state.error) {
      return null;
    }

    return (
      <Draggable
        className="thumbnail"
        style={{ backgroundImage: `url("${this.props.media.cdn_thumbnail ?? ''}")` }}
        info={{
          origin: 'share',
          type: 'media',
          content: this.props.media,
        }}
        draggable={this.props.media.rightsRequestStatus === RightRequestMQLStatus.Agreed}
      >
        {this.props.media.type === 'video'
                    && (
                    <div className="play-button">
                      <PlayArrowRoundedIcon
                        style={{ fontSize: '5rem', margin: 'auto' }}
                      />
                    </div>
                    )}
        <ShareCollectionContentOverlay
          haveRights={this.props.media.rightsRequestStatus === RightRequestMQLStatus.Agreed}
          isInstagramVideo={this.props.media.type === 'video' && this.props.media.source.name === 'instagram'}
          onShare={this.onShare.bind(this)}
          onDownload={() => this.download()}
        />
      </Draggable>
    );
  }

  private async isURLDead(url: string) {
    return fetch(url, { method: 'HEAD' }).then((e) => e.status === 404);
  }

  private download() {
    if (this.downloading) {
      return;
    }
    this.downloading = true;
    this.mediaService.download(this.props.media).finally(() => {
      this.downloading = false;
    });
  }
}

export default connect((state: ICombinedReducers) => ({
  config: state.common.config,
}))(OriginalUGC);
