import React, { ReactNode, useState } from 'react';
import { Row } from '@src/components/Utils';
import { classes, stylesheet } from 'typestyle';
import { Hide } from '@src/components/Hide';
import { ADALONG_COLORS } from '@src/styles';
import Popover from '@mui/material/Popover';
import { ProductCard, ProductCardData } from '../ProductCard';

interface Props {
  data: ProductCardData;
  imageLinks: string[];
  imageButtons?: ReactNode | undefined;
  postDataElement?: ReactNode | undefined;
  buttons: ReactNode;
  onImageClick?: (() => void) | null;
  onProductClick?: () => void;
}

const MAX_IMAGES = 4;
const PRODUCT_HEIGHT = 95;
const IMAGE_HEIGHT = 90;
const IMAGE_OFFSET = 8;
const IMAGE_WIDTH = IMAGE_HEIGHT;

/**
 * Create a ProductCard with common ProductGroups props and customizable data
 * @param props
 * @returns
 */
export function ProductGroupRow(props: Props) {
  return (
    <Row
      className={classes(sheet.productGroupRow, {
        [sheet.productGroupClickable]: !!props.onProductClick,
      })}
      onClick={props.onProductClick}
    >
      <ProductCard
        thumbnail={
          <div>
            {props.imageLinks.slice(0, MAX_IMAGES).map((link, index) => {
              const imageOffset = IMAGE_OFFSET * index;
              return (
                <Image
                  key={index}
                  src={link}
                  offset={imageOffset}
                  width={IMAGE_WIDTH - imageOffset}
                  onClick={props.onProductClick}
                />
              );
            })}
            <Hide hide={!props.imageButtons}>{props.imageButtons}</Hide>
          </div>
        }
        thumbnailWidth={IMAGE_WIDTH}
        data={props.data}
        height={PRODUCT_HEIGHT}
        onClick={props.onProductClick}
      />
      {props.postDataElement}
      {props.buttons}
    </Row>
  );
}

function Image(props: { src: string; width: number; offset?: number; onClick?: () => void }) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const handlePopoverOpen = (event: React.MouseEvent<HTMLElement, MouseEvent>) => {
    setAnchorEl(event.currentTarget);
  };
  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);
  return (
    <div
      onClick={props.onClick}
      className={sheet.productImage}
      onMouseEnter={handlePopoverOpen}
      onMouseLeave={handlePopoverClose}
      style={{
        height: IMAGE_HEIGHT,
        maxWidth: props.width,
        left: props.offset || 0,
      }}
    >
      {open && (
        <Popover
          id='mouse-over-popover'
          open={open}
          className={sheet.popover}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'top',
            horizontal: 'left',
          }}
          transformOrigin={{
            vertical: 'bottom',
            horizontal: 'right',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <img className={sheet.popoverImage} src={props.src} />
        </Popover>
      )}
      <img src={props.src} />
    </div>
  );
}

const sheet = stylesheet({
  popover: {
    pointerEvents: 'none',
    maxHeight: IMAGE_HEIGHT * 6,
    maxWidth: IMAGE_WIDTH * 6,
  },
  popoverImage: {
    display: 'flex',
    alignSelf: 'center',
    height: 'auto',
    width: IMAGE_WIDTH * 4,
    maxHeight: IMAGE_WIDTH * 5.5,
    zIndex: 9999,
  },
  productImage: {
    position: 'absolute',
    top: 0,
    flexShrink: 0,
    overflow: 'hidden',
    borderRadius: 10,
    border: `1px solid ${ADALONG_COLORS.SMOOTH_BLACK}`,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'center',
    $nest: {
      '& img': {
        height: '100%',
        margin: 'auto',
      },
    },
    cursor: 'pointer',
  },
  productGroupRow: {
    marginBottom: 20,
  },
  productGroupClickable: {
    cursor: 'pointer',
  },
});
