import React from 'react';
import { IconButton, InputAdornment, TextField } from '@mui/material';
import SendIcon from '@mui/icons-material/Send';
import { stylesheet } from 'typestyle';
import { percent, px } from 'csx';
import { ADALONG_COLORS } from '@src/styles';
import { Loader } from '@src/components/Loader';

export function MessageWriter(props: {
  text: string;
  isLoading: boolean;
  onChange: (text: string) => void;
  onSend: (text: string) => void;
}) {
  return (
    <div className={sheet.container}>
      <TextField
        id='outlined-start-adornment'
        multiline
        sx={{
          m: 1,
          width: percent(100),
          margin: 0,
          padding: px(8),
          paddingRight: px(80),
        }}
        placeholder='Write a message'
        value={props.text}
        onChange={(e) => props.onChange(e.target.value)}
        className={sheet.textField}
        InputProps={{
          endAdornment: (
            <InputAdornment position='end'>
              {!props.isLoading ? (
                <IconButton
                  sx={{ color: ADALONG_COLORS.SMOOTH_WHITE }}
                  aria-label='send message'
                  onClick={() => props.onSend(props.text)}
                >
                  <SendIcon />
                </IconButton>
              ) : (
                <Loader />
              )}
            </InputAdornment>
          ),
        }}
        variant='outlined'
      />
    </div>
  );
}

const sheet = stylesheet({
  container: {
    flexShrink: 0,
    width: percent(100),
    $nest: {},
  },
  textField: {
    $nest: {
      '& .MuiInputBase-root, fieldset': {
        color: ADALONG_COLORS.LIGHT_WHITE,
        borderColor: `#676666 !important`,
      },
    },
  },
});
