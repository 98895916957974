import { InfiniteData, useInfiniteQuery, UseInfiniteQueryOptions, UseInfiniteQueryResult } from 'react-query';
import { MediaFacet, MediaQuery, MediaView, ResGetMedia } from 'common';
import { queryClient, QUERY_KEYS } from '@src/query';
import { ApiError } from '@src/services/api';
import { MediaConstraints, MediaFilterBuilder } from '@src/fragments/Filters/utils/MediaFilterBuilder';
import { useDebounce } from 'use-debounce';
import { searchMedias } from './api';

export const MEDIA_LIMIT = 25;

export type QueryMediaFindHook = UseInfiniteQueryResult<ResGetMedia, ApiError>;
export let lastMediaLibraryQuery: Pick<MediaQuery, 'filter' | 'search'> | undefined;

/**
 * Find media for the given MediaQuery
 */

export const useQueryMediaFind = (
  params: Parameters<typeof QUERY_KEYS.mediaFindParams>[0],
  config?: UseInfiniteQueryOptions<ResGetMedia, ApiError>
): QueryMediaFindHook =>
  useInfiniteQuery({
    queryKey: QUERY_KEYS.mediaFindParams(params),
    queryFn: ({ pageParam: cursor }) =>
      searchMedias({
        ...params,
        cursor: cursor as string,
        limit: typeof params?.limit === 'number' ? params.limit : MEDIA_LIMIT,
      }),
    getNextPageParam: (lastRes) => lastRes.cursor,
    cacheTime: 0,
    ...config,
  });

/// Theses functions are shortcuts to create different
/// uses of the mediaFind query

/**
 * Create a mediaFind hook to get media
 * for the current queryString fields
 *
 */
export function useMediaFindLibrary(mc: MediaConstraints, limit: number = 25): QueryMediaFindHook {
  const query = MediaFilterBuilder.buildMediaFilter(mc);
  lastMediaLibraryQuery = query;
  return useQueryMediaFind(
    {
      ...query,
      limit,
    },
    { keepPreviousData: true }
  );
}

/**
 * Create a mediaFind hook to get a facet
 * for the given user input and queryString fields
 * Set debounce to avoid multiple requests
 */
export function useMediaFindFacet(
  mc: MediaConstraints,
  val: string,
  facet: MediaFacet | MediaFacet[],
  debounce: number = 0,
  enabled: boolean = true
): QueryMediaFindHook {
  const ignore = {
    creators: facet === 'creators',
    languages: facet === 'languages',
    productsExact: facet === 'productIdsExact',
    productsSimilar: facet === 'productIdsSimilar',
    ownContent: facet === 'ownContent',
  };
  const mediaFilter = MediaFilterBuilder.buildMediaFilter(mc, ignore);

  const [debouncedVal] = useDebounce(val, debounce);

  return useQueryMediaFind(
    {
      ...mediaFilter,
      limit: 0, // we don't want media, just facets
      facets: {
        fields: Array.isArray(facet) ? facet : [facet],
        input: debouncedVal,
      },
    },
    { enabled }
  );
}

/**
 * Update all mediaFind queries optimistically
 * The first depth of changesToMerges will be merged with the current media data
 * @example
 * updateMediaFindData('6064308b8cf81a370d642801', {
 *  object_labels: [{
 *      bounding_poly: [{x: 0, y: 0}, {x: 1, y: 0}, {x: 1, y: 1}, {x: 0, y: 1}],
 *      object_type: 'default',
 *      product_ids: ['3']
 *  }]
 * })
 */
export function updateMediaFindData(mediaId: string, changesToMerge: Partial<MediaView>): void {
  const queries = queryClient.getQueryCache().findAll(QUERY_KEYS.mediaFind());
  for (const query of queries) {
    const data = queryClient.getQueryData<InfiniteData<ResGetMedia>>(query.queryKey);
    if (data !== undefined) {
      const newPages = data.pages.map((page) => {
        // clone to avoid direct mutation
        const newPage = {
          ...page,
          media: [...page.media],
        };
        const mediaIndex = newPage.media.findIndex((media) => media._id === mediaId);
        if (mediaIndex !== -1) {
          newPage.media.splice(mediaIndex, 1, {
            ...newPage.media[mediaIndex],
            ...changesToMerge,
          });
        }
        return newPage;
      });
      queryClient.setQueryData<InfiniteData<ResGetMedia>>(query.queryKey, () => ({
        pages: newPages,
        pageParams: data.pageParams,
      }));
    }
  }
}
