import { GlobalError, GlobalErrorTypes } from '@src/types/GlobalError';
import LocalizedStrings from 'react-localization';
import { addAlert } from './helpers/redux';

export class GlobalErrorService implements GlobalError {
  public displayUnknownError(): void {
    addAlert({
      text: 'An error occured, please try again or contact us',
      type: 'danger',
    });
  }

  public displayCustomError(msg: string): void {
    addAlert({
      text: msg,
      type: 'danger',
    });
  }

  public displayTypeError(type: GlobalErrorTypes): void {
    let text = lang[type];
    if (!text) {
      text = lang.unknown;
    }
    addAlert({
      text,
      type: 'danger',
    });
  }
}

const lang = new LocalizedStrings({
  en: {
    unknown: 'An error occured, please try later or contact us',
    notlogged: 'You are no longer logged in',
    auth: 'You cannot access to this resource',
    forbidden: 'You do not have permission to do this',
    notfound: 'Resource not found',
    role: 'Access denied - Unsufficcient rights',
    'expired-action-code': 'Your password has expired, please enter your email again',
  },
});
