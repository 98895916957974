import React from 'react';
import { classes } from 'typestyle';
import { MediaView, UserView } from 'common';
import { MediaMark } from '@src/components/MediaInfo/MediaMark';
import { IMediaService } from '@src/types/Media';
import { PerfectScrollbar } from '@src/components/PerfectScrollbar';
import { Browsing } from '@src/types/Browsing';
import { tabContentClass, contentFrameClass } from './styles';

interface Props {
  media: MediaView
  mediaService: IMediaService
  user: UserView | null
  browsingService: Browsing
  onClose: () => void
}

export const InfoTab = (props: Props) => {
  const {
    media,
    mediaService,
  } = props;

  return (
    <>
      <PerfectScrollbar className={classes(tabContentClass, contentFrameClass)}>
        <MediaMark
          marks={mediaService.getMarks(media, ['mentions', 'hashtags', 'tagged', 'labels', 'ownContent'])}
        />
      </PerfectScrollbar>
    </>
  );
};
