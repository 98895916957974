/* eslint-disable import/prefer-default-export */
import { useQuery, UseQueryOptions } from 'react-query';
import { queryClient, DEPRECATED_QUERY_KEYS } from '@src/query';
import { ProductView } from 'common';
import { searchMedias } from './api';
import { getProducts } from '../products/api';

/**
 * Find products linked to any media
 */

export const useQueryMediaProduct = (value: string, config?: UseQueryOptions<ProductView[]>) =>
  useQuery({
    queryKey: [DEPRECATED_QUERY_KEYS.MEDIA_PRODUCT, value],
    queryFn: async () => {
      const res = await searchMedias({
        filter: { and: [] },
        facets: {
          fields: ['productIdsExact', 'productIdsSimilar'],
          input: value,
        },
        limit: 0,
      });

      const productIdsExact = res?.facets?.productIdsExact;
      const productIdsSimilar = res?.facets?.productIdsSimilar;

      // Combining the arrays to form a unified list of product IDs
      const combinedProductIds = [...(productIdsExact || []), ...(productIdsSimilar || [])];

      if (!combinedProductIds.length) {
        return [];
      }

      const products = queryClient.fetchQuery<ProductView[]>(
        [DEPRECATED_QUERY_KEYS.PRODUCTS, combinedProductIds],
        async (): Promise<ProductView[]> => {
          const products = await getProducts({
            ids: combinedProductIds,
          });
          return products;
        }
      );

      return products;
    },
    ...config,
  });

//           //TODO Lea this is supposed to change when we've done the migration(exact/similar)
// export const useQueryMediaProduct = (value: string, config?: UseQueryOptions<ProductView[]>) =>
//   useQuery({
//     queryKey: [DEPRECATED_QUERY_KEYS.MEDIA_PRODUCT, value],
//     queryFn: async () => {
//       const res = await searchMedias({
//         filter: { and: [] },
//         facets: {

//           fields: ['productIdsExact', 'productIdsSimilar'],
//           input: value,
//         },
//         limit: 0,
//       });

//       const productIdsExact = res?.facets?.productIdsExact;
//       const productIdsSimilar = res?.facets?.productIdsSimilar;

//       // Combining the arrays to form a unified list of product IDs
//       const combinedProductIds = [...(productIdsExact || []), ...(productIdsSimilar || [])];

//       if (!combinedProductIds.length) {
//         return [];
//       }

//       const products = queryClient.fetchQuery<ProductView[]>(
//         [DEPRECATED_QUERY_KEYS.PRODUCTS, combinedProductIds],
//         async (): Promise<ProductView[]> => {
//           const products = await getProducts({
//             ids: combinedProductIds,
//           });
//           return products;
//         }
//       );

//       return products;
//     },
//     ...config,
//   });
