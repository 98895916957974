import { UseInfiniteQueryOptions, useInfiniteQuery, useQuery } from 'react-query';
import { QUERY_KEYS } from '@src/query';
import { ApiTypes } from 'common';
import { ApiError } from '@src/services/api';
import { getAllProductGroups, getProductGroups } from './api';
import { ProductGroup } from 'common/dist/types/apiTypes';

export const useQueryProductGroups = (
  params: Parameters<typeof QUERY_KEYS.productGroupsParams>[0],
  config?: UseInfiniteQueryOptions<ApiTypes.ResGetProductGroups, ApiError>
) =>
  useInfiniteQuery<ApiTypes.ResGetProductGroups, ApiError>(
    QUERY_KEYS.productGroupsParams(params),
    async ({ pageParam: cursor }) =>
      getProductGroups({
        cursor,
        ...params,
      }),
    {
      getNextPageParam: (lastRes) => lastRes.cursor,
      ...config,
    }
  );

export const useQueryAllProductGroups = () =>
  useQuery(
    [QUERY_KEYS.productGroups()],
    async (): Promise<ProductGroup[]> => {
      const res = await getAllProductGroups();
      return res.productGroups;
    }
  );
