import { useMutationMediaObjectLabelsUpdate } from '@src/queries/media/useMutationMediaObjectLabelsUpdate';
import { ApiTypes, MediaView, ProductView } from 'common';
import { ArrayHelper } from '@src/services/helpers/array';

export const useProductLinking = (
  media: MediaView,
) => {
  const { mutate: mutateObjectLabels, isLoading } = useMutationMediaObjectLabelsUpdate();

  const updateProductIds = (
    existingIds: string[] | undefined,
    newIds: string[],
    shouldMerge: boolean
  ): string[] => {
    // If shouldMerge is true, we merge the newIds with the existingIds.
    // If shouldMerge is false, we remove the newIds from the existingIds.
    return shouldMerge
      ? ArrayHelper.uniqBy([...(existingIds ?? []), ...newIds])
      : (existingIds ?? []).filter(id => !newIds.includes(id));
  };

  const mutateMedia = (
    updatedExactIds?: string[],
    updatedSimilarIds?: string[]
  ) => {
    const params: ApiTypes.ParamsPutObjectLabels = {
      medias: [
        {
          mediaId: media._id,
          productIdsExact: updatedExactIds,
          productIdsSimilar: updatedSimilarIds,
        }
      ],
    };

    mutateObjectLabels(params);
  };

  const linkProducts = (products: ProductView[], linkType: string) => {
    const productIds = ArrayHelper.uniqBy(products.map((p) => p.id));
    const updatedExactIds = linkType === 'exact'
      ? updateProductIds(media.productIdsExact, productIds, true)
      : media.productIdsExact;

    const updatedSimilarIds = linkType === 'similar'
      ? updateProductIds(media.productIdsSimilar, productIds, true)
      : media.productIdsSimilar;

    mutateMedia(updatedExactIds, updatedSimilarIds);
  };

  const unlinkProducts = (productIdsToUnlink: string[], linkType: string) => {
    const updatedExactIds = linkType === 'exact'
      ? updateProductIds(media.productIdsExact, productIdsToUnlink, false)
      : media.productIdsExact;

    const updatedSimilarIds = linkType === 'similar'
      ? updateProductIds(media.productIdsSimilar, productIdsToUnlink, false)
      : media.productIdsSimilar;

    mutateMedia(updatedExactIds, updatedSimilarIds);
  };

  return { linkProducts, unlinkProducts, isLoading };
};
