import React, { Component } from 'react';
import Close from '@mui/icons-material/Close';
import { style, classes } from 'typestyle';

const SIZES = {
  small: 12,
  medium: 14,
  large: 16,
};

interface Props {
  classNames?: string[]
  onClick?: (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => void
  size?: keyof typeof SIZES | number
  color?: string
  hoverColor?: string
}

export class CloseButton extends Component<Props> {
  public render() {
    const {
      classNames = [],
      onClick,
      size,
      color: buttonColor = '#8f8f8f',
      hoverColor,
    } = this.props;

    let fontSize: number = SIZES.medium;
    if (size) {
      if (typeof size === 'string') {
        fontSize = SIZES[size];
      } else {
        fontSize = size;
      }
    }

    const hover = hoverColor ? {
      color: hoverColor,
    } : {
      filter: 'brightness(150%)',
    };

    const hoverClass = style({
      color: buttonColor,
      $nest: {
        '&:hover': hover,
      },
    });
    return (
      <Close
        style={{ fontSize }}
        className={classes(closeClass, ...classNames, hoverClass)}
        onClick={onClick}
      />
    );
  }
}

const closeClass = style({
  transition: '0.2s filter',
  cursor: 'pointer',
});
