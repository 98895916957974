import { addAlert, loadUserInfo } from '@src/actions/common';
import { setRoute } from './helpers/redux';
import { bigStore } from '@src/store';
import { getAuth, signOut, signInWithCustomToken, FacebookAuthProvider, GoogleAuthProvider, User } from 'firebase/auth';
import { submitting, loginFailed, logOut } from '@src/actions/login';
import { ServiceName, ServiceReference } from '@src/types/Services';
import { Api } from '@src/types/api';
import { Start } from '@src/types/Start';
import { Browsing, Pathname } from '@src/types/Browsing';
import { Login, SignInSuccess } from '../types/Login';
import { LOREALPRO_MICROSOFT_TENANT_ID } from 'config';
import { auth } from '../config/firebaseAuth';
import firebaseui from 'firebaseui';

export const UI_CONFIG: firebaseui.auth.Config = {
  signInFlow: 'popup',
};

const defaultSignInOptions: firebaseui.auth.Config['signInOptions'] = [
  FacebookAuthProvider.PROVIDER_ID,
  GoogleAuthProvider.PROVIDER_ID,
];

const lorealSignInOptions: firebaseui.auth.Config['signInOptions'] = [
  {
    provider: 'microsoft.com',
    customParameters: {
      tenant: LOREALPRO_MICROSOFT_TENANT_ID,
    }
  }
];

export class LoginService implements Login {
  public loggingOut = false;

  private apiService?: Api;

  private startService?: Start;

  private browsingService?: Browsing;

  private uiConfig?: firebaseui.auth.Config;

  public constructor(servicesPromise: ServiceReference) {
    servicesPromise.then((services) => {
      this.apiService = services.get<Api>(ServiceName.Api);
      this.startService = services.get<Start>(ServiceName.Start);
      this.browsingService = services.get<Browsing>(ServiceName.Browsing);
    });
  }

  public handleExpiredPassword(e: { message: string; type?: string }) {
    if (e.type === 'expiredtoken') {
      addAlert({
        text: 'Your password has expired, please change it now',
        type: 'danger',
      });
      setRoute(Pathname.changePassword);
    }
  }

  /**
     * Logout user
     * Note: Only way to logout
     */
  public async logout() {
    try {
      this.loggingOut = true;
      await signOut(auth);
      await this.apiService?.logout();
    } catch (e) {
      console.error(e);
    } finally {
      this.loggingOut = false;
      bigStore.dispatch(logOut());
      this.browsingService?.goToPage(Pathname.login);
    }
  }

  public async switchGroup(groupId: string) {
    try {
      await this.apiService?.switchGroup({ groupId });
      location.reload();
    } catch (e) {
      console.error(e);
    }
  }

  /**
   * Login user with email
   */
  public emailLogin(username: string, password: string, captchaToken: string) {
    bigStore.dispatch(submitting('login with credentials'));
    if (!captchaToken) {
      addAlert({
        text: 'Missing Captcha Token',
        type: 'danger',
      });
      bigStore.dispatch(loginFailed('missing captcha token'));
    }
    this.apiService?.loginFirebaseEmail(username, password, captchaToken, async (err, token) => {
      if (err) {
        this.handleLoginError(err);
        return;
      }
      if (token) {
        try {
          const res = await signInWithCustomToken(auth, token); // Use signInWithCustomToken from the modular SDK
          if (res.user) {
            const idToken = await res.user.getIdToken();
            this.apiService?.loginFirebase({
              idToken,
              uid: res.user.uid,
              has_accepted_cgu: true,
            }, async (e) => {
              if (e) {
                this.handleExpiredPassword(e);
                return;
              }
              await this.startService?.loadUser();
              this.startService?.loginSuccess();
            });
          }
        } catch (error) {
          console.error(error);
          const errorCode = (error as any).code;
          const cleanedErrorCode = errorCode.replace(/[\W_]+/g, '');
          bigStore.dispatch(loginFailed(cleanedErrorCode));
        }
      }
    });
  }

  public checkPasswordRoute(): boolean {
    const state = bigStore.getState();
    return !!(/\/changepassword/.test(window.location.pathname) && state.common.urlParams.oobCode);
  }

  public getFirebaseUiConfig() {
    if (this.uiConfig) {
      return this.uiConfig;
    }

    const tenantId = this.getTenantIdFromUrl();
    if (tenantId) {
      this.setFirebaseTenantId(tenantId);
    }
    return this.uiConfig = this.getUiConfig(tenantId);
  }

  private handleLoginError(err: any) {
    if (err.type === 'role') {
      addAlert({
        text: 'Invalid username or password',
        type: 'danger',
      });
    } else {
      addAlert({
        text: 'Invalid username or password',
        type: 'danger',
      });
    }
    const errorType = err.type?.split(' ').join('').toLowerCase();
    bigStore.dispatch(loginFailed(errorType || 'unknown'));
  }

  private getUiConfig(tenantId: string | null): firebaseui.auth.Config {
    switch (tenantId) {
      case 'lorealpro-ainzt':
        return {
          ...UI_CONFIG,
          signInOptions: lorealSignInOptions,
          callbacks: {
            signInSuccessWithAuthResult: this.signInSuccessWithAuthResult.bind(this),
          },
        }
      default:
        return {
          ...UI_CONFIG,
          signInOptions: defaultSignInOptions,
          callbacks: {
            signInSuccessWithAuthResult: this.signInSuccessWithAuthResult.bind(this),
          },
        }
    }
  }

  private signInSuccessWithAuthResult(authResult: SignInSuccess, _: unknown) {
    bigStore.dispatch(submitting('login with oauth'));
    const user = auth.currentUser;
    if (authResult && user) {
      user.getIdToken().then((idToken) => {
        this.apiService?.loginFirebase({
          uid: authResult.user.uid,
          idToken,
          has_accepted_cgu: true,
        }, ((e?) => {
          if (e) {
            this.handleExpiredPassword(e);
            bigStore.dispatch(loginFailed(e.message.split(' ').join('').toLowerCase()));
            return false;
          }
          loadUserInfo()(bigStore.dispatch).then(() => {
            this.startService?.loginSuccess();
          });
        }));
      });
    }
    return false;
  }

  private getTenantIdFromUrl(): string | null {
    const urlParams = new URLSearchParams(window.location.search);
    const tenantId = urlParams.get('tenant');

    switch (tenantId) {
      case 'loreal':
        return 'lorealpro-ainzt';
    }

    return tenantId ? tenantId.toLowerCase() : null
  }

  private setFirebaseTenantId(tenantId: string) {
    auth.tenantId = tenantId;
  }
}
