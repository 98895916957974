import React, { PropsWithChildren } from 'react';

import * as reduxHelpers from '@src/services/helpers/redux';
import { UserView, CollectionView, CollectionPermissionView } from 'common';
import { PerfectScrollbar } from 'components/PerfectScrollbar';
import { useSelector } from 'react-redux';
import { ICombinedReducers } from '@src/types/redux';
import FormControl from '@mui/material/FormControl';
import FormGroup from '@mui/material/FormGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import { style, classes, stylesheet } from 'typestyle';
import { useMutationCollectionPermissions } from '@src/queries/collections/useMutationCollection';
import { getCollectionLangError } from '@src/pages/Collection/collectionErrors';
import { CloseButton } from '@src/components/CloseButton';
import { ActionButton } from '@src/components/ActionButton';
import { CheckBox } from '@src/components/CheckBox';
import { ModalCloseButtonPosition } from '@src/components/Modals/CloseButtonPosition';
import { FormLineSeparator } from '@src/components/Form/FormLineSeparator';
import { FormLine } from '@src/components/Form/FormLine';
import { FormTitle } from '@src/components/Form/FormTitle';
import { OverlayContainer } from '@src/components/OverlayContainer';
import { useAddAlert } from '@src/fragments/Alerting/alertAtom';

interface Props {
  collection: CollectionView;
  onClose: () => void;
}

export function PermissionSettings(props: Props) {
  const addAlert = useAddAlert();
  const { mutateAsync: mutatePermissions, reset: resetMutatePermissions } =
    useMutationCollectionPermissions();

  const groupUsers = useSelector((state: ICombinedReducers) => state.common.groupUsers);

  const setUserPermissions = (
    user: CollectionPermissionView['user'],
    access: boolean,
    edit: boolean,
  ) => {
    mutatePermissions(
      {
        user: user._id,
        access,
        edit,
        collection: props.collection._id,
      },
      {
        onError: (error) =>
          addAlert({
            text: getCollectionLangError(error),
            close: resetMutatePermissions,
          }),
      },
    ).catch(console.error);
  };

  const permissions = props.collection.permissions?.filter((p) => !!p.user) || [];
  const filteredGroupUsers = groupUsers.filter(
    (u) => !permissions.find((p) => p.user._id === u._id) && u._id !== props.collection.owner._id,
  );

  return (
    <OverlayContainer style={{ minWidth: 450, padding: 35 }} onClose={props.onClose}>
      <FormTitle value={window.T('create.collection.permissions.title')} />
      <ModalCloseButtonPosition>
        <CloseButton onClick={props.onClose} />
      </ModalCloseButtonPosition>
      <PerfectScrollbar
        options={{
          minScrollbarLength: 30,
          wheelSpeed: 0.3,
        }}
        className={scrollContainerClass}
      >
        <div className={contentContainerClass}>
          <FormLine title={window.T('create.collection.permissions.hasaccess')} />
          <UserListContainer noOne={permissions.length === 0}>
            {permissions.map((perm) => (
              <div className={sheet.permissionsUser} key={perm.user._id}>
                <div className={sheet.permissionDisplayName} title={perm.user.displayName}>
                  {perm.user.displayName}
                </div>
                <div
                  className={classes(sheet.selectPermsClass, sheet.permissionChangeButton)}
                  onClick={(e) => e.stopPropagation()}
                >
                  <div>
                    <FormControl component='fieldset'>
                      <FormGroup aria-label='position' row>
                        <FormControlLabel
                          value='view'
                          onClick={() => setUserPermissions(perm.user, false, false)}
                          control={<CheckBox className={sheet.checkBox} checked />}
                          label='View'
                          labelPlacement='start'
                          className={classes(sheet.selectPermsViewClass, sheet.selectPermsBoxClass)}
                        />
                        <FormControlLabel
                          value='edit'
                          onClick={() => setUserPermissions(perm.user, true, !perm.edit)}
                          control={<CheckBox className={sheet.checkBox} checked={perm.edit} />}
                          label='Edit'
                          labelPlacement='start'
                          className={sheet.selectPermsBoxClass}
                        />
                      </FormGroup>
                    </FormControl>
                  </div>
                </div>
              </div>
            ))}
          </UserListContainer>
          <FormLineSeparator />
          {filteredGroupUsers.length > 0 && (
            <FormLine title={window.T('create.collection.permissions.ingroup')}>
              {filteredGroupUsers.map((user: UserView) => (
                <div className={sheet.permissionsUser} key={user._id}>
                  <div className={sheet.permissionDisplayName} title={user.displayName}>
                    {user.displayName}
                  </div>
                  <div className={sheet.permissionChangeButton}>
                    <ActionButton
                      variant='blue'
                      size='small'
                      onClick={() => setUserPermissions(user, true, false)}
                    >
                      {window.T('create.collection.permissions.giveaccess').toUpperCase()}
                    </ActionButton>
                  </div>
                </div>
              ))}
            </FormLine>
          )}
        </div>
      </PerfectScrollbar>
    </OverlayContainer>
  );
}

function UserListContainer(
  props: PropsWithChildren<{
    noOne: boolean;
  }>,
) {
  return (
    <div>
      {props.noOne && (
        <div className={sheet.noOne}>{window.T('create.collection.permissions.onlyyou')}</div>
      )}
      {props.children}
    </div>
  );
}

const sheet = stylesheet({
  collectionSettings: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 5,
  },
  frame: {
    zIndex: 3,
    margin: 'auto',
    width: 500,
    maxHeight: '94%',
    backgroundColor: '#202020',
    fontFamily: '"Public Sans", sans-serif',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  detailsContainer: {
    padding: '10px 7px 5px',
  },
  detailsField: {
    paddingBottom: 10,
    $nest: {
      input: {
        borderColor: '#a6a6a6',
        width: '100%',
      },
    },
  },
  detailsSave: {
    marginTop: 15,
    textAlign: 'center',
    $nest: {
      button: {
        minWidth: 110,
        padding: '6px 20px',
      },
    },
  },
  noOne: {
    display: 'block',
    textAlign: 'center',
    margin: '15px 0 5px',
    color: 'gray',
  },
  permissionsUser: {
    fontSize: 14,
    color: '#d2d2d2',
    padding: '10px 7px 5px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  permissionChangeButton: {
    whiteSpace: 'nowrap',
  },
  permissionDisplayName: {
    overflow: 'hidden',
    paddingTop: 4,
    textOverflow: 'ellipsis',
    marginRight: 23,
  },
  checkBox: {
    marginLeft: 10,
  },
  selectPermsClass: {
    display: 'flex',
    justifyContent: 'space-between',
    $nest: {
      '> div': {
        cursor: 'pointer',
      },
      '.select': {
        position: 'relative',
        fontSize: 13,
        $nest: {
          i: {
            marginLeft: 10,
            fontSize: 18,
            verticalAlign: 'middle',
          },
          span: {
            fontSize: 14,
            fontFamily: '"Public Sans", sans-serif',
          },
        },
        color: '#aaaaaa',
        transition: 'color 0.2s',
      },
    },
  },

  selectPermsViewClass: {
    paddingRight: 14,
  },

  selectPermsBoxClass: {
    $nest: {
      '&:hover': {
        color: 'white',
      },
    },
  },
});

const scrollContainerClass = style({
  position: 'relative',
  height: '100%',
});

const contentContainerClass = style({
  padding: '5px 10px 28px 0',
});
