import React, { ReactNode, useRef, useState } from 'react';
import { Page } from '@src/components/Page';
import { Browsing, Pathname } from '@src/types/Browsing';
import { Row, Column } from '@src/components/Utils';
import { SectionTitle } from '@src/components/SectionTitle';
import { SelectableButton, SelectableButtonStyle } from '@src/components/SelectableButton';
import LocalizedStrings from 'react-localization';
import { stylesheet, classes } from 'typestyle';
import { ADALONG_COLORS } from '@src/styles';
import { useQueryAllProductGroups, useQueryProductGroups } from '@src/queries/products/useQueryProductGroups';
import { ProductGroupScroller } from '@src/components/Product/ProductGroupScroller';
import { useQueryCatalogTaxonomy } from '@src/queries/products/useQueryCatalogTaxonomy';
import { Hide } from '@src/components/Hide';
import CircularProgress from '@mui/material/CircularProgress';
import { FormError } from '@src/components/Form/FormError';
import { TaxonomySection } from '@src/components/Product/Taxonomy/TaxonomySection';
import { ArrayHelper } from '@src/services/helpers/array';
import { SearchAndSelectBar } from '@src/components/SearchAndSelectBar';
import { MediaLibrary } from '@src/fragments/MediaLibrary';
import { useQueryMediaFind } from '@src/queries/media/useQueryMediaFind';
import { ProductGroupSelectable } from '@src/components/Product/ProductGroupSelectable';
import { MediaView, ProductGroupView, ProductView } from 'common';
import { BulkMediaActions } from '@src/components/BulkMediaActions';
import { BarelyVisibleText } from '@src/components/BarelyVisibleText';
import debounce from 'lodash.debounce';
import { RightsRequestStatusSelect, RightsRequestStatusValue } from '@src/components/RightsRequestStatusSelect';
import { useMutationMediaObjectLabelsUpdate } from '@src/queries/media/useMutationMediaObjectLabelsUpdate';
import AuthorizedPage from '@src/components/AuthorizedPage';
import { useQueryUserConfig } from '@src/queries/users/useQueryUserConfig';
import { QUERY_PREFIX, queryClient } from '@src/query';
import { SortOrderSelect } from '@src/components/Product/SortOrderSelect';
import { ServiceName, Services } from '@src/types/Services';
import { IPopupsService } from '@src/types/Popups';
import { MediaActionButtonController } from '@src/fragments/MediaActionButton/MediaActionController';
import { GetRightsButton } from '@src/components/IPRights/GetRightsButton';
import { ProductGroup, SortOrder } from '@src/../common/dist/types/apiTypes';
import { removeProductIdsFromObjectLabels } from './utils/productLinking';
import { MediaCountBadge } from './ProductPreview/MediaCountBadge';
import { MediaPreview } from './ProductPreview/MediaPreview';
import { goToGetRights } from '../Media/utils/mediaRoute';
import { Mql } from '@adalong/schemas';
import { Select } from '@src/components/Select';
import { useQueryCollections } from '@src/queries/collections/useQueryCollections';

interface Props {
  services: Services;
}

export function ProductPage(props: Props) {
  const debounceProductSearch = useRef(debounce((v) => setProductSearchFilterDebounced(v), 500)).current;

  const [selectedCatalogCountry, setSelectedCatalogCountry] = useState<string>('ALL');
  const [selectedCollection, setSelectedCollection] = useState<string>('ALL');
  const [selectedCategoryPath, setSelectedCategoryPath] = useState<string[]>([]);
  const [productSearchFilter, setProductSearchFilter] = useState('');
  const [productSearchFilterDebounced, setProductSearchFilterDebounced] = useState<string>('');
  const [selectedProductIds, setSelectedProductIds] = useState<string[]>([]);
  const [mediaSelection, setMediaSelection] = useState<MediaView[]>([]);
  const [rightsRequestStatus, setRightsRequestStatus] = useState<RightsRequestStatusValue>('all');
  const [expandedProductGroup, setExpandedProductGroup] = useState<string | null>();
  const [selectedCreatorCountry, setSelectedCreatorCountry] = useState<string>('ALL');

  // Prepare required services
  const [services] = useState(() => ({
    browsing: props.services.get<Browsing>(ServiceName.Browsing),
    popups: props.services.get<IPopupsService>(ServiceName.Popups),
  }));

  const { mutateAsync: mutateObjectLabels } = useMutationMediaObjectLabelsUpdate();

  const { data: resUserConfig } = useQueryUserConfig();
  const { data: collections = [] } = useQueryCollections();
  const [sortOrder, setSortOrder] = useState<SortOrder>('name-asc');
  const [mediaSection, setMediaSection] = useState('exact');

  const {
    data: resProductGroups,
    isLoading: isLoadingProductGroups,
    error: errorProductGroups,
    fetchNextPage: fetchMoreProductGroups,
    hasNextPage: canFetchMoreProductGroups,
  } = useQueryProductGroups({
    text: productSearchFilterDebounced,
    productTypes: ArrayHelper.filterNulls([selectedCategoryPath?.join('/')]),
    catalogCountries: selectedCatalogCountry === 'ALL' 
      ? undefined 
      : selectedCatalogCountry === 'MY_COUNTRIES' 
      ? resUserConfig?.user?.catalogCountries 
      : [selectedCatalogCountry],
    sortOrder,
    withMedia: true,
    applyLocalization: true,
  });

  const { data: resAllProductGroups, isLoading: isLoadingAllProductGroups, error: errorAllProductGroups } = useQueryAllProductGroups();

  const { data: taxonomy, isLoading: isLoadingTaxonomy, error: errorTaxonomy } = useQueryCatalogTaxonomy();

  let filter: Mql = {
    ...(
        (rightsRequestStatus !== 'all' || selectedCollection !== 'ALL' || selectedCreatorCountry !== 'ALL')
        ? {
            and: [
              {
                [mediaSection === 'exact' ? 'productIdsExact' : 'productIdsSimilar']: selectedProductIds,
              },
            ],
          }
        : {
            [mediaSection === 'exact' ? 'productIdsExact' : 'productIdsSimilar']: selectedProductIds,
          }
        ),
  };

  if (rightsRequestStatus !== 'all') {
    filter.and = filter.and ?? [];
    filter.and.push({ rightRequestStatuses: [rightsRequestStatus] });
  }

  if (selectedCollection !== 'ALL') {
    filter.and = filter.and ?? [];
    filter.and.push({ collections: [selectedCollection] });
  }

  if (selectedCreatorCountry !== 'ALL') {
    filter.and = filter.and ?? [];
    if (selectedCreatorCountry === 'MY_COUNTRIES') {
      filter.and.push({ creatorCountries: resUserConfig?.user?.catalogCountries });
    } else {
      filter.and.push({ creatorCountries: [selectedCreatorCountry] });
    }
  }

  const { data: resMediaFind, fetchNextPage: fetchMoreMedia, hasNextPage: canFetchMoreMedia, isLoading: isLoadingMedia } = useQueryMediaFind({
    filter,
  });

  const mediaList = resMediaFind?.pages.flatMap((r) => r.media);

  const productGroups = resProductGroups?.pages.flatMap((p) => p.productGroups) ?? [];
  const allProductGroups: ProductGroup[] = resAllProductGroups ?? [];
  const selectedProductIdsMap = ArrayHelper.mapToObject(selectedProductIds, (p) => p);

  /**
   * Media Section selection (exact/similar)
   * */

  const handleSectionClick = (type: string) => {
    setMediaSection(type);
  };

  return (
    <AuthorizedPage tab='product_catalog' user={resUserConfig?.user}>
      <Page path={Pathname.product} contentClassNames={[sheet.page]}>
        <Row className={sheet.sectionsContainer}>
          <Column className={classes(sheet.section, sheet.categoriesSection)}>
            {/* Country Filter Section */}
            <Hide hide={resUserConfig?.user?.catalogCountries.length === 0}>
              <div className={sheet.countryFilter}>
                <SectionTitle>{lang.filterByCountry.toUpperCase()}</SectionTitle>
                <div style={{marginTop: '15px'}}/>
                <Select
                  options={[
                    { value: 'ALL', text: 'All countries' },
                    { value: 'MY_COUNTRIES', text: 'My countries' },
                    ...(resUserConfig?.user?.catalogCountries.map((country) => ({
                      value: country,
                      text: country,
                    })) ?? []),
                  ]}
                  value={selectedCatalogCountry}
                  onChange={(value) => setSelectedCatalogCountry(value)}
                  variant="adalong"
                />
              </div>
            </Hide>
            {/* Categories Section */}
            <SectionTitle>{lang.categories.toUpperCase()}</SectionTitle>
            <Hide hide={!isLoadingTaxonomy}>
              <CircularProgress style={{ margin: 'auto' }} />
            </Hide>
            <Hide hide={isLoadingTaxonomy}>
              <TaxonomySection
                taxonomy={taxonomy}
                selectedCategoryPath={selectedCategoryPath}
                setSelectedCategoryPath={setSelectedCategoryPath}
                productGroups={allProductGroups}
              />
            </Hide>
            <Hide hide={!errorTaxonomy}>
              <FormError error={errorTaxonomy?.message} />
            </Hide>
          </Column>
          <Column className={classes(sheet.section, sheet.productSection, sheet.leftSeparator)}>
            <SectionTitle>{lang.productList.toUpperCase()}</SectionTitle>
            <Row className={sheet.productBar}>
              <SearchAndSelectBar
                inputValue={productSearchFilter}
                handlers={{
                  onText: handleProductSearch,
                  onCloseBar: () => handleProductSearch(''),
                }}
              />
              <SortOrderSelect value={sortOrder} onChange={(v: SortOrder) => setSortOrder(v)} />
            </Row>
            <ProductGroupScroller fetchMore={async () => fetchMoreProductGroups().then()} noMore={!canFetchMoreProductGroups}>
              {productGroups?.map((productGroup) => (
                <ProductGroupSelectable
                  key={productGroup.name}
                  productGroup={productGroup}
                  selectedProductIdsMap={selectedProductIdsMap}
                  expanded={expandedProductGroup === productGroup.name}
                  handleProductClick={handleProductClick}
                  postDataElement={(product) => productMediaPreview(productGroup, product)}
                  imageButtons={(product) => productMediaCount(productGroup, product)}
                />
              ))}
              <Hide hide={!isLoadingProductGroups && !isLoadingAllProductGroups}>
                <CircularProgress style={{ margin: 'auto' }} />
              </Hide>
              <Hide hide={!errorProductGroups}>
                <FormError error={errorProductGroups?.message} />
              </Hide>
              <Hide hide={!!(errorProductGroups || productGroups.length)}>
                <BarelyVisibleText size='medium'>{lang.noProducts}</BarelyVisibleText>
              </Hide>
            </ProductGroupScroller>
          </Column>
          <Column className={classes(sheet.section, sheet.mediaSection, sheet.leftSeparator)}>
            <Row className={sheet.mediaListClass}>
              <SelectableButton
                classes={[sheet.buttonClass]}
                buttonStyle={buttonStyle}
                selected={mediaSection === 'exact'}
                onClick={() => handleSectionClick('exact')}
              >
                {lang.mediaListExact.toUpperCase()}
              </SelectableButton>
              <SelectableButton
                classes={[sheet.buttonClass]}
                buttonStyle={buttonStyle}
                selected={mediaSection === 'similar'}
                onClick={() => handleSectionClick('similar')}
              >
                {lang.mediaListSimilar.toUpperCase()}
              </SelectableButton>
            </Row>

            <Row className={sheet.mediaLibraryBar} style={{ justifyContent: 'left' }}>
              {/* Right Requests status filter */}
              <div style={{ marginRight: '5px', marginTop: '8px' }}>
                <RightsRequestStatusSelect value={rightsRequestStatus} onChange={setRightsRequestStatus} />
              </div>
              {/* Collections filter */}
              <Hide hide={resUserConfig?.user?.catalogCountries.length === 0}>
                <div className={sheet.countryFilter} style={{ marginRight: '5px', marginBottom: '0px', marginTop: '8px' }}>
                  <Select
                    options={[
                      { value: 'ALL', text: 'All collections' },
                      ...(collections.map((collection) => ({
                        value: collection._id,
                        text: collection.name,
                      })) ?? []),
                    ]}
                    value={selectedCollection}
                    onChange={(value) => setSelectedCollection(value)}
                    variant="adalong"
                  />
                </div>
              </Hide>
              {/* Creator countries filter */}
              <Hide hide={resUserConfig?.user?.catalogCountries.length === 0}>
                <div className={sheet.countryFilter} style={{ marginBottom: '0px', marginTop: '8px' }}>
                  <Select
                    options={[
                      { value: 'ALL', text: 'All countries' },
                      { value: 'MY_COUNTRIES', text: 'My countries' },
                      ...(resUserConfig?.user?.catalogCountries.map((country) => ({
                        value: country,
                        text: country,
                      })) ?? []),
                    ]}
                    value={selectedCreatorCountry}
                    onChange={(value) => setSelectedCreatorCountry(value)}
                    variant="adalong"
                  />
                </div>
              </Hide>
            </Row>
            <Row className={sheet.mediaLibraryBar} style={{ margin: 0}}>
              <BulkMediaActions
                className={classes(sheet.multipleSelect, {
                  [sheet.hideMultipleSelect]: !mediaList?.length,
                })}
                count={mediaSelection.length}
                onClear={() => setMediaSelection([])}
                mediaActionButton={
                  <MediaActionButtonController
                    inCollection={undefined}
                    mediaList={mediaSelection}
                    services={services}
                    unlinkProduct={(mediaList) => handleUnlinkProducts(mediaList.map((media) => media._id))}
                    catalogCountries={resUserConfig?.user?.catalogCountries}
                  />
                }
              />
            </Row>
            {!mediaList?.length && !selectedProductIds.length ? (
              <div className={sheet.mediaLibraryMessage}>
                <BarelyVisibleText size='medium'>{lang.noMedia}</BarelyVisibleText>
              </div>
            ) : (
              <MediaLibrary
                mediaList={mediaList || []}
                loadMoreMedia={fetchMoreMedia}
                hideOnImageFailed={true}
                noMore={!canFetchMoreMedia}
                selection={mediaSelection}
                setSelection={setMediaSelection}
                libraryLoaded={!isLoadingMedia}
                onUnlinkProduct={(media) => handleUnlinkProducts([media])}
                renderMediaActionButton={(media) => (
                  <MediaActionButtonController
                    services={services}
                    // collection removal is not required in product page
                    inCollection={undefined}
                    mediaList={[media]}
                    unlinkProduct={(mediaList) => handleUnlinkProducts(mediaList.map((media) => media._id))}
                    catalogCountries={resUserConfig?.user?.catalogCountries}
                  />
                )}
                renderShortcutActionButton={(media, failed) => (
                  <Hide hide={failed || media.rightsRequestStatus === 'agreed'}>
                    <GetRightsButton onAction={() => services.browsing.goToPage(goToGetRights(window.location, media._id))} size='small' />
                  </Hide>
                )}
              />
            )}
          </Column>
        </Row>
      </Page>
    </AuthorizedPage>
  );

  async function handleUnlinkProducts(mediaIds: string[]): Promise<void> {
    const mediaSet = new Set(mediaIds);
    // find MediaView for each mediaId
    const mediaViews = mediaList?.filter((m) => mediaSet.has(m._id));
    if (mediaViews === undefined || mediaViews.length === 0) {
      return;
    }

    // create a change for each media
    const changes = mediaViews.map((media) => {
      let type: 'exact' | 'similar' = 'exact';

      // Determine whether the selectedProductIds are in product_ids_exact or product_ids_similar
      const hasExact = media.productIdsExact?.some((pid) => selectedProductIds.includes(pid));
      if (!hasExact) {
        type = 'similar';
      }

      const newMedia = removeProductIdsFromObjectLabels(selectedProductIds, media, type);

      return {
        mediaId: media._id,
        productIdsExact: newMedia.productIdsExact,
        productIdsSimilar: newMedia.productIdsSimilar,
      };
    });

    // send changes in batch
    await mutateObjectLabels({
      medias: changes,
    });
    await queryClient.invalidateQueries(QUERY_PREFIX.MEDIA_FIND);
  }

  // Sets the sortOrder to relevance if the sorting was just alphabetical
  // which is probably what the user wants
  function handleProductSearch(v: string) {
    setProductSearchFilter(v);
    debounceProductSearch(v);
    const isSortedByName = sortOrder.startsWith('name');
    if (v !== '' && isSortedByName) {
      setSortOrder('relevance-desc');
    }
  }

  function handleProductClick({ productId, productGroup }: { productId: string | null; productGroup: ProductGroupView | null }): void {
    // clicked on a product group
    if (productGroup) {
      // all products must be selected for a product group to be considered as selected
      const isProductGroupSelected = !productGroup.products.find((product) => !selectedProductIdsMap[product.id]);
      const expanded = expandedProductGroup === productGroup.name;
      if (expanded) {
        if (isProductGroupSelected) {
          // unselect the product group
          setSelectedProductIds([]);
          // close this expanded group
          setExpandedProductGroup(null);
        } else {
          selectProductGroup(productGroup);
        }
      } else {
        selectProductGroup(productGroup);
        setExpandedProductGroup(productGroup.name);
      }
    } else if (productId) {
      if (selectedProductIds.length === 1 && selectedProductIds[0] === productId) {
        // unselect the currently selected product
        setSelectedProductIds([]);
      } else {
        setSelectedProductIds([productId]);
      }
    }
  }

  // select all product in product group
  function selectProductGroup(productGroup: ProductGroupView) {
    setSelectedProductIds(productGroup.products.map(({ id }) => id));
  }

  /** Return the appropriate MediaPreview to display for each product
   * It will prioritize the display of exact UGCs.
   * If there are no exact UGCs, it will display similar UGCs.
   */
  function productMediaPreview(productGroup: ProductGroupView, currentProduct?: ProductView): ReactNode {
    let mediaList: MediaView[] | undefined;
    if (currentProduct) {
      currentProduct.media?.exact.list ? (mediaList = currentProduct.media?.exact.list) : (mediaList = currentProduct.media?.similar.list);
    } else {
      productGroup.media?.exact.list ? (mediaList = productGroup.media?.exact.list) : (mediaList = productGroup.media?.similar.list);
    }
    return <MediaPreview mediaList={mediaList} />;
  }

  function productMediaCount(productGroup: ProductGroupView, currentProduct?: ProductView): ReactNode {
    if (productGroup.products.length === 1) {
      // Case product group has a single product
      return <MediaCountBadge total={productGroup.products[0].media?.exact.total} />;
    }
    if (currentProduct) {
      // Case product
      return <MediaCountBadge total={currentProduct.media?.exact.total} />;
    }
    if (productGroup.media !== undefined) {
      // Case product group has multiple products
      return <MediaCountBadge total={productGroup.media?.exact.total} />;
    }
    return null;
  }
}

const lang = new LocalizedStrings({
  en: {
    categories: 'Categories',
    productList: 'Products',
    mediaListExact: 'Exact Linked Media',
    mediaListSimilar: 'Similar Linked Media',
    noProducts: 'No products found',
    noMedia: 'Select products to display media linked to it',
    unlinkProduct: 'Unlink',
    filterByCountry: 'Filter products by country',
  },
});

const sheet = stylesheet({
  page: {
    padding: 0,
  },
  sectionsContainer: {
    height: '100%',
  },
  section: {
    padding: '39px 28px 28px',
  },
  categoriesSection: {
    flex: 1,
  },
  productSection: {
    flex: 2,
  },
  mediaSection: {
    flex: 2,
  },
  leftSeparator: {
    borderLeft: `2px solid ${ADALONG_COLORS.LIGHT_GRAY}`,
  },
  listSectionScroller: {
    marginTop: 15,
    textAlign: 'center',
    position: 'relative',
    overflow: 'hidden',
    flex: 1,
    paddingRight: 30,
  },
  productBar: {
    flexGrow: 0,
    marginTop: 15,
    justifyContent: 'space-between',
  },
  multipleSelect: {
    margin: '8px 5px',
  },
  hideMultipleSelect: {
    opacity: 0,
  },
  mediaLibraryBar: {
    flexShrink: 0,
    flexGrow: 0,
    margin: '8px 0',
    justifyContent: 'space-between',
  },
  mediaLibraryMessage: {
    margin: 55,
  },
  mediaListClass: {
    flexGrow: 0,
  },
  buttonClass: {
    padding: '0 22px',
    fontWeight: 'bold',
  },
  countryFilter: {
    marginBottom: '20px',
    display: 'flex',
    flexDirection: 'column',
  },
});

const buttonStyle: SelectableButtonStyle = {
  size: 14,
  borderColor: '#0041fa',
  textColor: ADALONG_COLORS.LIGHT_WHITE,
  borderSize: 3,
};
