import React from 'react';
import TextField from '@mui/material/TextField';
import type { TextFieldProps } from '@mui/material/TextField';
import { classes, stylesheet } from 'typestyle';

export function LoginTextInput({
  className,
  ...props
}: TextFieldProps) {
  return (
    <TextField
      className={classes(sheet.textField, className)}
      {...props}
    />
  );
}

const sheet = stylesheet({
  textField: {
    color: '#c0c0c0',
    $nest: {
      input: {
        paddingLeft: '8px !important',
        color: '#fff !important',
        fontWeight: 'bold',
      },
      'div::before': {
        borderBottom: 'solid 2px white',
      },
      label: {
        paddingLeft: 7,
        color: '#8a8a8a',
        fontWeight: 'bold',
      },
      notchedOutline: {
        borderWidth: '1px',
        borderColor: 'yellow !important',
      },
    },
    marginTop: '8px !important',
    marginBottom: '8px !important',
  },
});
