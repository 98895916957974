/* eslint-disable import/prefer-default-export */
import React, { useState } from 'react';

import { CollectionBar } from '@src/components/CollectionBar';
import { MediaView, CollectionView } from 'common';
import { Browsing, Pathname } from '@src/types/Browsing';
import { useLocationQS } from '@src/utils/useLocationQS';
import { useQueryCollection } from '@src/queries/collections/useQueryCollection';
import { useQueryCollectionMedias } from '@src/queries/collections/useQueryCollectionMedias';
import { useMutationCollectionRemoveMedia } from '@src/queries/collections/useMutationCollection';
import { RightsRequestStatusValue } from '@src/components/RightsRequestStatusSelect';
import { ServiceName, Services } from '@src/types/Services';
import { IPopupsService } from '@src/types/Popups';
import { MediaActionButtonController, MediaActionProps } from '@src/fragments/MediaActionButton/MediaActionController';
import { UncollectButton } from '@src/components/CollectingButtons/Uncollect';
import { GetRightsButton } from '@src/components/IPRights/GetRightsButton';
import { Hide } from '@src/components/Hide';

import LocalizedStrings from 'react-localization';
import { MediaLibrary } from '@src/fragments/MediaLibrary';
import { useQueryUserConfig } from '@src/queries/users/useQueryUserConfig';
import { ArrayHelper } from '@src/services/helpers/array';
import { useAddAlert } from '@src/fragments/Alerting/alertAtom';
import { goToGetRights } from '../Media/utils/mediaRoute';
import logo from '../../assets/img/site/logo_alone_white.png';
import { getCollectionLangError } from './collectionErrors';
import NavBar from '../../components/NavBar';
import { CollectionHeader } from './View/CollectionHeader';

interface Props {
  services: Services;
  collectionId: string;
}

export function Collection({ collectionId, ...props }: Props) {
  // Prepare required services
  const [services] = useState(() => ({
    browsing: props.services.get<Browsing>(ServiceName.Browsing),
    popups: props.services.get<IPopupsService>(ServiceName.Popups),
  }));
  const [mediaSelection, setMediaSelection] = useState<MediaView[]>([]);
  const addAlert = useAddAlert();

  const [rightsStatusFilter, setRightsStatusFilter] = useState<RightsRequestStatusValue>('all');

  const {
    t: [token],
  } = useLocationQS(['t']);

  const { data: collection, isLoading: isLoadingCollection } = useQueryCollection(collectionId, false, token);
  const { data: userConfig } = useQueryUserConfig();
  const {
    data: mediaResponses,
    fetchNextPage,
    isLoading,
    hasNextPage,
  } = useQueryCollectionMedias(collectionId, {
    token,
    rightsStatus: rightsStatusFilter,
  });
  const { mutateAsync: removeMedia, reset: resetRemove } = useMutationCollectionRemoveMedia();

  const medias = mediaResponses?.pages.flatMap(({ medias }) => medias) || [];
  const mediasObj = ArrayHelper.mapToObject(medias, ({ _id }) => _id);
  const total = (mediaResponses?.pages || [])[0]?.total || 0;

  const loadMore = async (): Promise<void> => {
    await fetchNextPage();
  };

  const mediasWithCollection: (MediaView & {
    collection?: CollectionView;
  })[] = medias.map((media) => ({
    ...media,
    collection,
  }));

  const canEdit = collection && (collection.i_can_edit || collection.mine);

  const disableRemoval: MediaActionProps['disable'] = { removeFromCollection: lang.disableRemove };

  // loader for the first page only
  const isLibraryLoading: boolean = medias.length === 0 && isLoading;

  const user = userConfig?.user;

  if ((user === undefined && token === undefined) || token === '') {
    return null;
  }

  return (
    <div id='collections' style={{ height: '100vh' }}>
      {user !== undefined ? (
        <NavBar active={Pathname.collect} />
      ) : (
        <nav id='navbar' className='navbar navbar-toggleable-md'>
          <div className='logo'>
            <img src={logo} style={{ cursor: 'default' }} />
          </div>
        </nav>
      )}
      <div className='collection'>
        {user !== undefined && <CollectionBar />}
        <CollectionHeader
          rightsStatusFilter={rightsStatusFilter}
          setRightsStatusFilters={setRightsStatusFilter}
          isLoading={isLoadingCollection}
          collection={collection || null}
          totalResults={total}
          mediaSelection={mediaSelection}
          setMediaSelection={setMediaSelection}
          services={services}
        />
        <MediaLibrary
          hideOnImageFailed={true}
          loadMoreMedia={loadMore}
          setSelection={setMediaSelection}
          selection={mediaSelection}
          libraryLoaded={!isLibraryLoading}
          mediaList={mediasWithCollection}
          noMore={!hasNextPage}
          renderMediaActionButton={(media) => (
            <MediaActionButtonController
              services={services}
              inCollection={collection}
              // If the collection can't be edited we disable the remove
              // from collection button and display a tooltip
              disable={!canEdit ? disableRemoval : undefined}
              mediaList={[media]}
              catalogCountries={user?.catalogCountries}
            />
          )}
          renderShortcutActionButton={(media, failed) =>
            // Get rights is the main button here but we show the
            // removal button in case of an unavailable media
            failed ? (
              <UncollectButton onClick={() => handleRemoveMedia(media._id)} />
            ) : (
              <Hide hide={media.rightsRequestStatus === 'agreed'}>
                <GetRightsButton onAction={() => services.browsing.goToPage(goToGetRights(window.location, media._id))} size='small' />
              </Hide>
            )
          }
        />
      </div>
    </div>
  );

  function handleRemoveMedia(mediaId: string) {
    if (collection !== undefined) {
      removeMedia(
        {
          mediaIds: [mediaId],
          collectionId: collection?._id,
        },
        {
          onError: (error) =>
            addAlert({
              text: getCollectionLangError(error),
              close: resetRemove,
            }),
        }
      ).catch(console.error);
    }
  }
}

const lang = new LocalizedStrings({
  en: {
    disableRemove: "You don't have the permission to remove content from this collection",
  },
});
