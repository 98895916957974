import React, { PropsWithChildren } from 'react';
import { stylesheet } from 'typestyle';

interface Props {
  title: string
}

export function LoginSigninLabel({
  title,
}: PropsWithChildren<Props>) {
  return (
    <div className={sheet.signinButton}>
      {title}
    </div>
  );
}

const sheet = stylesheet({
  signinButton: {
    width: '100%',
    textAlign: 'center',
    fontSize: 25,
    paddingBottom: 10,
  },
});
