import React, { Component, ReactNode } from 'react';
import { PerfectScrollbar } from 'components/PerfectScrollbar';
import { style, classes } from 'typestyle';
import { ADALONG_COLORS } from '@src/styles';

interface IProps {
  children?: ReactNode;
  title: string;
  display: boolean;
  maxHeight?: number;
  onDisplay: (display: boolean) => any;
}

export class DropDown extends Component<IProps, any> {
  constructor(props: IProps) {
    super(props);
  }

  public onTitleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    this.props.onDisplay(!this.props.display);
  }

  public renderTitle() {
    return (
      <div>
        <div className='title' onClick={this.onTitleClick.bind(this)}>
          {this.props.title}
        </div>
        <div className={classes('arrow', arrowClass)} onClick={this.onTitleClick.bind(this)}>
          <i className='material-icons notranslate'>
            {this.props.display ? 'keyboard_arrow_up' : 'keyboard_arrow_down'}
          </i>
        </div>
      </div>
    );
  }

  public render() {
    return (
      <div className={classes('drop-down', dropDownClass)} onClick={(e) => e.stopPropagation()}>
        {this.renderTitle()}
        {this.props.display && (
          <div className={classes('drop-down-frame', dropDownFrameClass)}>
            {this.renderTitle()}
            <PerfectScrollbar
              style={{ maxHeight: this.props.maxHeight || 'default' }}
              className={classes('content', contentClass)}
            >
              {this.props.children}
            </PerfectScrollbar>
          </div>
        )}
      </div>
    );
  }
}

const dropDownClass = style({
  position: 'relative',
  backgroundColor: ADALONG_COLORS.DARKER_GRAY,
  borderRadius: 5,
  $nest: {
    '> div > .title, .drop-down-frame > div > .title': {
      padding: '10px',
      cursor: 'pointer',
    },
  },
});

const dropDownFrameClass = style({
  position: 'absolute',
  top: 0,
  left: 0,
  width: '100%',
  backgroundColor: 'inherit',
  borderRadius: 5,
  zIndex: 4,
});

const arrowClass = style({
  position: 'absolute',
  right: 11,
  top: 7,
  zIndex: 3,
  cursor: 'pointer',
  $nest: {
    i: {
      fontSize: 21,
    },
  },
});

const contentClass = style({
  position: 'relative',
  padding: '8px 16px',
});
