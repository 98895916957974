import { PRODUCT_ACTION_CREATORS } from '@src/actions/product';
import { ArrayHelper } from '@src/services/helpers/array';
import { ProductState } from '@src/types/redux/product';
import { Product } from '@adalong/schemas';
import { REDUX_ACTIONS } from '../actions/_types';

export const initialState: ProductState = {
  products: {},
};

const reducers = {
  [REDUX_ACTIONS.PRODUCT.SET_PRODUCTS]: (
    state: ProductState,
    action: ReturnType<typeof PRODUCT_ACTION_CREATORS['setProducts']>,
  ): ProductState => {
    const products = ArrayHelper.mapToObject(action.products, (p) => p.id);
    window.analytics?.trackEvent(action.type, 'ReduxProduct');
    return {
      ...state,
      products: {
        ...state.products,
        ...products,
      },
    };
  },
};

export const product = (state: ProductState = initialState, action: {
  type: string
  products: Product[]
}) => {
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
};
