import React, { Component } from 'react';
import { connect } from 'react-redux';
import { TableList, ListColumn } from '@src/components/TableList';
import DeleteIcon from '@mui/icons-material/Delete';
import { VideoMakerView } from 'common';
import { ICombinedReducers } from '@src/types/redux';

const mapStateToProps = (state: ICombinedReducers) => ({
  gif: state.video.gif,
  windowHeight: state.common.windowSize.height,
  page: state.video.page,
});

const dispatchToProps = {};

type Props = ReturnType<typeof mapStateToProps> & Readonly<typeof dispatchToProps> & {
  videoMakers: VideoMakerView[]
  onRemove: (vm: VideoMakerView) => any
  loading: boolean
  onOpen: (vm: VideoMakerView) => void
};

class BaseVideoMakerList extends Component<Props> {
  public render() {
    // Name colname desc creator
    const columns: ListColumn<VideoMakerView>[] = [
      {
        label: 'name',
        onClick: (input: VideoMakerView) => this.props.onOpen(input),
        displayName: (input: VideoMakerView) => input.name,
      }, {
        label: 'description',
        onClick: (input: VideoMakerView) => this.props.onOpen(input),
        displayName: (input: VideoMakerView) => input.description,
        minWidth: '22vw',
      }, {
        displayName: (input: VideoMakerView) => <DeleteIcon onClick={() => this.props.onRemove(input)} />,
        label: 'delete',
      },
    ];

    return <TableList list={this.props.videoMakers} columns={columns} emptyMessage="No saved videos. Please create a new one" loading={this.props.loading} />;
  }
}

export const VideoMakerList = connect(mapStateToProps, dispatchToProps)(BaseVideoMakerList);
