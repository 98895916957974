import React, { PropsWithChildren } from 'react';

import { stylesheet } from 'typestyle';
import { Row } from '../../Utils';

interface Props {
  title: string | JSX.Element;
  /**
   * Element to put at the right of the title,
   */
  rightSide?: JSX.Element;
  size?: number;
  titleUpperCase?: boolean;
}

/**
 * Line component for forms
 * Use it to link title and field
 */
export function FormLine({
  title,
  rightSide,
  children,
  size,
  titleUpperCase = true,
  ...props
}: PropsWithChildren<Props> & React.HTMLAttributes<HTMLDivElement>) {
  return (
    <div className={sheet.line} {...props}>
      <div
        className={sheet.title}
        style={{
          fontSize: size,
          textTransform: titleUpperCase ? 'uppercase' : undefined,
        }}
      >
        <Row className={sheet.titleRow}>
          {title}
          {rightSide}
        </Row>
      </div>
      <div className={sheet.field} style={{ fontSize: 15 }}>
        {children}
      </div>
    </div>
  );
}

const sheet = stylesheet({
  title: {
    fontWeight: 600,
  },
  titleRow: {
    justifyContent: 'space-between',
  },
  line: {
    position: 'relative',
    margin: '10px 0',
    $nest: {
      '.disabled-line:before': {
        content: "''",
        display: 'block',
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        zIndex: 5,
      },
      '.disabled-line': {
        color: '#737373',
      },
    },
  },
  field: {
    wordBreak: 'break-all',
    color: '#cccccc',
    marginTop: 10,
    $nest: {
      input: {
        paddingTop: 0,
      },
      textarea: {
        resize: 'none',
      },
    },
  },
});
