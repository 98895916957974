import { MediaFacet, ResGetMedia } from 'common';
import { RefObject } from 'react';
import { SvgIconComponent } from '@mui/icons-material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';
import { MarkDef } from './Mark';

export const SEARCH_MARK_TYPES = ['text', 'hashtags', 'labels'] as const; // Also define the order of appearance
export type SearchMarkTypes = typeof SEARCH_MARK_TYPES[number];

export enum KeyCode {
  ArrowDown,
  ArrowUp,
  ArrowLeft,
  ArrowRight,
  Enter,
  Escape,
}

export interface SearchState {
  input: string
  setInput: React.Dispatch<React.SetStateAction<string>>
  isLoading: boolean
  result: ResGetMedia | undefined
}

export type SearchMarkDef = MarkDef<SearchMarkTypes>;

export type SuggestionsDef = {
  [key in SearchMarkTypes]: SearchMarkDef[]
};

export type SelectedDef = {
  [key in SearchMarkTypes]?: string[]
};

export type SearchTheme = {
  [key in SearchMarkTypes]: {
    title: string
    symbol: SvgIconComponent | OverridableComponent<SvgIconTypeMap>
  }
};

export interface SearchElementRef {
  text: RefObject<HTMLInputElement>
  bar: RefObject<HTMLDivElement>
  suggestions: RefObject<HTMLDivElement>
}

export interface SearchEventHandlers {
  onText(val: string): void
  onInputFocus(): void
  onCloseBar(): void
  onCloseMark(mark: SearchMarkDef): void
  onClickSuggestion(mark: SearchMarkDef): void
  onSuggestionOver(mark: SearchMarkDef): void
}

export interface SearchUtilityInterface {
  parseExclusion(val: string): [string, boolean]
  addExclusionChar(val: string): string
  getExclusionList(vals: string[]): string[]
  getInclusionList(vals: string[]): string[]
  removeAlreadySelectedFacets(facets: SearchBarSuggestions, selectedFacets: SearchBarSuggestions): SearchBarSuggestions
}

export interface SearchManagerInterface extends SearchEventHandlers {
  utils: SearchUtilityInterface

  getSelected(): SearchMarkDef[]
}

export interface SearchApiConnector {
  search(val: string, facets: MediaFacet[]): Promise<Pick<ResGetMedia, 'facets' | 'total'> | void>
  reload(): void
}
export interface SearchStore {
  add(key: SearchMarkTypes, val: string): void
  remove(key: SearchMarkTypes, val: string): void
  get(key: SearchMarkTypes): string[]
}

export type SearchBarSuggestions = {
  [key in keyof Pick<NonNullable<ResGetMedia['facets']>, 'labels' | 'hashtags'>]?: string[]
};
