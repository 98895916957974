import React, { Component } from 'react';

interface IProps {
  match: {
    params: {
      status: string
    }
  }
}

interface IState {

}

export class Oauth extends Component <IProps, IState> {
  public render() {
    const { status } = this.props.match.params;
    return (
      <div className="container text-center oauth-page">
        <div className="row-fluid">
          <h1>{window.T(`oauth.${status}_title`)}</h1>
        </div>
        <div className="row-fluid">
          <p>{window.T(`oauth.${status}_desc`)}</p>
        </div>
        <div className="row-fluid">
          {/* <button type="button" className="button button-blue"
									onclick="window.open('', '_self', ''); window.close();">Close this page</button> */}
        </div>
      </div>
    );
  }
}
