export class StringHelper {
  /**
     * Return the given string with the first character in uppercase
     */
  public static upperFirst(str = ''): string {
    str = String(str);
    if (str.length < 1) {
      return '';
    }
    return str[0].toUpperCase() + str.slice(1);
  }

  public static removeDiacritics(str: string) {
    return str.normalize('NFD').replace(/[\u0300-\u036f]/g, '');
  }
}
