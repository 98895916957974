import { Point } from './Point';

export class Rectangle {
  private origin: Point;

  public constructor(public x: number, public y: number, public width: number, public height: number, public rot = 0) {
    this.rot = rot;
    this.origin = new Point(x + width * 0.5, y + height * 0.5);
  }

  public static fromObject(obj: any): Rectangle | undefined {
    let rect: Rectangle | undefined;
    try {
      rect = new Rectangle(obj.x, obj.y, obj.width, obj.height, obj.rot);
    } catch (e) {}

    return rect;
  }

  public containsPoint(point: Point): boolean {
    const tempPoint = point.substract(this.origin).rotate(this.rot);
    return tempPoint.x >= -this.width * 0.5 && tempPoint.x <= this.width * 0.5 && tempPoint.y >= -this.height * 0.5 && tempPoint.y <= this.height * 0.5;
  }

  public getPoints(): Point[] {
    const points: Point[] = [];
    const halfDiag = new Point(this.width / 2, this.height / 2);
    points.push(this.origin.add(halfDiag.rotate(this.rot)));
    points.push(this.origin.add(halfDiag.scale(-1, 1).rotate(this.rot)));
    points.push(this.origin.add(halfDiag.scale(1, -1).rotate(this.rot)));
    points.push(this.origin.add(halfDiag.scale(-1, -1).rotate(this.rot)));

    return points;
  }
}
