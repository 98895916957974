import React, { FC, useEffect, useState } from 'react';
import { Box, Tabs, Tab, Grid as MuiGrid } from '@mui/material';
import { DataGrid, GridColDef } from '@mui/x-data-grid';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import dayjs from 'dayjs';
import {
  EnrichedRightRequest,
  EnrichedRightRequestMetadata,
  RemoteConversationView, StoredConversationView
} from 'common';

type ConversationsGridProps = {
  storedConversations: StoredConversationView[] | undefined;
  isLoadingStoredConvs: boolean;
  remoteConversations: RemoteConversationView[] | undefined;
  isLoadingRemoteConvs: boolean;
  onConversationChange?: (targetId: string, interlocutorId: string, conversationId: string) => void;
};

export const ConversationsGrid: FC<ConversationsGridProps> = (
  {
    storedConversations,
    isLoadingStoredConvs,
    remoteConversations,
    isLoadingRemoteConvs,
    onConversationChange,
  }) => {
  const darkTheme = createTheme({
    palette: {
      mode: 'dark',
    },
  });

  const [rowsStoredConversations, setRowsStoredConversations] = useState<any[]>([]);
  const [rowsRemoteConversations, setRowsRemoteConversations] = useState<any[]>([]);
  const [activeTab, setActiveTab] = useState(0);

  const handleTabChange = (event: React.SyntheticEvent, newValue: number) => {
    setActiveTab(newValue);
  };

  const findMostFrequentLang = (enrichedRightRequests: EnrichedRightRequest[] = []): string => {
    const langCounts = enrichedRightRequests.flatMap(request => request.medias?.map(media => media.lang) ?? [])
      .reduce<Record<string, number>>((acc, lang) => {
        if (typeof lang === 'string') {
          acc[lang] = (acc[lang] || 0) + 1;
        }
        return acc;
      }, {});

    return Object.keys(langCounts).reduce((a, b) => langCounts[a] > langCounts[b] ? a : b, 'N/A');
  };

  const countMediasInDiffusion = (enrichedRightRequests: EnrichedRightRequest[] = []): number => {
    return enrichedRightRequests.reduce((acc, request) => acc + (request.medias?.filter(media => media.diffusion).length ?? 0), 0);
  };

  const renderUGCCell = (params: { row: { enrichedRightRequests: EnrichedRightRequestMetadata[] | undefined } }) => {
    const thumbnails = params.row.enrichedRightRequests?.flatMap(data => data.medias?.map(media => media.url.thumbnail) ?? [])
      .slice(0, 3);

    return (
      <Box sx={{ display: 'flex', alignItems: 'center', justifyContent: 'flex-start', height: '100%', overflow: 'hidden' }}>
        {thumbnails?.length ? (
          thumbnails.map((thumbnail, index) => (
            <Box
              key={index}
              component="img"
              sx={{ maxHeight: 30, maxWidth: '100%', marginRight: 0.5, '&:last-child': { marginRight: 0 } }}
              src={thumbnail}
              alt={`UGC Thumbnail ${index + 1}`}
            />
          ))
        ) : (
          <Box>N/A</Box>
        )}
      </Box>
    );
  };

  const renderIPRCell = (params: { row: { enrichedRightRequests: EnrichedRightRequestMetadata[] | undefined } }) => {
    const hasAgreedStatus = params.row.enrichedRightRequests?.some(
      request => request.rightRequest.status === 'agreed'
    ) ?? false;

    return hasAgreedStatus ? '✅' : 'No';
  };

  const updateRowsStoredConversations = () => {
    const flattenedStoredConversations = storedConversations?.flatMap(data => data.conversations) || [];

    const rows = flattenedStoredConversations.map(data => ({
      id: data.conversationId,
      date: 'Fetching from Meta...', // Placeholder before remote data is loaded
      username: data.interlocutorUsername,
      targetId: data.targetAccountId,
      interlocutorId: data.interlocutorId,
      enrichedRightRequests: data.rightRequests,
      lang: findMostFrequentLang(data.rightRequests),
      diffusion: countMediasInDiffusion(data.rightRequests),
      type: 'DM',
      msg: data.rightRequests?.length ? 'IPR' : 'N/A',
      view: 'Fetching from Meta...',
    }));

    setRowsStoredConversations(rows);
  };

  const updateRowsWithRemoteData = () => {
    if (!remoteConversations) return;

    // Map remote conversations by their conversationId for easy lookup
    const remoteConversationMap = new Map(
      remoteConversations.flatMap(remote => remote.conversations.map(conv => [conv.id, conv]))
    );

    // Update stored conversations with the matching remote data
    const updatedRows = rowsStoredConversations.map(row => {
      const remoteConv = remoteConversationMap.get(row.id);
      if (remoteConv) {
        return {
          ...row,
          date: dayjs(remoteConv.lastActivityDate).format('DD/MM/YYYY HH:mm'), // Replace 'Fetching from Meta...' with real data
          view: remoteConv.lastMessage, // Replace 'Fetching from Meta...' with real message
        };
      }
      return row;
    });

    setRowsStoredConversations(updatedRows);
  };

  const updateRowsRemoteConversations = () => {
    const storedConversationIds = new Set(
      storedConversations?.flatMap(data => data.conversations.map(conv => conv.conversationId)) || []
    );

    const filteredRemoteConversations = remoteConversations?.flatMap(data => data.conversations)
      .filter(remoteConv => !storedConversationIds.has(remoteConv.id)) || []; // Filter out already stored conversations

    const rows = filteredRemoteConversations.map(data => ({
      id: data.id,
      date: dayjs(data.lastActivityDate).format('DD/MM/YYYY HH:mm'),
      username: data.conversationInterlocutor.name,
      targetId: data.conversationTarget.id,
      interlocutorId: data.conversationInterlocutor.id,
      type: 'DM',
      view: data.lastMessage,
    }));

    setRowsRemoteConversations(rows);
  };

  useEffect(() => {
    updateRowsStoredConversations(); // Initialize the stored conversations
  }, [storedConversations]);

  useEffect(() => {
    updateRowsRemoteConversations(); // Update remote conversations grid after filtering out stored conversations
    updateRowsWithRemoteData(); // Merge remote data with stored conversations
  }, [remoteConversations, isLoadingRemoteConvs]);

  const columnsStoredConversations: GridColDef[] = [
    { field: 'date', headerName: 'Last activity', width: 200, filterable: false, sortable: true },
    { field: 'username', headerName: 'Username', width: 200, filterable: false, sortable: true },
    { field: 'ugc', headerName: 'UGC', width: 200, renderCell: renderUGCCell, filterable: false, sortable: false },
    { field: 'lang', headerName: 'Language', width: 200, filterable: false, sortable: false },
    { field: 'type', headerName: 'Type', width: 100, filterable: true, sortable: false },
    { field: 'msg', headerName: 'Msg', width: 100, filterable: true, sortable: false },
    { field: 'ipr', headerName: 'IPR', width: 100, renderCell: renderIPRCell, filterable: true, sortable: false },
    { field: 'diffusion', headerName: 'Diffusion', width: 100, filterable: true, sortable: false },
    { field: 'view', headerName: 'View', width: 200, filterable: false, sortable: false },
  ];

  const columnsRemoteConversations: GridColDef[] = [
    { field: 'date', headerName: 'Last activity', width: 200, filterable: false, sortable: true },
    { field: 'username', headerName: 'Username', width: 200, filterable: false, sortable: true },
    { field: 'type', headerName: 'Type', width: 100, filterable: true, sortable: false },
    { field: 'view', headerName: 'View', width: 200, filterable: false, sortable: false },
  ];

  return (
    <ThemeProvider theme={darkTheme}>
      <Box sx={{ height: '100vh', width: '100%' }}>
        <Tabs
          value={activeTab}
          onChange={handleTabChange}
          centered
          TabIndicatorProps={{ style: { display: 'none' } }}
        >
          <Tab label="IPR Conversations" />
          <Tab label="Other Conversations" />
        </Tabs>

        {activeTab === 0 && (
          <MuiGrid container spacing={2} sx={{ height: 400, width: '100%' }}>
            <MuiGrid item xs={12}>
              <DataGrid
                rows={rowsStoredConversations}
                columns={columnsStoredConversations}
                loading={isLoadingStoredConvs}
                checkboxSelection
                disableRowSelectionOnClick
                onRowClick={(params) =>
                  onConversationChange?.(
                    params.row.targetId,
                    params.row.interlocutorId,
                    params.row.id
                  )
                }
              />
            </MuiGrid>
          </MuiGrid>
        )}

        {activeTab === 1 && (
          <MuiGrid container spacing={2} sx={{ height: 400, width: '100%' }}>
            <MuiGrid item xs={12}>
              <DataGrid
                rows={rowsRemoteConversations}
                columns={columnsRemoteConversations}
                loading={isLoadingRemoteConvs}
                checkboxSelection
                disableRowSelectionOnClick
                onRowClick={(params) =>
                  onConversationChange?.(
                    params.row.targetId,
                    params.row.interlocutorId,
                    params.row.id
                  )
                }
              />
            </MuiGrid>
          </MuiGrid>
        )}
      </Box>
    </ThemeProvider>
  );
};
