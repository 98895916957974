import Badge from '@mui/material/Badge';
import { ADALONG_COLORS } from '@src/styles';
import React from 'react';
import { stylesheet } from 'typestyle';

interface Props {
  total: number | undefined;
}

export function MediaCountBadge({ total }: Props) {
  return (
    <Badge
      style={{ position: 'absolute' }} // to override mui style
      className={sheet.badge}
      badgeContent={total}
      // unfortunately you cannot use a sentinel value like -1 to not have a max value ...
      max={Infinity}
    >
      <span />
    </Badge>
  );
}

const sheet = stylesheet({
  badge: {
    top: 10,
    right: 10,
    $nest: {
      '.MuiBadge-badge': {
        backgroundColor: ADALONG_COLORS.LIGHT_YELLOW,
        color: ADALONG_COLORS.DARK_BLACK,
      },
    },
  },
});
