import React from 'react';
import { style, classes } from 'typestyle';
import type { SvgIconComponent } from '@mui/icons-material';
import AlternateEmail from '@mui/icons-material/AlternateEmail';
import { HashSvgIcon } from '@src/components/SvgIcons/Hash';
import { SearchSuggestion, SourceSearchSuggestion } from '@src/types/Filters';
import { Row } from '@src/components/Utils';
import { NETWORK_ICONS } from '@src/config/media';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import ExtensionIcon from '@mui/icons-material/Extension';
import PortraitIcon from '@mui/icons-material/Portrait';
import { SvgIconTypeMap } from '@mui/material';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SourceType } from '../../utils/SourceUtility';

interface Props {
  suggestion: SearchSuggestion<SourceSearchSuggestion>
  style?: {
    typeNameSpacing?: number
  }
  classNames?: string[]
}

const TYPE_ICONS: { [key in SourceType]: SvgIconComponent | OverridableComponent<SvgIconTypeMap> } = {
  hashtag: HashSvgIcon,
  mention: AlternateEmail,
  tagged: AccountCircleIcon,
  ownContent: PortraitIcon,
  extension: ExtensionIcon,
};

export function SourceItem({
  suggestion,
  style = {},
  classNames = [],
}: Props) {
  const NetworkIcon = NETWORK_ICONS[suggestion.network];
  const TypeIcon = TYPE_ICONS[suggestion.type];

  return (
    <Row
      className={classes(rowClass, ...classNames)}
    >
      <div>
        <span className={typeIconClass}>
          <TypeIcon fontSize="inherit" />
        </span>
        <span
          style={{
            marginLeft: style.typeNameSpacing || 0,
            marginRight: 5,
          }}
        >
          {suggestion.display}
        </span>
      </div>
      <div
        className={networkIconClass}
      >
        <NetworkIcon fontSize="inherit" />
      </div>
    </Row>
  );
}

const rowClass = style({
  justifyContent: 'space-between',
});

const typeIconClass = style({
  minWidth: 18,
  display: 'inline-block',
});

const networkIconClass = style({
  marginTop: -4,
  fontSize: 16,
  height: 0,
});
