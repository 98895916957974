import { style } from 'typestyle';

export const tabContentClass = style({
  overflow: 'hidden',
  height: '100%',
  position: 'relative',
});

export const contentFrameClass = style({
  padding: '21px 22px 20px 22px',
});
