import React from 'react';
import { FiltersUtility } from '@src/fragments/Filters/managers/FiltersUtility';
import { UrlDate } from '@src/types/Filters';
import { lang } from '../../DateFilter';

interface Props {
  date: UrlDate
}

export function DateItem({ date }: Props) {
  if (FiltersUtility.parseCustomDate(date)) {
    const [startDate, endDate] = FiltersUtility.urlDateToRange(date) || [];
    return (
      <>
        {`${FiltersUtility.displayableDate(startDate)} - ${FiltersUtility.displayableDate(endDate)}`}
      </>
    );
  }

  return (
    <>
      {date in lang.buttons ? lang.buttons[date as keyof typeof lang.buttons] : null}
    </>
  );
}
