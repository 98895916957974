import React, { PropsWithChildren } from 'react';
import { style } from 'typestyle';

export const SubSectionContent = ({ children }: PropsWithChildren<{}>) => {
  return <div className={subSectionClass}>{children}</div>;
};

const subSectionClass = style({
  paddingLeft: 10,
});
