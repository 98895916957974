import React, { Component } from 'react';

import { classes, stylesheet } from 'typestyle';
import { OpenSettings } from '../OpenSettings';

interface IProps {
  text: string
}

interface IState {

}
export class MissingPermission extends Component<IProps, IState> {
  public render() {
    return (
      <div className={classes(sheet.missingPermission, 'alert', 'alert-warning')}>
        <i className={classes('material-icons', 'notranslate', sheet.icon)}>warning</i>
        {this.props.text}
        <OpenSettings />
      </div>
    );
  }
}

const sheet = stylesheet({
    missingPermission: {
        background: 'transparent',
        border: '1px solid',
        borderRadius: 3,
        color: '#f3b541',
        marginBottom: 20,
    },
    icon: {
        fontSize: 18,
        verticalAlign: 'middle',
        marginRight: 13,
    },
});
