import React from 'react';
import { OverlayContainer } from '@src/components/OverlayContainer';
import { FormTitle } from '@src/components/Form/FormTitle';
import LocalizedStrings from 'react-localization';
import { FormButtons } from '@src/components/Form/FormButtons';
import { FormLineSeparator } from '@src/components/Form/FormLineSeparator';
import { ActionButton } from '@src/components/ActionButton';
import { classes, stylesheet } from 'typestyle';

import { DownloadWarningText } from '@src/components/DownloadWarning/DownloadWarning';
import { WarningIcon } from '@src/components/WarningIcon';
import { ADALONG_COLORS } from '@src/styles';
import CheckIcon from '@mui/icons-material/Check';
import { Loader } from '@src/components/Loader';
import { Row } from '@src/components/Utils';
import { Alert, AlertTitle } from '@mui/material';

interface Props {
  downloadable: number;
  total: number;
  displayWarning: {
    availability: string;
    rights: boolean;
  };
  isLoading: boolean;
  handleDownload(): void;
  handleClose(): void;
}

/**
 * Display a popin that prompt the user to download x content.
 * It will show the number of downloadable content and warning messages if necessary.
 */
export function MediaDownload(props: Props) {
  const { total, downloadable } = props;
  // all contents are downloadable
  const fullDownload = downloadable >= total;
  const warning = props.displayWarning.availability || props.displayWarning.rights;

  let alertToDisplay: React.ReactNode | undefined;
  if (props.isLoading) {
    alertToDisplay = <AlertLoader />;
  } else if (warning) {
    alertToDisplay = warningDownloadAlert();
  } else {
    alertToDisplay = okDownloadAlert();
  }

  return (
    <OverlayContainer frameClasses={[sheet.container]} onClose={props.handleClose}>
      <FormTitle value={lang.title} />
      <FormLineSeparator />
      {alertToDisplay}
      <FormButtons>
        <ActionButton variant='transparent' onAction={props.handleClose} className={sheet.button}>
          {lang.cancel}
        </ActionButton>
        <ActionButton variant='blue' onAction={props.handleDownload} className={sheet.button}>
          {lang.download}
        </ActionButton>
      </FormButtons>
    </OverlayContainer>
  );

  function warningDownloadAlert() {
    return (
      <Alert
        className={sheet.alert}
        variant='filled'
        severity='warning'
        icon={<WarningIcon className={sheet.alertIcon} fontSize='inherit' />}
      >
        {alertTitle()}
        <DownloadWarningText
          availability={props.displayWarning.availability}
          rights={props.displayWarning.rights}
        />
      </Alert>
    );
  }

  /**
   * Alert when everything is ok
   */
  function okDownloadAlert() {
    return (
      <Alert
        className={classes(sheet.alert, sheet.alertOk)}
        variant='filled'
        severity='info'
        icon={
          <CheckIcon className={classes(sheet.alertIcon, sheet.alertFullIcon)} fontSize='inherit' />
        }
        // If we really want to use an emoji, uncomment this line
        // icon={'😎'}
      >
        {alertTitle()}
        {/* Simply display instagram warning as a reminder */}
        <span className={sheet.reminder}>Reminder: </span>
        <DownloadWarningText availability={props.displayWarning.availability} rights={false} />
      </Alert>
    );
  }

  function alertTitle() {
    if (fullDownload) {
      return <AlertTitle>{lang.formatString(lang.alertTitle.full, { total })}</AlertTitle>;
    }
    // partial
    return (
      <AlertTitle>{lang.formatString(lang.alertTitle.partial, { total, downloadable })}</AlertTitle>
    );
  }
}

function AlertLoader() {
  return (
    <Row className={sheet.loader}>
      <Loader />
    </Row>
  );
}

const lang = new LocalizedStrings({
  en: {
    title: 'Download Content',
    cancel: 'Cancel',
    download: 'download',
    alertTitle: {
      partial: 'Only {downloadable} of {total} selected contents can be downloaded.',
      full: '{total} selected contents can be downloaded.',
    },
  },
});

const sheet = stylesheet({
  container: {
    maxWidth: 470,
  },
  button: {
    $nest: {
      '&:not(:last-of-type)': {
        marginRight: 25,
      },
    },
  },
  alert: {
    marginBottom: 22,
    $nest: {
      '.MuiAlert-icon': {
        justifyContent: 'center',
        flexDirection: 'column',
      },
      '.MuiAlert-message': {
        color: ADALONG_COLORS.DARK_BLACK,
        fontSize: 13,
        lineHeight: 1.2,
      },
      '.MuiAlertTitle-root': {
        fontWeight: 'bold',
        fontSize: 14,
      },
    },
  },
  alertIcon: {
    color: ADALONG_COLORS.LIGHT_WHITE,
  },
  // style the ok download alert
  alertOk: {
    $nest: {
      '&.MuiAlert-filledInfo': {
        backgroundColor: ADALONG_COLORS.LIGHT_WHITE,
      },
    },
  },
  alertFullIcon: {
    color: ADALONG_COLORS.GREEN,
  },
  reminder: {
    color: 'orange',
  },
  loader: {
    justifyContent: 'center',
    marginBottom: 30,
  },
});
