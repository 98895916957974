import React, { useState } from 'react';
import { MediaView } from 'common';
import { MediaLibrary } from '@src/fragments/MediaLibrary';
import { style, classes } from 'typestyle';
import { BulkMediaActions } from '@src/components/BulkMediaActions';
import { Hide } from '@src/components/Hide';
import { GetRightsButton } from '@src/components/IPRights/GetRightsButton';
import { Browsing } from '@src/types/Browsing';
import { goToGetRights } from '../../utils/mediaRoute';
import { tabContentClass } from './styles';

interface Props {
  similars: MediaView[] | undefined
  isLoading: boolean
  services: {
    browsing: Browsing
  }
  renderMediaActionButton: (mediaList: MediaView[]) => React.ReactNode
}

export const SimilarMediaTab = ({
  similars,
  isLoading,
  services,
  renderMediaActionButton,
}: Props) => {
  const [selected, setSelection] = useState<MediaView[]>([]);

  return (
    <div className={classes(tabContentClass, padderClass)}>
      <div className={multiSelectClass}>
        <BulkMediaActions
          count={selected.length}
          onClear={() => setSelection([])}
          mediaActionButton={renderMediaActionButton(selected)}
        />
      </div>
      <MediaLibrary
        mediaList={similars || []}
        loadMoreMedia={async () => null}
        noMore
        onImageError={() => null}
        hideOnImageFailed
        selection={selected}
        setSelection={setSelection}
        libraryLoaded={!isLoading}
        renderMediaActionButton={(media: MediaView) => renderMediaActionButton([media])}
        renderShortcutActionButton={(media, failed) => (
          <Hide hide={failed || media.rightsRequestStatus === 'agreed'}>
            <GetRightsButton
              onAction={() => services.browsing.goToPage(goToGetRights(window.location, media._id))}
              size="small"
            />
          </Hide>
        )}
      />
    </div>
  );
};

const multiSelectClass = style({
  position: 'relative',
  display: 'block',
  width: '100%',
  height: 36,
  paddingLeft: 5,
  paddingBottom: 5,
});

const padderClass = style({
  padding: 16,
  paddingRight: 7,
});
