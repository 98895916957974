import React, { Component, ReactElement } from 'react';
import { style } from 'typestyle';
import { SearchMarkTypes } from '@src/types/Search';
import { Mark } from '../../components/Mark';

const COLOR = '#484848';
const EXCLUSION_COLOR = '#ec4838';

interface Props {
  children: ReactElement<Mark<SearchMarkTypes>>
  onClose: () => any
  isExclusion: boolean
}

export class ActiveMark extends Component<Props> {
  public render() {
    const { children, onClose, isExclusion } = this.props;
    return (
      <div className={activeMarkClass}>
        <div className={containerClass} data-exclusion={isExclusion}>
          {children}
          <div
            onClick={() => onClose && onClose()}
            className={deleteClass}
            data-exclusion={isExclusion}
          >
            <i className="material-icons notranslate">close</i>
          </div>
        </div>
      </div>
    );
  }
}

const activeMarkClass = style({
  display: 'inline-block',
  position: 'relative',
  padding: '2px 3px',
});

const containerClass = style({
  display: 'inline-flex',
  flexDirection: 'row',
  padding: '1px 7px 1px 11px',
  border: '1px solid',
  borderColor: COLOR,
  color: COLOR,
  borderRadius: 10,
  $nest: {
    '&[data-exclusion="true"]': {
      borderColor: EXCLUSION_COLOR,
      color: EXCLUSION_COLOR,
    },
  },
});

const deleteClass = style({
  flexShrink: 0,
  paddingLeft: 8,
  $nest: {
    i: {
      verticalAlign: 'middle',
      color: 'inherit',
      border: '1px solid currentColor',
      borderRadius: 50,
      fontSize: 12,
      padding: 2,
      cursor: 'pointer',
      $nest: {
        '&:hover': {
          backgroundColor: COLOR,
          color: 'white',
        },
      },
    },
    '&[data-exclusion="true"]': {
      $nest: {
        i: {
          $nest: {
            '&:hover': {
              backgroundColor: EXCLUSION_COLOR,
            },
          },
        },
      },
    },
  },
});
