import React, { Component } from 'react';
import { classes, style } from 'typestyle';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  error?: string | JSX.Element | null
  level?: 'error' | 'warning'
}

/**
 * Display an error if necessary
 */
export class FormError extends Component<Props> {
  public render() {
    const { error, level = 'error', ...props } = this.props;
    if (!error) {
      return null;
    }
    const levelClassName = { error: 'danger', warning: 'warning' }[level];
    return (
      <div
        className={classes('alert', `alert-${levelClassName}`, formErrorClass)}
        {...props}
      >
        {error}
      </div>
    );
  }
}

const formErrorClass = style({
  textAlign: 'center',
  marginBottom: 18,
});
