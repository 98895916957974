import React, { Component } from 'react';
import DeleteIcon from '@mui/icons-material/Delete';
import CopyIcon from '@mui/icons-material/FileCopy';
import { style } from 'typestyle';
import { WarningIcon } from '@src/components/WarningIcon';
import LocalizedStrings from 'react-localization';
import Tooltip from '@mui/material/Tooltip';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { SharePopupInfo } from '@src/types/redux/share';
import { VideoElementView } from 'common';
import { VideoState } from '@src/types/redux/video';
import { VideoMakerHandlers } from '@src/types/VideoMaker';
import { MediaType } from '@adalong/schemas';
import { AdalongStyles } from '../../../../../styles';
import { CollectionContentOverlay } from '../../LeftContainer/components/CollectionContentOverlay';
import List from './List';

interface Props {
  handlers: VideoMakerHandlers
  selectImage: (idx: number) => void
  gif_elements: VideoElementView[]
  current_index: number
  resolvedImages: VideoState['resolvedImages']
}

export class DndList extends Component<Props> {
  public render() {
    return (
      <List
        onInsert={this.props.handlers.onInsertItem.bind(this.props.handlers)}
        onMoveItem={this.props.handlers.onMoveItem.bind(this.props.handlers)}
        validInsertInfo={this.validInsertInfo}
      >
        {this.renderContent()}
      </List>
    );
  }

  public renderContent() {
    if (!this.props.gif_elements) {
      return null;
    }

    return this.props.gif_elements.map((content, idx) => {
      const url = content.brandcontent ? content.image_url : this.props.resolvedImages[String(content.image_url)]?.finalUrl;

      return (
        <div key={idx} className={itemClass}>
          {!url && (
          <Tooltip title={lang.warning}>
            <div className={warningClass}>
              <WarningIcon fontSize="small" />
            </div>
          </Tooltip>
          )}
          <div
            className={containerClass}
            data-selected={this.props.current_index === idx}
          >
            <div
              className={squareClass}
            >
              <div className={imgClass}>
                <img src={url || ''} loading="lazy" />
              </div>
              <CollectionContentOverlay
                onClick={() => this.props.selectImage(idx)}
                classToApply=""
                iconToApply=""
              >
                <CopyIcon
                  style={{ margin: 4, width: 19 }}
                  onClick={(e) => {
                    this.props.handlers.onDuplicateCurrentFrame(idx);
                    e.preventDefault();
                    e.stopPropagation();
                  }}
                />
                <DeleteIcon style={AdalongStyles.DND.delete} onClick={() => this.props.handlers.onRemoveItem(idx)} />
              </CollectionContentOverlay>
            </div>
          </div>
        </div>
      );
    });
  }

  public getContentInfo(element: VideoElementView) {
    const info: SharePopupInfo = {
      image: '',
      file: '',
      fileType: 'image',
      isInstaVideo: element.media?.source.name === 'instagram' && element.media.type === 'video',
    };

    if (!element) { return info; }

    if (element.media) {
      // IMedia
      const { media } = element;

      info.image = media.cdn_image;
      info.fileType = media.type === MediaType.Image ? 'image' : 'video';
      info.file = media._id;
    }
    if (element.brandcontent) {
      // IBrandContent
      const { brandcontent } = element;
      info.image = brandcontent.urls.thumbnail;
      info.file = brandcontent._id;
    }

    return info;
  }

  public validInsertInfo(info: any) {
    if (!info || !['share'].includes(info.origin)) {
      return false;
    }
    return true;
  }
}

const lang = new LocalizedStrings({
  en: {
    warning: 'This content is not available',
  },
});

const containerStyle: NestedCSSProperties = {
  position: 'absolute',
  top: 0,
  left: 0,
  bottom: 0,
  right: 0,
};

const itemClass = style({
  height: 0,
  width: '100%',
  paddingBottom: '100%',
  position: 'relative',
});

const squareClass = style({
  width: '100%',
  height: '100%',
});

const containerClass = style({
  ...containerStyle,
  $nest: {
    '&[data-selected="true"]': {
      border: 'solid 7px #3b6ece',
    },
  },
});

const imgClass = style({
  ...containerStyle,
  overflow: 'hidden',
  objectFit: 'cover',
  pointerEvents: 'none',
  zIndex: -1,
  $nest: {
    img: {
      maxWidth: '100%',
      maxHeight: '100%',
    },
  },
});

const warningClass = style({
  position: 'absolute',
  right: 7,
  top: 4,
  zIndex: 3,
});
