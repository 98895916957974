import React, { PropsWithChildren } from 'react';
import { style } from 'typestyle';

const MARGIN = 20;

interface Props {
  vScale?: number
  hScale?: number
}

export function ModalCloseButtonPosition({
  vScale = 1,
  hScale = 1,
  children,
}: PropsWithChildren<Props>) {
  const closeButtonClass = style({
    position: 'absolute',
    top: MARGIN * vScale,
    right: MARGIN * hScale,
  });
  return (
    <div className={closeButtonClass}>
      {children}
    </div>
  );
}
