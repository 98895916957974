import React from 'react';
import { CollectionView } from 'common';
import { SortBy } from '@src/pages/Collection/ListPage';
import { Droppable, DroppableProvided } from 'react-beautiful-dnd';
import CollectionsList from "@src/pages/Collection/List/CollectionsListContent";

interface Props  {
    category: string,
    collections: CollectionView[],
    isLoading: boolean,
    sortBy: SortBy,
}

export const CollectionDragDrop = ({ category, collections, isLoading, sortBy }: Props) => {

    return (
        <Droppable key={category} droppableId={category} direction="horizontal">
            { (provided: DroppableProvided) => (
                <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                >
                    <CollectionsList
                        isLoading={isLoading}
                        list={collections}
                        sortBy={sortBy}
                    />
                    {collections.length < 1 ? provided.placeholder : undefined}
                </div>
            )}
        </Droppable>
        )

}
