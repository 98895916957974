import { useState } from 'react';
import { useMutationWidget } from '@src/queries/widgets/useMutationWidget';
import { SettingsProps, SectionState } from '../types/Settings';
import { createChangeSettings } from '../utils/createChangeSettings';
import {DEPRECATED_QUERY_KEYS, queryClient} from "@src/query";

export const useSettings = ({ remoteWidget, editedWidget, setEditedWidget, setCurrentLocalization }: SettingsProps) => {
  const [sectionOpened, setSectionOpened] = useState<SectionState>({
    info: true,
    sources: false,
    localization: false,
    contentFilters: false,
    layout: false,
    thumbnailDesign: false,
    shopThisLook: false,
    productDisplay: false,
  });

  const { mutateAsync: mutateWidget, error: savingError, isLoading: saving } = useMutationWidget();

  const changeSettings = createChangeSettings(editedWidget, setEditedWidget);

  const onSaveClick = async () => {
    if (!remoteWidget._id) return;

    try {
      const update = {
        ...editedWidget,
        settings: {
          ...editedWidget.settings,
          type: editedWidget.settings.type || 'wall',
          content_size: editedWidget.settings.content_size || 0,
          min_media: editedWidget.settings.min_media || 1,
          max_media: editedWidget.settings.max_media || null,
          has_exact_products: editedWidget.settings.has_exact_products || false,
          has_similar_products: editedWidget.settings.has_similar_products || false,
          min_exact_products: editedWidget.settings.min_exact_products || 0,
          max_exact_products: editedWidget.settings.max_exact_products_displayed || null,
          min_similar_products: editedWidget.settings.min_similar_products || 0,
          max_similar_products: editedWidget.settings.max_similar_products_displayed || null,
          diffusion: editedWidget.settings.diffusion || false,
        },
      };

      const updatedWidget = await mutateWidget({
        id: remoteWidget._id,
        widget: update,
      });

      if (updatedWidget) {
        changeSettings(updatedWidget.settings);
      }

      // Invalidate the query to update the widget
      queryClient.invalidateQueries(DEPRECATED_QUERY_KEYS.WIDGET_LOCALIZED);
    } catch (error) {
      // Error is handled by mutation hook
      console.error('Error saving widget:', error);
    }
  };

  const onResetClick = () => {
    setEditedWidget({
      name: remoteWidget.name || '',
      description: remoteWidget.description || '',
      settings: remoteWidget.settings,
    });
  };

  return {
    sectionOpened,
    setSectionOpened,
    changeSettings,
    onSaveClick,
    onResetClick,
    error: savingError,
    isLoading: saving,
    disableSubmit: editedWidget.name.length < 1,
  };
};
