import React from 'react';
import Badge, { BadgeProps } from '@mui/material/Badge';

import Tooltip from '@mui/material/Tooltip';
import { stylesheet, classes } from 'typestyle';
import { ADALONG_COLORS } from '@src/styles';
import { ShopSvgIcon } from '../SvgIcons/Shop';

interface Props {
  total: number
  className?: BadgeProps['className']
  title?: string
  onClick?: () => void
}

export function ProductsBadge({
  total,
  className,
  title,
  onClick,
}: Props) {
  if (total === 0) {
    return null;
  }
  return (
    <div
      className={classes(sheet.badgeContainer, className)}
      onClick={onClick}
    >
      <Tooltip
        title={title || false}
      >
        <Badge
          className={sheet.badge}
          badgeContent={total}
        >
          <ShopSvgIcon size={20} />
        </Badge>
      </Tooltip>
    </div>
  );
}

const sheet = stylesheet({
  badgeContainer: {
    cursor: 'pointer',
  },
  badge: {
    $nest: {
      '.MuiBadge-badge': {
        backgroundColor: ADALONG_COLORS.LIGHT_YELLOW,
        color: ADALONG_COLORS.DARK_BLACK,
      },
    },
  },
});
