import ReactGA from 'react-ga4';
import { NODE_ENV } from '@src/config';
import { UserView, IAdmin } from 'common';

export class GAnalytics {
  private user: UserView;
  private admin: boolean;

  constructor(private GA_KEY: string, user: UserView) {
    this.user = user;
    this.admin = user.email.endsWith('@adalong.com');

    this.init();
  }

  public init() {

    ReactGA.initialize(
      this.GA_KEY,
      {
        gtagOptions: {
          custom_map: {
            event_user_id: 'event_user_id',
            event_user_email: 'event_user_email',
            event_user_is_admin: 'event_user_is_admin',
            event_node_env: 'event_node_env',
          }
       },
    });
  }

  public trackEvent(action: string, category: string) {
    ReactGA.event(action, {
      event_category: category,
      event_user_id: this.user._id,
      event_user_email: this.user.email.replace(/\./g, '[dot]').replace(/@/g, '[at]'),
      event_user_is_admin: this.admin,
      event_node_env: NODE_ENV,
    });
  }
}
