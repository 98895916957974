import { useQuery, UseQueryOptions } from 'react-query';
import { DEPRECATED_QUERY_KEYS, queryClient } from '@src/query';
import { CollectionView } from 'common';
import { getCollections } from './api';

/**
 * Find collections on which the user has viewer rights
 */
export const useQueryCollections = (config?: UseQueryOptions<CollectionView[]>) => useQuery<CollectionView[]>([DEPRECATED_QUERY_KEYS.COLLECTIONS], queryCollections, config);

export const queryCollections = async () => {
  const collections = await getCollections();
  return collections.sort((a, b) => new Date(b.created_at).getTime() - new Date(a.created_at).getTime());
};

export const setQueryCollections = (collections: CollectionView[]) => {
  queryClient.setQueryData([DEPRECATED_QUERY_KEYS.COLLECTIONS], collections);
};
