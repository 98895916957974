import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { ADALONG_COLORS } from '@src/styles';
import { style } from 'typestyle';

interface Props {
  fill: string
}

/**
 * The exact linking icon is to be activated/deactivated by the user
 * and allows them to link a product that is an exact match to a given UGC.
 */

export function LinkExactButton({ fill }: Props) {
  return (
    <svg version='1.1' width='25' height='25' viewBox='0 0 1000 1000'>
      <defs></defs>
      <rect x='0' y='0' width='100%' height='100%' fill='rgba(0,0,0,0)' />
      <g transform='matrix(15.1515 0 0 15.1515 499.9995 499.9995)' id='101538'>
        <path
          fill={fill}
          style={{
            stroke: 'none',
            strokeWidth: 1,
            strokeDasharray: 'none',
            strokeLinecap: 'butt',
            strokeDashoffset: 0,
            strokeLinejoin: 'miter',
            strokeMiterlimit: 4,
            fillRule: 'nonzero',
            opacity: 1,
          }}
          vectorEffect='non-scaling-stroke'
          transform=' translate(-50, -50)'
          d='M 50 20 A 30 30 0 1 0 80 50 A 30 30 0 0 0 50 20 Z M 48.88 59.18 l -1.22 1.21 a 5.71 5.71 0 0 1 -8 0 a 5.63 5.63 0 0 1 0 -8 l 4.48 -4.45 c 2.79 -2.77 6 -3.16 8.15 -1 A 1.49 1.49 0 1 1 50.17 49 c -1.27 -1.26 -3 0.09 -4 1 L 41.73 54.5 a 2.66 2.66 0 0 0 0 3.77 a 2.73 2.73 0 0 0 3.83 0 l 1.22 -1.21 a 1.49 1.49 0 0 1 2.1 2.12 Z m 11.49 -11.4 l -4.78 4.74 a 6.57 6.57 0 0 1 -4.5 2.21 a 4.71 4.71 0 0 1 -3.35 -1.49 a 1.49 1.49 0 0 1 2.1 -2.12 c 0.44 0.43 1.46 1.45 3.65 -0.72 l 4.78 -4.75 a 2.65 2.65 0 0 0 0 -3.77 c -1 -0.95 -2.42 -1.4 -3.53 -0.3 l -1.52 1.51 A 1.49 1.49 0 1 1 51.12 41 l 1.52 -1.51 c 2.15 -2.14 5.4 -2 7.73 0.3 a 5.63 5.63 0 0 1 0 8 Z'
          stroke-linecap='round'
        />
      </g>
    </svg>
  );
}