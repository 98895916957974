import React from 'react';
import { OverlayContainer } from '@src/components/OverlayContainer';
import { PerfectScrollbar } from '@src/components/PerfectScrollbar';
import { MediaView } from 'common';
import LocalizedStrings from 'react-localization';
import { Hide } from '@src/components/Hide';
import { MissingSocial } from '@src/components/MissingSocial';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import penIcon from 'assets/img/icons/pen.svg';
import { Loader } from '@src/components/Loader';
import { ADALONG_COLORS } from '@src/styles';
import { stylesheet } from 'typestyle';
import { RightsTemplate } from '@src/queries/users/useQueryUserRightsTemplate';
import { ModalCloseButtonPosition } from '@src/components/Modals/CloseButtonPosition';
import { CloseButton } from '@src/components/CloseButton';
import { FormTitle } from '@src/components/Form/FormTitle';
import { FormLine } from '@src/components/Form/FormLine';
import { Input } from '@src/components/Input';
import { TextArea } from '@src/components/TextArea';
import { ActionButton } from '@src/components/ActionButton';
import { FormButtons } from '@src/components/Form/FormButtons';
import Alert from '@mui/material/Alert';
import { Box } from '@mui/material';

import { color } from 'csx';
import { styled } from '@mui/system';
import { FormLineSeparator } from '@src/components/Form/FormLineSeparator';

type ErrorType = keyof typeof lang.error;
export type ButtonTarget = 'hashtag' | 'terms' | 'creator';

interface Props {
  media?: MediaView;
  hashtag: string;
  comment: string;
  /**
   * Used to create a reference to the final comment
   */
  finalComment: string | undefined;
  missingSocial: boolean;
  rightsTemplate?: RightsTemplate;
  french: boolean;
  loading: boolean;
  confirmButtonVariant: 'send' | 'openPost' | 'done';
  textAreaRef: React.RefObject<HTMLTextAreaElement>;
  finalCommentRef: React.RefObject<HTMLTextAreaElement>;
  error?: {
    type?: string;
    data?: any;
  } | null;
  commentChar: {
    max: number;
    remaining: number;
  };
  handlers: {
    hashtagChange(val: string): void;
    commentChange(val: string): void;
    termsClick(): void;
    copyText(target: ButtonTarget): void;
    setFrench(french: boolean): void;
    openPostClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
    sendClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
    doneClick(e: React.MouseEvent<HTMLButtonElement, MouseEvent>): void;
    close(): void;
  };
}

export function GetRights({
  media,
  hashtag,
  comment,
  finalComment,
  missingSocial,
  rightsTemplate,
  french,
  loading,
  confirmButtonVariant,
  textAreaRef,
  finalCommentRef,
  error,
  commentChar,
  handlers,
}: Props) {
  const errorType = error?.type as ErrorType | undefined;
  return (
    <OverlayContainer frameClasses={[sheet.overlayFrame]} onClose={handlers.close}>
      <PerfectScrollbar
        className={sheet.scrollingFrame}
        options={{
          wheelSpeed: 0.3,
          minScrollbarLength: 30,
        }}
      >
        {!media || !rightsTemplate ? (
          <div className={sheet.loading}>
            <Loader />
          </div>
        ) : (
          <>
            <ModalCloseButtonPosition>
              <CloseButton onClick={handlers.close} />
            </ModalCloseButtonPosition>
            <FormTitle
              value={
                media.source.user_name
                  ? lang.formatString(lang.title, { username: media.source.user_name }).toString()
                  : lang.titleNoUsername
              }
            />
            <Hide hide={!missingSocial}>
              <MissingSocial
                text={
                  lang.formatString(lang.missingsocial, { social: media.source.name }) as string
                }
              />
            </Hide>
            <FormLineSeparator />
            <FormLine
              titleUpperCase={false}
              title={lang.hashtag}
              rightSide={
                <div>
                  <AddTextButton onClick={() => handlers.copyText('hashtag')}>
                    <AddIcon />
                    Hashtag
                  </AddTextButton>
                  <PenIcon />
                </div>
              }
            >
              <Input
                className={sheet.input}
                value={hashtag}
                onChange={(e) => handlers.hashtagChange(e.currentTarget.value)}
                placeholder={lang.hashtag_placeholder}
              />
            </FormLine>
            <FormLine
              titleUpperCase={false}
              title={lang.terms}
              rightSide={
                <>
                  <AddTextButton
                    onClick={() => handlers.copyText('terms')}
                    style={{ marginRight: '37.59px' }}
                  >
                    <AddIcon />
                    Terms
                  </AddTextButton>
                </>
              }
            >
              <div className={sheet.termsLink} onClick={handlers.termsClick}>
                <Input
                  className={sheet.input}
                  disabled
                  defaultValue={rightsTemplate?.terms}
                  placeholder={lang.terms_placeholder}
                />
              </div>
            </FormLine>
            <FormLine
              titleUpperCase={false}
              title={media.source.name === 'instagram' ? lang.comment_instagram : lang.comment}
              rightSide={
                <>
                  <div className={sheet.langSelector}>
                    <div
                      data-selected={!french}
                      onClick={() => handlers.setFrench(false)}
                      title={lang.english_selector}
                    >
                      EN
                    </div>
                    <div
                      data-selected={french}
                      onClick={() => handlers.setFrench(true)}
                      title={lang.french_selector}
                    >
                      FR
                    </div>
                  </div>
                  {media.source.user_name && (
                    <AddTextButton onClick={() => handlers.copyText('creator')}>
                      <AddIcon />
                      Mention Creator
                    </AddTextButton>
                  )}
                  <PenIcon />
                </>
              }
            >
              <div key={french ? 'fr' : 'en'}>
                <TextArea
                  name='comment'
                  ref={textAreaRef}
                  value={comment}
                  className={sheet.textarea}
                  onChange={(e) => handlers.commentChange(e.currentTarget.value)}
                  maxLength={commentChar.max}
                  placeholder={french ? lang.comment_placeholder_fr : lang.comment_placeholder_en}
                />
              </div>
              <div className={sheet.count} data-danger={commentChar.remaining < 0}>
                {commentChar.remaining}
              </div>
            </FormLine>

            <TextArea ref={finalCommentRef} value={finalComment} className={sheet.finalComment} />

            <div>
              <Hide hide={!error}>
                <Alert variant='filled' severity='error'>
                  {errorType && lang.formatString(lang.error[errorType], error?.data)}
                </Alert>
              </Hide>
            </div>
            <form>
              <Hide hide={!loading}>
                <Box m='auto'>
                  <Loader color={ADALONG_COLORS.LIGHT_WHITE} size={40} />
                </Box>
              </Hide>
              <FormButtons className={sheet.buttons}>
                {confirmButtonVariant === 'send' ? (
                  <ActionButton variant='blue' onAction={handlers.sendClick}>
                    {lang.send.toUpperCase()}
                  </ActionButton>
                ) : confirmButtonVariant === 'openPost' ? (
                  <ActionButton variant='blue' onAction={handlers.openPostClick}>
                    {lang.open_post.toUpperCase()}
                  </ActionButton>
                ) : (
                  <ActionButton variant='blue' onAction={handlers.doneClick}>
                    {lang.done.toUpperCase()}
                  </ActionButton>
                )}
              </FormButtons>
            </form>
          </>
        )}
      </PerfectScrollbar>
    </OverlayContainer>
  );
}

function PenIcon() {
  return <img className={sheet.penIcon} src={penIcon} />;
}

const AddTextButton = styled(Button)(({ theme }) => {
  const bgColor = '#464646';
  return {
    border: `2px solid ${bgColor}`,
    color: '#f8f9fa',
    backgroundColor: bgColor,
    fontFamily: '"Public Sans", sans-serif',
    padding: 4,
    fontSize: 11,
    letterSpacing: '0.7px',
    marginRight: 20,
    marginLeft: 10,
    display: 'inline-flex',
    '&:hover': {
      backgroundColor: color(bgColor).lighten(0.1).toHexString(),
    },
  };
});

const lang = new LocalizedStrings({
  en: {
    title: "Ask for @{username}'s Rights",
    titleNoUsername: 'Ask for Rights',
    desc: 'All changed will be saved for your next request',
    hashtag: 'Approval Hashtag',
    hashtag_placeholder: '#YesBrand',
    comment: 'Comment',
    comment_instagram: 'Comment to paste on Instagram',
    comment_placeholder_en: 'Write a personal and authentic message in english',
    comment_placeholder_fr: 'Write a personal and authentic message in french',
    terms: 'Terms & Conditions',
    terms_placeholder: 'Terms link not provided',
    preview: 'Copy / Paste',
    preview_desc: "Now you have to copy / paste your text in a comment to ask for user's rights.",
    open_post: 'Open post and paste your message',
    missingsocial: 'You must link your {social} account',
    bulkelements: 'requests to send',
    howitworks: 'How It Works',
    howitworks_text:
      'Once a user responds to your comment with the approval hashtag {hashtag} they are signing off to your terms and conditions!',
    cancel: 'Cancel',
    send: 'Send',
    done: 'Done',
    english_selector: 'Save an english version of your comment',
    french_selector: 'Save an french version of your comment',
    error: {
      maxlength: 'Your comment exceeds the maximum length',
      unknown: 'An error occured, please try later or contact us',
      undefined: 'An error occured, please try later or contact us',
      no_comment:
        "We can not find your comment. Have you correctly written the hashtag? We need it to handle the user's response.",
      auth: 'You cannot access to this resource',
      hashtag_length: 'Your hashtag must be at least {val} chars',
      comment_length: 'Your comment must be more consistent',
    },
  },
});

const sheet = stylesheet({
  loading: {
    textAlign: 'center',
  },
  overlayFrame: {
    width: 600,
    position: 'relative',
    padding: 0,
  },
  scrollingFrame: {
    padding: '32px 40px',
    height: '100%',
    position: 'relative',
  },
  count: {
    fontSize: 18,
    fontWeight: 600,
    textAlign: 'right',
    color: '#ffffff78',
    $nest: {
      '&[data-danger=true]': {
        color: '#ff3131',
      },
    },
  },
  input: {
    width: '100%',
  },
  langSelector: {
    display: 'inline-block',
    marginLeft: 24,
    fontSize: 10,
    $nest: {
      '& > div': {
        display: 'inline-block',
        marginLeft: 10,
        color: 'gray',
        cursor: 'pointer',
      },
      '& > div[data-selected=true]': {
        color: '#d0d0d0',
      },
    },
  },
  termsLink: {
    cursor: 'pointer',
    $nest: {
      '&:hover > input': {
        cursor: 'pointer',
        color: '#c5c5c5',
      },
      '& input': {
        color: 'darkgrey',
      },
    },
  },
  penIcon: {
    float: 'right',
    height: 16,
  },
  buttons: {
    justifyContent: 'center !important',
  },
  finalComment: {
    opacity: 0,
    height: 0,
    width: 0,
    position: 'absolute',
    zIndex: -1,
  },
  textarea: {
    minHeight: 90,
  },
});
