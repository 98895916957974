import React from 'react';
import { IconRender, InheritProps } from './../IconRender';

export function StoriesIcon(props: InheritProps) {
  return (
    <IconRender
      {...props}
      childrenRender={(color: string) => (
        <>
          <rect
            x='2'
            y='2'
            width='66'
            height='66'
            rx='8'
            fill='#343434'
            stroke={color}
            strokeWidth='4'
          />
          <path
            d='M24 20C24 17.2386 26.2386 15 29 15H40C42.7614 15 45 17.2386 45 20V49C45 51.7614 42.7614 54 40 54H29C26.2386 54 24 51.7614 24 49V20Z'
            fill='#D9D9D9'
          />
        </>
      )}
    />
  );
}
