import React, { Component, ReactElement } from 'react';
import { style } from 'typestyle';
import LocalizedStrings from 'react-localization';
import {
  SuggestionsDef, SearchTheme, SearchMarkDef, SearchEventHandlers, SEARCH_MARK_TYPES, SearchMarkTypes, SearchElementRef,
} from '@src/types/Search';
import { Mark } from '@src/components/Mark';
import { PerfectScrollbar } from '@src/components/PerfectScrollbar';
import { LinearLoader } from '@src/components/LinearLoader';
import { markEqual } from './utils/markEqual';

interface Props {
  suggestions: SuggestionsDef
  theme: SearchTheme
  handlers: SearchEventHandlers
  showLoader: boolean
  hightlightedSuggestion?: SearchMarkDef | null
  exclusion: boolean
  elementRefs: SearchElementRef
}

const EXCLUSION_SUGGEST_COLOR = '#9e4e4e';

export class Suggestions extends Component<Props> {
  private highlightedRef: HTMLDivElement | null = null;

  private lastScroll: number = 0;

  private lastAutomaticScroll: number = 0;

  public render() {
    const {
      suggestions, theme, elementRefs, showLoader,
    } = this.props;
    return (
      <div
        className={suggestionsClass}
        tabIndex={0}
        ref={elementRefs.suggestions}
      >
        <LinearLoader
          height={3}
          display={showLoader}
          classes={[loaderClass]}
        />
        {this.renderExtensibleCategories(suggestions, theme, suggestions.text[0]?.text)}
      </div>
    );
  }

  public componentDidUpdate() {
    this.ensureHightlightedIsVisible();
  }

  private renderCategory(title: string, entries: SearchMarkDef[], typedText?: string) {
    const markClasses = [markClass];
    if (this.props.exclusion) {
      markClasses.push(exclusionClass);
    }
    return (
      <div className={categoryClass} key={title}>
        <div className={suggestionTitleClass}>{title}</div>
        <PerfectScrollbar className={entriesContainerClass} onScroll={this.onScroll.bind(this)}>
          <div>
            {entries.map((e) => (
              this.renderContainerMark(e,
                <Mark
                  key={e.text}
                  classes={markClasses}
                  markDef={e}
                  highlight={typedText}
                  onClick={this.props.handlers.onClickSuggestion.bind(this.props.handlers)}
                  suggestColor={this.props.exclusion ? EXCLUSION_SUGGEST_COLOR : undefined}
                />)
            ))}
            {entries.length === 0 && (
            <div className={categoryEmptyClass}>{lang.formatString(lang.emptyCategory, { type: title })}</div>
            )}
          </div>
        </PerfectScrollbar>
      </div>
    );
  }

  private renderExtensibleCategories(suggestions: SuggestionsDef, theme: SearchTheme, highlight?: string) {
    return SEARCH_MARK_TYPES.map((k) => {
      const { title } = theme[k];
      return this.renderCategory(title, suggestions[k], highlight);
    });
  }

  private renderContainerMark(markDef: SearchMarkDef, mark: ReactElement<Mark<SearchMarkTypes>>) {
    const { hightlightedSuggestion } = this.props;
    const isHighlighted = hightlightedSuggestion ? markEqual(markDef, hightlightedSuggestion) : false;
    return (
      <div
        className={markContainerClass}
        key={mark.key?.toString()}
        data-hightlight={isHighlighted}
        ref={(ref) => (isHighlighted ? this.highlightedRef = ref : null)}
        onMouseOver={() => this.props.handlers.onSuggestionOver(markDef)}
      >
        {mark}
      </div>
    );
  }

  private ensureHightlightedIsVisible() {
    const elementPos = this.highlightedRef?.offsetTop;
    const elementSize = this.highlightedRef?.offsetHeight;
    const container = this.highlightedRef?.parentElement?.parentElement;
    const scrollTop = container?.scrollTop;
    const containerSize = container?.offsetHeight;

    if (Date.now() - this.lastScroll < 1000 || typeof elementPos !== 'number'
            || typeof elementSize !== 'number'
            || typeof scrollTop !== 'number'
            || typeof containerSize !== 'number'
    ) {
      return;
    }
    const hiddenBefore = elementPos - scrollTop < 0;
    const hiddenAfter = (elementPos + elementSize) - (scrollTop + containerSize) > 0;

    if (hiddenBefore) {
      container?.scrollTo({ top: elementPos });
    } else if (hiddenAfter) {
      container?.scrollTo({ top: elementPos - (containerSize - elementSize) });
    } else {
      return;
    }
    // Prevent onScroll event from being triggered
    this.lastAutomaticScroll = Date.now();
  }

  private onScroll() {
    if (Date.now() - this.lastAutomaticScroll < 500) {
      return;
    }
    // Prevent automatic scroll while scrolling with the mouse
    this.lastScroll = Date.now();
  }
}

const lang = new LocalizedStrings({
  en: {
    emptyCategory: 'No {type}',
  },
});

const suggestionsClass = style({
  position: 'absolute',
  display: 'flex',
  flexDirection: 'row',
  overflow: 'hidden',
  width: '100%',
  maxWidth: 600,
  padding: '15px 12px',
  backgroundColor: '#23202a',
  borderRadius: 7,
  border: '1px solid transparent',
  top: '100%',
  marginTop: 13,
  zIndex: 2,
  fontSize: 12,
  color: '#e0e0e0',
  outline: 'none',
  $nest: {
    '&:focus': {
      borderColor: '#1c3656',
    },
  },
});

const categoryClass = style({
  flex: 1,
  overflow: 'hidden',
  padding: '0 4px 0 0',
  position: 'relative',
});

const entriesContainerClass = style({
  maxHeight: 98,
  overflow: 'hidden',
  position: 'relative',
});

const categoryEmptyClass = style({
  color: '#464646',
  paddingLeft: 4,
});

const suggestionTitleClass = style({
  fontWeight: 'bold',
  color: 'white',
});
const markContainerClass = style({
  cursor: 'pointer',
  $nest: {
    '&[data-hightlight="true"]': {
      $nest: {
        '& *': {
          backgroundColor: '#0e199c',
        },
      },
    },
  },
});

const markClass = style({
  paddingLeft: 4,
  color: 'gray', // To set ellipsis color
  $nest: {
    '& > *': {
      color: 'white',
    },
  },
});

const exclusionClass = style({
  color: 'gray', // To set ellipsis color
  $nest: {
    '& > *': {
      color: '#ff6161',
    },
  },
});

const loaderClass = style({
  position: 'absolute',
  bottom: 0,
  left: 0,
  width: '100%',
});
