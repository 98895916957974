/* eslint-disable no-throw-literal */
/*
* Api between client and server
*/

import * as reduxHelpers from '@src/services/helpers/redux';
import {
  IBrandContent,
  OldApiTypes,
  ApiTypes,
  IMentionPage,
  MediaView,
  ResGetMedia,
  MediaQuery,
  MentionPageView,
  MetaPermission,
  MetadataTaggedFile,
} from 'common';
import { FBAdAccountResponse } from '@src/types/api';
import { InfoInsert } from '@src/types/VideoMaker';
import { IPRightsTemplate } from '@src/types/IPRights';
import { Pathname } from '@src/types/Browsing';
import { ApiHelper } from './helpers/api';
import { API_URL } from '../config';

export interface ApiError {
  message: string
  type?: string
  data?: any
  isServerError: boolean
  code: number
  kind: 'internal' | 'client'
  name: string
}

type ApiCallback<T> = (err: ApiError | undefined, res: T | undefined) => any;

export const api = {
  logout(): Promise<void> {
    return Call.post('/logout');
  },

  /** GET * */

  async pairCanva(canvaID: string, state: string) {
    const data = { canvaID, state };
    return Call.post<{ url: string }, typeof data>('/api/canva/pair', data);
  },

  async getMedia(mediaId: string): Promise<MediaView> {
    const query: MediaQuery = {
      filter: {
        ids: [mediaId],
      },
    };
    const res = await this.getMedias(query);
    const [media] = res.media;
    return media;
  },
  getMedias(params: MediaQuery): Promise<ResGetMedia> {
    const request = {
      stringifiedQuery: JSON.stringify(params),
    };
    return Call.get('/api/media/', request);
  },
  async getCollections(): Promise<ApiTypes.ResGetCollections> {
    const res = await Call.get<ApiTypes.ResGetCollections>('/api/collection/all');
    return res || [];
  },
  getCollection(collectionId: string, token?: string): Promise<ApiTypes.ResGetCollection> {
    const data = { collectionId, token };
    return Call.get<ApiTypes.ResGetCollection, typeof data>('/api/collection/one', data);
  },
  getCollectionMedias(collectionId: string, filters: any, token?: string): Promise<ApiTypes.ResGetCollectionMedias> {
    const data = { collectionId, ...filters, token };
    return Call.get('/api/collection/medias', data);
  },
  getCollectionShare(collectionId: string): Promise<ApiTypes.ResGetShareToken> {
    return Call.get('/api/collection/sharetoken', { collectionId });
  },
  getCollectionContentNumber(collectionId: string): Promise<number> {
    return Call.get('/api/collection/contentnumber', { collectionId });
  },
  getGroupUsers(): Promise<ApiTypes.ResGetGroupUsers> {
    return Call.get('/api/group/users');
  },
  async getRightsTemplate(): Promise<ApiTypes.ResGetRightsTemplate> {
    const templates = await Call.get<ApiTypes.ResGetRightsTemplate>('/api/group/rightstemplate');
    return templates || {};
  },
  getFacebookPages(): Promise<IMentionPage[]> {
    return Call.get('/api/user/getfacebookpages');
  },
  getFacebookAdAccounts(): Promise<FBAdAccountResponse[]> {
    return Call.get('/api/user/getfacebookadaccounts');
  },
  async getGroupMentionPages(): Promise<MentionPageView[]> {
    const res = await Call.get<MentionPageView[]>('/api/group/mentionpages').catch(() => null);
    return res || [];
  },
  async getMetaPermission(): Promise<MetaPermission[]> {
    return Call.get<MetaPermission[]>('/api/user/getmetapermissions');
  },
  async getTaggedBrandContentUrl(): Promise<MetadataTaggedFile> {
    return Call.get<MetadataTaggedFile>('/api/brandcontent/download');
  },

  /** SET * */
  login<T>(data: {}, callback: ApiCallback<T>) {
    Call.post('/login', data, callback);
  },
  loginFirebase<T>(data: { uid: string; idToken?: string; has_accepted_cgu: boolean }, callback: ApiCallback<T>) {
    Call.post('/loginfirebase', data, callback);
  },

  loginFirebaseEmail(
    email: string,
    password: string,
    captchaToken: string,
    callback: ApiCallback<string>,
  ) {
    Call.post('/loginfirebase', { email, password, captchaToken }, callback);
  },

  setPasswordUpdate(oobCode: string, captchaToken: string): Promise<string> {
    const data = { oobCode, captchaToken };
    return Call.post<string, typeof data>('/loginfirebase/passwordupdate', data);
  },
  resetPasswordAuthenticated(): Promise<void> {
    return Call.post('/api/user/passwordupdate/sendemail');
  },
  resetPassword(email: string, captchaToken: string): Promise<void> {
    return Call.post('/loginfirebase/passwordupdate/sendemail', { email, captchaToken });
  },
  switchGroup(params: ApiTypes.ParamsSwitchGroup): Promise<void> {
    return Call.put<ApiTypes.ParamsSwitchGroup>('/api/user/switch-group', params);
  },
  setRightsTemplate(data: IPRightsTemplate['template']) {
    return Call.post('/api/group/rightstemplate', { rightstemplate: data });
  },
  removeOauth(social: string, callback: (err: ApiError | undefined, res: any) => any) {
    const data = { social };
    Call.post<unknown, typeof data>('/api/user/removeoauth', data, callback);
  },
  setMentionPage(data: OldApiTypes.User.IBodySetMentionPage) {
    return new Promise((resolve, reject) => {
      Call.post('/api/user/setmentionpage', data, (err) => {
        if (err) {
          return reject(err);
        }
        resolve(undefined);
      });
    });
  },
  publish(data: OldApiTypes.Share.IBodyPublish): Promise<'ok'> {
    return Call.post<'ok', typeof data>('/api/share/publish', data);
  },
  /**
	 *
	 * @param file
	 * @param collectionId If not provided, it will be processed as a brand asset
	 */
  uploadBrandContent(file: File, collectionId?: string, progress?: TUploadFileProgress): Promise<IBrandContent> {
    const params: any = { brandfile: file };
    if (collectionId) {
      params.collectionId = collectionId;
    }

    return uploadFile(`${API_URL}/api/share/uploadbrandcontent`, params, progress);
  },
  removeBrandContent(brandContentId: string) {
    return Call.post('/api/share/removebrandcontent', { brandContentId });
  },
  getDisplayPreview(): Promise<ApiTypes.ResGetDisplayPreview> {
    return Call.get('/api/displaypreview/find');
  },
  insertItemInToDisplay(item: InfoInsert, position: number): Promise<ApiTypes.ResInsertDisplayPreview> {
    const data = { item, position };
    return Call.post<ApiTypes.ResInsertDisplayPreview, typeof data>('/api/displaypreview/insert', data);
  },
  moveItemInDisplay(itemId: string, newPosition: number): Promise<ApiTypes.ResMoveDisplayPreview> {
    const data = { itemId, newPosition };
    return Call.post<ApiTypes.ResMoveDisplayPreview, typeof data>('/api/displaypreview/move', data);
  },
  removeItemFromDisplay(itemId: string): Promise<ApiTypes.ResRemoveDisplayPreviewItem> {
    const data = { itemId };
    return Call.post<ApiTypes.ResRemoveDisplayPreviewItem, typeof data>('/api/displaypreview/remove', data);
  },
  saveDraftInItemFromDisplay(itemId: string, comment?: string, date?: Date): Promise<ApiTypes.ResSaveDraftDisplayPreview> {
    const data = { itemId, comment, date };
    return Call.post<ApiTypes.ResSaveDraftDisplayPreview, typeof data>('/api/displaypreview/savedraft', data);
  },
  getVideoMakers(): Promise<ApiTypes.ResGetAllVideoMakers> {
    return Call.get('/api/videomaker/');
  },
  getVideoMakerId(data: ApiTypes.ParamsGetVideoMaker): Promise<ApiTypes.ResGetVideoMaker> {
    return Call.get<ApiTypes.ResGetVideoMaker, typeof data>('/api/videomaker/id', data);
  },
  updateVideoMaker(data: ApiTypes.ParamsCreateOrUpdateVideoMaker): Promise<ApiTypes.ResCreateOrUpdateVideoMaker> {
    return Call.put<ApiTypes.ResCreateOrUpdateVideoMaker, ApiTypes.ParamsCreateOrUpdateVideoMaker>('/api/videomaker/', data);
  },
  deleteVideoMaker(data: ApiTypes.ParamsDeleteVideoMaker): Promise<ApiTypes.ResDeleteVideoMaker> {
    return Call.delete('/api/videomaker/', data);
  },
  processVideoMaker(data: ApiTypes.ParamsProcessVideoMaker): Promise<ApiTypes.ResProcessVideoMaker> {
    return Call.post('/api/videomaker/process', data);
  },
  getVideoMakerURL(data: ApiTypes.ParamsGetVideoMakerUrl): Promise<ApiTypes.ResGetVideoMakerUrl> {
    return Call.get('/api/videomaker/video', data);
  },
  publishVideoMakerAds(data: ApiTypes.ParamsPublishFBAdsVideoMaker): Promise<ApiTypes.ResPublishFBAdsVideoMaker> {
    return Call.post('/api/videomaker/facebookadspublish', data);
  },
  async getProducts(params: ApiTypes.ParamsGetProducts): Promise<ApiTypes.ResGetProducts['products']> {
    const { products } = await Call.get<{ products: ApiTypes.ResGetProducts['products'] }, ApiTypes.ParamsGetProducts>('/api/products', params);
    return products;
  },
  updateObjectLabels(params: ApiTypes.ParamsPutObjectLabels): Promise<void> {
    return Call.put<ApiTypes.ParamsPutObjectLabels>('/api/media/object_labels', params);
  },
  getCarouselMedias(target: MediaView): Promise<MediaView[]> {
    return Call.get<MediaView[], { target: string }>('/api/media/carousel', {
      target: target._id,
    });
  },
};

type serializable = object | any[];

const ajax = <Q extends serializable, R>(input: {
  path: string
  method: 'GET' | 'POST' | 'DELETE' | 'PUT'
  data?: Q
  callback?: (err?: ApiError, data?: R) => any
}): Promise<R> => {
  const {
    path, data, callback, method,
  } = input;

  const get = method === 'GET';
  let url = API_URL + path;

  if (get && data) {
    url += `?${ApiHelper.serializeToQS(data)}`;
  }

  const params: {
    method: 'GET' | 'POST' | 'DELETE' | 'PUT'
    credentials: string
    mode: string
    headers: {
      'Content-Type': string
    }
    body?: string
  } = {
    method,
    credentials: 'include',
    mode: 'cors',
    headers: { 'Content-Type': 'application/json' },
    body: JSON.stringify(data),
  };

  if (get) { delete params.body; }
  return fetch(url, params as any)
    .then(async (r) => {
      const { status } = r;
      let resp = { res: { err: {} } } as any;
      const responseBody = await r.text();
      try {
        resp = JSON.parse(responseBody);
      } catch (e) {
        console.error('Error while parsing json ', { path, error: e, responseBody });
        throw e;
      }
      switch (status || resp.err.code) {
        case 401:
          console.error('[auth error]', `API -> ${path}: ${JSON.stringify(data)}`);
          throw {
            message: 'Not Authorized',
            type: 'auth',
          };
        case 402:
        case 403:
          console.error('[auth error]', `API -> ${path}: ${JSON.stringify(data)}`);
          if (resp && resp.res === 'session locked') {
            throw {
              message: 'Session locked',
              type: 'session locked',
            };
          }
          if (resp?.res === 'non-existing user' || resp?.res === 'user not found') {
            throw {
              message: 'Non existing user',
              type: 'unregistered',
            };
          }
          throw {
            message: resp?.err?.message || 'Unsufficient rights',
            type: resp?.err?.type || 'role',
          };
        case 404:
          throw {
            message: 'NotFound',
            type: 'notfound',
          };
        case 405:
          throw {
            message: 'Method not allowed',
            type: 'methodnotallowed',
          };
        case 498:
          throw {
            message: 'Expired token',
            type: 'expiredtoken',
          };
        case 500:
          throw {
            message: resp.err.message || 'Internal server error',
            type: resp.err.type || 'internalerror',
            data: resp.err.data,
          };
        case 503:
          reduxHelpers.setRoute(Pathname.maintenance);
          throw {
            message: 'Maintenance',
            type: 'maintenance',
          };
      }

      if (resp.err) {
        throw resp.err;
      }

      return resp;
    })
    .then((v: {
      res: R
    }) => (callback ? callback(undefined, v.res) : v.res))
    .catch((e) => {
      console.error(`API <- ${path}: ${JSON.stringify(e)}`);
      if (callback) {
        callback(e);
      } else {
        throw e;
      }
    });
};

export abstract class Call {
  public static get<R>(path: string, callback?: (err?: ApiError, res?: R) => any): Promise<R>;
  public static get<R, Q extends serializable>(path: string, data?: Q): Promise<R>;
  public static get<R, Q extends serializable>(path: string, data?: Q, callback?: (err?: ApiError, res?: R) => any): Promise<R> {
    return ajax({
      path, data, callback, method: 'GET',
    });
  }

  public static post<R>(path: string, callback?: (err?: ApiError, res?: R) => any): Promise<R>;
  public static post<Q extends serializable>(path: string, data?: Q): Promise<void>;
  public static post<R, Q extends serializable>(path: string, data?: Q, callback?: (err?: ApiError, res?: R) => any): Promise<R>;
  public static post<R, Q extends serializable>(path: string, data?: Q, callback?: (err?: ApiError, res?: R) => any): Promise<R> {
    return ajax({
      path, data, callback, method: 'POST',
    });
  }

  public static delete<R>(path: string, callback?: (err?: ApiError, res?: R) => any): Promise<R>;
  public static delete<Q extends serializable>(path: string, data?: Q): Promise<void>;
  public static delete<R, Q extends serializable>(path: string, data?: Q, callback?: (err?: ApiError, res?: R) => any): Promise<R> {
    return ajax({
      path, data, callback, method: 'DELETE',
    });
  }

  public static put<R>(path: string, callback?: (err?: ApiError, res?: R) => any): Promise<R>;
  public static put<Q extends serializable>(path: string, data?: Q): Promise<void>;
  public static put<R, Q extends serializable>(path: string, data?: Q, callback?: (err?: ApiError, res?: R) => any): Promise<R>;
  public static put<R, Q extends serializable>(path: string, data?: Q, callback?: (err?: ApiError, res?: R) => any) {
    return ajax({
      path, data, callback, method: 'PUT',
    });
  }
}

async function handleApiResponse(r: any) {
  if (!r) {
    throw new Error('An error occured');
  } else if (r.err) {
    throw r.err;
  } else {
    return r.res;
  }
}

export type TUploadFileProgress = (percentage: number) => any;

/**
 *
 * @param url
 * @param params
 *
 * @example
 * await uploadFile('https://mysite.com/upload', {
 * 		file: myfile,
 * 		someparameter: 'hello'
 * }, (percentage) => console.log(`${percentage}%`))
 */
export async function uploadFile(url: string, params: { [key: string]: string | File }, progress?: TUploadFileProgress): Promise<XMLHttpRequest['response']> {
  const res = await new Promise((resolve, reject) => {
    const xhr = new XMLHttpRequest();
    xhr.responseType = 'json';
    xhr.withCredentials = true;

    if (progress) {
      xhr.upload.addEventListener('progress', (event) => {
        if (event.lengthComputable) {
          progress((event.loaded / event.total) * 100);
        }
      });
    }

    xhr.upload.addEventListener('error', () => reject(xhr.response));
    xhr.onreadystatechange = function () {
      if (xhr.readyState === 4) {
        return resolve(xhr.response);
      }
    };

    const formData = new FormData();
    Object.keys(params).forEach((k) => {
      const v = params[k];
      if (v instanceof File) {
        formData.append(k, v, v.name);
      } else {
        formData.append(k, v);
      }
    });

    xhr.open('POST', url);
    xhr.send(formData);
  });

  return handleApiResponse(res);
}
