import React from 'react';
import { IWidget } from 'common';
import { style } from 'typestyle';
import { CarouselIcon } from '../../Icons/LayoutIcons/Carousel';
import { WallIcon } from '../../Icons/LayoutIcons/Wall';
import { MosaicIcon } from '../../Icons/LayoutIcons/Mosaic';

type Type = IWidget['settings']['type'];

interface Props {
  type?: Type;
  onChange: (type: Type) => any;
}

export function LayoutSelector({ type, ...props }: Props) {
  const onChange = (type: Type) => {
    props.onChange(type);
  };

  return (
    <div className={selectClass}>
      <CarouselIcon onClick={() => onChange('carousel')} selected={type === 'carousel'} />
      <WallIcon onClick={() => onChange('wall')} selected={type === 'wall'} />
      <MosaicIcon onClick={() => onChange('mosaic')} selected={type === 'mosaic'} />
    </div>
  );
}
const selectClass = style({
  $nest: {
    '& > *': {
      margin: '0 5px',
    },
  },
});
