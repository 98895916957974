import React from 'react';
import { Code } from 'components/Code';
import { PerfectScrollbar } from 'components/PerfectScrollbar';
import { style } from 'typestyle';
import {
  WIDGET_CDN_URL, NODE_ENV, API_URL, WIDGET_VERSION,
} from '../../../config';
import { CollectionDataString, ProductDataString } from '../EditPage';
import { SourceType } from '../utils/sourceType';

interface Props {
  token?: string
  products?: ProductDataString
  collections?: CollectionDataString
  type: SourceType
}

const getCode = (apiKey: string, source: string, type: string) => `
<!--- 
Insert this snippet in your website to display the widget using the
configuration set on adalong.

Use the data-${type}s attribute on #adalong-widget to override the fallback.
The value must be a stringified array with the ${type} ids.

Get more informations on https://www.npmjs.com/package/@adalong/widget/v/${WIDGET_VERSION}
-->
<div id="adalong-widget"${source}></div>
<script defer src="${WIDGET_CDN_URL}"></script>
<script>
  window.addEventListener('load', async () => {
      const { initializeAdalongWidget } = window.AdalongWidget;
      ${NODE_ENV === 'production' 
        ? `const widget = await initializeAdalongWidget({
            token: '${apiKey}'
          });`
        : `const widget = await initializeAdalongWidget({
          token: '${apiKey}',
          config: {
              debug: {
                apiUrl: ${API_URL}, 
              },
            },
        });`}
      await widget.load('#adalong-widget');
  });
</script>
`.trim();

export function Export(props: Props) {
  const sourceData: string[] = [];
  if (props.products) {
    sourceData.push(toAttribute('data-products', props.products));
  }
  if (props.collections) {
    sourceData.push(toAttribute('data-collections', props.collections));
  }

  const sourceStr = sourceData.length ? ` ${sourceData.join(' ')}` : '';
  const code = getCode(
    props.token || '',
    sourceStr,
    props.type,
  );

  return (
    <PerfectScrollbar
      style={{ height: '100%' }}
      options={{
        wheelSpeed: 0.6,
        minScrollbarLength: 30,
      }}
    >
      <div className={exportClass}>
        <h5>Javascript</h5>
        <p>Insert the following code into your site</p>
        <Code language="html">
          {code}
        </Code>
      </div>
    </PerfectScrollbar>
  );
}

function toAttribute(attr: string, val: string = '') {
  const valWithEscapedQuotes = val.replaceAll("'", '&#39;');
  return `${attr}='${valWithEscapedQuotes}'`;
}

const exportClass = style({
  position: 'relative',
  padding: '20px 30px',
  $nest: {
    p: {
      fontFamily: 'Helvetica, sans-serif',
    },
  },
});
