import { useQuery, UseQueryOptions } from 'react-query';
import { QUERY_PREFIX } from '@src/query';
import { SimilarRequestType } from '@src/types/api';
import { MediaView } from 'common';
import { findMediaSimilar } from './api';

/**
 * Find products linked to any media
 */
export const useQueryMediaSimilar = (mediaId: string, similiType: SimilarRequestType, stringifiedQuery?: string, config?: UseQueryOptions<MediaView[]>) => useQuery([QUERY_PREFIX.MEDIA_SIMILAR, mediaId, { similiType }, stringifiedQuery], () => findMediaSimilar(mediaId, [similiType], stringifiedQuery));
