import React, { Component } from 'react';

import { connect } from 'react-redux';

import * as reduxHelpers from '@src/services/helpers/redux';
import { CollectionView } from 'common';
import { ICombinedReducers } from '@src/types/redux';
import { Pathname } from '@src/types/Browsing';
import { Hide } from '@src/components/Hide';
import { Loader } from '@src/components/Loader';
import { style } from 'typestyle';
import { Row } from '@src/components/Utils';
import { PerfectScrollbar } from '@src/components/PerfectScrollbar';
import { CollectionThumb } from '@src/components/CollectionThumb';
import { Draggable, DraggableProvided } from 'react-beautiful-dnd';

interface IProps {
  list: CollectionView[];
  sortBy: 'title' | 'date';
  isLoading: boolean;
}

interface IState {
  collections: CollectionView[];
  common: any;
}

class CollectionsList extends Component<IProps, IState> {
  public render() {
    const sortMethod = {
      title: (a: CollectionView, b: CollectionView) =>
        a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
      date: (a: CollectionView, b: CollectionView) =>
        new Date(b.created_at) > new Date(a.created_at) ? 1 : -1,
    }[this.props.sortBy];
    const collections = this.props.list.sort(sortMethod);
    return (
      <PerfectScrollbar
        ref='container'
        options={{ minScrollbarLength: 50 }}
        className={collectionsListClass}
      >
        {' '}
        {collections.length === 0 && (
          <div data-empty={window.T('create.collections.empty')}>
            <Hide hide={!this.props.isLoading}>
              <Row className={loaderClass}>
                <Loader />
              </Row>
            </Hide>
          </div>
        )}
        {collections.length > 0 &&
          collections.map((collection, index) => (
            <Draggable key={collection._id} draggableId={collection._id} index={index}>
              {(provided: DraggableProvided) => (
                <div
                  className={collectionContainerClass}
                  ref={provided.innerRef}
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  key={collection._id}
                >
                  <CollectionThumb
                    collection={collection}
                    full
                    className={collectionThumbClass}
                    onClick={() =>
                      reduxHelpers.setRoute(`/collections/${collection._id}` as Pathname)
                    }
                    backgroudImageUrl={collection.preview_media_url ?? undefined}
                  />
                </div>
              )}
            </Draggable>
          ))}
      </PerfectScrollbar>
    );
  }
}

const loaderClass = style({
  marginTop: '33%',
  justifyContent: 'center',
});

const padding = '6px';
const collectionsListClass = style({
  overflow: 'hidden',
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
  gap: '1px',
  padding: `0 ${padding}`,
  $nest: {
    '> div:empty': {
      height: '200px',
      $nest: {
        '&:before': {
          content: 'attr(data-empty)',
          display: 'block',
          textAlign: 'center',
          marginTop: 75,
          color: '#646464',
          fontFamily: 'Raleway, sans-serif',
          fontSize: 25,
        },
      },
    },
  },
});
const collectionContainerClass = style({
  flex: '0 0 250px',
  height: 125,
  padding,
  $nest: {
    '@media only screen and (max-width: 800px)': {
      height: 100,
      flex: '0 0 200px',
    },
  },
});
const collectionThumbClass = style({
  height: '100%',
  width: '100%',
});

export default connect((state: ICombinedReducers) => ({
  windowSize: state.common.windowSize,
}))(CollectionsList);
