import { ActionButton } from '@src/components/ActionButton';
import React from 'react';
import { stylesheet } from 'typestyle';

interface Props {
  text: string
  disabled?: boolean
  onClick: () => void
}

export function LoginSubmitButton({
  text,
  disabled,
  onClick,
}: Props) {
  return (
    <ActionButton
      disabled={disabled}
      variant="transparent"
      className={sheet.loginSubmitButton}
      onClick={onClick}
    >
      {text}
    </ActionButton>
  );
}

const sheet = stylesheet({
  loginSubmitButton: {
    width: '100%',
    marginTop: 15,
  },
});
