import React, { PropsWithChildren } from 'react';
import { style } from 'typestyle';
import { ThemeStyle, ADALONG_COLORS, TTitleSizes } from '@src/styles';

interface Props {
  size?: TTitleSizes
}

export function SectionTitle({
  children,
  size = 'medium',
}: PropsWithChildren<Props>) {
  return (
    <span
      className={titleClass}
      style={{ fontSize: ThemeStyle.textSizes[size] }}
    >
      {children}
    </span>
  );
}

const titleClass = style({
  fontWeight: 'bold',
  color: ADALONG_COLORS.LIGHT_WHITE,
});
