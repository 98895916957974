/*
**	Share Action Creators
*/

import * as ACTIONS from './_types';

export function logOut() {
  return { type: ACTIONS.LOGOUT };
}

export function loginSuccess() {
  return { type: ACTIONS.LOGIN_SUCCESS };
}

export function loginFailed(error?: string) {
  return { type: ACTIONS.LOGIN_FAILED, error };
}

export function submitting(submitting: string) {
  return { type: ACTIONS.LOGIN_SUBMITTING, submitting };
}

export function fieldChange() {
  return { type: ACTIONS.LOGIN_FIELD_CHANGED };
}
