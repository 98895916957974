import { Row } from '@src/components/Utils';
import React, { useState } from 'react';
import { stylesheet } from 'typestyle';
import { auth } from '@src/config/firebaseAuth';
import StyledFirebaseAuth from '@src/pages/Login/StyledFirebaseAuth';
import firebaseui from 'firebaseui';
import { IS_TURNSTILE_ENABLED } from 'config';
import { LoginSubmitButton } from './LoginSubmitButton';
import { LoginTextInput } from './TextInput';
import { PropsLogin } from './UserSettings';
import Captcha from './Captcha';

interface OwnProps {
  uiConfig: firebaseui.auth.Config
  emailLogin(username: string, password: string, captchaToken: string): void
}

type IProps = OwnProps & PropsLogin;

export default function UserLogin(props: IProps): JSX.Element {

  const [username, setusername] = useState<string>("");
  const [password, setpassword] = useState<string>("");
  const [captchaToken, setCaptchaToken] = useState<string | undefined>(undefined);

  function handleUsername(event: { target: { value: string } }) {
    setusername(event.target.value);
  }

  function handlePassword(event: { target: { value: string } }) {
    setpassword(event.target.value);
  }

  function emailLogin() {
    if (captchaToken || !IS_TURNSTILE_ENABLED) {
      props.emailLogin(username, password, captchaToken ?? '');
    }
  }

  function handleCaptchaTokenChange(token: string) {
    setCaptchaToken(token);
  }

  return (
    <>
      <div className={sheet.firebaseLogin}>
        <StyledFirebaseAuth uiConfig={props.uiConfig} firebaseAuth={auth} />
      </div>
      <Row>
        <div className="col-sm-10 offset-sm-1" style={{ marginBottom: 27 }}>
          <div style={{
            borderBottom: 'solid 2px', bottom: -13, position: 'relative', zIndex: 1,
          }}
          />
          <span style={{
            backgroundColor: '#272727', position: 'relative', zIndex: 5, padding: 7,
          }}
          >
            OR
          </span>
        </div>
      </Row>
      <Row>
        <div className="col-sm-10 offset-sm-1">
          <LoginTextInput label="Login" type="email" fullWidth onChange={handleUsername} />
        </div>
      </Row>
      <Row>
        <div className="col-sm-10 offset-sm-1">
          <LoginTextInput label="Password" type="password" fullWidth value={password} onChange={handlePassword} />
        </div>
      </Row>
      {username && username.length >= 2
        ? (
          <Row>
            <div
              className="col-sm-10 offset-sm-1"
              style={{
                fontSize: 11, color: '#c14e4c', fontWeight: 'bold', marginTop: 7, marginBottom: 7,
              }}
            >
              If you only sign in with your email and password, it might be easier for someone
              to break into your account.
              {' '}
              <br />
              {' '}
              Sign in with your social media account and you will benefit
              from Multi-Factor Authentication.
            </div>
          </Row>
        )
        : null}
      {IS_TURNSTILE_ENABLED ? (
        <>
          <Captcha
            onTokenChange={handleCaptchaTokenChange}
          />
          <div className="cf-turnstile"></div>
        </>
      ) : null}
      <Row className={sheet.submit}>
        <LoginSubmitButton
          text={window.T('login.submit').toUpperCase()}
          onClick={emailLogin}
        />
      </Row>
    </>
  );
}

const sheet = stylesheet({
  firebaseLogin: {
    $nest: {
      '.firebaseui-idp-list': {
        display: 'flex',
        justifyContent: 'space-around',
      },
      '.firebaseui-idp-button': {
        width: 60,
        height: 60,
        lineHeight: 60,
        textAlign: 'center',
        borderRadius: 30,
      },
      '.firebaseui-idp-facebook': {
        backgroundImage: 'url(\'https://cdn4.iconfinder.com/data/icons/social-media-icons-the-circle-set/48/facebook_circle-512.png\')',
        backgroundSize: 'cover',
      },
      '.firebaseui-idp-facebook img': {
        display: 'none',
      },
      '.firebaseui-idp-text.firebaseui-idp-text-long': {
        display: 'none',
      },
      '.firebaseui-idp-icon': {
        width: 30,
        height: 30,
      },
      '.firebaseui-idp-icon-wrapper': {
        display: 'flex',
      },
    },
  },
  submit: {
    justifyContent: 'center',
  },
});
