import {
  IBrandContent,
  VideoMakerView,
  DisplayPreviewContentView,
  MediaView,
} from 'common';
import { VideoState } from '@src/types/redux/video';

import { TShareCollectionInfo } from '@src/types/redux/share';
import { REDUX_ACTIONS } from './_types';

export const VIDEO_ACTIONS = {
  setLoading(loading: boolean) {
    return { type: REDUX_ACTIONS.GIF.SET_LOADING, loading };
  },

  setLoadingEdit(loadingEdit: number) {
    return { type: REDUX_ACTIONS.GIF.SET_LOADING_EDIT, loadingEdit };
  },

  setVideoMakerList(list: VideoMakerView[]) {
    return { type: REDUX_ACTIONS.GIF.SET_VIDEOMAKER_LIST, list };
  },

  loadListSuccess(list: TShareCollectionInfo[]) {
    return { type: REDUX_ACTIONS.GIF.LOAD_LIST, list };
  },

  loadingCollection(id: string) {
    return { type: REDUX_ACTIONS.GIF.LOADING_COLLECTION, id };
  },

  loadCollectionSuccess(medias: MediaView[], id: string) {
    return { type: REDUX_ACTIONS.GIF.LOAD_COLLECTION_SUCCESS, medias, id };
  },

  addBrandContentToCollection(collectionId: string | undefined, brandContent: IBrandContent) {
    return { type: REDUX_ACTIONS.GIF.ADD_BRANDCONTENT_TO_COLLECTION, brandContent, collectionId };
  },

  removeBrandContentFromCollection(brandContentId: string) {
    return { type: REDUX_ACTIONS.GIF.REMOVE_BRANDCONTENT_FROM_COLLECTION, brandContentId };
  },

  setBrandContents(brandContents: IBrandContent[]) {
    return { type: REDUX_ACTIONS.GIF.BRANDCONTENTS.STORE, brandContents };
  },

  setInsertedDisplayPreviewContent(content: DisplayPreviewContentView) {
    return { type: REDUX_ACTIONS.GIF.SET_COLLECTION_CONTENT, content };
  },

  widthUpdate(width: number) {
    return { type: REDUX_ACTIONS.GIF.EDITOR.WIDTH_UPDATE, width };
  },

  editVideoMaker(videoMaker: VideoMakerView) {
    return { type: REDUX_ACTIONS.GIF.EDITOR.EDIT_VIDEO_MAKER, videoMaker };
  },

  changePage(page: VideoState['page']) {
    return { type: REDUX_ACTIONS.GIF.EDITOR.CHANGE_PAGE, page };
  },

  setResolvedImages(resolvedImages: VideoState['resolvedImages']) {
    return { type: REDUX_ACTIONS.GIF.EDITOR.SET_RESOLVED_IMAGES, resolvedImages };
  },

  wasSaved() {
    return { type: REDUX_ACTIONS.GIF.EDITOR.WAS_SAVED };
  },

  selectImage(index: number) {
    return { type: REDUX_ACTIONS.GIF.EDITOR.SELECT_CURRENT_IMAGE, index };
  },

  updateGif(gif: Partial<VideoMakerView>) {
    return { type: REDUX_ACTIONS.GIF.EDITOR.UPDATE_GIF, gif };
  },
};
