import React from 'react';
import { DropDownIcon } from './DropDownIcon';

export function LayoutIcon() {
  return (
    <DropDownIcon
      childrenRender={() => (
        <>
          <rect width='50' height='50' rx='10' fill='#D9D9D9' />
          <path
            d='M19.066 30.1649H10.1649C8.98455 30.1649 7.85253 29.696 7.01789 28.8613C6.18325 28.0267 5.71436 26.8947 5.71436 25.7143V10.8791C5.71436 9.69878 6.18325 8.56676 7.01789 7.73212C7.85253 6.89748 8.98455 6.42859 10.1649 6.42859H19.066C20.2464 6.42859 21.3784 6.89748 22.213 7.73212C23.0477 8.56676 23.5166 9.69878 23.5166 10.8791V25.7143C23.5166 26.8947 23.0477 28.0267 22.213 28.8613C21.3784 29.696 20.2464 30.1649 19.066 30.1649Z'
            fill='#343434'
          />
          <path
            d='M39.8355 21.2638H30.9344C29.7541 21.2638 28.6221 20.7949 27.7874 19.9602C26.9528 19.1256 26.4839 17.9936 26.4839 16.8132V10.8791C26.4839 9.69878 26.9528 8.56676 27.7874 7.73212C28.6221 6.89748 29.7541 6.42859 30.9344 6.42859H39.8355C41.0159 6.42859 42.1479 6.89748 42.9825 7.73212C43.8172 8.56676 44.2861 9.69878 44.2861 10.8791V16.8132C44.2861 17.9936 43.8172 19.1256 42.9825 19.9602C42.1479 20.7949 41.0159 21.2638 39.8355 21.2638Z'
            fill='#343434'
          />
          <path
            d='M39.8355 45.0001H30.9344C29.7541 45.0001 28.6221 44.5312 27.7874 43.6965C26.9528 42.8619 26.4839 41.7299 26.4839 40.5495V28.6814C26.4839 27.501 26.9528 26.369 27.7874 25.5344C28.6221 24.6997 29.7541 24.2308 30.9344 24.2308H39.8355C41.0159 24.2308 42.1479 24.6997 42.9825 25.5344C43.8172 26.369 44.2861 27.501 44.2861 28.6814V40.5495C44.2861 41.7299 43.8172 42.8619 42.9825 43.6965C42.1479 44.5312 41.0159 45.0001 39.8355 45.0001Z'
            fill='#343434'
          />
          <path
            d='M19.066 45H10.1649C8.98455 45 7.85253 44.5311 7.01789 43.6964C6.18325 42.8618 5.71436 41.7298 5.71436 40.5494V37.5824C5.71436 36.402 6.18325 35.27 7.01789 34.4354C7.85253 33.6007 8.98455 33.1318 10.1649 33.1318H19.066C20.2464 33.1318 21.3784 33.6007 22.213 34.4354C23.0477 35.27 23.5166 36.402 23.5166 37.5824V40.5494C23.5166 41.7298 23.0477 42.8618 22.213 43.6964C21.3784 44.5311 20.2464 45 19.066 45Z'
            fill='#343434'
          />
        </>
      )}
    />
  );
}
