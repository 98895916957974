import React from 'react';
import { stylesheet } from 'typestyle';

export function FormLineSeparator() {
  return (
    <div className={sheet.separator} />
  );
}

const sheet = stylesheet({
  separator: {
    marginTop: 20,
    marginBottom: 20,
    height: 1,
    backgroundColor: '#303030',
  },
});
