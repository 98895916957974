import { ApiTypes, IGroup, IMentionPage, MentionPageView, MetaPermission } from 'common';
import { Call } from '@src/services/api';
import { FBAdAccountResponse } from '@src/types/api';

export const getUserConfig = (): Promise<ApiTypes.ResGetMe> => Call.get<ApiTypes.ResGetMe>('/api/user/me');

export const setRightsTemplate = (data: Partial<IGroup['rights_template']>) => Call.post('/api/group/rightstemplate', { rightstemplate: data });

export const getRightsTemplate = (): Promise<ApiTypes.ResGetRightsTemplate> => Call.get<ApiTypes.ResGetRightsTemplate>('/api/group/rightstemplate');

export const getGroupMentionPages = (): Promise<MentionPageView[] | null> => Call.get<IMentionPage[]>('/api/group/mentionpages').catch(() => null);

export const fetchFacebookAdAccounts = (): Promise<FBAdAccountResponse[]> => Call.get('/api/user/getfacebookadaccounts')

export const fetchFacebookPages = (): Promise<ApiTypes.ResGetFacebookPages[]> => Call.get('/api/user/getfacebookpages');

export const getMetaPermissions = (): Promise<MetaPermission[]> => Call.get('/api/user/getmetapermissions');