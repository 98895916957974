import { ADALONG_COLORS } from '@src/styles';
import React from 'react';
import { Switch } from '@mui/material';
import { style } from 'typestyle';

interface Props {
  checked: boolean;
  onLabelSwitchChange: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

export function ExcludeLabelsSwitch({ checked, onLabelSwitchChange }: Props) {
  return (
    <div className={sectionFolderClass}>
      <div className={childrenClass}>
        <div className={excludeLabelClass}>Exclude Graphic Labels</div>
        <Switch
          checked={checked}
          onChange={onLabelSwitchChange}
          name='checkedAllLabels'
          sx={{
            '& .MuiSwitch-thumb': {
              backgroundColor: checked ? ADALONG_COLORS.GREEN : ADALONG_COLORS.LIGHT_GRAY,
            },
            '&.MuiSwitch-root .MuiSwitch-track': {
              backgroundColor: checked ? ADALONG_COLORS.GREEN : ADALONG_COLORS.DARK_GRAY,
            },
          }}
          size='small'
        />
      </div>
    </div>
  );
}

const sectionFolderClass = style({
  marginBottom: 23,
});

const childrenClass = style({
  fontSize: 14,
  fontWeight: 'inherit',
  display: 'flex',
  flexDirection: 'row',
  justifyContent: 'space-between',
});

const excludeLabelClass = style({
  fontFamily: '"Public Sans", sans-serif',
  textTransform: 'uppercase',
  color: 'white',
  fontWeight: 'bold',
  fontSize: 12,
  letterSpacing: '0.7px',
  margin: 'auto 0',
});
