import { ADALONG_COLORS } from '@src/styles';
import React from 'react';
import { style } from 'typestyle';
import TextContent from '../Media/TextContent';

interface Props {
  text: string | undefined,
  groupName?: string,
}

export function MediaCaption({
  text,
  groupName,
}: Props) {
  if (!text) {
    return null;
  }
  return (
    <div className={captionClass}>
      <TextContent caption={text} style={textClass} groupName={groupName} />
    </div>
  );
}

const textClass: React.CSSProperties = {
  fontSize: 15,
  color: ADALONG_COLORS.LIGHT_WHITE,
  padding: 0,
};

const captionClass = style({
  marginTop: 17,
  wordBreak: 'break-word',
  fontWeight: 'lighter',
});
