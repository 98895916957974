import { IPopupsService, AddToCollection } from '@src/types/Popups';
import { collectOverlay, setPopup, setPopupLoading, setCreatorCountriesOverlay } from '@src/actions/common';
import { ServiceReference, ServiceName } from '@src/types/Services';
import { ServiceStore } from '@src/types/redux';
import { EPopup, PopupsState } from '@src/types/redux/common';
import { GlobalError } from '@src/types/GlobalError';
import { Pathname } from '@src/types/Browsing';
import { BrowsingService } from './Browsing';
import { MediaView } from 'common';

export class PopupsService implements IPopupsService {
  private browsingService?: BrowsingService;

  private globalErrorService?: GlobalError;

  constructor(
    servicesPromise: ServiceReference,
    private store: ServiceStore,
  ) {
    servicesPromise.then((services) => {
      this.browsingService = services.get<BrowsingService>(ServiceName.Browsing);
      this.globalErrorService = services.get<GlobalError>(ServiceName.GlobalError);
    });
  }

  /**
     * Open a popup and load given data in it
     */
  public open<T extends EPopup>(popup: T, data: PopupsState[T]): void {
    this.store.dispatch(setPopup(popup, data));
  }

  /**
     * Close a popup
     */
  public close(popup: EPopup) {
    this.browsingService?.goToPage(this.getBasePath());
    this.store.dispatch(setPopup(popup, null));
  }

  /**
     * Open the collect Panel
     */
  public openCollectOverlay(step: 'add' | 'new', media?: AddToCollection): void {
    // Note: if refactoring the collect overlay, use the open() method instead
    this.store.dispatch(collectOverlay(step, media));
  }

  public openSetCreatorCountriesOverlay(mediaList: MediaView[]): void {
    this.store.dispatch(setCreatorCountriesOverlay(mediaList));
  }

  public async setPopupLoading(loading: boolean): Promise<void> {
    this.store.dispatch(setPopupLoading(loading));
  }

  private getBasePath(): Pathname {
    let path = window.location.pathname;
    if (/\/discover\/[^?]+/.test(path)) {
      path = Pathname.discover;
    }
    const testOpenedCollection = /(\/collections\/[^/]+)\/[^/]/.exec(path);
    if (testOpenedCollection && testOpenedCollection.length > 1) {
      path = testOpenedCollection[1];
    }
    return path as Pathname;
  }
}
