import React from 'react';
import LocalizedStrings from 'react-localization';
import { ActionButton, ActionProps } from '../ActionButton';

export function GetRightsButton(props: ActionProps) {
  return (
    <ActionButton
      {...props}
    >
      {lang.getRights}
    </ActionButton>
  );
}

const lang = new LocalizedStrings({
  en: {
    getRights: 'Get rights',
  },
});
