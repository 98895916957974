import React from 'react';
import LocalizedStrings from 'react-localization';
import { ActionButton } from '../ActionButton';

interface Props {
  onClick: () => void
}

export function UncollectButton({
  onClick,
}: Props) {
  return (
    <ActionButton
      onAction={onClick}
      variant="gray"
      size="small"
    >
      {lang.uncollect}
    </ActionButton>
  );
}

const lang = new LocalizedStrings({
  en: {
    uncollect: 'Remove',
  },
});
