import { useMutation } from 'react-query';
import { ApiError } from '@src/services/api';
import { CollectionView } from 'common';
import { DEPRECATED_QUERY_KEYS, queryClient } from '@src/query';
import { duplicate } from './api';

export const useMutationCollectionDuplicate = () => useMutation<CollectionView, ApiError, { collectionId: string }>(({ collectionId }) => duplicate(collectionId), {
  onSuccess: async () => {
    const key = [DEPRECATED_QUERY_KEYS.COLLECTIONS];
    queryClient.invalidateQueries(key);
  },
});
