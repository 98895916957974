import React, { Component } from 'react';
import { DownloadPrompt } from '@src/components/DownloadPrompt';
import { setPopup } from '@src/actions/common';
import { bigStore } from '@src/store';
import { EPopup } from '@src/types/redux/common';

interface Props {
  collectionId: string
}

class DownloadCollection extends Component<Props> {
  public render() {
    const { collectionId } = this.props;
    return (
      <DownloadPrompt
        collectionId={collectionId}
        hide={() => this.close()}
      />
    );
  }

  private close() {
    bigStore.dispatch(setPopup(EPopup.downloadCollection, null));
  }
}

export { DownloadCollection };
