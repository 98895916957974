import React, { useState, useEffect } from 'react';

import * as CommonActions from '../../actions/common';
import { auth } from '@src/config/firebaseAuth';

import { UserView } from 'common';
import { EPopup } from '@src/types/redux/common';
import { bigStore } from '@src/store';

import Box from '@mui/material/Box';
import Avatar from '@mui/material/Avatar';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Tooltip from '@mui/material/Tooltip';
import Settings from '@mui/icons-material/Settings';
import Logout from '@mui/icons-material/Logout';
import PersonIcon from '@mui/icons-material/Person';
import PolicyIcon from '@mui/icons-material/Policy';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import { style } from 'typestyle';
import { ArrowDownward, ArrowForward } from '@mui/icons-material';
import firebase from 'firebase/compat/app';

interface Props {
  user: UserView
  onLogout: () => void
  onSwitchGroup: (groupdId: string) => void
}

/* MUI Styling */
const theme = createTheme({
  components: {
    MuiList: {
      styleOverrides: {
        root: {
          backgroundColor: '#131415',
          color: 'white',
        },
      },
    },
  },
});

export function UserSettings(props : Props) {
  const [user, setUser] = useState<any | null>(auth.currentUser);

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const signOut = () => props.onLogout()


  const openUserSettings = () => {
    bigStore.dispatch(CommonActions.setPopup(EPopup.userSettings, true));
  }

  const openOrganizationSettings = () => {
    bigStore.dispatch(CommonActions.setPopup(EPopup.settings, true));
  }

  useEffect(() => {
    const fireunsub = auth.onAuthStateChanged((user) => setUser(user));
    return () => {
        if (fireunsub) { fireunsub(); }
      }
  }, [])

  const availableGroups = props.user.availableGroups.map(group =>
    <MenuItem sx={menuItemStyle} key={group._id} onClick={() => props.onSwitchGroup(group._id!)}>
    <ListItemIcon sx={iconStyle}>
      {props.user.group.name == group.name ? <ArrowDownward fontSize="small" /> : <ArrowForward fontSize="small" />}
    </ListItemIcon>
    {group.name}
  </MenuItem>)

  return (
    <>
    {user
          ? (
            <>
      <Box sx={boxStyle}>
        <Typography sx={{ minWidth: 200 }}>Hello, {user.displayName || user.email }</Typography>

        <Tooltip title="Account settings">
          <IconButton
            onClick={handleClick}
            size="small"
            sx={{ ml: 2 }}
            aria-controls={open ? 'account-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={open ? 'true' : undefined}
          >
            {user.photoURL ? <Avatar src={user.photoURL} /> : <PersonIcon color="secondary" />}
          </IconButton>
        </Tooltip>
      </Box>

      <ThemeProvider theme={theme}>

      <Menu
      classes={{}}
        anchorEl={anchorEl}
        id="account-menu"
        open={open}
        onClose={handleClose}
        onClick={handleClose}
        PaperProps={{
          elevation: 0,
          sx: {menuStyle},
        }}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
      >
        <MenuItem
     onClick={openUserSettings}
        sx={menuItemStyle}
        >
          <ListItemIcon sx={iconStyle}>
            <ManageAccountsIcon fontSize="small" />
          </ListItemIcon>
          My settings
        </MenuItem>
        <Divider sx={dividerStyle} />

        {props.user.availableGroups.length > 1 ? (<>
          <MenuItem sx={menuItemStyle}>
            Switch brand
          </MenuItem>
          {availableGroups}

          <Divider sx={dividerStyle} />
          </>
        ):(<></>)}

        <MenuItem
        sx={menuItemStyle}
        onClick={openOrganizationSettings}>
          <ListItemIcon sx={iconStyle}>
            <Settings fontSize="small" />
          </ListItemIcon>
          My organization settings
        </MenuItem>

        <Divider sx={dividerStyle}/>

        <a
        className={linkStyle}
        target='_blank'
        href='https://www.adalong.com/privacy/'>
          <MenuItem
          sx={menuItemStyle}>
          <ListItemIcon sx={iconStyle} >
            <PolicyIcon fontSize="small" />
          </ListItemIcon>
          Privacy Policy
          </MenuItem>
        </a>

        <Divider sx={dividerStyle}/>

        <MenuItem
        sx={menuItemStyle}
        onClick={signOut}>
          <ListItemIcon sx={iconStyle} >
            <Logout fontSize="small" />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      </ThemeProvider>


      </>
          ): (
            <div style={{ display: 'flex', flexDirection: 'row' }}>
              <div onClick={openUserSettings}>{props.user.email}</div>
              <div onClick={signOut}> <Logout /> </div>
            </div>
          )}


    </>
  );
}

const dividerStyle = {
  bgcolor: '#909191',
  margin: '8px 0',
}

const menuItemStyle = {
  '&:hover': {
    backgroundColor: '#1b1b1b',
  },
}

const linkStyle = style ({
  textDecoration: 'none',
  color:'#fff'
})

const iconStyle = {
  color:'white',
}

const boxStyle = {
  display: 'flex',
  alignItems: 'center',
  textAlign: 'right',
}

const menuStyle ={
  overflow: 'visible',
  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
  mt: 1.5,

  '& .MuiAvatar-root': {
    width: 32,
    height: 32,
    ml: -0.5,
    mr: 1,
  },
  '&:before': {
    content: '""',
    display: 'block',
    position: 'absolute',
    top: 0,
    right: 14,
    width: 10,
    height: 10,
    bgcolor: 'black',
    transform: 'translateY(-50%) rotate(45deg)',
    zIndex: 0,
  },
}

export interface PropsLogin {
  login: (username: string, password: string, captchaToken: string) => void
}

export interface StateLogin {
  username: string
  password: string
}
