import React, { useState } from 'react';
import MoreHorizIcon from '@mui/icons-material/MoreHoriz';
import { stylesheet } from 'typestyle';
import { ActionButton } from './ActionButton';
import { FloatingMenu, MenuDivider, Props as FloatingMenuProps } from './FloatingMenu';
import { Loader } from './Loader';
import { Hide } from './Hide';

interface Props {
  /**
   * Button size as "rem"
   */
  size?: number;
  menuList: FloatingMenuProps['list'];
  loading: boolean;
  loaderPosition?: 'right' | 'left';
}

/**
 * A button displaying a floating menu on click
 */
export function MoreButton({ size = 1, menuList, loading, loaderPosition = 'left' }: Props) {
  const [anchorEl, setAnchorEl] = useState<Element | null>(null);
  const sizeStr = `${size}rem`;

  const list = menuList.map((listElement) => {
    if (listElement === MenuDivider) {
      return listElement;
    }
    // capture click to close the floating menu
    return {
      ...listElement,
      onClick: () => {
        listElement.onClick();
        handleClose();
      },
    };
  });

  return (
    <>
      <ActionButton
        minWidth={0}
        size='small'
        className={sheet.moreButton}
        style={{
          padding: 0, // overwrite default padding
        }}
        onAction={actionHandler}
      >
        <Hide hide={!loading}>
          <div className={sheet.loader} data-position={loaderPosition}>
            <Loader size={20} />
          </div>
        </Hide>
        <MoreHorizIcon
          style={{
            width: sizeStr,
            height: sizeStr,
          }}
        />
      </ActionButton>
      <FloatingMenu anchorEl={anchorEl} handleClose={handleClose} list={list} />
    </>
  );

  function actionHandler(event: React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    setAnchorEl(event.currentTarget);
  }

  function handleClose() {
    setAnchorEl(null);
  }
}

const sheet = stylesheet({
  moreButton: {
    position: 'relative',
    height: 'fit-content',
    minWidth: 0,
    justifyContent: 'center',
    display: 'flex',
    flexDirection: 'row',
    $nest: {
      '> span': {
        margin: 'auto',
      },
    },
  },
  loader: {
    position: 'absolute',
    top: 0,
    $nest: {
      '&[data-position="left"]': {
        right: '160%',
      },
      '&[data-position="right"]': {
        left: '160%',
      },
    },
  },
});
