import React from 'react';
import { useRecoilState, useRecoilValue } from 'recoil';
import { Suggestions, Value } from '../Suggestions';
import { FilterState } from '@src/pages/Discover/components/Atoms/Atoms';
import { onFilterChange } from '@src/fragments/Filters/utils/FilterStateUtility';
import { XclusionUtility } from '@src/fragments/Filters/managers/XclusionUtility';
import {
  creatorCountriesExcludeState,
  creatorCountriesInput,
} from '@src/pages/Discover/components/Selectors/Selectors';

interface Props {
  availableCountries: string[];
}

export function CreatorCountriesSuggestion({ availableCountries }: Props) {
  const [filters, setFilters] = useRecoilState(FilterState);
  const exclude = useRecoilValue(creatorCountriesExcludeState);
  const filterCreatorCountriesInput = useRecoilValue(creatorCountriesInput);

  // Map available countries to suggestion objects
  const allCountries: Value[] = availableCountries.map((country) => ({
    key: country,
    children: <span>{country}</span>,
  }));

  // Filter selected countries and handle inclusion/exclusion
  const rawKeys = XclusionUtility.getRawKeys(filters.creatorCountries || []);
  const selectedValues: Value[] = allCountries.filter((country) =>
    rawKeys.includes(country.key)
  );

  // Filter countries based on user input
  const filteredValues = filterCreatorCountriesInput ? allCountries.filter((country) =>
    country.key.toLowerCase().includes(filterCreatorCountriesInput.toLowerCase())
  ) : allCountries;

  return (
    <Suggestions
      values={filteredValues}
      selectedValues={selectedValues}
      onChange={onFilterChange(setFilters, 'creatorCountries')}
      exclude={exclude}
    />
  );
}
