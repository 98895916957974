import React, { Component, ReactNode } from 'react';
import { style, classes } from 'typestyle';
import { ADALONG_COLORS } from '@src/styles';
import { Row } from './Utils';
import { CloseButton } from './CloseButton';

type Variant = 'positive' | 'negative';

interface Props {
  children: ReactNode
  onRemove: () => void
  variant?: Variant

}

/**
 * Component to render a label with a cross button anywhere in the app
 * It can be positive or negative (red)
 */
export class LabelPill extends Component<Props, {}> {
  public render() {
    const {
      onRemove,
      variant = 'positive',
    } = this.props;

    const v = variantClasses[variant];
    return (
      <div
        className={classes(pillClass, v.class)}
      >
        <Row className={rowClass}>
          <CloseButton
            color={v.closeColor}
            classNames={[removeClass]}
            onClick={onRemove}
          />
          <div>
            {this.props.children}
          </div>
        </Row>
      </div>
    );
  }
}

const pillClass = style({
  $debugName: 'pill',
  display: 'inline-block',
  padding: '1px 4px',
  borderWidth: 1,
  borderStyle: 'solid',
  backgroundColor: '#262727',
  borderRadius: 4,
  fontWeight: 500,
  margin: '4px 8px 4px 0',
});

const rowClass = style({
  alignItems: 'center',
});

const variantClasses: {
  [key in Variant]: {
    class: string
    closeColor: string
  }
} = {
  positive: {
    class: style({
      color: ADALONG_COLORS.SMOOTH_WHITE,
      borderColor: ADALONG_COLORS.DARK_GRAY,
    }),
    closeColor: ADALONG_COLORS.LIGHT_GRAY,
  },
  negative: {
    class: style({
      color: ADALONG_COLORS.SMOOTH_WHITE,
      borderColor: ADALONG_COLORS.FLASH_RED,
    }),
    closeColor: ADALONG_COLORS.LIGHT_GRAY,
  },
};

const removeClass = style({
  flexShrink: 0,
  marginRight: 3,
  cursor: 'pointer',
});
