/* eslint-disable no-underscore-dangle */
import React from 'react';

import { connect, useSelector } from 'react-redux';
import NavBar from 'components/NavBar';
import DeleteIcon from '@mui/icons-material/Delete';

import { bigStore } from 'store';
import { promptConfirm } from 'actions/common';
import {
  Pathname, Browsing, ArgsName, PathAction,
} from '@src/types/Browsing';
import { ICombinedReducers } from '@src/types/redux';
import LocalizedStrings from 'react-localization';
import { Services, ServiceName } from '@src/types/Services';
import { style } from 'typestyle';
import { ActionButton } from '@src/components/ActionButton';
import { Hide } from '@src/components/Hide';
import { FormError } from '@src/components/Form/FormError';
import { WidgetView } from 'common';
import { useMutationRemoveWidget } from '../../queries/widgets/useMutationRemoveWidget';
import { useQueryWidgets } from '../../queries/widgets/useQueryWidgets';
import AuthorizedPage from '../../components/AuthorizedPage';
import { TableList, ListColumn } from '../../components/TableList';
import { getSourceType } from './utils/sourceType';
import { ButtonIcon } from '@src/components/ButtonIcon';
import LiveIcon from '@mui/icons-material/FiberManualRecord';

const mapStateToProps = (state: ICombinedReducers) => ({
  windowHeight: state.common.windowSize.height,
});

interface OwnProps {
  services: Services
}

type Props = Readonly<ReturnType<typeof mapStateToProps>> & OwnProps;

function Widgets(props: Props) {
  const browsingService: Browsing = props.services.get(ServiceName.Browsing);

  const user = useSelector((state: ICombinedReducers) => state.common.user);

  const {
    data: widgets = [],
    isLoading,
    error: errorLoading,
  } = useQueryWidgets();

  const {
    mutate: removeWidget,
    error: errorRemoving,
  } = useMutationRemoveWidget();

  const collectionWidgets = widgets.filter((widget) => getSourceType(widget) === 'collection');
  const productWidgets = widgets.filter((widget) => getSourceType(widget) === 'product');

  const onRemoveProductWidget = (widget: WidgetView) => {
    bigStore.dispatch(
      promptConfirm({
        question: lang.confirmDelete,
        text: { yes: lang.delete, no: lang.cancel },
        yesColor: 'red',
        answer: async (yes) => {
          if (!yes) {
            return;
          }
          return removeWidget({ id: widget._id });
        },
      }),
    );
  };

  const onWidgetClick = (widget: WidgetView) => {
    browsingService.goToPage({
      root: Pathname.widget,
      args: new Map().set(ArgsName.widget, widget._id),
    });
  };

    const showLiveStatus = (domain? : string) : string | JSX.Element => {
    if (domain) {
      return <ButtonIcon
      icon={LiveIcon}
      titleAccess={domain}
      sx= {{ color: 'green', fontSize: 16, '&:hover': { color: 'darkgreen' }  }}
    />
    } return '';
  };

  const listCollections = (
    liveCollections?: string[],
    fallbackCollections? : string[],
    productCollections?:string[] )
    : string | JSX.Element => {
      if (liveCollections?.length) {
        return liveCollections.join(' • ');
      }
      if (fallbackCollections?.length) {
        return fallbackCollections.join(' • ');
      }
      if (productCollections?.length) {
        return productCollections.join(' • ');
      }
      return '';
    }


  const WidgetColumns: ListColumn<WidgetView>[] = [{
    label: lang.live,
    onClick: (input: WidgetView) => onWidgetClick(input),
    displayName: (input: WidgetView) => showLiveStatus(input.live_status?.domain),
  }, {
    label: lang.name,
    onClick: (input: WidgetView) => onWidgetClick(input),
    displayName: (input: WidgetView) => input.name,
  }, {
    label: lang.description,
    onClick: (input: WidgetView) => onWidgetClick(input),
    displayName: (input: WidgetView) => input.description,
    minWidth: '22vw',
  },
  {
    label: lang.collections,
    onClick: (input: WidgetView) => onWidgetClick(input),
    displayName(input: WidgetView) {
      return <div className={collectionClass}>{
        listCollections(
          input.live_collections.names,
          input.settings.default_sources?.collection_namelist,
          input.settings.default_sources?.product_namelist,
        )}
      </div>},
    minWidth: '22vw',
  },
  {
    label: lang.creator,
    onClick: (input: WidgetView) => onWidgetClick(input),
    displayName: (input: WidgetView) => input.created_by.displayName || 'unknown',
  }, {
    label: '',
    displayName(input: WidgetView) {
      return <DeleteIcon className={deleteClass} onClick={() => onRemoveProductWidget(input)} />;
    },
  }];

  return (
    <AuthorizedPage tab="widget" user={user}>
      <div className={pageClass} style={{ height: props.windowHeight }}>
        <NavBar active={Pathname.widget} />
        <div className={widgetsClass}>
          <div className={listClass}>
            <h3 className={tableTitleClass}>
              {lang.sourceType.collection}
              <ActionButton
                variant="blue"
                onAction={() => browsingService.goToPage(`${Pathname.widget}/${PathAction.create}?source=collection`)}
                className={createClass}
              >
                {lang.create}
              </ActionButton>
            </h3>

            <TableList
              list={collectionWidgets}
              columns={WidgetColumns}
              emptyMessage={lang.noWidget}
              loading={isLoading}
            />
          </div>

            {user?.groupFeatures.product_catalog && (
                <div className={listClass}>
              <h3 className={tableTitleClass}>
                {lang.sourceType.product}
                <ActionButton
                  variant="blue"
                  onAction={() => browsingService.goToPage(`${Pathname.widget}/${PathAction.create}?source=product`)}
                  className={createClass}
                >
                  {lang.create}
                </ActionButton>
              </h3>
              <TableList
                list={productWidgets}
                columns={WidgetColumns}
                emptyMessage={lang.noWidget}
                loading={isLoading}
              />
                </div>
            )}
            <Hide hide={!errorLoading || !errorRemoving}>
              {/* <Contain */}
              <FormError error={errorLoading ? lang.error.refresh : lang.error.unknown} />
            </Hide>
        </div>
      </div>
    </AuthorizedPage>
  );
}

const lang = new LocalizedStrings({
  en: {
    title: 'Widgets',
    create: 'Create new',
    WidgetTitle: 'Product widget',
    live: 'Live',
    name: 'name',
    sourceType: {
      product: 'Product',
      collection: 'Collection',
    },
    description: 'Description',
    collections: 'Collections',
    creator: 'Created by',
    owner: 'Owner',
    confirmDelete: 'Attention, deleting a widget cannot be cancelled; this action is irreversible.',
    noWidget: 'No widget found. Create one to start.',
    cancel: 'Cancel',
    delete: 'Delete',
    error: {
      refresh: 'Unable to get widget list. Please try again or contact us.',
      unknown: 'An error occurred while removing the widget, please try again or contact us.',
    },
  },
});

const pageClass = style({
  display: 'flex',
  flexDirection: 'column',
});
const widgetsClass = style({
  display: 'flex',
  flexFlow: 'column nowrap',
  overflow: 'hidden',
  alignItems: 'center',
  justifyContent: 'center',
  height: '100%'
});
const listClass = style({
  display: 'flex',
  flexDirection: 'column',
  flex:' 0 0 50%',
  overflow: 'hidden',
  width: '100%',
})
const deleteClass = style({
  cursor: 'pointer',
  color: 'gray',
  transition: 'color 0.2s',
  $nest: {
    '&:hover': {
      color: 'white',
    },
  },
});

const createClass = style({
  float: 'right',
  marginRight: 80,
});

const tableTitleClass = style({
  paddingLeft: 15,
  marginTop: 30,
});

const collectionClass = style ({
  width: '22vw',
  overflow: 'hidden',
  whiteSpace: 'nowrap',
  textOverflow: 'ellipsis',
})

export default connect(mapStateToProps)(Widgets);
