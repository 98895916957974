import { push } from 'redux-first-history';

import LocalizedStrings, { GlobalStrings } from 'react-localization';
import { IAlert } from '@src/types/alert';
import { Pathname } from '@src/types/Browsing';
import { bigStore } from '../../store';
import * as CommonActions from '../../actions/common';

const routeParamsCache: Map<string, string> = new Map();

export function loadTranslation<T = any>(translation: GlobalStrings<T>) {
  return new LocalizedStrings<T>(translation);
}

export function setRoute(route: Pathname) {
  const targetRoute = new URL(`http://a${route}`);

  const currentRoute = window.location.pathname + window.location.search;
  if (route === currentRoute) {
    return;
  }

  const { pathname } = targetRoute;
  let { search }: { search: string | undefined } = targetRoute;

  routeParamsCache.set(window.location.pathname, window.location.search);
  if (currentRoute.indexOf(Pathname.discover) === -1 && route.indexOf(Pathname.discover) > -1) {
    // we're navigating from another page to the discover page
    if (!search) {
      search = routeParamsCache.get(pathname);
    }
  }

  try {
    bigStore.dispatch(push({
      pathname,
      search,
    }));
  } catch (e) {

  }
}

export function addAlert(alert: Omit<IAlert, 'id'>) {
  bigStore.dispatch(CommonActions.addAlert(alert));
}

export function keepUrlParams() {
  const params = new URL(window.location.href).searchParams;
  bigStore.dispatch(CommonActions.setUrlParam('oauthFailed', !!parseInt(params.get('_of') || '', 10)));
  bigStore.dispatch(CommonActions.setUrlParam('token', params.get('t')));
  bigStore.dispatch(CommonActions.setUrlParam('oobCode', params.get('oobCode')));
}

export function notLogged() {
  // The user session has probably expired
  addAlert({ text: 'common.error.notlogged', type: 'danger' });
  bigStore.dispatch(push('/login'));
}

export function notAuthorized() {
  addAlert({ text: 'common.error.auth', type: 'danger' });
}

export function triggerResize() {
  window.dispatchEvent(new Event('resize'));
}
