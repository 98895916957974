import { useQuery, UseQueryOptions } from 'react-query';
import { QUERY_KEYS, queryClient } from '@src/query';
import { IBrandContent } from 'common';
import { getBrandContents } from './api';

export const useQueryBrandContents = (collectionId: string | undefined, config?: UseQueryOptions<IBrandContent[]>) => useQuery({
  queryKey: QUERY_KEYS.brandContentCollection(collectionId),
  queryFn: () => getBrandContents(collectionId),
  ...config,
});

export const getQueryDataBrandContents = (collectionId: string | undefined): IBrandContent[] | undefined => queryClient.getQueryData(QUERY_KEYS.brandContentCollection(collectionId));

export const setQueryDataBrandContents = (
  collectionId: string | undefined,
  data: IBrandContent[],
) => {
  const key = QUERY_KEYS.brandContentCollection(collectionId);
  queryClient.cancelQueries(key);
  return queryClient.setQueryData(key, data);
};

/**
 * Add brand content if it is not already in the query data
 */
export const addBrandContentToQueryData = (
  brandContent: IBrandContent,
  collectionId: string | undefined,
) => {
  const data = getQueryDataBrandContents(collectionId) || [];
  if (data.find((b) => b._id === brandContent._id) === undefined) {
    data.unshift(brandContent);
    setQueryDataBrandContents(collectionId, data);
  }
};
