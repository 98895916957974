import React from 'react';
import LocalizedStrings from 'react-localization';
import { ActionButton, ActionProps } from '../../../../components/ActionButton';

export function GetPasswordLinkButton(props: ActionProps) {
  return (
    <ActionButton
      {...props}
    >
      {lang.getPasswordLink}
    </ActionButton>
  );
}

const lang = new LocalizedStrings({
  en: {
    getPasswordLink: 'Send me a link',
  },
});
