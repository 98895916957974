import React from 'react';
import {SearchSuggestion, XCLUSION_SELECTIONS} from '@src/types/Filters';
import { CollectionItem } from '@src/fragments/Filters/Search/FilterItem/CollectionItem';
import { Suggestions, Props as SuggestionsProps } from '../Suggestions';
import { useRecoilState, useRecoilValue } from "recoil";
import {
  FilterState,
} from "@src/pages/Discover/components/Atoms/Atoms";
import {useQueryCollections} from "@src/queries/collections/useQueryCollections";
import {XclusionUtility} from "@src/fragments/Filters/managers/XclusionUtility";
import {onFilterChange} from "@src/fragments/Filters/utils/FilterStateUtility";
import {collectionInput, collectionExcludeState} from "@src/pages/Discover/components/Selectors/Selectors";

interface Props {
  onChange: SuggestionsProps['onChange']
  values: SearchSuggestion[]
  selectedValues: SearchSuggestion[]
  exclude: boolean
}

export const isInParam = (key: string, rawCollection: string[]) => (rawCollection || []).includes(key);

export function CollectionSuggestion() {

  const [filters, setFilters] = useRecoilState(FilterState)
  const rawCollectionFilter = XclusionUtility.getRawKeys(filters.collection || []);
  const collectionFilterInput = useRecoilValue(collectionInput)
  const matchesSearch = (display: string): boolean => collectionFilterInput === '' || display.toLowerCase().includes(collectionFilterInput.toLowerCase());
  const exclude = useRecoilValue(collectionExcludeState)
  const { data: userCollections = [] } = useQueryCollections();
  // The collections' MQL are then memoized using ReactQuery
  const allCollectionDescs = userCollections.map(({ _id, name }) => ({ key: _id, display: name }));
  const allCollectionFiltered = allCollectionDescs.filter(({ key, display }) => matchesSearch(display));
  const selectedCollectionsValues = allCollectionDescs.filter(({ key, display }) => isInParam(key, rawCollectionFilter) && matchesSearch(display)).map((val) => (
      {
        key: XclusionUtility.xcludeKey(val.key, exclude ? XCLUSION_SELECTIONS[1] : XCLUSION_SELECTIONS[0]),
        display: val.display
      }
      ));

  return (
    <Suggestions
      onChange={onFilterChange(setFilters, 'collection')}
      selectedValues={renderValues(selectedCollectionsValues)}
      values={renderValues(allCollectionFiltered)}
      exclude={exclude}
    />
  );
}

function renderValues(suggestions: SearchSuggestion[]) {
  return suggestions.map((suggestion) => ({
    key: suggestion.key,
    children: (
      <CollectionItem suggestion={suggestion} />
    ),
  }));
}
