import React from 'react';
import { style } from 'typestyle';
import ReactLocalizedString from 'react-localization';
import { MediaView } from '@src/../common/dist';
import { ADALONG_COLORS } from '@src/styles';
import * as reduxHelpers from '@src/services/helpers/redux';
import { useAddAlert } from '@src/fragments/Alerting/alertAtom';
import { useMutationDiffusion } from '@src/queries/media/useMutationDiffusion';
import { Tooltip } from '@mui/material';

export interface Props {
  media: MediaView;
}

/**
 * The diffusion icon is to be activated/deactivated by the user
 * by a simple click and allows the user to select certain medias
 * to be broadcasted in the widget through the corresponding option.
 */
export function MediaDiffusionIcon(props: Props) {
  const addAlert = useAddAlert();

  const { mutateAsync: updateDiffusion, reset: resetDiffusion } = useMutationDiffusion();

  const onDiffusionClick = () => {
    if (!props.media) {
      return;
    }
    updateDiffusion(
      {
        _id: props.media._id,
        diffusion: !(props.media.diffusion ?? false),
      },
      {
        onSuccess: () => {
          reduxHelpers.addAlert({
            text: lang.success,
            type: 'success',
          });
        },
        onError: () =>
          addAlert({
            text: lang.error,
            close: resetDiffusion,
          }),
      }
    ).catch(console.error);
  };

  return (
    <>
      <Tooltip
        title={props.media.diffusion ? lang.tooltip.disabled : lang.tooltip.enabled}
        placement='top'
      >
        <button className={buttonIconClass} onClick={onDiffusionClick}>
          <svg
            xmlns='http://www.w3.org/2000/svg'
            xmlnsXlink='http://www.w3.org/1999/xlink'
            version='1.1'
            width='18'
            height='18'
            viewBox='0 0 1000 1000'
            xmlSpace='preserve'
            fill='none'
          >
            <g transform='matrix(11.0108 0 0 11.0108 495.986 499.9983)' id='476231'>
              <path
                fill={props.media.diffusion ? ADALONG_COLORS.GREEN : ADALONG_COLORS.LIGHT_GRAY}
                style={{
                  stroke: 'none',
                  strokeWidth: 1,
                  strokeDasharray: 'none',
                  strokeLinecap: 'butt',
                  strokeDashoffset: 0,
                  strokeLinejoin: 'miter',
                  strokeMiterlimit: 4,
                  fillRule: 'nonzero',
                  opacity: 1,
                }}
                vector-effect='non-scaling-stroke'
                transform=' translate(-50, -50.0001)'
                d='M 8.98035 52.59362 V 44.41333 A 38.93582 38.93582 0 0 1 47.8739 83.311 H 39.69226 A 30.7508 30.7508 0 0 0 8.98035 52.59362 Z m 0 16.3606 A 14.3677 14.3677 0 0 1 23.329 83.311 h 8.18164 a 22.55956 22.55956 0 0 0 -22.53027 -22.537 Z M 5 82.20025 a 5.07368 5.07368 0 1 0 5.07367 -5.06579 A 5.07371 5.07371 0 0 0 5 82.20025 Z m 69.48194 -69.482 H 28.124 A 20.54159 20.54159 0 0 0 7.606 33.23626 V 37.227 h 5 V 33.23626 A 15.5357 15.5357 0 0 1 28.124 17.7182 H 74.48194 A 15.5357 15.5357 0 0 1 90 33.23626 v 31.833 A 15.52507 15.52507 0 0 1 74.49219 80.57708 H 55.08106 v 5 H 74.49219 A 20.531 20.531 0 0 0 95 65.06927 v -31.833 A 20.54159 20.54159 0 0 0 74.48194 12.7182 Z'
                stroke-linecap='round'
              />
            </g>
          </svg>
        </button>
      </Tooltip>
    </>
  );
}

export const lang = new ReactLocalizedString({
  en: {
    error: 'Unable to change diffusion status',
    success: 'Successfully updated diffusion status',
    tooltip: {
      enabled: 'Enable diffusion on this media',
      disabled: 'Disable diffusion on this media',
    },
  },
});

const buttonIconClass = style({
  cursor: 'pointer',
  background: ADALONG_COLORS.ABSOLUTE_BLACK,
  border: 'none',
});
