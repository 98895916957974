import React, { Component, createRef } from 'react';

import SvgIcon from '@mui/material/SvgIcon';
import styles from './styles.scss';

interface IProps {
  classNames?: string[]
  onFiles: (files: File[]) => any
  accept?: string[]
}

export class UploadButton extends Component<IProps> {
  private fileInputRef = createRef<HTMLInputElement>();

  public render() {
    const classes = [styles.upload, ...(this.props.classNames || [])].join(
      ' ',
    );
    return (
      <div className={classes} onClick={this.onClick.bind(this)}>
        <div>
          {/* <Icon>add</Icon> */}
          <UploadIcon className={styles.icon} />
          <input
            ref={this.fileInputRef}
            type="file"
            accept={
                            this.props.accept
                              ? this.props.accept.join(',')
                              : '*'
                        }
            multiple
            onChange={this.onChange.bind(this)}
          />
        </div>
      </div>
    );
  }

  public onChange(event: React.ChangeEvent<HTMLInputElement>) {
    const { files } = event.target;
    if (!files) {
      return;
    }

    const fileArray: File[] = [...files];
    this.props.onFiles(fileArray);
  }

  public onClick() {
    this.fileInputRef.current!.click();
  }
}

const UploadIcon = (props: any) => (
  <SvgIcon
    {...props}
    version="1.1"
    id="Layer_1"
    xmlns="http://www.w3.org/2000/svg"
    xmlnsXlink="http://www.w3.org/1999/xlink"
    x="0px"
    y="0px"
    viewBox="0 0 595.3 841.9"
    xmlSpace="preserve"
  >
    <path
      d="M376.3,518.5c0-11.3,0-21.8,0-32.9c1.9,0,3.6,0,5.3,0c13.9,0,27.8,0.1,41.8,0c34.9-0.3,64.2-27.5,66.3-61.4
			c2.3-36.5-22.5-67.4-58.2-71.7c-9.6-1.2-19.5-0.4-29.2-0.5c-1.9,0-3.8,0-6.4,0c0-7.6,0-14.7,0-21.9c0.1-12.3-1.8-24.2-6.1-35.7
			c-15.9-43.1-58.1-68.8-101.3-65.6c-49.3,3.7-86,41.8-91.8,85.5c-0.7,5.4-0.9,10.8-1,16.2c-0.2,6.9,0,13.7,0,21.5
			c-2.3,0-4.3-0.1-6.3,0c-12.4,0.6-25.2-0.3-37.2,2.2c-30.7,6.6-52.1,35.5-50.8,66.4c1.4,32.9,24.6,59.3,56.7,64.2
			c4.4,0.7,8.9,0.8,13.4,0.8c14.4,0.1,28.7,0,43.4,0c0,11,0,21.6,0,32.5c-0.6,0.1-1.2,0.4-1.8,0.4c-17.1-0.1-34.3,0.4-51.3-0.3
			c-47.4-2-88.3-41.4-92.6-88.8c-4.8-52.9,29.1-98.3,81.2-108.5c4.1-0.8,8.2-1.2,12.9-1.8c4.4-42.7,23.7-77.2,59.7-101.5
			c24.5-16.6,51.9-23.6,81.4-21.8c21.6,1.3,41.7,7.7,60.2,19c18.4,11.2,33.1,26,44.4,44.3c11.3,18.4,17.5,38.5,19.4,60.2
			c13.2,1,25.6,3.9,37.3,9.3c39.8,18.6,62.9,61.1,56.7,104.3c-6.3,43.6-40.6,78.6-84.4,84.3c-15,2-30.3,1-45.5,1.3
			C386.9,518.6,381.9,518.5,376.3,518.5z"
    />
    <path
      d="M278.1,422c-11.2,13.1-22.4,26.2-33.6,39.4c-4.1,4.9-9.2,7.5-15.6,6.5c-13.2-2-18.6-16.8-9.9-27.2
			c12.5-15,25.2-29.8,37.9-44.6c8.4-9.9,16.8-19.7,25.2-29.6c8.5-10,19.3-10,27.7-0.1c20.6,24.2,41.3,48.5,61.9,72.8
			c8.7,10.3,4.5,24.6-8.1,27.6c-6.7,1.6-12.2-0.9-16.6-6c-10.5-12.3-21-24.6-31.4-36.9c-0.8-1-1.7-1.9-3.5-3.9c0,2.8,0,4.4,0,6
			c0,63.8,0,127.7,0,191.5c0,11-7.2,18.3-17.4,17.8c-8.1-0.4-14.8-6.9-15.3-15.1c-0.1-1.8-0.1-3.5-0.1-5.3c0-62.6,0-125.1,0-187.7
			c0-1.7,0-3.4,0-5.1C278.8,422.1,278.4,422.1,278.1,422z"
    />
  </SvgIcon>
);
