import { VideoState } from '@src/types/redux/video';
import { UserView } from 'common';
import { REDUX_ACTIONS } from '../actions/_types';
import { VIDEO_ACTIONS } from '../actions/video';

const initialState: VideoState = {
  list: [],
  loading: false,
  loadingEdit: 0,
  loadedCollection: null,
  brandCollection: [],
  selected_collection: '',
  current_index: 0,
  page: 'create',
  gif: {
    _id: (undefined as any) as string,
    duration: 7,
    elements: [],
    ratio: 16 / 9,
    rendered: false,
    storedUrl: '',
    width: 1980,
    description: '',
    name: 'unnamed',
    owner: (undefined as any) as UserView,
  },
  resolvedImages: {},
};

export type IVideo = VideoState;

const reducers = {
  [REDUX_ACTIONS.GIF.EDITOR.UPDATE_GIF](
    state: VideoState,
    action: ReturnType<typeof VIDEO_ACTIONS.updateGif>,
  ): VideoState {
    return {
      ...state,
      gif: {
        ...state.gif,
        ...action.gif,
      },
    };
  },
  [REDUX_ACTIONS.GIF.ADD_BRANDCONTENT_TO_COLLECTION](
    state: IVideo,
    action: ReturnType<typeof VIDEO_ACTIONS.addBrandContentToCollection>,
  ): IVideo {
    const { brandCollection } = state;
    brandCollection.push(action.brandContent);
    return {
      ...state,
      brandCollection: [...brandCollection],
    };
  },
  [REDUX_ACTIONS.GIF.BRANDCONTENTS.STORE](
    state: IVideo,
    action: ReturnType<typeof VIDEO_ACTIONS.setBrandContents>,
  ): IVideo {
    return {
      ...state,
      brandCollection: action.brandContents.slice(),
    };
  },
  [REDUX_ACTIONS.GIF.REMOVE_BRANDCONTENT_FROM_COLLECTION](
    state: IVideo,
    action: ReturnType<
            typeof VIDEO_ACTIONS.removeBrandContentFromCollection
    >,
  ): IVideo {
    const brandCollection = state.brandCollection.filter(
      (brandelm) => brandelm._id !== action.brandContentId,
    );
    return {
      ...state,
      brandCollection,
    };
  },
  [REDUX_ACTIONS.GIF.EDITOR.SELECT_CURRENT_IMAGE](
    state: IVideo,
    action: ReturnType<typeof VIDEO_ACTIONS.selectImage>,
  ): IVideo {
    return {
      ...state,
      current_index: action.index,
    };
  },
  [REDUX_ACTIONS.GIF.EDITOR.WAS_SAVED](state: IVideo): IVideo {
    window.analytics?.trackEvent(REDUX_ACTIONS.GIF.EDITOR.WAS_SAVED, 'ReduxVideoMkaer');
    return {
      ...state,
    };
  },
  [REDUX_ACTIONS.GIF.EDITOR.WIDTH_UPDATE](
    state: IVideo,
    action: ReturnType<typeof VIDEO_ACTIONS.widthUpdate>,
  ): IVideo {
    const scale = action.width / state.gif.width;
    const elements = state.gif.elements.map((element) => {
      if (element.x) { element.x *= scale; }
      if (element.y) { element.y *= scale; }
      if (element.width) { element.width *= scale; }
      if (element.height) { element.height *= scale; }
      return { ...element };
    });
    return {
      ...state,
      gif: {
        ...state.gif,
        elements,
        width: action.width,
      },
    };
  },
  [REDUX_ACTIONS.GIF.EDITOR.EDIT_VIDEO_MAKER](
    state: IVideo,
    action: ReturnType<typeof VIDEO_ACTIONS.editVideoMaker>,
  ): IVideo {
    return {
      ...state,
      gif: { ...action.videoMaker },
      page: 'create',
      current_index: 0,
    };
  },
  [REDUX_ACTIONS.GIF.EDITOR.CHANGE_PAGE](
    state: IVideo,
    action: ReturnType<typeof VIDEO_ACTIONS.changePage>,
  ): IVideo {
    return {
      ...state,
      current_index: 0,
      page: action.page,
    };
  },
  [REDUX_ACTIONS.GIF.EDITOR.SET_RESOLVED_IMAGES](
    state: IVideo,
    action: ReturnType<typeof VIDEO_ACTIONS.setResolvedImages>,
  ): IVideo {
    return {
      ...state,
      resolvedImages: {
        ...state.resolvedImages,
        ...action.resolvedImages,
      },
    };
  },
  [REDUX_ACTIONS.GIF.SET_VIDEOMAKER_LIST](
    state: VideoState,
    action: ReturnType<typeof VIDEO_ACTIONS.setVideoMakerList>,
  ): VideoState {
    return {
      ...state,
      list: action.list,
    };
  },
  [REDUX_ACTIONS.GIF.SET_LOADING](
    state: VideoState,
    { loading }: ReturnType<typeof VIDEO_ACTIONS.setLoading>,
  ): VideoState {
    return {
      ...state,
      loading,
    };
  },
  [REDUX_ACTIONS.GIF.SET_LOADING_EDIT](
    state: VideoState,
    { loadingEdit }: ReturnType<typeof VIDEO_ACTIONS.setLoadingEdit>,
  ): VideoState {
    return {
      ...state,
      loadingEdit,
    };
  },
};

export default function (state = initialState, action: any) {
  if (!action) {
    return state;
  }
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
}
