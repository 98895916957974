import React, { PropsWithChildren } from 'react';
import { ADALONG_COLORS } from '@src/styles';
import { style } from 'typestyle';
import TextLineBreak from '../TextLineBreak';

interface Props {
  caption?: string
  style?: React.CSSProperties
  overlay?: JSX.Element,
  groupName?: string,
}

export default function TextContent({
  caption = '',
  children,
  groupName,
  style: customStyle,
}: PropsWithChildren<Props>) {
  const nodes = textToNode(caption, groupName);
  return (
    <div>
      <div>
        {children}
      </div>
      <div className={classTweetText} style={customStyle}>
        {nodes}
      </div>
    </div>
  );
}

function textToNode(caption: string, groupName?: string) {
  const regex = RegExp(/([#@][^ .,!?]+)/);
  const splitted = caption.split(regex);
  const finalText = splitted.map((str, strIndex) => {
    // highlight hashtag
    if (regex.test(str)) {
      return (
        // eslint-disable-next-line react/no-array-index-key
        <span className={classKeyword} key={strIndex}>
          {str}
        </span>
      );
    }

    // render other text with line breaks
    // eslint-disable-next-line react/no-array-index-key
    return <TextLineBreak text={str} key={strIndex} highlightText={groupName} />;
  });
  return finalText;
}

const classKeyword = style({
  color: ADALONG_COLORS.TWITTER_BLUE,
  overflowWrap: 'break-word',
});

const classTweetText = style({
  color: ADALONG_COLORS.SMOOTH_WHITE,
  fontSize: 22,
  visibility: 'visible',
  minHeight: 150,
  padding: 22,
  paddingTop: 50,
});
