import React from 'react';
import { style } from 'typestyle';
import AlternateEmail from '@mui/icons-material/AlternateEmail';
import { SearchSuggestion } from '@src/types/Filters';

interface Props {
  suggestion: SearchSuggestion
}

export function CreatorItem({
  suggestion,
}: Props) {
  return (
    <div>
      <span className={mailIconClass}>
        <AlternateEmail fontSize="inherit" />
      </span>
      <span>{suggestion.display}</span>
    </div>
  );
}

const mailIconClass = style({
  minWidth: 18,
  display: 'inline-block',
});
