import React, { Component, HTMLAttributes } from 'react';

import { style } from 'typestyle';

interface IProps extends HTMLAttributes<HTMLDivElement> {
  containerClasses: string[];
  frameClasses: string[];
  onClose: any;
}

interface IState {}

export class OverlayContainer extends Component<IProps, IState> {
  public static defaultProps = {
    frameClasses: [],
    containerClasses: [],
  };

  public render() {
    const { containerClasses, frameClasses, onClose, ...props } = this.props;

    return (
      <div className={[containerClass, ...containerClasses].join(' ')} onClick={onClose}>
        <div
          className={[frameClass, ...frameClasses].join(' ')}
          {...props}
          onClick={this.onClick.bind(this)}
        >
          {this.props.children}
        </div>
      </div>
    );
  }

  public onClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }
}

const containerClass = style({
  position: 'fixed',
  width: '100%',
  height: '100%',
  top: 0,
  left: 0,
  zIndex: 4,
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  background: 'rgba(0, 0, 0, 0.5)',
});
const frameClass = style({
  maxHeight: '94%',
  margin: 'auto',
  fontFamily: '"Public Sans", sans-serif',
  overflow: 'hidden',
  position: 'relative',
  background: '#272727',
  padding: '32px 40px',
  $nest: {
    i: {
      fontSize: 19,
    },
  },
});
