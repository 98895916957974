import React from 'react';
import { IconRender, InheritProps } from './../IconRender';

export function RoundedIcon(props: InheritProps) {
  return (
    <IconRender
      {...props}
      childrenRender={(color: string) => (
        <>
          <rect
            x='1.5'
            y='1.5'
            width='67'
            height='67'
            rx='8.5'
            fill='#343434'
            stroke={color}
            strokeWidth='4'
          />
          <path
            d='M21.3258 56.3501L21.3257 34.2646C21.3257 25.9803 28.0414 19.2646 36.3257 19.2645L56.1399 19.2645'
            stroke='white'
            strokeWidth='4'
          />
        </>
      )}
    />
  );
}
