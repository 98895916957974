import React, { useEffect, useState } from 'react';
import { DragDropContext, DropResult } from 'react-beautiful-dnd';

import { Pathname } from '@src/types/Browsing';
import { SearchAndSelectBar } from '@src/components/SearchAndSelectBar';
import { CollectionView } from 'common';
import { useQueryCollections } from '@src/queries/collections/useQueryCollections';
import NavBar from '../../components/NavBar';
import { CollectionsHeader } from './List/CollectionsListHeader';
import { PerfectScrollbar } from '@src/components/PerfectScrollbar';
import { style } from 'typestyle';
import { CollectionDragDrop } from '@src/pages/Collection/View/CollectionDragDrop';
import { Divider } from '@mui/material';
import { PageTitle } from '@src/components/PageTitle';
import { useMutationCollection } from '@src/queries/collections/useMutationCollection';
import * as reduxHelpers from '@src/services/helpers/redux';

export type SortBy = 'title' | 'date';
export const collectionCategories = ['My selection', 'AdAlong suggestions', 'Social Media', 'Website', 'CRM', 'Archive'];

export function Collections() {
  const { data: collections = [], isLoading } = useQueryCollections();
  const [sortBy, setSortBy] = useState<SortBy>('date');
  const [filter, setFilter] = useState<string>('');
  const [filteredCollections, setFilteredCollections] = useState(collections);

  const sortMethod = {
    title: (a: CollectionView, b: CollectionView) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()),
    date: (a: CollectionView, b: CollectionView) => (new Date(b.created_at) > new Date(a.created_at) ? 1 : -1),
  }[sortBy];
  // Filter collections depending on searchBar input
  const filterCollections = (): CollectionView[] => {
    if (filter.length < 1) {
      return collections;
    }
    return collections.filter((collection) => collection.name.toLowerCase().includes(filter.toLowerCase()));
  };

  const { mutateAsync: mutateCollection, error: errorUpdate, reset: resetMutateCollection } = useMutationCollection();

  const detailsSave = (collection: CollectionView, category: string) => {
    mutateCollection(
      {
        _id: collection._id,
        name: collection.name,
        description: collection.description,
        category,
      },
      {
        onSuccess: () => {
          reduxHelpers.addAlert({
            text: 'create.collection.settings.details.success',
            type: 'success',
          });
        },
        onError: () => {
          reduxHelpers.addAlert({
            text: 'Error while updating collection' + errorUpdate?.message,
            type: 'danger',
          });
        },
      }
    );
  };

  const collectionsByCategory = (collections: CollectionView[]): Map<string, string[]> => {
    const categoryMap = new Map<string, string[]>();
    collectionCategories.forEach((cat) => {
      categoryMap.set(cat, []);
    });
    return collections.sort(sortMethod).reduce((map, currentValue) => {
      const key = currentValue.category ?? 'My selection';
      if (!map.has(key)) {
        map.set(key, []);
      }
      let collectionCat = map.get(key) ?? [];
      collectionCat.push(currentValue._id);
      map.set(key, [...collectionCat]);
      return map;
    }, categoryMap);
  };
  const arrayFromMap = (collectionMap: Map<string, string[]>) => Array.from(collectionMap, ([name, list]) => ({ name, list }));

  const [collectionsList, setCollectionsList] = useState(arrayFromMap(collectionsByCategory(collections)));

  const filterById = (collections: CollectionView[], ids: string[]) =>
    collections
      .filter((c) => ids.includes(c._id))
      .sort((a, b) => {
        return ids.indexOf(a._id) < ids.indexOf(b._id) ? -1 : 1;
      });

  function onDragEnd(result: DropResult) {
    const { source, destination } = result;
    // dropped outside the list
    if (!destination) {
      return;
    }
    const sourceCat = source.droppableId;
    const destinationCat = destination.droppableId;
    if (sourceCat === destinationCat) {
      return;
    }
    let sourceCollections = collectionsList.find((cat) => cat.name === sourceCat)?.list?.slice();
    let destinationCollections = collectionsList.find((cat) => cat.name === destinationCat)?.list?.slice();
    if (!sourceCollections || !destinationCollections) {
      return;
    }
    const [removedCollection] = sourceCollections?.splice(source.index, 1);
    const collectionToUpdate = collections.find((c) => c._id === removedCollection);
    if (!collectionToUpdate) {
      return;
    }
    if (collectionToUpdate.locked) {
      reduxHelpers.addAlert({ text: 'Collection is locked!', type: 'warning' });
      return;
    }
    if (!collectionToUpdate.i_can_edit) {
      reduxHelpers.addAlert({ text: 'Need edit permissions!', type: 'warning' });
      return;
    }
    destinationCollections.splice(destination.index, 0, removedCollection);
    const newCatCollections = collectionsList.map((cat) => {
      if (cat.name === sourceCat) {
        return {
          name: cat.name,
          list: sourceCollections ?? [],
        };
      }
      if (cat.name === destinationCat) {
        return {
          name: cat.name,
          list: destinationCollections ?? [],
        };
      }
      return cat;
    });
    try {
      detailsSave(collectionToUpdate, destinationCat);
      setCollectionsList(newCatCollections);
    } catch (e) {
      return;
    }
  }

  useEffect(() => {
    setFilteredCollections(filterCollections());
  }, [filter]);

  useEffect(() => {
    setCollectionsList(arrayFromMap(collectionsByCategory(collections)));
  }, [collections]);

  useEffect(() => {
    setFilteredCollections(filteredCollections.sort(sortMethod));
    let newList = collectionsList.slice();
    newList.forEach((cat) => {
      cat.list.sort((a, b) => {
        const collectionA = filteredCollections.find((coll) => coll._id === a);
        const collectionB = filteredCollections.find((coll) => coll._id === b);
        if (!collectionA || !collectionB) {
          return 0;
        }
        const indexOfa = filteredCollections.indexOf(collectionA);
        const indexOfb = filteredCollections.indexOf(collectionB);
        if (indexOfa < indexOfb) {
          return -1;
        }
        return 1;
      });
    });
    setCollectionsList(newList);
  }, [sortBy]);

  return (
    <div id='collections' style={{ height: '100vh' }}>
      <NavBar active={Pathname.collect} />
      <div className='page-content'>
        <CollectionsHeader list={collections} sortBy={sortBy} setSortBy={setSortBy} filter={filter} setFilter={setFilter} />
        <DragDropContext onDragEnd={onDragEnd}>
          <PerfectScrollbar options={{ minScrollbarLength: 30 }} className={collectionsListClass}>
            {collectionsList.map((cat) => (
              <>
                <h2
                  style={{
                    fontWeight: 600,
                    fontSize: 18,
                    marginBottom: 5,
                    marginTop: 15,
                    marginLeft: 10,
                  }}
                >
                  {cat.name}
                </h2>
                <Divider />
                <CollectionDragDrop
                  category={cat.name}
                  collections={filterById(filteredCollections, cat.list)}
                  isLoading={isLoading}
                  sortBy={sortBy}
                />
              </>
            ))}
          </PerfectScrollbar>
        </DragDropContext>
      </div>
    </div>
  );
}

const padding = '6px';
const collectionsListClass = style({
  position: 'relative',
  overflow: 'hidden',
  padding: `0 ${padding}`,
  $nest: {
    '> div:empty': {
      $nest: {
        '&:before': {
          content: 'attr(data-empty)',
          display: 'block',
          textAlign: 'center',
          marginTop: 150,
          color: '#646464',
          fontFamily: 'Raleway, sans-serif',
          fontSize: 26,
        },
      },
    },
  },
});
