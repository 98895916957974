import { QueryClient } from "react-query";
import { ApiTypes, MediaQuery } from "common";
import { RightsRequestStatusValue } from "./components/RightsRequestStatusSelect";

const STALE_TIME = 1000 * 60 * 10;
const CACHE_TIME = 1000 * 60 * 10;

/**
 * React Query cache
 */
export const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      staleTime: STALE_TIME,
      cacheTime: CACHE_TIME,
      retry: false,
    },
  },
});

// Deprecated
const KEYS = [
  "BRAND_CONTENTS",

  "CATALOG_TAXONOMY",
  "COLLECTIONS",
  "COLLECTION_CONTENT_NUMBER",

  "MEDIA_DIMENSIONS",
  "MEDIA_PRODUCT", // find product from media aggregation

  "PRODUCT", // get product info
  "PRODUCTS", // get products info

  "WIDGET", // get a widget
  "WIDGETS", // get widgets
  "WIDGET_LOCALIZED", // get localized widget
] as const;
type Keys = typeof KEYS[number];
type QueryKeys = {
  [key in Keys]: string;
};

/**
 * Deprecated: Use PREFIX instead
 */
export const DEPRECATED_QUERY_KEYS: QueryKeys = KEYS.reduce(
  (prev, curr) => ({ ...prev, [curr]: curr }),
  {}
) as QueryKeys;

// Use PREFIX to define unique query keys
const PREFIX = [
  "BLACKLISTED_CREATORS",
  "BRAND_CONTENTS",
  "COLLECTION",
  "COLLECTION_MEDIA",
  "CONVERSATIONS",
  "CONVERSATION_MESSAGES",
  "INFLUENCERS",
  "FACEBOOK_PAGES",
  "FACEBOOK_AD_ACCOUNTS",
  "INSTAGRAM_PAGES",
  "MEDIA",
  "MEDIA_OBJECT_LABELS",
  "MEDIA_FIND",
  "MEDIA_LINKED_TO",
  "MEDIA_RIGHTS_REQUEST",
  "MEDIA_SIMILAR",
  "MEDIA_CAROUSEL",
  "PRODUCT_GROUPS",
  "PRODUCTS_AVAILABLES_LOCALIZATIONS",
  "REMOTE_CONVERSATIONS",
  "STATISTICS",
  "USER_RIGHTS_TEMPLATE",
  "USER_CONFIG",
  "USER_GROUP_MENTION_PAGES",
  "USER_META_PERMISSION",
  "COUNT_RIGHTS_REQUEST",
  "RIGHTS_REQUEST",
] as const;
type Prefix = typeof PREFIX[number];
type QueryPrefix = {
  [key in Prefix]: string;
};
export const QUERY_PREFIX: QueryPrefix = PREFIX.reduce(
  (prev, curr) => ({ ...prev, [curr]: curr }),
  {}
) as QueryPrefix;

/**
 * Define ways to get unique query keys for React queries
 */
export const QUERY_KEYS = {
  brandContentCollection: (collectionId: string | undefined) => [
    QUERY_PREFIX.BRAND_CONTENTS,
    collectionId,
  ],

  collection: (collectionId: string) => [QUERY_PREFIX.COLLECTION, collectionId],
  collectionsMedia: () => [QUERY_PREFIX.COLLECTION_MEDIA],
  collectionMedia: (
    collectionId?: string,
    params: {
      token?: string;
      rightsStatus?: RightsRequestStatusValue;
    } = {}
  ) => [QUERY_PREFIX.COLLECTION_MEDIA, collectionId, params],
  conversations: () => [QUERY_PREFIX.CONVERSATIONS],
  remoteConversations: () => [QUERY_PREFIX.REMOTE_CONVERSATIONS],
  conversationMessages: (conversationId: string) => [
    QUERY_PREFIX.CONVERSATION_MESSAGES,
    conversationId,
  ],

  influencers: (influencerId?: string) => [
    QUERY_PREFIX.INFLUENCERS,
    influencerId,
  ],
  blacklistedCreators: () => [
    QUERY_PREFIX.BLACKLISTED_CREATORS,
  ],

  facebookPages: () => [QUERY_PREFIX.FACEBOOK_PAGES],
  facebookAdAccounts: () => [QUERY_PREFIX.FACEBOOK_AD_ACCOUNTS],
  instagramPages: () => [QUERY_PREFIX.INSTAGRAM_PAGES],
  metaPermission: () => [QUERY_PREFIX.USER_META_PERMISSION],

  media: (mediaId?: string) => [QUERY_PREFIX.MEDIA, mediaId],
  mediaObjectLabels: (mediaId: string) => [
    QUERY_PREFIX.MEDIA_OBJECT_LABELS,
    mediaId,
  ],
  mediaFind: () => [QUERY_PREFIX.MEDIA_FIND],
  mediaFindParams: (params: Omit<MediaQuery, "cursor">) => [
    QUERY_PREFIX.MEDIA_FIND,
    params,
  ],
  mediaRightsRequest: (mediaId: string) => [
    QUERY_PREFIX.MEDIA_RIGHTS_REQUEST,
    mediaId,
  ],
  countRightsRequest: () => [QUERY_PREFIX.COUNT_RIGHTS_REQUEST],
  rightsRequest: () => [QUERY_PREFIX.RIGHTS_REQUEST],

  productGroups: () => QUERY_PREFIX.PRODUCT_GROUPS,
  productGroupsParams: (
    params: Pick<
      ApiTypes.ParamsGetProductGroups,
      "productIds" | "productTypes" | "text" | "withMedia" | "sortOrder" | "catalogCountries" | "applyLocalization"
    >
  ) => [QUERY_KEYS.productGroups(), params],
  productsAvailableLocalizations: () =>
    QUERY_PREFIX.PRODUCTS_AVAILABLES_LOCALIZATIONS,

  statistics: () => [QUERY_PREFIX.STATISTICS],

  userRightsTemplate: () => [QUERY_PREFIX.USER_RIGHTS_TEMPLATE],
  userConfig: () => [QUERY_PREFIX.USER_CONFIG],
  userGroupMentionPages: () => [QUERY_PREFIX.USER_GROUP_MENTION_PAGES],

};
