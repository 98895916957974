import React, { useState } from 'react';
import { OverlayContainer } from 'components/OverlayContainer';
import { FormLine } from '@src/components/Form/FormLine';
import { TextArea } from 'components/TextArea';
import { Input } from 'components/Input';
import { FormTitle } from '@src/components/Form/FormTitle';

import Button from '@mui/material/Button';
import { FormButtons } from '@src/components/Form/FormButtons';
import LocalizedStrings from 'react-localization';
import { style, classes } from 'typestyle';
import { useSelector } from 'react-redux';
import { ICombinedReducers } from '@src/types/redux';
import { useLocationQS } from '@src/utils/useLocationQS';
import { Loader } from '@src/components/Loader';
import { CloseButton } from '@src/components/CloseButton';
import { Error } from './Error';
import { useMutationCreateWidget } from '../../queries/widgets/useMutationCreateWidget';
import { ModalCloseButtonPosition } from '../../components/Modals/CloseButtonPosition';

interface Props {
  onClose(): void
}

export function WidgetCreate(props: Props) {
  const user = useSelector((state: ICombinedReducers) => state.common.user);
  const productFeature = !!(user?.groupFeatures.product_catalog);

  const [name, setName] = useState('');
  const [description, setDescription] = useState('');

  const { source: qsSource } = useLocationQS(['source']);
  const sourceType = qsSource[0] === 'collection' || !productFeature ? 'collection' : 'product' as const;

  const {
    mutate: createWidget,
    error,
    isLoading: submiting,
  } = useMutationCreateWidget();

  const submit = async () => {
    createWidget({
      name,
      description,
      sourceType,
    }, {
      onSuccess: () => {
        props.onClose();
      },
    });
  };

  return (
    <OverlayContainer
      style={{ minWidth: 450, padding: 35 }}
      onClose={props.onClose}
    >
      <ModalCloseButtonPosition>
        <CloseButton onClick={props.onClose} />
      </ModalCloseButtonPosition>
      <FormTitle value={lang.title} />
      <FormLine title={lang.name}>
        <Input
          onChange={({ target }) => setName(target.value)}
          placeholder={lang.name}
        />
      </FormLine>
      <FormLine title={lang.description}>
        <TextArea
          placeholder={lang.description}
          onChange={({ target }) => setDescription(target.value)}
          maxLength={1000}
        />
      </FormLine>
      <Error error={error} />
      <FormButtons className={buttonsClass}>
        <Button
          className={classes('button button-border')}
          onClick={props.onClose}
        >
          {lang.close.toUpperCase()}
        </Button>
        <Button
          className={classes('button', 'button-blue')}
          onClick={submit}
        >
          {lang.submit.toUpperCase()}
          {submiting ? (
            <div className={loadingClass}>
              <Loader size={23} />
            </div>
          ) : null}
        </Button>
      </FormButtons>
    </OverlayContainer>
  );
}

const lang = new LocalizedStrings({
  en: {
    title: 'Create a widget',
    name: 'Widget Name',
    description: 'Description',
    type: 'Source type',
    close: 'Cancel',
    submit: 'Create',
    errors: {
      unknown: 'An error occured',
    },
  },
});

const buttonsClass = style({
  position: 'relative',
  $nest: {
    '& > button': {
      flex: 1,
    },
    '& > button:first-of-type': {
      marginRight: 50,
    },
  },
});

const loadingClass = style({
  position: 'absolute',
  right: 13,
  top: 5,
});
