import React from 'react';
import { InfoInsert } from '@src/types/VideoMaker';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  info: InfoInsert
  onDragStart?: (e: React.DragEvent<HTMLDivElement>) => void
}

export default function Draggable({
  onDragStart,
  ...props
}: Props) {
  const dragStartHandler = (e: React.DragEvent<HTMLDivElement>) => {
    const curTarget = e.currentTarget;
    curTarget && e.dataTransfer.setDragImage(curTarget, 75, 75);
    e.dataTransfer.setData('text', JSON.stringify(props.info));
    if (onDragStart) {
      onDragStart(e);
    }
  };

  return (
    <div
      className={props.className}
      onDragStart={dragStartHandler}
      {...props}
    >
      {props.children}
    </div>
  );
}
