import React, { useState, useEffect } from 'react';

import { Category } from '@adalong/schemas';
import { styled } from '@mui/system/';
import ArrowRightIcon from '@mui/icons-material/ArrowRight';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import { style } from 'typestyle';
import { ThemeStyle, ADALONG_COLORS } from '@src/styles';
import { color } from 'csx';
import LocalizedStrings from 'react-localization';
import { ArrayHelper } from '@src/services/helpers/array';
import { SimpleTreeView, TreeItem, TreeItemProps } from '@mui/x-tree-view';
import {
  CategoryWithParent, getTaxonomyWithParents, toFlatTaxonomy, categoryToPath, equalPaths, findTextFilterIndex, applyTextFilter,
} from '../utils/taxonomy';
import { BarelyVisibleText } from '../../BarelyVisibleText';
import { Row } from '../../Utils';
import { CATEGORY_SEPARATOR } from '@src/config/product';
import { ProductGroup } from "common/dist/types/apiTypes";

interface Props {
  taxonomy?: Category
  textFilter?: string
  groups?: ProductGroup[]
  selectedCategoryPath: string[]
  onChangeCategoryPath: (categoryPath: string[]) => void
}

const StyledTreeView = styled(SimpleTreeView)({
  marginTop: 10,
  flexGrow: 1,
  maxWidth: 400,
});

/**
 * Display the catalog taxonomy as an expandable tree
 */
export function TaxonomyTree({
  taxonomy,
  textFilter,
  groups,
  selectedCategoryPath,
  onChangeCategoryPath,
}: Props) {
  const [prevExpanded, setPrevExpanded] = useState<string[] | null>(null); // keep expanded before text filtering
  const [expanded, setExpanded] = useState<string[]>([]);
  const [initialGroups, setInitialGroups] = useState(groups);
  const allCategoryText = 'All categories';

  useEffect(() => {
    if (!selectedCategoryPath.length) {
      setInitialGroups(groups);
    }
  }, [groups]);

  // keep taxonomy locally to avoid re filtering on every render
  const [filteredTaxonomy, setFilteredTaxonomy] = useState<CategoryWithParent[] | null>(null);
  useEffect(() => {
    if (!taxonomy) {
      return setFilteredTaxonomy(null);
    }
    const taxonomyWithParents = getTaxonomyWithParents(taxonomy, null);
    const filtered = applyTextFilter(taxonomyWithParents.children, textFilter) || [];
    filtered.sort((a, b) => a.displayName > b.displayName ? 1 : -1)
    setFilteredTaxonomy(filtered);
    if (textFilter) {
      // open all to show results matching
      if (prevExpanded === null) {
        setPrevExpanded(expanded);
      }
      setExpanded(
        toFlatTaxonomy(filtered).map((c) => categoryToPath(c).map((c) => c.name).join(CATEGORY_SEPARATOR), // full path node
          // eslint-disable-next-line function-paren-newline
        ),
      );
    } else if (prevExpanded !== null) {
      setExpanded(prevExpanded);
      setPrevExpanded(null);
    }
  }, [taxonomy, textFilter]);

  if (!filteredTaxonomy || filteredTaxonomy.length === 0) {
    return (
      <Row className={infoMessageClass}>
        <BarelyVisibleText size="medium">{lang.noCategories}</BarelyVisibleText>
      </Row>
    );
  }

  return (
    <Row>
      <StyledTreeView
        slots={{
          collapseIcon: ArrowDropDownIcon,
          expandIcon: ArrowRightIcon
        }}
        onExpandedItemsChange={handleToggle}
        expandedItems={expanded}
        selectedItems={selectedCategoryPath.join(CATEGORY_SEPARATOR)}
      >
        <div
          className={labelClass}
        >
          {allCategoryText}
          <div
            className={productStatClass}
          >
            {`\xa0 ${totalProductsCount(filteredTaxonomy)} \xa0|`}
          </div>
          <div
            className={mediaCoverageClass}
          >
            {`${mediaCoverage(initialGroups)}%`}
          </div>
        </div>
        {categoriesToComponentTree(filteredTaxonomy, textFilter || '')}
      </StyledTreeView>
    </Row>
  );

  function calculateCategoryTotalProducts(cat: CategoryWithParent): number {
    let totalProducts = 0;
    if (cat.children.length > 0) {
      cat.children.forEach((child) => {
        totalProducts += calculateCategoryTotalProducts(child);
      });
    }
    totalProducts += cat.totalProducts ?? 0;
    return totalProducts;
  }

  function totalProductsCount(categoryList: CategoryWithParent[]): number {
    let total = 0;
    categoryList.forEach((cat) => {
      total += calculateCategoryTotalProducts(cat);
    });
    return total;
  }

  function totalUniqueLinking(group: ProductGroup): [number, number] {
    let totalUnique = 0;
    let numberofProducts = group.products.length;
    group.products.forEach( (p) => {
      if (p.product_media_count > 0) {
        totalUnique++
      }
    })
    return [totalUnique, numberofProducts];
  }

  function mediaCoverage(groups: ProductGroup[] | undefined): number {
    if (!groups) {
      return 0;
    }
    let totalUniqueMedia = 0;
    let totalNumberOfProducts = 0;
    groups.forEach( (group) => {
      const countResult = totalUniqueLinking(group);
      totalUniqueMedia += countResult[0];
      totalNumberOfProducts += countResult[1];
    })
    if (isNaN(totalUniqueMedia / totalNumberOfProducts)) {
      return 0
    }
    const num =  (totalUniqueMedia / totalNumberOfProducts) * 100;
    return Math.round((num + Number.EPSILON) * 100) / 100;
  }

  function handleItemClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>, nodeId: string) {
    e.stopPropagation();
    const path = nodeId.split(CATEGORY_SEPARATOR);
    if (path) {
      if (!equalPaths(selectedCategoryPath, path)) {
        onChangeCategoryPath(path);
        const exp = path.map((p, i) => path.slice(0, i + 1).join(CATEGORY_SEPARATOR));
        setExpanded(ArrayHelper.uniqBy([...expanded, ...exp]));
        if (prevExpanded) {
          setPrevExpanded(ArrayHelper.uniqBy([...prevExpanded, ...exp]));
        }
      } else {
        onChangeCategoryPath([]);
      }
    }
  }

  function handleToggle(e: React.ChangeEvent<{}>, nodeIds: string[]) {
    setExpanded(nodeIds);
  }

  function StyledTreeItem({
    children,
    label,
    category,
    itemId,
    ...props
  }: TreeItemProps & { category: CategoryWithParent }) {
    const path = itemId.split(CATEGORY_SEPARATOR);
    const filterGroups = (group: ProductGroup) => {
      return group.products.find((p) => {
        const typeList = p.product_type?.split(CATEGORY_SEPARATOR) ?? [];
        return typeList.find((type) => path.includes(type))
            && (typeList.length > path.length
                || typeList[typeList.length - 1] === path[path.length - 1])
          }
      );
    }
    const filteredGroups = groups?.filter(filterGroups);

    return (
      <TreeItem
        label={(
          <span>
            <div
              className={labelClass}
              onClick={(e) => handleItemClick(e, itemId)}
            >
              {label}
            </div>
            <div
              className={productStatClass}
            >
              {`\xa0 ${calculateCategoryTotalProducts(category)} \xa0|`}
            </div>
                  <div
                      className={mediaCoverageClass}
                  >
              {`${mediaCoverage(filteredGroups)}%`}
            </div>
          </span>
        )}
        classes={{
          root: rootLabelClass,
        }}
        itemId={itemId}
        {...props}
      >
        {children}
      </TreeItem>
    );
  }

  function categoriesToComponentTree(
    categories: CategoryWithParent[],
    textFilter: string,
  ): JSX.Element[] {
    return categories.map((cat) => {
      let children: null | ReturnType<typeof categoriesToComponentTree> = null;
      if (cat.children) {
        children = categoriesToComponentTree(cat.children, textFilter);
      }
      let label: string | JSX.Element[] = cat.displayName;
      if (textFilter) {
        const i = findTextFilterIndex(label, textFilter);
        if (i !== -1) {
          label = [
            <span key="0">{label.slice(0, i)}</span>,
            <span key="1" className={hightlightLabelClass}>
              {label.slice(i, i + textFilter.length)}
            </span>,
            <span key="2">{label.slice(i + textFilter.length)}</span>,
          ];
        }
      }
      const path = categoryToPath(cat).map((c) => c.name).join(CATEGORY_SEPARATOR);
      return (
        <StyledTreeItem
          key={path}
          data-path={path}
          label={label}
          category={cat}
          itemId={path}>
          {children}
        </StyledTreeItem>
      );
    });
  }
}

const lang = new LocalizedStrings({
  en: {
    noCategories: 'No categories',
  },
});

const labelClass = style({
  display: 'inline-block',
  fontSize: ThemeStyle.textSizes.medium,
  color: ADALONG_COLORS.LIGHT_GRAY,
  fontFamily: ThemeStyle.fonts.primary,
  fontWeight: 400,
  lineHeight: 1.5,
  $nest: {
    '&:hover': {
      color: color(ADALONG_COLORS.LIGHT_GRAY).lighten(0.1).toHexString(),
    },
  },
});

const productStatClass = style({
  display: 'inline-block',
  fontSize: ThemeStyle.textSizes.small,
  color: ADALONG_COLORS.LIGHT_GRAY,
  marginLeft: 10,
  marginRight: 10,
  fontFamily: ThemeStyle.fonts.primary,
  fontWeight: 300,
  lineHeight: 1.5,
  textAlign: 'center',
});

const mediaCoverageClass = style({
  display: 'inline-block',
  fontSize: ThemeStyle.textSizes.small,
  color: ADALONG_COLORS.LIGHT_WHITE,
  fontFamily: ThemeStyle.fonts.primary,
  fontWeight: 800,
  lineHeight: 1.5,
  $nest: {
    '&:hover': {
      color: color(ADALONG_COLORS.LIGHT_YELLOW).lighten(0.1).toHexString(),
    },
  },
});

const infoMessageClass = style({
  marginTop: 20,
  justifyContent: 'center',
});

const rootLabelClass = style({
  $nest: {
    '& > .MuiTreeItem-content': {
      marginBottom: 6,
    },
    '&.Mui-selected > .MuiTreeItem-content .MuiTreeItem-label': {
      background: 'transparent !important',
      $nest: {
        [`.${labelClass}`]: {
          color: `${ADALONG_COLORS.LIGHT_WHITE} !important`,
          fontWeight: 600,
        },
      },
    },
  },
});

const hightlightLabelClass = style({
  fontWeight: 600,
  color: color(ADALONG_COLORS.LIGHT_GRAY).lighten(0.1).toHexString(),
});