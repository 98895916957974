import { WidgetView } from 'common';

export type SourceType = 'collection' | 'product';

export function getSourceType(widget: WidgetView): SourceType {
  const hasProductList = Array.isArray(widget.settings.default_sources?.product_list);
  const hasCollectionList = Array.isArray(widget.settings.default_sources?.collection_list);

  // If there is a product list, it's a product widget (even if there is also a collection list)
  if (hasProductList) {
    return 'product';
  }

  if (hasCollectionList) {
    return 'collection';
  }

  throw new Error('Widget does not have valid source lists');
}
