import { useMutation } from 'react-query';
import { ApiTypes, WidgetView } from 'common';
import { DEPRECATED_QUERY_KEYS, queryClient } from '@src/query';

import { ApiError } from '@src/services/api';
import { updateWidget } from './api';

export const useMutationWidget = () =>
  useMutation<
    WidgetView,
    ApiError,
    {
      id: string;
      widget: ApiTypes.WidgetUpdate;
    }
  >(({ id, widget }) => updateWidget(id, widget), {
    onSuccess: async (widget) => {
      const widgetId = [DEPRECATED_QUERY_KEYS.WIDGET, widget._id];
      queryClient.cancelQueries(widgetId);
      queryClient.setQueryData(widgetId, widget);
      const widgets = [DEPRECATED_QUERY_KEYS.WIDGETS];
      queryClient.invalidateQueries(widgets);
      return widget;
    },
  });
