import React from 'react';
import { LayoutIcon, InheritProps } from './Layout';

export function MosaicIcon(props: InheritProps) {
  return (
    <LayoutIcon
      {...props}
      childrenRender={(color: string) => (
        <svg
          width='100%'
          height='100%'
          viewBox='0 0 122 122'
          fill='none'
          xmlns='http://www.w3.org/2000/svg'
          preserveAspectRatio='xMidYMid meet'
        >
          <rect
            x='2'
            y='2'
            width='118'
            height='118'
            rx='8'
            fill='#343434'
            stroke={color}
            strokeWidth='4'
          />
          <rect x='13' y='14' width='62' height='59' rx='5' fill='#D9D9D9' />
          <rect x='81' y='14' width='28' height='28' rx='5' fill='#D9D9D9' />
          <rect x='81' y='47' width='28' height='28' rx='5' fill='#D9D9D9' />
          <rect x='13' y='80' width='28' height='28' rx='5' fill='#D9D9D9' />
          <rect x='47' y='80' width='28' height='28' rx='5' fill='#D9D9D9' />
          <rect x='81' y='80' width='28' height='28' rx='5' fill='#D9D9D9' />
        </svg>
      )}
    />
  );
}
