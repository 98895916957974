import React, { Component } from 'react';

import { IBrandContent, CollectionView } from 'common';
import { BrandContent } from '../BrandContent';
import { UploadButton } from './components/UploadButton';

import { UploadingFile, IUploadingFile } from './components/UploadingFile';
import page from './styles.scss';

interface IProps {
  content: {
    brandcontents: IBrandContent[]
  }
  canEdit?: boolean
  uploadingFiles: IUploadingFile[]
  collection?: CollectionView
  onUpload: (files: File[]) => any
}

interface IState {

}

export class Upload extends Component<IProps, IState> {
  public render() {
    return (
      <div className="collection-content">
        <div className={page.collectionBrandassets}>
          {this.props.content.brandcontents.map((content: IBrandContent) => (
            <BrandContent
              content={content}
              key={content._id}
            />
          ))}
          {this.props.uploadingFiles.map((file) => (
            <UploadingFile file={file} key={file.id} />
          ))}
          {!this.props.collection || this.props.collection.i_can_edit ? (
            <UploadButton
              onFiles={this.onUpload.bind(this)}
              accept={['video/*', 'image/*']}
            />
          ) : null}
          <div style={{ display: 'flex', width: 135, margin: '2%' }}>&nbsp;</div>
        </div>
      </div>
    );
  }

  public onUpload(files: File[]) {
    if (this.props.onUpload && files.length) {
      this.props.onUpload(files);
    }
  }
}
