import React, { forwardRef, TextareaHTMLAttributes } from 'react';
import { classes, stylesheet } from 'typestyle';

export const TextArea = forwardRef<HTMLTextAreaElement, TextareaHTMLAttributes<HTMLTextAreaElement>>(
  ({
    className,
    ...props
  }, ref) => (
    <textarea
      ref={ref}
      className={classes(sheet.textarea, className)}
      {...props}
    />
  ),
);

const sheet = stylesheet({
  textarea: {
    background: 'transparent',
    color: 'white',
    borderRadius: 5,
    letterSpacing: '0.7px',
    padding: '8px 5px',
    width: '100%',
    border: '2px solid',
    outline: 'none',
    transition: 'border-color 0.2s',
    minHeight: 45,
    $nest: {
      '&:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0px 1000px #272727 inset',
        '-webkit-text-fill-color': '#ffd17b',
      },
      '&:focus': {
        borderColor: '#89c1ec',
      },
      '&[disabled]': {
        borderColor: 'gray',
      },
    },
  },
});
