import { PathLocation, QsFields } from '@src/types/Browsing';

export const VALUE_SEPARATOR = ',';

/**
 * Apply a changes to the query string
 * @param location Current location
 * @param changes Changes to apply
 * @returns the new location
 */
export function setQsParam(
  location: PathLocation,
  changes: {
    field: QsFields
    val?: string | string[]
  }[],
): PathLocation {
  const searchParams = new URLSearchParams(location.search);
  changes.forEach(({
    field,
    val,
  }) => {
    if (val && val.length > 0) {
      const valStr = Array.isArray(val) ? val.join(VALUE_SEPARATOR) : val;
      searchParams.set(field, valStr);
    } else {
      searchParams.delete(field);
    }
  });
  const qs = searchParams.toString();
  return {
    pathname: location.pathname,
    search: qs ? `?${qs}` : '',
  };
}

export function pathLocationToString(location: PathLocation): string {
  return location.pathname + location.search;
}

/**
 * Add value to a query string
 */
export function addQsParam({
  location,
  key,
  currentValue,
  val,
}: {
  location: PathLocation
  key: QsFields
  currentValue: string[]
  val: string
}): string {
  const changes: Parameters<typeof setQsParam>[1] = [];
  if (!currentValue.includes(val)) {
    changes.push({
      field: key,
      val: [...currentValue, val],
    });
  }
  return pathLocationToString(setQsParam(location, changes));
}

/**
 * Remove a value from the query string
 */
export function removeQsParam({
  location,
  key,
  currentValue,
  val,
}: {
  location: PathLocation
  key: QsFields
  currentValue: string[]
  val: string
}): string {
  const changes: Parameters<typeof setQsParam>[1] = [];
  const index = currentValue.indexOf(val);
  if (index > -1) {
    const values = [...currentValue];
    values.splice(index, 1);
    changes.push({
      field: key,
      val: values.length > 0 ? values : undefined,
    });
  }
  return pathLocationToString(setQsParam(location, changes));
}
