import { Call } from '@src/services/api';
import { ApiTypes, CollectionView } from 'common';

export const getCollections = async (): Promise<ApiTypes.ResGetCollections> => {
  const res = await Call.get<ApiTypes.ResGetCollections>('/api/collection/all');
  return res || [];
};

export const getCollectionContentNumber = (collectionId: string): Promise<number> => Call.get('/api/collection/contentnumber', { collectionId });

export const getCollection = (collectionId: string, includeQuery: boolean, token?: string): Promise<ApiTypes.ResGetCollection> => {
  const data = {
    collectionId,
    includeQuery,
    token: token || null,
  };
  return Call.get<ApiTypes.ResGetCollection, typeof data>('/api/collection/one', data);
};

export const getCollectionMedias = (collectionId: string, filters: any, token?: string): Promise<ApiTypes.ResGetCollectionMedias> => {
  const data = { collectionId, ...filters, token };
  return Call.get('/api/collection/medias', data);
};

export const setCollectionUserPermissions = (data: {
  collection: string // collectionId
  access: boolean
  edit: boolean
  user: string
}): Promise<ApiTypes.ResSetCollectionUserPermissions> => Call.post<ApiTypes.ResSetCollectionUserPermissions, typeof data>('/api/collection/setuserpermissions', data);

// TODO: convert this endpoint in a true update collection endpoint (REST)
export const updateCollection = (collection: Pick<CollectionView, '_id' | 'name' | 'description' | 'category'>) => Call.post<CollectionView, {
  collectionId: string
  desc: string
  name: string
  category?: string
}>('/api/collection/editdetails', {
  collectionId: collection._id,
  desc: collection.description,
  name: collection.name,
  category: collection.category ?? undefined
});

export const lockCollection = (collection: Pick<CollectionView, '_id' | 'locked'>) => Call.post<CollectionView, {
  collectionId: string
  locked: boolean
}>('/api/collection/lock', {
  collectionId: collection._id,
  locked: !!collection.locked,
});

export const addMediasToCollection = (data: ApiTypes.ParamsAddMediaToCollection): Promise<ApiTypes.ResAddMediaToCollection> => Call.post<ApiTypes.ResAddMediaToCollection, typeof data>('/api/collection/addmedias', data);

export const removeFromCollection = (data: ApiTypes.ParamsRemoveMediaFromCollection): Promise<ApiTypes.ResRemoveMediaFromCollection> => Call.post<ApiTypes.ResRemoveMediaFromCollection, typeof data>('/api/collection/removemedias', data);

export const createCollection = (data: { name: string; desc: string; category: string | undefined; }): Promise<CollectionView> => Call.post<CollectionView, typeof data>('/api/collection/create', data);

export const deleteCollection = (id: string) => Call.post('/api/collection/delete', { collection: id });
export const duplicate = (collection: string): Promise<ApiTypes.ResDuplicateCollection> => {
  const data = { collection };
  return Call.post<ApiTypes.ResDuplicateCollection, typeof data>('/api/collection/duplicate', data);
};
