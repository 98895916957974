import React, { Component, ReactNode } from 'react';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import CreateIcon from '@mui/icons-material/Create';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import styles from './styles.scss';

interface IProps {
  children?: ReactNode
  onClick?: (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  classToApply: string
  iconToApply: '' | 'modify' | 'add' | 'remove'
}

export class CollectionContentOverlay extends Component<IProps, {}> {
  public render() {
    return (
      <div className={styles.overlay + this.props.classToApply} onClick={this.props.onClick}>
        <div>
          {this.props.iconToApply === 'modify' && <CreateIcon />}
          {this.props.iconToApply === 'add' && <AddCircleIcon />}
          {this.props.iconToApply === 'remove' && <RemoveCircleIcon />}
          {this.props.iconToApply === '' && ''}
        </div>
        {this.props.children}
      </div>
    );
  }
}
