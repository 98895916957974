import { MediaView } from 'common';

/**
 * Remove product ids from all the
 * media object labels
 */
export function removeProductIdsFromObjectLabels(productIds: string[], media: MediaView, type: string): MediaView {
  const updatedMedia = { ...media };
  if (type === 'exact') {
    updatedMedia.productIdsExact = media.productIdsExact?.filter((productId) => !productIds.includes(productId)) || [];
  } else {
    updatedMedia.productIdsSimilar = media.productIdsSimilar?.filter((productId) => !productIds.includes(productId)) || [];
  }

  return updatedMedia;
}
