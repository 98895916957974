import React, { useState } from 'react';

import { TextArea } from '@src/components/TextArea';
import { FacebookPageInterface } from '@src/types/Business';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { style } from 'typestyle';
import { ADALONG_COLORS } from '@src/styles';
import { DropDown } from '../../../../../../components/DropDown';

interface IProps {
  creator?: string;
  disable: boolean;
  pages: any[];
  selectedPage?: FacebookPageInterface;
  title: string;
  message: string;
  onTitleChange: (title: string) => void;
  selectPage: (page: FacebookPageInterface) => void;
  onMessageChange: (val: string) => void;
  isVideo: boolean;
  missingSharePerm: boolean;
}

export default function FacebookPage(props: IProps): JSX.Element | null {
  const [displayDropdown, setdisplayDropdown] = useState<boolean>(false);

  let pageSelectionTitle = window
    .T('share.popup.facebookpage.subtitles.select_placeholder')
    .toUpperCase();
  if (props.selectedPage) {
    pageSelectionTitle = props.selectedPage.name;
  }

  return (
    <div className='content' onClick={onFrameClick}>
      <div className={`line ${props.disable || props.missingSharePerm ? 'disabled-line' : ''}`}>
        <div className='title'>{window.T('share.popup.facebookpage.subtitles.select')}</div>
        <div className='field'>
          <DropDown
            title={pageSelectionTitle}
            display={displayDropdown}
            onDisplay={setdisplayDropdown}
          >
            {props.pages.map((page, i) => (
              <div
                className='selection'
                key={i}
                data-selected={page.id === props.selectedPage?.id}
                onClick={(e) => selectPage(e, page)}
              >
                {page.name}
              </div>
            ))}
          </DropDown>
        </div>
      </div>
      {props.isVideo ? (
        <div className='line'>
          <div className='title'>{window.T('share.popup.facebookpage.subtitles.title')}</div>
          <div className='field'>
            <input
              className='input'
              placeholder={window.T('share.popup.facebookpage.subtitles.title_placeholder')}
              value={props.title}
              onChange={(e) => props.onTitleChange(e.target.value)}
            />
          </div>
        </div>
      ) : null}
      <div className='line'>
        <div className={flexContainerClass}>
          <div className={idCommentClass}>
            {window.T('share.popup.facebookpage.subtitles.comment')}
          </div>
          {props.creator ? (
            <Button className={creatorClass} onClick={() => addCreator()}>
              <AddIcon className={iconClass} />
              Mention Creator
            </Button>
          ) : null}
        </div>
        <TextArea
          id='textarea'
          value={props.message}
          placeholder={window.T('share.popup.facebookpage.subtitles.comment_placeholder')}
          onChange={({ target }) => props.onMessageChange(target.value)}
          maxLength={1000}
        />
      </div>
    </div>
  );

  function selectPage(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    page: FacebookPageInterface,
  ) {
    e.stopPropagation();
    props.selectPage(page);
    setdisplayDropdown(false);
  }

  function onFrameClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    setdisplayDropdown(false);
  }

  function addCreator() {
    props.onMessageChange(`${props.message}@${props.creator}`);
  }
}

const idCommentClass = style({
  verticalAlign: 'middle',
  alignSelf: 'center',
  flex: 1,
});

const creatorClass = style({
  border: '2px solid #464646',
  color: `${ADALONG_COLORS.SMOOTH_WHITE} !important`,
  backgroundColor: '#464646',
  borderColor: 'transparent',
  fontFamily: '"Public Sans", sans-serif',
  padding: 1,
  fontSize: 12,
  letterSpacing: '0.7px',
  alignSelf: 'center',
  textAlignLast: 'right',
  marginLeft: 'auto',
});

const flexContainerClass = style({
  display: 'flex',
  marginBottom: 10,
});

const iconClass = style({
  fontSize: 15,
});
