import { Browsing, PathLocation, QsNames } from '@src/types/Browsing';
import {
  SearchBarSuggestions, SearchMarkDef, SearchTheme, SEARCH_MARK_TYPES, SuggestionsDef, SearchMarkTypes, KeyCode, SearchState,
} from '@src/types/Search';
import React, { useEffect, useRef, useState } from 'react';
import { addQsParam, removeQsParam } from '@src/utils/browsing';
import { Search } from './Search';
import { SearchUtility } from './manager/SearchUtility';
import { searchClear, SearchQsKey } from './utils/searchRoute';
import { SuggestionsMove } from './manager/SuggestionsMove';
import { XclusionUtility } from '../Filters/managers/XclusionUtility';
import { useRecoilState } from 'recoil';
import { FilterState } from '@src/pages/Discover/components/Atoms/Atoms';

const MARK_TYPE_TO_QS_NAME: { [key in SearchMarkTypes]: SearchQsKey } = {
  text: QsNames.discoverSearchText,
  hashtags: QsNames.discoverSearchHashtags,
  labels: QsNames.discoverSearchLabels,
};

interface Props {
  qsValues: { [key in SearchMarkTypes]: string[] }
  pathLocation: PathLocation
  services: {
    browsing: Browsing
  }
  state: SearchState
  theme: SearchTheme
}

export function SearchController({
  state,
  ...props
}: Props) {
  const [highligthedSuggestion, setHighligthedSuggestion] = useState<SearchMarkDef | null>(null);
  const [displaySuggestions, setDisplaySuggestions] = useState<boolean>(false);
  const [_, setFilters] = useRecoilState(FilterState);

  const textInputRef = useRef<HTMLInputElement>(null);
  const barRef = useRef<HTMLDivElement>(null);
  const suggestionsRef = useRef<HTMLDivElement>(null);

  // Avoid stale closure for onKeyDown by
  // update the ref to the the handler on each render
  const onKeyDownRef = useRef(onKeyDown);
  useEffect(() => { onKeyDownRef.current = onKeyDown; });
  useEffect(() => {
    const keydownHandler = (e: KeyboardEvent) => onKeyDownRef.current(e);
    window.addEventListener('click', onWindowClick);
    window.addEventListener('keydown', keydownHandler);
    return () => {
      window.removeEventListener('keydown', keydownHandler);
      window.removeEventListener('click', onWindowClick);
    };
  }, []);

  const facets: SearchBarSuggestions = {};
  Object.entries(state.result?.facets || {}).forEach(([facetKey, facetVal]) => {
    facets[facetKey as keyof SearchBarSuggestions] = facetVal?.map((value) => value);
  });

  const suggestions = suggestionsDef(state.input, facets || {});

  return (
    <Search
      manager={{
        getSelected,
        onClickSuggestion,
        onCloseBar,
        onCloseMark,
        onInputFocus,
        onSuggestionOver,
        onText,
        utils: SearchUtility,
      }}
      elementRefs={{
        text: textInputRef,
        bar: barRef,
        suggestions: suggestionsRef,
      }}
      values={{
        displaySuggestions,
        inputValue: state.input,
        loadingSuggestions: state.isLoading,
        highligthedSuggestion,
        suggestions,
      }}
      theme={props.theme}
    />
  );

  function getSelected(): SearchMarkDef[] {
    const marks = SEARCH_MARK_TYPES.reduce<SearchMarkDef[]>((nextMarks, type) => nextMarks.concat(
      props.qsValues[type].map<SearchMarkDef>((v) => ({
        text: v,
        type,
        symbol: props.theme[type].symbol,
      })),
    ), []);
    return marks;
  }

  async function onText(val: string) {
    state.setInput(val);
    setDisplaySuggestions(true);
  }

  function onInputFocus() {
    setDisplaySuggestions(true);
  }

  function onCloseBar() {
    clearText();
    props.services.browsing.goToPage(searchClear(props.pathLocation));
    
    // Reset filters
    setFilters((prev) => {
      return {
        ...prev,
        hashtag: [],
        label: [],
        caption: [],
      };
    });
  }

  function onCloseMark(mark: SearchMarkDef) {
    props.services.browsing.goToPage(removeQsParam({
      location: props.pathLocation,
      key: MARK_TYPE_TO_QS_NAME[mark.type],
      currentValue: props.qsValues[mark.type],
      val: mark.text,
    }));

    // Remove from filters
    setFilters((prev) => {
      const newFilters = { ...prev };
      switch (mark.type) {
        case 'hashtags':
          newFilters.hashtag = newFilters.hashtag.filter((h) => h !== mark.text);
          break;
        case 'labels':
          newFilters.label = newFilters.label.filter((l) => l !== mark.text);
          break;
        case 'text':
          newFilters.caption = newFilters.caption.filter((c) => c !== mark.text);
          break;
      }
      return newFilters;
    });
  }

  function onClickSuggestion(mark: SearchMarkDef) {
    selectMarkSuggestion(mark);
  }

  function onSuggestionOver(mark: SearchMarkDef) {
    setHighligthedSuggestion(mark);
  }

  async function onWindowClick(e: MouseEvent) {
    const inBar = barRef.current?.contains(e.target as Node);
    const inSuggestions = suggestionsRef.current?.contains(e.target as Node);
    if (!inBar && !inSuggestions) {
      setDisplaySuggestions(false);
    }
  }

  async function onKeyDown(e: KeyboardEvent) {
    const code: KeyCode | undefined = KeyCode[e.code as keyof typeof KeyCode];
    if (code === undefined) {
      return;
    }
    if (code === KeyCode.Escape) {
      return setDisplaySuggestions(false);
    }
    if (document.activeElement === textInputRef.current) {
      if (code === KeyCode.ArrowDown) {
        setHighligthedSuggestion(null);
        await setDisplaySuggestions(true);
        suggestionsRef.current?.focus();
        navigate(code, suggestions);
      } else if (code === KeyCode.Enter) {
        if (!state.input.length && displaySuggestions) {
          return setDisplaySuggestions(false);
        }
        const [val] = SearchUtility.parseExclusion(state.input);
        selectMarkSuggestion(
          markDef(val, 'text'),
        );
      }
    } else if (document.activeElement === suggestionsRef.current) {
      navigate(code, suggestions);
    }
  }

  async function selectMarkSuggestion(mark: SearchMarkDef) {
    const [, isExclusion] = SearchUtility.parseExclusion(state.input);
    const val = isExclusion ? SearchUtility.addExclusionChar(mark.text) : mark.text;
    if (!val) {
      return;
    }
    props.services.browsing.goToPage(addQsParam({
      location: props.pathLocation,
      key: MARK_TYPE_TO_QS_NAME[mark.type],
      currentValue: props.qsValues[mark.type],
      val,
    }));

    // Add to filters
    setFilters((prev) => {
      const newFilters = { ...prev };
      switch (mark.type) {
        case 'hashtags':
          newFilters.hashtag = [...newFilters.hashtag, mark.text];
          break;
        case 'labels':
          newFilters.label = [...newFilters.label, mark.text];
          break;
        case 'text':
          newFilters.caption = [...newFilters.caption, mark.text];
          break;
      }
      return newFilters;
    });
    setHighligthedSuggestion(null);
    // props.apiConnector.reload()
    await clearText();
    textInputRef.current?.focus();
    state.setInput('');
  }

  async function clearText() {
    state.setInput('');
  }

  /** Browse suggestions */
  function navigate(code: KeyCode, suggestions?: SuggestionsDef) {
    if (!suggestions) {
      return;
    }
    let highligthed = highligthedSuggestion;
    if (!highligthed) {
      highligthed = suggestions[SEARCH_MARK_TYPES[0]]?.[0];
    } else if (code === KeyCode.Enter) {
      selectMarkSuggestion(highligthed);
    } else {
      const suggestionsMove = new SuggestionsMove(suggestions);
      highligthed = suggestionsMove.move(code, highligthed);
    }
    setHighligthedSuggestion(highligthed);
  }

  function suggestionsDef(
    text: string,
    facets: SearchBarSuggestions,
  ): SuggestionsDef {
    const selectedFacets = getAlreadySelectedFacets();
    const newFacets = SearchUtility.removeAlreadySelectedFacets(facets, selectedFacets);

    return {
      hashtags: newFacets.hashtags?.map((h) => markDef(h, 'hashtags')) || [],
      labels: newFacets.labels?.map((h) => markDef(h, 'labels')) || [],
      text: [markDef(XclusionUtility.getRawKey(text), 'text')],
    };
  }

  function getAlreadySelectedFacets(): SearchBarSuggestions {
    const selectedFacets: SearchBarSuggestions = {
      hashtags: [],
      labels: [],
    };

    SEARCH_MARK_TYPES.forEach((markType) => {
      if (markType !== 'text') {
        selectedFacets[markType] = props.qsValues[markType];
      }
    });

    return selectedFacets;
  }

  function markDef(
    text: string,
    type: SearchMarkTypes,
  ): SearchMarkDef {
    return {
      text,
      type,
      symbol: props.theme[type].symbol,
    };
  }
}
