import { style } from 'typestyle';

export { Row } from './Row';
export { Column } from './Column';
export { Content } from './Content';

export const rowClass = style({
  display: 'flex',
  flexDirection: 'row',
  flexGrow: 1,
});

export const columnClass = style({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
});
