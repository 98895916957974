import React from 'react';
import LocalizedStrings from 'react-localization';
import { IIPRightsStatus } from '@src/types/IPRights';
import { style } from 'typestyle';
import { RightRequestMQLStatus } from '@adalong/schemas';

interface Props {
  status: IIPRightsStatus
  format?: (s: string) => string
}

export function IPRightsStatusText({
  status,
  format,
}: Props) {
  let text = lang[status || RightRequestMQLStatus.Unrequested] as string;
  if (!text) {
    return <></>;
  }
  if (format) {
    text = format(text);
  }
  return (
    <span className={textClass}>{text}</span>
  );
}

const lang = new LocalizedStrings({
  en: {
    requested: 'Rights requested',
    agreed: 'Rights agreed',
    disagreed: 'Rights refused',
    error: 'Rights unavailable',
    unrequested: 'No rights requested',
  },
});

const textClass = style({
  verticalAlign: 'inherit',
});
