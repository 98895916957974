import React from 'react';
import GridLibraryLayout, { LibElement } from '@src/components/Library/GridLibraryLayout';

interface Props {
  loadMore: () => Promise<void>
  noMore: boolean
  libraryLoaded: boolean
  elements: LibElement[]
}

export function Library(props: Props) {
  return (
    <GridLibraryLayout
      libraryLoaded={props.libraryLoaded}
      noMore={props.noMore}
      loadMore={() => props.loadMore()}
      elements={props.elements}
    />
  );
}
