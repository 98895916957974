import React from 'react';

interface Props {
  marginTop?: number
}

export function FilterNote({
  children,
  marginTop = 0,
}: React.PropsWithChildren<Props>) {
  return (
    <div style={{ marginTop }}>
      {children}
    </div>
  );
}
