import { SourceNetwork } from '@src/types/SourceNetwork';
import { DateHelpers } from '@src/services/helpers/date';
import { QsNames } from '@src/types/Browsing';
import { CommonState } from '@src/types/redux/common';
import { CollectionView, MediaQuery, SearchQueryElement } from 'common';
import { XclusionSelection } from '@src/types/Filters';
import { XclusionUtility } from '../managers/XclusionUtility';
import { SearchUtility } from '../../Search/manager/SearchUtility';
import { FiltersUtility } from '../managers/FiltersUtility';
import { DetailedSource, SourceUtility } from './SourceUtility';

// MediaConstraints qualifies the media to display on discover ot to be taken into account
// in facets, expressed in UI terms
export interface MediaConstraints {
  qs: FiltersFromQs;
  isExactSearch: boolean;
  collections: CollectionView[];
  filterCollectionXclusion: XclusionSelection;
  filterOwnContentXclusion: XclusionSelection;
  filterCreatorCountriesXclusion: XclusionSelection;
  state: Pick<CommonState, 'user' | 'groupMentionPages'>;
}

interface PreparedFilters {
  source?: DetailedSource[];
  media?: string[];
  creator?: string[];
  hashtag?: string[];
  label?: string[];
  caption?: string[];
  date?: string[];
  lang?: string[];
  productExact?: string[];
  productSimilar?: string[];
  rrStatus?: string[];
  ownContent?: DetailedSource[];
  creatorCountries?: string[];
}

type FiltersFromQsKeys = typeof QsNames[keyof Pick<
  typeof QsNames,
  | 'discoverFiltersMediaRRStatuses'
  | 'discoverFiltersSource'
  | 'discoverFiltersCreator'
  | 'discoverFiltersDate'
  | 'discoverFiltersExact'
  | 'discoverFiltersLang'
  | 'discoverFiltersMedia'
  | 'discoverFiltersProductExact'
  | 'discoverFiltersProductSimilar'
  | 'discoverFiltersOwnContent'
  | 'discoverFiltersCreatorCountries'
  | 'discoverSearchHashtags'
  | 'discoverSearchLabels'
  | 'discoverSearchText'
  | 'discoverExactMode'
>];
export type FiltersFromQs = { [key in FiltersFromQsKeys]: string[] };

/**
 * This class aims building the queries from filters to get media
 */
export class MediaFilterBuilder {
  public static prepareFilters(filtersFromQs: FiltersFromQs, common: Pick<CommonState, 'user' | 'groupMentionPages'>): PreparedFilters {
    const detailedSource = SourceUtility.getAllDetailedSourcesFromState(common) || [];
    return {
      ...filtersFromQs,
      source: detailedSource.filter((s) => filtersFromQs.source.includes(SourceUtility.sourceToUrlKey(s))),
      ownContent: detailedSource.filter((s) =>
        filtersFromQs.ownContent.map((s) => XclusionUtility.getRawKey(s)).includes(SourceUtility.sourceToUrlKey(s))
      ),
    };
  }

  /**
   * Build the search and filter fields of a
   * MediaQuery
   */
  public static buildMediaFilter(
    mc: MediaConstraints,
    ignore: {
      creators?: boolean;
      languages?: boolean;
      productsExact?: boolean;
      productsSimilar?: boolean;
      ownContent?: boolean;
      creatorCountries?: boolean;
    } = {}
  ): Pick<MediaQuery, 'filter' | 'search' | 'rawOnly'> {
    const {
      qs,
      state,
      isExactSearch,
      collections,
      filterCollectionXclusion,
      filterOwnContentXclusion,
    } = mc;
    const {
      source = [],
      media = [],
      creator = [],
      hashtag = [],
      label = [],
      caption = [],
      date = [],
      lang = [],
      productExact = [],
      productSimilar = [],
      rrStatus = [],
      ownContent = [],
      creatorCountries = [],
    } = MediaFilterBuilder.prepareFilters(qs, state);

    const filter: MediaQuery['filter'] = {
      and: [],
    };

    if (media.length) {
      filter.and?.push({
        mediaType: media as MediaQuery['filter']['mediaType'],
      });
    }

    if (rrStatus.length > 0) {
      filter.and?.push({
        rightRequestStatuses: rrStatus as MediaQuery['filter']['rightRequestStatuses'],
      });
    }

    // Add sources
    if (source.length) {
      const sourceQueries = MediaFilterBuilder.sourceQueries(source);
      if (sourceQueries) {
        filter.and?.push({ or: sourceQueries });
      }
    }

    // Add creators
    if (!ignore.creators && creator.length) {
      filter.and?.push(this.xclusionFilterToQuery(creator, 'creators'));
    }

    // Add own content
    if (!ignore.ownContent && ownContent.length) {
      const ownContentQuery = ownContent
        .map((s) => {
          const id = s.other;
          const network = s.network;
          if (!id) {
            return null;
          }

          return XclusionUtility.xcludeKey(`${network}_${id}`, filterOwnContentXclusion);
        })
        .filter((s) => s) as string[];
      filter.and?.push(this.xclusionFilterToQuery(ownContentQuery, 'ownContent'));
    }

    // Add language
    if (!ignore.languages && lang.length) {
      filter.and?.push(this.xclusionFilterToQuery(lang, 'languages'));
    }

    // Add exact products
    if (!ignore.productsExact && productExact.length) {
      filter.and?.push(this.xclusionFilterToQuery(productExact, 'productIdsExact'));
    }

    // Add exact products
    if (!ignore.productsSimilar && productSimilar.length) {
      filter.and?.push(this.xclusionFilterToQuery(productSimilar, 'productIdsSimilar'));
    }

    // Add creator countries
    if (!ignore.creatorCountries && creatorCountries.length) {
      filter.and?.push(this.xclusionFilterToQuery(creatorCountries, 'creatorCountries'));
    }

    // These fields are filled by the searchbar
    const excludableFields: {
      field: keyof SearchQueryElement;
      values: string[];
    }[] = [
      {
        field: 'labels',
        values: label,
      },
      {
        field: 'caption',
        values: caption,
      },
      {
        field: 'tags',
        values: hashtag,
      },
    ];

    const searchBarPositiveFilters: SearchQueryElement[] = [];

    // Value to exclude
    const but: MediaQuery['filter'] = {
      and: [],
    };

    excludableFields.forEach(({ field, values }) => {
      const excluded = SearchUtility.getExclusionList(values);
      const included = SearchUtility.getInclusionList(values);
      if (excluded.length) {
        but.and?.push({
          not: {
            [field]: excluded,
          },
        });
      }
      if (included.length) {
        if (isExactSearch) {
          included.forEach((includedFilter) => {
            filter.and?.push({
              [field]: [includedFilter],
            });
          });
        } else {
          searchBarPositiveFilters.push({
            [field]: included,
          } as SearchQueryElement);
        }
      }
    });

    if (but.and?.length) {
      filter.and?.push(but);
    }

    if (date.length) {
      const range = FiltersUtility.urlDateToRange(date[0]);
      const [dateFrom, dateTo] = range || [];
      if (dateFrom || dateTo) {
        filter.and?.push({
          dates: {
            gt: DateHelpers.dateToTimeStamp(dateFrom, 'startOf'),
            lt: DateHelpers.dateToTimeStamp(dateTo, 'endOf'),
          },
        });
      }
    }

    // add collection filters
    if (collections.length > 0) {
      const positiveCollectionFilter = {
        or: collections.filter(({ query }) => query !== undefined).map(({ query }) => query!),
      };

      const collectionFilter = filterCollectionXclusion === 'inclusion' ? positiveCollectionFilter : { not: positiveCollectionFilter };

      filter.and?.push(collectionFilter);
    }

    if (!searchBarPositiveFilters.length || isExactSearch) {
      return { filter };
    }

    return { search: searchBarPositiveFilters, filter };
  }

  /**
   * Build queries to filter by sources
   * Returns an array with objects for each network containing values (mentions, hashtags, ...)
   */
  public static sourceQueries(detailedSources?: DetailedSource[]): MediaQuery['filter'][] | null {
    if (!detailedSources?.length) {
      return null;
    }

    const sourceQueries: MediaQuery['filter'][] = [];

    // Sort by network
    const sourceByNetwork: {
      [key in SourceNetwork]?: {
        source: DetailedSource[];
        filter?: MediaQuery['filter'][];
      };
    } = {};
    detailedSources.forEach((s) => {
      const sbn = (sourceByNetwork[s.network] = sourceByNetwork[s.network] || { source: [] });
      sbn.source.push(s);
    });

    // For each network,
    Object.entries(sourceByNetwork).forEach(([network, sbn]) => {
      const sbnFilter: {
        tags?: string[];
        mentions?: string[];
        tagged?: string[];
        sources?: string[];
      } = {};

      // For each type
      sbn?.source.forEach((s) => {
        if (s.type === 'hashtag') {
          if (!sbnFilter.tags) {
            sbnFilter.tags = [];
          }
          sbnFilter.tags.push(s.value);
        } else if (s.type === 'tagged') {
          const id = s.other;
          if (!id) {
            return null;
          }
          const pageId = `${network}_${id}`;
          if (!sbnFilter.tagged) {
            sbnFilter.tagged = [];
          }
          sbnFilter.tagged.push(pageId);
        } else if (s.type === 'mention') {
          const id = s.other;
          if (!id) {
            return null;
          }
          const pageId = `${network}_${id}`;
          if (!sbnFilter.mentions) {
            sbnFilter.mentions = [];
          }
          sbnFilter.mentions.push(pageId);
        }
      });

      if (sbnFilter.tags?.length || sbnFilter.tagged?.length 
        || sbnFilter.mentions?.length || network === 'tiktok') {
        sourceQueries.push({
          and: [
            {
              sources: [network],
            },
            {
              or: Object.entries(sbnFilter).map(([key, val]) => ({ [key]: val })),
            },
          ],
        });
      }
    });
    return sourceQueries;
  }

  private static xclusionFilterToQuery(values: string[], field: keyof MediaQuery['filter']): MediaQuery['filter'] {
    const isInclusion = XclusionUtility.isInclusion(values[0]);
    const queryFilter: MediaQuery['filter'] = {
      [field]: XclusionUtility.getRawKeys(values),
    };
    if (isInclusion) {
      return queryFilter;
    }
    return { not: queryFilter };
  }
}
