import { useMutation } from 'react-query';
import { ApiError } from '@src/services/api';
import { ApiTypes } from 'common';
import { queryClient, QUERY_KEYS, QUERY_PREFIX } from '@src/query';
import { updateMediaFindData } from './useQueryMediaFind';
import { deleteRightsRequest } from './api';

export const useMutationRightsRequestDelete = () => useMutation<ApiTypes.ResDeleteRightsRequest, ApiError, {
  mediaId: string
}>(({ mediaId }) => deleteRightsRequest(mediaId), {
  onSuccess: async (res, { mediaId }) => {
    // update optimistically for user satisfaction
    // and then refresh media to have perfectly updated results
    res.forEach((id) => {
      updateMediaFindData(id, { rightsRequestStatus: undefined });
      // refetch media for detailed view
      queryClient.refetchQueries(QUERY_KEYS.media(id));
    });
    queryClient.invalidateQueries(QUERY_KEYS.mediaFind());
    queryClient.invalidateQueries(QUERY_KEYS.collectionsMedia());
    queryClient.invalidateQueries([QUERY_PREFIX.MEDIA_SIMILAR]);
    queryClient.invalidateQueries([QUERY_PREFIX.MEDIA_CAROUSEL]);
  },
});
