import React from 'react';
import { stylesheet, classes } from 'typestyle';
import { ADALONG_COLORS } from '@src/styles';
import { Column } from './Utils';

interface Props<ToggleValue> {
  buttons: ToggleButton<ToggleValue>[];
  value: ToggleValue;
  className?: string | string[];
  onChange: (value: ToggleValue) => void;
}

interface ToggleButton<ToggleValue> {
  element: JSX.Element;
  val: ToggleValue;
  tooltip?: string;
  disabled?: string | null | boolean;
}

export function ToggleButtons<ToggleValue>({
  buttons,
  className,
  value,
  onChange,
}: Props<ToggleValue>) {
  return (
    <div className={classes(sheet.toggleButtonsClass, className)}>
      {buttons.map((button) => {
        const { element, val, disabled } = button;
        return (
          <Column
            onClick={() => onClick(button)}
            className={classes(sheet.toggleButtonClass, {
              [sheet.activeButtonCLass]: val === value,
              [sheet.disabledButtonClass]: disabled,
            })}
          >
            {element}
          </Column>
        );
      })}
    </div>
  );

  function onClick(button: ToggleButton<ToggleValue>) {
    if (!button.disabled && button.val !== value) {
      onChange(button.val);
    }
  }
}

const sheet = stylesheet({
  toggleButtonsClass: {
    display: 'flex',
    color: ADALONG_COLORS.LIGHT_GRAY,
  },
  toggleButtonClass: {
    padding: 8,
    color: ADALONG_COLORS.LIGHT_GRAY,
    borderBottom: '1px solid',
    borderColor: ADALONG_COLORS.DARK_GRAY,
    cursor: 'pointer',
    $nest: {
      '> *': {
        margin: 'auto',
      },
      '&:hover': {
        background: ADALONG_COLORS.LIGHT_BLACK,
      },
    },
    justifyContent: 'center',
  },
  activeButtonCLass: {
    color: `${ADALONG_COLORS.LIGHT_WHITE} !important`,
    borderColor: `${ADALONG_COLORS.LIGHT_WHITE} !important`,
  },
  disabledButtonClass: {
    color: `${ADALONG_COLORS.LIGHT_BLACK} !important`,
    $nest: {
      '&:hover': {
        background: 'transparent !important',
      },
    },
  },
});
