import React from 'react';
import { PlatformIcon, PlatformIconProps } from './Platform';

export function LandscapeIcon(props: PlatformIconProps) {
  return (
    <PlatformIcon {...props} style={{ transform: 'rotateZ(-90deg)' }}>
      <rect height="60.54" rx="5.09" ry="5.09" width="32.11" x="15.94" y="1.62" />
      <line x1="15.94" x2="48.06" y1="8.75" y2="8.75" />
      <line x1="15.94" x2="48.06" y1="54.42" y2="54.42" />
      <line x1="29.83" x2="33.68" y1="57.83" y2="57.83" />
    </PlatformIcon>
  );
}
