import { MediaView } from 'common/dist';
import { MediaType } from '@adalong/schemas';

/**
 * Download warnings for one or more medias
 */
export interface MediaDownloadWarning {
  available: string;
  rights: boolean;
}

/**
 * Tell if we should display a warning when trying to download a media
 *
 * Conditions for displaying download warning:
 * - media is unavailable
 * - any content without approval
 */
export function shouldDisplayDownloadWarning(medias: MediaView[]): MediaDownloadWarning {
  const warning: MediaDownloadWarning = {
    available: '',
    rights: false,
  };

  // find a media that should display a warning
  medias.forEach((media) => {
    if (media.storedVersion === 'NA' || media.storedVersion === 'VCR') {
      warning.available = media.storedVersion;
    }
    if (media.rightsRequestStatus !== 'agreed') {
      warning.rights = true;
    }
  });
  return warning;
}

/**
 * Return true if at least one warning is true
 */
export function isDownloadWarningTrue(warning: MediaDownloadWarning): boolean {
  for (const val of Object.values(warning)) {
    if (val) {
      return true;
    }
  }
  return false;
}

export function isMediaDownlodableByType(media: MediaView): boolean {
  return media.type === MediaType.Image || media.type === MediaType.Video;
}

/**
 * Media plus the download warning associated
 */
export type MediaAndDownloadWarning = {
  warning: MediaDownloadWarning;
  media: MediaView;
};

/**
 * Filter by warnings to return downloadable media
 * We do only prevent the download if the media is not available.
 */
export function getDownloadableMedia(warnings: MediaAndDownloadWarning[]): MediaView[] {
  return warnings
    .filter((val) => val.warning.available !== 'NA' && val.warning.available !== 'VCR')
    .map(({ media }) => media);
}

/**
 * Obtain warning per media
 */
export function getMediaWarnings(medias: MediaView[]): MediaAndDownloadWarning[] {
  return medias.map((media) => {
    const warning: MediaDownloadWarning = shouldDisplayDownloadWarning([media]);
    return {
      media,
      warning,
    };
  });
}
