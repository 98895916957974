import { UseQueryOptions, useQuery, useQueries, UseQueryResult } from 'react-query';
import { QUERY_KEYS } from '@src/query';
import { CollectionView } from 'common';
import { getCollection } from './api';

export const useQueryCollection = (
  id: string,
  includeQuery: boolean = false,
  token?: string,
  config?: UseQueryOptions<CollectionView | undefined>
) =>
  useQuery<CollectionView | undefined, unknown>(
    QUERY_KEYS.collection(id),
    () => getCollection(id, includeQuery, token),
    config
  );

/**
 * Load a dynamic list of collection
 */
export const useQueriesCollection = (
  collectionIds: string[],
  includeQuery: boolean = false,
  token?: string
): UseQueryResult<CollectionView>[] => {
  const queries = collectionIds.map((id) => ({
    queryKey: QUERY_KEYS.collection(id),
    queryFn: () => getCollection(id, includeQuery, token),
  }));
  // Cast to change generic type because of bad types of useQueries
  // see: https://github.com/tannerlinsley/react-query/issues/1675
  return useQueries(queries) as UseQueryResult<CollectionView>[];
};
