import React, { Component } from 'react';

import * as reduxHelpers from '@src/services/helpers/redux';
import { EPopup } from '@src/types/redux/common';
import { classes, stylesheet } from 'typestyle';
import { bigStore } from '../../store';
import * as CommonActions from '../../actions/common';

import translation from './translation';

const lang = reduxHelpers.loadTranslation(translation);

interface IProps {

}

interface IState {

}
export class OpenSettings extends Component<IProps, IState> {
  public render() {
    return (
      <span
        className={sheet.openSettings}
        onClick={() => bigStore.dispatch(CommonActions.setPopup(EPopup.settings, true))}
      >
        <i className={classes('material-icons', 'notranslate', sheet.icon)}>
          open_in_new
        </i>
        {' '}
        {lang.opensettings}
      </span>
    );
  }
}

const sheet = stylesheet({
  openSettings: {
    marginLeft: 20,
    color: '#47a0e6',
    cursor: 'pointer',
    transition: 'color 0.2s',
    $nest: {
      '&:hover': {
        textDecoration: 'underline',
        color: '#238de1',
      },
    },
  },
  icon: {
    verticalAlign: 'middle',
  },
});
