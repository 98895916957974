import { ApiTypes } from 'common';
import { Call } from '@src/services/api';
import { Category } from '@adalong/schemas';

export const getProducts = async (
  params: ApiTypes.ParamsGetProducts,
): Promise<ApiTypes.ResGetProducts['products']> => {
  const res = await Call.get<ApiTypes.ResGetProducts, ApiTypes.ParamsGetProducts>(
    '/api/products',
    params,
  );
  return res.products;
};

export const getTaxonomy = (): Promise<Category> => Call.get('/api/catalog/taxonomy');

export const getProductGroups = async (
  params: ApiTypes.ParamsGetProductGroups,
): Promise<ApiTypes.ResGetProductGroups> => Call.get('/api/product-groups', params);

export const getAllProductGroups = async (): Promise<ApiTypes.ResGetAllProductGroups> =>
  Call.get('/api/product-groups', { size: 9999 });

export const getProductsAvailablesLocalizations = async (): Promise<ApiTypes.ResGetLocalizationMappings> => {
  return Call.get<ApiTypes.ResGetLocalizationMappings >('/api/products/country-mappings');
}