import { useQuery } from 'react-query';
import { DEPRECATED_QUERY_KEYS } from '@src/query';
import { ApiError } from '@src/services/api';
import { Category } from '@adalong/schemas';
import { getTaxonomy } from './api';

/**
 * Get catalog taxonomy
 */
export const useQueryCatalogTaxonomy = () => useQuery<Category, ApiError>([DEPRECATED_QUERY_KEYS.CATALOG_TAXONOMY], () => getTaxonomy());
