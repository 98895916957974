import { DISCOVER_ACTION_CREATORS } from '@src/actions/discover';
import { DiscoverState } from '@src/types/redux/discover';
import { REDUX_ACTIONS } from '../actions/_types';

const MAX_SEARCHFILTER_SUGGESTIONS = 5;

const initialState: DiscoverState = {
  mlLabelsAutoCompletion: [],
  aitags: [], // Array of array
  media: [],
  cursor: null,
  noMore: false, // No more content to load on scroll
  libraryLoaded: false,
  totalMedias: 0,
  totalExactMedias: 0,
  filtersUnfold: true,
};

export type Idiscover = DiscoverState;

export type DiscoverAction = any;

export default function (state = initialState, action: DiscoverAction) {
  switch (action.type) {
    case REDUX_ACTIONS.DISCOVER.SET_LIBRARY_LOADED:
      return {
        ...state,
        libraryLoaded: action.libraryLoaded,
      };
    case REDUX_ACTIONS.DISCOVER.SET_TOTAL_MEDIAS:
      return {
        ...state,
        totalMedias: action.totalMedias,
      };
    case REDUX_ACTIONS.DISCOVER.SET_TOTAL_EXACT_MEDIAS:
      return {
        ...state,
        totalExactMedias: action.totalExactMedias,
      };
    case REDUX_ACTIONS.DISCOVER.SET_LIBRARY:
      return {
        ...state,
        media: action.medias,
        noMore: false,
      };
    case REDUX_ACTIONS.DISCOVER.ADD_MEDIAS_TO_LIBRARY:
      return {
        ...state,
        media: state.media.concat(action.medias),
      };
    case REDUX_ACTIONS.DISCOVER.NO_MORE:
      return {
        ...state,
        noMore: action.noMore,
      };
    case REDUX_ACTIONS.DISCOVER.SET_FILTERS_UNFOLD:
      return {
        ...state,
        filtersUnfold: action.unfold,
      };
    case REDUX_ACTIONS.DISCOVER.SET_ML_LABELS_COMPLETION:
      return {
        ...state,
        mlLabelsAutoCompletion: action.completion,
      };
    case REDUX_ACTIONS.DISCOVER.SAVE_SEARCHFILTER_VALUE:
      return {
        ...state,
        [action.kind]: {
          ...(state[action.kind as keyof DiscoverState] as object),
          value: action.value,
        },
      };
    case REDUX_ACTIONS.DISCOVER.SET_MEDIA_NOT_FOUND:
      return {
        ...state,
        totalMedias: Math.max(state.totalMedias - 1, 0),
        totalExactMedias: action.isExactMode ? Math.max(state.totalExactMedias - 1, 0)
          : Math.min(state.totalMedias - 1, state.totalExactMedias),
      };
    case REDUX_ACTIONS.DISCOVER.SET_LIBRARY_CURSOR:
      return {
        ...state,
        cursor: action.cursor,
      };
    case REDUX_ACTIONS.DISCOVER.SET_SEARCHFILTER_SUGGESTION:
      return setSearchFilterSuggestion(state, action);
    default:
      return state;
  }
}

const setSearchFilterSuggestion = function (
  state: Idiscover,
  action: ReturnType<typeof DISCOVER_ACTION_CREATORS.setSearchFilterSuggestion>,
) {
  const { suggestion } = action;
  const { kind } = action;
  return {
    ...state,
    [kind]: {
      ...(state[action.kind as keyof DiscoverState] as object),
      suggestion: suggestion.slice(0, MAX_SEARCHFILTER_SUGGESTIONS),
    },
  };
};
