import { GlobalError } from './GlobalError';
import { Api } from './api';
import { Start } from './Start';
import { Login } from './Login';
import { Browsing } from './Browsing';
import { IVideoMakerService } from './VideoMaker';
import { IMediaService } from './Media';
import { IPopupsService } from './Popups';
import { ServiceStore } from './redux';
import { IProductService } from './Product';

export enum ServiceName {
  GlobalError,
  Api,
  Start,
  Login,
  Browsing,
  VideoMaker,
  MediaService,
  Popups,
  Product,
}

export type ServiceReference = Promise<Services>;

export type ServiceCast = GlobalError | Api | Start | Login | Browsing | IVideoMakerService | IMediaService | IPopupsService | IProductService;

export interface Services {
  get<T extends ServiceCast>(name: ServiceName): T
  getStore(): ServiceStore
}
