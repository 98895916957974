import { DATE_FORMAT, DATE_UNIT_SEPARATOR } from '@src/config/discover';
import dayjs from 'dayjs';
import { UrlDate, DateRange } from '@src/types/Filters';

const RELATIVE_DATE_REGEXP = /^(days|months)(\d\d?)$/;
const CUSTOM_DATE_FORMAT_REGEXP = `${DATE_FORMAT.replace(/\w/g, '\\d')}`;
const CUSTOM_DATE_REGEXP = new RegExp(`^(${CUSTOM_DATE_FORMAT_REGEXP})_(${CUSTOM_DATE_FORMAT_REGEXP})$`);

interface RelativeDateValues {
  value: number
  unit: 'day' | 'month'
}

export class FiltersUtility {
  public static urlDateToRange(urlDate: UrlDate): DateRange | null {
    let dateFrom: Date;
    let dateTo: Date;

    const relativeDate = this.parseRelativeDate(urlDate);
    const customDate = this.parseCustomDate(urlDate);
    if (relativeDate) {
      dateFrom = dayjs().add(-(relativeDate.value), relativeDate.unit).toDate();
      dateTo = dayjs().toDate();
    } else if (customDate) {
      [dateFrom, dateTo] = customDate;
    } else {
      return null;
    }

    return [dateFrom, dateTo];
  }

  public static parseRelativeDate(urlDate: UrlDate): RelativeDateValues | null {
    const relative = RELATIVE_DATE_REGEXP.exec(urlDate);
    if (!relative) {
      return null;
    }
    return {
      unit: relative[1].slice(0, -1) as RelativeDateValues['unit'],
      value: Number(relative[2]),
    };
  }

  public static parseCustomDate(urlDate: UrlDate): [Date, Date] | null {
    const custom = CUSTOM_DATE_REGEXP.exec(urlDate);
    if (!custom) {
      return null;
    }
    return [
      dayjs(custom[1]).toDate(),
      dayjs(custom[2]).toDate(),
    ];
  }

  public static customDateToUrlDate(dates: [Date, Date]) {
    return `${dayjs(dates[0]).format(DATE_FORMAT)}_${dayjs(dates[1]).format(DATE_FORMAT)}`;
  }

  public static displayableDate(date?: Date): string {
    if (!date) {
      return 'No date';
    }
    return dayjs(date).format(
      DATE_FORMAT.replace(new RegExp(`${DATE_UNIT_SEPARATOR}`, 'g'), '/'),
    );
  }
}
