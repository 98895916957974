import { MEDIA_ACTION_CREATORS } from '@src/actions/media';
import { MediaState } from '@src/types/redux/media';
import { REDUX_ACTIONS } from '../actions/_types';

export const initialState: MediaState = {
  dimensions: {},
};

export type MediaAction = any;

const reducers = {
  [REDUX_ACTIONS.MEDIA.SET_DIMENSIONS]: (
    state: MediaState,
    action: ReturnType<typeof MEDIA_ACTION_CREATORS['setDimensions']>,
  ) => {
    const dimensions = { ...state.dimensions };
    dimensions[action.mediaId] = action.dimensions;
    return {
      ...state,
      dimensions,
    };
  },
};

export const media = function (state: MediaState = initialState, action: MediaAction) {
  if (reducers[action.type]) {
    return reducers[action.type](state, action);
  }
  return state;
};
