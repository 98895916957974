import React from 'react';
import { SvgIconProps } from '@src/types/SvgIcon';

function HashSvgIcon(props: SvgIconProps) {
  const { color = 'currentColor', size = 12 } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      height={size}
      viewBox="0 0 8 12"
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={color} fillRule="nonzero" transform="translate(-68 -14)">
          <g transform="translate(51 4)">
            <path
              d="M10 2.124L8.25 14 7 13.827 8.759 2 10 2.124zm3 0L11.259 14 10 13.827 11.75 2l1.25.124zM14 10v1H6v-1h8zm0-4v1H6V6h8z"
              transform="translate(11 8)"
            />
          </g>
        </g>
      </g>
    </svg>
  );
}

export { HashSvgIcon };
