import React from 'react';
import { style } from 'typestyle';

interface Props {
  childrenRender: () => JSX.Element;
}

export function DropDownIcon({ childrenRender }: Props) {
  return (
    <svg className={svgClass} viewBox='0 0 55 55'>
      <g stroke='none' fill='none' fillRule='evenodd'>
        {childrenRender()}
      </g>
    </svg>
  );
}

const svgClass = style({
  cursor: 'pointer',
  width: 20,
  height: 20,
  marginRight: '7px',
});
