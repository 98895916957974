import { IProductService } from '@src/types/Product';
import { Api } from '@src/types/api';
import { ServiceReference, ServiceName } from '@src/types/Services';
import { ServiceStore } from '@src/types/redux';
import { PRODUCT_ACTION_CREATORS } from '@src/actions/product';
import { ProductView } from 'common';

/**
 * Product service
 */
export class ProductService implements IProductService {
  private apiService?: Api;

  public constructor(
    servicesPromise: ServiceReference,
    private store: ServiceStore,
  ) {
    servicesPromise.then((services) => {
      this.apiService = services.get<Api>(ServiceName.Api);
    });
  }

  /**
     * Get products from the api and store in redux
     * It will not fetch products already present in the store
     * The orignal order is not guaranted
     */
  public async fetchProducts(productIds: string[]): Promise<ProductView[]> {
    let resolvedProducts: ProductView[] = [];
    const { product: productState } = this.store.getState();
    const filteredProductIds = productIds.filter((p) => {
      const product = productState.products[p];
      if (!product) {
        return true;
      }
      resolvedProducts.push(product);
      return false;
    });

    if (!filteredProductIds.length) {
      return resolvedProducts;
    }
    resolvedProducts = await this.apiService?.getProducts({
      ids: filteredProductIds,
    }).catch((e) => {
      console.error(e);
    }) || [];
    if (resolvedProducts.length) {
      this.store.dispatch(PRODUCT_ACTION_CREATORS.setProducts(resolvedProducts));
    }
    return resolvedProducts;
  }
}
