import {MediaType, XCLUSION_SELECTIONS, XclusionSelection} from '@src/types/Filters';
import {SetterOrUpdater} from "recoil";
import {DiscoverQs} from '@src/types/Discover';
import {XclusionUtility} from '@src/fragments/Filters/managers/XclusionUtility';
import {RightRequestMQLStatus} from "@adalong/schemas";

export const onFilterChange = (setFilters: SetterOrUpdater<DiscoverQs>, filter: keyof DiscoverQs) => (
    values: string[],
    selected: boolean,
    xclusion: XclusionSelection = XCLUSION_SELECTIONS[0],
): void => {
        if(!selected){
            setFilters((filters) => ({
                ...filters,
                [filter]: filters[filter].filter(
                    c => !XclusionUtility.getRawKeys(values).includes(XclusionUtility.getRawKey(c))
                )
            }));
            return;
        }
        setFilters((filters) => ({
            ...filters,
            [filter]: [...filters[filter],
                ...XclusionUtility.getRawKeys(values).map((key) => XclusionUtility.xcludeKey(key, xclusion))]
        }));
    }

export const onXclusionSwitch = (
    xclusion: XclusionSelection,
    setXclusion: SetterOrUpdater<XclusionSelection>,
    filter: keyof DiscoverQs,
    setFilters: SetterOrUpdater<DiscoverQs>
): void => {
    setFilters((filters) => ({
        ...filters,
        [filter]: XclusionUtility.getRawKeys(filters[filter]).map((key) => XclusionUtility.xcludeKey(key, xclusion)),
    }))
    setXclusion(xclusion);
}

export const clearFilter = (setFilters: SetterOrUpdater<DiscoverQs>, filter: keyof DiscoverQs): void => {
    setFilters((filters) => ({
        ...filters,
        [filter]: []
    }))
}

export function validMediaType(m: string): MediaType | null {
    if (m == 'image' || m == 'video' || m == 'text') {
        return m;
    }
    return null;
}

export function validRightRequestStatus(m: string): RightRequestMQLStatus | null {
    if (m === RightRequestMQLStatus.Requested
        || m === RightRequestMQLStatus.Agreed
        || m === RightRequestMQLStatus.Unrequested) {
        return m;
    }
    return null;
}
