import React, { Component } from 'react';

import { style } from 'typestyle';

interface IProps {

}

interface IState {

}

export class NotFound extends Component <IProps, IState> {
  public render() {
    return (
      <div className={notFoundClass}>
        <h1>
          {window.T('common.notfound')}
        </h1>
      </div>
    );
  }
}

const notFoundClass = style({
  position: 'fixed',
  height: '100%',
  width: '100%',
  top: 0,
  left: 0,
  display: 'flex',
  justifyContent: 'center',
  textAlign: 'center',
  flexDirection: 'column',
  backgroundColor: '#212121',
  fontFamily: 'Raleway, sans-serif',
  zIndex: 1,
});
