import React, { Component } from 'react';
import { PerfectScrollbar } from 'components/PerfectScrollbar';
import { InfiniteScroller } from '@src/components/InfiniteScroller';
import styles from './styles.scss';

interface IProps {
  title: string | React.ReactElement
  empty?: string
  isEmpty?: boolean
  children: any
  leftSection?: any
  collectionId?: string
  noMore?: boolean
  loadCollectionMedia?: () => Promise<void>
}

export class CollectionRow extends Component <IProps> {
  public constructor(props: IProps) {
    super(props);
  }

  public render() {
    return (
      <div className={`${styles.collectionRow} medias`} data-empty={this.props.isEmpty}>
        <div className="head">
          <div className="title">{this.props.title}</div>
          <div className="filters" />
        </div>
        <div className={styles.row}>
          {this.props.leftSection ? (
            <div className={styles.leftSection}>
              {this.props.leftSection}
            </div>
          ) : null}
          <InfiniteScroller
            container={(props, children) => (
              <PerfectScrollbar
                className={styles.bar}
                data-empty={this.props.isEmpty}
                options={{
                  wheelSpeed: 0.6,
                  minScrollbarLength: 30,
                  maxScrollbarLength: 120,
                  useBothWheelAxes: true,
                  suppressScrollY: true,
                }}
                {...props}
              >
                {children}
              </PerfectScrollbar>
            )}
            noMore={this.props.noMore || false}
            threshold={1700}
            load={this.props.loadCollectionMedia?.bind(this) || (async () => {})}
            horizontal
          >
            <div className="slide" data-empty={this.props.empty} ref="container">
              {this.props.children}
            </div>
          </InfiniteScroller>
        </div>
      </div>
    );
  }
}
