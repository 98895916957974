import { useQuery } from 'react-query';
import { queryClient, QUERY_KEYS } from '@src/query';
import { ApiTypes } from 'common';
import { getRightsTemplate } from './api';

export interface RightsTemplate {
  terms: ApiTypes.ResGetRightsTemplate['terms']
  template: Partial<ApiTypes.ResGetRightsTemplate['template']>
}

/**
 * Get config
 */
export const useQueryUserRightsTemplate = () => useQuery<RightsTemplate>(QUERY_KEYS.userRightsTemplate(), () => getRightsTemplate(), {
  keepPreviousData: true,
});

export const getQueryDataUserRightsTemplate = (): RightsTemplate | undefined => queryClient.getQueryData(QUERY_KEYS.userRightsTemplate());

export const setQueryDataUserRightsTemplate = (data: RightsTemplate): void => {
  queryClient.setQueryData(QUERY_KEYS.userRightsTemplate(), data);
};
