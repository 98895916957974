export function injectScript(
  url: string,
  injectTo: HTMLElement,
  id: string,
) {
  const script: HTMLScriptElement = document.querySelector(`#${id}`) || document.createElement('script');
  script.src = url;
  script.id = id;
  injectTo.appendChild(script);
  return script;
}

export function injectStyle(
  url: string,
  injectTo: HTMLElement,
  id: string,
) {
  const style: HTMLLinkElement = document.querySelector(`#${id}`) || document.createElement('link');
  style.rel = 'stylesheet';
  style.href = url;
  style.id = id;
  injectTo.appendChild(style);
  return style;
}
