import React, { Component } from 'react';

import { connect, useSelector } from 'react-redux';
import { VideoPlayer } from 'components/VideoPlayer';

import { ICombinedReducers } from '@src/types/redux';
import { ShareNetworkType, SharePopupInfo } from '@src/types/redux/share';
import { ActionButton } from '@src/components/ActionButton';
import { style, classes } from 'typestyle';
import { ADALONG_COLORS } from '@src/styles';
import { Row } from '@src/components/Utils';
import { MediaType } from '@adalong/schemas';
import styles from './styles.scss';
import { useQueryUserConfig } from '@src/queries/users/useQueryUserConfig';

interface IProps {
  openSharePopup: (target: ShareNetworkType, info: SharePopupInfo) => void
  closeSharePrompt: () => void
}

export default function SharePopup(props: IProps): JSX.Element | null {

  const info = useSelector((state: ICombinedReducers) => state.share.sharePrompt);
  const disableInstaShare = useDisableInstaShare();

  function select(target: ShareNetworkType) {
    props.openSharePopup(target, info!);

    props.closeSharePrompt();
  }

  if (!info) {
    return null;
  }
  return (
    <div className={`${styles.sharePrompt} popup-overlay`} onClick={props.closeSharePrompt}>
      <div className={`frame ${nohighlimitClass}`} onClick={(e) => e.stopPropagation()}>
        <div className="title">
          {window.T('share.prompt.title')}
          <div className="remove" onClick={props.closeSharePrompt}>
            <i className="material-icons notranslate">close</i>
          </div>
        </div>
        <div className="content">
          {info.fileType === 'video' ? (
            <VideoPlayer media={{
              type: MediaType.Video,
              image_large: '',
              video: info.file,
            }}
            />
          ) : null}
          {info.fileType === 'image' ? (
            <img src={info.image} alt="" />
          ) : null}
        </div>
        <Row
          className={buttonsClass}
        >
          <ActionButton
            className={classes(fbPageClass, buttonClass)}
            onClick={() => select('facebookpage')}
          >
            {window.T('share.prompt.buttons.facebookpage').toUpperCase()}
          </ActionButton>
          <ActionButton
            className={classes(fbAdsClass, buttonClass)}
            onClick={() => select('facebookads')}
          >
            {window.T('share.prompt.buttons.facebookads').toUpperCase()}
          </ActionButton>
        </Row>
        <br />
        {disableInstaShare === false ? (
          <Row
          className={buttonsClass}
          >
            <ActionButton
              className={classes(instagramClass, buttonClass)}
              onClick={() => select('instagrampage')}
            >
            {window.T('share.prompt.buttons.instagrampage').toUpperCase()}
            </ActionButton>
          </Row>
        ) : null}
      </div>
    </div>
  );
}

function useDisableInstaShare(): boolean {
  const auth = useQueryUserConfig()?.data?.user.groupFeatureDisabler?.instagram_publish;
  if (auth === undefined) {
    return true;
  }
  return auth;
}

const buttonsClass = style({
  justifyContent: 'center',
});

const nohighlimitClass = style({
  height: 'auto !important',
});

const buttonClass = style({
  padding: '8px 35px !important',
  fontWeight: 'bold',
  $nest: {
    '> span': {
      letterSpacing: '1px !important',
    },
  },
});

const fbPageClass = style({
  backgroundColor: ADALONG_COLORS.FB_BLUE,
  borderColor: ADALONG_COLORS.FB_BLUE,
  color: ADALONG_COLORS.SMOOTH_WHITE,
  marginRight: 20,
});

const fbAdsClass = style({
  backgroundColor: ADALONG_COLORS.SMOOTH_WHITE,
  borderColor: ADALONG_COLORS.SMOOTH_WHITE,
  color: ADALONG_COLORS.FB_BLUE,
});

const instagramClass = style({
  backgroundColor: ADALONG_COLORS.INSTAGRAM_PURPLE,
  borderColor: ADALONG_COLORS.INSTAGRAM_PURPLE,
  color: ADALONG_COLORS.SMOOTH_WHITE,
});
