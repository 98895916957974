import { ThemeStyle as ImportedThemeStyle, ADALONG_COLORS } from '@src/styles';

export const DEFAULT_SIZE_VALUE = 3;
export const MIN_SIZE_VALUE = 1;
export const MAX_SIZE_VALUE = 100; // Maximum of columns
export const SECTION_TITLE_SIZE = 'small';
export const FORM_LINE_TITLE_SIZE = ImportedThemeStyle.textSizes.small;
export const FORM_VALUE_TITLE_SIZE = ImportedThemeStyle.textSizes.tiny;
export const INPUT_WIDTH = '120px';

export const ThemeStyle = {
  background: {
    main: '#151515',
  },
  fonts: {
    primary: '"Public Sans", sans-serif',
  },
  button: {
    color: '#bbbbbb',
    hoveredColor: 'white',
    size: 15,
  },
  container: {
    color: ADALONG_COLORS.SMOOTH_BLACK,
  },
  borderSeparator: `4px solid ${ADALONG_COLORS.DARK_GRAY}`,
  ipRightsStatus: {
    colors: {
      requested: '#0c81de',
      agreed: '#5cb85c',
      unrequested: '#3f3f3f',
    },
  },
  textSizes: {
    tiny: 10,
    small: 12,
    medium: 14,
    large: 16,
    big: 18,
  },
};

export type TTitleSizes = keyof typeof ThemeStyle.textSizes;
