import React from 'react';
import * as reduxHelpers from '@src/services/helpers/redux';
import { Pathname } from '@src/types/Browsing';
import { useQueryCollections } from '@src/queries/collections/useQueryCollections';
import { PerfectScrollbar } from '../PerfectScrollbar';
import { CollectionThumb } from '../CollectionThumb';

// TODO: Move in the Collection folder in pages

export function CollectionBar() {
  const { data: collections } = useQueryCollections();

  if (!collections) {
    return null;
  }

  return (
    <PerfectScrollbar
      id="collection-bar"
      options={{
        wheelSpeed: 0.6,
        minScrollbarLength: 30,
        maxScrollbarLength: 120,
        useBothWheelAxes: true,
        suppressScrollY: true,
      }}
    >
      <div>
        {collections.map((collection) => (
          <div className="collection-container" key={collection._id}>
            <CollectionThumb
              collection={collection}
              onClick={() => reduxHelpers.setRoute(`/collections/${collection._id}` as Pathname)}
              backgroudImageUrl={collection.preview_media_url ?? undefined}
            />
          </div>
        ))}
      </div>
    </PerfectScrollbar>
  );
}
