import React from 'react';
import { IWidget } from 'common';
import { style } from 'typestyle';
import { SquareIcon } from '../../Icons/ThumbnailShapeIcons/Square';
import { PortraitIcon } from '../../Icons/ThumbnailShapeIcons/Portrait';
import { StoriesIcon } from '../../Icons/ThumbnailShapeIcons/Stories';

type ThumbnailShape = IWidget['settings']['thumbnail_shape'];

interface Props {
  thumbnail_shape: ThumbnailShape;
  onChange: (thumbnail_shape: ThumbnailShape) => any;
}

export function ThumbnailShapeSelector({ thumbnail_shape, ...props }: Props) {
  const onChange = (thumbnail_shape: ThumbnailShape) => {
    props.onChange(thumbnail_shape);
  };

  return (
    <div className={selectClass}>
      <div className={itemClass}>
        <SquareIcon onClick={() => onChange('square')} selected={thumbnail_shape === 'square'} />{' '}
        SQUARE{' '}
      </div>
      <div className={itemClass}>
        <PortraitIcon
          onClick={() => onChange('portrait')}
          selected={thumbnail_shape === 'portrait'}
        />{' '}
        PORTRAIT{' '}
      </div>
      <div className={itemClass}>
        <StoriesIcon onClick={() => onChange('stories')} selected={thumbnail_shape === 'stories'} />{' '}
        STORIES{' '}
      </div>
    </div>
  );
  10;
}
const selectClass = style({
  marginTop: '10px',
  marginBottom: '10px',
  display: 'flex',
  flexDirection: 'row',
});

const itemClass = style({
  fontSize: '0.7em',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginRight: '10px',
});
