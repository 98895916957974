import React, { Component, ReactNode } from 'react';
import shareIcon from '@src/assets/img/icons/share.svg';

import { stylesheet } from 'typestyle';
import Tooltip from '@mui/material/Tooltip';
import Warning from '@mui/icons-material/Warning';
import DownloadIcon from '@mui/icons-material/GetApp';
import { ButtonIcon } from '../ButtonIcon';
import { Column } from '../Utils';

interface IProps {
  children?: ReactNode
  onShare: () => any
  onDownload?: () => void
  isInstagramVideo?: boolean
  haveRights?: boolean
  isBrandContent?: boolean
  isPreview?: boolean
}

interface IState {

}

export class ShareCollectionContentOverlay extends Component<IProps, IState> {
  public render() {
    const {
      onShare,
      onDownload,
    } = this.props;
    return (
      <div className={sheet.overlay}>
        <div>
          {!this.props.haveRights && !this.props.isBrandContent && !this.props.isPreview ? (
            <Column style={alertClass}>
              <Tooltip title="You don't have rights on this media">
                <Warning color="secondary" />
              </Tooltip>
            </Column>
          ) : (
            <>
            <img src={shareIcon} className={sheet.icon} onClick={onShare} />
            {onDownload && (
              <ButtonIcon
                className={sheet.download}
                icon={DownloadIcon}
                onClick={onDownload}
              />
            )}
            </>
          )
        }
        </div>
        {this.props.children}
      </div>
    );
  }
}

const alertClass: React.CSSProperties = {
  justifyContent: 'center',
  alignItems: 'center',
  height: 'inherit',
};

const sheet = stylesheet({
  overlay: {
    zIndex: 2,
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    background: '#00000099',
    opacity: 0,
    transition: 'opacity 0.2s',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'space-around',
    textAlign: 'center',
    $nest: {
      '&:hover:not(:active)': {
        opacity: 1,
      },
    },
  },
  icon: {
    width: 40,
    height: 40,
    marginLeft: 10,
    filter: 'brightness(70%)',
    verticalAlign: 'middle',
    $nest: {
      '&:hover': {
        filter: 'brightness(100%)',
      },
    },
  },
  download: {
    position: 'absolute',
    top: 4,
    right: 4,
  },
});
