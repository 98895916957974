import React from 'react';
import { SectionFolder } from '@src/components/SectionFolder';
import { SectionTitle } from '@src/components/SectionTitle';
import { FormLine } from '@src/components/Form/FormLine';
import { Input } from 'components/Input';
import { TextArea } from 'components/TextArea';
import { InformationIcon } from '../../Icons/DropDownIcons/Information';
import { SectionProps } from '../types/Settings';
import LocalizedStrings from 'react-localization';
import { FORM_LINE_TITLE_SIZE, SECTION_TITLE_SIZE } from '../utils/variables';
import { SectionContent } from '../subcomponents/SectionContent';

const Information = ({ opened, onOpen, editedWidget, setEditedWidget }: SectionProps) => {
  return (
    <SectionFolder
      title={
        <SectionTitle size={SECTION_TITLE_SIZE}>
          <InformationIcon />
          {lang.title}
        </SectionTitle>
      }
      opened={opened}
      onOpen={onOpen}
    >
      <SectionContent>
        <FormLine title={lang.fields.name} size={FORM_LINE_TITLE_SIZE}>
          <Input
            onChange={({ target: { value } }) => setEditedWidget({ ...editedWidget, name: value })}
            value={editedWidget.name}
            placeholder={lang.fields.name}
          />
        </FormLine>
        <FormLine title={lang.fields.description} size={FORM_LINE_TITLE_SIZE}>
          <TextArea
            onChange={({ target: { value } }) => {
              setEditedWidget({ ...editedWidget, description: value });
            }}
            value={editedWidget.description}
          />
        </FormLine>
      </SectionContent>
    </SectionFolder>
  );
};

const lang = new LocalizedStrings({
  en: {
    title: 'Information',
    fields: {
      name: 'Name',
      description: 'Description',
    },
  },
});

export default Information;
