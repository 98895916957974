import React, { useEffect, useState } from 'react';
import { Browsing, QsFields, QsNames } from '@src/types/Browsing';
import { useLocationQS } from '@src/utils/useLocationQS';
import { ServiceName, Services } from '@src/types/Services';
import { MediaView, ResGetMedia } from 'common';
import { useLocation } from 'react-router';
import { DiscoverQs } from '@src/types/Discover';
import { useDispatch } from 'react-redux';
import { DISCOVER_ACTION_CREATORS } from '@src/actions/discover';
import { IPopupsService } from '@src/types/Popups';
import { useMediaFindLibrary, useMediaFindFacet } from '@src/queries/media/useQueryMediaFind';
import { useQueryUserConfig } from '@src/queries/users/useQueryUserConfig';
import { useQueryGroupMentionPages } from '@src/queries/users/useQueryGroupMentionPages';
import { ArrayHelper } from '@src/services/helpers/array';
import { XclusionUtility } from '@src/fragments/Filters/managers/XclusionUtility';
import { pathLocationToString, setQsParam } from '@src/utils/browsing';
import { useQueriesCollection } from '@src/queries/collections/useQueryCollection';
import { MediaConstraints } from '@src/fragments/Filters/utils/MediaFilterBuilder';
import { useQueryCollections } from '@src/queries/collections/useQueryCollections';
import { Discover } from './Discover';
import {
  FacetState,
  filterCollectionXclusionState,
  filterCreatorCountriesXclusionState,
  filterCreatorXclusionState,
  filterLanguageXclusionState,
  filterProductExactXclusionState,
  filterProductSimilarXclusionState,
  filterOwnContentXclusionState,
  FilterState,
  InitialLibraryState,
} from '@src/pages/Discover/components/Atoms/Atoms';
import { useRecoilState } from 'recoil';
import { useQueryMediaFindAll } from '@src/queries/media/useQueryMediaFindAll';

export const TYPING_DEBOUNCE = 350;

interface Props {
  services: Services;
}

export default function DiscoverController(props: Props) {
  const dispatch = useDispatch();

  const [filters, setFilters] = useRecoilState(FilterState);
  const [facets, setFacetsState] = useRecoilState(FacetState);
  function setParam(field: Partial<QsFields>, value: string[] | undefined) {
    services.browsing.goToPage(
      pathLocationToString(
        setQsParam(window.location, [
          {
            field,
            val: value,
          },
        ])
      )
    );
  }
  // Get values from the query string
  const qs = useLocationQS([
    QsNames.discoverFiltersMediaRRStatuses,
    QsNames.discoverExactMode,
    QsNames.discoverSearchText,
    QsNames.discoverSearchHashtags,
    QsNames.discoverSearchLabels,
    QsNames.discoverFiltersCreator,
    QsNames.discoverFiltersDate,
    QsNames.discoverFiltersExact,
    QsNames.discoverFiltersLang,
    QsNames.discoverFiltersMedia,
    QsNames.discoverFiltersProductExact,
    QsNames.discoverFiltersProductSimilar,
    QsNames.discoverFiltersSource,
    QsNames.discoverFiltersCollection,
    QsNames.discoverFiltersOwnContent,
    QsNames.discoverFiltersCreatorCountries,
  ]);

  useEffect(() => {
    for (const [field, value] of Object.entries(filters)) {
      setParam(field as Partial<QsFields>, value);
    }
  }, [filters]);

  // Define the exact search mode
  const [exactMode] = qs.exact;
  const isExactSearch: boolean = exactMode === 'true';

  // Create states of childrens
  const [filtersOpened, setFiltersOpened] = useState(true);
  const [mediaSelection, setMediaSelection] = useState<MediaView[]>([]);
  const [, setInitialLibraryStat] = useRecoilState(InitialLibraryState);
  const [, setFilterLanguageXclusion] = useRecoilState(filterLanguageXclusionState);
  const [, setfilterProductExactXclusion] = useRecoilState(filterProductExactXclusionState);
  const [, setfilterProductSimilarXclusion] = useRecoilState(filterProductSimilarXclusionState);
  const [, setFilterCreatorXclusion] = useRecoilState(filterCreatorXclusionState);
  const [filterOwnContentXclusion, setFilterOwnContentXclusion] = useRecoilState(filterOwnContentXclusionState);
  const [filterCollectionXclusion, setFilterCollectionXclusion] = useRecoilState(filterCollectionXclusionState);
  const [filterCreatorCountriesXclusion, setFilterCreatorCountriesXclusion] = useRecoilState(filterCreatorCountriesXclusionState);
  const [searchInput, setSearchInput] = useState('');

  const [selectAllActive, setSelectAllActive] = useState(false);

  useEffect(() => {
    setFilterCreatorXclusion(XclusionUtility.prevailing(qs.creator));
    setFilterCollectionXclusion(XclusionUtility.prevailing(qs.collection));
    setFilterLanguageXclusion(XclusionUtility.prevailing(qs.lang));
    setfilterProductExactXclusion(XclusionUtility.prevailing(qs.productExact));
    setfilterProductSimilarXclusion(XclusionUtility.prevailing(qs.productSimilar));
    setFilterOwnContentXclusion(XclusionUtility.prevailing(qs.ownContent));
    setFilterCreatorCountriesXclusion(XclusionUtility.prevailing(qs.creatorCountries));
  }, []);

  // Prepare required services
  const [services] = useState(() => ({
    browsing: props.services.get<Browsing>(ServiceName.Browsing),
    popups: props.services.get<IPopupsService>(ServiceName.Popups),
  }));

  // Config queries
  const { data: userConfig } = useQueryUserConfig();
  const { data: groupMentionPageList, isLoading: isLoadingGroupMentionPageList } = useQueryGroupMentionPages();
  const user = userConfig?.user || null;
  const groupMentionPages = ArrayHelper.mapToObject(groupMentionPageList || [], (p) => p.id);
  const stateForQueries = { user, groupMentionPages };

  // Collection handling
  // All the user's collections' identifiers
  const { data: userCollections = [] } = useQueryCollections();
  const userCollectionIds = userCollections.map(({ _id }) => _id);
  // The collections' MQL are then memoized using ReactQuery

  const includeQuery = true;
  const inputCollectionKeys = XclusionUtility.getRawKeys(filters.collection);

  const includedCollectionKeys = userCollectionIds.filter((id) => inputCollectionKeys.includes(id));
  const collectionResults = useQueriesCollection(includedCollectionKeys, includeQuery);
  // map collections results to collections and filter potentially undefined items
  const collections = ArrayHelper.filterNulls(collectionResults.map(({ data }) => data));

  const mediaConstraints: MediaConstraints = {
    qs,
    state: stateForQueries,
    isExactSearch,
    collections,
    filterCollectionXclusion,
    filterOwnContentXclusion,
    filterCreatorCountriesXclusion,
  };

  // Media queries
  const {
    data: resLibrary,
    isLoading: isLoadingLibrary,
    isFetching: isFetchingLibrary,
    isFetchingNextPage: isFetchingMoreLibrary,
    hasNextPage: canFetchMoreLibrary,
    fetchNextPage: fetchMoreLibrary,
  } = useMediaFindLibrary(
    {
      ...mediaConstraints,
      isExactSearch: false,
    },
    // we only want total if it's not the current mode
    isExactSearch ? 0 : undefined
  );
  const {
    data: resLibraryExact,
    isLoading: isLoadingLibraryExact,
    isFetching: isFetchingLibraryExact,
    isFetchingNextPage: isFetchingMoreLibraryExact,
    hasNextPage: canFetchMoreLibraryExact,
    fetchNextPage: fetchMoreLibraryExact,
  } = useMediaFindLibrary(
    {
      ...mediaConstraints,
      isExactSearch: true,
    },
    // we only want total if it's not the current mode
    isExactSearch ? undefined : 0
  );
  const {
    data: allMedia,
    refetch: fetchAllMedia,
    isFetching: isFetchingAll,
  } = useQueryMediaFindAll(
    {
      ...mediaConstraints,
      isExactSearch: true,
    }
  );
  const resLibraryToDisplay = isExactSearch ? resLibraryExact : resLibrary;
  const library: MediaView[] = resLibraryToDisplay?.pages.flatMap((r) => r.media) || [];
  const shoppableUGCs: number = library.filter((media) => media.hasActiveExactProducts || media.hasActiveSimilarProducts).length;
  const canFetchMore = isExactSearch ? canFetchMoreLibraryExact : canFetchMoreLibrary;
  const fetchMore = isExactSearch ? fetchMoreLibraryExact : fetchMoreLibrary;
  const isFetchingMore = isFetchingMoreLibrary || isFetchingMoreLibraryExact;
  const libraryFetching = isFetchingLibrary || isFetchingLibraryExact;
  let libraryLoaded = !isLoadingLibrary && !isLoadingLibraryExact;
  if (isFetchingMore) {
    // prevent hiding the lib when scrolling
    libraryLoaded = true;
  }

  useEffect(() => {
    setInitialLibraryStat({
      total: getTotalMedia(resLibraryToDisplay?.pages),
      shoppableUGCs,
    });
  }, [resLibraryExact, resLibrary]);

  // Filters queries
  // Wait for the library before fetching facets
  const enableFacet = !(isFetchingMore || libraryFetching);

  useEffect(() => {
    setFacetsState({
      typingDebounce: TYPING_DEBOUNCE,
      enableFacet: enableFacet,
      mediaConstraints: mediaConstraints,
    });
  }, [enableFacet]);

  // Product : Based on exact products
  const { data: resFilterProductIds, isLoading: isLoadingFilterProduct } = useMediaFindFacet(
    mediaConstraints,
    '',
    'productIdsExact',
    TYPING_DEBOUNCE,
    enableFacet
  );

  // Search query
  const { data: resSearch, isLoading: isLoadingSearch } = useMediaFindFacet(
    mediaConstraints,
    XclusionUtility.getRawKey(searchInput),
    ['hashtags', 'labels'],
    TYPING_DEBOUNCE,
    enableFacet
  );

  // Get current location for routing purposes
  const location = useLocation();

  // Set default filters when mounted
  useEffect(() => {
    if (areFiltersEmpty(qs)) {
      setDefaultFilters();
    }
  }, []);

  useEffect(() => {
    setFilters(qs);
  }, []);

  const handleSelectAll = async () => {
    try {
      const result = await fetchAllMedia();
      if (result.data) {
        setMediaSelection(result.data);
        setSelectAllActive(true);
      }
    } catch (error) {
      console.error('Error fetching all media:', error);
    }
  };

  if (!user) {
    return null;
  }
  return (
    <Discover
      isExactMode={isExactSearch}
      qsValues={qs}
      pathLocation={location}
      services={services}
      searchState={{
        input: searchInput,
        setInput: setSearchInput,
        isLoading: isLoadingSearch,
        result: resSearch?.pages[0],
      }}
      commonStore={stateForQueries}
      filtersOpened={filtersOpened}
      mediaSelection={mediaSelection}
      setMediaSelection={setMediaSelection}
      setFiltersOpened={setFiltersOpened}
      setMediaNotFound={setMediaNotFound}
      setExactMode={setExactMode}
      library={library}
      displayFetchingLoader={libraryLoaded && libraryFetching}
      libraryLoaded={libraryLoaded}
      noMoreMedia={!canFetchMore}
      loadMoreMedia={fetchMore}
      totalMedia={getTotalMedia(resLibrary?.pages)}
      totalExactMedia={getTotalMedia(resLibraryExact?.pages)}
      handleSelectAll={handleSelectAll}
      selectAllActive={selectAllActive}
      setSelectAllActive={setSelectAllActive}
      isFetchingAll={isFetchingAll}
    />
  );

  function setMediaNotFound(id: string, isExactMode: boolean) {
    dispatch(DISCOVER_ACTION_CREATORS.setMediaNotFound(id, isExactMode));
  }

  function setExactMode(mode: boolean) {
    services.browsing.goToPage(
      pathLocationToString(
        setQsParam(location, [
          {
            field: QsNames.discoverExactMode,
            val: mode ? 'true' : undefined,
          },
        ])
      )
    );
  }

  function setDefaultFilters() {
    const defaultFilters: Parameters<typeof setQsParam>[1] = [
      {
        field: QsNames.discoverFiltersDate,
        val: ['days30'],
      },
    ];
    services.browsing.goToPage(pathLocationToString(setQsParam(location, defaultFilters)));
  }
}

function getTotalMedia(res: ResGetMedia[] | undefined): number {
  if (!res) {
    return 0;
  }
  const [r] = res;
  return r?.total || 0;
}

// Check if filters from the qs are empty
function areFiltersEmpty(qs: DiscoverQs): boolean {
  return !Object.values(qs).find((val) => val.length > 0);
}
