import * as React from 'react';

function SvgComponent({ color = 'currentColor', ...props }: React.SVGProps<SVGSVGElement>) {
  return (
    <svg height={24} width={24} fill={color} viewBox="0 0 100 100" {...props}>
      <path d="M79.3 30.2c6.1 0 11-4.9 11-11s-4.9-11-11-11-11 4.9-11 11c0 2.7 1 5.1 2.5 7L57.4 40.5c-1.9-1.6-4.4-2.6-7.1-2.6-2.7 0-5.1.9-7 2.5L29.8 27.9c.6-1.1 1-2.3 1-3.7 0-4-3.3-7.3-7.3-7.3s-7.3 3.3-7.3 7.3 3.3 7.3 7.3 7.3c1.6 0 3.1-.6 4.4-1.5l13.5 12.5c-1.3 1.8-2 4-2 6.3 0 2.6.9 5 2.4 6.9L26.9 71.5c-1.8-1.3-4-2.1-6.4-2.1-6.1 0-11 4.9-11 11s4.9 11 11 11 11-4.9 11-11c0-2.6-.9-5-2.4-6.9l14.8-15.7c1.7 1.3 3.9 2 6.2 2.1L52 77.1c-3.7 1.1-6.4 4.4-6.4 8.4 0 4.9 3.9 8.8 8.8 8.8s8.8-3.9 8.8-8.8c0-4.7-3.7-8.5-8.3-8.8L53 59.6c2-.5 3.9-1.6 5.3-3.1L72 66.4c-.4.9-.7 1.9-.7 3 0 4 3.3 7.3 7.3 7.3s7.3-3.3 7.3-7.3-3.3-7.3-7.3-7.3c-1.9 0-3.6.7-4.9 1.9L60 54.1c.8-1.5 1.3-3.3 1.3-5.2 0-2.3-.7-4.4-1.9-6.2L73 28.3c1.8 1.2 3.9 1.9 6.3 1.9z" />
    </svg>
  );
}

export default SvgComponent;
