export abstract class ApiHelper {
  /**
     * Serialize an object to be derializable by the api
     */
  public static serializeToQS(obj: object | any[], prefix?: string): string;
  public static serializeToQS(obj: any, prefix?: string): string {
    const strings: string[] = [];
    Object.keys(obj).forEach((p) => {
      const key = prefix ? `${prefix}[]` : p;
      const val = obj[p];
      if (val === undefined) {
        return;
      }

      if (val !== null && typeof val === 'object') {
        strings.push(this.serializeToQS(val, key));
      } else {
        strings.push(`${encodeURIComponent(key)}=${encodeURIComponent(val)}`);
      }
    });
    return strings.join('&');
  }
}
