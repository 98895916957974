import React from 'react';
import { IWidget } from 'common';
import { style } from 'typestyle';
import { ModalIcon } from '../../Icons/ShopThisLookIcons/Modal';
import { ProductListIcon } from '../../Icons/ShopThisLookIcons/ProductList';
import { NoneIcon } from '../../Icons/ShopThisLookIcons/None';

type ShopThisLook = IWidget['settings']['shop_this_look_display'];

interface Props {
  shop_this_look_display: ShopThisLook;
  onChange: (shop_this_look_display: ShopThisLook) => any;
}

export function ShopThisLookSelector({ shop_this_look_display, ...props }: Props) {
  const onChange = (shop_this_look_display: ShopThisLook) => {
    props.onChange(shop_this_look_display);
  };

  return (
    <div className={selectClass}>
      <div className={itemClass}>
        <ModalIcon onClick={() => onChange('modal')} selected={shop_this_look_display === 'modal'} />{' '}
        MODAL{' '}
      </div>
      <div className={itemClass}>
        <ProductListIcon
          onClick={() => onChange('product_list')}
          selected={shop_this_look_display === 'product_list'}
        />{' '}
        PRODUCT LIST{' '}
      </div>
      <div className={itemClass}>
        <NoneIcon onClick={() => onChange('none')} selected={shop_this_look_display === 'none'} />{' '}
        NONE{' '}
      </div>
    </div>
  );
}
const selectClass = style({
  marginTop: '10px',
  marginBottom: '10px',
  display: 'flex',
  flexDirection: 'column',
});

const itemClass = style({
  fontSize: '0.7em',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginRight: '10px',
  $nest: {
    '& svg': {
      marginRight: '8px'
    }
  }
});
