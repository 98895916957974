import React, { useState } from 'react';
import { TextArea } from '@src/components/TextArea';
import LocalizedStrings from 'react-localization';
import { ActionButton } from '@src/components/ActionButton';
import { style, classes } from 'typestyle';
import { Row } from '@src/components/Utils';
import { ThemeStyle } from '@src/styles';
import styles from './styles.scss';
import DatePickerMui from '@src/components/DatePickerMui';
import TextField from '@mui/material/TextField/TextField';

interface IProps {
  disable: boolean
  creator?: string
  defaultComment?: string
  onMessageChange: (val: string) => void
  message: string
  handleDateChange: (date: Date | null) => void
  scheduledDate: Date | null
}

export default function DisplayPreview(props: IProps): JSX.Element | null {
  const [displayDropdown, setdisplayDropdown] = useState<boolean>(false);

  return (
    <div className={styles.instagramwithbuffer} onClick={onFrameClick}>
      <div className="line">
        <Row className={creatorContainerClass}>
          <div
            className={classes('title', titleClass)}
            id="comment"
          >
            {lang.commentTitle.toUpperCase()}
          </div>
          {props.creator ? (
            <ActionButton
              className={creatorButtonClass}
              variant="gray"
              onClick={() => addCreator()}
            >
              {lang.mentionCreator}
            </ActionButton>
          ) : null}
        </Row>
        <TextArea
          id="textarea"
          value={props.message}
          style={{ minHeight: 110 }}
          placeholder={lang.commentPlaceholder}
          onChange={({ target }) => props.onMessageChange(target.value)}
          maxLength={1000}
        />
        {/* <div
          className={classes('title', titleClass)}
        >
          {lang.schedulerTitle}
        </div>
        <DatePickerMui
          labeldate="Schedule Date"
          classes={[styles.datepicker]}
          onDateChange={(date) => props.handleDateChange(date)}
          currentDate={props.scheduledDate}
          disablePast={true}
        /> */}
      </div>
    </div>
  );

  function onFrameClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    setdisplayDropdown(false);
  }

  function addCreator() {
    props.onMessageChange(`${props.message}@${props.creator}`);
  }
}

const lang = new LocalizedStrings({
  en: {
    commentTitle: 'Caption',
    commentPlaceholder: 'Write an original caption, add emojis and include your brand hashtag!',
    mentionCreator: 'Mention Creator',
    schedulerTitle: 'Schedule Publishing',
  },
});

const titleClass = style({
  fontSize: ThemeStyle.textSizes.medium,
});

const creatorContainerClass = style({
  justifyContent: 'space-between',
});

const creatorButtonClass = style({
  padding: '0px 12px !important',
  fontSize: `${ThemeStyle.textSizes.tiny}px !important`,
  marginBottom: 7,
  $nest: {
    '> span': {
      letterSpacing: '1.5px',
    },
  },
});
