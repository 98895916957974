import React from 'react';
import Icon from '@mui/icons-material/WarningRounded';
import { style, classes } from 'typestyle';
import { SvgIconProps } from '@mui/material';

export function WarningIcon(props: SvgIconProps) {
  const { className, ...inheritedProps } = props;
  return <Icon {...inheritedProps} className={classes(iconClass, className)} />;
}

const iconClass = style({
  color: 'orange',
});
