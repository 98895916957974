import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ICombinedReducers } from '@src/types/redux';
import { EPopup } from '@src/types/redux/common';
import { AddToCollection } from '@src/types/Popups';
import AddTo from './components/AddTo';
import CreateCollection from './components/CreateCollection';
import * as CommonActions from '../../../../actions/common';

import styles from './styles.scss';

interface IProps {
  media?: AddToCollection
  close: () => void
  show: boolean | null
  step: 'add' | 'new'
}

interface IState {

}

class CollectOverlay extends Component <IProps, IState> {
  public render() {
    if (!this.props.media) {
      return (null);
    }
    return (
      <div
        className={styles.collectOverlay}
        data-show={!!this.props.show}
        onClick={() => this.props.close()}
      >
        <div className="frame" onClick={(e) => e.stopPropagation()}>
          {window.reactSwitch(this.props.step, [
            { val: 'add', elem: () => <AddTo media={this.props.media} /> },
            { val: 'new', elem: () => <CreateCollection media={this.props.media} onClose={() => this.props.close()}/> },
          ])}
        </div>
      </div>
    );
  }
}

export default connect((state: ICombinedReducers) => ({
  show: state.common.popups.collectOverlay,
  step: state.common.collectOverlay.step,
  media: state.common.collectOverlay.media,
}), (dispatch) => ({
  close: () => dispatch(CommonActions.setPopup(EPopup.collectOverlay, false)),
}))(CollectOverlay);
