import React, { PropsWithChildren } from 'react';
import { style } from 'typestyle';

export const SectionContent = ({ children }: PropsWithChildren<{}>) => {
  return <div className={sectionClass}>{children}</div>;
};

const sectionClass = style({
  paddingRight: 10,
});
