/*
**	Component presenting the list of collections
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';

import IconList from '@mui/icons-material/List';
import { ServiceName, Services } from '@src/types/Services';
import { Pathname } from '@src/types/Browsing';
import { SectionProgressLoader } from '@src/components/SectionProgressLoader';
import { IVideoMakerService } from '@src/types/VideoMaker';
import { BrowsingService } from '@src/services/Browsing';
import { ICombinedReducers } from '@src/types/redux';
import NavBar from '../../components/NavBar';
import RightContainer from './components/RightContainer';
import { LeftContainer } from './components/LeftContainer';

const mapStateToProps = (state: ICombinedReducers) => ({
  gif: state.video.gif,
  page: state.video.page,
  windowHeight: state.common.windowSize.height,
  user: state.common.user,
  loadingEdit: state.video.loadingEdit,
});

interface RouterProps {
  match: {
    params: {
      id: string
    }
  }
}

interface OwnProps {
  services: Services
}

type Props = OwnProps & Readonly<ReturnType<typeof mapStateToProps>> & RouterProps;

class VideoMakerPage extends Component<Props> {
  private videoMakerService: IVideoMakerService;

  private browsingService: BrowsingService;

  public constructor(props: Props) {
    super(props);
    this.videoMakerService = props.services.get<IVideoMakerService>(ServiceName.VideoMaker);
    this.browsingService = props.services.get<BrowsingService>(ServiceName.Browsing);
  }

  public componentDidMount() {
    const { id } = this.props.match.params;
    this.videoMakerService.loadAndSetVideoMaker(id);
  }

  public render() {
    return (
      <div style={{ display: 'flex', flexDirection: 'column', height: '100%' }}>
        <NavBar active={Pathname.create} />
        <div style={{
          display: 'flex', flexDirection: 'column', flexGrow: 1, overflow: 'hidden', position: 'relative',
        }}
        >
          <div>
            <h3 style={{
              paddingTop: 15,
              paddingBottom: 15,
              marginBottom: 0,
              marginLeft: 33,
              marginRight: 33,
              borderBottom: 'solid 1px',
            }}
            >
              {window.T('createvideo.title')}
              :
              <IconList
                onClick={() => this.browsingService.goToPage(Pathname.create)}
                style={{
                  cursor: 'pointer', width: 'auto', height: 55, marginLeft: 35,
                }}
              >
                Back
              </IconList>
            </h3>
          </div>
          <div style={{ display: 'flex', flexGrow: 1, overflow: 'hidden' }}>
            <>
              <LeftContainer handlers={this.videoMakerService} />
              <RightContainer videoMakerService={this.videoMakerService} />
            </>
          </div>
          {this.props.loadingEdit < 100 && (
          <SectionProgressLoader
            value={this.props.loadingEdit}
            opacity={0.8}
          />
          )}
        </div>
      </div>
    );
  }
}

export const VideoMaker = connect(mapStateToProps)(VideoMakerPage);
