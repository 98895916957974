import { MediaFacet, ProductView, ResGetMedia } from 'common';
import { SourceType } from '@src/fragments/Filters/utils/SourceUtility';
import React from 'react';
import { RightRequestMQLStatus } from '@adalong/schemas';
import { SourceNetwork } from './SourceNetwork';
import { TranslatedLanguage, Iso6393Language } from './Language';

export enum MediaSource {
  twitter = 'twt',
  instagram = 'ig',
  tiktok = 'tiktok',
}

export type DateRange = [Date, Date];

export type RelativeDate = 'days7' | 'days30' | 'months2' | 'months6';
export type UrlDate = RelativeDate | 'all' | string;

export const ReversedMediaSource: Partial<{
  [key in MediaSource]: string
}> = {};

Object.entries(MediaSource).forEach(([key, val]) => ReversedMediaSource[val] = key);

export type MediaType = 'image' | 'video' | 'text';

export type XclusionSelection = 'inclusion' | 'exclusion';
export const XCLUSION_SELECTIONS: XclusionSelection[] = ['inclusion', 'exclusion'];

export interface LangResult {
  lang: TranslatedLanguage
  code: Iso6393Language
}

Object.entries(MediaSource).forEach(([key, val]) => ReversedMediaSource[val] = key);

export interface FiltersApiConnector {
  searchFacets(val: string, facets: MediaFacet[]): Promise<Pick<ResGetMedia, 'facets' | 'total'> | void>
  reload(): void
  fetchProducts(productIds: string[]): Promise<ProductView[]>
}

export type FilterParamKey = 'source' | 'media' | 'creator' | 'date' | 'lang' | 'product';
export interface FilterParamsService {
  getParams(): {
    [key in FilterParamKey]?: string[]
  }
  setParam(key: FilterParamKey, val: string[] | undefined): boolean
}

export type SearchSuggestion<T = {}> = {
  key: string
  display: string
} & T;
export interface SourceSearchSuggestion {
  network: SourceNetwork
  type: SourceType
}

export interface SearchFilterState<S = {}> {
  inputValue: string
  loading: boolean
  suggestions: SearchSuggestion<S>[]
}

// Managers

export interface FiltersManagerInterface {
  languageManager: LanguageManagerInterface
  creatorManager: CreatorsManagerInterface
  sourceManager: SourcesManagerInterface
  productManager: ProductsManagerInterface
  mediaTypeManager: MediaTypeManagerInterface
  rightRequestStatusManager: RightRequestStatusManagerInterface
  dateManager: DateManagerInterface
  collectionManager: CollectionManagerInterface
}
export interface LanguageManagerInterface extends SearchFilterManager, XclusionManager {
  getSuggestions(): SearchSuggestion[]
}
export interface CreatorsManagerInterface extends SearchFilterManager, XclusionManager {
  getSuggestions(): SearchSuggestion[]
}
export interface SourcesManagerInterface extends SearchFilterManager<SourceSearchSuggestion> {
  getSuggestions(): SearchSuggestion<SourceSearchSuggestion>[]
  selectNetwork(network: SourceNetwork): void
  isNetworkSelected(network: SourceNetwork): boolean
}

export interface ProductsManagerInterface extends SearchFilterManager, XclusionManager {
  getSuggestions(): SearchSuggestion[]
}

export interface RightRequestStatusManagerInterface {
  getSelectedRightRequestStatus(): RightRequestMQLStatus[]
  clearAllRightRequestStatuses(): void
  onRightRequestStatus(type: RightRequestMQLStatus, checked: boolean): void
  isRightRequestStatusSelected(type: RightRequestMQLStatus): boolean
}

export interface MediaTypeManagerInterface {
  getSelectedMediaType(): MediaType[]
  clearAllMediaType(): void
  onMediaType(type: MediaType, checked: boolean): void
  isMediaTypeSelected(type: MediaType): boolean
}

export interface DateManagerInterface {
  getDate(): UrlDate | undefined
  onDateChange(date?: UrlDate): void
}

export interface CollectionManagerInterface extends SearchFilterManager, XclusionManager {
  getSuggestions(): SearchSuggestion[]
}

export interface SearchFilterManager<T = {}> extends SearchMethods {
  onText(value: string): void
  isSuggestionSelected(selection: SearchSuggestion<T>, filter: string[]): boolean
  findSelectedSuggestions(): SearchSuggestion<T>[]
  getSelectedSuggestion(): SearchSuggestion<T>[]
  findSuggestions(suggestions: SearchSuggestion<T>[], rawString?: ((s: string) => string)): SearchSuggestion<T>[]
  getFilters(): Set<string>
  selectAll(): void
  displaySelectAll(): boolean
}

interface SearchMethods {
  search(value?: string): Promise<void>
  reloadSearch(): Promise<void>
  onSuggestionChange(selectionKey: string[], selected: boolean, xclusion?: XclusionSelection): void
  onClear(): void
}

export interface XclusionManager {
  onXclusionSwitch(value: XclusionSelection): void
}

export interface XclusionState {
  xclusion: XclusionSelection
  setXclusion: React.Dispatch<React.SetStateAction<XclusionSelection>>
}

export interface InputState {
  input: string
  setInput: React.Dispatch<React.SetStateAction<string>>
}

interface QueryResultState {
  isLoading: boolean
  result: ResGetMedia | undefined
}

interface CollectionDesc {
  key: string
  display: string
}

export interface FiltersState {
  source: InputState & Pick<QueryResultState, 'isLoading'>
  language: QueryResultState & InputState & XclusionState
  product: QueryResultState & InputState & XclusionState & {
    products?: ProductView[]
  }
  creator: QueryResultState & InputState & XclusionState
  collection: InputState & XclusionState & {
    collections?: CollectionDesc[]
  }
}
export type SetFilterParam = (values: string[] | undefined) => void;

type HandlerSelected<T = {}> = () => SearchSuggestion<T>[];
type HandlerClear = () => void;
interface DefaultHandler {
  selected: HandlerSelected
  clear: HandlerClear
}
export interface FiltersHandlers {
  date: {
    selected: () => string | undefined
    clear: HandlerClear
  }
  mediaType: {
    selected: () => MediaType[]
    clear: HandlerClear
  }
  rightRequestStatus: {
    selected: () => RightRequestMQLStatus[],
    clear: HandlerClear
  }
  lang: DefaultHandler
  source: {
    selected: HandlerSelected<SourceSearchSuggestion>
    clear: HandlerClear
  }
  creator: DefaultHandler
  product: DefaultHandler
  collection: DefaultHandler
}
