import { useQuery } from 'react-query';
import { QUERY_KEYS } from '@src/query';
import { getGroupMentionPages } from './api';

/**
 * Get mention pages for user's group
 */
export const useQueryGroupMentionPages = () => useQuery(QUERY_KEYS.userGroupMentionPages(), () => getGroupMentionPages(), {
  staleTime: Infinity,
  cacheTime: Infinity,
});
