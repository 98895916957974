import { useQuery, UseQueryOptions } from 'react-query';
import { MediaView } from 'common';
import { QUERY_KEYS } from '@src/query';
import { getMedia } from './api';

/**
 * Find products linked to any media
 */
export const useQueryMedia = (mediaId: string, config?: UseQueryOptions<MediaView, unknown>) => useQuery({
  queryKey: QUERY_KEYS.media(mediaId),
  queryFn: async () => getMedia(mediaId),
  ...config,
});
