import React, { Component, ReactNode } from 'react';
import { classes, stylesheet } from 'typestyle';
import { Row } from '../../Utils';

interface Props {
  children?: ReactNode
  className?: string
}

class FormButtons extends Component<Props, {}> {
  public render() {
    const { className } = this.props;
    return (
      <Row className={classes(sheet.button, className)}>
        {this.props.children}
      </Row>
    );
  }
}

const sheet = stylesheet({
  button: {
    justifyContent: 'space-between',
  },
});

export { FormButtons };
