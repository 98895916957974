import React from 'react';
import { SearchSuggestion } from '@src/types/Filters';
import { TextEllipsis } from '@src/components/TextEllipsis';
import Tooltip from '@mui/material/Tooltip';
import { TOOLTIP_DELAY } from '@src/config';

const MAX_CHAR = 28;

interface Props {
  suggestion: SearchSuggestion
}

export function ProductItem({
  suggestion,
}: Props) {
  return (
    <Tooltip
      title={suggestion.display}
      placement="right"
      enterDelay={TOOLTIP_DELAY}
    >
      <span>
        <TextEllipsis
          maxChar={MAX_CHAR}
          clickable={false}
          text={suggestion.display}
          offset={4}
        />
      </span>
    </Tooltip>
  );
}
