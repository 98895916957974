import { HashSvgIcon } from '@src/components/SvgIcons/Hash';
import TextFieldsIcon from '@mui/icons-material/TextFields';
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { SvgIconComponent } from '@mui/icons-material/';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PortraitIcon from '@mui/icons-material/Portrait';
import { OverridableComponent } from '@mui/material/OverridableComponent';
import { SvgIconTypeMap } from '@mui/material/SvgIcon/SvgIcon';

type keys = 'text' | 'hashtags' | 'labels' | 'mentions' | 'tagged' | 'ownContent';

export const MARK_THEME: {
  [key in keys]: {
    title: string
    symbol: SvgIconComponent | OverridableComponent<SvgIconTypeMap>
  }
} = {
  text: {
    title: 'Caption',
    symbol: TextFieldsIcon,
  },
  hashtags: {
    title: 'Hashtags',
    symbol: HashSvgIcon,
  },
  labels: {
    title: 'Labels',
    symbol: VisibilityIcon,
  },
  mentions: {
    title: 'Mentions',
    symbol: AlternateEmailIcon,
  },
  tagged: {
    title: 'Tags',
    symbol: AccountCircleIcon,
  },
  ownContent: {
    title: 'Own Content',
    symbol: PortraitIcon,
  },
};
