import React from 'react';
import LocalizedStrings from 'react-localization';
import { RightRequestMQLStatus } from '@adalong/schemas';

interface Props {
  rrStatus: RightRequestMQLStatus
}

export function RightRequestStatusItem({ rrStatus }: Props) {
  return (
    <>
      {lang[rrStatus] || null}
    </>
  );
}

const lang = new LocalizedStrings({
  en: {
    agreed: 'Agreed',
    requested: 'Requested',
    unrequested: 'Unrequested',
  },
});
