import { useMutation } from 'react-query';
import { DEPRECATED_QUERY_KEYS, queryClient } from '@src/query';
import { ApiError } from '@src/services/api';
import { ApiTypes, WidgetView } from 'common';
import { createWidget } from './api';

export const useMutationCreateWidget = () => useMutation<WidgetView, ApiError, ApiTypes.BodyCreateWidget['widget']>((widget) => createWidget(widget), {
  onSuccess: async () => {
    const key = [DEPRECATED_QUERY_KEYS.WIDGETS];
    queryClient.invalidateQueries(key);
  },
});
