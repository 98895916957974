export enum Pathname {
  login = '/login',
  discover = '/discover',
  collect = '/collections',
  share = '/share',
  widget = '/widgets',
  create = '/videomaker',
  analyze = '/analyze',
  crm = '/crm',
  product = '/catalog',
  changePassword = '/changepassword',
  maintenance = '/maintenance',
  root = '/',
}

export enum PathAction {
  create = 'create',
}

export type PathArgs = Map<string, string>;
export interface ComposedPathName {
  root: Pathname;
  args: PathArgs;
  qs?: { [key: string]: string | string[] };
}

const IDArg = {
  id: 'id',
};

export const ArgsName = {
  create: IDArg,
  collect: IDArg,
  widget: IDArg,
  conversation: {
    targetId: 'target',
    interlocutorId: 'interlocutor',
    conversationId: 'conversation',
  },
  mediaId: 'mediaId',
};

export const QsNames = {
  // val: media id
  detailedMediaView: 'detailedMedia',

  // val: detailed media tab name
  detailedMediaViewTab: 'tab',

  // val: media Id
  getRightsModal: 'getRights',

  // val: media Id
  rightsActionRequiredModal: 'getRightsActionRequired',

  // val: list of media id
  mediaDownload: 'download',

  // val: 'true' or undefined
  discoverExactMode: 'exact',

  // val: typed by user
  discoverSearchText: 'caption',

  // val: hashtags selected
  discoverSearchHashtags: 'hashtag',

  // val: labels selected
  discoverSearchLabels: 'label',

  // val: (ig|twt)_(mention|hashtag)_[sourcename]
  discoverFiltersSource: 'source',

  // val: image video and text
  discoverFiltersMedia: 'media',

  // val: RightRequest Statuses
  discoverFiltersMediaRRStatuses: 'rrStatus',

  // val: creators names
  discoverFiltersCreator: 'creator',

  // var : own content
  discoverFiltersOwnContent: 'ownContent',

  // val: date or range
  discoverFiltersDate: 'date',

  // val: boolean
  discoverFiltersExact: 'exact',

  // val: lang list
  discoverFiltersLang: 'lang',

  // val: exact product ids
  discoverFiltersProductExact: 'productExact',

  // val: similar product ids
  discoverFiltersProductSimilar: 'productSimilar',

  // val: collection ids
  discoverFiltersCollection: 'collection',

  // val: creator countries
  discoverFiltersCreatorCountries: 'creatorCountries',
} as const;

export type QsFields = typeof QsNames[keyof typeof QsNames];

export type PathLocation = Pick<Location, 'pathname' | 'search'>;

export interface Browsing {
  goToPage(route: Pathname | ComposedPathName | string): void;
  setRoute(route: string): void;
}
