import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { ADALONG_COLORS } from '@src/styles';
import { style } from 'typestyle';

interface Props {
  fill: string
}

/**
 * The similar linking icon is to be activated/deactivated by the user
 * and allows them to link a product that is an similar match to a given UGC.
 */
export function LinkSimilarButton({fill}: Props) {
  return (
    <svg
      xmlns='http://www.w3.org/2000/svg'
      xmlnsXlink='http://www.w3.org/1999/xlink'
      version='1.1'
      width='25'
      height='25'
      viewBox='0 0 1000 1000'
      xmlSpace='preserve'
    >
      <defs></defs>
      <rect x='0' y='0' width='100%' height='100%' fill='rgba(0,0,0,0)' />
      <g transform='matrix(1.1344 0 0 1.1344 495.6127 502.309)' id='150471'>
        <g vector-effect='non-scaling-stroke'>
          <g transform='matrix(1 0 0 1 -0.5 -0.5)'>
            <path
              fill={fill}
              style={{
                stroke: 'none',
                strokeWidth: 1,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeDashoffset: 0,
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                fillOpacity: 0,
                fillRule: 'evenodd',
                opacity: 1,
              }}
              transform=' translate(-249.5, -212.5)'
              d='M -0.5 -0.5 C 166.167 -0.5 332.833 -0.5 499.5 -0.5 C 499.5 141.5 499.5 283.5 499.5 425.5 C 332.833 425.5 166.167 425.5 -0.5 425.5 C -0.5 283.5 -0.5 141.5 -0.5 -0.5 Z'
              stroke-linecap='round'
            />
          </g>
          <g transform='matrix(1 0 0 1 69.0621 -49.8696)'>
            <path
              fill={fill}
              style={{
                stroke: 'none',
                strokeWidth: 1,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeDashoffset: 0,
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                fillRule: 'evenodd',
                opacity: 1,
              }}
              transform=' translate(-319.0621, -163.1304)'
              d='M 330.5 49.5 C 365.254 48.1244 391.754 62.1244 410 91.5 C 425.719 124.462 421.719 154.795 398 182.5 C 372.528 206.972 347.361 231.805 322.5 257 C 301.668 274.279 278.001 280.279 251.5 275 C 239.709 270.859 229.876 264.025 222 254.5 C 215.341 240.809 218.841 230.642 232.5 224 C 237.589 223.174 242.589 223.508 247.5 225 C 251.783 227.615 255.783 230.615 259.5 234 C 265.5 237.762 271.833 238.428 278.5 236 C 287.929 231.898 296.262 226.231 303.5 219 C 326 196.5 348.5 174 371 151.5 C 384.572 128.624 380.406 109.79 358.5 95 C 347.629 89.4656 336.629 89.1323 325.5 94 C 315.196 103.303 305.196 112.97 295.5 123 C 282.48 129.93 272.314 127.096 265 114.5 C 262.215 106.847 263.215 99.8468 268 93.5 C 279.974 80.1853 293.14 68.352 307.5 58 C 314.899 54.1052 322.566 51.2719 330.5 49.5 Z'
              stroke-linecap='round'
            />
          </g>
          <g transform='matrix(1 0 0 1 -57.0857 52.215)'>
            <path
              fill={fill}
              style={{
                stroke: 'none',
                strokeWidth: 1,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeDashoffset: 0,
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                fillRule: 'evenodd',
                opacity: 1,
              }}
              transform=' translate(-192.9143, -265.215)'
              d='M 231.5 153.5 C 257.508 150.431 278.008 159.431 293 180.5 C 295.835 199.064 288.002 207.897 269.5 207 C 266.126 206.479 263.126 205.146 260.5 203 C 252.088 193.2 241.755 190.534 229.5 195 C 220.956 199.549 212.956 204.882 205.5 211 C 184.362 233.139 162.862 254.972 141 276.5 C 130.825 290.471 129.825 305.138 138 320.5 C 152.199 337.769 169.366 341.269 189.5 331 C 198.684 322.315 208.017 313.815 217.5 305.5 C 229.644 302.042 238.811 305.709 245 316.5 C 247.169 322.314 246.835 327.981 244 333.5 C 234.132 345.035 223.299 355.535 211.5 365 C 173.317 385.827 139.15 380.66 109 349.5 C 91.9067 325.937 87.9067 300.27 97 272.5 C 100.325 264.507 104.659 257.174 110 250.5 C 134.528 225.638 159.361 201.138 184.5 177 C 198.36 165.403 214.027 157.57 231.5 153.5 Z'
              stroke-linecap='round'
            />
          </g>
        </g>
      </g>
    </svg>
  );
}