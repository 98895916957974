import React, { Component } from 'react';
import { SelectableButton } from '@src/components/SelectableButton';
import { style } from 'typestyle';
import { ThemeStyle } from '@src/styles';
import { InfoSvgIcon } from '@src/components/SvgIcons/Info';
import { ShopSvgIcon } from '@src/components/SvgIcons/Shop';
import { CloseButton } from '@src/components/CloseButton';
import { DetailedMediaViewTab } from '@src/types/redux/common';
import SimilarIcon from './Components/SimilarIcon';

const ICON_SIZE = 24;
const ICONS: [DetailedMediaViewTab, JSX.Element][] = [
  ['similars', <SimilarIcon key="similarskey" style={{ width: ICON_SIZE }} />],
  ['product', <ShopSvgIcon key="productkey" size={ICON_SIZE} />],
  ['info', <InfoSvgIcon key="infokey" size={ICON_SIZE} />],
];

interface Props {
  active: DetailedMediaViewTab
  onSelectTab: (tab: DetailedMediaViewTab) => void
  tabs: { [key in DetailedMediaViewTab]: JSX.Element }
  disable?: {
    [key in DetailedMediaViewTab]?: {
      text?: string
      hide?: boolean
    }
  }
  onClose: () => void
}

export class DetailTabs extends Component<Props> {
  public render() {
    const {
      active,
      onSelectTab,
      tabs,
      disable = {},
    } = this.props;

    return (
      <>
        <div className={tabsClass}>
          {ICONS.map(([tab, icon]) => (
            !disable[tab]?.hide && (
            <div className={buttonClass} key={tab}>
              <SelectableButton
                onClick={() => onSelectTab(tab)}
                selected={tab === active}
                disable={disable[tab]?.text}
              >
                <div className={IconClass}>
                  {icon}
                </div>
              </SelectableButton>
            </div>
            )
          ))}
          <CloseButton
            size="large"
            classNames={[closeButtonClass]}
            onClick={this.props.onClose}
          />
        </div>
        <div className={contentClass}>
          {tabs[active]}
        </div>
      </>
    );
  }
}

const IconClass = style({
  padding: '0 6px 5px',
});

const tabsClass = style({
  padding: '10px 17px',
  borderBottom: ThemeStyle.borderSeparator,
  $debugName: 'tabs',
});

const buttonClass = style({
  padding: '0 5px',
  display: 'inline-block',
  $debugName: 'DetailTabButton',
});

const contentClass = style({
  flex: 1,
  overflow: 'hidden',
  position: 'relative',
  $debugName: 'content',
});

const closeButtonClass = style({
  float: 'right',
  marginTop: 10,
  marginRight: 10,
});
