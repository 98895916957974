// @ts-ignore

import React, { Component } from "react";
import { QueryClientProvider } from "react-query";
import "styles/cssImports";
import { Provider } from "react-redux";
import { Route, Router, Switch, Redirect, RouteComponentProps } from "react-router-dom";
import { NotFound } from "pages/Popups/components/NotFound";
import { Maintenance } from "pages/Popups/components/Maintenance";
import { RecoilRoot } from "recoil";
import { LoginDep } from "./pages/Login";
import ChangePassword from "./pages/Login/ChangePassword";
import { Collections } from "./pages/Collection/ListPage";
import { Collection } from "./pages/Collection/ViewPage";
import Share from "./pages/Share/SharePage";
import { VideoMaker } from "./pages/VideoMaker";
import { VideoMakers } from "./pages/VideoMakers";
import Popups from "./pages/Popups";
import WidgetList from "./pages/Widget/ListPage";
import WidgetEdit from "./pages/Widget/EditPage";
import { Oauth } from "./pages/Oauth";

import * as CommonActions from "./actions/common";

import { bigStore, reduxHistory } from "./store";

import { Services } from "./services";
import { ROOT_PATH } from "./config";
import { Start } from "./types/Start";
import { ServiceName } from "./types/Services";
import { DetailedMediaView } from "./pages/Media/DetailedMediaView";
import {
  ArgsName,
  Pathname,
  Browsing,
  PathAction,
  QsNames
} from "./types/Browsing";
import { WidgetCreate } from "./pages/Widget/CreateModal";
import { queryClient } from "./query";
import { ProductPage } from "./pages/Product/ProductPage";
import { OnQsMatch } from "./components/OnQsMatch";
import GetRightsController from "./pages/Media/GetRights/GetRightsController";
import DiscoverController from "./pages/Discover/DiscoverController";
import { MediaDownloadController } from "./pages/Media/Download/DownloadController";
import { AlertSnackbar } from "./fragments/Alerting/AlertSnackbar";
import { AnalyzePage } from "./pages/Analyze/AnalyzePage";
import { CRMPage } from "@src/pages/CRM/CRMPage";

class StaticContext {}

export class Root extends Component {
  private startService: Start;

  private browsingService: Browsing;

  constructor(props: any) {
    super(props);
    this.startService = Services.get(ServiceName.Start);
    this.browsingService = Services.get(ServiceName.Browsing);
    this.startService.init();

    window.addEventListener("resize", () => {
      bigStore.dispatch(
        CommonActions.windowResize(window.innerWidth, window.innerHeight)
      );
    });
    window.addEventListener("focus", () =>
      bigStore.dispatch(CommonActions.windowFocus(true))
    );
    window.addEventListener("blur", () =>
      bigStore.dispatch(CommonActions.windowFocus(false))
    );
  }

  public handleTouchStart(e: TouchEvent) {
    e.preventDefault();
  }

  public handleTouchMove(e: TouchEvent) {
    e.preventDefault();
  }

  public componentDidMount() {
    document.body.addEventListener("mousewheel", (e: Event) => {
      const delta = (e as WheelEvent).deltaX;
      if (delta !== 0) {
        e.preventDefault();
      }
    });

    document.addEventListener("touchstart", this.handleTouchStart, {
      passive: false
    });
    document.addEventListener("touchmove", this.handleTouchMove, {
      passive: false
    });
  }

  public onWindowClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    bigStore.dispatch(CommonActions.mouseClick(e));
  }

  public render() {
    return (
      <Provider store={bigStore}>
        <RecoilRoot>
          <Router history={reduxHistory}>
            <QueryClientProvider client={queryClient}>
              <div
                onClick={this.onWindowClick}
                style={{ height: "100%", width: "100%", position: "absolute" }}
              >
                <AlertSnackbar />
                <Popups services={Services} />
                <Switch>
                  <Route exact path="/login" component={LoginDep(Services)} />
                  <Route
                    exact
                    path="/changepassword"
                    component={ChangePassword}
                  />
                  <Route exact path="/oauth/:status" component={Oauth} />
                  <Redirect exact from="/" to={ROOT_PATH} />
                  <Route
                    exact
                    path={Pathname.discover}
                    render={() => <DiscoverController services={Services} />}
                  />
                  <Route
                    exact
                    path={`${Pathname.product}`}
                    render={() => <ProductPage services={Services} />}
                  />
                  <Route
                    exact
                    path={`${Pathname.analyze}`}
                    render={() => <AnalyzePage />}
                  />
                  <Route
                    exact
                    path={Pathname.collect}
                    component={Collections}
                  />
                  <Route
                    exact
                    path={`/collections/:${ArgsName.collect.id}`}
                    render={props => (
                      <Collection
                        services={Services}
                        collectionId={props.match.params[ArgsName.collect.id]!}
                      />
                    )}
                  />
                  <Route exact path="/share" component={Share} />
                  <Route
                    exact
                    path={`${Pathname.widget}/:opt(${PathAction.create})?`}
                    render={props => (
                      <WidgetList {...props} services={Services} />
                    )}
                  />
                  <Route
                    exact
                    path={`${Pathname.widget}/:${ArgsName.widget.id}`}
                    render={props => (
                      <WidgetEdit {...props} widgetId={props.match.params.id} />
                    )}
                  />
                  <Route
                    exact
                    path="/videomaker"
                    render={props => (
                      <VideoMakers {...props} services={Services} />
                    )}
                  />
                  <Route
                    exact
                    path={`/videomaker/:${ArgsName.create.id}`}
                    render={(
                      props:
                        | RouteComponentProps<
                            { [p: string]: string },
                            StaticContext,
                            unknown
                          >
                        | any
                    ) => <VideoMaker {...props} services={Services} />}
                  />

                  <Route
                    exact
                    path="/crm"
                    render={() => <CRMPage services={Services} />}
                  />

                  <Route
                    exact
                    path={`/crm/:${ArgsName.conversation.targetId}/:${ArgsName.conversation.interlocutorId}/:${ArgsName.conversation.conversationId}`}
                    render={(
                      props: RouteComponentProps<{
                        [p: string]: string | undefined;
                      }>
                    ) => (
                      <CRMPage
                        conversation={{
                          conversationId:
                            props.match.params[
                              ArgsName.conversation.conversationId
                            ] || "",
                          target:
                            props.match.params[
                              ArgsName.conversation.targetId
                            ] || "",
                          interlocutor:
                            props.match.params[
                              ArgsName.conversation.interlocutorId
                            ] || ""
                        }}
                        services={Services}
                      />
                    )}
                  />

                  <Route exact path="/maintenance" component={Maintenance} />
                  <Route path="*" component={NotFound} />
                </Switch>

                {/** Modals */}
                <Route
                  exact
                  path={[`${Pathname.collect}/:${ArgsName.collect.id}`, "*"]}
                  render={(
                    props:
                      | RouteComponentProps<
                          { [p: string]: string },
                          StaticContext,
                          unknown
                        >
                      | any
                  ) => (
                    <OnQsMatch
                      qsKey={QsNames.detailedMediaView}
                      match={val => !!val.length}
                    >
                      <DetailedMediaView
                        collectionId={props.match.params.id}
                        currentPathname={props.location.pathname}
                        services={Services}
                      />
                    </OnQsMatch>
                  )}
                />
                <Route
                  path="*"
                  render={props => (
                    <OnQsMatch
                      qsKey={QsNames.getRightsModal}
                      match={val => !!val.length}
                    >
                      <GetRightsController services={Services} />
                    </OnQsMatch>
                  )}
                />
                <Route
                  path="*"
                  render={props => (
                    <OnQsMatch
                      qsKey={QsNames.mediaDownload}
                      match={val => val.length > 0}
                    >
                      <MediaDownloadController
                        browsingService={this.browsingService}
                      />
                    </OnQsMatch>
                  )}
                />
                <Route
                  exact
                  path={`${Pathname.widget}/${PathAction.create}`}
                  render={() => (
                    <WidgetCreate
                      onClose={() =>
                        this.browsingService.goToPage(Pathname.widget)
                      }
                    />
                  )}
                />
              </div>
            </QueryClientProvider>
          </Router>
        </RecoilRoot>
      </Provider>
    );
  }

  private routedRender(
    Child: React.ComponentClass<any>,
    additionalProps: any = {}
  ) {
    return (props: any) => {
      const childProps = { ...props, ...additionalProps };
      return <Child {...childProps} />;
    };
  }
}
