import React from 'react';
import { SvgIconProps } from '@src/types/SvgIcon';

export function InfoSvgIcon({
  color = 'currentColor',
  size = 16,
  style = {},
}: SvgIconProps) {
  return (
    <svg fill={color} xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width={size} height={size}>
      <path d="M12.939 6.053c-.121-.093-.26-.159-.422-.196-.159-.037-.331-.056-.512-.056-.182 0-.354.019-.517.056-.163.037-.305.103-.426.196-.12.093-.215.217-.285.369-.07.153-.104.344-.104.574 0 .225.035.414.104.569.07.155.164.281.285.374.121.093.263.161.426.2.163.04.335.06.517.06.181 0 .353-.02.512-.06.162-.039.301-.108.422-.2.12-.093.217-.218.289-.374.072-.154.108-.344.108-.569 0-.23-.036-.422-.108-.574C13.156 6.27 13.059 6.146 12.939 6.053zM10.777 9.159H13.224V18.126H10.777z" />
      <path fill="none" d="M0 0H24V24H0z" />
      <path d="M12,0C5.373,0,0,5.373,0,12c0,6.627,5.373,12,12,12s12-5.373,12-12C24,5.373,18.627,0,12,0z M12,22 C6.477,22,2,17.523,2,12C2,6.477,6.477,2,12,2s10,4.477,10,10C22,17.523,17.523,22,12,22z" />
    </svg>
  );
}
