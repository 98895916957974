import { Select, SelectOption } from '@src/components/Select';
import React from 'react';
import LocalizedStrings from 'react-localization';
import { SortOrder, sortOrders } from 'common/dist/types/apiTypes';

interface Props {
  value: SortOrder
  onChange: (v: SortOrder) => void
}

export function SortOrderSelect({ value, onChange }: Props) {
  const options: SelectOption<SortOrder>[] = sortOrders.map((sortOrder) => ({
    value: sortOrder,
    text: lang[sortOrder],
  }));

  return (
    <Select
      options={options}
      onChange={onChange}
      value={value}
      variant="adalong"
    />
  );
}

const lang = new LocalizedStrings({
  en: {
    'name-asc': 'A -> Z',
    'name-desc': 'Z -> A',
    'relevance-desc': 'Relevance',
    'mediaCount-asc': 'Less media first',
    'mediaCount-desc': 'More media first',
  },
});
