import React from 'react';
import { PlatformIcon, PlatformIconProps } from './Platform';

export function DesktopIcon(props: PlatformIconProps) {
  return (
    <PlatformIcon {...props}>
      <path d="M1.42,38.13l61.08-.19v3.48a7,7,0,0,1-7,7H8.39a7,7,0,0,1-7-7V14.83a7,7,0,0,1,7-7H55.52a7,7,0,0,1,7,7v17.9" />
      <path d="M28.25,48.4S28,54.59,22,56.23" />
      <path d="M35.93,48.4s.26,6.19,6.3,7.83" />
      <line x1="18.04" x2="45.88" y1="56.23" y2="56.23" />
      <line x1="31.02" x2="32.9" y1="43" y2="43" />
    </PlatformIcon>
  );
}
