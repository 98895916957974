import { PerfectScrollbar } from '@src/components/PerfectScrollbar';
import { px } from 'csx';
import React from 'react';
import { stylesheet } from 'typestyle';
import { Box } from '@mui/system';
import { Loader } from '@src/components/Loader';

export function MessagesScroller(props: {
  items: JSX.Element[];
  isLoading: boolean;
}) {
  return (
    <PerfectScrollbar className={sheet.container}>
      {props.isLoading ? (
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
          }}
        >
          <Loader />
        </Box>
      ) : props.items.length === 0 ? (
        'No messages'
      ) : null}
      {props.items}
    </PerfectScrollbar>
  );
}

const sheet = stylesheet({
  container: {
    width: '100%',
    padding: `0 ${px(5)}`,
  },
});
