import React, { HTMLAttributes } from 'react';
import { stylesheet } from 'typestyle';

interface Props extends HTMLAttributes<HTMLDivElement> {
  value: string
}

function FormTitle(props: Props) {
  const { value } = props;

  return (
    <div className={sheet.title}>
      {value}
    </div>
  );
}

const sheet = stylesheet({
  title: {
    fontSize: 26,
    fontWeight: 700,
    marginBottom: 20,
  },
});

export { FormTitle };
