import React, {useEffect, useState} from 'react';
import { SearchSuggestion } from '@src/types/Filters';
import { Suggestions } from '../Suggestions';
import { CreatorItem } from '../FilterItem/CreatorItem';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    FacetState,
    FilterState
} from '@src/pages/Discover/components/Atoms/Atoms';
import { useMediaFindFacet } from "@src/queries/media/useQueryMediaFind";
import { onFilterChange } from "@src/fragments/Filters/utils/FilterStateUtility";
import { XclusionUtility } from "@src/fragments/Filters/managers/XclusionUtility";
import {creatorInput, creatorExcludeState} from "@src/pages/Discover/components/Selectors/Selectors";

interface Props {
    influencers?: SearchSuggestion[],
}

export function CreatorsSuggestion({ influencers }: Props) {
  const [filters, setFilters ] = useRecoilState(FilterState)
  const [facetState, ] = useRecoilState(FacetState)

  const filterCreatorInput = useRecoilValue(creatorInput)
  const exclude = useRecoilValue(creatorExcludeState)

  const {
    data: resFilterCreator,
    isLoading: isLoadingFilterCreator,
  } = useMediaFindFacet(
      facetState.mediaConstraints,
      filterCreatorInput,
      'creators',
      facetState.typingDebounce,
      facetState.enableFacet,
  );
 const creators = resFilterCreator?.pages[0].facets?.creators;
 const [suggestions, setSuggestions] = useState(influencers? influencers
     : creators?.map(
      (value) => ({
        key: value,
        display: `${value}`,
      }),
  ) ?? [])

  useEffect(() => {
      if (!influencers && !isLoadingFilterCreator && resFilterCreator?.pages[0].facets?.creators) {
          setSuggestions(resFilterCreator?.pages[0].facets?.creators.map(
              (value) => ({
                  key: value,
                  display: `${value}`,
              }),
          ))
      }
  }, [isLoadingFilterCreator, resFilterCreator])

  const selectedSuggestions = influencers ?
      influencers.filter((inf) => filters.creator.find((c) => XclusionUtility.getRawKey(c) === XclusionUtility.getRawKey(inf.key)))
      : filters.creator.map(
      (value) => ({
          key: value,
          display: `${XclusionUtility.getRawKey(value)}`,
      })
  );

 return (
    <Suggestions
      onChange={onFilterChange(setFilters, 'creator')}
      selectedValues={renderValues(selectedSuggestions)}
      values={renderValues(suggestions)}
      exclude={exclude}
    />
  );
}

function renderValues(suggestions: SearchSuggestion[]) {
  return suggestions.map((suggestion) => ({
    key: suggestion.key,
    children: (
      <CreatorItem suggestion={suggestion} />
    ),
  }));
}
