import React, { PropsWithChildren } from 'react';
import { QsNames } from '@src/types/Browsing';
import { useLocationQS } from '@src/utils/useLocationQS';

interface Props {
  qsKey: (typeof QsNames)[keyof typeof QsNames]
  match: (val: string[]) => boolean
}

export function OnQsMatch({
  qsKey,
  match,
  children,
}: PropsWithChildren<Props>) {
  const {
    [qsKey]: val,
  } = useLocationQS([qsKey]);
  if (match(val)) {
    return <>{children}</>;
  }
  return null;
}
