import React, { ReactNode, MouseEventHandler } from 'react';
import { Price } from '@adalong/schemas';
import { style, classes } from 'typestyle';
import LocalizedStrings from 'react-localization';
import Collapse from '@mui/material/Collapse';
import { ProductGroupView, ProductView } from 'common';
import { Column } from '../../Utils';
import { FolderButton } from '../../FolderButton';
import { ProductCardData } from '../ProductCard';
import { Hide } from '../../Hide';
import { ProductGroupRow } from './ProductGroupRow';

interface Props {
  productGroup: ProductGroupView;
  customRenderer?: {
    buttons?: (product?: ProductView, isSingleProduct?: boolean) => ReactNode;
  } & ProductGroupCustomRenderer;
  /**
   * Control
   */
  expanded: boolean;
  productCountFormatter: ProductCountFormatter;
  /**
   * Handler for product and product groups clicks
   * Depending on the target the click was performed on,
   * one field will be null
   */
  onProductClick?: (target: {
    productId: string | null;
    productGroup: ProductGroupView | null;
  }) => void;
  onExpandClick?: () => void;
}

export interface ProductGroupCustomRenderer {
  imageButtons?: (product?: ProductView) => ReactNode;
  postDataElement?: (product?: ProductView) => ReactNode;
}

type ProductCountFormatter = (count: number) => string | ReactNode;

export function ProductGroup({
  productGroup,
  customRenderer,
  expanded,
  onExpandClick,
  productCountFormatter,
  onProductClick,
}: Props) {
  const mainCardData = getMainCardData(productGroup, productCountFormatter);
  if (mainCardData === null) {
    return null;
  }
  const singleProduct = productGroup.products.length === 1;

  return (
    <div>
      {/* Main Row */}
      <ProductGroupRow
        data={mainCardData}
        imageLinks={productGroup.products.map(({ image_link }) => image_link)}
        imageButtons={customRenderer?.imageButtons?.()}
        postDataElement={customRenderer?.postDataElement?.()}
        buttons={
          <Column
            className={classes(productGroupButtonsClass, {
              [productSingleButtonClass]: singleProduct,
            })}
          >
            <Hide hide={singleProduct}>
              <div onClick={handleToggleFolder}>
                <FolderButton direction='vertical' opened={expanded} />
              </div>
            </Hide>
            {customRenderer?.buttons?.(undefined, singleProduct)}
          </Column>
        }
        onImageClick={!singleProduct ? handleToggleFolder : null}
        onProductClick={
          !onProductClick
            ? undefined
            : () =>
                onProductClick({
                  productId: singleProduct ? productGroup.products[0].id : null,
                  productGroup: !singleProduct ? productGroup : null,
                })
        }
      />

      {/* Sub Row */}
      <Hide hide={singleProduct}>
        <Collapse in={expanded}>
          <div className={subCardsClass}>
            {productGroup.products.map((product) => (
              <ProductGroupRow
                key={product.id}
                imageLinks={[product.image_link]}
                data={{
                  description: product.description,
                  price: formatProductPrice(product),
                  title: {
                    text: product.title,
                    tooltip: formatProductTooltip(product),
                  },
                  link: product.link,
                }}
                imageButtons={customRenderer?.imageButtons?.(product)}
                postDataElement={customRenderer?.postDataElement?.(product)}
                buttons={
                  <Column
                    className={classes(productGroupButtonsClass, productSingleButtonClass)}
                    style={{ justifyContent: 'flex-end' }}
                  >
                    {customRenderer?.buttons?.(product, false)}
                  </Column>
                }
                onProductClick={
                  !onProductClick
                    ? undefined
                    : () =>
                        onProductClick({
                          productId: product.id,
                          productGroup: null,
                        })
                }
              />
            ))}
          </div>
        </Collapse>
      </Hide>
    </div>
  );

  function handleToggleFolder(e?: Parameters<MouseEventHandler<HTMLDivElement>>[0]) {
    e?.stopPropagation();
    onExpandClick?.();
  }
}

function getMainCardData(
  productGroup: ProductGroupView,
  productCountFormatter: ProductCountFormatter
): ProductCardData | null {
  const [firstProduct] = productGroup.products;
  const singleProduct = productGroup.products.length === 1;
  if (!firstProduct) {
    return null;
  }

  if (singleProduct) {
    // when productGroup with single product
    return {
      description: firstProduct.description,
      price: formatProductPrice(firstProduct),
      title: {
        text: firstProduct.title,
        tooltip: formatProductTooltip(firstProduct),
      },
      link: firstProduct.link,
    };
  }
  // when productGroup with products
  return {
    description: (
      <>
        {productCountFormatter(productGroup.products.length)}
        {` ${productGroup.name}`}
      </>
    ),
    price: formatProductGroupPrice(productGroup),
    title: {
      text: productGroup.name,
      tooltip: productGroup.name,
    },
    link: null,
  };
}

function formatProductTooltip(product: ProductView): string {
  return `(${product.id}) ${product.title}: ${product.description}`;
}

function formatProductPrice(product: ProductView): string {
  if (!product.price) {
    return '';
  }
  return formatCurrency(product.price);
}

function formatProductGroupPrice(productGroup: ProductGroupView): string {
  const lowerPrice = productGroup.products.reduce<number | null>((prev, { price }) => {
    if (prev === null) {
      return price?.amount || null;
    }
    if (price?.amount !== undefined) {
      return prev < price.amount ? prev : price.amount;
    }
    return prev;
  }, null);
  if (lowerPrice === undefined || lowerPrice === null) {
    return '';
  }
  const currency = productGroup.products.find((product) => product.price?.currency)?.price
    ?.currency;
  const formattedPrice = formatCurrency({
    amount: lowerPrice,
    currency: currency || 'eur',
  });
  return `${lang.priceFrom} ${formattedPrice}`;
}

function formatCurrency(price: Price): string {
  const formatter = new Intl.NumberFormat('en-EN', {
    style: 'currency',
    currency: price.currency.toUpperCase(),
  });
  return formatter.format(price.amount);
}

const lang = new LocalizedStrings({
  en: {
    priceFrom: 'From',
  },
});

const subCardsClass = style({
  paddingLeft: 25,
});

const productGroupButtonsClass = style({
  flex: 'initial',
  flexShrink: 0,
  justifyContent: 'space-between',
});

const productSingleButtonClass = style({
  justifyContent: 'flex-end',
  marginBottom: 8,
});
