import React, { Component } from 'react';
import * as reduxHelpers from '@src/services/helpers/redux';

import { FolderButton } from '@src/components/FolderButton';
import { classes, stylesheet } from 'typestyle';
import TuneIcon from '@mui/icons-material/Tune';

interface Props {
  unfold: boolean
  onUnfold: (unfold: boolean) => void
}

export class FilterTitle extends Component <Props> {
  public onClick() {
    this.props.onUnfold(!this.props.unfold);
    reduxHelpers.triggerResize();
  }

  public render() {
    return (
      <div className={sheet.header} onClick={this.onClick.bind(this)}>
        <div>
          <TuneIcon className={sheet.icon} />
          <span className={sheet.title}>Filter</span>
        </div>
        <div className={classes('right', sheet.fold)}>
          <FolderButton
            size={22}
            opened={this.props.unfold}
            direction="horizontal"
          />
        </div>
      </div>
    );
  }
}

const sheet = stylesheet({
  header: {
    display: 'flex',
    justifyContent: 'space-between',
    padding: '0 20px',
    cursor: 'pointer',
  },
  title: {
    fontSize: 20,
    verticalAlign: 'middle',
  },
  icon: {
    marginRight: 9,
    verticalAlign: 'middle',
  },
  fold: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    marginRight: 2,
  },
});
