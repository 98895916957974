import React from 'react';
import { SearchSuggestion, SourceSearchSuggestion } from '@src/types/Filters';
import { Suggestions } from '../Suggestions';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
    FacetState,
    FilterState
} from '@src/pages/Discover/components/Atoms/Atoms';
import { onFilterChange } from '@src/fragments/Filters/utils/FilterStateUtility';
import { ownContentInput, ownContentExcludeState } from '@src/pages/Discover/components/Selectors/Selectors';
import { SourceItem } from '@src/fragments/Filters/Search/FilterItem/SourceItem';
import { style } from 'typestyle';
import { getSelectedSuggestion, getSuggestions } from '@src/fragments/Filters/Search/Suggestions/SourcesSuggestion';

export function OwnContentSuggestion() {
    const [filters, setFilters] = useRecoilState(FilterState)
    const [facetState,] = useRecoilState(FacetState)

    const filterOwnContentInput = useRecoilValue(ownContentInput)
    const exclude = useRecoilValue(ownContentExcludeState)
    const sourceState = facetState.mediaConstraints.state

    const values =  getSuggestions(sourceState, filterOwnContentInput).filter((s) => s.type === 'ownContent');
    const selectedValues = getSelectedSuggestion(filters.ownContent);

    return (
        <Suggestions
            onChange={onFilterChange(setFilters, 'ownContent')}
            selectedValues={renderValues(selectedValues)}
            values={renderValues(values)}
            exclude={exclude}
        />
    );
}

function renderValues(suggestions: SearchSuggestion<SourceSearchSuggestion>[]) {
    return suggestions.map((suggestion) => ({
        key: suggestion.key,
        children: (
          <SourceItem
            suggestion={suggestion}
            classNames={[sourceItemClass]}
            style={{
                typeNameSpacing: 5,
            }}
          />
        ),
    }));
}

const sourceItemClass = style({
    marginRight: 15,
});
