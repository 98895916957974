import { useLocation } from 'react-router-dom';
import { VALUE_SEPARATOR } from './browsing';

export function useLocationQS<T extends string>(keys: T[]): { [key in T]: string[] } {
  const search = new URLSearchParams(useLocation().search);
  return parseUrlSearch(search, keys);
}

export function parseUrlSearch<T extends string>(search: URLSearchParams, keys: T[]): { [key in T]: string[] } {
  const q: any = {};
  keys.forEach((k) => {
    q[k] = search.get(k)?.split(VALUE_SEPARATOR) || [];
  });
  return q;
}
