import React from 'react';
import { IconRender, InheritProps } from './../IconRender';

export function PortraitIcon(props: InheritProps) {
  return (
    <IconRender
      {...props}
      childrenRender={(color: string) => (
        <>
          <rect
            x='2'
            y='2'
            width='66'
            height='66'
            rx='8'
            fill='#343434'
            stroke={color}
            strokeWidth='4'
          />
          <rect x='20' y='16' width='30' height='37' rx='5' fill='#D9D9D9' />
        </>
      )}
    />
  );
}
