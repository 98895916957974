import { useInfiniteQuery } from 'react-query';
import { QUERY_KEYS } from '@src/query';
import { getStoredConversations } from './api';
import { ApiTypes } from 'common';

/**
 * Return the list of stored conversations, enriched with VDM and Saul data.
 */
export const useQueryStoredConversations = () => {
  return useInfiniteQuery<ApiTypes.ResGetStoredConversations>(
    QUERY_KEYS.conversations(),
    ({ pageParam: cursor }) => getStoredConversations(cursor)
  );
};
