import React, { Component } from 'react';

import * as reduxHelpers from '@src/services/helpers/redux';
import translation from './translation';

import styles from './styles.scss';

const lang = reduxHelpers.loadTranslation(translation);

interface IProps {

}

interface IState {

}

export class Maintenance extends Component <IProps, IState> {
  public render() {
    return (
      <div className={styles.maintenance}>
        <h1>
          {lang.message}
        </h1>
      </div>
    );
  }
}
