import { style, classes } from 'typestyle';
import React from 'react';
import NavigateBefore from '@mui/icons-material/NavigateBefore';
import NavigateNext from '@mui/icons-material/NavigateNext';
import { MediaView } from 'common';

interface Props {
  openMedia: (media: MediaView) => void
  carouselMedias?: MediaView[]
  displayOnHover: boolean
  currentId: string
}

export const CarouselButtons = (props: Props) => {
  const {
    openMedia, carouselMedias, displayOnHover, currentId,
  } = props;

  const currentElm = carouselMedias?.find((elm) => elm._id === currentId);
  const currentIndex = (currentElm && carouselMedias && carouselMedias.indexOf(currentElm)) ?? -1;
  const noCarousel = !carouselMedias || carouselMedias.length <= 1 || !displayOnHover;
  const hideBackButton = noCarousel || currentIndex <= 0;
  const hideNextButton = noCarousel || carouselMedias && currentIndex === carouselMedias.length - 1;
  const prevMedia = carouselMedias && carouselMedias[currentIndex - 1];
  const nextMedia = carouselMedias && carouselMedias[currentIndex + 1];

  return (
    <>
      <div
        className={classes(nextButtonClass, hideBackButton && hiddenButton)}
        style={{ left: 0 }}
        onClick={() => prevMedia && openMedia(prevMedia)}
      >
        <NavigateBefore />
      </div>
      <div
        className={classes(nextButtonClass, hideNextButton && hiddenButton)}
        style={{ right: 0 }}
        onClick={() => nextMedia && openMedia(nextMedia)}
      >
        <NavigateNext />
      </div>
    </>
  );
};

const nextButtonClass = style({
  width: 40,
  height: 40,
  margin: 20,
  textAlign: 'center',
  borderRadius: 20,
  cursor: 'pointer',
  position: 'absolute',
  bottom: 'calc(50% - 40px)',
  backgroundColor: '#97979797',
  fontSize: 18,
  color: 'white',
  justifyContent: 'center',
  lineHeight: '40px',
  userSelect: 'none',
  transition: 'visibility 0.3s, opacity 0.3s linear',
});

const hiddenButton = style({
  visibility: 'hidden',
  opacity: 0,
});
