import { CLOUDFLARE_TURNSTILE, CLOUDFLARE_TURNSTILE_VERSION } from 'config';
import { useEffect } from 'react';
import React from 'react';
import { stylesheet } from 'typestyle';

type IProps = {
    onTokenChange: (token: string) => void;
};

/**
 * Called "Captcha" for convenience, we use Turnstile from Cloudflare
 */
export default function Captcha(props: IProps): JSX.Element {

    function resultSuccess(token: string) {
        props.onTokenChange(token);
    }

    /**
     * Here the widget is generated
     * windows.turnstile is a custom value, take a look for more parameters
     */
    function generateTurnstile() {
        window.turnstile?.render("#turnstileCaptcha", {
            sitekey: CLOUDFLARE_TURNSTILE,
            theme: "dark",
            appearance: "interaction-only",
            callback: resultSuccess
        })
    }

    /**
     * This function is used to inject the script from CloudFlare
     * It create a new script element
     */
    function inject() {
        const script = document.createElement('script');
        script.id = "injected_cfturnstile";
        script.src = `https://challenges.cloudflare.com/turnstile/v${CLOUDFLARE_TURNSTILE_VERSION}/api.js`;
        script.defer = true;
        script.async = true;
        script.onload = generateTurnstile
        document.body.appendChild(script);
    }

    /**
     * We can call it the "entry point"
     */
    useEffect(() => {
        // We do nothing if script is already injected
        if (!window.turnstile) {
            inject();
        }
        else {
            generateTurnstile();
        }
    }, []);

    return (
        <div id="turnstileCaptcha" className={sheet.captchaRow}></div>
    )
}

const sheet = stylesheet({
    captchaRow: {
        width: "100%",
        $nest: {
            '> iframe': {
                width: "inherit !important",
            },
        }
    },
});