import { useMutation } from 'react-query';
import { ApiError } from '@src/services/api';
import { ApiTypes, RightRequestView } from 'common';
import { queryClient, QUERY_KEYS, QUERY_PREFIX } from '@src/query';
import { updateMediaFindData } from './useQueryMediaFind';
import { updateRightsRequest } from './api';

export const useMutationRightsRequestUpdate = () => useMutation<ApiTypes.ResPostRightsRequest, ApiError, {
  mediaId: string
  rightsRequest: ApiTypes.ParamsPostRightsRequest
}>(({ mediaId, rightsRequest }) => updateRightsRequest(mediaId, rightsRequest), {
  onSuccess: async (res) => {
    // update optimistically for user satisfaction
    // and then refresh media to have perfectly updated results
    res.forEach((rr: RightRequestView) => {
      updateMediaFindData(rr.mediaId, { rightsRequestStatus: rr.status });
      // refetch media for detailed view
      queryClient.refetchQueries(QUERY_KEYS.media(rr.mediaId));
    });
    queryClient.invalidateQueries(QUERY_KEYS.mediaFind());
    queryClient.invalidateQueries(QUERY_KEYS.collectionsMedia());
    queryClient.invalidateQueries([QUERY_PREFIX.MEDIA_SIMILAR]);
    queryClient.invalidateQueries([QUERY_PREFIX.MEDIA_CAROUSEL]);
  },
});
