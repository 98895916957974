import React, { useState, useEffect } from 'react';

import { ICombinedReducers } from '@src/types/redux';
import { useSelector } from 'react-redux';
import NavBar from 'components/NavBar';
import { Pathname } from '@src/types/Browsing';
import LocalizedStrings from 'react-localization';
import { SelectableButton } from '@src/components/SelectableButton';
import { ADALONG_COLORS, ThemeStyle } from '@src/styles';
import { Alert } from '@mui/material';
import { classes, style } from 'typestyle';
import Snackbar from '@mui/material/Snackbar';
import { ProductsLocalizationMapping, WidgetView } from 'common';
import TuneIcon from '@mui/icons-material/Tune';
import { Loader } from '@src/components/Loader';
import { useQueryWidgetLocalized } from '@src/queries/widgets/useQueryWidgetLocalized';
import { useQueryProductsAvailableCountries } from '@src/queries/products/useQueryProducts';
import { getSourceType } from './utils/sourceType';
import { Export } from './Edit/Export';
import { Preview } from './Edit/Preview';
import { Settings } from './Edit/Settings';
import { useQueryUserConfig } from '@src/queries/users/useQueryUserConfig';

export type ProductDataString = string;
export type CollectionDataString = string;

interface Props {
  widgetId: string;
}

export type EditedWidget = Pick<WidgetView, 'name' | 'description' | 'settings'> & { active?: boolean };

export default function Widget(props: Props) {
  // Config queries
  const { data: userConfig } = useQueryUserConfig();
  const { data: localizedWidgetList, error, isLoading } = useQueryWidgetLocalized(props.widgetId);
  const { data: productCountryMappings } = useQueryProductsAvailableCountries();

  const windowHeight = useSelector((state: ICombinedReducers) => state.common.windowSize.height);

  const [rightTab, setRightTab] = useState<'preview' | 'export'>('preview');
  const [editedWidget, setEditedWidget] = useState<EditedWidget | undefined>(
    undefined,
  ); // currently edited widget state

  // Current widget settings based on the current localization
  const [currentLocalization, setCurrentLocalization] = useState<string | undefined>(
    localizedWidgetList?.find((w) => w.active)?.settings.localization,
  );
  const [currentLocalizedWidget, setCurrentLocalizedWidget] = useState<WidgetView | undefined>(
    undefined,
  );

  // Available localizations for the user
  const [userCountries, setUserCountries] = useState<string[]>([]);
  const [userAvailableLocalizations, setUserAvailableLocalizations] = useState<ProductsLocalizationMapping[]>([]);

  useEffect(() => {
    if (userConfig) {
      setUserCountries(userConfig.user.catalogCountries);
    }
  }, [userConfig]);

  useEffect(() => {
    // change current widget
    if (localizedWidgetList?.length) {
      setEditedWidget({
        ...localizedWidgetList[0],
        settings: { 
          ...localizedWidgetList[0].settings,
          localization: undefined, // when the user is not able to edit the localized settings of the current widget's localization
         },
      });
    }

    const currentLoc = localizedWidgetList?.find((w) => w.active)?.settings.localization;
    if (currentLoc && userAvailableLocalizations.some((loc) => loc.isoCode === currentLoc)) {
      setCurrentLocalization(currentLoc);
    }
  }, [localizedWidgetList, userAvailableLocalizations]);

  useEffect(() => {
    if (currentLocalization) {
      // Find the correct widget based on the current localization
      const localizedWidget = localizedWidgetList?.find(
        (w) => w.settings.localization === currentLocalization,
      );
      if (localizedWidget) {
        setCurrentLocalizedWidget(localizedWidget);
        setEditedWidget(localizedWidget);
      }
    } else {
      // if no localization is set, use the first widget
      setCurrentLocalizedWidget(localizedWidgetList?.[0]);
      setEditedWidget(localizedWidgetList?.[0]);
    }
  }, [currentLocalization, localizedWidgetList]);

  useEffect(() => {
    // Filter available localizations based on the user's catalog countries
    if (productCountryMappings) {
      const availableLocalizations = productCountryMappings.res.filter(
        (mapping) => userCountries.includes(mapping.isoCode),
      );
      setUserAvailableLocalizations(availableLocalizations);
    } else {
      setUserAvailableLocalizations([]);
    }
  }, [productCountryMappings, userCountries]);

  const productStr: ProductDataString = getSourceStr(
    editedWidget?.settings.default_sources?.product_list,
    currentLocalizedWidget?.settings.default_sources?.product_list,
  );
  const collectionStr: CollectionDataString = getSourceStr(
    editedWidget?.settings.default_sources?.collection_list,
    currentLocalizedWidget?.settings.default_sources?.collection_list,
  );

  return (
    <div className={pageClass} style={{ height: windowHeight }}>
      <NavBar active={Pathname.widget} />
      {currentLocalizedWidget && editedWidget && (
        <div className={widgetClass}>
          <div className={settingsColumnClass}>
            <div className={columnTitlesClass}>
              <SelectableButton selected={false}>
                <div className={columnTitleTextClass}>
                  <TuneIcon />
                  <span className={settingsTitleClass}>{lang.settings}</span>
                </div>
              </SelectableButton>
            </div>

            {currentLocalizedWidget.live_status?.domain && (
              <div className={statusInfoClass}>
                <span className={statusDotClass}> ● </span>
                {lang.status + currentLocalizedWidget.live_status?.domain}.
              </div>
            )}

            <Settings
              remoteWidget={currentLocalizedWidget}
              editedWidget={editedWidget}
              setEditedWidget={setEditedWidget}
              productCountryMappings={userAvailableLocalizations}
              setCurrentLocalization={setCurrentLocalization}
            />
          </div>
          <div className={previewColumnClass}>
            <div className={classes(columnTitlesClass, columnRightClass)}>
              <SelectableButton
                selected={rightTab === 'preview'}
                onClick={() => setRightTab('preview')}
              >
                <div className={columnTitleTextClass}>{lang.preview}</div>
              </SelectableButton>
              <SelectableButton
                selected={rightTab === 'export'}
                onClick={() => setRightTab('export')}
              >
                <div className={columnTitleTextClass}>{lang.script}</div>
              </SelectableButton>
            </div>
            <div className={contentClass}>
              <div>
                {rightTab === 'preview' ? (
                  <Preview
                    token={currentLocalizedWidget.token}
                    settings={editedWidget.settings}
                    products={productStr}
                    collections={collectionStr}
                    displaySelectMessage={!atLeastOneSource(editedWidget)}
                  />
                ) : null}
                {rightTab === 'export' ? (
                  <Export
                    token={currentLocalizedWidget.token}
                    products={productStr}
                    collections={collectionStr}
                    type={getSourceType(currentLocalizedWidget)}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      )}
      <Snackbar open={!!error} autoHideDuration={6000}>
        <Alert severity='error' variant='filled'>
          {lang.error.load}
        </Alert>
      </Snackbar>
      {!error && isLoading && (
        <div className={loaderClass}>
          <Loader size={40} />
        </div>
      )}
    </div>
  );
}

function getSourceStr(editedSources: string[] = [], remoteSource: string[] = []) {
  let source = '';
  // we don't want to export defaults sources
  const syncWithRemote = editedSources.sort().join() === remoteSource.sort().join();
  if (editedSources.length && !syncWithRemote) {
    source = JSON.stringify(editedSources);
  }
  return source;
}

function atLeastOneSource(editedWidget?: EditedWidget): boolean {
  const products = editedWidget?.settings.default_sources?.product_list?.length;
  const collections = editedWidget?.settings.default_sources?.collection_list?.length;
  return !!(products || collections);
}

const lang = new LocalizedStrings({
  en: {
    title: 'Widget',
    settings: 'Settings',
    preview: 'Preview',
    script: 'Script',
    noSourceToExport: 'Select a source first',
    error: {
      load: 'Cannot load widget',
    },
    status: 'This widget is currently live on ',
  },
});

const pageClass = style({
  display: 'flex',
  flexDirection: 'column',
  height: '100%',
});

const loaderClass = style({
  textAlign: 'center',
  marginTop: '22vh',
});

const widgetClass = style({
  display: 'flex',
  flexDirection: 'row',
  flex: 1,
  overflow: 'hidden',
  margin: '30px 30px 0',
});

const settingsColumnClass = style({
  flexShrink: 0,
  minWidth: '200px',
  display: 'flex',
  flexDirection: 'column',
});

const previewColumnClass = style({
  flex: 1,
  display: 'flex',
  flexDirection: 'column',
  overflow: 'hidden',
});

const columnTitlesClass = style({
  $debugName: 'columnTitles',
  flexShrink: 0,
  borderBottom: '1px solid #979797',
  padding: '9px 0',
  $nest: {
    '& > div': {
      marginRight: '30px',
    },
  },
});

const columnRightClass = style({
  paddingLeft: 30,
});

const contentClass = style({
  display: 'flex',
  flex: 1,
  overflow: 'hidden',
  justifyContent: 'center',
  borderLeft: '1px solid #979797',
  $nest: {
    '& > div': {
      width: '100%',
      position: 'relative',
      height: '100%',
      padding: '15px 15px 0',
    },
  },
});

const columnTitleTextClass = style({
  color: 'white',
  fontSize: ThemeStyle.textSizes.big,
  fontWeight: 'bold',
  textTransform: 'uppercase',
  $nest: {
    '&[data-disabled="true"]': {
      color: ADALONG_COLORS.LIGHT_GRAY,
    },
  },
});

const settingsTitleClass = style({
  marginLeft: 10,
});

const statusDotClass = style({
  color: '#2a7d4b',
});

const statusInfoClass = style({
  marginTop: 10,
  fontSize: ThemeStyle.textSizes.medium,
  $nest: {
    '&[data-disabled="true"]': {
      color: ADALONG_COLORS.LIGHT_GRAY,
    },
  },
});
