import cloneDeep from 'lodash.clonedeep';
import { DisplayPreviewView, DisplayPreviewContentView } from 'common';
import { TShareCollection, ShareState } from '@src/types/redux/share';
import * as ACTIONS from '../actions/_types';

const initialState: ShareState = {
  loadedCollections: {},
  loadingCollections: {},
  openedCollection: {},
  sharePrompt: null,
  sharePopup: null,

  publishing: false,
  publishError: null,

  displayPreview: null,
};

export type Ishare = ShareState;

export type ShareAction = any;

const reducers = {
  [ACTIONS.LOAD_SHARE_COLLECTION](
    state: Ishare,
    action: { id: string; collection: TShareCollection },
  ): ShareState {
    const loadedCollections = cloneDeep(state.loadedCollections);
    if (loadedCollections[action.id]?.medias && action.collection.medias && action.collection.cursor) {
      action.collection.medias = loadedCollections[action.id].medias!.concat(action.collection.medias);
    }
    loadedCollections[action.id] = action.collection;
    return {
      ...state,
      loadedCollections: {
        ...state.loadedCollections,
        ...loadedCollections,
      },
    };
  },
  [ACTIONS.LOADING_SHARE_COLLECTION](
    state: Ishare,
    action: { id: string; loading: boolean },
  ): ShareState {
    const loadingCollections = { ...state.loadingCollections };
    loadingCollections[action.id] = action.loading;
    return {
      ...state,
      loadingCollections,
    };
  },
  [ACTIONS.OPEN_SHARE_COLLECTION](
    state: Ishare,
    action: { id: string; open: boolean },
  ): ShareState {
    return {
      ...state,
      openedCollection: {
        ...state.openedCollection,
        [action.id]: action.open,
      },
    };
  },

  [ACTIONS.SET_SHARE_PROMPT](state: Ishare, action: any): ShareState {
    return {
      ...state,
      sharePrompt: action.info,
    };
  },
  [ACTIONS.OPEN_SHARE_POPUP](state: Ishare, action: any): ShareState {
    const sharePopup = cloneDeep(action.info);
    sharePopup.target = action.target;
    return {
      ...state,
      sharePopup: {
        ...state.sharePopup,
        ...sharePopup,
      },
    };
  },
  [ACTIONS.CLOSE_SHARE_POPUP](state: Ishare): ShareState {
    return {
      ...state,
      sharePopup: null,
      publishError: null,
      publishing: false,
    };
  },
  [ACTIONS.PUBLISHING](state: Ishare): ShareState {
    window.analytics?.trackEvent(ACTIONS.PUBLISHING, 'ReduxShare');
    return {
      ...state,
      publishing: true,
      publishError: null,
    };
  },
  [ACTIONS.PUBLISH_FAILED](state: Ishare, action: { error: any }): ShareState {
    window.analytics?.trackEvent(ACTIONS.PUBLISH_FAILED, 'ReduxShare');
    return {
      ...state,
      publishing: false,
      publishError: action.error,
    };
  },
  [ACTIONS.SET_DISPLAY_PREVIEW](
    state: Ishare,
    action: { displayPreview: DisplayPreviewView },
  ): ShareState {
    return { ...state, displayPreview: action.displayPreview };
  },
  [ACTIONS.SET_INSERTED_DISPLAY_PREVIEW_CONTENT](
    state: Ishare,
    action: { content: DisplayPreviewContentView },
  ): ShareState {
    if (!state.displayPreview?.content) {
      return state;
    }
    const displayPreview = { ...state.displayPreview };
    const content = [...displayPreview.content!];
    const updatedContent = content.findIndex(
      (c) => !c._id
                && (c[c.type] as any)._id
                    === (action.content[action.content.type] as any)._id,
    );
    if (updatedContent === -1) {
      return state;
    }
    content[updatedContent] = action.content;
    displayPreview.content = content;
    return {
      ...state,
      displayPreview,
    };
  },
};

export default function (state = initialState, action: ShareAction) {
  const actionType = action.type as keyof typeof reducers;
  if (reducers[actionType]) {
    return reducers[actionType](state, action);
  }
  return state;
}
