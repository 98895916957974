/* eslint-disable import/prefer-default-export */

import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import { useSelector } from 'react-redux';
import * as reduxHelpers from '@src/services/helpers/redux';
import { StringHelper } from '@src/services/helpers/string';

import { bigStore } from 'store';
import { ICombinedReducers } from '@src/types/redux';
import * as CommonActions from 'actions/common';
import { CollectionView } from 'common';
import { api } from '@src/services/api';
import { EPopup } from '@src/types/redux/common';
import { PageTitle } from '@src/components/PageTitle';

import { Pathname, Browsing, ArgsName } from '@src/types/Browsing';
import { queryClient, QUERY_KEYS } from '@src/query';
import { useMutationCollectionDelete } from '@src/queries/collections/useMutationCollectionDelete';
import { useMutationCollectionDuplicate } from '@src/queries/collections/useMutationCollectionDuplicate';
import { getCollectionLangError } from '@src/pages/Collection/collectionErrors';
import { RightsRequestStatusSelect, RightsRequestStatusValue } from '@src/components/RightsRequestStatusSelect';
import { stylesheet } from 'typestyle';
import DownloadIcon from '@mui/icons-material/GetApp';
import ShareIcon from '@mui/icons-material/Share';
import SettingsIcon from '@mui/icons-material/Settings';
import DeleteIcon from '@mui/icons-material/Delete';
import CopyIcon from '@mui/icons-material/FileCopy';
import LiveIcon from '@mui/icons-material/FiberManualRecord';
import { ADALONG_COLORS, ThemeStyle } from '@src/styles';
import LocalizedStrings from 'react-localization';
import { ButtonIcon } from '@src/components/ButtonIcon';
import { BulkMediaActions } from '@src/components/BulkMediaActions';
import { WithMediaSelection } from '@src/types/MediaSelection';
import { MediaActionButtonController } from '@src/fragments/MediaActionButton/MediaActionController';
import { IPopupsService } from '@src/types/Popups';
import { PermissionSettings } from '@src/pages/Collection/View/CollectionHeader/components/PermissionSettings';
import UserAccessList from '@src/pages/Collection/View/CollectionHeader/components/PermissionList/UserAccessList';
import { useMutationCollectionLock } from '@src/queries/collections/useMutationCollection';
import { Lock, LockOpen } from '@mui/icons-material';
import { useAddAlert } from '@src/fragments/Alerting/alertAtom';
import { CollectionSettings } from './components/CollectionSettings';

interface Props extends WithMediaSelection {
  isLoading: boolean;
  collection: CollectionView | null;
  totalResults: number;
  rightsStatusFilter: RightsRequestStatusValue;
  services: {
    popups: IPopupsService;
    browsing: Browsing;
  };
  setRightsStatusFilters: (status: RightsRequestStatusValue) => void;
}

export function CollectionHeader(props: Props) {
  const [settings, setSettings] = useState(false);
  const [permission, setPermission] = useState(false);
  const [share, setShare] = useState<boolean | string>();
  const addAlert = useAddAlert();

  const { mutateAsync: lockCollection, reset: resetLock } = useMutationCollectionLock();
  const { mutateAsync: deleteCollection, reset: resetDelete } = useMutationCollectionDelete();
  const { mutateAsync: duplicateCollection, reset: resetDuplicate } = useMutationCollectionDuplicate();

  const user = useSelector((state: ICombinedReducers) => state.common.user);

  const onCollectionLock = () => {
    if (!props.collection) {
      return;
    }
    lockCollection(
      {
        _id: props.collection._id,
        locked: !(props.collection.locked ?? false),
      },
      {
        onSuccess: () => {
          reduxHelpers.addAlert({
            text: 'create.collection.settings.details.success',
            type: 'success',
          });
        },
        onError: (error) =>
          addAlert({
            text: getCollectionLangError(error),
            close: resetLock,
          }),
      }
    ).catch(console.error);
  };

  const onCollectionSettingsClose = () => {
    setSettings(false);
  };

  const onCollectionSettings = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    if (props.collection?.locked) {
      reduxHelpers.addAlert({ text: 'Collection is locked!', type: 'warning' });
      return;
    }
    e.stopPropagation();
    setSettings(true);
  };

  const onPermissionSettings = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    setPermission(true);
  };

  const onPermissionsClose = () => {
    setPermission(false);
  };

  const onDownload = (e: React.MouseEvent<SVGSVGElement, MouseEvent>) => {
    e.stopPropagation();
    if (props.collection) {
      bigStore.dispatch(CommonActions.setPopup(EPopup.downloadCollection, props.collection._id));
    }
  };

  const onClickShareOverlay = () => {
    setShare(false);
  };

  const onShare = () => {
    if (!props.collection) {
      return;
    }

    api
      .getCollectionShare(props.collection._id)
      .then((token: string) => {
        if (props.collection) {
          setShare(`${window.location.origin}/collections/${props.collection._id}?t=${token}`);
        }
      })
      .catch((err) => {
        reduxHelpers.addAlert({ text: `common.error.${err.type}`, type: 'danger' });
      });
  };

  const closeShare = (): void => {
    setShare(undefined);
  };

  const onDelete = () => {
    if (props.collection?.locked) {
      reduxHelpers.addAlert({ text: 'Collection is locked!', type: 'warning' });
      return;
    }
    if (props.collection) {
      bigStore.dispatch(
        CommonActions.promptConfirm({
          question: window.T('create.collections.confirmremove'),
          answer: (yes) => {
            if (!yes || !props.collection) {
              return;
            }
            deleteCollection(
              {
                collectionId: props.collection._id,
              },
              {
                onSuccess: () => {
                  setTimeout(() => reduxHelpers.setRoute(Pathname.collect), 100);
                },

                onError: (error) =>
                  addAlert({
                    text: getCollectionLangError(error),
                    close: resetDelete,
                  }),
              }
            ).catch(console.error);
          },
        })
      );
    }
  };

  const onRightsStatus = (val: RightsRequestStatusValue) => {
    props.setRightsStatusFilters(val);
    const collectionId = props.collection?._id;
    if (collectionId) {
      queryClient.invalidateQueries(QUERY_KEYS.collection(collectionId));
    }
  };

  const onDuplicate = () => {
    if (props.collection) {
      bigStore.dispatch(
        CommonActions.promptConfirm({
          question: window.T('create.collection.confirmduplicate'),
          answer: (yes) => {
            if (!yes || !props.collection) {
              return;
            }
            duplicateCollection(
              {
                collectionId: props.collection._id,
              },
              {
                onSuccess: (newCol) => {
                  reduxHelpers.setRoute(`/collections/${newCol._id}` as Pathname);
                },
                onError: (error) =>
                  addAlert({
                    text: getCollectionLangError(error),
                    close: resetDuplicate,
                  }),
              }
            ).catch(console.error);
          },
        })
      );
    }
  };

  const { collection, isLoading } = props;
  const permissions = collection?.permissions?.filter((p) => !!p.user) || [];

  if (isLoading) {
    return null;
  }

  //TODO create a case for A/ Collection not found and B/ Collection belonging to an unlogged user
  if (!collection) {
    return <div className='header'>{/* {<div className='notfound'>{window.T('create.collection.notfound')}</div>} */}</div>;
  }

  const shareStyle = {
    display: 'flex',
  };

  const onStatusClick = (widgetId: string[]) => {
    if (widgetId.length === 1) {
      props.services.browsing.goToPage({
        root: Pathname.widget,
        args: new Map().set(ArgsName.widget, widgetId[0]),
      });
    }
  };

  return (
    <div className='header'>
      <div className='left'>
        <div className='title'>
          <PageTitle>
            {collection.live_widgets && collection.live_widgets.ids.length > 0 && (
              <ButtonIcon
                icon={LiveIcon}
                titleAccess={collection.live_widgets.names.join(' • ')}
                className={sheet.liveIcon}
                onClick={() => onStatusClick(collection.live_widgets!.ids)}
                sx={{ color: 'green', fontSize: 20, '&:hover': { color: 'darkgreen' } }}
              />
            )}

            {StringHelper.upperFirst(collection.name)}
          </PageTitle>
        </div>
        <div className='desc'>{StringHelper.upperFirst(collection.description)}</div>
        <div className='count'>
          <NumberFormat value={props.totalResults} displayType='text' thousandSeparator /> elements
          {user && (
            <BulkMediaActions
              count={props.mediaSelection.length}
              onClear={() => props.setMediaSelection([])}
              mediaActionButton={
                <MediaActionButtonController
                  inCollection={collection}
                  mediaList={props.mediaSelection}
                  services={props.services}
                  catalogCountries={user.catalogCountries}
                />
              }
            />
          )}
        </div>
      </div>
      <div className='right'>
        <div className={sheet.details}>
          {user && (
            <>
              <span className={sheet.owner}>
                {collection.mine && window.T('create.collection.selfowner')}
                {!collection.mine && window.T('create.collection.owner', { owner: collection.owner.displayName })}
              </span>
              <UserAccessList permissionList={permissions} onClick={onPermissionSettings} isMine={collection.mine} />
            </>
          )}

          {collection.mine && (
            <ButtonIcon
              icon={props.collection?.locked ? Lock : LockOpen}
              titleAccess={props.collection?.locked ? lang.buttons.unlock : lang.buttons.lock}
              className={sheet.headerButton}
              onClick={onCollectionLock}
            />
          )}

          {user && <ButtonIcon icon={CopyIcon} titleAccess={lang.buttons.copy} className={sheet.headerButton} onClick={onDuplicate} />}
          {user && <ButtonIcon icon={ShareIcon} titleAccess={lang.buttons.share} className={sheet.headerButton} onClick={onShare} />}
          {user && (
            // special case for this icon that seems smaller
            <ButtonIcon
              icon={DownloadIcon}
              size={ICON_SIZE + 4}
              titleAccess={lang.buttons.download}
              className={sheet.headerButton}
              onClick={onDownload}
            />
          )}
          {collection.mine && (
            <ButtonIcon
              icon={SettingsIcon}
              titleAccess={lang.buttons.settings}
              style={{
                opacity: props.collection?.locked ? '0.5' : '1',
              }}
              className={sheet.headerButton}
              onClick={onCollectionSettings}
            />
          )}
          {collection.mine && (
            <ButtonIcon
              icon={DeleteIcon}
              titleAccess={lang.buttons.delete}
              style={{
                opacity: props.collection?.locked ? '0.5' : '1',
              }}
              className={sheet.headerButton}
              onClick={onDelete}
            />
          )}
        </div>
        {!user ? null : (
          <div className='bottom-buttons'>
            <div className='rights-filters'>
              <RightsRequestStatusSelect onChange={onRightsStatus} value={props.rightsStatusFilter} />
            </div>
          </div>
        )}

        {!settings ? null : <CollectionSettings collection={collection} onClose={() => onCollectionSettingsClose()} />}
        {!permission ? null : <PermissionSettings collection={collection} onClose={() => onPermissionsClose()} />}
      </div>
      {share && (
        <div className='share-overlay' onClick={onClickShareOverlay}>
          <div onClick={(e) => e.stopPropagation()}>
            <div className='title' style={shareStyle}>
              <div className='desc'>{window.T('create.collection.share')}</div>
              <div className='remove' onClick={closeShare}>
                <i className='material-icons notranslate' style={{ color: '#adadad' }}>
                  close
                </i>
              </div>
            </div>
            <div className='link'>{share}</div>
          </div>
        </div>
      )}
    </div>
  );
}

const lang = new LocalizedStrings({
  en: {
    buttons: {
      lock: 'Lock collection from editing',
      unlock: 'Unlock collection and allow editing',
      permission: 'Check collection permissions',
      delete: 'Remove the collection',
      copy: 'Duplicate the collection',
      settings: 'Access to collection settings',
      download: "Download the collection's content",
      share: 'Share the collection with a secured link',
    },
  },
});

const ICON_SIZE = 20;

const sheet = stylesheet({
  details: {
    textAlign: 'right',
    fontFamily: ThemeStyle.fonts.primary,
    color: ADALONG_COLORS.DARK_GRAY,
    marginRight: 10,
    marginLeft: 10,
    fontSize: ICON_SIZE,
    verticalAlign: 'bottom',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-evenly',
  },
  owner: {
    fontSize: 14,
    marginRight: 10,
  },
  headerButton: {
    marginLeft: 8,
    marginRight: 8,
    verticalAlign: 'middle',
  },
  liveIcon: {
    marginRight: 8,
  },
});
