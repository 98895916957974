import { useMutation } from 'react-query';
import { ApiError } from '@src/services/api';
import { ApiTypes, CollectionView } from 'common';
import { DEPRECATED_QUERY_KEYS, queryClient, QUERY_KEYS } from '@src/query';
import {
  setCollectionUserPermissions, updateCollection, addMediasToCollection, removeFromCollection, lockCollection,
} from './api';

export const useMutationCollectionPermissions = () => useMutation<CollectionView, ApiError, {
  access: boolean
  collection: string
  edit: boolean
  user: string
}>((params) => setCollectionUserPermissions(params), {
  onSuccess: async (collection) => {
    const key = QUERY_KEYS.collection(collection._id);
    queryClient.cancelQueries(key);
    queryClient.setQueryData(key, collection);
    return collection;
  },
});

export const useMutationCollection = () => useMutation<CollectionView, ApiError, Parameters<typeof updateCollection>[0]>((partialCollection) => updateCollection(partialCollection), {
  onSuccess: async (res, partialCollection) => {
    const key = QUERY_KEYS.collection(partialCollection._id);
    queryClient.invalidateQueries([DEPRECATED_QUERY_KEYS.COLLECTIONS]);
    queryClient.invalidateQueries(key);
  },
});

export const useMutationCollectionLock = () => useMutation<CollectionView, ApiError, Parameters<typeof lockCollection>[0]>((partialCollection) => lockCollection(partialCollection), {
  onSuccess: async (res, partialCollection) => {
    const key = QUERY_KEYS.collection(partialCollection._id);
    queryClient.invalidateQueries([DEPRECATED_QUERY_KEYS.COLLECTIONS]);
    queryClient.invalidateQueries(key);
  },
});


export const useMutationCollectionAddMedia = () => {
  const mutation = useMutation<CollectionView, ApiError, { collectionId: string; mediaIds: string[] }>(
    ({ collectionId, mediaIds }) => addMediasToCollection({ collectionId, mediaIds }),
    {
      onSuccess: async (res, { collectionId }) => {
        const key = QUERY_KEYS.collectionMedia(collectionId);
        queryClient.invalidateQueries(key);
        queryClient.invalidateQueries(QUERY_KEYS.collection(collectionId));
        queryClient.invalidateQueries([DEPRECATED_QUERY_KEYS.COLLECTION_CONTENT_NUMBER]);
      },
    }
  );

  const addMediaInBatches = async (collectionId: string, mediaIds: string[], batchSize: number = 100) => {
    for (let i = 0; i < mediaIds.length; i += batchSize) {
      const batch = mediaIds.slice(i, i + batchSize);
      await mutation.mutateAsync({ collectionId, mediaIds: batch });
    }
  };

  return { ...mutation, addMediaInBatches };
};

export const useMutationCollectionRemoveMedia = () => useMutation<CollectionView, ApiError, ApiTypes.ParamsRemoveMediaFromCollection>(({ collectionId, mediaIds }) => removeFromCollection({
  collectionId,
  mediaIds,
}), {
  onSuccess: async (res, { collectionId }) => {
    const key = QUERY_KEYS.collectionMedia(collectionId);
    queryClient.invalidateQueries(key);
    queryClient.invalidateQueries(QUERY_KEYS.collection(collectionId));
    queryClient.invalidateQueries([DEPRECATED_QUERY_KEYS.COLLECTION_CONTENT_NUMBER]);
  },
});
