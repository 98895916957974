/**
 * This component is the basis for discover
 * and collection libraries as they are
 * substantially the same
 */
import React, { Component, ReactNode, useEffect, useRef } from 'react';
import { classes, stylesheet } from 'typestyle';
import { loadTranslation } from '@src/services/helpers/redux';
import usePrevious from '@src/utils/hooks/usePrevious';
import { Loader } from '../Loader';
import { InfiniteScroller } from '../InfiniteScroller';
import { PerfectScrollbar } from '../PerfectScrollbar';
import CustomMasonry, { CustomMasonryProps } from '@src/components/Library/CustomMasonry';

interface Props {
  libraryLoaded: boolean
  noMore: boolean
  loadMore: InfiniteScroller['props']['load']
  elements: LibElement[] | null
}

export interface LibElement {
  node: ReactNode
  dimensions: {
    width: number
    height: number
  } | undefined
}

/**
 * Organize given element to to display them in a grid layout like
 * the masonry plugin
 */
export default function GridLibraryLayout({
  libraryLoaded,
  loadMore,
  noMore,
  elements,
}: Props) {
  const masonryRef = useRef<Component<CustomMasonryProps>>(null);
  const scrollbarRef = useRef<PerfectScrollbar>(null);
  const infiniteScrollbarRef = useRef<InfiniteScroller>(null);
  const previousElements = usePrevious(elements);

  useEffect(() => {
    // When we have less element than previously, a reload has been performed
    if ((elements?.length || 0) < (previousElements?.length || 0)) {
      scrollbarRef.current?.scrollTop(0);
      infiniteScrollbarRef.current?.resetPage();
    }
  }, [elements]);

  return (
    <>
      {!libraryLoaded && (
        <div className={sheet.loaderContainer}>
          <Loader />
        </div>
      )}
      <InfiniteScroller
        ref={infiniteScrollbarRef}
        container={(props, children) => (
          <PerfectScrollbar
            ref={scrollbarRef}
            className={sheet.library}
            options={{ minScrollbarLength: 30 }}
            {...props}
          >
            {children}
          </PerfectScrollbar>
        )}
        noMore={noMore}
        threshold={1700}
        loader={libraryLoaded} // Doesn't display the infinite loader if the library is reloading
        load={loadMore}
      >
        <div
          className={classes(sheet.gridContainer, {
            [sheet.hideGridContainer]: !libraryLoaded,
          })}
        >
          <CustomMasonry
            ref={masonryRef}
            updateOnEachImageLoad
            options={{
              transitionDuration: 0,
              initLayout: false,
            }}
            onImagesLoaded={masonryImagesLoaded}
          >
            {elements?.map(({ node }) => node)}
          </CustomMasonry>
        </div>
        {(elements === null || elements.length === 0) && libraryLoaded && (
          <div className={sheet.nothing}>
            {lang.nothing}
          </div>
        )}
      </InfiniteScroller>
    </>
  );

  function masonryImagesLoaded() {
    setTimeout(() => {
      // @ts-ignore
      // eslint-disable-next-line @typescript-eslint/no-unsafe-call
      masonryRef.current?.performLayout(); // Fix firefox bug
    }, 200);
  }
}

const sheet = stylesheet({
  loaderContainer: {
    position: 'relative',
    textAlign: 'center',
    marginTop: 125,
  },
  library: {
    position: 'relative',
    paddingRight: 10,
    height: '100%',
    overflow: 'hidden',
  },
  gridContainer: {
    marginTop: 2,
  },
  hideGridContainer: {
    display: 'none',
  },
  column: {
    display: 'inline-block',
    verticalAlign: 'top',
  },
  nothing: {
    textAlign: 'center',
    paddingTop: 150,
    color: 'gray',
  },
});

const translation = {
  en: {
    nothing: 'No content to display',
  },
};
const lang = loadTranslation(translation);
