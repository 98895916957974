import React from 'react';
import { style } from 'typestyle';
import {
  SearchManagerInterface, SearchTheme, SearchMarkDef, SuggestionsDef, SearchElementRef,
} from '../../types/Search';
import { Bar } from './Bar';
import { Suggestions } from './Suggestions';

interface Props {
  manager: SearchManagerInterface
  theme: SearchTheme
  elementRefs: SearchElementRef
  values: {
    suggestions?: SuggestionsDef | null
    highligthedSuggestion?: SearchMarkDef | null
    displaySuggestions: boolean
    inputValue: string
    loadingSuggestions: boolean
  }
}

/**
 * This component sticks together Bar and Suggestion and map the manager
 */
export function Search({
  manager,
  theme,
  elementRefs,
  values: {
    suggestions,
    displaySuggestions,
    inputValue,
    highligthedSuggestion,
    loadingSuggestions,
  },
}: Props) {
  const [, isExclusion] = manager.utils.parseExclusion(inputValue);
  return (
    <div className={searchClass}>
      <Bar
        markDefs={manager.getSelected()}
        theme={theme}
        inputValue={inputValue}
        handlers={manager}
        elementRefs={elementRefs}
        formatExclusion={manager.utils.parseExclusion.bind(manager.utils.parseExclusion)}
      />
      {displaySuggestions && suggestions && (
        <Suggestions
          elementRefs={elementRefs}
          suggestions={suggestions}
          theme={theme}
          handlers={manager}
          hightlightedSuggestion={highligthedSuggestion}
          showLoader={loadingSuggestions}
          exclusion={isExclusion}
        />
      )}
    </div>
  );
}

const searchClass = style({
  position: 'relative',
});
