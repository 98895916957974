import React from 'react';
import { stylesheet } from 'typestyle';

interface Props {
  text: string,
  highlightText?: string,
}

/**
 * Render a text with <br> component for line
 * breaks
 */
export default function TextLineBreak({
  text,
  highlightText,
}: Props) {
  if (highlightText) {
    const splittedText: string[] = text.split(highlightText);
    const firstPhrase = splittedText.shift();
    return (
      <span className={sheet.text}>
        {firstPhrase}
        {
            splittedText.map((txt) => (
              <span>
                <mark>
                  {highlightText}
                </mark>
                {txt}
              </span>
            ))
          }
      </span>
    );
  }
  return (
    <span className={sheet.text}>
      {text}
    </span>
  );
}

const sheet = stylesheet({
  text: {
    whiteSpace: 'pre-line',
  },
});
