import React, { useState } from 'react';
import { loadTranslation } from '@src/services/helpers/redux';
import { ShareInfo } from '@src/types/redux/share';
import TitleBar from '../TitleBar';
import translation from './translation';
import { ShareCollectionContent } from '../ShareCollectionContent';
import { IUploadingFile } from '../ShareCollectionContent/components/UploadingFile';

const lang = loadTranslation(translation);

interface Props {
  onShare: (info: ShareInfo) => void
  onUpload: (files: File[]) => void
  uploadingFiles: IUploadingFile[]
}

export function BrandAssets(props: Props) {
  const [opened, setOpened] = useState(false);

  return (
    <div>
      <TitleBar
        title={lang.title}
        style={{ backgroundColor: '#313131' }}
        onSelect={() => setOpened(!opened)}
      />
      {opened && (
        <ShareCollectionContent
          canEdit
          onShare={props.onShare}
          onUpload={props.onUpload}
          uploadingFiles={props.uploadingFiles}
        />
      )}
    </div>
  );
}
