import { useState, useEffect } from 'react';
import { MediaView } from 'common';

export const useObjectLabels = (media: MediaView) => {
  const [objectLabels, setObjectLabels] = useState<{
    productIdsExact: string[];
    productIdsSimilar: string[];
  }>({
    productIdsExact: media.productIdsExact || [],
    productIdsSimilar: media.productIdsSimilar || [],
  });

  useEffect(() => {
    setObjectLabels({
      productIdsExact: media.productIdsExact ?? [],
      productIdsSimilar: media.productIdsSimilar ?? [],
    });
  }, [media.productIdsExact, media.productIdsSimilar]);

  return [objectLabels, setObjectLabels] as const;
};
