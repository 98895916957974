declare let __GLOBAL__: any;
const env = __GLOBAL__;

export const { API_URL } = env;
export const { NODE_ENV } = env;
export const { WIDGET_CDN_URL } = env;
export const { WIDGET_VERSION } = env;
export const { BROWSER } = env;
export const { CLOUDFLARE_TURNSTILE } = env;
export const { CLOUDFLARE_TURNSTILE_VERSION } = env;
export const { DATADOG_BROWSER_RUM_APPID } = env;
export const { DATADOG_BROWSER_RUM_CLIENTID } = env;
export const { LOREALPRO_MICROSOFT_TENANT_ID } = env;
export const { IS_TURNSTILE_ENABLED } = env;
export const { GA_KEY } = env;

export const SCROLLING_DIRECTION = {
  DOWN: 'down',
  UP: 'up',
};

export const ROOT_PATH = '/discover';

export const SESSION_STORAGE_KEYS = {
  WIDGET_PLATFORM: 'WIDGET_PLATFORM',
};

export const COLLECTION_NAME_MAX_LENGTH = 30;
export const TOOLTIP_DELAY = 300;
