import React, { Component } from 'react';

import CircularProgress from '@mui/material/CircularProgress';
import { Loader } from '@src/components/Loader';
import styles from './styles.scss';

export interface IUploadingFile {
  id: string
  progress: number
  localUrl: string
  collectionId?: string
}

interface IProps {
  file: IUploadingFile
}

interface IState {

}

export class UploadingFile extends Component<IProps, IState> {
  public render() {
    return (
      <div className="thumbnail" style={{ backgroundImage: `url('${this.props.file.localUrl}')` }}>
        <div className={styles.overlay}>
          <div>
            {this.props.file.progress < 100
              ? (
                <CircularProgress
                  className={styles.progress}
                  variant="determinate"
                  value={this.props.file.progress}
                  size={48}
                  thickness={4}
                />
              )
              : <Loader size={10} />}
          </div>
        </div>
      </div>
    );
  }
}
