import { DetailedMediaViewTab } from '@src/types/redux/common';
import { QsNames, PathLocation } from '@src/types/Browsing';
import { pathLocationToString, setQsParam } from '@src/utils/browsing';

/**
 * Return path to open detailed view tab
 */
export function goToMedia(
  location: PathLocation,
  mediaId?: string,
  tab?: DetailedMediaViewTab,
): string {
  return pathLocationToString(
    setQsParam(location, [{
      field: QsNames.detailedMediaView, val: mediaId,
    }, {
      field: QsNames.detailedMediaViewTab, val: tab,
    }]),
  );
}

export function goToCloseMedia(location: PathLocation): string {
  return pathLocationToString(setQsParam(location, [{
    field: QsNames.detailedMediaView,
  }, {
    field: QsNames.detailedMediaViewTab,
  }]));
}

/**
 * Add query param to open the get rights modal
 */
export function goToGetRights(location: PathLocation, mediaId?: string): string {
  return pathLocationToString(setQsParam(location, [{ field: QsNames.getRightsModal, val: mediaId }]));
}

export function goToCloseGetRights(location: PathLocation): string {
  return pathLocationToString(setQsParam(location, [{
    field: QsNames.getRightsModal,
  }]));
}

/**
 * Add query param to open the rights action required modal
 */
export function goToRightsActionRequired(location: PathLocation, mediaId?: string): string {
  return pathLocationToString(setQsParam(location, [{ field: QsNames.rightsActionRequiredModal, val: mediaId }]));
}

export function goToCloseRightsActionRequired(location: PathLocation): string {
  return pathLocationToString(setQsParam(location, [{
    field: QsNames.rightsActionRequiredModal,
  }]));
}

/**
 * Add query param to open the download popup
 */
export function goToMediaDownload(location: PathLocation, mediaIds?: string[]): string {
  return pathLocationToString(setQsParam(location, [{ field: QsNames.mediaDownload, val: mediaIds }]));
}

export function closeMediaDownload(location: PathLocation): string {
  return pathLocationToString(setQsParam(location, [{
    field: QsNames.mediaDownload,
  }]));
}
