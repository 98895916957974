import React from 'react';

/* Redux */
import { ICombinedReducers } from '@src/types/redux';
import { useDispatch, useSelector } from 'react-redux';

/* Api */
import { useMutationDeleteCreator } from '@src/queries/influencers/useMutationDeleteCreator';
import { useMutationUpdateCreator } from '@src/queries/influencers/useMutationUpdateCreator';

/* Styling */
import { style, stylesheet } from 'typestyle';
import styles from '../styles.scss';

/* Misc */
import * as CommonActions from '../../../../../actions/common';
import { EPopup } from '@src/types/redux/common';
import LocalizedStrings from 'react-localization';

/* Components */
import { OverlayContainer } from '@src/components/OverlayContainer';
import { CloseButton } from '@src/components/CloseButton';
import { Row, Column } from '@src/components/Utils';
import { ActionButton } from '@src/components/ActionButton';
import { Hide } from '@src/components/Hide';
import { FormError } from '@src/components/Form/FormError';

/* Types */
import { CreatorView } from 'common';

export default function EditInfluencer(props: { influencer: CreatorView }) {

  const isBlacklist = props.influencer.isBlacklisted;

  const dispatch = useDispatch();

  const user = useSelector((state: ICombinedReducers) => state.common.user);
  const close = () => dispatch(CommonActions.setPopup(EPopup.editInfluencer, null));

  const { mutate: deleteInfluencer, error: errorDeleting } = useMutationDeleteCreator();
  const { mutate: editInfluencer, error: errorEditing } = useMutationUpdateCreator();

  const onDelete = async (influencerId: string) => {
    await deleteInfluencer({ id: influencerId });
    close();
  };

  const onEditInfluencer = async (influencer: CreatorView) => {
    await editInfluencer({ id: influencer._id, doc: {
      // When the creator is not blacklisted, we want to edit the influencer status, through isInfluencerDisabled flag
      countries: influencer.countries,
      isBlacklisted: isBlacklist ? false : influencer.isBlacklisted,
      isInfluencerDisabled: isBlacklist ? influencer.isInfluencerDisabled : !influencer.isInfluencerDisabled,
    }});
    close();
  };

  if (!user) {
    return null; // TODO: loading
  }
  return (
    <>
      <OverlayContainer
        containerClasses={[styles.settingsContainer]}
        frameClasses={[styles.settings]}
        onClose={close}
      >
        <Row className={headClass}>
          <div className={sheet.title}>{isBlacklist ? lang.blackListTitle : lang.title}</div>
          <CloseButton size='large' classNames={[sheet.closeButton]} onClick={close} />
        </Row>

        <Row className={headClass}>
          <Column className={sheet.column}>
            <div className={sheet.warning}>{isBlacklist ? lang.deleteBlacklistDesc : lang.deleteDesc}</div>
          </Column>

          {!isBlacklist &&
          <Column className={sheet.columnDivider}>
            <div className={sheet.warning}>
              {props.influencer.isInfluencerDisabled ? lang.activateDesc : lang.deactivateDesc}
            </div>
          </Column>
          }
        </Row>

        <Row>
          <Column className={sheet.column}>
            <ActionButton
              className={sheet.buttonColumn}
              variant='gray'
              // When the creator is blacklisted, the 'delete' button should in fact just edit the blacklist flag
              // and not delete the creator completely
              onClick={!isBlacklist ? () => onDelete(props.influencer._id) : () => onEditInfluencer(props.influencer)}
              data-size='medium'
            >
              {isBlacklist ? lang.deleteBlacklistBtn.toUpperCase() : lang.deleteBtn.toUpperCase()}
            </ActionButton>
          </Column>
          { !isBlacklist && <Column className={sheet.column}>
            <ActionButton
                className={sheet.buttonColumn}
                variant='blue'
                onClick={() => onEditInfluencer(props.influencer)}
                data-size='medium'
            >
              {props.influencer.isInfluencerDisabled
                  ? lang.activateBtn.toUpperCase()
                  : lang.deactivateBtn.toUpperCase()}
            </ActionButton>
          </Column> }
        </Row>

        <Hide hide={!errorEditing || !errorDeleting}>
          <FormError error={errorEditing ? lang.error.ending : lang.error.removing} />
        </Hide>
      </OverlayContainer>
    </>
  );
}

const lang = new LocalizedStrings({
  en: {
    title: 'Modify an Influencer',
    blackListTitle: 'Edit blacklist',
    deleteBlacklist: 'Remove from blacklist',
    deleteBlacklistDesc: '❌ This creator will be removed from the blacklist, and its content will be visible again.',
    deleteTitle: 'Reset & Deactivate this Influencer',
    deleteDesc:
      '❌ This influencer will be deactivated and all the associated media will have their rights reset. ',
    deleteBtn: 'reset & deactivate',
    deleteBlacklistBtn: 'remove from blacklist',

    activateTitle: 'Activate this Influencer',
    activateDesc:
      '✅ Starting from now, you will start to collect all rights on the medias associated with this influencer.',
    activateBtn: 'activate',

    deactivateTitle: 'Deactivate this Influencer',
    deactivateDesc:
      '⚠️ Starting from now, you will stop automatically collecting rights on the medias associated with this influencer. All rights on the medias associated with this influencer in their active period will remain valid. ',
    deactivateBtn: 'deactivate',

    error: {
      general: 'An error occured, please try later or contact us',
      removing: 'An error occurred while removing the influencer, please try again or contact us.',
      ending:
        'An error occurred while trying to end your influencer partnership, please try again or contact us.',
    },
  },
});

const headClass = style({
  flexShrink: 0,
});

const sheet = stylesheet({
  title: {
    flex: 1,
    textAlign: 'center',
    fontSize: 25,
    marginBottom: 25,
  },
  closeButton: {
    flexShrink: 0,
  },
  buttonContainer: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
  warning: {
    margin: '20px 0',
  },
  column: {
    width: '50%',
    padding: '0 20px',
  },
  columnDivider: {
    width: '50%',
    padding: '0 20px',
    borderLeft: '1px solid white',
  },
  buttonColumn: {
    width: '75%',
    margin: '0 auto',
  },
});
