import { useMutation } from 'react-query';
import { queryClient, QUERY_KEYS } from '@src/query';
import { ApiError } from '@src/services/api';
import { deleteCreator } from './api';

export const useMutationDeleteCreator = () =>
  useMutation<
    boolean,
    ApiError,
    {
      id: string;
    }
  >(({ id }) => deleteCreator(id), {
    onSuccess: async () => {
      let key = QUERY_KEYS.influencers();
      queryClient.invalidateQueries(key);
      queryClient.refetchQueries(key);

      key = QUERY_KEYS.blacklistedCreators();
      queryClient.invalidateQueries(key);
      queryClient.refetchQueries(key);
    },
  });
