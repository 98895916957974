import React, { useEffect } from 'react';
import prismjs from 'prismjs';
import { stylesheet } from 'typestyle';
import { injectStyle } from './injectors';

interface Props extends React.HTMLAttributes<HTMLPreElement> {
  language?: string
}

const PRISM_VERSION = '1.22.0';
const PRISM_THEME_URL = `https://cdn.jsdelivr.net/npm/prismjs@${PRISM_VERSION}/themes/prism-okaidia.css`;

/**
 * Code snippet component
 */
export function Code({
  language,
  children,
  ...props
}: Props) {
  const langClass = language !== undefined ? `language-${language}` : undefined;

  useEffect(() => {
    highlightCode();
  }, [children]);

  return (
    <pre className={`prettyprint ${sheet.pre}`} {...props}>
      <code className={langClass}>{children}</code>
    </pre>
  );
}

function highlightCode(): void {
  const head = document.getElementsByTagName('head')[0];
  injectStyle(PRISM_THEME_URL, head, 'code-prettify-style');

  prismjs.highlightAll();
}

const sheet = stylesheet({
  pre: {
    padding: '6px 11px !important',
  },
});
