import React from 'react';
import { DropDownIcon } from './DropDownIcon';

export function LocalizationIcon() {
  return (
    <DropDownIcon
      childrenRender={() => (
        <>
          <rect width='50' height='50' rx='10' fill='#D9D9D9' />
          <path
            d='M22.8039 40.7835C23.3805 41.6323 24.3353 42.1429 25.3585 42.1429C26.3817 42.1429 27.3382 41.6323 27.9132 40.7835C31.6293 35.3026 37.8569 25.3637 37.8569 20.418C37.8569 13.4924 32.2509 7.85715 25.3569 7.85715C18.463 7.85715 12.8569 13.4919 12.8569 20.418C12.8569 25.3632 19.0862 35.3021 22.8007 40.7835H22.8039ZM25.3585 14.1841C28.7797 14.1841 31.5661 16.9781 31.5661 20.415C31.5661 23.8519 28.7798 26.6511 25.3585 26.6511C21.9372 26.6511 19.151 23.857 19.151 20.415C19.151 16.973 21.9372 14.1841 25.3585 14.1841Z'
            fill='#343434'
          />
        </>
      )}
    />
  );
}
