import { useMutation } from 'react-query';
import { QUERY_KEYS, queryClient } from '@src/query';
import { ApiError } from '@src/services/api';
import { ApiTypes } from 'common';
import { updateCreator } from './api';

export const useMutationUpdateCreator = () =>
  useMutation<void, ApiError, { id: string, doc: ApiTypes.BodyUpdateCreator }>(
    (params) => updateCreator(params),
    {
      onSuccess: async (res, partialInfluencer) => {
        let key = QUERY_KEYS.influencers();
        queryClient.invalidateQueries(key);
        queryClient.refetchQueries(key);

        key = QUERY_KEYS.blacklistedCreators();
        queryClient.invalidateQueries(key);
        queryClient.refetchQueries(key);
      },
    }
  );
