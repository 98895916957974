import i18 from 'i18next';
import en from '@src/assets/translation/en/app_translation.json';

export default function (lang?: string | i18.Callback, callback?: i18.Callback) {
  if (typeof lang !== 'string') {
    callback = lang;
    lang = undefined;
  }
  i18.init({
    lng: lang || 'en',
    resources: {
      en: { translation: en },
    },
    nsSeparator: false,
  }, callback);
}
