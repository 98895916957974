import { IMentionPage, ApiTypes } from 'common';
import { IAlert } from '@src/types/alert';
import { REDUX_ACTIONS } from '../actions/_types';
import { EPopup, CommonState } from '../types/redux/common';

export const initialState: CommonState = {
  windowSize: {
    height: window.innerHeight,
    width: window.innerWidth,
  },
  windowFocus: false,
  mouseClick: () => null,
  settingsPopup: {},

  facebookPages: [],
  facebookAdAccounts: [],
  user: null,
  admin: null,
  config: null,
  alerts: [],

  influencers: [],

  popups: {
    [EPopup.notfound]: false,
    [EPopup.account]: false,
    [EPopup.settings]: false,
    [EPopup.userSettings]: false,
    [EPopup.widgetCreation]: false,
    [EPopup.rights]: false,
    [EPopup.collectOverlay]: false,
    [EPopup.setCreatorCountriesOverlay]: false,
    [EPopup.getRights]: null,
    [EPopup.promptconfirm]: null,
    [EPopup.downloadCollection]: null,
    [EPopup.addInfluencer]: null,
    [EPopup.editInfluencer]: null,
    [EPopup.addBlacklist]: null,
  },
  popupLoading: false,

  collectOverlay: {
    step: 'add',
    media: undefined,
  },

  setCreatorCountriesOverlay: {
    mediaList: undefined,
  },

  urlParams: {
    token: undefined,
    oobCode: undefined,
    oauthFailed: false,
  },

  oauth: {
    twitter: false,
    instagram: false,
    facebook: false,
    google: false,
  },

  groupMentionPages: {},
  groupUsers: [],
};

type ActionType = any; // TODO

export default function(state = initialState, action: ActionType): CommonState {
  switch (action.type) {
    case REDUX_ACTIONS.COMMON.SET_GROUP_MENTION_USERS:
      return {
        ...state,
        groupUsers: action.users,
      };
    case REDUX_ACTIONS.COMMON.MOUSE_CLICK:
      action.e.persist();
      return {
        ...state,
        mouseClick: action.e,
      };
    case REDUX_ACTIONS.COMMON.WINDOW_RESIZE:
      return {
        ...state,
        ...state,
        windowSize: {
          height: action.height,
          width: action.width,
        },
      };
    case REDUX_ACTIONS.COMMON.SET_WINDOW_FOCUS:
      return {
        ...state,
        windowFocus: action.focus,
      };
    case REDUX_ACTIONS.COMMON.SET_FACEBOOK_PAGES:
      window.analytics?.trackEvent(action.type, 'ReduxCommon');
      return {
        ...state,
        ...state,
        facebookPages: action.pages,
      };
    case REDUX_ACTIONS.COMMON.SET_FACEBOOK_AD_ACCOUNTS:
      window.analytics?.trackEvent(action.type, 'ReduxCommon');
      return {
        ...state,
        ...state,
        facebookAdAccounts: action.adAccounts,
      };
    case REDUX_ACTIONS.COMMON.SET_POPUP:
      return {
        ...state,
        popups: {
          ...state.popups,
          [action.popup]: action.val,
        },
      };
    case REDUX_ACTIONS.COMMON.SET_POPUP_LOADING:
      return {
        ...state,
        popupLoading: action.loading,
      };
    case REDUX_ACTIONS.COMMON.SET_USER_INFO:
      return setUserInfo(state, action.user);
    case REDUX_ACTIONS.COMMON.SET_ADMIN_INFO:
      return {
        ...state,
        admin: action.admin,
      };
    case REDUX_ACTIONS.COMMON.SET_CONFIG:
      return {
        ...state,
        ...state,
        config: action.config,
      };
    case REDUX_ACTIONS.COMMON.SET_OAUTH: {
      const oauths: CommonState['oauth'] = { ...state.oauth };
      oauths[action.social as keyof CommonState['oauth']] = action.enabled;

      return {
        ...state,
        ...state,
        oauth: oauths,
      };
    }
    case REDUX_ACTIONS.COMMON.ADD_ALERT: {
      const alerts: IAlert[] = [...state.alerts];
      const id = Math.random();
      alerts.push({
        ...action.alert,
        id,
      });

      return {
        ...state,
        ...state,
        alerts,
      };
    }
    case REDUX_ACTIONS.COMMON.REMOVE_ALERT:
      return {
        ...state,
        ...state,
        alerts: state.alerts.filter((a) => a.id !== action.id),
      };
    case REDUX_ACTIONS.COMMON.SET_URL_PARAM: {
      const urlParams: any = { ...state.urlParams };
      const { key } = action;
      urlParams[key] = action.value;

      return {
        ...state,
        ...state,
        urlParams,
      };
    }
    case REDUX_ACTIONS.COMMON.COLLECT_OVERLAY:
      return {
        ...state,
        collectOverlay: {
          ...state.collectOverlay,
          step: action.step,
          media: action.media || state.collectOverlay.media,
        },
        popups: {
          ...state.popups,
          collectOverlay: true,
        },
      };
    case REDUX_ACTIONS.COMMON.SET_CREATOR_COUNTRIES_OVERLAY:
      return {
        ...state,
        setCreatorCountriesOverlay: {
          ...state.setCreatorCountriesOverlay,
          mediaList: action.mediaList,
        },
        popups: {
          ...state.popups,
          setCreatorCountriesOverlay: true,
        },
      };
    case REDUX_ACTIONS.COMMON.SET_GROUP_MENTION_PAGES:
      return setGroupMentionPages(state, action);
    default:
      return state;
  }
}

const setUserInfo = function(state: CommonState, user: ApiTypes.ResGetMe['user']): CommonState {
  user = { ...user };
  if (!user.oauth) {
    user.oauth = initialState.oauth as any;
  }
  return {
    ...state,
    user,
    oauth: user.oauth as any, // TODO: this is garbage
  };
};

interface GroupMentionPage {
  pages: IMentionPage[];
}

const setGroupMentionPages = function(
  state: CommonState,
  { pages }: GroupMentionPage
): CommonState {
  const groupMentionPages: {
    [key: string]: IMentionPage;
  } = {};
  pages.forEach((p) => (groupMentionPages[p.id] = { ...p }));
  return {
    ...state,
    groupMentionPages,
  };
};
