import React, { Component } from 'react';
import NumberFormat from 'react-number-format';
import { style, stylesheet } from 'typestyle';
import { Column, Row } from '@src/components/Utils';
import LocalizedStrings from 'react-localization';
import { MediaView } from 'common';
import { BulkMediaActions } from '@src/components/BulkMediaActions';
import { Hide } from '@src/components/Hide';
import { Loader } from '@src/components/Loader';
import { MediaActionButtonController } from '@src/fragments/MediaActionButton/MediaActionController';
import { Browsing } from '@src/types/Browsing';
import { IPopupsService } from '@src/types/Popups';
import { WithMediaSelection } from '@src/types/MediaSelection';

interface Props extends WithMediaSelection {
  isExactMode: boolean;
  estimatedCount: number;
  exactCount: number;
  libraryFetching: boolean;
  services: {
    popups: IPopupsService;
    browsing: Browsing;
  };
  collectAllSelected: (medias: MediaView[]) => void;
  onSearchModeChange(exact: boolean): void;
  onSelectAll: () => void;
  selectAllActive: boolean;
  setSelectAllActive: (active: boolean) => void;
  isFetchingAll: boolean;
  catalogCountries?: string[];
}

export class LibraryHeader extends Component<Props> {
  public render() {
    return (
      <Row className={libraryHeaderClass}>
        <Column className={selectOptionsClass}>
          <div className={actionGroupClass}>
            {/* "Select All" Button */}
            <button
              className={buttonClass}
              onClick={this.props.onSelectAll}
              disabled={this.props.selectAllActive || this.props.isFetchingAll}
              title="Up to 10 000 media will be selected"
            >
              {this.props.isFetchingAll ? 'Selecting...' : 'Select All'}
            </button>

            {/* BulkMediaActions */}
            <BulkMediaActions
              count={this.props.mediaSelection.length}
              onClear={() => {
                this.props.setMediaSelection([]);
                this.props.setSelectAllActive(false);
              }}
              mediaActionButton={(
                <MediaActionButtonController
                  inCollection={undefined}
                  mediaList={this.props.mediaSelection}
                  services={this.props.services}
                  catalogCountries={this.props.catalogCountries}
                />
              )}
            />
          </div>
        </Column>

        {/* Results Section */}
        <div className={resultsClass}>
          <div className={resultsLineClass}>
            <Hide hide={!this.props.libraryFetching}>
              <div className={sheet.fetching}>
                <Loader size={10} />
              </div>
            </Hide>
            <span className={estimateDescClass}>
              {lang.estimateDescription}
            </span>
            <button
              className={buttonClass}
              onClick={() => this.props.onSearchModeChange(false)}
              disabled={!this.props.isExactMode}
              style={{ color: !this.props.isExactMode ? 'white' : undefined }}
            >
              <NumberFormat
                value={this.props.estimatedCount}
                displayType="text"
                thousandSeparator
                className={countClass}
              />
              {window.T('collect.filterright.results')}
            </button>
          </div>
          <button
            className={buttonClass}
            onClick={() => this.props.onSearchModeChange(true)}
            disabled={!!this.props.isExactMode}
            style={{ color: this.props.isExactMode ? 'white' : undefined }}
          >
            <NumberFormat
              value={this.props.exactCount}
              displayType="text"
              thousandSeparator
              className={countClass}
            />
            {window.T('collect.filterright.exactresults')}
          </button>
        </div>
      </Row>
    );
  }
}

const lang = new LocalizedStrings({
  en: {
    estimateDescription: 'Estimated media matches: ',
  },
});

const sheet = stylesheet({
  fetching: {
    marginRight: 10,
    display: 'inline-block',
  },
});

const libraryHeaderClass = style({
  fontSize: 14,
  justifyContent: 'space-between',
});

const countClass = style({
  marginRight: 4,
});

const buttonClass = style({
  fontSize: 13,
  textAlign: 'right',
  background: 'none',
  border: 'none',
  outline: 'none',
  color: 'gray',
  fontWeight: 'bold',
  padding: 0,
  $nest: {
    '&:hover': {
      textDecoration: 'underline',
    },
    '&:disabled': {
      cursor: 'default',
      textDecoration: 'none',
    },
  },
});

const estimateDescClass = style({
  marginRight: 4,
  fontSize: 12,
  verticalAlign: 'top',
});

const resultsClass = style({
  color: 'gray',
  display: 'flex',
  flexDirection: 'column',
});

const resultsLineClass = style({
  lineHeight: '10px',
});

const selectOptionsClass = style({
  justifyContent: 'flex-end',
  flexGrow: 0,
});

const actionGroupClass = style({
  display: 'flex',
  alignItems: 'center',
  gap: '16px', // Add space between "Select All" and BulkMediaActions
});
