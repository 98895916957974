import { SearchBarSuggestions, SearchUtilityInterface } from '@src/types/Search';
import { SEARCH_SUGGESTION_MAX } from '@src/config/search';

import { ArrayHelper } from '../../../services/helpers/array';

export const EXCLUSION_CHAR = '-';

export const SearchUtility = new class implements SearchUtilityInterface {
  /**
     * Remove the minus character and return the new string and a boolean telling if the val was an exclusion
     * @param val text
     */
  public parseExclusion(val: string): [string, boolean] {
    const b = val.indexOf(EXCLUSION_CHAR) === 0;
    const v = b ? val.slice(1) : val;
    return [v, b];
  }

  public addExclusionChar(val: string): string {
    return `${EXCLUSION_CHAR}${val}`;
  }

  public getExclusionList(vals: string[]): string[] {
    return ArrayHelper.filterNulls(vals.map((v) => {
      const [val, isExclusion] = this.parseExclusion(v);
      return isExclusion ? val : null;
    }));
  }

  public getInclusionList(vals: string[]): string[] {
    return ArrayHelper.filterNulls(vals.map((v) => {
      const [val, isExclusion] = this.parseExclusion(v);
      return isExclusion ? null : val;
    }));
  }

  public removeAlreadySelectedFacets(facets: SearchBarSuggestions, selectedFacets: SearchBarSuggestions): SearchBarSuggestions {
    const newFacets: SearchBarSuggestions = { ...facets };

    Object.entries(newFacets).forEach(([key, _]) => {
      const facetKey = key as keyof SearchBarSuggestions;
      newFacets[facetKey] = (newFacets[facetKey] || [])
        .filter((facet) => (selectedFacets[facetKey] || []).indexOf(facet) === -1)
        .slice(0, SEARCH_SUGGESTION_MAX);
    });

    return newFacets;
  }
}();
