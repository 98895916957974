import React, { useState } from 'react';
import * as reduxHelpers from '@src/services/helpers/redux';
import { CollectionView } from 'common';
import { PerfectScrollbar } from 'components/PerfectScrollbar';
import { style, stylesheet } from 'typestyle';
import { COLLECTION_NAME_MAX_LENGTH } from '@src/config';
import { useMutationCollection } from '@src/queries/collections/useMutationCollection';
import { getCollectionLangError } from '@src/pages/Collection/collectionErrors';
import { CloseButton } from '@src/components/CloseButton';
import { ActionButton } from '@src/components/ActionButton';
import { Input } from '@src/components/Input';
import { ModalCloseButtonPosition } from '@src/components/Modals/CloseButtonPosition';
import { FormLine } from '@src/components/Form/FormLine';
import { OverlayContainer } from '@src/components/OverlayContainer';
import { useAddAlert } from '@src/fragments/Alerting/alertAtom';

interface Props {
  collection: CollectionView;
  onClose: () => void;
}

export function CollectionSettings(props: Props) {
  const [editedName, setEditedName] = useState(props.collection.name);
  const [editedDescription, setEditedDescription] = useState(props.collection.description);
  const addAlert = useAddAlert();

  const { mutateAsync: mutateCollection, reset: resetMutateCollection } = useMutationCollection();

  const detailsSave = () => {
    mutateCollection(
      {
        _id: props.collection._id,
        name: editedName,
        description: editedDescription,
      },
      {
        onSuccess: () => {
          reduxHelpers.addAlert({
            text: 'create.collection.settings.details.success',
            type: 'success',
          });
        },
        onError: (error) =>
          addAlert({
            text: getCollectionLangError(error),
            close: resetMutateCollection,
          }),
      },
    ).catch(console.error);
  };

  const canSave =
    editedName !== props.collection.name || editedDescription !== props.collection.description;

  return (
    <OverlayContainer style={{ minWidth: 450, padding: 35 }} onClose={props.onClose}>
      <ModalCloseButtonPosition>
        <CloseButton onClick={props.onClose} />
      </ModalCloseButtonPosition>
      <PerfectScrollbar
        options={{
          minScrollbarLength: 30,
          wheelSpeed: 0.3,
        }}
        className={scrollContainerClass}
      >
        <div className={contentContainerClass}>
          <FormLine title={window.T('create.collection.settings.details.title')}>
            <div className={sheet.detailsContainer}>
              <div className={sheet.detailsField}>
                <Input
                  value={editedName}
                  maxLength={COLLECTION_NAME_MAX_LENGTH}
                  onChange={({ target }) => setEditedName(target.value)}
                  placeholder={window.T('create.collection.settings.details.name_placeholder')}
                />
              </div>
              <div className={sheet.detailsField}>
                <Input
                  defaultValue={editedDescription}
                  onChange={({ target }) => setEditedDescription(target.value)}
                  placeholder={window.T('create.collection.settings.details.desc_placeholder')}
                />
              </div>
              {canSave && (
                <div className={sheet.detailsSave}>
                  <ActionButton variant='blue' onClick={detailsSave}>
                    {window.T('create.collection.settings.details.save').toUpperCase()}
                  </ActionButton>
                </div>
              )}
            </div>
          </FormLine>
        </div>
      </PerfectScrollbar>
    </OverlayContainer>
  );
}

const sheet = stylesheet({
  collectionSettings: {
    position: 'fixed',
    width: '100%',
    height: '100%',
    top: 0,
    left: 0,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    zIndex: 5,
  },
  frame: {
    zIndex: 3,
    margin: 'auto',
    width: 500,
    maxHeight: '94%',
    backgroundColor: '#202020',
    fontFamily: '"Public Sans", sans-serif',
    overflow: 'hidden',
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
  },
  detailsContainer: {
    padding: '10px 7px 5px',
  },
  detailsField: {
    paddingBottom: 10,
    $nest: {
      input: {
        borderColor: '#a6a6a6',
        width: '100%',
      },
    },
  },
  detailsSave: {
    marginTop: 15,
    textAlign: 'center',
    $nest: {
      button: {
        minWidth: 110,
        padding: '6px 20px',
      },
    },
  },
  noOne: {
    display: 'block',
    textAlign: 'center',
    margin: '15px 0 5px',
    color: 'gray',
  },
  permissionsUser: {
    fontSize: 14,
    color: '#d2d2d2',
    padding: '10px 7px 5px',
    display: 'flex',
    justifyContent: 'space-between',
  },
  permissionChangeButton: {
    whiteSpace: 'nowrap',
  },
  permissionUsername: {
    overflow: 'hidden',
    paddingTop: 4,
    textOverflow: 'ellipsis',
    marginRight: 23,
  },
  checkBox: {
    marginLeft: 10,
  },
  selectPermsClass: {
    display: 'flex',
    justifyContent: 'space-between',
    $nest: {
      '> div': {
        cursor: 'pointer',
      },
      '.select': {
        position: 'relative',
        fontSize: 13,
        $nest: {
          i: {
            marginLeft: 10,
            fontSize: 18,
            verticalAlign: 'middle',
          },
          span: {
            fontSize: 14,
            fontFamily: '"Public Sans", sans-serif',
          },
        },
        color: '#aaaaaa',
        transition: 'color 0.2s',
      },
    },
  },

  selectPermsViewClass: {
    paddingRight: 14,
  },

  selectPermsBoxClass: {
    $nest: {
      '&:hover': {
        color: 'white',
      },
    },
  },
});

const scrollContainerClass = style({
  position: 'relative',
  height: '100%',
});

const contentContainerClass = style({
  padding: '5px 10px 28px 0',
});
