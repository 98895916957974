import React from 'react';
import { SectionFolder } from '@src/components/SectionFolder';
import { SectionTitle } from '@src/components/SectionTitle';
import { SectionProps } from '../types/Settings';
import LocalizedStrings from 'react-localization';
import { FORM_LINE_TITLE_SIZE, SECTION_TITLE_SIZE } from '../utils/variables';
import { SectionContent } from '../subcomponents/SectionContent';
import { createChangeSettings } from '../utils/createChangeSettings';
import { ThumbnailIcon } from '../../Icons/DropDownIcons/Thumbnail';
import { ThumbnailShapeSelector } from '../selectors/ThumbnailShapeSelector';
import { SettingsCheckbox } from '../subcomponents/SettingsCheckbox';
import { WidgetView } from 'common';
import { ThumbnailCornersSelector } from '../selectors/ThumbnailCornersSelector';
import { FormLine } from '@src/components/Form/FormLine';
import { ThumbnailPackshotSelector } from '../selectors/ThumbnailPackshotSelector';

const ThumbnailDesign = ({ opened, onOpen, editedWidget, setEditedWidget }: SectionProps) => {
  const changeSettings = createChangeSettings(editedWidget, setEditedWidget);

  const handleThumbnailChange = <K extends keyof WidgetView['settings']>(
    key: K,
    value: WidgetView['settings'][K],
  ) => {
    if (value === editedWidget.settings[key]) {
      return;
    }
    changeSettings({ [key]: value });
  };

  return (
    <SectionFolder
      title={
        <SectionTitle size={SECTION_TITLE_SIZE}>
          <ThumbnailIcon />
          {lang.title.main}
        </SectionTitle>
      }
      opened={opened}
      onOpen={onOpen}
    >
      <SectionContent>
        <SettingsCheckbox
          label={lang.fields.displayName}
          checked={editedWidget.settings.display_name}
          onClick={() =>
            changeSettings({
              display_name: !editedWidget.settings.display_name,
            })
          }
        />

        <FormLine title={lang.title.shape} size={FORM_LINE_TITLE_SIZE}>
          <ThumbnailShapeSelector
            thumbnail_shape={editedWidget.settings.thumbnail_shape}
            onChange={(value) => handleThumbnailChange('thumbnail_shape', value)}
          />
        </FormLine>

        <FormLine title={lang.title.corners} size={FORM_LINE_TITLE_SIZE}>
          <ThumbnailCornersSelector
            thumbnail_corners={editedWidget.settings.thumbnail_corners}
            onChange={(value) => handleThumbnailChange('thumbnail_corners', value)}
          />
        </FormLine>

        <FormLine title={lang.title.packshot} size={FORM_LINE_TITLE_SIZE}>
          <ThumbnailPackshotSelector
            thumbnail_packshot={editedWidget.settings.thumbnail_packshot}
            onChange={(value) => handleThumbnailChange('thumbnail_packshot', value)}
          />
        </FormLine>
      </SectionContent>
    </SectionFolder>
  );
};

const lang = new LocalizedStrings({
  en: {
    title: {
      main: 'Thumbnail Design',
      shape: 'shape',
      corners: 'corners',
      packshot: 'packshot',
    },
    fields: { displayName: 'Display username' },
  },
});

export default ThumbnailDesign;
