import React, { useState, useRef, RefObject } from 'react';
import { PerfectScrollbar } from 'components/PerfectScrollbar';

import * as ShareActions from 'actions/share';
import uniqid from 'uniqid';
import { bigStore } from 'store';
import NumberFormat from 'react-number-format';
import * as reduxHelpers from '@src/services/helpers/redux';
import { api } from '@src/services/api';
import { TShareCollectionInfo, ShareInfo } from '@src/types/redux/share';
import { setPopup } from '@src/actions/common';
import { EPopup } from '@src/types/redux/common';
import { PageTitle } from '@src/components/PageTitle';
import LocalizedStrings from 'react-localization';
import { useQueryCollections } from '@src/queries/collections/useQueryCollections';
import { CollectionView } from 'common';
import { BrandAssets } from './components/BrandAssets';
import { IUploadingFile } from './components/ShareCollectionContent/components/UploadingFile';
import styles from './styles.scss';
import { ShareCollectionContent } from './components/ShareCollectionContent';
import { ShareCollection } from './components/ShareCollection';
import { addBrandContentToQueryData } from '../../../queries/brandcontent/useQueryBrandContents';

const MAX_IMG_BYTES = 8 * 10 ** 6; // 8MB
const MAX_VIDEO_BYTES = 1 * 10 ** 9; // 1GB

interface UploadingFiles {
  [key: string]: IUploadingFile | undefined
}

export function ShareCollections() {
  const [uploadingFiles, setUploadingFiles] = useState<UploadingFiles>({});

  const scrollingContainerRef = useRef<HTMLDivElement>(null);

  const { data: collections = [] } = useQueryCollections();

  const showOpenedCollection = (collectionRef: HTMLDivElement | null) => {
    const scrollingContainer = scrollingContainerRef.current;
    if (!collectionRef || !scrollingContainer) {
      return;
    }
    const before = collectionRef.offsetTop - scrollingContainer.scrollTop;
    const invisible = (before + collectionRef.clientHeight) - scrollingContainer.clientHeight;

    if (invisible > 0) {
      scrollingContainer.scrollBy(0, invisible + 45);
    }
  };

  const onShare = (info: ShareInfo) => {
    bigStore.dispatch(ShareActions.openSharePrompt(info));
  };

  /**
	 * Create a new BrandContent
	 */
  const uploadFile = async (file: File, collection?: { _id: string } & Partial<TShareCollectionInfo>) => {
    if (!validUploadFile(file)) {
      return;
    }
    const id = uniqid();
    const localUrl = URL.createObjectURL(file);
    const brandContent = await api.uploadBrandContent(file, collection?._id, (percentage) => {
      const uploading: IUploadingFile = {
        localUrl,
        progress: percentage,
        id,
        collectionId: collection?._id,
      };
      setUploadingFiles((currentState) => ({
        ...currentState,
        [id]: uploading,
      }));
    }).catch((err) => {
      console.error('Upload Error', err);
      if (err.type === 'badformat') {
        reduxHelpers.addAlert({
          type: 'danger',
          text: lang.formatString(lang.error.badformat, {
            name: file.name,
            formats: err.data.formats.join(', '),
          }).toString(),
        });
      } else {
        reduxHelpers.addAlert({ type: 'danger', text: lang.error.upload });
      }
    });

    const mutatedUploadingFiles = { ...uploadingFiles };
    delete mutatedUploadingFiles[id];
    setUploadingFiles(mutatedUploadingFiles);

    if (brandContent) {
      // optimistically update brand content query
      addBrandContentToQueryData(brandContent, collection?._id);
    }
  };

  const validUploadFile = (file: File) => {
    if (/image/.test(file.type) && file.size > MAX_IMG_BYTES) {
      reduxHelpers.addAlert({
        type: 'danger',
        text: lang.formatString(lang.error.fileTooBig, {
          type: 'Images',
          max: MAX_IMG_BYTES / 10 ** 6,
          name: file.name,
          unit: 'MB',
        }).toString(),
      });
      return false;
    } if (/video/.test(file.type) && file.size > MAX_VIDEO_BYTES) {
      reduxHelpers.addAlert({
        type: 'danger',
        text: lang.formatString(lang.error.fileTooBig, {
          type: 'Videos',
          max: MAX_VIDEO_BYTES / 10 ** 9,
          name: file.name,
          unit: 'GB',
        }).toString(),
      });
      return false;
    }
    return true;
  };

  return (
    <div className={styles.shareCollections}>
      <div className={styles.header}>
        <div className="left">
          <div className="title">
            <PageTitle>
              {lang.title}
            </PageTitle>
          </div>
          <div className="count">
            <NumberFormat
              value={collections.length}
              displayType="text"
              thousandSeparator
            />
            {' '}
            {window.T('share.desc')}
          </div>
        </div>
      </div>

      <PerfectScrollbar
        containerRef={scrollingContainerRef}
        options={{ minScrollbarLength: 30 }}
        className={styles.collections}
      >
        <div>
          <div data-empty={window.T('share.empty')}>
            <BrandAssets
              onShare={onShare}
              onUpload={(files: File[]) => files.forEach((file) => uploadFile(file))}
              uploadingFiles={Object.values(uploadingFiles).filter((u): u is IUploadingFile => !u?.collectionId)}
            />
            {collections.map((collection) => (
              <CollectionLine
                key={collection._id}
                collection={collection}
                onOpenedCollection={(ref) => setTimeout(() => showOpenedCollection(ref.current), 200)}
                onShare={onShare}
                uploadFile={uploadFile}
                uploadingFiles={uploadingFiles}
              />
            ))}
          </div>
        </div>
      </PerfectScrollbar>
    </div>
  );
}

function CollectionLine({
  collection,
  uploadingFiles,
  onOpenedCollection,
  onShare,
  uploadFile,
}: {
  collection: CollectionView
  uploadingFiles: UploadingFiles
  onOpenedCollection: (ref: RefObject<HTMLDivElement>) => void
  onShare: (info: ShareInfo) => void
  uploadFile: (file: File, collection: { _id: string } & Partial<TShareCollectionInfo>) => void
}) {
  const ref = useRef<HTMLDivElement>(null);
  const [opened, setOpened] = useState(false);

  return (
    <div key={collection._id}>
      <ShareCollection
        collection={collection}
        onSelect={() => setOpened(!opened)}
      />
      {opened
                && (
                <ShareCollectionContent
                  canEdit={!!collection.i_can_edit}
                  onLoadedContent={() => onOpenedCollection(ref)}
                  onShare={onShare}
                  onUpload={(files) => files.forEach((file) => uploadFile(file, collection))}
                  uploadingFiles={Object.values(uploadingFiles).filter((u): u is IUploadingFile => u?.collectionId === collection._id)}
                  collectionId={collection._id}
                />
                )}
    </div>
  );
}

const lang = new LocalizedStrings({
  en: {
    title: 'Share content',
    error: {
      upload: 'Upload failed. Please try again or contact us',
      fileTooBig: 'Your file "{name}" is too big. {type} should be less than {max} {unit} due to Facebook and Instagram restrictions',
      badformat: 'Your file "{name}" can\'t be uploaded. Due to Facebook and Instagram restrictions, it should be in one of the following formats: {formats}',
    },
  },
});
