import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import LocalizedStrings from 'react-localization';
import { PageTitle } from '@src/components/PageTitle';
import { CollectionView } from 'common';
import { Select } from '@src/components/Select';
import { SortBy } from '../../ListPage';
import CreateCollection from '@src/pages/Popups/components/CollectOverlay/components/CreateCollection';
import { IconButton, Modal, Tooltip } from '@mui/material';
import AddCircleIcon from '@mui/icons-material/AddCircle';
import { ADALONG_COLORS } from '@src/styles';
import { SearchAndSelectBar } from '@src/components/SearchAndSelectBar';
import { style } from 'typestyle';

interface Props {
  sortBy: SortBy;
  list: CollectionView[];
  setSortBy: (sortBy: SortBy) => void;
  filter: string;
  setFilter: Function;
}

export function CollectionsHeader(props: Props) {
  const keys: SortBy[] = ['title', 'date'];
  const [createCollection, setCreateCollection] = useState(false);
  const { sortBy, setSortBy, list, filter, setFilter } = props;
  const sortByOptions = keys.map((o) => ({
    value: o,
    text: window.T(`create.collections.sortby.${o}`),
  }));
  return (
    <div className='header'>
      <div className='left'>
        <div className='title'>
          <PageTitle>{lang.title}</PageTitle>
        </div>
        <div className='count'>
          <NumberFormat value={list.length} displayType='text' thousandSeparator />{' '}
          {window.T('create.collections.collections')}
        </div>
      </div>
      <div className='center'>
        <SearchAndSelectBar
          className={searchStyle}
          inputValue={filter}
          handlers={{
            onText: (val) => setFilter(val),
            onCloseBar: () => setFilter(''),
          }}
        />
      </div>
      <div className='right'>
        <Tooltip title={'Add a collecton'} placement={'top'}>
          <IconButton
            sx={{
              marginRight: 10,
            }}
            onClick={() => setCreateCollection(true)}
          >
            <AddCircleIcon
              fontSize={'large'}
              sx={{
                color: ADALONG_COLORS.LIGHT_GRAY,
              }}
            />
          </IconButton>
        </Tooltip>
        <Select options={sortByOptions} onChange={setSortBy} value={sortBy} variant='adalong' />
      </div>
      <Modal
        sx={{
          position: 'absolute',
          top: '20%',
          backgroundColor: 'rgba(0,0,0,0.5)',
          flexShrink: 0,
          overflow: 'hidden',
          borderRadius: 10,
          border: `1px solid ${ADALONG_COLORS.SMOOTH_BLACK}`,
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'center',
          cursor: 'pointer',
        }}
        open={createCollection}
        onClose={() => setCreateCollection(false)}
      >
        <CreateCollection onClose={() => setCreateCollection(false)}/>
      </Modal>
    </div>
  );
}

const searchStyle = style({
  marginTop: 70,
  marginLeft: 100,
  width: 400,
});

const lang = new LocalizedStrings({
  en: {
    title: 'Collections',
  },
});
