import React from 'react';
import Tooltip from '@mui/material/Tooltip';
import { WarningIcon } from '@src/components/WarningIcon';
import LocalizedStrings from 'react-localization';
import { stylesheet } from 'typestyle';

interface Props {
  /**
   * Display a warning about media definition or availability for download
   */
  availability: string;
  /**
   * Display a warning about rights not approved to use a content
   */
  rights: boolean;
}

/**
 * Warns about the download restrictions
 */
export function DownloadWarning(props: Props) {
  const tooltip = <DownloadWarningText {...props} />;
  return (
    <Tooltip title={tooltip} placement='right-start'>
      <span>
        <WarningIcon className={sheet.warning} fontSize='small' />
      </span>
    </Tooltip>
  );
}

/**
 * Create a fragment containing warning text to display depending on props
 */
export function DownloadWarningText(props: Props) {
  return (
    <>
      {props.availability === 'NA' && <span>{lang.NA}</span>}
      {props.availability === 'VCR' && <span>{lang.VCR}</span>}
      {/* Only the second line need a line break */}
      {props.rights && <div>{lang.rights}</div>}
    </>
  );
}

const lang = new LocalizedStrings({
  en: {
    NA: 'This content is unavailable.',
    VCR: 'This video cannot be downloaded due to copyrighted music.',
    rights: "You didn't get the rights to use this content",
  },
});

const sheet = stylesheet({
  warning: {
    marginLeft: 5,
    verticalAlign: 'bottom',
  },
});
