import React, { Component } from 'react';
import { connect } from 'react-redux';

import { ICombinedReducers } from '@src/types/redux';
import { loadDisplayPreview } from 'actions/share';
import { loadTranslation } from '@src/services/helpers/redux';
import translation from './translation';
import Grid from './components/Grid';
import styles from './styles.scss';

const lang = loadTranslation(translation);

const mapStateToProps = (state: ICombinedReducers) => ({
  displayPreview: state.share.displayPreview,
});

const dispatchToProps = {
  loadDisplayPreview,
};

type Props = Readonly<typeof dispatchToProps> & Readonly<ReturnType<typeof mapStateToProps>>;

class Display extends Component<Props> {
  public render() {
    const incorrect = this.incorrectOrder();

    return (
      <div className={styles.display}>
        <div className={styles.titlebar}>
          <div>
            {lang.title}
          </div>
        </div>
        {incorrect.length ? (
          <div className="alert alert-warning text-center mb-3">
            {lang.dateOrderWarning}
          </div>
        ) : null}
        <Grid incorrectContentOrder={incorrect} />
      </div>
    );
  }

  public componentDidMount() {
    this.props.loadDisplayPreview();
  }

  /**
	 * Returns misplacedcontent content ids
	 */
  public incorrectOrder() {
    if (!this.props.displayPreview?.content?.length) {
      return {};
    }
    // Keep content with dates;
    const content = this.props.displayPreview.content.filter((c) => !!c.date);

    const incorrect: { [key: string]: boolean } = {};
    let minDate: string | undefined = (content[0] || {}).date;
    content.forEach(({ _id, date }) => {
      if (minDate && date! > minDate) {
        incorrect[_id] = true;
      } else {
        minDate = date;
      }
    });

    return incorrect;
  }
}

export default connect(
  mapStateToProps,
  dispatchToProps,
)(Display);
