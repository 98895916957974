import React, { useCallback, useState } from 'react';
import AllInclusiveIcon from '@mui/icons-material/AllInclusive';
import { ThemeStyle } from '@src/styles';
import LocalizedStrings from 'react-localization';
import { ToggleButtons } from '../ToggleButtons';
import { LinkExactButton } from './LinkButton/LinkExactButton';
import { LinkSimilarButton } from './LinkButton/LinkSimilarButton';
import { LinkButton } from './LinkButton/LinkButton';

export enum LinkedFilterValue {
  'all',
  'exact',
  'similar',
}
interface Props {
  value: LinkedFilterValue;
  buttonSize?: number;
  onChange: (value: LinkedFilterValue) => void;
}

export function LinkedFilter({ value, buttonSize = ThemeStyle.textSizes.large, onChange }: Props) {
  const style: React.CSSProperties = {
    width: buttonSize,
    height: buttonSize,
    marginRight: 10,
    marginLeft: 10,
  };

  const [selectedFilter, setSelectedFilter] = useState<LinkedFilterValue | null>(null);

  const onSimilarClick = useCallback(() => setSelectedFilter(LinkedFilterValue.similar), [
    selectedFilter,
  ]);
  const onAllClick = useCallback(() => setSelectedFilter(LinkedFilterValue.all), [selectedFilter]);
  const onExactClick = useCallback(() => setSelectedFilter(LinkedFilterValue.exact), [
    selectedFilter,
  ]);

  return (
    <ToggleButtons
      onChange={onChange}
      buttons={[
        {
          element: <AllInclusiveIcon onClick={onAllClick} style={style} />,
          val: LinkedFilterValue.all,
          tooltip: lang.all,
        },
        {
          element: (
            <LinkButton
              tooltip={lang.similar}
              onClick={onSimilarClick}
              inactive={selectedFilter !== LinkedFilterValue.similar}
              svg={LinkSimilarButton}
            />
          ),
          val: LinkedFilterValue.similar,
        },
        {
          element: (
            <LinkButton
              tooltip={lang.exact}
              onClick={onExactClick}
              inactive={selectedFilter !== LinkedFilterValue.exact}
              svg={LinkExactButton}
            />
          ),
          val: LinkedFilterValue.exact,
        },
      ]}
      value={value}
    />
  );
}

const lang = new LocalizedStrings({
  en: {
    all: 'Show all products',
    similar: 'Show only similar products linked',
    exact: 'Show only exact products linked',
    linkedOff: 'Product not linked only',
    linkedOnly: '',
  },
});
