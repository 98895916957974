import { PlayCircleFilledWhite } from '@mui/icons-material';
import React from 'react';

interface Props {
  marginBottom?: number;
  fontSize?: number;
  color?: string;
}

export function CheckboxNote({
  children,
  marginBottom,
  fontSize = 0,
  color = 'white',
}: React.PropsWithChildren<Props>) {
  return <div style={{ marginBottom, fontSize, color }}>{children}</div>;
}
