import React, { PropsWithChildren } from 'react';
import { style } from 'typestyle';
import { FolderButton } from './FolderButton';
import { Row } from './Utils';

interface Props {
  title: string | JSX.Element;
  indentation?: number;
  opened?: boolean;
  onOpen?: (opened: boolean) => void;
  /**
   * An element to append after the title
   */
  afterTitle?: JSX.Element;
  tooltip?: string;
}

const CHILDREN_INDENTATION = 14;

export function SectionFolder({ title, indentation = 1, opened = true, onOpen, afterTitle, children }: PropsWithChildren<Props>) {
  return (
    <div>
      <Row className={titleClass}>
        <span>
          <span className={titleTextClass}>{title}</span>
          {afterTitle}
        </span>
        {onOpen && (
          <div className={folderButtonClass} onClick={() => onOpen(!opened)}>
            <FolderButton size={22} opened={opened} />
          </div>
        )}
      </Row>
      {opened && (
        <div
          className={childrenClass}
          style={{
            paddingLeft: CHILDREN_INDENTATION * indentation,
          }}
        >
          {children}
        </div>
      )}
    </div>
  );
}

const titleClass = style({
  textTransform: 'uppercase',
  color: 'white',
  fontWeight: 'bold',
  justifyContent: 'space-between',
  marginBottom: 20,
  marginTop: 20,
});

const titleTextClass = style({
  verticalAlign: 'middle',
});

const folderButtonClass = style({
  paddingRight: 7,
  cursor: 'pointer',
});

const childrenClass = style({
  fontSize: 14,
  fontWeight: 'inherit',
});
