import React, { ChangeEvent, Component } from 'react';
import CircularProgress from '@mui/material/CircularProgress';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import Button from '@mui/material/Button';
import { connect } from 'react-redux';
import { Row } from '@src/components/Utils';
import { addAlert } from '@src/services/helpers/redux';
import { style } from 'typestyle';
import { SocialNetworkButton } from 'components/SocialNetworkButton';
import LocalizedStrings from 'react-localization';
import { VideoMakerView } from 'common';
import { ICombinedReducers } from '@src/types/redux';
import { FBAdAccountResponse } from '@src/types/api';
import { api } from '../../../../../services/api';

interface IProps {
  gif: VideoMakerView
  onProcess: (videoMaker: VideoMakerView) => Promise<void>
}

const mapStateToProps = (state: ICombinedReducers) => ({
  title: state.video.gif.name,
  description: state.video.gif.description,
  resolvedImages: state.video.resolvedImages,
});

type Props = Readonly<ReturnType<typeof mapStateToProps>> & IProps;

interface IState {
  processingStatus: 'processing' | 'done' | 'error' | 'nothing'
  facebookStatus: 'nothing' | 'posting' | 'posted' | 'error' | 'processingerror'
  processed: boolean
  videoURL?: string
  error: string
  noAdAccounts: boolean
  facebookAdsAccounts: FBAdAccountResponse[]
  selectedAccountIdx: number
}

class BaseVideoFacebookPublishing extends Component<Props, IState> {
  public processInterval?: any; // NodeJS.Timeout messes compiler

  public constructor(props: Props) {
    super(props);

    this.state = {
      error: '',
      processed: false,
      noAdAccounts: false,
      facebookStatus: 'nothing',
      processingStatus: 'nothing',
      facebookAdsAccounts: [],
      selectedAccountIdx: -1,
    };
  }

  public componentWillMount() {
    api.getFacebookAdAccounts().then((res) => {
      this.gotFBAccounts(undefined, res);
      this.process();
    }).catch((err: Error) => {
      this.gotFBAccounts(err);
    });
  }

  public componentWillUnmount() {
    clearInterval(this.processInterval);
  }

  public gotFBAccounts(err: Error | undefined, res?: FBAdAccountResponse[]): boolean {
    if (err || !res) {
      this.setState({
        facebookStatus: 'error',
        noAdAccounts: true,
      });
      return false;
    }
    this.setState({
      facebookAdsAccounts: res,
    });
    return true;
  }

  public async process() {
    this.processInterval = setInterval(() => this.checkFinalVideo(), 2500);
    this.setState({
      error: '',
      processingStatus: 'processing',
    });
    const GIF = { ...this.props.gif };
    GIF.elements = GIF.elements.map((elm) => {
      elm = { ...elm };
      if (elm.media && elm.image_url) {
        const resolvedImage = this.props.resolvedImages[elm.image_url];
        if (resolvedImage) {
          elm.image_url = resolvedImage.finalUrl;
        } else {
          console.error(elm);
          console.error(this.props.resolvedImages[elm.image_url]);
          throw new Error('One media could not be loaded');
        }
      }
      return elm;
    });

    await this.props.onProcess(GIF).catch((e) => {
      console.error(e);
      this.setState({
        error: e,
        processingStatus: 'error',
        processed: false,
      });
      addAlert({
        text: 'Could not process video, please try again',
        type: 'danger',
      });
      clearInterval(this.processInterval);
    });
  }

  public async checkFinalVideo() {
    await api.getVideoMakerURL({ videoID: this.props.gif._id }).then((response) => {
      if (response === '') {
        // Still processing
        this.setState({
          processed: false,
          processingStatus: 'processing',
        });
      } else if (typeof response === 'string') {
        addAlert({
          text: 'Video successfully processed',
          type: 'success',
        });
        // Got an URL
        this.setState({
          processed: true,
          videoURL: response,
          processingStatus: 'done',
        });
        clearInterval(this.processInterval);
      }
    }).catch((e) => {
      this.setState({
        error: e,
      });

      this.setState({
        processed: false,
        processingStatus: 'error',
      });
      addAlert({
        text: 'Could not upload video to facebook, please try again',
        type: 'danger',
      });
      clearInterval(this.processInterval);
    });
  }

  public async publishFacebookAds(videoURL: string, accountId: string) {
    this.setState({
      facebookStatus: 'posting',
    });
    await api.publishVideoMakerAds({
      videoMaker: this.props.gif,
      videoURL,
      accountID: accountId,
    }).then(() => {
      this.setState({
        facebookStatus: 'posted',
      });
    }).catch((e: Error) => {
      this.setState({
        facebookStatus: 'error',
        error: `${e}`,
      });
    });
  }

  public handleAccountSelection(event: SelectChangeEvent<{ name?: string | undefined; value: unknown }>) {
    this.setState({
      selectedAccountIdx: event.target.value as any,
    });
  }

  public render() {
    return (
      <div className={containerClass}>
        <>
          <Row style={{
            alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center', color: 'white', flexGrow: 3,
          }}
          >
            {
                            this.state.processingStatus === 'error' && (
                            <p>
                              An error occured when processing video :(
                              <br />
                              <Button
                                onClick={() => this.process()}
                                variant="outlined"
                                style={{
                                  color: 'white', backgroundColor: '#1f73e5',
                                }}
                              >
                                Try again
                              </Button>
                            </p>
                            )
                        }
            {
                            this.state.processingStatus === 'nothing' && (
                            <p>
                              <Button
                                onClick={() => this.process()}
                                variant="outlined"
                                style={{
                                  color: 'white', backgroundColor: '#1f73e5',
                                }}
                              >
                                Begin video rendering...
                              </Button>
                            </p>
                            )
                        }
            {
                            this.state.processingStatus === 'processing' && (
                            <p>
                              Processing video...
                              <CircularProgress />
                            </p>
                            )
                        }
            {this.state.processingStatus === 'done' && (
            <>
              <div style={{ marginRight: 20 }}>Select an account to publish your video: </div>
              <Select
                className={selectorClass}
                style={{ color: 'white' }}
                id="demo-simple-select-helper"
                value={this.state.selectedAccountIdx as any}
                onChange={this.handleAccountSelection.bind(this)}
              >
                <MenuItem key={-1} value={-1}>
                  <em>None</em>
                </MenuItem>
                {this.state.facebookAdsAccounts.map((account, idx) => <MenuItem key={idx} value={idx}>{account.name}</MenuItem>)}
              </Select>
            </>
            )}
          </Row>
          {this.state.noAdAccounts
            ? (
              <Row style={{
                alignItems: 'center', flexWrap: 'wrap', justifyContent: 'center', color: 'white',
              }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <p>No facebook ads account, please link your account</p>
                  <SocialNetworkButton linked={false} social="facebook" />
                </div>
              </Row>
            )
            : (
              <>
                <Row>
                  {this.state.facebookStatus === 'posting' ? (
                    <>
                      Posting to facebook
                      {' '}
                      <CircularProgress />
                    </>
                  ) : (
                    <Button
                      variant="outlined"
                      style={{
                        color: 'white', backgroundColor: (this.state.selectedAccountIdx < 0) ? '#888' : '#1f73e5', height: 52,
                      }}
                      disabled={this.state.selectedAccountIdx < 0}
                      onClick={() => {
                        this.state.videoURL
                          ? this.publishFacebookAds(this.state.videoURL, this.state.facebookAdsAccounts[this.state.selectedAccountIdx].id)
                          : console.error('no videoURL available');
                      }}
                    >
                      Publish to facebook
                    </Button>
                  )}
                </Row>
                {this.state.facebookStatus != 'nothing'
                                && (
                                <Row>
                                  {window.reactSwitch(this.state.facebookStatus, [
                                    {
                                      val: 'error',
                                      elem: (
                                        <p>An error occured when posting to facebook, please try again later or contact support</p>
                                      ),
                                    },
                                    {
                                      val: 'posted',
                                      elem: (
                                        <p>
                                          {lang.formatString(lang.publishSuccess.message, {
                                            adsManager: (
                                              <a href="https://www.facebook.com/ads/manager/media/library/" target="_blank" rel="noreferrer">
                                                {lang.publishSuccess.adsManager}
                                              </a>
                                            ),
                                            businessManager: (
                                              <a href="https://business.facebook.com/asset-library/video" target="_blank" rel="noreferrer">
                                                {lang.publishSuccess.businessManager}
                                              </a>
                                            ),
                                          })}
                                        </p>
                                      ),
                                    },
                                  ])}
                                </Row>
                                )}
              </>
            )}

        </>
        {this.state.processingStatus == 'done' && (
        <Row>
          OR
        </Row>
        )}
        <Row style={{ flexGrow: 8 }}>
          <Button
            variant="outlined"
            disabled={this.state.processingStatus != 'done' || !this.state.videoURL}
            style={{
              color: 'white', backgroundColor: this.state.processingStatus != 'done' || !this.state.videoURL ? '#888' : '#1f73e5', height: 52,
            }}
            onClick={(event) => {
              const element = document.createElement('a');
              document.body.appendChild(element);
              element.setAttribute('href', `${this.state.videoURL}`);
              element.setAttribute('download', `${this.props.gif._id}.mp4`);
              element.setAttribute('target', '_blank');
              element.style.display = '';
              element.click();
              document.body.removeChild(element);
              event.stopPropagation();
            }}
          >
            Download video
          </Button>
        </Row>
      </div>
    );
  }
}

const lang = new LocalizedStrings({
  en: {
    publishSuccess: {
      message: 'Video successfully posted to your {adsManager} and - if you have an account, to your {businessManager}.',
      adsManager: 'Facebook Ads Manager Library',
      businessManager: 'Business Manager Library',
    },
  },
});

const selectorClass = style({ color: 'white', marginRight: 20, $nest: { ' .MuiSelect-icon': { color: 'white' } } });

const containerClass = style({
  display: 'flex',
  flexDirection: 'column',
  flexGrow: 1,
  alignItems: 'center',
  justifyContent: 'center',
  margin: '0 20px',
});

export const VideoFacebookPublishing = connect(
  mapStateToProps,
)(BaseVideoFacebookPublishing);
