export const recoilKeys = {
  alertState: 'AlertState',
  filters: 'Filters',
  facetState: 'FacetState',
  filterCreatorInput: 'FilterCreatorInput',
  filterCreatorCountriesInput: 'FilterCreatorCountriesInput',
  filterLanguageInput: 'FilterLanguageInput',
  filterProductExactInput: 'FilterProductExactInput',
  filterProductSimilarInput: 'FilterProductSimilarInput',
  filterOwnContentInput: 'FilterOwnContentInput',
  filterCreatorXclusion: 'FilterCreatorXclusion',
  filterLanguageXclusion: 'FilterLanguageXclusion',
  filterProductExactXclusion: 'filterProductExactXclusion',
  filterProductSimilarXclusion: 'filterProductSimilarXclusion',
  filterSourceInput: 'FilterSourceInput',
  filterCollectionInput: 'FilterCollectionInput',
  filterCollectonXclusion: 'FilterCollectonXclusion',
  filterOwnContentXclusion: 'FilterOwnContentXclusion',
  filterCreatorCountriesXclusion: 'FilterCreatorCountriesXclusion',
  allProducts: 'AllProducts',
  allCollections: 'AllCollections',
  initialLibrary: 'InitialLibrary',
};
