import { setRightsTemplateFr } from '@src/actions/rightsrequest';
import { RightsRequestsState } from '@src/types/redux/rightsRequests';
import { REDUX_ACTIONS } from '../actions/_types';

const initialState: RightsRequestsState = {
  rightsTemplateFr: false,
};

const reducers = {
  [REDUX_ACTIONS.RIGHTS_REQUESTS.SET_RIGHTSTEMPLATE_FR](
    state: RightsRequestsState,
    action: ReturnType<typeof setRightsTemplateFr>,
  ) {
    return {
      ...state,
      rightsTemplateFr: action.fr,
    };
  },
};

export default function (state = initialState, action: any) {
  if (reducers[action.type]) {
    window.analytics?.trackEvent(action.type, 'ReduxRightsRequests');
    return reducers[action.type](state, action);
  }
  return state;
}
