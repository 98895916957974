import React, { Component, createRef } from 'react';
import PerfectScrollbarJS from 'perfect-scrollbar';
import { style, classes } from 'typestyle';

interface IProps extends React.HTMLAttributes<HTMLDivElement> {
  options?: PerfectScrollbarJS.Options
  className?: string
  id?: string
  style?: any
  containerRef?: React.RefObject<HTMLDivElement>
}

interface IState {

}

export class PerfectScrollbar extends Component<IProps, IState> {
  private bar?: PerfectScrollbarJS;

  private scrollbarRef = createRef<HTMLDivElement>();

  constructor(props: IProps) {
    super(props);
  }

  public componentDidMount() {
    const component = this.props.containerRef?.current || this.scrollbarRef.current;
    if (!component) {
      console.warn('perfectscrollbar not properly created');
      return;
    }
    this.bar = new PerfectScrollbarJS(component, this.props.options);
  }

  public componentDidUpdate() {
    this.bar?.update();
  }

  public componentWillUnmount() {
    this.bar?.destroy();
    delete this.bar;
  }

  public scrollTop(value: number) {
    const scrollBarRef = this.getScrollBarRef()?.current;
    if (scrollBarRef) {
      scrollBarRef.scrollTop = value;
    }
    this.bar?.update();
  }

  public render() {
    const {
      children,
      containerRef,
      className,
      ...props
    } = this.props;
    return (
      <div ref={containerRef || this.scrollbarRef} className={classes(scrollbarClass, className)} {...props}>
        {children}
      </div>
    );
  }

  private getScrollBarRef() {
    return this.props.containerRef || this.scrollbarRef;
  }
}

const scrollbarClass = style({
  position: 'relative',
});
