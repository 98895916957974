import React, { PropsWithChildren } from 'react';
import { style } from 'typestyle';
import { CheckBox } from '../../components/CheckBox';
import { Row } from '../../components/Utils';

interface Props {
  checked: boolean
  onChange: (checked: boolean) => void
  negative?: boolean
}

export function CheckBoxLine(props: PropsWithChildren<Props>) {
  const {
    negative = false,
  } = props;
  return (
    <Row className={lineClass}>
      <CheckBox
        checked={props.checked}
        onChange={props.onChange}
        variant={negative ? 'redcross' : 'classic'}
      />
      <div
        className={textClass}
        data-checked={props.checked}
        onClick={() => props.onChange(!props.checked)}
      >
        {props.children}
      </div>
    </Row>
  );
}

const lineClass = style({
  marginBottom: 6,
  cursor: 'pointer',
});

const textClass = style({
  verticalAlign: 'middle',
  marginLeft: 7,
  width: '100%',
  $nest: {
    '&[data-checked="true"]': {
      color: 'white',
    },
  },
});
