import React, { useCallback, useEffect } from 'react';
import AuthorizedPage from '@src/components/AuthorizedPage';
import { useSelector } from 'react-redux';
import { ICombinedReducers } from '@src/types/redux';
import { MessagesScroller } from './MessagesScroller';
import { Services } from '@src/types/Services';
import { Message } from './Message';
import { ArrayHelper } from '@src/services/helpers/array';
import { MessageWriter } from './MessageWriter';
import { useQueryConversationMessage } from '@src/queries/messaging/useQueryConversationMessages';
import useLocalStorage from 'use-local-storage';
import { useMutationMessageCreate } from '@src/queries/messaging/useMutationMessageCreate';
import { QUERY_KEYS, queryClient } from '@src/query';
import { useQueryRemoteConversations } from '@src/queries/messaging/useQueryRemoteConversations';
import { DmConversation } from '@adalong/schemas';

export function MessagesPage(props: {
  conversation?: {
    conversationId: string;
    target: string;
    interlocutor: string;
  };
  services: Services;
}) {
  const user = useSelector((state: ICombinedReducers) => state.common.user);
  const { data: conversationListRes } = useQueryRemoteConversations();
  const conversationList = conversationListRes?.pages.flatMap((p) => p);
  const flattenedConvs = conversationList?.flatMap((c) => c.conversations) || [];

  // Save the draft in the local storage
  const [newMessage, setNewMessage] = useLocalStorage(`CRM_newMessage`, '');

  let currentConversation: DmConversation | undefined;
  if (props.conversation && conversationList) {
    const { conversation } = props;
    currentConversation = flattenedConvs.find(
      (c) => conversationUId(c.conversationTarget.id, c.conversationInterlocutor.id) === conversationUId(conversation.target, conversation.interlocutor)
    );
  }

  const { data: conversationMessagesRes, isLoading: isLoadingMessages } = useQueryConversationMessage(
    {
      interlocutorId: currentConversation?.conversationInterlocutor.id || '',
      targetAccountId: currentConversation?.conversationTarget.id || '',
      network: currentConversation?.networkName || '',
      conversationId: currentConversation?.id || '',
    },
    { enabled: !!currentConversation }
  );
  const conversationMessages = conversationMessagesRes?.pages.flatMap((m) => m.Dms);

  const { mutateAsync: sendMessage, isLoading: isSendingMessage } = useMutationMessageCreate();

  const onSendMessage = useCallback(
    (text: string) => {
      if (!currentConversation) {
        return;
      }
      sendMessage({
        body: text,
        interlocutorId: currentConversation.conversationInterlocutor.id,
        senderAccountId: currentConversation.conversationTarget.id,
        networkName: currentConversation.networkName,
      })
        .then(() => {
          // clear the text bar
          setNewMessage('');
        })
        .catch(() => {
          // TODO show error to user
        });
    },
    [currentConversation]
  );
  const onChangeMessage = useCallback((text: string) => {
    setNewMessage(text);
  }, []);

  // Auto-refresh the conversation every 30 seconds
  useEffect(() => {
    if (!currentConversation) {
      return;
    }

    const interval = setInterval(() => {
      queryClient.invalidateQueries(
        QUERY_KEYS.conversationMessages(
          conversationUId(
            currentConversation!.conversationTarget.id,
            currentConversation!.conversationInterlocutor.id
          )
        )
      );
    }, 30000);

    return () => clearInterval(interval);
  }, [currentConversation]);

  // Create the list of message with appropriate informations
  let conversationMessageItems: JSX.Element[] = [];
  if (currentConversation && conversationMessages) {
    conversationMessageItems = ArrayHelper.filterNulls(
      conversationMessages.map((message) => {
        if (!currentConversation) {
          return null;
        }
        // set the message sender name
        let senderInfo: { name: string };
        const selfMessage = currentConversation.conversationTarget.id === message.networkSenderId;
        senderInfo = selfMessage ? { name: currentConversation.conversationTarget.name } : { name: currentConversation.conversationInterlocutor.name };

        return <Message key={message.networkMessageId} message={message} senderInfo={senderInfo} self={selfMessage} />;
      })
    );
  }

  return (
    <AuthorizedPage tab='crm' user={user}>
      {currentConversation ? (
        <>
          <MessagesScroller items={conversationMessageItems} isLoading={isLoadingMessages} />
          <MessageWriter text={newMessage} isLoading={isSendingMessage} onSend={onSendMessage} onChange={onChangeMessage} />
        </>
      ) : null }
    </AuthorizedPage>
  );
}

export function conversationUId(targetId: string, interlocutorId: string) {
  return `${targetId}-${interlocutorId}`;
}
