import React, { useState } from 'react';
import { ProductGroupView, ProductView } from 'common';
import { stylesheet } from 'typestyle';
import { ADALONG_COLORS } from '@src/styles';
import LocalizedStrings from 'react-localization';
import { LinkExactButton } from './LinkButton/LinkExactButton';
import { ProductGroup } from './ProductGroup/ProductGroup';
import { LinkSimilarButton } from './LinkButton/LinkSimilarButton';
import { LinkButton } from './LinkButton/LinkButton';

interface Props {
  productGroup: ProductGroupView;
  productIdsExact: string[];
  productIdsSimilar: string[];
  disableLinking: boolean;
  linkProducts: (products: ProductView[], linkType: string) => void;
  unlinkProducts: (productIdsToUnlink: string[], linkType: string) => void;
}

/**
 * This component displays the product in the linking tab with extra linking features.
 * (=> "ProductGroup" with linking features)
 */
export function ProductGroupLinkable({ productGroup, productIdsExact, productIdsSimilar, disableLinking, linkProducts, unlinkProducts }: Props) {
  const [expanded, setExpanded] = useState(false);

  const allProductsLinkedExact =
    productGroup.products.find((p) => {
      // Check if productIdsExact is defined and does not include p.id
      return !productIdsExact || !productIdsExact.includes(p.id);
    }) === undefined;

  const allProductsLinkedSimilar =
    productGroup.products.find((p) => {
      // Check if productIdsSimilar is defined and does not include p.id
      return !productIdsSimilar || !productIdsSimilar.includes(p.id);
    }) === undefined;

  return (
    <ProductGroup
      productGroup={productGroup}
      productCountFormatter={productCountFormatter}
      expanded={expanded}
      onExpandClick={handleExpandClick}
      customRenderer={{
        buttons: linkButton,
      }}
    />
  );

  function handleExpandClick() {
    setExpanded(!expanded);
  }

  function linkButton(product?: ProductView) {
    if (product) {
      const linkedExact = productIdsExact.includes(product.id);
      const linkedSimilar = productIdsSimilar.includes(product.id);

      let onClickExact = () => linkProducts([product], 'exact');
      let onClickSimilar = () => linkProducts([product], 'similar');

      if (linkedExact) {
        onClickExact = () => {
          return unlinkProducts([product.id], 'exact');
        };
      }
      if (linkedSimilar) {
        onClickSimilar = () => {
          return unlinkProducts([product.id], 'similar');
        };
      }
      return (
        <div>
          <LinkButton
            svg={LinkSimilarButton}
            linked={linkedSimilar}
            onClick={onClickSimilar}
            disabled={!linkedSimilar && disableLinking && lang.disabledLinking}
            tooltip={linkedSimilar ? lang.linkButton.similar.unlink : lang.linkButton.similar.link}
          />
          <LinkButton
            svg={LinkExactButton}
            linked={linkedExact}
            onClick={onClickExact}
            disabled={!linkedExact && disableLinking && lang.disabledLinking}
            tooltip={linkedExact ? lang.linkButton.exact.unlink : lang.linkButton.exact.link}
          />
        </div>
      );
    }

    // Product groups with one or more products
    return (
      <div>
        <LinkButton
          svg={LinkSimilarButton}
          linked={allProductsLinkedSimilar}
          onClick={() => linkAll('similar')}
          disabled={!allProductsLinkedSimilar && disableLinking && lang.disabledLinking}
          tooltip={allProductsLinkedSimilar ? lang.linkButton.similar.unlink : lang.linkButton.similar.link}
        />
        <LinkButton
          svg={LinkExactButton}
          linked={allProductsLinkedExact}
          onClick={() => linkAll('exact')}
          disabled={!allProductsLinkedExact && disableLinking && lang.disabledLinking}
          tooltip={allProductsLinkedExact ? lang.linkButton.exact.unlink : lang.linkButton.exact.link}
        />
      </div>
    );
  }

  function linkAll(type: string) {
    if (type === 'exact') {
      if (!allProductsLinkedExact) {
        linkProducts(productGroup.products, type);
      } else {
        // all product already linked, we should unlink
        {
          unlinkProducts(
            productGroup.products.map((p) => p.id),
            type
          );
        }
      }
    } else {
      if (!allProductsLinkedSimilar) {
        linkProducts(productGroup.products, type);
      } else {
        // all product already linked, we should unlink
        {
          unlinkProducts(
            productGroup.products.map((p) => p.id),
            type
          );
        }
      }
    }
  }

  // only exact products are counted
  function productCountFormatter(count: number) {
    const numberOfProductLinked = productGroup.products.filter((p) => p.id).length;
    return (
      <>
        <span className={sheet.productGroupLinkedNumber}>{numberOfProductLinked}</span>
        {` | ${count}`}
      </>
    );
  }
}

const lang = new LocalizedStrings({
  en: {
    priceFrom: 'From',
    disabledLinking: 'To link a product, select a box first',
    linkButton: {
      similar: {
        link: 'Link to similar product',
        unlink: 'Unlink to similar product',
      },
      exact: {
        link: 'Link to exact product',
        unlink: 'Unlink to exact product',
      },
    },
  },
});

const sheet = stylesheet({
  productGroupLinkedNumber: {
    color: ADALONG_COLORS.LIGHT_YELLOW,
  },
});
