import React from 'react';
import { IWidget } from 'common';
import { style } from 'typestyle';

import { WithoutPackshotIcon } from '../../Icons/PackshotIcons/WithoutPackshot';
import { WithPackshotIcon } from '../../Icons/PackshotIcons/WithPackshot';

type ThumbnailPackshot = IWidget['settings']['thumbnail_packshot'];

interface Props {
  thumbnail_packshot: ThumbnailPackshot;
  onChange: (thumbnail_packshot: ThumbnailPackshot) => any;
}

export function ThumbnailPackshotSelector({ thumbnail_packshot, ...props }: Props) {
  const onChange = (thumbnail_packshot: ThumbnailPackshot) => {
    props.onChange(thumbnail_packshot);
  };

  return (
    <div className={selectClass}>
      <div className={itemClass}>
        <WithPackshotIcon onClick={() => onChange(true)} selected={thumbnail_packshot === true} />{' '}
        VISIBLE
      </div>
      <div className={itemClass}>
        <WithoutPackshotIcon
          onClick={() => onChange(false)}
          selected={thumbnail_packshot === false}
        />
        NONE
      </div>
    </div>
  );
  10;
}
const selectClass = style({
  marginTop: '10px',
  marginBottom: '10px',
  display: 'flex',
  flexDirection: 'row',
});

const itemClass = style({
  fontSize: '0.7em',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginRight: '10px',
});
