import { ReversedMediaSource, MediaSource } from '@src/types/Filters';
import { CommonState } from '@src/types/redux/common';
import { SourceNetwork } from '@src/types/SourceNetwork';
import { MentionPageView } from 'common';

const SEPARATOR = '_';
const SEP_REGEXP = new RegExp(`^(\\w+?)${SEPARATOR}(\\w+?)${SEPARATOR}(.+)`);

const TYPES = { hashtag: 1, mention: 1, tagged: 1, ownContent: 1, extension: 1 }; // Define available type of sources
export type SourceType = (keyof typeof TYPES);
export interface DetailedSource {
  type: (keyof typeof TYPES)
  network: SourceNetwork
  value: string
  other?: string // Used to keep mention page id
}
export type SourceUrlKey = string;

export class SourceUtility {
  /**
     * Convert DetailedSource to a key as string for the url parameter
     */
  public static sourceToUrlKey(source: DetailedSource): SourceUrlKey {
    return [
      MediaSource[source.network],
      source.type,
      source.value,
    ].join(SEPARATOR);
  }

  /**
     * Convert key from url to DetailedSource
     */
  public static urlKeyToSource(key: SourceUrlKey): DetailedSource | null {
    const k = SEP_REGEXP.exec(key);
    if (!k) {
      return null;
    }
    const abridgedNetwork = k[1] as 'ig' | 'tiktok';
    const type = k[2] as 'hashtag' | 'mention' | 'tagged' | 'ownContent' | 'extension';
    const value: string = k[3];

    const network = ReversedMediaSource[abridgedNetwork] as SourceNetwork;
    if (!TYPES[type] || !network) {
      return null;
    }
    return {
      network,
      type,
      value,
    };
  }

  /**
     * Return data from the store formatted as DetailedSources
     */
  public static getAllDetailedSources({
    hashtags = [],
    mentionPages = {},
  }: {
    hashtags?: string[]
    mentionPages?: { [key: string]: MentionPageView }
  }): DetailedSource[] {
    const sources: DetailedSource[] = [];
    sources.push({
      network: 'tiktok',
      type: 'extension',
      value: 'tiktok',
      other: 'tiktok',
    });
    Object.values(mentionPages).forEach((mention) => {
      if (!MediaSource[mention.source]) {
        return;
      }
      sources.push({
        network: mention.source,
        type: 'mention',
        value: mention.name,
        other: mention.id,
      });
      if (mention.source === 'instagram') {
        sources.push({
          network: mention.source as SourceNetwork,
          type: 'tagged',
          value: mention.name,
          other: mention.id,
        });

        sources.push({
          network: mention.source as SourceNetwork,
          type: 'ownContent',
          value: mention.name,
          other: mention.id,
        })
      }
    });
    hashtags.forEach((hashtag) => {
        const h: Pick<DetailedSource, 'type' | 'value'> = { type: 'hashtag', value: hashtag };
        sources.push({ network: 'instagram', ...h });
    });

    return sources;
  }

  /**
     * Return data from the store formatted as DetailedSources
     */
  public static getAllDetailedSourcesFromState(common: Pick<CommonState, 'user' | 'groupMentionPages'>): DetailedSource[] {
    return SourceUtility.getAllDetailedSources({
      hashtags: common.user?.group.tags,
      mentionPages: common.groupMentionPages,
    });
  }
}
