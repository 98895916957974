import { Page } from '@src/components/Page';
import { Pathname } from '@src/types/Browsing';
import { useQueryStatistics } from '@src/queries/statistics/useQueryStatistics';
import React, { useState } from 'react';
import { Column, Row } from '@src/components/Utils';
import { classes, style, stylesheet } from 'typestyle';
import { Hide } from '@src/components/Hide';
import { Loader } from '@src/components/Loader';
import { PerfectScrollbar } from '@src/components/PerfectScrollbar';
import { BarChart, PieChart, StackOrderType } from '@mui/x-charts';
import { Analytics } from 'common/dist/types/apiTypes';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import { MenuItem, Select } from '@mui/material';
import { PiecewiseColorLegend } from '@mui/x-charts';
import NumberFormat from 'react-number-format';

const sheet = stylesheet({
  title: {
    paddingLeft: '18px',
    marginBottom: '0',
  },
  subtitle: {
    margin: '0',
  },
  p: {
    margin: 0,
  },
  secondRowBarCharts: {
    marginTop: '20px',
  },
  sectionsContainer: {
    height: 'auto',
  },
  firstRowBarCharts: {
    flexGrow: 'unset !important',
  },
  section: {
    paddingLeft: '20px', // compensation for marginRight of the bar charts
  },
  categoriesSection: {
    flex: 1,
  },
  firstSection: {
    // make the first section bigger
    flex: 2,
    borderRight: '1px solid #333',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
  },
  secondSection: {
    flex: 1,
    alignItems: 'start',
    paddingTop: '55px', // compensation for All sources select
  },
  pieChartContainer: {
    height: '300px !important',
    margin: '0 auto',
    width: '100%',
  },
  barChartContainer: {
    height: '300px',
    width: '100%',
  },
  description: {
    lineHeight: '2',
  },
  lastMonthWrap: {
    width: '100%',
    marginBottom: '20px',
    height: '300px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
  },
  metric: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: '10px',
    width: '100%',
  },
  metricCount: {
    fontSize: '40px',
    fontWeight: 'bold',
    margin: '0',
  },
  metricDescription: {
    fontSize: '18px',
    margin: '0',
  },
});

const barChartMargins = {
  top: 20,
  right: 20,
  left: 60,
  bottom: 25,
};
const barChartSx = {
  '.MuiChartsAxis-tickLabel': {
    strokeWidth: '1',
    fontSize: '10pt !important',
    fill: 'white',
  },
  padding: '10px 0',
};

const scrollBar = style({
  overflow: 'hidden',
  height: '100%',
  position: 'relative',
  padding: '0 20px',
});
const darkTheme = createTheme({
  palette: {
    mode: 'dark',
  },
});
type IngestionDataset = {
  month: string;
  [key: `imagesCounts_${string}`]: number;
  [key: `videosCounts_${string}`]: number;
};

type RightsRequestsDataset = {
  month: string;
  [key: `agreed_${string}`]: number;
  [key: `requested_${string}`]: number;
};

type Series = {
  stackOrder: StackOrderType;
  label: string;
  dataKey: string;
  stack?: string;
  color?: string;
};

type StatsToShow = {
  month: string;
  year: string;
  imagesCounts: { value: number };
  videoCounts: { value: number };
  rightsRequests: { agreed: number, requested: number };
};
export const AnalyzePage = () => {
  const { data: analytics, isLoading: isStatisticsLoading } = useQueryStatistics();
  const [source, setSource] = useState('total');
  const sources = analytics ? getAllSources(analytics) : [];

  const statsToShow = analytics
    ? cutLastYears(getMonthlyStatsBySource(analytics, source), 3)
    : null;
  const formattedData = statsToShow
    ? formatStatisticsByMonthData(statsToShow)
    : { series: [], dataset: [] };
  const rightsRequestsData = statsToShow
    ? formatRightsRequestsData(statsToShow)
    : { series: [], dataset: [] };
  const cumulatedStatistics = statsToShow ? makeCumulatedStatistics(statsToShow) : [];
  const langsStatistics = analytics ? formatLangData(analytics) : [];
  const lastMonthUgcCount = analytics ? getLastMonthUgcCount(analytics) : 0;
  const lastMonthAcceptanceRate = analytics ? getLastMonthAcceptanceRate(analytics) : 0;
  const formattedCumulatedData = statsToShow
    ? formatStatisticsByMonthData(cumulatedStatistics)
    : { series: [], dataset: [] };
  const formattedCumulatedRightsRequestsData = statsToShow
    ? formatRightsRequestsData(cumulatedStatistics)
    : { series: [], dataset: [] };

  return (
    <Page path={Pathname.analyze}>
      <ThemeProvider theme={darkTheme}>
        <PerfectScrollbar className={scrollBar}>
          <Row className={sheet.sectionsContainer}>
            <Column className={classes(sheet.section, sheet.categoriesSection, sheet.firstSection)}>
              <Hide hide={!isStatisticsLoading}>
                <Loader />
              </Hide>
              <Hide hide={isStatisticsLoading || !analytics}>
                <p className={classes(sheet.p, sheet.description)}>
                  <Select
                    style={{ width: '300px', height: '30px' }}
                    value={source}
                    onChange={(e) => setSource(e.target.value)}
                  >
                    {sources.map((source) => (
                      <MenuItem key={source} value={source}>
                        {source === 'total' ? 'All sources' : source}
                      </MenuItem>
                    ))}
                  </Select>
                </p>
                <br />
                <Row className={sheet.firstRowBarCharts}>
                  <Column>
                    <h3 className={sheet.subtitle}>Photos and videos collected</h3>
                    <div className={sheet.barChartContainer}>
                      <BarChart
                        dataset={formattedData.dataset}
                        slotProps={{ legend: { hidden: true } }}
                        xAxis={[
                          {
                            scaleType: 'band',
                            dataKey: 'month',
                          },
                        ]}
                        series={formattedData.series}
                        sx={barChartSx}
                        margin={barChartMargins}
                      />
                    </div>
                  </Column>

                  <Column>
                    <h3 className={sheet.subtitle}>Cumulated photos and videos collected</h3>
                    <div className={sheet.barChartContainer}>
                      <BarChart
                        dataset={formattedCumulatedData.dataset}
                        xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                        slotProps={{ legend: { hidden: true } }}
                        series={formattedCumulatedData.series}
                        sx={barChartSx}
                        margin={barChartMargins}
                      />
                    </div>
                  </Column>
                </Row>

                <Row className={sheet.secondRowBarCharts}>
                  <Column>
                    <h3 className={sheet.subtitle}>Agreed and requested rights requests</h3>
                    <div className={sheet.barChartContainer}>
                      <BarChart
                        dataset={rightsRequestsData.dataset}
                        xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                        slotProps={{ legend: { hidden: true } }}
                        series={rightsRequestsData.series}
                        margin={barChartMargins}
                        sx={barChartSx}
                      />
                    </div>
                  </Column>
                  <Column>
                    <div className={sheet.barChartContainer}>
                    <h3 className={sheet.subtitle}>Cumulated rights requests</h3>
                      <BarChart
                        dataset={formattedCumulatedRightsRequestsData.dataset}
                        xAxis={[{ scaleType: 'band', dataKey: 'month' }]}
                        slotProps={{ legend: { hidden: true } }}
                        series={formattedCumulatedRightsRequestsData.series}
                        sx={barChartSx}
                        margin={barChartMargins}
                      />
                    </div>
                  </Column>
                </Row>
              </Hide>
            </Column>
            <Column
              className={classes(sheet.section, sheet.categoriesSection, sheet.secondSection)}
            >
              <Hide hide={!isStatisticsLoading}>
                <Loader />
              </Hide>
              <Hide hide={isStatisticsLoading || !analytics}>
              <h3 className={sheet.subtitle}>Your last month in a wrap</h3>
                <div className={sheet.lastMonthWrap}>
                  
                  <div className={sheet.metric}>
                    <NumberFormat
                      value={lastMonthUgcCount}
                      displayType="text"
                      thousandSeparator
                      className={sheet.metricCount}
                    />
                    <p className={sheet.metricDescription}>new UGCs collected</p>
                  </div>
                  <div className={sheet.metric}>
                  <NumberFormat
                      value={lastMonthAcceptanceRate}
                      displayType="text"
                      suffix="%"
                      decimalScale={2}
                      thousandSeparator
                      className={sheet.metricCount}
                    />
                    <p className={sheet.metricDescription}>acceptance rate</p>
                  </div>
                </div>
                
                <div className={sheet.pieChartContainer}>
                  <h3 className={sheet.subtitle}>Content language distribution</h3>
                  <PieChart
                    series={[{ data: langsStatistics }]}
                    sx={{
                      '.MuiPieArc-root': {
                        strokeWidth: '0',
                      },
                      '.MuiChartsAxis-tickLabel': {
                        strokeWidth: '1',
                        fontSize: '10pt !important',
                        fill: 'white',
                      },
                      padding: '10px 0',
                    }}
                    margin={{top: 20, right: 130}}
                    // width={400}
                  >
                    <PiecewiseColorLegend
                      axisDirection='x'
                      position={{ vertical: 'top', horizontal: 'middle' }}
                      direction='row'
                      hideFirst
                      padding={0}
                      labelStyle={{ fontSize: 10 }}
                    />
                  </PieChart>
                </div>
              </Hide>
            </Column>
          </Row>
        </PerfectScrollbar>
      </ThemeProvider>
    </Page>
  );
};

function cutLastYears(data: StatsToShow[], yearsToKeep: number) {
  const currentYear = new Date().getFullYear();
  return data.filter((oneMonthData) => {
    return currentYear - Number(oneMonthData.year) <= yearsToKeep;
  });
}

function getLastMonthUgcCount(analytics: Analytics[]): number {
  const lastMonthStats = analytics[analytics.length - 1];
  let count = 0;
  if (lastMonthStats.ingestion.imagesCounts && lastMonthStats.ingestion.imagesCounts.total) {
    count += lastMonthStats.ingestion.imagesCounts.total;
  }
  if (lastMonthStats.ingestion.videosCounts && lastMonthStats.ingestion.videosCounts.total) {
    count += lastMonthStats.ingestion.videosCounts.total;
  }
  return count;
}

function getLastMonthAcceptanceRate(analytics: Analytics[]): number {
  const lastMonthStats = analytics[analytics.length - 1];
  const agreed = lastMonthStats.rightsRequests.agreed || 0;
  const requested = lastMonthStats.rightsRequests.requested || 0;
  if (requested === 0) {
    return 0;
  }
  return (agreed / requested) * 100;
}

function formatLangData(data: Analytics[], treshold = 0.01) {
  const languagesData = data.map((oneMonthData) => oneMonthData.language);
  const colors = generateColorPalette().flat();
  // calculate the total number of languages
  const totalLanguages = languagesData.reduce((acc, curr) => {
    for (let lang in curr) {
      acc += curr[lang];
    }
    return acc;
  }, 0);

  const aggregatedData = languagesData.reduce((acc, curr) => {
    for (let lang in curr) {
      if (!acc[lang]) {
        acc[lang] = 0;
      }
      acc[lang] += curr[lang];
    }
    return acc;
  }, {});
  // filter out languages that are less than the treshold
  for (let lang in aggregatedData) {
    if (aggregatedData[lang] / totalLanguages < treshold) {
      delete aggregatedData[lang];
    }
  }
  return Object.entries(aggregatedData).sort((a, b) => a[1] < b[1] ? 1: -1).map(([lang, value]) => {
    return {
      id: lang,
      label: lang == 'empty' ? 'no caption' : lang,
      value: value,
      // color: colors.pop()
    };
  });
}

function makeCumulatedStatistics(analytics: StatsToShow[]) {
  const res = [];
  // group by year
  const dataByYear = analytics.reduce((acc: Record<string, StatsToShow[]>, curr) => {
    if (!acc[curr.year]) {
      acc[curr.year] = [];
    }
    acc[curr.year].push(curr);
    return acc;
  }, {});

  // for each year, sort by month
  for (let year in dataByYear) {
    dataByYear[year].sort((a, b) => {
      return Number(a.month) > Number(b.month) ? 1 : -1;
    });
  }

  // cumulate data for each year separately
  for (let year in dataByYear) {
    const cumulatedData = dataByYear[year].reduce((acc: StatsToShow[], curr, index) => {
      if (index === 0) {
        acc.push(curr);
      } else {
        const prev = acc[index - 1];
        acc.push({
          month: curr.month,
          year: curr.year,
          imagesCounts: {
            value: curr.imagesCounts.value + prev.imagesCounts.value,
          },
          videoCounts: {
            value: curr.videoCounts.value + prev.videoCounts.value,
          },
          rightsRequests: {
            agreed: curr.rightsRequests.agreed + prev.rightsRequests.agreed,
            requested: curr.rightsRequests.requested + prev.rightsRequests.requested,
          },
        });
      }
      return acc;
    }, []);
    res.push(...cumulatedData);
  }
  return res;
}

function formatStatisticsByMonthData(analytics: StatsToShow[]): {
  dataset: IngestionDataset[];
  series: Series[];
} {
  const years = new Set<string>();
  const data: Record<string, IngestionDataset> = {};
  const baseColors = ['rgb(218, 0, 255)', '#02B2AF'];
  for (let oneMonthData of analytics) {
    years.add(oneMonthData.year); // Track all years present in the data
    if (!data[oneMonthData.month]) {
      data[oneMonthData.month] = {
        month: monthKeyToName(oneMonthData.month, oneMonthData.year),
      };
    }
    data[oneMonthData.month][`imagesCounts_${oneMonthData.year}`] = oneMonthData.imagesCounts.value;
    data[oneMonthData.month][`videosCounts_${oneMonthData.year}`] = oneMonthData.videoCounts.value;
  }
  const formattedData = Object.values(data);
  const series: Series[] = [];
  const colorPalette = generateColorPalette();
  years.forEach((year) => {
    const [color1, color2] = colorPalette.shift() || 'rgb(218, 0, 255)';
    series.push(
      {
        stackOrder: 'none' as StackOrderType,
        label: `Videos (${year})`,
        dataKey: `videosCounts_${year}`,
        stack: `videosCounts_${year}`,
        color: color1,
      },
      {
        stackOrder: 'none' as StackOrderType,
        label: `Images (${year})`,
        dataKey: `imagesCounts_${year}`,
        stack: `videosCounts_${year}`,
        color: color2,
      },
    );
  });
  return {
    dataset: formattedData,
    series,
  };
}

function formatRightsRequestsData(analytics: StatsToShow[]): {
  dataset: RightsRequestsDataset[];
  series: Series[];
} {
  const data: Record<string, RightsRequestsDataset> = {};
  const years = new Set<string>();
  for (let oneMonthData of analytics) {
    years.add(oneMonthData.year); // Track all years present in the data
    if (!data[oneMonthData.month]) {
      data[oneMonthData.month] = {
        month: monthKeyToName(oneMonthData.month, oneMonthData.year),
      };
    }
    data[oneMonthData.month][`agreed_${oneMonthData.year}`] = oneMonthData.rightsRequests.agreed;
    data[oneMonthData.month][`requested_${oneMonthData.year}`] = oneMonthData.rightsRequests.requested;
  }
  const series: Series[] = [];
  const formattedData = Object.values(data);
  const colorPalette = generateColorPalette();
  years.forEach((year) => {
    series.push({
      stackOrder: 'none' as StackOrderType,
      label: `Agreed (${year})`,
      dataKey: `agreed_${year}`,
      stack: `rr_${year}`,
    });
    series.push({
      stackOrder: 'none' as StackOrderType,
      label: `Requested (${year})`,
      dataKey: `requested_${year}`,
      stack: `rr_${year}`,
    });
  });
  return {
    dataset: formattedData,
    series,
  };
}

function monthKeyToName(month: string, year: string) {
  // fix timezone issue by substracting timezone offset
  const newDate = new Date(Number(year), Number(month) - 1, 0, new Date().getTimezoneOffset() * -1);
  return newDate.toLocaleString('default', { month: 'short' });
}

function getAllSources(analytics: Analytics[]): string[] {
  const sources = new Set<string>();
  for (let oneMonthData in analytics) {
    const data = analytics[oneMonthData];
    if (data.ingestion.imagesCounts) {
      for (let source in data.ingestion.imagesCounts) {
        sources.add(source);
      }
    }
    if (data.ingestion.videosCounts) {
      for (let source in data.ingestion.videosCounts) {
        sources.add(source);
      }
    }
  }
  return Array.from(sources);
}

function getMonthlyStatsBySource(
  analytics: Analytics[],
  sourceToFilter: string = 'total',
): StatsToShow[] {
  let res = [];
  for (let oneMonthData of analytics) {
    const { month, year, ingestion, rightsRequests } = oneMonthData;

    res.push({
      month,
      year,
      imagesCounts: {
        value: ingestion.imagesCounts[sourceToFilter] || 0,
      },
      videoCounts: {
        value: ingestion.videosCounts[sourceToFilter] || 0,
      },
      rightsRequests: {
        agreed: rightsRequests.agreed || 0,
        requested: rightsRequests.requested || 0,
      },
    });
  }
  return res;
}

function generateColorPalette() {
  return [
    ["#00FF7F", "#FF00FF"],  // Year 1 - Spring Green & Magenta
    ["#00CED1", "#FF4500"],  // Year 2 - Dark Turquoise & Orange-Red
    ["#7B68EE", "#32CD32"],  // Year 3 - Medium Slate Blue & Lime Green
    ["#FFD700", "#8A2BE2"]   // Year 4 - Gold & Blue-Violet
  ];
}
