import { useQuery } from 'react-query';
import { QUERY_KEYS } from '@src/query';
import { getMetaPermissions } from './api';

/**
 * Get user Meta Permissions
 */
export const useQueryMetaPermission = () => {
    return useQuery(
        QUERY_KEYS.metaPermission(), 
        getMetaPermissions, {
            onSuccess: (data) => {
                return data;
            }
        }
    )
};