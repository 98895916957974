import React from 'react';
import { Page } from '@src/components/Page';
import { Browsing, PathLocation, Pathname, QsNames } from '@src/types/Browsing';
import { MARK_THEME } from '@src/config/mark';
import { FilterTitle } from 'pages/Discover/components/FilterTitle';
import { stylesheet } from 'typestyle';
import { NestedCSSProperties } from 'typestyle/lib/types';
import { SearchController } from '@src/fragments/Search/SearchController';
import { DiscoverQs } from '@src/types/Discover';
import { MediaLibrary } from '@src/fragments/MediaLibrary';
import { MediaView } from 'common';
import { IPopupsService } from '@src/types/Popups';
import { CommonState } from '@src/types/redux/common';
import { SearchState } from '@src/types/Search';
import { MediaActionButtonController } from '@src/fragments/MediaActionButton/MediaActionController';
import { GetRightsButton } from '@src/components/IPRights/GetRightsButton';
import { Hide } from '@src/components/Hide';
import { goToGetRights } from '../Media/utils/mediaRoute';
import { LibraryHeader } from './components/LibraryHeader';
import { FiltersView } from '@src/fragments/Filters/FiltersView';

interface Props {
  searchState: SearchState;
  filtersOpened: boolean;
  isExactMode: boolean;
  qsValues: DiscoverQs;
  pathLocation: PathLocation;
  services: {
    browsing: Browsing;
    popups: IPopupsService;
  };
  commonStore: Pick<CommonState, 'user' | 'groupMentionPages'>;
  library: MediaView[];
  noMoreMedia: boolean;
  /**
   * Display a loader when the library is displayed but
   * something is reloading
   */
  displayFetchingLoader: boolean;
  libraryLoaded: boolean;
  loadMoreMedia: () => void;
  mediaSelection: MediaView[];
  totalMedia: number;
  totalExactMedia: number;
  setMediaSelection: (selection: MediaView[]) => void;
  setExactMode: (exact: boolean) => void;
  setFiltersOpened: (unfold: boolean) => void;
  setMediaNotFound: (id: string, isExactMode: boolean) => void;
  handleSelectAll: () => void;
  selectAllActive: boolean;
  setSelectAllActive: (active: boolean) => void;
  isFetchingAll: boolean;
}

export function Discover(props: Props) {
  return (
    <Page path={Pathname.discover} contentClassNames={[sheet.pageContent]}>
      <div className={sheet.contentHead}>
        <div className={sheet.filterTitle}>
          <FilterTitle unfold={props.filtersOpened} onUnfold={props.setFiltersOpened} />
        </div>
        <div className={sheet.search}>
          <SearchController
            services={props.services}
            pathLocation={props.pathLocation}
            qsValues={{
              text: props.qsValues[QsNames.discoverSearchText],
              hashtags: props.qsValues[QsNames.discoverSearchHashtags],
              labels: props.qsValues[QsNames.discoverSearchLabels],
            }}
            state={props.searchState}
            theme={MARK_THEME}
          />
        </div>
      </div>
      <div className={sheet.contentBody}>
        <div className={sheet.filtersContainer} data-hide={String(!props.filtersOpened)}>
          <FiltersView
            enableProductFilter={props.commonStore.user?.groupFeatures.product_catalog || false}
            creatorCountriesFilterValues={props.commonStore.user?.catalogCountries || []}
          />
        </div>
        <div className={sheet.libraryContainer}>
          <div className={sheet.libraryHeader}>
            <LibraryHeader
              isExactMode={props.isExactMode}
              collectAllSelected={(medias) => props.services.popups.openCollectOverlay('add', medias)}
              estimatedCount={props.totalMedia}
              exactCount={props.totalExactMedia}
              mediaSelection={props.mediaSelection}
              setMediaSelection={props.setMediaSelection}
              onSearchModeChange={props.setExactMode}
              libraryFetching={props.displayFetchingLoader}
              services={props.services}
              onSelectAll={props.handleSelectAll}
              selectAllActive={props.selectAllActive}
              setSelectAllActive={props.setSelectAllActive}
              isFetchingAll={props.isFetchingAll}
              catalogCountries={props.commonStore.user?.catalogCountries}
            />
          </div>
          <MediaLibrary
            loadMoreMedia={async () => props.loadMoreMedia()}
            mediaList={props.library}
            selection={props.mediaSelection}
            setSelection={props.setMediaSelection}
            onImageError={(mediaID: string) => props.setMediaNotFound(mediaID, props.isExactMode)}
            noMore={props.noMoreMedia}
            libraryLoaded={props.libraryLoaded}
            hideOnImageFailed
            renderMediaActionButton={(media) => (
              <MediaActionButtonController
                services={props.services}
                inCollection={undefined} // collection removal is not required in discover
                mediaList={[media]}
                catalogCountries={props.commonStore.user?.catalogCountries}
              />
            )}
            renderShortcutActionButton={(media, failed) => (
              <Hide hide={failed || media.rightsRequestStatus === 'agreed' || media.source.name === 'tiktok'}>
                <GetRightsButton onAction={() => props.services.browsing.goToPage(goToGetRights(window.location, media._id))} size='small' />
              </Hide>
            )}
          />
        </div>
      </div>
    </Page>
  );
}

const filtersClasses: NestedCSSProperties = {
  flexShrink: 0,
  width: 320,
};

const sheet = stylesheet({
  pageContent: {
    display: 'flex',
    flexDirection: 'column',
    overflow: 'hidden',
  },
  contentHead: {
    display: 'flex',
    flexDirection: 'row',
    flexShrink: 0,
  },
  contentBody: {
    display: 'flex',
    flexDirection: 'row',
    overflow: 'hidden',
    flex: 1,
  },
  filterTitle: {
    ...filtersClasses,
    fontWeight: 'bold',
    textTransform: 'uppercase',
  },
  search: {
    padding: '6px 15px 12px',
    flex: 1,
    zIndex: 2,
  },
  filtersContainer: {
    ...filtersClasses,
    height: '100%',
    position: 'relative',
    $nest: {
      '&[data-hide="true"]': {
        width: 0,
      },
    },
  },
  libraryContainer: {
    flex: 1,
    padding: '0 3px 0 12px',
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
  },
  libraryHeader: {
    padding: '10px 15px 7px 5px',
  },
});
