import { useMutation } from 'react-query';
import { ApiError } from '@src/services/api';
import { setRightsTemplate } from './api';
import { getQueryDataUserRightsTemplate, setQueryDataUserRightsTemplate } from './useQueryUserRightsTemplate';

type RTemplate = Parameters<typeof setRightsTemplate>[0];

export const useMutationUserRightsTemplate = () => useMutation<unknown, ApiError, {
  rightsTemplate: Parameters<typeof setRightsTemplate>[0]
}>(({ rightsTemplate }) => setRightsTemplate(rightsTemplate), {
  onMutate: async (variables) => {
    // update the cache directly
    const rightsTemplate = getQueryDataUserRightsTemplate();
    setQueryDataUserRightsTemplate({
      terms: rightsTemplate?.terms || '',
      template: variables.rightsTemplate,
    });
  },
});
