import React, { useState } from 'react';
import NumberFormat from 'react-number-format';
import VisibilitySensor from 'react-visibility-sensor';
import LocalizedStrings from 'react-localization';

import { StringHelper } from '@src/services/helpers/string';
import { CollectionView } from 'common';
import dayjs from 'dayjs';
import { useQueryCollectionContentNumber } from '@src/queries/collections/useQueryCollectionContentNumber';
import { useMutationCollectionDelete } from '@src/queries/collections/useMutationCollectionDelete';
import { style, classes } from 'typestyle';
import { useAddAlert } from '@src/fragments/Alerting/alertAtom';
import { getLangError } from '../../fragments/Alerting/AlertSnackbar';
import { Loader } from '../Loader';
import * as CommonActions from '../../actions/common';
import { bigStore } from '../../store';

// TODO: Move in the Collection folder in pages

interface Props {
  collection: CollectionView;
  onClick: Function;
  full?: boolean;
  backgroudImageUrl?: string;
  className?: string;
}

export function CollectionThumb(props: Props) {
  const [visible, setVisible] = useState(false);
  const addAlert = useAddAlert();

  const { data: contentNumber } = useQueryCollectionContentNumber(props.collection._id, {
    enabled: visible,
  });
  const { mutateAsync: deleteCollection, reset: resetDelete } = useMutationCollectionDelete();

  const onDelete = (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    e.stopPropagation();
    bigStore.dispatch(
      CommonActions.promptConfirm({
        question: window.T('create.collections.confirmremove'),
        answer: (yes) => {
          if (!yes) {
            return;
          }
          deleteCollection(
            { collectionId: props.collection._id },
            {
              onError: (error) =>
                addAlert({
                  text: getLangError(error.type, lang.errors),
                  close: resetDelete,
                }),
            }
          ).catch(console.error);
        },
      })
    );
  };

  /* Arrow function for binding */
  const onVisibilityChange = (isVisible: boolean) => {
    if (isVisible) {
      setVisible(true);
    }
  };

  if (!props.collection) {
    return null;
  }

  const { name } = props.collection;

  return (
    <div
      className={classes(collectionThumbClass, props.className)}
      onClick={() => props.onClick()}
      style={
        props.backgroudImageUrl ? { backgroundImage: `url(${props.backgroudImageUrl})` } : undefined
      }
    >
      <div className={overlayClass} />
      <VisibilitySensor onChange={onVisibilityChange} partialVisibility>
        <div className={miscClass} style={{ position: 'relative' }}>
          <div className={nameClass}>{StringHelper.upperFirst(name)}</div>
          <div className={countClass}>
            {typeof contentNumber !== 'undefined' ? (
              <NumberFormat value={contentNumber} displayType='text' thousandSeparator />
            ) : (
              <Loader size={10} />
            )}{' '}
            &nbsp;elements &middot;
            {dayjs(props.collection.created_at).format('YYYY.MM.DD')}
          </div>
        </div>
      </VisibilitySensor>
      {!!props.full && (
        <div className={classes(permissionsClass, miscClass)}>
          {props.collection.mine && window.T('create.collection.selfowner')}
          {!props.collection.mine && window.T('create.collection.permissions.canview')}
          {!props.collection.mine &&
            props.collection.i_can_edit &&
            `${window.T('create.collection.permissions.andedit')}`}
        </div>
      )}
      {!!props.full && props.collection.mine && (
        <div
          className={classes(removeClass, miscClass)}
          title={StringHelper.upperFirst(window.T('create.collections.remove'))}
          onClick={onDelete}
        >
          <i className='material-icons notranslate'>delete</i>
        </div>
      )}
    </div>
  );
}

const lang = new LocalizedStrings({
  en: {
    errors: {
      usedAsWidgetSource:
        'This collection is used as Widget source. Please remove it from all widgets before deleting this collection',
      unknown: 'An error ocurred',
    },
  },
});

const overlayBackground = 'rgba(0, 0, 0, 0.26)';
const collectionThumbClass = style({
  position: 'relative',
  height: 85,
  width: 340,
  backgroundSize: 'cover',
  backgroundPosition: 'center',
  cursor: 'pointer',
  display: 'flex',
  justifyContent: 'center',
  flexDirection: 'column',
});
const overlayClass = style({
  $nest: {
    '&:hover': {
      background: `opacify(${overlayBackground}, 0.3)`,
    },
  },
});
const miscClass = style({
  zIndex: 3,
  textAlign: 'left',
  marginLeft: 20,
  overflow: 'hidden',
});

const nameClass = style({
  textOverflow: 'ellipsis',
  overflow: 'hidden',
  fontSize: 18,
});

const countClass = style({
  fontSize: 12,
  opacity: 0.7,
});

const permissionsClass = style({
  position: 'absolute',
  bottom: 5,
  left: 0,
  fontSize: 12,
  color: '#dbdbdb',
});
const removeClass = style({
  display: 'none',
  position: 'absolute',
  right: 10,
  top: 10,
  color: 'rgba(208, 208, 208, 0.65)',
  transition: '0.2s color',
  $nest: {
    '&:hover': {
      color: 'white',
      display: 'block',
    },
    '.floating-confirm': {
      top: 0,
      right: 0,
    },
  },
});
