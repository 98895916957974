import React from 'react';
import { MediaInfoMark } from '@src/types/Media';
import { style } from 'typestyle';
import { color } from 'csx';
import { ADALONG_COLORS } from '@src/styles';
import { Mark } from '../Mark';

const COLORS = {
  hashtags: color(ADALONG_COLORS.LIGHT_GRAY).fade(0.5).toString(),
  mentions: color(ADALONG_COLORS.FLASH_PINK).fade(0.5).toString(),
  labels: color(ADALONG_COLORS.FLASH_PURPLE).fade(0.5).toString(),
  tagged: color(ADALONG_COLORS.LIGHT_YELLOW).fade(0.5).toString(),
  ownContent: color(ADALONG_COLORS.LIGHT_GRAY).fade(0.5).toString(),
};

interface Props {
  marks: MediaInfoMark[]
}

export function MediaMark({
  marks,
}: Props) {
  const markElements = marks.map((mark) => (
    <div
      className={markClass}
      style={{ borderColor: COLORS[mark.type] }}
      key={`${mark.type}_${mark.text}`}
    >
      <Mark
        markDef={mark}
        classes={[markContentClass]}
        iconSize={10}
      />
    </div>
  ));
  return (
    <>{markElements}</>
  );
}

const markClass = style({
  fontWeight: 100,
  display: 'inline-block',
  border: '1px solid',
  borderRadius: 30,
  padding: '3px 7px',
  margin: '4px 6px 3px 0',
});

const markContentClass = style({
  lineHeight: 1,
  fontFamily: 'HelveticaNeue-Light, Helvetica, sans-serif',
  $nest: {
    span: {
      verticalAlign: 'initial !important',
    },
  },
});
