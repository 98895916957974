import { ApiTypes, MediaQuery, MediaView, ResGetMedia } from 'common';
import { Call } from '@src/services/api';
import {ResRightRequest, SimilarRequestType} from '@src/types/api';

export const getMedia = async (mediaId: string): Promise<MediaView> => {
  const query: MediaQuery = {
    filter: {
      ids: [mediaId],
    },
  };
  const res = await searchMedias(query);
  const [media] = res.media;

  return media;
};

export const searchMedias = async (query: MediaQuery): Promise<ResGetMedia> =>
  Call.post<ResGetMedia, MediaQuery>('/api/media/search', query);

export const updateProductMediaCount = async (query: MediaQuery): Promise<void> =>
  Call.post<void, MediaQuery>('/api/media/update-linked-products-exact', query);

export const getMediaCarousel = (mediaId: string): Promise<MediaView[]> =>
  Call.get<MediaView[], { target: string }>('/api/media/carousel', {
    target: mediaId,
  });

export const findMediaSimilar = (
  mediaId: string,
  similiType: SimilarRequestType[],
  stringifiedQuery?: string
): Promise<MediaView[]> =>
  Call.get<
    MediaView[],
    { target: string; similiType: typeof similiType; stringifiedQuery?: string }
  >('/api/media/similar', {
    target: mediaId,
    similiType,
    stringifiedQuery,
  });

export const updateObjectLabels = (params: ApiTypes.ParamsPutObjectLabels): Promise<void> =>
  Call.put<ApiTypes.ParamsPutObjectLabels>('/api/media/object_labels', params);

export const countRightsRequest = (): Promise<number> => Call.get<number>(`/api/media/count-rights-request`);

export const getRightsRequest = (): Promise<ResRightRequest> => Call.get<ResRightRequest>(`/api/media/rights-request`);

export const createRightsRequest = (
  mediaId: string,
  params: ApiTypes.ParamsPostRightsRequest
): Promise<ApiTypes.ResPostRightsRequest> =>
  Call.post<ApiTypes.ResPostRightsRequest, ApiTypes.ParamsPostRightsRequest>(
    `/api/media/${mediaId}/rights-request`,
    params
  );

export const updateRightsRequest = (
  mediaId: string,
  params: ApiTypes.ParamsPostRightsRequest
): Promise<ApiTypes.ResPostRightsRequest> =>
  Call.post<ApiTypes.ResPostRightsRequest, ApiTypes.ParamsPostRightsRequest>(
    `/api/media/${mediaId}/rights-request`,
    params
  );

export const deleteRightsRequest = (mediaId: string): Promise<ApiTypes.ResDeleteRightsRequest> =>
  Call.delete(`/api/media/${mediaId}/rights-request`);

export const updateDiffusion = (media: Pick<MediaView, '_id' | 'diffusion'>) =>
  Call.post<
    MediaView,
    {
      mediaId: string;
      diffusion: boolean;
    }
  >('/api/media/diffusion', {
    mediaId: media._id,
    diffusion: !!media.diffusion,
  });
