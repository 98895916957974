import React, {Component} from 'react'

interface IProps {
    value: number
}

export class VideoTime extends Component <IProps, any> {

    constructor(props: IProps) {
        super(props)
    }

    public putZero(val: number) {
        const str_val = val.toFixed()
        return '00'.slice(0, 2 - str_val.length) + str_val
    }

    public render() {
        const minutes = this.putZero((this.props.value || 0) / 60)
        const seconds = this.putZero((this.props.value || 0) % 60)
        return <span>
            <span>{minutes}</span>:<span>{seconds}</span>
        </span>
    }
}
