import React from 'react';
import Menu, { MenuProps } from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import Divider from '@mui/material/Divider';
import { ThemeProvider, createTheme } from '@mui/material';
import Tooltip from '@mui/material/Tooltip';

import { ADALONG_COLORS, ThemeStyle } from '@src/styles';

export interface FloatingMenuItem {
  /**
     * Should be unique to identify items
     */
  id: string
  content: JSX.Element | string
  /**
     * If a string is provided, the button is disabled and
     * a tooltip displays the string
     */
  disabledReason?: string
  onClick: () => void
}

export const MenuDivider = 'divider';
type TMenuDivider = typeof MenuDivider;

export interface Props {
  /**
     * A HTML element, or a function that returns it. It's used to set the position of the menu.
     */
  anchorEl: MenuProps['anchorEl']
  handleClose: () => void
  list: (FloatingMenuItem | TMenuDivider)[]
}

export function FloatingMenu({
  anchorEl,
  handleClose,
  list,
}: Props) {
  return (
    <ThemeProvider theme={materialTheme}>
      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={handleClose}
      >
        {list.map(renderListElement)}
      </Menu>
    </ThemeProvider>
  );

  function renderListElement(listElement: FloatingMenuItem | TMenuDivider, index: number) {
    switch (listElement) {
      case MenuDivider: {
        return <Divider key={`divider-${index}`} />;
      }
      default: {
        const item: FloatingMenuItem = listElement;

        const disabled = item.disabledReason !== undefined;
        // Prevent click as we changed pointer events
        const onClick = disabled ? undefined : item.onClick;
        const tooltipTitle: string = item.disabledReason || '';

        return (
          <Tooltip
            title={tooltipTitle}
            key={item.id}
          >
            <MenuItem
              onClick={onClick}
              key={item.id}
              disabled={disabled}
            >
              {item.content}
            </MenuItem>
          </Tooltip>
        );
      }
    }
  }
}

const materialTheme = createTheme({
  components: {
    MuiList: {
      styleOverrides: {
        padding: {
          paddingTop: 0,
          paddingBottom: 0,
        },
      },
    },
    MuiPaper: {
      styleOverrides: {
        root: {
          backgroundColor: ADALONG_COLORS.FLASH_BLUE,
        },
        rounded: {
          borderRadius: 8,
        },
      },
    },
    MuiButtonBase: {
      styleOverrides: {
        root: {
          color: `${ADALONG_COLORS.SMOOTH_WHITE} !important`,
        },
      },
    },
    MuiListItem: {
      styleOverrides: {
        button: {
          '&:hover': {
            backgroundColor: 'rgb(0 0 0 / 8%)',
          },
        },
      },
    },
    MuiMenuItem: {
      styleOverrides: {
        root: {
          fontSize: ThemeStyle.textSizes.small,
          fontWeight: 600,
          '&.Mui-disabled': {
            // help displaying tooltip when button is disabled
            // by default MenuItem would have pointerEvents: none
            // https://github.com/mui-org/material-ui/blob/v4.11.4/packages/material-ui/src/ButtonBase/ButtonBase.js#L39
            pointerEvents: 'auto',
          },
        },
      },
    },
    MuiDivider: {
      styleOverrides: {
        root: {
          backgroundColor: 'rgb(255 255 255 / 13%) !important',
        },
      },
    },
  },
});
