import { downloadBrandContent } from '@src/queries/brandcontent/api';
import { MetadataTaggedFile } from 'common';
import { DateHelpers } from './date';

const MIME_TO_EXT = {
  'image/png': 'png',
  'image/jpeg': 'jpg',
  'image/gif': 'gif',
  'video/3gpp': '3gp',
  'video/mpeg': 'mpg',
  'video/quicktime': 'mov',
  'video/mp4': 'mp4',
};

type MimeType = keyof typeof MIME_TO_EXT;

export class DownloadHelper {

  public static mimeToExt(mime: string): string {
    return MIME_TO_EXT[mime as MimeType] || '';
  }

  /**
     * Construct the url to download media and navigate to it
     */
  public static getMediaDownloadUrl(mediaIds: string[], API_URL: string): string {
    const url = `${API_URL}/api/media/download?`;
    const params: string[] = [];
    mediaIds.forEach((id) =>
    // serialize array for web-app deserializer
      params.push(`ids[]=${id}`));
    return url + params.join('&');
  }

  /**
     * Create a temporary link to trigger the download
     */
  public static triggerDownload(url: string) {
    const link = document.createElement('a');
    link.target = '_blank';
    link.href = url;
    link.download = url;
    document.body.appendChild(link);
    link.click();
    //SetTimeout prevent some FIrefox incompatibility
    setTimeout(() => {
      URL.revokeObjectURL(link.href);
      link.remove;
    }, 1000);
  }

  /*
  Download Brand Content Tagged media
  Tag with Adalong Tags
  */
  public static async downloadBrandContentMedia(
    mediaUrl: string,
    trackingId: string
  ): Promise<MetadataTaggedFile> {
    const date = new Date(Date.now());
    const MetaFormatedDate = date.getUTCFullYear() + `:` + DateHelpers.dateZero(date.getMonth() + 1) + `:` + DateHelpers.dateZero(date.getDate())
    const metadatas = `{"date":"` + MetaFormatedDate + `","touchType":"2"}`;
    
    const taggedFile = await downloadBrandContent({
      url: mediaUrl,
      trackId: trackingId,
      target: "adalong",
      metadatas: metadatas,
    });
    taggedFile.fileUrl = toBucketDomain(taggedFile.fileUrl);
    return (taggedFile);
  }

}

// transform a URL like storage.googleapis.com/my-bucket to my-bucket.storage.googleapis.com
// Otherwise, CORS headers won't be present in the response
function toBucketDomain(gcsUrl: string): string {
  if (!gcsUrl.startsWith('https://storage.googleapis.com')) {
    return gcsUrl;
  }

  return gcsUrl.replace(
    /^https:\/\/storage.googleapis.com\/([^/]+)/,
    'https://$1.storage.googleapis.com',
  );
}