import React from 'react';
import { DropDownIcon } from './DropDownIcon';

export function ThumbnailIcon() {
  return (
    <DropDownIcon
      childrenRender={() => (
        <>
          <rect width='50' height='50' rx='10' fill='#D9D9D9' />
          <path
            d='M39.5715 43.5714H10.4286C7.81367 43.5714 5.71436 41.4721 5.71436 38.8571V9.71429C5.71436 7.09931 7.81367 5 10.4286 5H39.5715C42.1865 5 44.2858 7.09931 44.2858 9.71429V38.8571C44.2858 41.4721 42.1865 43.5714 39.5715 43.5714ZM17.2858 17.8571C18.4426 17.8571 19.5576 18.3293 20.3712 19.1429L41.3274 40.0991C41.5853 39.7141 41.7142 39.329 41.7142 38.857V9.71411C41.7142 8.51377 40.7716 7.57126 39.5713 7.57126H10.4284C9.2281 7.57126 8.28559 8.51377 8.28559 9.71411V25.0574L14.2003 19.1427C15.0139 18.3291 16.129 17.8571 17.2858 17.8571ZM34.0001 19.1429C31.8572 19.1429 30.1429 17.4286 30.1429 15.2857C30.1429 13.1429 31.8572 11.4286 34.0001 11.4286C36.1429 11.4286 37.8572 13.1429 37.8572 15.2857C37.8572 17.4286 36.1429 19.1429 34.0001 19.1429ZM34.0001 14C33.2718 14 32.7144 14.5575 32.7144 15.2857C32.7144 16.0139 33.2718 16.5714 34.0001 16.5714C34.7283 16.5714 35.2858 16.0139 35.2858 15.2857C35.2858 14.5575 34.7283 14 34.0001 14Z'
            fill='#343434'
          />
        </>
      )}
    />
  );
}
