import React from 'react';
import { SvgIconProps } from '@src/types/SvgIcon';

function SearchSvgIcon(props: SvgIconProps) {
  const { color = 'currentColor', size = 16, style = {} } = props;
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width={size}
      height={size}
      viewBox="0 0 16 16"
      style={style}
    >
      <g fill="none" fillRule="evenodd" stroke="none" strokeWidth="1">
        <g fill={color} fillRule="nonzero" transform="translate(-17 -11)">
          <g transform="translate(17 11)">
            <path d="M13.55 11.854c-.236-.236-.875-.47-1.918-.702a6.617 6.617 0 001.73-4.471A6.69 6.69 0 006.68 0 6.69 6.69 0 000 6.68a6.69 6.69 0 006.68 6.682c1.714 0 3.29-.651 4.472-1.73.217 1.016.451 1.656.702 1.918l2.09 2.09c.24.24.549.36.857.36a1.207 1.207 0 00.856-2.056l-2.107-2.09zm-6.87.137a5.315 5.315 0 01-5.31-5.31c0-2.93 2.382-5.31 5.31-5.31 2.93 0 5.311 2.38 5.311 5.31 0 2.93-2.38 5.31-5.31 5.31z" />
          </g>
        </g>
      </g>
    </svg>
  );
}

export { SearchSvgIcon };
