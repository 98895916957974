import { useQuery } from 'react-query';
import { DEPRECATED_QUERY_KEYS } from '@src/query';
import { ApiTypes } from 'common';
import { ApiError } from '@src/services/api';
import { getWidgets } from './api';

/**
 * Get widgets
 */
export const useQueryWidgets = () => useQuery<ApiTypes.ResGetWidgetList, ApiError | Error>([DEPRECATED_QUERY_KEYS.WIDGETS], getWidgets);
