import { useMutation } from "react-query";
import { queryClient, QUERY_KEYS } from "@src/query";
import { ApiError } from "@src/services/api";
import { ApiTypes, CreatorView } from "common";
import { createCreator } from "./api";
import { BodyCreateCreator } from "common/dist/types/apiTypes";

export const useMutationCreateCreator = () =>
  useMutation<CreatorView, ApiError, ApiTypes.BodyCreateCreator>(
    (influencer: BodyCreateCreator) => createCreator(influencer),
    {
      onSuccess: async () => {
        let key = QUERY_KEYS.influencers();
        queryClient.invalidateQueries(key);

        key = QUERY_KEYS.blacklistedCreators();
        queryClient.invalidateQueries(key);
      },
    }
  );
