import React, { useState } from 'react';
import { IAlert } from '@src/types/alert';
import { style } from 'typestyle';
import { ADALONG_COLORS } from '@src/styles';
import Slide from '@mui/material/Slide';

interface Props {
  alert: IAlert
  onRemove: () => void
  timeout?: number
}

export function Alert({
  alert,
  onRemove,
  timeout,
}: Props) {
  const [show, setShow] = useState(() => {
    if (timeout) {
      setTimeout(() => {
        if (show) {
          setShow(false);
        }
      }, timeout);
    }
    return true;
  });

  const onClose = () => {
    setShow(false);
  };

  return (
    <Slide direction="down" in={show} onExited={onRemove}>
      <div className={alertClass} data-state={alert.type}>
        <div className={textClass}>{window.T(alert.text)}</div>
        <div className={removeClass}>
          <div onClick={onClose}>
            <i className="material-icons notranslate">close</i>
          </div>
        </div>
      </div>
    </Slide>
  );
}

const alertClass = style({
  $debugName: 'alert',
  position: 'relative',
  textAlign: 'center',
  width: 500,
  margin: '45px auto 0',
  borderRadius: 100,
  padding: '8px 60px 8px 40px',
  color: ADALONG_COLORS.LIGHT_WHITE,
  $nest: {
    '&[data-state="danger"]': {
      backgroundColor: ADALONG_COLORS.FLASH_RED,
    },
    '&[data-state="success"]': {
      backgroundColor: ADALONG_COLORS.FLASH_GREEN,
    },
    '&[data-state="warning"]': {
      backgroundColor: ADALONG_COLORS.YELLOW,
    },
  },
});

const textClass = style({
  overflow: 'hidden',
  wordBreak: 'break-word',
});

const removeClass = style({
  position: 'absolute',
  top: 0,
  right: 15,
  height: '100%',
  display: 'flex',
  flexDirection: 'column',
  justifyContent: 'center',
  $nest: {
    '> div': {
      cursor: 'pointer',
      display: 'flex',
      justifyContent: 'center',
      flexDirection: 'column',
      $nest: {
        i: {
          fontSize: 17,
        },
      },
      border: '2px solid',
      borderRadius: 20,
      padding: 4,
    },
  },
});
