import { useQuery } from 'react-query';
import { QUERY_KEYS } from '@src/query';
import { fetchFacebookPages } from './api';

/**
 * Get config
 */
export const useQueryFacebookPages = () => {
    return useQuery(
        QUERY_KEYS.facebookPages(), 
        fetchFacebookPages, {
            onSuccess: (data) => {
                if (typeof data === 'string') {
                    return [];
                }
                return data;
            }
        }
    )
};