import React, { Component, createRef } from 'react';
import { stylesheet } from 'typestyle';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  label: string
  left?: boolean
  classNames?: string[]
  onClick?: (e: any) => any
}

class FormLabel extends Component<Props> {
  private divRef = createRef<HTMLDivElement>();

  public render() {
    const {
      left, label, children, classNames, ...props
    } = this.props;

    const _label = this.renderLabel(label);
    return (
      <div className={[sheet.labelContainer, ...(classNames || [])].join(' ')} {...props}>
        {left ? _label : null}
        <div ref={this.divRef}>
          {children}
        </div>
        {!left ? _label : null}
      </div>
    );
  }

  public renderLabel(label: string) {
    return (
      <label onClick={this.onClick.bind(this)}>
        <span>{label}</span>
      </label>
    );
  }

  public onClick(e: React.MouseEvent<HTMLLabelElement, MouseEvent>) {
    if (this.props.onClick) {
      this.props.onClick(e);
    }
  }
}

const sheet = stylesheet({
  labelContainer: {
    display: 'flex',
    flexDirection: 'row',
    $nest: {
      label: {
        cursor: 'pointer',
        margin: '0 0 0 5px',
        fontSize: 12,
        $nest: {
          span: {
            verticalAlign: 'sub',
          },
        },
      },
    },
  },
});

export { FormLabel };
