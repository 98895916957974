import React, { Component, createRef } from 'react';
import { style } from 'typestyle';
import { InfiniteScroller } from '@src/components/InfiniteScroller';
import { PerfectScrollbar } from '@src/components/PerfectScrollbar';
import { MediaView } from 'common';
import CollectionListContent from '../CollectionListContent';

interface Props {
  content: MediaView[]
  noMore: boolean
  loadMedias: () => Promise<void>
}

export class CollectionList extends Component <Props> {
  private scrollerRef = createRef<InfiniteScroller>();

  public render() {
    return (
      <InfiniteScroller
        ref={this.scrollerRef}
        container={(props, children) => (
          <PerfectScrollbar
            className={collectionWrapperClass}
            options={{
              wheelSpeed: 0.6,
              minScrollbarLength: 30,
              maxScrollbarLength: 120,
              wheelPropagation: false,
            }}
            {...props}
          >
            {children}
          </PerfectScrollbar>
        )}
        noMore={this.props.noMore}
        threshold={1000}
        load={this.props.loadMedias}
      >
        <div className={containerClass}>
          {this.props.content.filter((media) => media && media.type === 'image').map((media) => <CollectionListContent key={media._id} media={media} />)}
          <div style={{ display: 'flex', width: 135, margin: '2%' }}>&nbsp;</div>
        </div>
      </InfiniteScroller>
    );
  }
}

const collectionWrapperClass = style({
  position: 'relative',
  maxHeight: '35vh',
  overflow: 'hidden',
  margin: '5px 10px 2px',
  boxSizing: 'unset',
});

const containerClass = style({
  justifyContent: 'center',
  display: 'flex',
  flexWrap: 'wrap',
  width: '100%',
});
