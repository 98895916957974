import { SourceNetwork } from '@src/types/SourceNetwork';
import type { SvgIconComponent } from '@mui/icons-material';
import Instagram from '@mui/icons-material/Instagram';
import Twitter from '@mui/icons-material/Twitter';
import MusicNote from '@mui/icons-material/MusicNote';

export const NETWORK_ICONS: { [key in SourceNetwork]: SvgIconComponent } = {
  instagram: Instagram,
  twitter: Twitter,
  tiktok: MusicNote, // tmp icon for tiktok because material ui does not have it
};