import React from 'react';
import { style, classes } from 'typestyle';
import Tooltip from '@mui/material/Tooltip';
import LocalizedStrings from 'react-localization';

interface Props extends React.HTMLAttributes<HTMLDivElement> {
  disabled: boolean
  classNames?: string[]
  tooltip?: string
}
function FeatureDisabler({
  disabled = true,
  classNames = [],
  tooltip = lang.premiumFeatureWarning,
  children,
  ...props
}: Props) {
  return (
    <Tooltip title={disabled ? tooltip : ''}>
      <div {...props} className={classes(...classNames, disabled && disabledClass)}>
        <span className={disabled ? preventFromClickClass : ''}>
          {children}
        </span>
      </div>
    </Tooltip>
  );
}

export { FeatureDisabler };

const lang = new LocalizedStrings({
  en: {
    premiumFeatureWarning: 'This is a premium feature. Please contact us for more information.',
  },
});

const disabledClass = style({
  opacity: 0.5,
});

const preventFromClickClass = style({
  pointerEvents: 'none',
});
