import React, { Component, HTMLAttributes } from 'react';

export class Content extends Component<HTMLAttributes<HTMLDivElement>> {
  public render() {
    const { style, children, ...props } = this.props;

    return (
      <div
        {...props}
        style={{ ...style, display: 'flex' }}
      >
        {children}
      </div>
    );
  }
}
