import React from 'react';
import { IconRender, InheritProps } from './../IconRender';

export function WithPackshotIcon(props: InheritProps) {
  return (
    <IconRender
      {...props}
      childrenRender={(color: string) => (
        <>
          <rect width='70' height='70' rx='8' fill='#343434' />
          <rect x='2' y='2' width='66' height='66' rx='6' stroke={color} stroke-width='4' />
          <path
            d='M4 47H66V62C66 64.2091 64.2091 66 62 66H8C5.79086 66 4 64.2091 4 62V47Z'
            fill='#D9D9D9'
          />
        </>
      )}
    />
  );
}
