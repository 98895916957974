import React, { useEffect } from 'react';
import TextField from '@mui/material/TextField';
import { AutoCompleteOption, OptionId, OptionLabel } from '@src/types/AutoComplete';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import { Autocomplete } from '@mui/material';
import { styled } from '@mui/system';
import { style } from 'typestyle';

export type RenderTag = (id: string, onRemove: () => void) => JSX.Element;

export interface AutoCompleteProps {
  loading: boolean;
  options: AutoCompleteOption[] | any;
  selected?: string[];
  title: string;
  onInputChange?: (val: string) => void;
  onAdd: (val: OptionId) => void;
  onRemove: (val: OptionId) => void;
  renderTag: RenderTag;
  id?: string;
}

const StyledInput = styled(TextField)(({ theme }) => ({
  borderColor: 'white',
  '&::placeholder': {
    fontStyle: 'italic',
  },
}));

export function AutoComplete({ 
  loading, 
  options, 
  selected, 
  title, 
  onAdd, 
  onRemove, 
  renderTag,
  onInputChange,
  id,
}: AutoCompleteProps) {
  const defaultTags = options.filter((op: { id: OptionId; label: OptionLabel }) => selected?.find((s) => s === op.id));

  return (
    <Autocomplete
      id={`autocomplete-${id}`}
      className={autoCompleteClass}
      hidden={!options.length && loading}
      multiple
      options={options} 
      filterSelectedOptions
      disableClearable
      popupIcon={<KeyboardArrowDownIcon />}
      getOptionLabel={(option) => option?.label || ''}
      isOptionEqualToValue={(option, value) => String(option?.id) === String(value?.id)}
      value={selected ? defaultTags : undefined} // Controlled when selected is passed
      defaultValue={selected ? undefined : defaultTags} // Uncontrolled fallback
      renderTags={(values, getTagProps) =>
        values.map((option, index) =>
          renderTag(option.id, () => {
            const tagProps = { ...getTagProps({ index }) };
            tagProps.onDelete(index);
            onRemove(option.id);
          })
        )
      }
      onInputChange={(event, value) => {
        onInputChange?.(value);
      }}
      onChange={(e, values, reason) => {
        if (reason === 'selectOption') {
          const optionId = [...values]?.pop()?.id;
          if (optionId) {
            onAdd(optionId);
          }
        }
      }}
      renderInput={(params) => <StyledInput {...params} placeholder={title} />}
    />
  );
}

const autoCompleteClass = style({
  marginTop: '10px',
});