import React from 'react';
import Radio, { RadioProps } from '@mui/material/Radio';
import { styled } from '@mui/system';
import { ADALONG_COLORS } from '@src/styles';

interface Props<T extends { _id: string }> {
  elms?: T[]
  currentId?: string
  handleChange: (elm: T) => any
  style?: React.CSSProperties
  className?: string
}

const CustomRadioRoot = styled('span')({
  borderRadius: '50%',
  width: 10,
  height: 10,
  backgroundColor: ADALONG_COLORS.LIGHT_GRAY,
  '&.Mui-focusVisible': {
    outline: '2px auto rgba(19,124,189,.6)',
    outlineOffset: 2,
  },
  'input:hover ~ &': {
    backgroundColor: '#ebf1f5',
  },
  'input:disabled ~ &': {
    boxShadow: 'none',
    background: 'rgba(206,217,224,.5)',
  },
});

const CustomCheckedIcon = styled(CustomRadioRoot)({
  backgroundColor: ADALONG_COLORS.LIGHT_WHITE,
  // 'backgroundImage': 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
  '&:before': {
    display: 'block',
    width: 10,
    height: 10,
    backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 32%)',
    content: '""',
  },
  'input:hover ~ &': {
    backgroundColor: '#106ba3',
  },
});

// Inspired by blueprintjs
function CustomRadio(props: RadioProps) {
  return (
    <Radio
      disableRipple
      color="default"
      style={{ padding: 4 }}
      checkedIcon={<CustomCheckedIcon />}
      icon={<CustomRadioRoot />}
      {...props}
    />
  );
}

export function PointBar<T extends { _id: string }>({
  elms,
  currentId,
  handleChange,
  style,
  ...rest
}: Props<T>) {
  const key = `radiogroup-${Math.random()}`;
  return (
    <div
      style={{ ...pointBarStyle, ...style }}
      {...rest}
    >
      {elms && elms?.length > 1 && elms.map((elm, i) => (
        <CustomRadio
          key={i}
          checked={elm._id === currentId}
          onChange={() => handleChange(elm)}
          value={elm}
          name={key}
        />
      ))}
    </div>
  );
}

const pointBarStyle: React.CSSProperties = {
  width: '100%',
  textAlign: 'center',
};
