import React, { Component, ReactNode } from 'react';
import { style } from 'typestyle';
import backgroundLoginImg from '@src/assets/img/site/background-login.jpg';

interface LoginContainerProps {
  children: ReactNode;
}

export class LoginContainer extends Component<LoginContainerProps, {}> {
  public render() {
    return (
      <div className={loginClass}>
        {this.props.children}
      </div>
    );
  }
}

const loginClass = style({
  position: 'absolute',
  display: 'flex',
  flexGrow: 1,
  width: '100%',
  height: '100%',
  backgroundImage: `url(${backgroundLoginImg})`,
  backgroundPosition: 'center',
  backgroundSize: 'cover',
  textAlign: 'center',
  justifyContent: 'center',
  flexDirection: 'column',
  overflowY: 'scroll',
  $nest: {
    '& .alert-success': {
      backgroundColor: '#50a92b !important',
    },
  },
});
