import React from 'react';
import { NETWORK_ICONS } from '@src/config/media';
import { stylesheet } from 'typestyle';
import type { SvgIconComponent } from '@mui/icons-material';
import { ADALONG_COLORS } from '@src/styles';
import { Row } from '../Utils';

interface Props {
  network?: keyof typeof NETWORK_ICONS
  link?: string
  titleLine: React.ReactNode
  /**
     * Button on the rights of the title
     * To display a menu for media actions
     */
  actionButtons: React.ReactNode
  littleDataLine: React.ReactNode
  descriptionLine?: React.ReactNode
  marksLine?: React.ReactNode

  size?: number
  autoHeight?: boolean
  networkIconSize?: number
}

/**
 * Structure for displaying media info
 */
export function MediaInfo({
  autoHeight,
  network,
  link,
  titleLine,
  actionButtons,
  littleDataLine,
  descriptionLine,
  marksLine,
  size = 12,
}: Props) {
  const heightManager = autoHeight ? { maxHeight: 300 } : { height: 300 };
  return (
    <div
      style={{ fontSize: size, ...heightManager }}
      className={sheet.mediaInfo}
    >
      <Row>
        <a
          className={sheet.link}
          href={link}
          target="_blank"
          rel="noreferrer"
        >
          {renderNetworkIcon(network, size * 2)}
        </a>
        <div className={sheet.headLines}>
          <div className={sheet.titleLine}>
            {titleLine}
            <div className={sheet.actionButtons}>
              {actionButtons}
            </div>
          </div>
          <div>{littleDataLine}</div>
        </div>
      </Row>
      <div style={{ fontSize: size + 2 }}>
        {descriptionLine}
      </div>
      <div style={{ fontSize: size - 2 }}>
        {marksLine}
      </div>
    </div>
  );
}

function renderNetworkIcon(network?: keyof typeof NETWORK_ICONS, size?: number) {
  if (!network) {
    return null;
  }
  const Icon: SvgIconComponent = NETWORK_ICONS[network];
  return (
    <div className={sheet.networkIconContainer} style={{ fontSize: size }}>
      <Icon fontSize="inherit" className={sheet.networkIcon} />
    </div>
  );
}

const sheet = stylesheet({
  link: {
    color: ADALONG_COLORS.LIGHT_WHITE,
  },
  mediaInfo: {
    textAlign: 'left',
  },
  headLines: {
    flex: 1,
  },
  networkIconContainer: {
    flexShrink: 0,
    marginRight: 8,
  },
  networkIcon: {
    verticalAlign: 'top',
  },
  titleLine: {
    position: 'relative',
  },
  actionButtons: {
    position: 'absolute',
    right: 2,
    top: 4,
  },
});
