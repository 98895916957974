import { ADALONG_COLORS } from '@src/styles';
import { color } from 'csx';
import React from 'react';
import { classes, style } from 'typestyle';

interface Props {
  selected: boolean
}

export type PlatformIconProps = React.HTMLAttributes<HTMLOrSVGElement> & Props;

export function PlatformIcon({
  children,
  selected,
  className,
  ...props
}: PlatformIconProps) {
  return (
    <svg
      className={classes(svgClass, className)}
      viewBox="0 0 64 64"
      height="100%"
      data-active={selected}
      {...props}
    >
      <g
        className={iconClass}
        data-active={selected}
      >
        {children}
      </g>
    </svg>
  );
}

const svgClass = style({
  stroke: ADALONG_COLORS.LIGHT_GRAY,
  $nest: {
    '&:hover': {
      stroke: color(ADALONG_COLORS.LIGHT_GRAY).lighten(0.14).toString(),
    },
    '&[data-active="true"]': {
      stroke: ADALONG_COLORS.LIGHT_WHITE,
    },
  },
});

const iconClass = style({
  $nest: {
    '& > path, & > line, & > rect': {
      fill: 'none',
      strokeLinecap: 'round',
      strokeLinejoin: 'round',
      strokeWidth: 2,
    },
    '&[data-active="true"]': {
      stroke: ADALONG_COLORS.LIGHT_WHITE,
      $nest: {
        '& > path, & > line, & > rect': {
          strokeWidth: 4,
        },
      },
    },
  },
});
