import React, { InputHTMLAttributes } from 'react';
import { classes, stylesheet } from 'typestyle';

type Props = InputHTMLAttributes<HTMLInputElement>;

function Input(props: Props) {
  const { className = [] } = props = { ...props };
  delete props.className;
  return (
    <input
      className={classes(sheet.input, className)}
      {...props}
    />
  );
}

const sheet = stylesheet({
  input: {
    background: 'transparent',
    color: 'white',
    letterSpacing: '0.7px',
    padding: '8px 5px',
    border: 'none',
    borderBottom: '2px solid',
    outline: 'none',
    transition: 'border-color 0.2s',
    $nest: {
      '&:-webkit-autofill': {
        '-webkit-box-shadow': '0 0 0px 1000px #272727 inset',
        '-webkit-text-fill-color': '#ffd17b',
      },
      '&:focus': {
        borderColor: '#89c1ec',
      },
      '&[disabled]': {
        borderColor: 'gray',
      },
    },
  },
});

export { Input };
