import * as React from 'react';
import Avatar from '@mui/material/Avatar';
import { CollectionPermissionView } from 'common';
import {AvatarGroup, Tooltip} from '@mui/material';
import {ADALONG_COLORS} from "@src/styles";
import AddIcon from '@mui/icons-material/Add';

interface Props {
    permissionList: CollectionPermissionView[],
    onClick: (e: React.MouseEvent<HTMLDivElement, MouseEvent>) => void,
    isMine: boolean,
}

const avatarDefaultSx = {
    width: 26,
    height: 26,
    marginTop: '2px',
    fontSize: 14,
    textShadow: '0px 0px 3px black'
}

function avatarColor(index: number) {
    const colors = ['#E3FF73','#0005DC','#BFFDDF','#F500FF','#F9FCEE','#6E73DB', '#D9F4E4', '#F680FF', '#EAF9AF', '#727F3B','#00055B', '#729683', '#76007F', '#797A74'];
    return colors[index % colors.length];
}


function stringAvatar(
    i: number,
    displayName: string
) {
    let initials;
    const splitedDisplay = displayName.split('.');
    if (splitedDisplay.length != 2) {
        initials = displayName.charAt(0).toUpperCase() + displayName.charAt(1).toUpperCase();
    }
    else
    {
        initials = splitedDisplay[0][0].toUpperCase() + splitedDisplay[1][0].toUpperCase();
    }
    return {
        sx: {
            bgcolor: avatarColor(i),
            ...avatarDefaultSx,
        },
        children: initials,
    };
}

export default function UserAccessList({ permissionList, onClick, isMine }: Props) {
    return (
        <AvatarGroup
            sx={{ marginRight: 5, '& .MuiAvatar-root': { ...avatarDefaultSx }, }}
            max={10}  onClick={(e) => permissionList.length > 10 ? onClick(e) : null }>
            { permissionList.map((perm, i) => (
                <Tooltip title={perm.user?.displayName ?? 'no displayname'}>
                    <Avatar
                        {...stringAvatar(i, perm.user.displayName ?? '')} />
                </Tooltip>
                )

            )}
            {isMine && (
                <Avatar
                    sx={{
                    bgcolor: ADALONG_COLORS.LIGHT_GRAY,
                    cursor: 'pointer',
                    ...avatarDefaultSx,
                }}
                    onClick={(e) => onClick(e)}

                > <AddIcon sx={{ fontSize: 15 }} /> </Avatar>
            )}
        </AvatarGroup>
    );
}
