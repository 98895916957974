import React from 'react';
import { IIPRightsStatus } from '@src/types/IPRights';
import { style } from 'typestyle';
import { ThemeStyle } from '@src/styles';
import { ObjectHelper } from '@src/services/helpers/object';
import { IPRightsStatusText } from './IPRightsStatusText';

interface Props {
  status: IIPRightsStatus
  format?: (s: string) => string
}

export function IPRightsStatus({
  status,
  format = (s) => s.toLowerCase(),
}: Props) {
  return (
    <span className={statusClass} data-status={status || 'none'}>
      <IPRightsStatusText status={status} format={format} />
    </span>
  );
}

const statusClass = style({
  fontWeight: 'bold',
  verticalAlign: 'inherit',
  // Create a color rule for each ip rights status
  $nest: ObjectHelper.map(ThemeStyle.ipRightsStatus.colors, ([key, color]) => [`&[data-status="${key}"]`, {
    color,
  }]),
});
