import dayjs from 'dayjs';
import dayjsUtc from 'dayjs/plugin/utc';

dayjs.extend(dayjsUtc);

export const DateHelpers = {
  dateToTimeStamp(date: Date | undefined, d: 'startOf' | 'endOf'): number | undefined {
    if (!date) {
      return undefined;
    }
    return dayjs(date)[d]('day').utc().unix() * 1000;
  },
  dateZero(num: number): string {
    // 1 -> 01 , formate date
    return num.toString().padStart(2, '0');
  },
};
