import React from 'react';
import { FormLabel } from '@src/components/Form/FormLabel';
import { CheckBox } from '@src/components/CheckBox';
import { style } from 'typestyle';

export const SettingsCheckbox = ({
  label,
  checked,
  onClick,
}: {
  label: string;
  checked: boolean;
  onClick: () => void;
}) => {
  return (
    <FormLabel classNames={[checkboxClass]} label={label} onClick={onClick}>
      <div className={checkboxContainerClass}>
        <CheckBox size={16} checked={checked} onChange={onClick} />
      </div>
    </FormLabel>
  );
};

const checkboxClass = style({
  $debugName: 'checkbox',
  margin: '5px 0',
});
const checkboxContainerClass = style({
  marginRight: 5,
});
