import { useMutation } from 'react-query';
import { ApiError } from '@src/services/api';
import { queryClient, QUERY_KEYS, QUERY_PREFIX } from '@src/query';
import { createCreator, createCreatorBulk } from '../influencers/api';

export const useMutationCreatorCountriesUpdate = () => useMutation<any, ApiError, {
  updates: {
    username: string
    countries: string[]
    countriesToExclude?: string[]
  }[]
}>(async ({ updates }) => {
  if (updates.length === 0) {
    return Promise.resolve();
  } else if (updates.length === 1) {
    const { username, countries, countriesToExclude } = updates[0];
    return createCreator({
      username,
      countries,
      countriesToExclude,
      upsert: true,
    });
  } else {
    return createCreatorBulk(updates.map(({ username, countries, countriesToExclude }) => ({
      username,
      countries,
      countriesToExclude,
      upsert: true,
    })));
  }
}, {
  onSuccess: async (_) => {
    queryClient.invalidateQueries(QUERY_KEYS.mediaFind());
    queryClient.invalidateQueries(QUERY_KEYS.collectionsMedia());
    queryClient.invalidateQueries([QUERY_PREFIX.MEDIA_SIMILAR]);
    queryClient.invalidateQueries([QUERY_PREFIX.MEDIA_CAROUSEL]);
  },
});
