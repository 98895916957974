/*
 **	Discover Action Creators
 */

import { DiscoverState } from '@src/types/redux/discover';
import { MediaView } from 'common';
import { REDUX_ACTIONS } from './_types';

export const DISCOVER_ACTION_CREATORS = {
  setLibrary(medias: MediaView[]) {
    return { type: REDUX_ACTIONS.DISCOVER.SET_LIBRARY, medias };
  },

  noMoreMedias(noMore: boolean) {
    return { type: REDUX_ACTIONS.DISCOVER.NO_MORE, noMore };
  },

  addMediasToLibrary(medias: MediaView[]) {
    return { type: REDUX_ACTIONS.DISCOVER.ADD_MEDIAS_TO_LIBRARY, medias };
  },

  setLibraryLoaded(libraryLoaded: boolean) {
    return { type: REDUX_ACTIONS.DISCOVER.SET_LIBRARY_LOADED, libraryLoaded };
  },

  setTotalMedias(totalMedias?: number) {
    return { type: REDUX_ACTIONS.DISCOVER.SET_TOTAL_MEDIAS, totalMedias };
  },

  setTotalExactMedias(totalExactMedias: number) {
    return { type: REDUX_ACTIONS.DISCOVER.SET_TOTAL_EXACT_MEDIAS, totalExactMedias };
  },

  setFiltersUnfold(unfold: boolean) {
    return { type: REDUX_ACTIONS.DISCOVER.SET_FILTERS_UNFOLD, unfold };
  },

  setSearchFilterSuggestion(suggestion: string[], kind: string) {
    return { type: REDUX_ACTIONS.DISCOVER.SET_SEARCHFILTER_SUGGESTION, suggestion, kind };
  },

  saveSearchFilterValue(value: string, kind: string) {
    return { type: REDUX_ACTIONS.DISCOVER.SAVE_SEARCHFILTER_VALUE, value, kind };
  },

  setMlLabelsCompletion(completion: string[]) {
    return { type: REDUX_ACTIONS.DISCOVER.SET_ML_LABELS_COMPLETION, completion };
  },

  setMediaNotFound(id: string, isExactMode: boolean) {
    return { type: REDUX_ACTIONS.DISCOVER.SET_MEDIA_NOT_FOUND, id, isExactMode };
  },

  setMasonryImagesLoaded(loaded: boolean) {
    return { type: REDUX_ACTIONS.DISCOVER.SET_MASONRY_IMAGES_LOADED, loaded };
  },

  setLibraryCursor(cursor: DiscoverState['cursor']) {
    return { type: REDUX_ACTIONS.DISCOVER.SET_LIBRARY_CURSOR, cursor };
  },
};
