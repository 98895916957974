import React, { useState, useEffect, useCallback } from 'react';

/*Redux */
import { connect } from 'react-redux';
import { ICombinedReducers } from '@src/types/redux';

/*Styling*/
import { style, stylesheet } from 'typestyle';
import styles from '../Settings/styles.scss';

/*Misc*/
import * as CommonActions from '../../../../actions/common';
import { auth } from '../../../../config/firebaseAuth';
import { api } from '../../../../services/api';
import { EPopup } from '@src/types/redux/common';
import { DispatchType } from '../../../../store';

/*Components*/
import { OverlayContainer } from '@src/components/OverlayContainer';
import { CloseButton } from '@src/components/CloseButton';
import { Row } from '@src/components/Utils';
import { Loader } from '@src/components/Loader';
import { PerfectScrollbar } from 'components/PerfectScrollbar';
import { GetPasswordLinkButton } from './GetPasswordLinkButton';

/* Types */
import { UserView, IAdmin } from 'common';
import { Services } from '@src/types/Services';

interface IState {
    resetPasswordStatus: 'none' | 'sending' | 'sent' | 'error'
  }

  interface OwnProps {
    services: Services
  }

  interface IProps {
    admin?: IAdmin
    user: UserView | null
    focus?: boolean
    loadUserInfo: () => Promise<{
      user: UserView
    }>
    close: () => void
  }

  type Props = OwnProps & IProps;

export function UserSettings(props: Props) {

    const [resetPasswordStatus, setResetPasswordStatus] = useState('none');
    const [mounted, setMounted] = useState(false)

    const refresh = (): void | NodeJS.Timeout => {
        if (!mounted) {
          return;
        }
        const loop = () => setTimeout(() => refresh(), 3000);
        if (!props.focus) {
          return loop();
        }
      }

      const close = useCallback(
        () => {
          props.close();
        },
        [],
      );

      const resetPassword = async () => {
        setResetPasswordStatus('sending');
        const c_user = auth.currentUser;

        if (!c_user) {
          console.error('Firebase user undefined');
          setResetPasswordStatus('error');
          return null;
        }
        const c_email = c_user.email;
        if (!c_email) {
          console.error(`User ${c_user.displayName} has no email`);
          setResetPasswordStatus('error');
          return null;
        }
        await api.resetPasswordAuthenticated().then(() => {
        setResetPasswordStatus('sent');
        }).catch((err) => {
          console.error(err);
          setResetPasswordStatus('error');
        });
      }

    useEffect(() => {
        setMounted(true)
        refresh();
        return () => {
            setMounted(false)
          }
      }, [])


      if (!props.user) {
        return null; // TODO: loading
      }
    return (
<>
    <OverlayContainer
        containerClasses={[styles.settingsContainer]}
        frameClasses={[styles.settings]}
        onClose={close}
      >
        <Row className={headClass}>
          <div className={sheet.title}>{window.T('common.settings.title')}</div>
          <CloseButton
            size="large"
            classNames={[sheet.closeButton]}
            onClick={close}
          />
        </Row>
        <PerfectScrollbar className={contentClass}>
        {!props.admin
            ? (
              <section id="oauth">
                <div>
                  <div className={"title"}>
                    {window.T('common.settings.password')}
                  </div>
                  <div className="desc">
                    {
                      ['none', 'error'].indexOf(resetPasswordStatus) >= 0
                      ?
                      <>
                      {window.T('common.settings.resetpassword')}
                      <GetPasswordLinkButton
                      onAction={() => resetPassword()}
                      size="small"
                      />
                      </>
                       : null
                    }


                    {window.reactSwitch(resetPasswordStatus, [
                      {
                        val: 'sending',
                        elem: (
                          <Loader size={15} />
                        ),
                      },
                      {
                        val: 'error',
                        elem: (
                          <span className="alert">{window.T('common.settings.resetpassworderror')}</span>
                        ),
                      },
                      {
                        val: 'sent',
                        elem: (
                          <span>{window.T('common.settings.resetemailsent')}</span>
                        ),
                      },
                    ])}
                  </div>
                </div>
              </section>
            )
            : null}
            </PerfectScrollbar>
            </OverlayContainer>
</>
    )
}

const headClass = style({
    flexShrink: 0,
  });

  const contentClass = style({
    paddingRight: 10,
  });

  const sheet = stylesheet({
    title: {
      flex: 1,
      textAlign: 'center',
      fontSize: 30,
      marginBottom: 25,
    },
    closeButton: {
      flexShrink: 0,
    },
  });

  export default connect((state: ICombinedReducers) => ({
    focus: state.common.windowFocus,
    opened: state.common.settingsPopup,
    user: state.common.user,
    admin: state.common.admin,
  }), (dispatch: DispatchType) => ({
    loadUserInfo: () => dispatch(CommonActions.loadUserInfo()),
    close: () => dispatch(CommonActions.setPopup(EPopup.userSettings, false)),
  }))(UserSettings);
