import { useQuery } from 'react-query';
import { QUERY_KEYS } from '@src/query';
import { fetchFacebookAdAccounts } from './api';

/**
 * Get config
 */
 export const useQueryFacebookAdAccounts = () => {
  return useQuery(
      QUERY_KEYS.facebookAdAccounts(), 
      fetchFacebookAdAccounts, {
          onSuccess: (data) => {
              if (typeof data === 'string') {
                  return [];
              }
              return data;
          }
      }
  )
};