import { MediaView } from 'common';
import { Row } from '@src/components/Utils';
import { ADALONG_COLORS } from '@src/styles';
import React from 'react';
import { stylesheet } from 'typestyle';

const MAX_IMAGES = 4;

interface Props {
  mediaList?: MediaView[];
}

const SQUARE_SIZE = 60;

export function MediaPreview({ mediaList }: Props) {
  if (!mediaList) {
    return null;
  }
  const list = mediaList.filter((m) => !m.isDeleted);
  return (
    <Row className={sheet.mediaPreview}>
      {list.slice(0, MAX_IMAGES).map((media, i) => (
        <div
          key={i}
          className={sheet.imageContainer}
          style={{
            backgroundImage: `url("${media.cdn_image}")`,
          }}
        />
      ))}
    </Row>
  );
}

const sheet = stylesheet({
  mediaPreview: {
    marginRight: 3,
    justifyContent: 'flex-end',
  },
  imageContainer: {
    backgroundColor: ADALONG_COLORS.LIGHT_BLACK,
    backgroundRepeat: 'no-repeat',
    backgroundPosition: 'center',
    backgroundSize: 'cover',
    width: SQUARE_SIZE,
    height: SQUARE_SIZE,
    margin: 'auto 3px',
  },
});
