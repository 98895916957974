export class Point {
  constructor(public x: number, public y: number) {}

  public set(x: number, y: number): void {
    this.x = x;
    this.y = y;
  }

  public distance(pt: Point): number {
    const dx = pt.x - this.x;
    const dy = pt.y - this.y;
    return Math.sqrt((dx * dx + dy * dy));
  }

  public add(pt: Point): Point {
    return new Point(this.x + pt.x, this.y + pt.y);
  }

  public substract(pt: Point): Point {
    return new Point(this.x - pt.x, this.y - pt.y);
  }

  public scale(scaleX: number, scaleY: number): Point {
    return new Point(this.x * scaleX, this.y * scaleY);
  }

  public angleDeg(): number {
    return Math.atan2(this.y, this.x) * 57.2957795131;
  }

  public rotate(degs: number): Point {
    const rads = degs * 0.01745329251; // PI / 180
    const x = this.x * Math.cos(rads) + this.y * Math.sin(rads);
    const y = this.x * Math.sin(rads) - this.y * Math.cos(rads);
    return new Point(x, y);
  }

  public get length(): number {
    return Math.sqrt(this.x * this.x + this.y * this.y);
  }
}
