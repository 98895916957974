import React from 'react';
import { SearchSuggestion } from '@src/types/Filters';

interface Props {
  suggestion: SearchSuggestion
}

export function LanguageItem({
  suggestion,
}: Props) {
  return (
    <span>{suggestion.display}</span>
  );
}
