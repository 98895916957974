import LocalizedStrings from 'react-localization';
import { getLangError } from '@src/fragments/Alerting/AlertSnackbar';
import { ApiError } from '@src/services/api';

export const getCollectionLangError = (error: ApiError | null) => lang.formatString<string>(
  getLangError(error?.type, lang.errors) || '', error?.data || {},
);

const lang = new LocalizedStrings({
  en: {
    errors: {
      forbidden: 'Insufficient rights for this action',
      duplicate: 'A collection with this name already exists in your group',
      tooshort: 'Invalid {field} ({count} chars min)',
      toolong: 'Invalid {field} ({count} chars max)',
      usedAsWidgetSource: 'This collection is used as Widget source. Please remove it from all widgets before deleting this collection',
    },
  },
});
