import { useInfiniteQuery, UseInfiniteQueryOptions } from 'react-query';
import { queryClient, QUERY_KEYS } from '@src/query';
import { ApiTypes } from 'common';
import { RightsRequestStatusValue } from '@src/components/RightsRequestStatusSelect';
import { getCollectionMedias } from './api';

const GET_MEDIA_LIMIT = 25;

/**
 * Get collection
 * Note: Once the collection use /api/media to get media, use directly useQueryMediaFind hook
 * and remove this one
 * @param collectionId
 * @param params
 * @param config
 */
export const useQueryCollectionMedias = (
  collectionId: string,
  params?: {
    token?: string
    rightsStatus: RightsRequestStatusValue
  },
  config?: UseInfiniteQueryOptions<ApiTypes.ResGetCollectionMedias>,
) => {
  const rightsStatus = params?.rightsStatus;
  return useInfiniteQuery({
    queryKey: QUERY_KEYS.collectionMedia(collectionId, params),
    queryFn: ({ pageParam: cursor }) => getCollectionMedias(collectionId, {
      cursor,
      limit: GET_MEDIA_LIMIT,
      rightsStatus,
    }, params?.token),
    getNextPageParam: (lastRes) => lastRes.cursor,
    onSuccess: (data) => {
      data.pages.forEach((res) => res.medias.forEach((media) => queryClient.setQueryData(QUERY_KEYS.media(media._id), media)));
    },
    cacheTime: 0,
    ...config,
  });
};
