/*
** Component presenting the list of collections
*/
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { style } from 'typestyle';
import { Row } from '@src/components/Utils';
import { Pathname } from '@src/types/Browsing';
import { ICombinedReducers } from '@src/types/redux';
import AuthorizedPage from '@src/components/AuthorizedPage';
import NavBar from '../../components/NavBar';
import styles from './styles.scss';
import { ShareCollections } from './Collections/index';
import { closeSharePrompt, openSharePopup } from 'actions/share';

import SharePrompt from './components/SharePrompt';
import SharePopup from './components/SharePopup';
import { bigStore } from '@src/store';
import { ShareNetworkType, SharePopupInfo } from '@src/types/redux/share';
import Display from './Display';

const mapStateToProps = (state: ICombinedReducers) => ({
  sharePopup: state.share.sharePopup,
  sharePrompt: state.share.sharePrompt,
  windowHeight: state.common.windowSize.height,
  user: state.common.user,
});

type Props = Readonly<ReturnType<typeof mapStateToProps>>;

// Open  Share modal
function openShare(target: ShareNetworkType, info: SharePopupInfo) {
  bigStore.dispatch(openSharePopup(target, info));
}

// Close share modal
function closeShare() {
  bigStore.dispatch(closeSharePrompt());
}

class Share extends Component <Props> {
  public constructor(props: Props) {
    super(props);
    this.state = {
      uploadingFiles: {},
    };
  }

  public render() {
    return (
      <AuthorizedPage tab="share" user={this.props.user}>
        <div className={styles.page} style={{ height: this.props.windowHeight }}>
          <NavBar active={Pathname.share} />
          {this.props.sharePrompt && 
            <SharePrompt 
              openSharePopup={openShare}
              closeSharePrompt={closeShare}
            />
          }
          {this.props.sharePopup && <SharePopup />}
          <div className={styles.share}>
            <Row className={contentClass}>
              <ShareCollections />
              <Display />
            </Row>
          </div>
        </div>
      </AuthorizedPage>
    );
  }
}

const contentClass = style({
  position: 'relative',
  overflow: 'hidden',
  flex: 1,
});

export default connect(mapStateToProps)(Share);
