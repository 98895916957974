import { ApiTypes } from "common";
import { Call } from "@src/services/api";

export const createCreator = (
  influencer: ApiTypes.BodyCreateCreator
): Promise<any> =>
  Call.post<any, ApiTypes.BodyCreateCreator>(
    "/api/creator/",
    influencer
  );

export const createCreatorBulk = (
  influencers: ApiTypes.BodyCreateCreator[]
): Promise<any> =>
  Call.post<any, ApiTypes.BodyCreateCreators>(
    "/api/creator/bulk",
    {
      creators: influencers,
    }
  );

export const getInfluencersCreators = (): Promise<ApiTypes.ResGetCreatorsList> =>
  Call.get(
    "/api/creator",
    {
      isInfluencer: true,
    }
  );

export const getBlacklistedCreators = (): Promise<ApiTypes.ResGetCreatorsList> =>
  Call.get(
    "/api/creator",
    {
      isBlacklisted: true,
    }
  );  

export const deleteCreator = (
  id: string
): Promise<ApiTypes.ResDeleteInfluencer> =>
  Call.delete(`/api/creator/${id}`);

export const updateCreator = (data: {
  id: string;
  doc: ApiTypes.BodyUpdateCreator;
}): Promise<void> =>
  Call.put<void, ApiTypes.BodyUpdateCreator>(
    `/api/creator/${data.id}`,
    data.doc
  );
