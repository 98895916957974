import React, { PropsWithChildren } from 'react';
import { stylesheet } from 'typestyle';
import { Row } from '../Utils';

/**
 * Create row with an flex-end alignment for the shortcut action
 * button in media views
 */
export function ShortcutActionRow({
  children,
}: PropsWithChildren<{}>) {
  return (
    <Row
      className={sheet.shortcutAction}
    >
      {children}
    </Row>
  );
}

const sheet = stylesheet({
  shortcutAction: {
    flexGrow: 'initial',
    padding: 17,
    width: '100%',
    justifyContent: 'flex-end',
  },
});
