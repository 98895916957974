/*
**	Common Action Creators
*/

import * as reduxHelpers from '@src/services/helpers/redux';
import {
  ApiTypes, IMentionPage, MediaView, MentionPageView, GroupUserView,
} from 'common';
import { AnyAction } from 'redux';
import { ThunkDispatch } from 'redux-thunk';
import { EPopup, CommonState } from '@src/types/redux/common';
import { IAlert } from '@src/types/alert';
import { SocialNetworkType } from '@src/components/SocialNetworkButton';
import { FacebookAdsInterface } from '@src/types/Business';
import { AddToCollection } from '@src/types/Popups';
import { getUserConfig } from '@src/queries/users/api';
import { queryClient, QUERY_KEYS } from '@src/query';
import { DispatchType } from '../store';
import { api } from '../services/api';
import { REDUX_ACTIONS } from './_types';

export function windowResize(width: number, height: number) {
  return { type: REDUX_ACTIONS.COMMON.WINDOW_RESIZE, width, height };
}

export function windowFocus(focus: boolean) {
  return { type: REDUX_ACTIONS.COMMON.SET_WINDOW_FOCUS, focus };
}

export function mouseClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
  return { type: REDUX_ACTIONS.COMMON.MOUSE_CLICK, e };
}

export function selectMedia(media: MediaView) {
  return { type: REDUX_ACTIONS.COMMON.SELECT_MEDIA, media };
}

export function unselectMedia(media: MediaView) {
  return { type: REDUX_ACTIONS.COMMON.UNSELECT_MEDIA, media };
}

export function setFacebookPages(pages: IMentionPage[]) {
  return { type: REDUX_ACTIONS.COMMON.SET_FACEBOOK_PAGES, pages };
}

export function setPopup<T extends EPopup>(popup: T, val: CommonState['popups'][T]) {
  return { type: REDUX_ACTIONS.COMMON.SET_POPUP, popup, val };
}

export function setPopupLoading(loading: boolean) {
  return { type: REDUX_ACTIONS.COMMON.SET_POPUP_LOADING, loading };
}

export function promptConfirm(data: CommonState['popups']['promptconfirm']) {
  return setPopup(EPopup.promptconfirm, data);
}

export function setUserInfo(user: ApiTypes.ResGetMe['user']) {
  return { type: REDUX_ACTIONS.COMMON.SET_USER_INFO, user };
}

export function setAdminInfo(admin?: {
  _id: string
  username: string
}) {
  return { type: REDUX_ACTIONS.COMMON.SET_ADMIN_INFO, admin };
}

export function setConfig(config: ApiTypes.ResGetMe['config']) {
  return { type: REDUX_ACTIONS.COMMON.SET_CONFIG, config };
}

export function setOauth(social: SocialNetworkType, enabled: boolean) {
  return { type: REDUX_ACTIONS.COMMON.SET_OAUTH, social, enabled };
}

export function setUrlParam(key: string, value: unknown) {
  return { type: REDUX_ACTIONS.COMMON.SET_URL_PARAM, key, value };
}

export function addAlert(alert: Omit<IAlert, 'id'>) {
  return { type: REDUX_ACTIONS.COMMON.ADD_ALERT, alert };
}

export function removeAlert(id: IAlert['id']) {
  return { type: REDUX_ACTIONS.COMMON.REMOVE_ALERT, id };
}

export function setFacebookAdAccounts(adAccounts: FacebookAdsInterface[]) {
  return { type: REDUX_ACTIONS.COMMON.SET_FACEBOOK_AD_ACCOUNTS, adAccounts };
}

export function collectOverlay(step: 'add' | 'new', media?: AddToCollection) {
  return { type: REDUX_ACTIONS.COMMON.COLLECT_OVERLAY, step, media };
}

export function setCreatorCountriesOverlay(mediaList: MediaView[]) {
  return { type: REDUX_ACTIONS.COMMON.SET_CREATOR_COUNTRIES_OVERLAY, mediaList };
}

export function setGroupMentionPages(pages: MentionPageView[]) {
  return { type: REDUX_ACTIONS.COMMON.SET_GROUP_MENTION_PAGES, pages };
}

export function setGroupUsers(users: GroupUserView[]) {
  return { type: REDUX_ACTIONS.COMMON.SET_GROUP_MENTION_USERS, users };
}

/* Async */

export function loadFacebookPages() {
  return (dispatch: DispatchType) => api.getFacebookPages()
    .then((pages) => {
      dispatch(setFacebookPages(pages));
      return pages;
    }).catch((err: Error) => {
      if (err) {
        reduxHelpers.addAlert({ type: 'danger', text: 'common.settings.mention.error_getpages' });
        throw err;
      }
    });
}

export function loadFacebookAdAccounts() {
  return (dispatch: DispatchType) => api.getFacebookAdAccounts()
    .then((adAccounts) => {
      dispatch(setFacebookAdAccounts(adAccounts));
      return adAccounts;
    });
}

// TODO: Replace all uses of user, admin and config from redux to react-query
// and remove this function
export function loadUserInfo() {
  return (dispatch: DispatchType): Promise<ApiTypes.ResGetMe> => {
    queryClient.fetchQuery(QUERY_KEYS.userConfig());

    // backward compatibility
    return getUserConfig().then(({ user, admin, config }) => {
      dispatch(setAdminInfo(admin));
      dispatch(setConfig(config));
      if (!user) { throw new Error('missing user'); }
      dispatch(setUserInfo(user));

      return { user, admin, config };
    });
  };
}

export function removeOauth(social: SocialNetworkType) {
  return (dispatch: DispatchType) => new Promise((resolve, reject) => {
    api.removeOauth(social, (err) => {
      if (err) {
        return reject(err);
      }
      dispatch(setOauth(social, false));
      resolve(undefined);
    });
  });
}

export function loadGroupMentionPages() {
  return (dispatch: DispatchType) => api.getGroupMentionPages().then((pages) => {
    dispatch(setGroupMentionPages(pages));
    return pages;
  });
}

export function loadGroupUsers() {
  return (dispatch: ThunkDispatch<{}, {}, AnyAction>) => api.getGroupUsers().then((users) => {
    dispatch(setGroupUsers(users));
    return users;
  });
}
