import React from 'react';
import { classes, style } from 'typestyle';

interface Props {
  direction?: 'vertical' | 'horizontal'
  opened: boolean
  size?: number
}

const ICONS = {
  vertical: ['keyboard_arrow_down', 'keyboard_arrow_up'],
  horizontal: ['keyboard_arrow_right', 'keyboard_arrow_left'],
};

export function FolderButton({
  opened,
  direction = 'vertical',
  size = 24,
}: Props) {
  const i = opened ? 1 : 0;
  const icon = ICONS[direction][i];
  return (
    <i
      className={classes('material-icons', 'notranslate', iconClass)}
      style={{ fontSize: size }}
    >
      {icon}
    </i>
  );
}

const iconClass = style({
  cursor: 'pointer',
});
