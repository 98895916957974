import React from 'react';
import { SearchSuggestion } from '@src/types/Filters';
import { Suggestions } from '../Suggestions';
import { ProductItem } from '../FilterItem/ProductItem';
import { useMediaFindFacet } from '@src/queries/media/useQueryMediaFind';
import { useQueryProducts } from '@src/queries/products/useQueryProducts';
import { TYPING_DEBOUNCE } from '@src/pages/Discover/DiscoverController';
import { useRecoilState, useRecoilValue } from 'recoil';
import { FacetState, FilterState } from '@src/pages/Discover/components/Atoms/Atoms';
import { XclusionUtility } from '@src/fragments/Filters/managers/XclusionUtility';
import { onFilterChange } from '@src/fragments/Filters/utils/FilterStateUtility';
import { ArrayHelper } from '@src/services/helpers/array';
import {
  productExactExcludeState,
  productExactInput,
  productSimilarExcludeState,
  productSimilarInput,
} from '@src/pages/Discover/components/Selectors/Selectors';
import { ProductView } from 'common';

export function addProductNameToSuggestions(suggestions: SearchSuggestion[], resFilterProduct: ProductView[] = []): SearchSuggestion[] {
  const products = ArrayHelper.mapToObject(resFilterProduct, (p) => p.id);
  return suggestions.map((suggestion) => {
    const rawKey = XclusionUtility.getRawKey(suggestion.key);
    const product = products[rawKey];
    return {
      ...suggestion,
      display: `${rawKey} ${product?.title || ''}`,
    };
  });
}

export function ProductsSuggestion({ productType }: { productType: 'productExact' | 'productSimilar' }) {
  const [filters, setFilters] = useRecoilState(FilterState);
  const [facetState] = useRecoilState(FacetState);

  const matchesSearch = (display: string, filterInput: string): boolean =>
    filterInput === '' || display.toLowerCase().includes(filterInput.toLowerCase());

  const filterInput = useRecoilValue(productType === 'productExact' ? productExactInput : productSimilarInput);
  const filterExclude = useRecoilValue(productType === 'productExact' ? productExactExcludeState : productSimilarExcludeState);

  // Products
  const facetType = productType === 'productExact' ? 'productIdsExact' : 'productIdsSimilar';
  const { data: resFilterProductIds, isLoading: isLoadingFilterProduct } = useMediaFindFacet(
    facetState.mediaConstraints,
    '',
    facetType,
    TYPING_DEBOUNCE,
    facetState.enableFacet
  );

  const productIds = resFilterProductIds?.pages[0].facets?.[facetType] ?? [];
  const { data: resFilterProduct, isLoading: isLoadingAllProducts, error: filterProductError } = useQueryProducts(productIds || [], {
    enabled: !!productIds,
  });

  const productIdsValues = addProductNameToSuggestions(
    productIds.map((p) => ({ key: p, display: p })),
    resFilterProduct
  ).filter((s) => matchesSearch(s.display, filterInput));

  const selectedProductIdsValues = addProductNameToSuggestions(
    filters[productType].map((p) => ({ key: p, display: XclusionUtility.getRawKey(p) })),
    resFilterProduct
  );

  return (
    <Suggestions
      onChange={onFilterChange(setFilters, productType)}
      selectedValues={renderValues(selectedProductIdsValues)}
      values={renderValues(productIdsValues)}
      exclude={filterExclude}
    />
  );
}

function renderValues(suggestions: SearchSuggestion[]) {
  return suggestions.map((suggestion) => ({
    key: suggestion.key,
    children: <ProductItem suggestion={suggestion} />,
  }));
}
