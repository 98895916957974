import React from 'react';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import LocalizedStrings from 'react-localization';
import Slide from '@mui/material/Slide';
import { useRecoilState } from 'recoil';
import { AlertEvent, withAlert } from './alertAtom';

const AUTO_HIDE_DURATION = 6000;

/**
 * Display an error at a time at the top center of the screen
 */
export function AlertSnackbar() {
  const [alerts, setAlerts] = useRecoilState(withAlert);

  const currentAlert: AlertEvent | undefined = alerts[0];

  const onClose = (event: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }
    currentAlert?.close?.();
    setAlerts(alerts.slice(1));
  };

  return (
    <Snackbar
      style={{ top: 80 }}
      open={!!currentAlert}
      anchorOrigin={{ vertical: 'top', horizontal: 'center' }}
      autoHideDuration={AUTO_HIDE_DURATION}
      onClose={onClose}
      TransitionComponent={(props) => <Slide {...props} direction="down" />}
    >
      <MuiAlert
        onClose={onClose}
        elevation={6}
        variant="filled"
        severity={currentAlert?.severity || 'error'}
      >
        {currentAlert?.text}
      </MuiAlert>
    </Snackbar>
  );
}

export function getLangError(
  key?: string,
  errors?: { [key: string]: string | undefined },
): string | undefined {
  if (!key) {
    return undefined;
  }
  if (!errors?.[key]) {
    const commonError = (lang as unknown as { [key: string]: string | undefined })[key];
    if (commonError) {
      return commonError;
    }
    return errors?.unknown || lang.unknown;
  }
  return errors[key];
}

const lang = new LocalizedStrings({
  en: {
    auth: 'You cannot access to this resource',
    forbidden: 'You do not have permission to do this',
    notfound: 'Resource not found',
    role: 'Access denied - Unsufficcient rights',
    unknown: 'An error occured, please try later or contact us',
    missing_social: 'Please link your social accounts first',
  },
});
