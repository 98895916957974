import { useMutation } from 'react-query';
import { ApiError } from '@src/services/api';
import { MediaView } from 'common';
import { queryClient, QUERY_KEYS, QUERY_PREFIX } from '@src/query';
import { updateDiffusion } from './api';

export const useMutationDiffusion = () =>
  useMutation<MediaView, ApiError, Parameters<typeof updateDiffusion>[0]>(
    (partialMedia) => updateDiffusion(partialMedia),
    {
      onSuccess: async (res, partialMedia) => {
        const key = QUERY_KEYS.media(partialMedia._id);
        queryClient.invalidateQueries([QUERY_PREFIX.MEDIA_FIND]);
        queryClient.invalidateQueries([QUERY_PREFIX.COLLECTION_MEDIA]);
        queryClient.invalidateQueries(key);
      },
    }
  );
