import { AlertProps } from '@mui/material';
import { recoilKeys } from '@src/components/recoilKeys';
import { atom, useRecoilState } from 'recoil';

export interface AlertEvent {
  text?: string | string[]
  /**
   * Default to error
   */
  severity?: AlertProps['severity']
  /**
   * Callback to close the error
   * Let undefined to hide the close button
   */
  close?: () => void
}

type AlertState = AlertEvent[];

type AlertEventCreation = Omit<AlertEvent, 'id'>;

export const withAlert = atom<AlertState>({
  key: recoilKeys.alertState,
  default: [],
});

/**
 * Hook to simplify adding alerts
 */
export const useAddAlert = () => {
  const [, setAlerts] = useRecoilState(withAlert);
  return (alert: AlertEventCreation) => {
    setAlerts((alerts) => [...alerts, alert]);
  };
};
