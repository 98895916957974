import React, { useState } from 'react';

import { FacebookAdsInterface } from '@src/types/Business';
import { DropDown } from '../../../../../../components/DropDown';

interface IProps {
  disable: boolean
  adAccounts: FacebookAdsInterface[]
  selectedAdAccount?: FacebookAdsInterface
  title: string
  onTitleChange: (title: string) => void
  selectAdAccount: (arg0: any) => void
  missingSharePerm: boolean
}

export default function FacebookAds(props: IProps): JSX.Element | null {

  const [displayDropdown, setdisplayDropdown] = useState<boolean>(false);

  let adAccountSelectionTitle = window.T('share.popup.facebookads.subtitles.select_placeholder').toUpperCase();
  if (props.selectedAdAccount) {
    adAccountSelectionTitle = props.selectedAdAccount.name;
  }

  return (
    <div className="content" onClick={onFrameClick}>
      <div className={`line ${props.disable || props.missingSharePerm ? 'disabled-line' : ''}`}>
        <div className="title">{window.T('share.popup.facebookads.subtitles.select')}</div>
        <div className="field">
          <DropDown
            title={adAccountSelectionTitle}
            display={displayDropdown}
            onDisplay={setdisplayDropdown}
          >
            {props.adAccounts.map((adAccount, i) => (
              <div
                className="selection"
                key={i}
                data-selected={adAccount.id === props.selectedAdAccount?.id}
                onClick={(e) => selectAdAccount(e, adAccount)}
              >
                {adAccount.name}
              </div>
            ))}
          </DropDown>
        </div>
      </div>
      <div className="line">
        <div className="title">{window.T('share.popup.facebookads.subtitles.title')}</div>
        <div className="field">
          <input
            className="input"
            placeholder={window.T('share.popup.facebookads.subtitles.title_placeholder')}
            value={props.title}
            onChange={(e) => props.onTitleChange(e.target.value)}
          />
        </div>
      </div>
    </div>
  );
  function selectAdAccount(e: React.MouseEvent<HTMLDivElement, MouseEvent>, adaccount: FacebookAdsInterface) {
    e.stopPropagation();
    props.selectAdAccount(adaccount);
    setdisplayDropdown(false);
  }

  function onFrameClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    setdisplayDropdown(false);
  }
}
