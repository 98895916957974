import React, { ReactNode } from 'react';
import { ProductGroupView, ProductView } from 'common';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { ADALONG_COLORS } from '@src/styles';
import { ProductGroup, ProductGroupCustomRenderer } from './ProductGroup/ProductGroup';

interface Props extends ProductGroupCustomRenderer {
  productGroup: ProductGroupView;
  selectedProductIdsMap: { [key: string]: string };
  expanded: boolean;
  handleProductClick: (target: {
    productId: string | null;
    productGroup: ProductGroupView | null;
  }) => void;
}

/**
 * ProductGroup with selection features
 */
export function ProductGroupSelectable({
  productGroup,
  selectedProductIdsMap,
  expanded,
  handleProductClick,
  postDataElement,
  imageButtons,
}: Props) {
  const allSelectedProductIds =
    productGroup.products.find((p) => selectedProductIdsMap[p.id] === undefined) === undefined;

  return (
    <ProductGroup
      productGroup={productGroup}
      productCountFormatter={() => ''}
      expanded={expanded}
      customRenderer={{
        buttons: selectButton,
        postDataElement,
        imageButtons,
      }}
      // same behavior whatever button is clicked
      onExpandClick={() => handleProductClick({ productGroup, productId: null })}
      onProductClick={handleProductClick}
    />
  );

  function selectButton(product?: ProductView, isSingleProduct?: boolean): ReactNode {
    if (product) {
      const selectedProduct = selectedProductIdsMap[product.id];
      const selected: boolean = selectedProduct !== undefined;
      return (
        <VisibilityIcon
          fontSize='small'
          htmlColor={selected ? 'inherit' : ADALONG_COLORS.DARK_GRAY}
        />
      );
    }
    if (isSingleProduct) {
      // single product in product group
      return (
        <VisibilityIcon
          fontSize='small'
          htmlColor={allSelectedProductIds ? 'inherit' : ADALONG_COLORS.DARK_GRAY}
        />
      );
    }
    // Product groups with several products
    return null;
  }
}
