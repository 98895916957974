import { LoginState } from '@src/types/redux/login';
import * as ACTIONS from '../actions/_types';

export const initialLoginState: LoginState = {
  submitting: false,
  error: null,
  logged: undefined,
};

export type Ilogin = LoginState;

export type LoginAction = any;

export default function (state = initialLoginState, action: LoginAction): LoginState {
  window.analytics?.trackEvent(action.type, 'ReduxLogin');
  switch (action.type) {
    case ACTIONS.LOGIN_SUBMITTING:
      return {
        ...state, logout: false, submitting: action.submitting, error: null,
      };
    case ACTIONS.LOGIN_SUCCESS:
      return { ...state, logged: true, submitting: false };
    case ACTIONS.LOGOUT:
      return { ...state, submitting: false, logged: false };
    case ACTIONS.LOGIN_FAILED:
      return {
        ...state, error: action.error, submitting: false, logged: false,
      };
    case ACTIONS.LOGIN_FIELD_CHANGED:
      return { ...state, error: null };
  }
  return state;
}
