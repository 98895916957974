import React from 'react';

import { bigStore } from 'store';
import * as CommonActions from 'actions/common';
import * as reduxHelpers from '@src/services/helpers/redux';
import Draggable from 'components/Draggable';
import DeleteIcon from '@mui/icons-material/Delete';
import { IBrandContent } from 'common';
import { useMutationRemoveBrandContents } from '@src/queries/brandcontent/useMutationRemoveBrandContent';
import styles from './styles.scss';
import translation from './translation';
import { CollectionContentOverlay } from '../CollectionContentOverlay';

const lang = reduxHelpers.loadTranslation(translation);

interface Props {
  content: IBrandContent
}

export function BrandContent(props: Props) {
  const { mutate: removeBrandContent } = useMutationRemoveBrandContents();

  return (
    <Draggable
      id={props.content._id}
      className="dndVideoContent"
      style={{ backgroundImage: `url("${props.content.urls.thumbnail}")`, backgroundSize: 'cover', backgroundPosition: 'center' }}
      info={{
        origin: 'share',
        type: 'brandcontent',
        content: props.content,
        image: props.content.urls.thumbnail,
      }}
    >
      <CollectionContentOverlay
        classToApply=""
        iconToApply=""
      >
        <DeleteIcon className={styles.delete} onClick={onDelete} />
      </CollectionContentOverlay>
    </Draggable>
  );

  function onDelete() {
    bigStore.dispatch(CommonActions.promptConfirm({
      question: lang.delete,
      answer: async (yes) => {
        if (!yes) {
          return;
        }
        return removeBrandContent(props.content._id);
      },
    }));
  }
}
