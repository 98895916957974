import React from 'react';
import { IWidget } from 'common';
import { style } from 'typestyle';
import { SquareIcon } from '../../Icons/ThumbnailCornersIcons/Square';
import { RoundedIcon } from '../../Icons/ThumbnailCornersIcons/Rounded';

type ThumbnailCorners = IWidget['settings']['thumbnail_corners'];

interface Props {
  thumbnail_corners: ThumbnailCorners;
  onChange: (thumbnail_corners: ThumbnailCorners) => any;
}

export function ThumbnailCornersSelector({ thumbnail_corners, ...props }: Props) {
  const onChange = (thumbnail_corners: ThumbnailCorners) => {
    props.onChange(thumbnail_corners);
  };

  return (
    <div className={selectClass}>
      <div className={itemClass}>
        <SquareIcon onClick={() => onChange('square')} selected={thumbnail_corners === 'square'} />{' '}
        SQUARE{' '}
      </div>
      <div className={itemClass}>
        <RoundedIcon
          onClick={() => onChange('rounded')}
          selected={thumbnail_corners === 'rounded'}
        />{' '}
        ROUNDED{' '}
      </div>
    </div>
  );
  10;
}
const selectClass = style({
  marginTop: '10px',
  marginBottom: '10px',
  display: 'flex',
  flexDirection: 'row',
});

const itemClass = style({
  fontSize: '0.7em',
  display: 'flex',
  flexDirection: 'row',
  alignItems: 'center',
  marginRight: '10px',
});
