/*
** Redux Store
*/

import { createReduxHistoryContext } from 'redux-first-history';
import {
  applyMiddleware, combineReducers, compose, createStore, AnyAction, Middleware, CombinedState, Reducer,
} from 'redux';
import thunk from 'redux-thunk';
import { createBrowserHistory } from 'history';
import * as actionListener from './actionlistener';
import common from './reducers/common';
import discover from './reducers/discover';
import login, { initialLoginState } from './reducers/login';
import rightsrequests from './reducers/rightsrequests';
import share from './reducers/share';
import video from './reducers/video';
import { product } from './reducers/product';
import { media } from './reducers/media';
import { BROWSER } from './config';
import { ICombinedReducers, ReduxState } from './types/redux';
import * as ACTIONS from './actions/_types';

export const history = createBrowserHistory({
  basename: '/',
});

const {
  createReduxHistory,
  routerMiddleware,
  routerReducer,
} = createReduxHistoryContext({
  history: history,
});

const middleware = [
  thunk,
  actionListener.middleware as Middleware<{}, CombinedState<ICombinedReducers>, any>,
  routerMiddleware,
];

if (BROWSER) {
  const createLogger = require('redux-logger');
  const logger = createLogger({
    collapsed: true,
  });
  middleware.push(logger);
}

export const combinedReducers = combineReducers<ICombinedReducers>({
  login,
  common: common as Reducer,
  share,
  video,
  discover,
  rightsrequests,
  media,
  product,
  router: routerReducer,
});

export const rootReducer = function (state: ReduxState | undefined, action: AnyAction) {
  if (action.type === ACTIONS.LOGOUT) {
    if (state?.login?.logout) {
      state = {
        login: initialLoginState,
      } as ReduxState;
    }
  }

  return combinedReducers(state, action);
};

export const bigStore = createStore(
  rootReducer,
  compose(
    applyMiddleware(
      ...middleware,
    ),
  ),
);

export const reduxHistory = createReduxHistory(bigStore);

export type DispatchType = (dispatch: any) => any;
