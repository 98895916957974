import React from 'react';
import { Column, Row } from '@src/components/Utils';
import { useQueryCatalogTaxonomy } from '@src/queries/products/useQueryCatalogTaxonomy';
import { useQueryProductGroups, useQueryAllProductGroups } from '@src/queries/products/useQueryProductGroups';
import { ProductGroupLinkable } from '@src/components/Product/ProductGroupLinkable';
import { Loader } from '@src/components/Loader';
import { Modal } from '@src/components/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import { ProductGroupScroller } from '@src/components/Product/ProductGroupScroller';
import { classes, style } from 'typestyle';
import { ThemeStyle } from '@src/styles';
import LocalizedStrings from 'react-localization';
import { TaxonomySection } from '@src/components/Product/Taxonomy/TaxonomySection';
import { SearchAndSelectBar } from '@src/components/SearchAndSelectBar';
import { Hide } from '@src/components/Hide';
import { FormError } from '@src/components/Form/FormError';
import { BarelyVisibleText } from '@src/components/BarelyVisibleText';
import { ImageList, ImageListItem, Typography } from '@mui/material';
import { LinkedFilter, LinkedFilterValue } from '@src/components/Product/LinkedFilter';
import { MediaView, ProductGroupView } from 'common';
import { useProductLinking } from '@src/pages/Media/DetailedMediaView/Tabs/hooks/useProductLinking';
import { ArrayHelper } from '@src/services/helpers/array';
import { SectionTitle } from '@src/components/SectionTitle';
import { useSelector } from 'react-redux';
import { ICombinedReducers } from '@src/types/redux';
import { useObjectLabels } from '@src/pages/Media/DetailedMediaView/Tabs/hooks/useObjectLabels';

interface Props {
  media: MediaView;
  linkedFilter: LinkedFilterValue;
  selectedCategoryPath: string[];
  productSearchFilter: string;
  setLinkedFilter: (linkedFilter: LinkedFilterValue) => void;
  setSelectedCategoryPath: (selected: string[]) => void;
  setProductSearchFilter: (value: string) => void;
}

export const ProductTab = ({
  media,
  linkedFilter,
  selectedCategoryPath,
  productSearchFilter,
  setLinkedFilter,
  setSelectedCategoryPath,
  setProductSearchFilter,
}: Props) => {
  // Hooks
  const [objectLabels] = useObjectLabels(media);
  const { linkProducts, unlinkProducts, isLoading: isLoadingProductLinking } = useProductLinking(media);

  // Queries
  const { data: taxonomy, isLoading: isLoadingTaxonomy, error: errorTaxonomy } = useQueryCatalogTaxonomy();
  const filterByLinkedProducts = decideProductLinkedFilter({ objectLabels, linkedFilter });
  const productTypes = ArrayHelper.filterNulls([selectedCategoryPath?.join('/')]);
  const productIds = filterByLinkedProducts || undefined;
  // Try to give the best results if a user searches for a product
  // Otherwise just display the list alphabetically
  const sortOrder = productSearchFilter === '' ? 'name-asc' : 'relevance-desc';
  const {
    data: resProductGroups,
    isLoading: isLoadingProductGroups,
    error: errorProductGroups,
    fetchNextPage: fetchMoreProductGroups,
    hasNextPage: canFetchMoreProductGroups,
  } = useQueryProductGroups(
    {
      text: productSearchFilter,
      productTypes,
      productIds,
      sortOrder,
    },
    {
      enabled: filterByLinkedProducts !== false, // no products linked whereas filter is set to linked only
    }
  );
  const { data: resAllProductGroups, isLoading: isLoadingAllProductGroups, error: errorAllProductGroups } = useQueryAllProductGroups();

  const groupName: string = useSelector((state: ICombinedReducers) => state.common?.user?.group?.name ?? '');
  const productGroups: ProductGroupView[] = resProductGroups?.pages.flatMap((p) => p.productGroups) ?? [];
  const allGroups = resAllProductGroups ?? [];
  const productListAlone = errorTaxonomy !== null;

  return (
    <Row className={productTabClass}>
      <Hide hide={productListAlone}>
        <Column className={classes(taxonomyTabColumn)}>
          <SectionTitle size='small'>{lang.taxonomy.toUpperCase()}</SectionTitle>
          <TaxonomySection
            taxonomy={taxonomy}
            selectedCategoryPath={selectedCategoryPath}
            setSelectedCategoryPath={setSelectedCategoryPath}
            productGroups={allGroups}
          />
        </Column>
      </Hide>
      <div className={classes(productTabListColumn)} data-alone={productListAlone}>
        <Column className={searchClass}>
          <Row style={{ flexGrow: 0 }}>
            <SearchAndSelectBar
              className={productSearchBarClass}
              inputValue={productSearchFilter}
              handlers={{
                onText: setProductSearchFilter,
                onCloseBar: () => setProductSearchFilter(''),
              }}
            />
            <LinkedFilter value={linkedFilter} onChange={setLinkedFilter} />
          </Row>
          <ImageList cols={3}>
            <ImageListItem>
              {searchSuggestions(media.caption ?? '', groupName).length > 0 && <Typography variant='caption'>Suggestions</Typography>}
            </ImageListItem>
            {searchSuggestions(media.caption ?? '', groupName).map((s, i) => (
              <ImageListItem rows={1} cols={3} className={suggestionClass} key={i} onClick={() => setProductSearchFilter(s)}>
                <Typography
                  variant='subtitle2'
                  style={{
                    padding: '5px',
                    border: '1px solid',
                    borderRadius: '2px',
                    width: 'fit-content',
                  }}
                >
                  {s}
                </Typography>
              </ImageListItem>
            ))}
          </ImageList>
          <div className={infoMessageClass}>
            <FormError error={errorProductGroups?.message} />
            <Hide hide={!!productGroups?.length || isLoadingProductGroups || isLoadingAllProductGroups}>
              <BarelyVisibleText size='medium'>{lang.noProducts}</BarelyVisibleText>
              <Hide hide={linkedFilter === LinkedFilterValue.all}>
                <BarelyVisibleText size='medium'>{lang.noLinkedProductTips}</BarelyVisibleText>
              </Hide>
            </Hide>
            <Hide hide={!isLoadingProductGroups}>
              <Loader />
            </Hide>
          </div>
          <ProductGroupScroller noMore={!canFetchMoreProductGroups} fetchMore={() => fetchMoreProductGroups().then()}>
            {productGroups?.map((productGroup) => (
              <ProductGroupLinkable
                key={productGroup.name}
                productGroup={productGroup}
                productIdsExact={objectLabels.productIdsExact}
                productIdsSimilar={objectLabels.productIdsSimilar}
                linkProducts={linkProducts}
                unlinkProducts={unlinkProducts}
                disableLinking={false}
              />
            ))}
          </ProductGroupScroller>
        </Column>
      </div>
      {(isLoadingProductLinking || isLoadingTaxonomy) && (
        <Modal style={{ zIndex: 10 }}>
          <CircularProgress style={{ margin: 'auto' }} />
        </Modal>
      )}
    </Row>
  );
};

/**
 * Decide if it should filter by linked products depending on what labels are selected
 */
export const decideProductLinkedFilter = (params: {
  objectLabels: { productIdsExact: string[]; productIdsSimilar: string[] },
  linkedFilter: LinkedFilterValue
}) => {
  const { objectLabels, linkedFilter } = params;
  const allExactLabelProductIds = objectLabels.productIdsExact ?? [];
  const allSimilarLabelProductIds = objectLabels.productIdsSimilar ?? [];

  switch (linkedFilter) {
    case LinkedFilterValue.exact:
      if (allExactLabelProductIds.length === 0) {
        return false;
      }
      return allExactLabelProductIds;

    case LinkedFilterValue.similar:
      if (allSimilarLabelProductIds.length === 0) {
        return false;
      }
      return allSimilarLabelProductIds;

    case LinkedFilterValue.all:
      return [];
  }
}

/**
 * Function to suggest product searches.
 * @param caption
 * @param searchName Use to get sentences in which searchName is present
 *
 * The caption is split using '|', '\n', '.', ',' and ';' characters, then
 * filtered to consider only phrases smaller than 200 characters that
 * contains the groupName. Then emojis and empty-spaces are removed.
 */
export const searchSuggestions = (caption: string, searchName: string): string[] => {
  const searchValue = RegExp(`\\S*${searchName}\\S*`, 'ig');
  return (
    (caption || '')
      .split(/[\n|,\\.;]/)
      .filter((l) => l.length < 200 && RegExp(`(?<!#)${searchName}`, 'gi').exec(l))
      .map((l) =>
        l
          .replaceAll(searchValue, '')
          // Remove emoticon unicodes
          .replaceAll(RegExp('[^\u0020-\u007e\u00a0-\u00ff\u0152\u0153\u0178]', 'g'), '')
      )
      // Remove empty lines
      .filter((l) => l.length > 1)
  );
}

const lang = new LocalizedStrings({
  en: {
    taxonomy: 'Categories',
    description: 'Search for a product',
    noBoxes: 'No objects detected in this image',
    noProducts: 'No products found',
    noLinkedProductTips: 'Click on "All products" to see the catalog',
  },
});

const productTabClass = style({
  overflow: 'hidden',
  height: '100%',
  position: 'relative',
});

const taxonomyTabColumn = style({
  borderRight: ThemeStyle.borderSeparator,
  width: '40%',
  height: '100%',
  boxSizing: 'border-box',
  flexGrow: 'initial',
  padding: '10px 14px 0',
  position: 'relative',
});

const productTabListColumn = style({
  width: '60%',
  flexGrow: 'initial',
  position: 'relative',
  padding: '21px 22px 0',
  $nest: {
    '&[data-alone="true"]': {
      width: '100%',
    },
  },
});

const searchClass = style({
  height: '100%',
});

const productSearchBarClass = style({
  flex: 1,
  marginRight: 15,
});

const infoMessageClass = style({
  marginTop: 30,
  padding: 15,
  textAlign: 'center',
  $nest: {
    '&:empty': {
      display: 'none',
    },
  },
});

const suggestionClass = style({
  fontSize: '0.7em',
  $nest: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
});
