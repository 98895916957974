import React, { useEffect, useState } from 'react';
import { PerfectScrollbar } from '@src/components/PerfectScrollbar';
import { Modal } from '@src/components/Modal';
import { WindowSize, DetailedMediaViewTab, detailedMediaViewTabs } from '@src/types/redux/common';
import { Column, Row } from '@src/components/Utils';

import { MediaInfo } from '@src/components/MediaInfo';
import { IMediaService } from '@src/types/Media';
import { style, classes, stylesheet } from 'typestyle';
import { IPopupsService } from '@src/types/Popups';
import { MediaLittleData } from '@src/components/MediaInfo/MediaLittleData';
import { MediaUsername } from '@src/components/MediaInfo/MediaUsername';
import { MediaCaption } from '@src/components/MediaInfo/MediaCaption';
import { Overlay } from '@src/components/Overlay';
import LocalizedStrings from 'react-localization';
import { Services, ServiceName } from '@src/types/Services';
import { NETWORK_ICONS } from '@src/config/media';
import { PointBar } from '@src/components/PointBar';
import { useQueryMedia } from '@src/queries/media/useQueryMedia';
import { useQueryMediaCarousel } from '@src/queries/media/useQueryMediaCarousel';
import { useQueryMediaDimensions } from '@src/queries/media/useQueryMediaDimensions';
import { useQueryMediaSimilar } from '@src/queries/media/useQueryMediaSimilar';
import { useLocationQS } from '@src/utils/useLocationQS';
import { useQueryCollection } from '@src/queries/collections/useQueryCollection';
import { useSelector } from 'react-redux';
import { ICombinedReducers } from '@src/types/redux';
import { useWindowSize } from '@src/utils/hooks/useWindowSize';
import { ThemeStyle } from '@src/styles';
import { MediaType, RightRequestMQLStatus } from '@adalong/schemas';
import { ArrayHelper } from '@src/services/helpers/array';
import { ProductsBadge } from '@src/components/Product/ProductsBadge';
import { LinkedFilterValue } from '@src/components/Product/LinkedFilter';
import { Browsing, QsNames } from '@src/types/Browsing';
import { ShortcutActionRow } from '@src/components/Media/ShortcutActionRow';
import { GetRightsButton } from '@src/components/IPRights/GetRightsButton';
import { Hide } from '@src/components/Hide';
import { MediaActionButtonController } from '@src/fragments/MediaActionButton/MediaActionController';
import { Loader } from '@src/components/Loader';
import { ModalContainer } from '@src/components/ModalContainer';
import { IPRightsStatus } from '@src/components/IPRights/IPRightsStatus';
import { StringHelper } from '@src/services/helpers/string';
import { lastMediaLibraryQuery } from '../../../queries/media/useQueryMediaFind';
import { goToMedia, goToCloseMedia, goToGetRights } from '../utils/mediaRoute';
import { CarouselButtons } from './Components/CarouselButtons';
import { SimilarMediaTab } from './Tabs/SimilarMediasTab';
import { contentFrameClass } from './Tabs/styles';
import { InfoTab } from './Tabs/InfoTab';
import { ProductTab } from './Tabs/ProductTab';
import { Container } from './Container';
import { DetailTabs } from './DetailTabs';
import { MediaContentView } from '../../../components/Media/MediaViewer';

interface Props {
  collectionId?: string;
  currentPathname: string;
  services: Services;
}

export function DetailedMediaView({ collectionId, services, currentPathname }: Props) {
  let tab: DetailedMediaViewTab = 'info';
  const {
    [QsNames.detailedMediaViewTab]: [tabString],
    [QsNames.detailedMediaView]: [mediaId],
  } = useLocationQS([QsNames.detailedMediaViewTab, QsNames.detailedMediaView]);

  const [{ popupsService, mediaService, browsingService }] = useState(() => ({
    popupsService: services.get<IPopupsService>(ServiceName.Popups),
    mediaService: services.get<IMediaService>(ServiceName.MediaService),
    browsingService: services.get<Browsing>(ServiceName.Browsing),
  }));
  const windowSize = useWindowSize();
  const [contentWidth, setContentWidth] = useState<null | number>(null);
  const [productLinkedFilter, setProductLinkedFilter] = useState<LinkedFilterValue>(LinkedFilterValue.all);
  const [selectedProductCategoryPath, setProductSelectedCategoryPath] = useState<string[]>([]);
  const [productSearchFilter, setProductSearchFilter] = useState('');
  const [numberOfLinkedProducts, setNumberOfLinkedProducts] = useState(0);

  const user = useSelector((state: ICombinedReducers) => state.common.user);
  const { data: media } = useQueryMedia(mediaId, {
    keepPreviousData: true,
    enabled: mediaId !== undefined,
  });
  const { data: collection } = useQueryCollection(collectionId || '', false, undefined, {
    enabled: collectionId !== undefined,
  });
  const { data: carouselMedias } = useQueryMediaCarousel(mediaId);
  const isText = media?.type === MediaType.Text;

  const inDiscover = currentPathname.indexOf('/discover') === 0;
  // last query done in discover to use with similar media in that page only
  let stringifiedQuery: string | undefined;
  if (inDiscover && lastMediaLibraryQuery) {
    stringifiedQuery = JSON.stringify(lastMediaLibraryQuery);
  }

  const { data: similars, isLoading: isLoadingSimilars } = useQueryMediaSimilar(mediaId, 'labels', stringifiedQuery, { enabled: media && !isText });
  const { data: mediaDimensions = media?.dimensions } = useQueryMediaDimensions(media, {
    enabled: media && !isText,
  });

  tab =
    detailedMediaViewTabs.find(
      (t): t is DetailedMediaViewTab => t === tabString // confirm that tab is an existing tab
    ) || (user && !isText ? 'similars' : 'info');

  const isVertical = /\/(reel|tv)\//.test(media?.url?.post || '');
  const isReel = /\/reel\//.test(media?.url?.post || '');
  const instagramHack =
    media?.type === 'video' && media?.source.name === 'instagram' && (media?.carousel_index === undefined || media?.carousel_index === 0)
      ? media?.code
      : undefined;

  const displayOverlayOnHover = tab !== 'product';
  const maxSize = getMaxSize(windowSize);
  const displayCarousel: boolean = carouselMedias !== undefined && carouselMedias.length > 1;
  const groupName: string = useSelector((state: ICombinedReducers) => state.common?.user?.group?.name ?? '');
  const onClose = () => {
    browsingService.goToPage(goToCloseMedia(window.location));
  };

  const onOpenTab = (newTab: DetailedMediaViewTab) => {
    browsingService.goToPage(goToMedia(window.location, mediaId, newTab));
  };

  const openMedia = (mediaId: string) => {
    browsingService.goToPage(goToMedia(window.location, mediaId));
  };

  useEffect(() => {
    setNumberOfLinkedProducts(
      media?.productIdsExact ? ArrayHelper.uniqBy(media.productIdsExact).length : 0
    )
  }, [media?.productIdsExact]);

  // Display a spinner while the media is loading
  if (media === undefined) {
    return (
      <Modal onClose={onClose}>
        <ModalContainer className={sheet.mediaLoading}>
          <Loader />
        </ModalContainer>
      </Modal>
    );
  }

  return (
    <Modal onClose={onClose}>
      <Container>
        <Row style={{ flexGrow: 0, maxHeight: '100%', height: maxSize.height }}>
          <Column className={leftColumnClass}>
            <MediaContentView
              media={{
                _id: media._id,
                caption: media.caption,
                type: media.type,
                image_large: media.cdn_image,
                video: media.cdn_video,
                dimensions: media.dimensions || mediaDimensions,
                instagramHack,
                isVertical,
                isReel,
              }}
              minWidth={400}
              maxWidth={maxSize.leftWidth}
              onContentWidth={setContentWidth}
              overlay={
                tab === 'product' ? (
                  <Overlay>
                    <ProductsBadge
                      total={numberOfLinkedProducts}
                      className={productsBadgeClass}
                      onClick={onProductBadgetClick}
                      title={lang.productBadgeTooltip}
                    />
                  </Overlay>
                ) : (
                  user && (
                    <>
                      <CarouselButtons
                        carouselMedias={carouselMedias}
                        currentId={media._id}
                        displayOnHover={displayOverlayOnHover}
                        openMedia={(media) => openMedia(media._id)}
                      />
                      <ShortcutActionRow>
                        {/* Display get rights only if there're no right request done */}
                        <Hide hide={media.rightsRequestStatus !== RightRequestMQLStatus.Unrequested}>
                          <GetRightsButton onAction={() => browsingService.goToPage(goToGetRights(window.location, media._id))} size='small' />
                        </Hide>
                      </ShortcutActionRow>
                    </>
                  )
                )
              }
              overlayConfig={{ displayOnHover: displayOverlayOnHover }}
            />
            <div>
              <Hide hide={!displayCarousel}>
                <PointBar className={selectorClass} elms={carouselMedias} currentId={media._id} handleChange={(media) => openMedia(media._id)} />
              </Hide>
              <PerfectScrollbar
                className={classes(contentFrameClass, mediaInfoLeftClass)}
                style={{
                  maxWidth: contentWidth,
                  paddingTop: displayCarousel ? 0 : 10,
                }}
              >
                <MediaInfo
                  network={media.source.name as keyof typeof NETWORK_ICONS}
                  link={media.url.post}
                  titleLine={
                    <Column>
                      <MediaUsername username={media.source.user_name} />
                      <IPRightsStatus status={media.rightsRequestStatus} format={(s) => StringHelper.upperFirst(s)} />
                    </Column>
                  }
                  actionButtons={
                    <MediaActionButtonController
                      inCollection={collection}
                      mediaList={[media]}
                      services={{
                        browsing: browsingService,
                        popups: popupsService,
                      }}
                      // Do not display button to open product linking
                      // if we already have this tab opened
                      linkProduct={tab !== 'product'}
                      catalogCountries={user?.catalogCountries}
                    />
                  }
                  littleDataLine={
                    <MediaLittleData location={media.info?.location?.name} likes={media.info?.social?.likes} date={media.published_at} />
                  }
                  descriptionLine={
                    media.type !== MediaType.Text ? (
                      <MediaCaption text={media.caption} groupName={tab === 'product' ? groupName : undefined} />
                    ) : (
                      undefined
                    )
                  }
                />
              </PerfectScrollbar>
            </div>
          </Column>
          <Column className={rightColumnClass} style={{ width: maxSize.rightWidth }}>
            <DetailTabs
              active={tab}
              onClose={onClose}
              disable={{
                product: !user?.groupFeatures.product_catalog
                  ? { text: lang.premiumFeatureWarning }
                  : media.type === 'text'
                  ? { text: lang.productImageOnly }
                  : undefined,
                similars: !user ? { hide: true } : undefined,
              }}
              onSelectTab={onOpenTab}
              tabs={{
                info: <InfoTab onClose={onClose} media={media} mediaService={mediaService} user={user} browsingService={browsingService} />,
                product: (
                  <ProductTab
                    media={media}
                    linkedFilter={productLinkedFilter}
                    selectedCategoryPath={selectedProductCategoryPath}
                    productSearchFilter={productSearchFilter}
                    setLinkedFilter={setProductLinkedFilter}
                    setSelectedCategoryPath={setProductSelectedCategoryPath}
                    setProductSearchFilter={setProductSearchFilter}
                  />
                ),
                similars: (
                  <SimilarMediaTab
                    similars={similars}
                    isLoading={isLoadingSimilars}
                    services={{ browsing: browsingService }}
                    renderMediaActionButton={(mediaList) => (
                      <MediaActionButtonController
                        inCollection={undefined}
                        mediaList={mediaList}
                        services={{
                          browsing: browsingService,
                          popups: popupsService,
                        }}
                        catalogCountries={user?.catalogCountries}
                      />
                    )}
                  />
                ),
              }}
            />
          </Column>
        </Row>
      </Container>
    </Modal>
  );

  function onProductBadgetClick() {
    // see all linked products
    setProductLinkedFilter(LinkedFilterValue.exact);
    setProductSelectedCategoryPath([]);
    setProductSearchFilter('');
  }
}

interface DetailedViewSize {
  leftWidth: number;
  rightWidth: number;
  height: number;
}
function getMaxSize(windowSize: WindowSize): DetailedViewSize {
  const MARGIN_WIDTH = 50;
  const MARGIN_HEIGHT = 100;

  const size: DetailedViewSize = {
    leftWidth: 400,
    rightWidth: 400,
    height: 600,
  };
  if (windowSize.width > 1000) {
    const halfWidth = (windowSize.width - MARGIN_WIDTH) / 2;
    size.leftWidth = halfWidth;
    size.rightWidth = halfWidth;
  }
  if (windowSize.height > 800) {
    size.height = windowSize.height - MARGIN_HEIGHT;
  }
  return size;
}

const lang = new LocalizedStrings({
  en: {
    productImageOnly: 'The product feature is available for images only',
    premiumFeatureWarning: 'This is a premium feature. Please contact us for more information.',
    productBadgeTooltip: 'Click to see all exact linked products',
  },
});

const sheet = stylesheet({
  mediaLoading: {
    overflow: 'hidden',
    margin: 'auto',
    padding: '10px 20px',
    justifyContent: 'center',
    backgroundColor: 'transparent',
  },
});

const leftColumnClass = style({
  maxHeight: '100%',
  borderRight: ThemeStyle.borderSeparator,
});

const rightColumnClass = style({
  flexShrink: 0,
});

const mediaInfoLeftClass = style({
  maxHeight: 300,
  overflow: 'hidden',
});

const selectorClass = style({
  height: 35,
});

const productsBadgeClass = style({
  position: 'absolute',
  top: 20,
  right: 30,
  zIndex: 99999,
});
