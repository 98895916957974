/**
 * Serializer for user friendly query string
 * Provide baseQs to concatenate to an already existing query string
 */
export class UrlQSSerializer {
  public static serialize(obj: Record<string, any>, baseQs?: URLSearchParams): string {
    const searchParams = baseQs || new URLSearchParams();
    if (!obj) {
      return '';
    }
    Object.entries(obj).forEach(([p, val]) => {
      const values: string[] = this.valToStrings(val);
      const stringVal: string = values.map(
        (v) => encodeURIComponent(v),
      ).join(',');
      if (stringVal) {
        searchParams.set(encodeURIComponent(p), stringVal);
      }
    });
    return searchParams.toString();
  }

  public static deserialize(qs: string): { [key: string]: string[] } {
    const obj: any = {};
    const params = qs.split('&');
    params.forEach((str) => {
      const [key, value] = str.split('=');
      if (!key || !value) {
        return;
      }
      const values = value.split('%2C').map((v) => decodeURIComponent(v));
      const objectkey = decodeURIComponent(key);
      obj[objectkey] = values;
    });
    return obj;
  }

  private static valToStrings(val: any | any[]): string[] {
    const values = Array.isArray(val) ? val : [val];
    const strings: string[] = [];
    values.forEach((v) => {
      if (typeof v === 'undefined') {
        return;
      }
      v = String(v);
      if (v === '[object Object]') {
        return;
      }
      strings.push(v);
    });
    return strings;
  }
}
