import {
  SearchMarkDef, SuggestionsDef, SEARCH_MARK_TYPES, KeyCode,
} from '../../../types/Search';
import { markEqual } from '../utils/markEqual';

export class SuggestionsMove {
  public constructor(private suggestions: SuggestionsDef) { }

  public move(code: KeyCode, currentlyHighligthed: SearchMarkDef): SearchMarkDef | null {
    const rowLength = Object.values(this.suggestions).reduce((max, sugg) => Math.max(max, sugg.length), 0);

    const flattenMarks = SEARCH_MARK_TYPES.reduce<(SearchMarkDef | null)[]>((arr, k) => {
      const suggs = this.suggestions[k];
      return [
        ...arr,
        ...suggs,
        ...Array(rowLength - suggs.length).fill(null),
      ];
    }, []);

    const index = flattenMarks.findIndex((m) => (m ? markEqual(m, currentlyHighligthed) : false));
    if (index === -1) {
      console.warn('Suggestion not found');
      return null;
    }

    let move = 0;
    let dir = 1;

    switch (code) {
      case KeyCode.ArrowDown:
        dir = 1;
        move = 1;
        break;
      case KeyCode.ArrowUp:
        dir = -1;
        move = -1;
        break;
      case KeyCode.ArrowLeft:
      case KeyCode.ArrowRight:
        dir = code === KeyCode.ArrowLeft ? -1 : 1;
        move = rowLength * dir;
        break;
      default:
        break;
    }

    let [highlighted, newIndex] = this.getCircularElement(flattenMarks, index, move);
    while (highlighted === null) {
      [highlighted, newIndex] = this.getCircularElement(flattenMarks, newIndex, dir);
    }

    return highlighted;
  }

  private getCircularElement<T>(arr: T[], index: number, move: number): [T, number] {
    const { length } = arr;
    const finalIndex = (index + move + length) % length;
    return [
      arr[finalIndex],
      finalIndex,
    ];
  }
}
