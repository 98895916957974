import { Props as FloatingMenuProps } from '@src/components/FloatingMenu';
import { MoreButton } from '@src/components/MoreButton';
import React from 'react';
import ReactLocalizedString from 'react-localization';

export interface Props {
  loading: boolean
  list: FloatingMenuProps['list']
}

/**
 * The more button for all the media actions
 * Open a floating menu on click with all the required actions
 * to act on one or more media
 */
export function MediaActionButton(props: Props) {
  return (
    <MoreButton menuList={props.list} loading={props.loading} />
  );
}

export const lang = new ReactLocalizedString({
  en: {
    addToCollection: 'Collect',
    removeFromCollection: 'Remove from collection',
    getRights: 'Get rights',
    resetRights: 'Reset rights',
    setRightsAsAgreed: 'Set rights as agreed',
    linkProduct: 'Link products',
    unlinkProduct: 'Unlink the product',
    download: 'Download content',
    setCreatorCountries: 'Set creator countries',
  },
});
