import { IVideoMakerService, InfoInsert, SizeImage } from '@src/types/VideoMaker';
import { ServiceName, Services } from '@src/types/Services';
import { VideoElementView, VideoMakerView, VideoMakerDocUpdate } from 'common';
import { ServiceStore } from '@src/types/redux';
import { IVideo } from '@src/reducers/video';
import { VIDEO_ACTIONS } from '@src/actions/video';
import { Api } from '@src/types/api';
import { GlobalError } from '@src/types/GlobalError';
import { ImageResolverResponse } from '@src/types/Image';
import { VideoState } from '@src/types/redux/video';
import { ImageResolver } from './helpers/imageResolver';

const LOADING_START = 10;
const LOADING_EDIT_GET_PERCENT = 40;

export class VideoMakerService implements IVideoMakerService {
  private apiService: Api;

  private globalError: GlobalError;

  public constructor(
    services: Services,
    private store: ServiceStore,
  ) {
    this.apiService = services.get<Api>(ServiceName.Api);
    this.globalError = services.get<GlobalError>(ServiceName.GlobalError);
  }

  /**
     * Check that a media are available and set result in redux
     */
  public async resolveImages(
    elements: VideoElementView[],
    onLoaded?: (element: VideoElementView) => void,
  ): Promise<void> {
    const { video: videoState } = this.store.getState();
    const resolvedUrls: IVideo['resolvedImages'] = { ...videoState.resolvedImages };
    await Promise.all(elements.map(async (element) => {
      const { image_url } = element;
      if (image_url && !resolvedUrls[image_url]) {
        let image: ImageResolverResponse | null = null;
        if (image_url) {
          image = await (
            ImageResolver.load(image_url).then((elm) => elm).catch(() => null)
          );
        }
        resolvedUrls[image_url] = image;
      }
      onLoaded?.(element);
    }));
    this.store.dispatch(VIDEO_ACTIONS.setResolvedImages(resolvedUrls));
  }

  /**
     * Dispatch editVideoMaker event to open editor and set the current video maker
     */
  public async setEditVideoMaker(videoMaker: VideoMakerView) {
    this.store.dispatch(VIDEO_ACTIONS.editVideoMaker(videoMaker));
    this.resolveImages(videoMaker.elements);
  }

  public async loadVideoMakers(): Promise<VideoMakerView[]> {
    this.store.dispatch(VIDEO_ACTIONS.setLoading(true));
    const videoMakers = await this.apiService.getVideoMakers() || [];
    this.store.dispatch(VIDEO_ACTIONS.setVideoMakerList(videoMakers));
    this.store.dispatch(VIDEO_ACTIONS.setLoading(false));
    return videoMakers;
  }

  public async loadAndSetVideoMaker(videoMakerId: string): Promise<void> {
    let loadingValue = LOADING_START;

    this.setLoadingEdit(loadingValue);
    const videoMaker = await this.apiService.getVideoMakerId({ videoMakerId }).catch((err) => {
      this.globalError.displayTypeError(err.type);
    });
    this.setLoadingEdit(loadingValue = LOADING_EDIT_GET_PERCENT);

    if (videoMaker) {
      this.setEditVideoMaker(videoMaker);

      const p = (100 - loadingValue) / (videoMaker.elements.length);
      const increaseLoader = () => this.setLoadingEdit(loadingValue += p);
      await this.resolveImages(videoMaker.elements, increaseLoader);
    }
    this.setLoadingEdit(100);
  }

  public async createVideoMaker(name?: string, description?: string): Promise<VideoMakerView> {
    const { common: { user } } = this.store.getState();
    if (!user) {
      throw Error('missing user to create video maker');
    }
    this.store.dispatch(VIDEO_ACTIONS.setLoading(true));
    const newVideoMaker: VideoMakerView = {
      _id: undefined as any as string, // Needed for a new media
      description: description || 'description...',
      duration: 3,
      elements: [],
      name: name || 'New video',
      owner: user,
      ratio: 1,
      rendered: false,
      storedUrl: '',
      width: 1080,
    };

    await this.apiService.updateVideoMaker({
      videoMaker: VideoMakerViewToVideoMakerDocUpdate(newVideoMaker),
    });
    this.store.dispatch(VIDEO_ACTIONS.setLoading(false));

    this.loadVideoMakers();
    return newVideoMaker;
  }

  public async removeVideoMaker(videoMakerId: string): Promise<void> {
    this.store.dispatch(VIDEO_ACTIONS.setLoading(true));
    await this.apiService.deleteVideoMaker({ videoMakerId }).catch((err) => {
      this.globalError.displayTypeError(err.type);
    });
    this.store.dispatch(VIDEO_ACTIONS.setLoading(false));
    await this.loadVideoMakers();
  }

  public async changePage(page: VideoState['page']) {
    this.store.dispatch(VIDEO_ACTIONS.changePage(page));
  }

  public async processVideoMaker(vm: VideoMakerView): Promise<void> {
    return this.apiService.processVideoMaker({ videoMaker: VideoMakerViewToVideoMakerDocUpdate(vm) });
  }

  public async update(videoMaker: VideoMakerView): Promise<void> {
    await this.apiService.updateVideoMaker({
      videoMaker: VideoMakerViewToVideoMakerDocUpdate(videoMaker),
    });
  }

  public async onChangeDuration(duration: number): Promise<VideoMakerView> {
    const videoMaker: VideoMakerView = {
      ...this.getEditedVideoMaker(),
      duration,
    };
    this.store.dispatch(VIDEO_ACTIONS.updateGif(videoMaker));
    await this.update(videoMaker);
    return videoMaker;
  }

  public async onChangeRatio(ratio: number): Promise<VideoMakerView> {
    const videoMaker: VideoMakerView = {
      ...this.getEditedVideoMaker(),
      ratio,
    };
    this.store.dispatch(VIDEO_ACTIONS.updateGif(videoMaker));
    await this.update(videoMaker);
    return videoMaker;
  }

  public async onSetVideoCollection(
    elements: VideoElementView[],
    idx = -1,
  ): Promise<VideoMakerView> {
    const { video: videoState } = this.store.getState();
    const currentIndex = Math.min(
      idx >= 0 ? idx : videoState.current_index,
      elements.length - 1,
    );
    const videoMaker = {
      ...this.getEditedVideoMaker(),
      elements,
    };
    this.store.dispatch(VIDEO_ACTIONS.updateGif(videoMaker));
    this.store.dispatch(VIDEO_ACTIONS.selectImage(currentIndex));

    await this.update(videoMaker);
    return videoMaker;
  }

  public async onUpdateParams(params: { description?: string; name?: string }): Promise<VideoMakerView> {
    const videoMaker = { ...this.getEditedVideoMaker() };
    if (typeof params.name === 'string') {
      videoMaker.name = params.name;
    }
    if (typeof params.description === 'string') {
      videoMaker.description = params.description;
    }
    this.store.dispatch(VIDEO_ACTIONS.updateGif(videoMaker));
    await this.update(videoMaker);
    return videoMaker;
  }

  public async onResizeImage(size: SizeImage): Promise<VideoMakerView> {
    const { video: videoState } = this.store.getState();
    const videoMaker = { ...this.getEditedVideoMaker() };
    videoMaker.elements = [...videoMaker.elements];
    const newImage = {
      ...videoMaker.elements[videoState.current_index],
      ...size,
    };

    videoMaker.elements.splice(videoState.current_index, 1, newImage);
    this.store.dispatch(VIDEO_ACTIONS.updateGif(videoMaker));
    return videoMaker;
  }

  public async onDuplicateCurrentFrame(frameIndex: number): Promise<VideoMakerView> {
    const videoMaker = { ...this.getEditedVideoMaker() };
    videoMaker.elements = [...videoMaker.elements];

    videoMaker.elements.splice(frameIndex, 0, videoMaker.elements[frameIndex]);
    this.store.dispatch(VIDEO_ACTIONS.selectImage(frameIndex + 1));
    this.store.dispatch(VIDEO_ACTIONS.updateGif(videoMaker));
    await this.update(videoMaker);
    return videoMaker;
  }

  public async onInsertItem(info: InfoInsert, position: number): Promise<void> {
    const videoMaker = { ...this.getEditedVideoMaker() };
    videoMaker.elements = [...videoMaker.elements];
    if (!videoMaker.elements) {
      return;
    }

    // Update locally
    if (info.content) {
      videoMaker.elements.splice(position, 0, {
        media: !('file' in info.content) ? info.content : undefined,
        brandcontent: 'file' in info.content ? info.content : undefined,
        image_url: info.image,
        x: 0,
        y: 0,
        width: undefined,
        height: undefined,
        rot: 0,
      });
    }
    this.store.dispatch(VIDEO_ACTIONS.updateGif(videoMaker));
    this.store.dispatch(VIDEO_ACTIONS.selectImage(position));
    this.resolveImages(videoMaker.elements);
    await this.update(videoMaker);
  }

  public async onMoveItem(itemIndex: number, newPosition: number): Promise<void> {
    const videoMaker = { ...this.getEditedVideoMaker() };
    videoMaker.elements = [...videoMaker.elements];
    if (!videoMaker.elements) {
      return;
    }

    const [item] = videoMaker.elements.splice(itemIndex, 1);
    if (!item.media && !item.brandcontent) {
      return;
    }
    videoMaker.elements.splice(newPosition, 0, item);

    this.store.dispatch(VIDEO_ACTIONS.updateGif(videoMaker));
    this.store.dispatch(VIDEO_ACTIONS.selectImage(newPosition));
    await this.update(videoMaker);
  }

  public async onRemoveItem(itemIndex: number): Promise<void> {
    const videoMaker = { ...this.getEditedVideoMaker() };
    videoMaker.elements = [...videoMaker.elements];
    if (!videoMaker.elements) {
      return;
    }

    videoMaker.elements.splice(itemIndex, 1);

    this.store.dispatch(VIDEO_ACTIONS.updateGif(videoMaker));
    await this.update(videoMaker);
  }

  private getEditedVideoMaker(): VideoMakerView {
    const { video } = this.store.getState();
    return video.gif;
  }

  private setLoadingEdit(value: number) {
    this.store.dispatch(VIDEO_ACTIONS.setLoadingEdit(value));
  }
}

function VideoMakerViewToVideoMakerDocUpdate(videoMaker: VideoMakerView): VideoMakerDocUpdate {
  return {
    ...videoMaker,
    owner: videoMaker.owner._id,
    storedUrl: videoMaker.storedUrl || '',
    name: videoMaker.name || 'Unnamed',
    elements: videoMaker.elements.map((elem) => ({
      ...elem,
      media: elem.media?._id,
      brandContent: elem.brandcontent?._id,
    })),
  };
}
