type s = React.CSSProperties;

export const AdalongStyles = {
  DND: {
    thumbnail: {
      width: '100%',
      height: '100%',
      backgroundSize: 'cover',
      backgroundPosition: 'center',
      backgroundColor: '#282828',
    } as s,
    container: {
      position: 'absolute',
      top: 0,
      left: 0,
      bottom: 0,
      right: 0,
    } as s,
    delete: {
      position: 'absolute',
      right: 5,
      bottom: 5,
      color: '#8a8989',
      fontSize: 22,
      cursor: 'pointer',
      transition: 'color 0.2s',
      '&:hover': {
        color: 'white',
      },
    } as s,
    item: {
      height: 0,
      width: '100%',
      paddingBottom: '100%',
      position: 'relative',
    } as s,
  },
};

export const ANALYZE_COLORS = {
  YELLOW_PALE: '#F9FCEE',
  GREEN: '#BFFDDF',
  BLUE: '#0005DC',
  ROSE: '#F500FF',
  YELLOW: '#E3FF73',
  GREEN_DARK: '#797A74',
  BLUE_DARK: '#00055B',
  ROSE_DARK: '#76007F',
  YELLOW_DARK: '#727F3B',
  LIGHTER: '#F7F7F7',
  GREEN_LIGHTER: '#D9F4E4',
  BLUE_LIGHTER: '#6E73DB',
  ROSE_LIGHTER: '#F680FF',
  YELLOW_LIGHTER: '#EAF9AF',
};

export const ADALONG_COLORS = {
  FLASH_RED: '#e62117',
  FLASH_BLUE: '#0005DC',
  FB_BLUE: '#3b5998',
  TWITTER_BLUE: '#1DA1F2',

  FLASH_PURPLE: '#8f4dfa',
  FLASH_PINK: '#f50b88',
  INSTAGRAM_PURPLE: '#833AB4',

  FLASH_GREEN: '#63c739',
  GREEN: '#228a2a',

  SMOOTH_WHITE: '#e0e0e0',
  LIGHT_WHITE: '#ffffff',

  DARKER_GRAY: '#343434',
  DARK_GRAY: '#4b4c4c',
  LIGHT_GRAY: '#909191',

  LIGHT_BLACK: '#323232',
  SMOOTH_BLACK: '#1b1b1b',
  DARK_BLACK: '#131415',
  ABSOLUTE_BLACK: '#000000',

  YELLOW: '#ffb600',
  LIGHT_YELLOW: '#e3fc73',
};

export const ThemeStyle = {
  background: {
    main: '#151515',
  },
  fonts: {
    primary: '"Public Sans", sans-serif',
  },
  button: {
    color: '#bbbbbb',
    hoveredColor: 'white',
    size: 15,
  },
  container: {
    color: ADALONG_COLORS.SMOOTH_BLACK,
  },
  borderSeparator: `4px solid ${ADALONG_COLORS.DARK_GRAY}`,
  ipRightsStatus: {
    colors: {
      requested: '#0c81de',
      agreed: '#5cb85c',
      unrequested: '#3f3f3f',
    },
  },
  textSizes: {
    tiny: 10,
    small: 12,
    medium: 14,
    large: 16,
    big: 18,
  },
};

export type TTitleSizes = keyof typeof ThemeStyle.textSizes;
