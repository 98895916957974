import React, { Fragment } from 'react';
import { style } from 'typestyle';
import { ADALONG_COLORS } from '@src/styles';
import { DotSeparator } from './DotSeparator';

export interface Props {
  data: (string | JSX.Element)[]
}

export function LittleDataDescription({
  data,
}: Props) {
  const last = data.length - 1;

  return (
    <div className={littleDataContainerClass}>
      {data.map((d, i) => (
        <Fragment key={i}>
          {LittleData(d, i)}
          {i < last && <DotSeparator />}
        </Fragment>
      ))}
    </div>
  );
}

function LittleData(val: string | JSX.Element, key: number) {
  return (
    <div className={littleDataClass} key={key}>
      {val}
    </div>
  );
}

const littleDataContainerClass = style({
  color: ADALONG_COLORS.LIGHT_GRAY,
});

const littleDataClass = style({
  display: 'inline-block',
});
