import React, { Component, ReactNode } from 'react';
import Slider from '@mui/material/Slider';
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import { VideoMakerHandlers } from '@src/types/VideoMaker';

interface Props {
  children?: ReactNode
  ratio: number
  duration: number
  handlers: VideoMakerHandlers
}

export class VideoSettings extends Component<Props, {}> {
  public options: [number, number][] = [
    [16, 9],
    [4, 3],
    [1, 1],
    [4, 5],
    [9, 16],
  ];

  public valuetext(value: number) {
    return `${value}°C`;
  }

  public handleSliderChange(_: Event, value: number | number[]) {
    if (Array.isArray(value)) {
      value = value[0];
    }
    this.props.handlers.onChangeDuration(value);
  }

  public render() {
    const boxSize = 50;
    return (
      <div style={{ display: 'inline-flex', flexDirection: 'row', flexWrap: 'wrap' }}>
        <div
          style={{
            borderRadius: 7,
            height: boxSize,
            borderColor: '#fff',
            backgroundColor: '#00000000 !important',
            display: 'flex',
            flexShrink: 0,
          }}
        >
          {this.options.map((option, i) => {
            const ratio = option[0] / option[1];
            const baseWidth = ratio >= 1 ? boxSize / 3 * ratio : boxSize / 3;
            const isSelected = ratio === this.props.ratio;
            return (
              <Button
                key={i}
                size="small"
                variant="contained"
                className="settingButton"
                onClick={() => this.props.handlers.onChangeRatio(ratio)}
                style={{
                  fontSize: 10,
                  borderLeft: i === 0 ? '' : 'solid 1px #fff',
                  width: boxSize,
                  height: boxSize,
                  backgroundColor: '#424242',
                  borderRadius: 0,
                  color: '#fff',
                }}
              >
                <div style={{ display: 'flex', flexDirection: 'column' }}>
                  <div style={{
                    display: 'flex', flexGrow: 1, width: baseWidth, height: baseWidth / ratio, border: `solid 1px ${isSelected ? '#3b6ece' : '#fff'}`,
                  }}
                  />
                  <span>{`${option[0]}:${option[1]}`}</span>
                </div>
              </Button>
            );
          })}
        </div>
        <div style={{
          display: 'flex',
          flexShrink: 0,
          minWidth: 150,
          minHeight: 60,
          alignItems: 'center',
          flexGrow: 1,
          paddingLeft: 16,
          paddingRight: 16,
        }}
        >
          <Slider
            defaultValue={this.props.duration}
            getAriaValueText={() => this.valuetext(10)}
            aria-labelledby="discrete-slider-always"
            step={0.1}
            onChange={this.handleSliderChange.bind(this)}
            components={{ ValueLabel: SlideLabelComponent }}
            min={1} // Facebook minimal video duration
            max={10}
          />
        </div>
        {this.props.children}
      </div>
    );
  }
}

function SlideLabelComponent(props: {
  children: React.ReactElement
  open: boolean
  value: number
}) {
  const { children, open, value } = props;

  return (
    <Tooltip open={open} enterTouchDelay={0} placement="bottom" title={value}>
      {children}
    </Tooltip>
  );
}
