import React from 'react';
import { style } from 'typestyle';
import ReactLocalizedString from 'react-localization';
import { MediaView } from '@src/../common/dist';

export interface Props {
  media: MediaView;
}

export function MediaRightsUnrequestedIcon(props: Props) {
  return (
    <>
      <div className={iconClass}>
        <svg viewBox='0 0 1000 1000' width='18' height='18' xmlns='http://www.w3.org/2000/svg'>
          <g transform='matrix(12.469, 0, 0, 12.469, 499.560394, 498.735962)' id='855587'>
            <path
              style={{
                stroke: 'none',
                strokeWidth: 0,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeDashoffset: 0,
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                fill: 'rgb(144,145,145)',
                fillRule: 'nonzero',
                opacity: 1,
              }}
              transform=' translate(-49.9356, -50.025)'
              d='M 83.2 30.8 c 0 -2.1 -0.1 -4.2 -0.1 -6.1 c 0 -1.5 -1.2 -2.8 -2.8 -2.8 c -11.8 0 -20.9 -3.4 -28.4 -10.7 c -1.1 -1 -2.8 -1 -3.9 0 c -7.5 7.3 -16.5 10.7 -28.4 10.7 c -1.5 0 -2.8 1.2 -2.8 2.8 c 0 1.9 0 4 -0.1 6.1 c -0.4 19.9 -0.9 47.1 32.2 58.6 c 0.3 0.1 0.6 0.2 0.9 0.2 c 0.3 0 0.6 0 0.9 -0.2 C 84 77.9 83.5 50.7 83.2 30.8 z M 63.4 44.3 L 48.1 59.6 c -0.5 0.5 -1.2 0.8 -2 0.8 c -0.7 0 -1.4 -0.3 -2 -0.8 L 36.5 52 c -1.1 -1.1 -1.1 -2.8 0 -3.9 c 1.1 -1.1 2.8 -1.1 3.9 0 l 5.7 5.7 l 13.4 -13.4 c 1.1 -1.1 2.8 -1.1 3.9 0 C 64.5 41.5 64.5 43.2 63.4 44.3 z'
              stroke-linecap='round'
            />
          </g>
          <g transform='matrix(6.5818, 0, 0, 2.2097, 473.310394, 483.496399)' id='841536'>
            <path
              style={{
                stroke: 'none',
                strokeWidth: 0,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeDashoffset: 0,
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                fill: 'rgb(144,145,145)',
                fillRule: 'nonzero',
                opacity: 1,
              }}
              vector-effect='non-scaling-stroke'
              transform=' translate(-46.5001, -78.5)'
              d='M 2.6167 78.5 C 2.6167 57.2341 7.7391 38.064 15.8764 24.2946 C 24.0482 10.4665 34.9664 2.5 46.5001 2.5 C 58.0338 2.5 68.952 10.4665 77.1238 24.2946 C 85.2611 38.064 90.3835 57.2341 90.3835 78.5 C 90.3835 99.7659 85.2611 118.936 77.1238 132.705 C 68.952 146.533 58.0338 154.5 46.5001 154.5 C 34.9664 154.5 24.0482 146.533 15.8764 132.705 C 7.7391 118.936 2.6167 99.7659 2.6167 78.5 Z'
              stroke-linecap='round'
            />
          </g>
          <g transform='matrix(12.8464, 0, 0, 12.8464, 500, 500)' id='519846'>
            <path
              style={{
                stroke: 'none',
                strokeWidth: 0,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeDashoffset: 0,
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                fill: 'rgb(34,42,48)',
                fillRule: 'nonzero',
                opacity: 1,
              }}
              vector-effect='non-scaling-stroke'
              transform=' translate(-18, -18.05)'
              d='M 6.2 3.5 L 3.5 6.2 c -0.7 0.7 -0.7 1.9 0 2.7 l 9.2 9.2 l -9.2 9.2 c -0.7 0.7 -0.7 1.9 0 2.7 l 2.6 2.6 c 0.7 0.7 1.9 0.7 2.7 0 l 9.2 -9.2 l 9.2 9.2 c 0.7 0.7 1.9 0.7 2.7 0 l 2.6 -2.6 c 0.7 -0.7 0.7 -1.9 0 -2.7 L 23.3 18 l 9.2 -9.2 c 0.7 -0.7 0.7 -1.9 0 -2.7 l -2.6 -2.6 c -0.7 -0.7 -1.9 -0.7 -2.7 0 L 18 12.7 L 8.8 3.5 C 8.1 2.8 6.9 2.8 6.2 3.5 z'
              stroke-linecap='round'
            />
          </g>
        </svg>
      </div>
    </>
  );
}

export const lang = new ReactLocalizedString({
  en: {},
});

const iconClass = style({
  display: 'inline',
});
