import React, { useEffect, useState } from 'react';
import { style, classes } from 'typestyle';
import * as reduxHelpers from '@src/services/helpers/redux';
import { Pathname } from '@src/types/Browsing';
import { auth } from '@src/config/firebaseAuth';
import { confirmPasswordReset, verifyPasswordResetCode } from 'firebase/auth';
import { api } from '../../services/api';
import { LoginTextInput } from './TextInput';
import { LoginSigninLabel } from './SigninLabel';
import { LoginSubmitButton } from './LoginSubmitButton';
import Captcha from './Captcha';

interface IProps {
  oobCode: string
}

interface inputErrors {
  lowerCase: boolean,
  upperCase: boolean,
  number: boolean,
  longEnough: boolean,
  specialChar: boolean,
  confirmation: boolean,
}

export default function FormPasswordInput(props: IProps): JSX.Element {
  const specialCharsList = '(#$%.*+-&@<>!?)';

  const minCharsLength = 8;

  const [captchaToken, setCaptchaToken] = useState<string | undefined>(undefined);
  const [password, setPassword] = useState<string>("");
  const [passwordConfirmation, setpasswordConfirmation] = useState<string>("");
  const [errors, setErrors] = useState<inputErrors>({
    lowerCase: true,
    upperCase: true,
    number: true,
    longEnough: true,
    specialChar: true,
    confirmation: true,
  });
  useEffect(() => {
    validatePassword()
  }, [password, passwordConfirmation]);

  function handleCaptchaTokenChange(token: string) {
    setCaptchaToken(token);
  }

  function passwordIsValid(): boolean {
    return (Object.values(errors).indexOf(false) === -1);
  }

  function passwordConfirmationIsValid(): boolean {
    return errors.confirmation;
  }

  async function updatePasswordRequest(): Promise<void> {
    if (!captchaToken || !passwordIsValid()) {
      return;
    }

    let emailUser = '';
    try {
      emailUser = await verifyPasswordResetCode(auth, props.oobCode);
    } catch (e: any) {
      console.error(e);
      if (['auth/expired-action-code', 'auth/invalid-action-code'].indexOf(e.code) >= 0) {
        reduxHelpers.addAlert({ text: 'common.error.expired-action-code', type: 'danger' });
        reduxHelpers.setRoute(Pathname.root);
        setTimeout(() => {
          reduxHelpers.setRoute(Pathname.root);
          reduxHelpers.keepUrlParams();
          reduxHelpers.setRoute(Pathname.changePassword);
        }, (200));
      }
      return;
    }
    if (emailUser === '') {
      return;
    }
    try {
      await api.setPasswordUpdate(props.oobCode, captchaToken);
      await confirmPasswordReset(auth, props.oobCode, password);
      reduxHelpers.addAlert({ text: 'changepassword.passwordchanged', type: 'success' });
      reduxHelpers.setRoute(Pathname.login);
    } catch (e) {
      console.error('confirmPassword', e);
    }
  }

  function handlePassword(pass: string) {
    setPassword(pass);
  }

  function handlePasswordConfirm(confPass: string) {
    setpasswordConfirmation(confPass);
  }

  function isLongEnough(): boolean {
    return password.length >= minCharsLength;
  }

  function hasUppercase(): boolean {
    return /[A-Z]/.test(password);
  }

  function hasSpecialChar(): boolean {
    return specialCharsList.split('').map((char) => password.indexOf(char) >= 0).reduce((prev, curr) => curr || prev, false);
  }

  function hasLowercase(): boolean {
    return /[a-z]/.test(password);
  }

  function hasNumber(): boolean {
    return /[0-9]/.test(password);
  }

  function checkPasswordValidation(): boolean {
    return password === passwordConfirmation;
  }

  function validatePassword() {
    let errors = {
      lowerCase: hasLowercase(),
      upperCase: hasUppercase(),
      number: hasNumber(),
      longEnough: isLongEnough(),
      specialChar: hasSpecialChar(),
      confirmation: checkPasswordValidation(),
    }
    setErrors(errors);
  }

  return (
    <div>
      <div className="col-sm-10 offset-sm-1">
        <LoginSigninLabel title={window.T('changepassword.title')} />

        <div style={{ position: 'relative' }}>
          {!passwordIsValid() && (
            <span className={classes('password-error-popup', errorPopupClass)}>
              Your password must:
              {!errors.longEnough && (
                <li>
                  include at least
                  {' '}
                  {minCharsLength}
                  {' '}
                  characters
                </li>
              )}
              {!errors.lowerCase && (
                <li>
                  include at least one lowercase letter
                </li>
              )}
              {!errors.upperCase && (
                <li>
                  include at least one uppercase letter
                </li>
              )}
              {!errors.number && (
                <li>
                  include at least one number
                </li>
              )}
              {!errors.specialChar && (
                <li>
                  include at least one special character
                  {' '}
                  {specialCharsList}
                </li>
              )}
              {!errors.confirmation && (
                <li>
                  match your confirmation password
                </li>
              )}
            </span>
          )}
          <LoginTextInput
            error={!passwordIsValid()}
            label="password"
            type="password"
            fullWidth
            onChange={({ target }) => handlePassword(target.value)}
          />
        </div>
        <LoginTextInput
          error={!passwordConfirmationIsValid()}
          label="password confirmation"
          type="password"
          fullWidth
          onChange={({ target }) => handlePasswordConfirm(target.value)}
        />
        <Captcha
          onTokenChange={(token) => handleCaptchaTokenChange(token)}
        />
        <div className="buttons row" style={{ marginTop: 7 }}>
          <LoginSubmitButton
            disabled={!passwordIsValid()}
            onClick={() => updatePasswordRequest()}
            text={window.T('submit').toUpperCase()}
          />
        </div>
      </div>
    </div>
  );

}

const errorPopupClass = style({
  backgroundColor: 'white',
  display: 'flex',
  color: '#555',
  padding: 8,
  flexDirection: 'column',
  fontSize: 10,
  fontWeight: 300,
  textAlign: 'left',
  borderRadius: 4,
  position: 'absolute',
  left: -360,
  width: 330,
  top: 0,
  margin: 15,
});
