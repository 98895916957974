import React from 'react';
import { classes, style } from 'typestyle';
import ReactLocalizedString from 'react-localization';

export interface Props {}

export function MediaRightsApprovedIcon() {
  return (
    <>
      <div className={iconClass}>
        <svg
          xmlns='http://www.w3.org/2000/svg'
          xmlnsXlink='http://www.w3.org/1999/xlink'
          version='1.1'
          width='18'
          height='18'
          viewBox='0 0 1000 1000'
          xmlSpace='preserve'
        >
          <g transform='matrix(12.469 0 0 12.469 500.0009 499.6952)' id='877718'>
            <path
              style={{
                stroke: 'none',
                strokeWidth: 1,
                strokeDasharray: 'none',
                strokeLinecap: 'butt',
                strokeDashoffset: 0,
                strokeLinejoin: 'miter',
                strokeMiterlimit: 4,
                fill: 'rgb(34,138,42)',
                fillRule: 'nonzero',
                opacity: 1,
              }}
              transform=' translate(-49.9356, -50.025)'
              d='M 83.2 30.8 c 0 -2.1 -0.1 -4.2 -0.1 -6.1 c 0 -1.5 -1.2 -2.8 -2.8 -2.8 c -11.8 0 -20.9 -3.4 -28.4 -10.7 c -1.1 -1 -2.8 -1 -3.9 0 c -7.5 7.3 -16.5 10.7 -28.4 10.7 c -1.5 0 -2.8 1.2 -2.8 2.8 c 0 1.9 0 4 -0.1 6.1 c -0.4 19.9 -0.9 47.1 32.2 58.6 c 0.3 0.1 0.6 0.2 0.9 0.2 c 0.3 0 0.6 0 0.9 -0.2 C 84 77.9 83.5 50.7 83.2 30.8 z M 63.4 44.3 L 48.1 59.6 c -0.5 0.5 -1.2 0.8 -2 0.8 c -0.7 0 -1.4 -0.3 -2 -0.8 L 36.5 52 c -1.1 -1.1 -1.1 -2.8 0 -3.9 c 1.1 -1.1 2.8 -1.1 3.9 0 l 5.7 5.7 l 13.4 -13.4 c 1.1 -1.1 2.8 -1.1 3.9 0 C 64.5 41.5 64.5 43.2 63.4 44.3 z'
              stroke-linecap='round'
            />
          </g>
        </svg>
      </div>
    </>
  );
}

export const lang = new ReactLocalizedString({
  en: {},
});

const iconClass = style({
  display: 'inline',
});
