import { ADALONG_COLORS, ThemeStyle } from '@src/styles';
import { percent, px } from 'csx';
import React from 'react';
import { stylesheet } from 'typestyle';
import { ConversationMessage } from 'common';
import dayjs from 'dayjs';

export function Message(props: {
  message: ConversationMessage;
  senderInfo: { name: string };
  self: boolean;
}) {
  const date = dayjs(props.message.receivedDate);
  const formattedDate = date.format('HH:mm DD-MM-YYYY');
  return (
    <div className={sheet.container} data-self={props.self.toString()}>
      <b>{props.senderInfo.name}</b>
      <span className={sheet.date}>{formattedDate}</span>
      <p className={sheet.body}>{props.message.message}</p>
    </div>
  );
}

const sheet = stylesheet({
  container: {
    // light
    // backgroundColor: '#edebeb',
    // color: '#393939',
    color: 'white',

    // dark
    backgroundColor: '#565656',
    borderRadius: 14,
    width: percent(100),
    margin: `${px(10)} 0`,
    padding: `${px(4)} ${px(10)}`,
    border: `1px solid #8888881a`,
    $nest: {
      '&[data-self="true"]': {
        // light
        backgroundColor: '#3d95c3',
        // dark
        // backgroundColor: '#1c2729',
      },
      b: {
        fontSize: ThemeStyle.textSizes.large,
      },
    },
  },
  body: {
    fontSize: ThemeStyle.textSizes.medium,
    paddingLeft: 12,
    margin: 0,
  },
  date: {
    fontSize: ThemeStyle.textSizes.small,
    marginLeft: 12,
    color: ADALONG_COLORS.SMOOTH_WHITE,
  },
});
