import React, {Component} from 'react';

import {connect} from 'react-redux';

import {ICombinedReducers} from '@src/types/redux';
import {Services} from '@src/types/Services';
import {removeAlert, setPopup} from '@src/actions/common';
import {EPopup} from '@src/types/redux/common';
import {Action, Dispatch} from 'redux';
import {Modal} from '@src/components/Modal';
import CircularProgress from '@mui/material/CircularProgress';
import {IAlert} from '@src/types/alert';
import {DownloadCollection} from './components/DownloadCollection';
import {NotFound} from './components/NotFound';
import Settings from './components/Settings/index';
import UserSettings from './components/UserSettings/index';
import AddInfluencer from './components/Settings/AddInfluencer/index';
import Blacklist from './components/Settings/Blacklist'
import EditInfluencer from './components/Settings/EditInfluencer/index';
import {AlertUser} from './components/AlertUser';
import PromptConfirm from './components/PromptConfirm/index';
import CollectOverlay from './components/CollectOverlay';
import SetCreatorCountriesPopup from './components/SetCreatorCountriesOverlay';

interface OwnProps {
  services: Services;
}

const mapStateToProps = (state: ICombinedReducers) => ({
  common: state.common,
  media: state.media,
  product: state.product,
  popupLoading: state.common.popupLoading,
  rightsrequests: state.rightsrequests,
  mediaDimensions: state.media.dimensions,
  windowSize: state.common.windowSize,
});

const mapDispatchToProps = (dispatch: Dispatch<Action>) => ({
  close: (popup: EPopup) => dispatch(setPopup(popup, null)),
  removeAlert: (id: IAlert['id']) => dispatch(removeAlert(id)),
});

type Props = OwnProps &
  Readonly<ReturnType<typeof mapStateToProps>> &
  Readonly<ReturnType<typeof mapDispatchToProps>>;

class Popups extends Component<Props> {
  public render() {
    return (
      <div>
        <AlertUser alerts={this.props.common.alerts} remove={this.props.removeAlert} />
        <CollectOverlay />
        {this.props.common.popups.promptconfirm && <PromptConfirm />}
        {this.props.common.popups.settings && <Settings services={this.props.services} />}
        {this.props.common.popups.userSettings && <UserSettings services={this.props.services} />}
        {this.props.common.popups.addInfluencer && <AddInfluencer services={this.props.services} />}
        {this.props.common.popups.addBlacklist && <Blacklist services={this.props.services} />}
        {this.props.common.popups.editInfluencer && (
          <EditInfluencer influencer={this.props.common.popups.editInfluencer} />
        )}
        {this.props.common.popups.notfound && <NotFound />}
        {this.props.common.popups.downloadCollection ? (
          <DownloadCollection collectionId={this.props.common.popups.downloadCollection} />
        ) : null}
        {this.props.common.popups.setCreatorCountriesOverlay && <SetCreatorCountriesPopup />}
        {this.props.popupLoading && (
          <Modal style={{ zIndex: 10 }}>
            <CircularProgress style={{ margin: 'auto' }} />
          </Modal>
        )}
      </div>
    );
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Popups);
