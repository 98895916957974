import React, { Component, ReactNode } from 'react';
import { stylesheet } from 'typestyle';

interface PageHeaderProps {
  children?: ReactNode;
}

class PageHeader extends Component<PageHeaderProps, {}> {
  public render() {
    return (
      <div className={sheet.header}>
        {this.props.children}
      </div>
    );
  }
}

const sheet = stylesheet({
  header: {
    flexShrink: 0,
    margin: '20px 15px 10px',
  },
});

export { PageHeader };
