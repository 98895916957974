import { useMutation } from 'react-query';
import { queryClient, QUERY_KEYS } from '@src/query';
import { ApiError } from '@src/services/api';
import { ApiTypes } from '@src/../common/dist';
import { updateMediaFindData } from './useQueryMediaFind';
import { updateObjectLabels } from './api';
import { MediaView } from 'common';

// This delay is quite conservative since we don't really need to apply the update
// right away; the user focus is probably not there anyway
const productGroupUpdateDelayMillis = 3000;

export const useMutationMediaObjectLabelsUpdate = () => useMutation<void, ApiError, ApiTypes.ParamsPutObjectLabels>((params) => updateObjectLabels(params), {
  onSuccess: async (_, { medias }) => {
    medias.forEach(({
      mediaId,
      productIdsExact,
      productIdsSimilar,
    }) => {
      queryClient.invalidateQueries(QUERY_KEYS.collectionsMedia());

      updateMediaFindData(mediaId, {
        productIdsExact: productIdsExact,
        productIdsSimilar: productIdsSimilar,
      });

      // Update the media query directly in the cache
      queryClient.setQueryData<MediaView | undefined>(QUERY_KEYS.media(mediaId), (oldData: MediaView | undefined) => {
        if (!oldData) {
          return oldData;
        }

        return {
          ...oldData,
          productIdsExact: productIdsExact,
          productIdsSimilar: productIdsSimilar,
        };
      });
    });

    // Unfortunately, the update to the product groups happen by a fire-and-forget action from web-app
    // The refreshing delay reflects the time it takes for the update send by web-app to be processed by catalong
    // Another way to improve responsiveness would be to optimistically update the UI directly, but this would
    // be more complicated, so probably not worth it.
    setTimeout(() => {
      queryClient.invalidateQueries(QUERY_KEYS.productGroups());
    }, productGroupUpdateDelayMillis);
  },
});
