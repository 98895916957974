import React, { useState } from 'react';

import { TextArea } from '@src/components/TextArea';
import { InstagramPageInterface } from '@src/types/Business';
import Button from '@mui/material/Button';
import AddIcon from '@mui/icons-material/Add';
import { style } from 'typestyle';
import { ADALONG_COLORS } from '@src/styles';
import { DropDown } from '../../../../../../components/DropDown';
import { CheckBox } from '@src/components/CheckBox';

interface IProps {
  creator?: string;
  disable: boolean;
  pages: any[];
  selectedPage?: InstagramPageInterface;
  message: string;
  selectPage: (page: InstagramPageInterface) => void;
  onMessageChange: (val: string) => void;
  shareFeed: boolean;
  onShareFeedChange: (val: boolean) => void;
  isVideo: boolean;
  missingSharePerm: boolean;
}

export default function InstagramPage(props: IProps): JSX.Element | null {
  const [displayDropdown, setdisplayDropdown] = useState<boolean>(false);

  let pageSelectionTitle = window
    .T('share.popup.instagrampage.subtitles.select_placeholder')
    .toUpperCase();
  if (props.selectedPage) {
    pageSelectionTitle = props.selectedPage.name;
  }

  return (
    <div className='content' onClick={onFrameClick}>
      <div className={`line ${props.disable || props.missingSharePerm ? 'disabled-line' : ''}`}>
        <div className='title'>{window.T('share.popup.instagrampage.subtitles.select')}</div>
        <div className='field'>
          <DropDown
            title={pageSelectionTitle}
            display={displayDropdown}
            onDisplay={setdisplayDropdown}
          >
            {props.pages.map((page, i) => (
              <div
                className='selection'
                key={i}
                data-selected={page.id === props.selectedPage?.id}
                onClick={(e) => selectPage(e, page)}
              >
                {page.name}
              </div>
            ))}
          </DropDown>
        </div>
      </div>
      <div className='line'>
        <div className={flexContainerClass}>
          <div className={idCommentClass}>
            {window.T('share.popup.instagrampage.subtitles.comment')}
          </div>
          {props.creator ? (
            <Button className={creatorClass} onClick={() => addCreator()}>
              <AddIcon className={iconClass} />
              Mention Creator
            </Button>
          ) : null}
        </div>
        <TextArea
          id='textarea'
          value={props.message}
          placeholder={window.T('share.popup.instagrampage.subtitles.comment_placeholder')}
          onChange={({ target }) => props.onMessageChange(target.value)}
          maxLength={1000}
        />
      </div>
      {props.isVideo ? (
        <div className='line'>
          <div className={flexContainerClass}>
            <div className={shareFeedClass}>
              {window.T('share.popup.instagrampage.subtitles.sharefeed')}
            </div>
            <CheckBox
              id='sharefeed'
              checked={props.shareFeed}
              onChange={(checked: boolean) => {
                props.onShareFeedChange(checked);
              }}
            />
          </div>
        </div>
      ) : null}
    </div>
  );

  function selectPage(
    e: React.MouseEvent<HTMLDivElement, MouseEvent>,
    page: InstagramPageInterface,
  ) {
    e.stopPropagation();
    props.selectPage(page);
    setdisplayDropdown(false);
  }

  function onFrameClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    setdisplayDropdown(false);
  }

  function addCreator() {
    props.onMessageChange(`${props.message}@${props.creator}`);
  }
}

const idCommentClass = style({
  verticalAlign: 'middle',
  alignSelf: 'center',
  flex: 1,
});

const shareFeedClass = style({
  verticalAlign: 'middle',
  alignSelf: 'center',
  flex: 1,
});

const creatorClass = style({
  border: '2px solid #464646',
  color: `${ADALONG_COLORS.SMOOTH_WHITE} !important`,
  backgroundColor: '#464646',
  borderColor: 'transparent',
  fontFamily: "'Public Sans', sans-serif",
  padding: 1,
  fontSize: 12,
  letterSpacing: '0.7px',
  alignSelf: 'center',
  textAlignLast: 'right',
  marginLeft: 'auto',
});

const flexContainerClass = style({
  display: 'flex',
  marginBottom: 10,
});

const iconClass = style({
  fontSize: 15,
});
