import React, { Component, HTMLAttributes } from 'react';
import { classes } from 'typestyle';
import { rowClass } from './index';

export class Row extends Component<HTMLAttributes<HTMLDivElement>> {
  public render() {
    const { className, children, ...props } = this.props;

    return (
      <div
        {...props}
        className={classes(rowClass, className)}
      >
        {children}
      </div>
    );
  }
}
