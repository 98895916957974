import { ApiTypes } from 'common';
import { Call } from '@src/services/api';

export const getStoredConversations = async (cursor?: string): Promise<ApiTypes.ResGetStoredConversations> => {
  return Call.get('/api/dm/storedconversations', { cursor });
};

export const getRemoteConversations = async (cursor?: string): Promise<ApiTypes.ResGetRemoteConversations> => {
  return Call.get('/api/dm/remoteconversations', { cursor });
};

export const getConversationMessages = async (params: ApiTypes.ParamsGetConversationMessages, cursor?: string) => {
  return Call.get<ApiTypes.ResGetDms, ApiTypes.ParamsGetConversationMessages>('/api/dm/messages', {
    ...params,
    cursor,
  });
};

export const sendMessage = async (params: ApiTypes.ParamsSendDm) => {
  return Call.post('/api/dm/messages', params);
};
