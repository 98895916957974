import React from 'react';
import { SectionFolder } from '@src/components/SectionFolder';
import { SectionTitle } from '@src/components/SectionTitle';
import { SectionProps } from '../types/Settings';
import LocalizedStrings from 'react-localization';
import { SECTION_TITLE_SIZE } from '../utils/variables';
import { SectionContent } from '../subcomponents/SectionContent';
import { createChangeSettings } from '../utils/createChangeSettings';
import { ShopThisLookIcon } from '../../Icons/DropDownIcons/ShopThisLook';
import { WidgetView } from 'common';
import { ShopThisLookSelector } from '../selectors/ShopThisLookSelector';

const ShopThisLook = ({ opened, onOpen, editedWidget, setEditedWidget }: SectionProps) => {
  const changeSettings = createChangeSettings(editedWidget, setEditedWidget);

  const handleShopThisLookChange = <K extends keyof WidgetView['settings']>(
    key: K,
    value: WidgetView['settings'][K],
  ) => {
    if (value === editedWidget.settings[key]) {
      return;
    }
    
    const newSettings: Partial<WidgetView['settings']> = {
      [key]: value, 
      // Set product display settings to false when shop_this_look_display is 'none'
      display_exact_products_only: value === 'none' ? false : editedWidget.settings.display_exact_products_only,
      display_similar_products_only: value === 'none' ? false : editedWidget.settings.display_similar_products_only,
      // Set shop_this_look boolean value to support v5 and v6 widgets
      shop_this_look: value === 'none' ? false : true,
    };

    changeSettings(newSettings);
  };

  return (
    <SectionFolder
      title={
        <SectionTitle size={SECTION_TITLE_SIZE}>
          <ShopThisLookIcon />
          {lang.title.main}
        </SectionTitle>
      }
      opened={opened}
      onOpen={onOpen}
    >
      <SectionContent>
        <ShopThisLookSelector
          shop_this_look_display={editedWidget.settings.shop_this_look_display}
          onChange={(value) => handleShopThisLookChange('shop_this_look_display', value)}
        />
      </SectionContent>
    </SectionFolder>
  );
};

const lang = new LocalizedStrings({
  en: {
    title: {
      main: 'Shop This Look',
    },
  },
});

export default ShopThisLook;