import {
  ApiTypes,
  UserView,
  MediaView,
  RightRequestView,
  CollectionView,
  MentionPageView,
  CreatorView,
} from 'common';
import { IAlert } from '../alert';
import { AddToCollection } from '../Popups';

export enum EPopup {
  getRights = 'getRights',
  account = 'account', // maybe old file ?
  settings = 'settings',
  userSettings = 'userSettings',
  rights = 'rigths', // beware here, the syntax is not correct
  collectOverlay = 'collectOverlay',
  setCreatorCountriesOverlay = 'setCreatorCountriesOverlay',
  notfound = 'notfound',
  promptconfirm = 'promptconfirm',
  widgetCreation = 'widgetCreation',
  downloadCollection = 'downloadCollection',
  addInfluencer = 'addInfluencer',
  editInfluencer = 'editInfluencer',
  addBlacklist = 'addBlacklist'
}

export interface IConfig {
  rightstemplate: {
    maxComment: number;
    commentReply: string;
    link: string;
  };
}

export type UserWithFeaturesView = ApiTypes.ResGetMe['user'];

export interface CommonState {
  windowSize: WindowSize;
  windowFocus: boolean;
  mouseClick: () => null;
  settingsPopup: {};

  facebookPages: ApiTypes.ResGetFacebookPages;
  facebookAdAccounts: [];
  user: UserWithFeaturesView | null;
  admin: any | null;
  config: null | IConfig; // Loaded from back
  alerts: IAlert[];

  influencers: CreatorView[];

  popups: PopupsState;
  popupLoading: boolean;

  collectOverlay: {
    step: 'add' | 'new';
    // media: MediaView | MediaView[] | undefined
    media?: AddToCollection;
  };

  setCreatorCountriesOverlay: {
    mediaList?: MediaView[];
  };

  urlParams: {
    token: undefined | string;
    oobCode: undefined | string;
    oauthFailed: boolean;
  };

  oauth: {
    twitter: boolean;
    instagram: boolean;
    facebook: boolean;
    google: boolean;
  };

  groupMentionPages: { [key: string]: MentionPageView };
  groupUsers: UserView[];
}

export interface PopupsState {
  [EPopup.notfound]: null | boolean;
  [EPopup.account]: null | boolean;
  [EPopup.settings]: null | boolean;
  [EPopup.userSettings]: null | boolean;
  [EPopup.widgetCreation]: null | boolean;
  [EPopup.rights]: null | boolean;
  [EPopup.collectOverlay]: null | boolean;
  [EPopup.setCreatorCountriesOverlay]: null | boolean;
  [EPopup.getRights]: null | {
    medias: MediaView[];
  };
  [EPopup.promptconfirm]: null | {
    question: string;
    answer: (yes: boolean) => Promise<any> | undefined | void; // | void
    text?: {
      yes?: string;
      no?: string;
    };
    yesColor?: 'blue' | 'red';
  };
  [EPopup.downloadCollection]: null | string;
  [EPopup.addBlacklist]: null | boolean;
  [EPopup.addInfluencer]: null | boolean;
  [EPopup.editInfluencer]: null | CreatorView;
}

export interface IDetailedMediaView {
  mediaId: string;
  collection?: CollectionView;
  uncollect: boolean;
  canEdit: boolean;
  rightsRequestStatus?: RightRequestView['status'] | 'none';
  onRightsRequestClick?: (status: string) => void;
  displayRightsRequestButton?: boolean;
  tab?: DetailedMediaViewTab;
}

export interface WindowSize {
  height: number;
  width: number;
}

export const detailedMediaViewTabs = ['info', 'product', 'similars'] as const;
export type DetailedMediaViewTab = typeof detailedMediaViewTabs[number];
