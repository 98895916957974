import React, { PropsWithChildren, HTMLAttributes } from 'react';
import { style } from 'typestyle';

/**
 * Overlay that will take 100% of the parent size
 */
export function Overlay({
  children,
  ...props
}: PropsWithChildren<HTMLAttributes<HTMLDivElement>>) {
  return (
    <div className={overlayClass} {...props}>
      {children}
    </div>
  );
}

const overlayClass = style({
  position: 'absolute',
  top: 0,
  right: 0,
  bottom: 0,
  left: 0,
});
