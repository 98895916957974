import React, { ReactNode, forwardRef, Component } from 'react';
import Masonry, { MasonryPropTypes } from 'react-masonry-component';

/*
 * Workaround until the library is updated and supports React 18
 */

export interface CustomMasonryProps extends MasonryPropTypes {
  children?: ReactNode;
}

const CustomMasonry = forwardRef<Component<MasonryPropTypes>, CustomMasonryProps>((props, ref) => {
  return <Masonry ref={ref} {...props} />;
});

export default CustomMasonry;
