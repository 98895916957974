import React, { Component, createRef, ReactNode } from 'react';

import { PerfectScrollbar } from 'components/PerfectScrollbar';
import Draggable from 'components/Draggable';
import styles from './styles.scss';

const DEFAULT_EMPTY_LINES = 3;

interface Props {
  children?: ReactNode
  maxColumn?: number
  onInsert: (item: any, position: number) => void
  onMoveItem: (itemIndex: number, newPosition: number) => void
  validInsertInfo: (info: any) => boolean
}

interface State {
  dragInsert: number
  draggingItem: number
}

export default class List extends Component<Props, State> {
  private dragInsert = -1;

  private mousePosition = { x: 0, y: 0 };

  private componentRef = createRef<HTMLDivElement>();

  public constructor(props: Props) {
    super(props);
    this.state = {
      dragInsert: -1,
      draggingItem: -1, // Moving item already in the grid
    };
  }

  public render() {
    const childrens = this.getChildrens();
    return (
      <div ref={this.componentRef} onDragLeave={this.onDragLeave.bind(this)}>
        <PerfectScrollbar
          options={{
            useBothWheelAxes: true,
            suppressScrollY: true,
          }}
          onDragEnter={(e) => this.onDragEnter(childrens.length, e)}
          onDragOver={this.onDragOver.bind(this)}
          onDrop={(e) => this.onDrop(childrens.length, e)}
        >
          <div style={{ display: 'inline-flex' }}>
            {this.renderBoxes(childrens)}
          </div>
        </PerfectScrollbar>
      </div>
    );
  }

  public renderBoxes(childrens: React.ReactNode[]) {
    const boxesCreators = childrens.map((item, originalIndex) => ((i: number) => {
      const dragging = this.state.draggingItem === originalIndex;
      const removing = dragging && this.state.dragInsert === -1;
      return (
        <Draggable
          key={i}
          style={{ width: 110, height: 110, padding: 7 }}
          info={{ origin: 'grid', type: 'media' }}
          data-dragging={dragging}
          onDragEnter={(e) => this.onDragEnter(i, e)}
          onDrop={(e) => this.onDrop(i, e)}
          onDragStart={() => this.setState({ draggingItem: originalIndex, dragInsert: originalIndex })}
          onDragEnd={() => this.onItemDragEnd()}
        >
          <div className={styles.itemDelete} data-removing={removing} />
          {item}
        </Draggable>
      );
    }));

    const isInserting = this.state.dragInsert > -1;
    const isDraggingItem = this.state.draggingItem > -1;
    const isNotDraggingItemToSamePosition = this.state.draggingItem !== this.state.dragInsert;
    const isNotDraggingToANewPosition = this.state.dragInsert !== childrens.length;
    const isNotDraggingTheLastItem = this.state.draggingItem + 1 !== childrens.length;

    if (isInserting) {
      if (!isDraggingItem) {
        // Adding new item
        const index = this.state.dragInsert;
        const emptyBox = (i: number) => (
          <div
            key={i}
            className={styles.item}
            onDragEnter={(e) => this.onDragEnter(index, e)}
            onDrop={(e) => this.onDrop(index, e)}
          >
            <div className={styles.emptyItem} />
          </div>
        );
        boxesCreators.splice(index, 0, emptyBox);
      } else if (
        isNotDraggingItemToSamePosition && (
          isNotDraggingToANewPosition
                    || isNotDraggingTheLastItem
        )
      ) {
        // Moving item
        const [item] = boxesCreators.splice(this.state.draggingItem, 1);
        boxesCreators.splice(this.dragInsert, 0, item);
      }
    }

    // Create an empty grid if necessary
    let toCreate = (DEFAULT_EMPTY_LINES * this.maxColumn) - boxesCreators.length;
    const r = (boxesCreators.length % this.maxColumn);
    if (toCreate < 0 && r) {
      toCreate = this.maxColumn - r;
    }
    while (toCreate-- > 0) {
      boxesCreators.push((i: number) => (
        <div
          key={i}
          className={styles.item}
        >
          <div className={[styles.itemForEmptyGrid, styles.emptyItem].join(' ')} />
        </div>
      ));
    }

    return boxesCreators.map((f, i) => f(i));
  }

  public componentDidMount() {
    window.addEventListener('dragover', this.onMouseMove.bind(this));
  }

  public componentWillUnmount() {
    window.removeEventListener('dragover', this.onMouseMove.bind(this));
  }

  private get maxColumn() {
    return this.props.maxColumn || 3;
  }

  public getChildrens(): React.ReactNode[] {
    if (!this.props.children) {
      return [];
    }
    return React.Children.map(this.props.children, (children) => children) || [];
  }

  public onMouseMove(event: DragEvent) {
    this.mousePosition = {
      x: event.pageX,
      y: event.pageY,
    };
  }

  public onDragOver(event: React.DragEvent<HTMLDivElement>) {
    event.preventDefault();
  }

  public onDragEnter(index: number, event: React.DragEvent<HTMLDivElement>) {
    event.stopPropagation();
    this.setDragInsert(index);
  }

  public onDragLeave(e: React.DragEvent<HTMLDivElement>) {
    e.persist();
    setTimeout(() => {
      if (this.mouseIsOut()) {
        this.setDragInsert(-1);
      }
    }, 20);
  }

  public onDrop(index: number, event: React.DragEvent<HTMLDivElement>) {
    event.stopPropagation();

    const numberOfChildrens = this.getChildrens().length;

    const info = this.getDragInfo(event);
    if (this.props.validInsertInfo(info)) {
      const position = index === -1 ? numberOfChildrens : index;

      this.props.onInsert(info, position);
    } else if (info.origin === 'grid') {
      const position = index === numberOfChildrens ? numberOfChildrens - 1 : index;
      this.props.onMoveItem(this.state.draggingItem, position);
    }

    this.setDragInsert(-1);
    this.setState({ draggingItem: -1 });
  }

  public onItemDragEnd() {
    if (this.mouseIsOut()) {
      this.setState({ draggingItem: -1 });
    }
  }

  public getDragInfo(event: React.DragEvent<HTMLDivElement>) {
    const message = event.dataTransfer.getData('text');
    if (!message) {
      return null;
    }
    let info: any | null = null;
    try {
      info = JSON.parse(message);
    } catch (e) { }
    return info;
  }

  public setDragInsert(index: number) {
    if (this.dragInsert === index) {
      return;
    }
    this.dragInsert = index;
    this.setState({ dragInsert: this.dragInsert });
  }

  public mouseIsOut() {
    const {
      left, top, width, height,
    } = this.componentRef.current!.getBoundingClientRect();
    const right = left + width;
    const bottom = top + height;
    const { x, y } = this.mousePosition;
    const offset = 5;
    return x <= left + offset || y <= top + offset || y >= bottom - offset || x >= right - offset;
  }
}
