/* eslint-disable no-underscore-dangle */
import { CollectionView, MediaView } from '@src/../common/dist';
import { getLangError } from '@src/fragments/Alerting/AlertSnackbar';
import { goToGetRights, goToMedia, goToMediaDownload } from '@src/pages/Media/utils/mediaRoute';
import { useMutationCollectionRemoveMedia } from '@src/queries/collections/useMutationCollection';
import { useMutationRightsRequestDelete } from '@src/queries/media/useMutationMediaRightsRequestDelete';
import { useMutationRightsRequestUpdate } from '@src/queries/media/useMutationMediaRightsRequestUpdate';
import { Browsing } from '@src/types/Browsing';
import { IPopupsService } from '@src/types/Popups';
import React, { PropsWithChildren } from 'react';
import { LocalizedStrings } from 'react-localization';
import {
  FloatingMenuItem,
  MenuDivider,
  Props as FloatingMenuProps,
} from '@src/components/FloatingMenu';
import { MediaType, RightRequestStatus } from '@adalong/schemas';
import {
  getDownloadableMedia,
  getMediaWarnings,
  isDownloadWarningTrue,
  isMediaDownlodableByType,
  shouldDisplayDownloadWarning,
} from '@src/services/Media/download';
import { DownloadHelper } from '@src/services/helpers/download';
import { API_URL } from '@src/config';
import { DownloadWarning } from '../../components/DownloadWarning/DownloadWarning';
import { lang as mediaActionLang, MediaActionButton } from './MediaActionButton';
import { useAddAlert } from '../Alerting/alertAtom';

const LINKABLE_TYPE: MediaType[] = [MediaType.Image];

// Get keys from media action lang
type ItemKeys<T> = keyof (T extends LocalizedStrings<infer R> ? R : T);

export interface MediaActionProps {
  mediaList: MediaView[];
  /**
   * The collection in which the media is
   * If not provided, the "remove from collection" button will
   * not be displayed
   */
  inCollection: CollectionView | undefined;
  /**
   * Provide a string to disable a button and show a tooltip
   * @example { 'removeFromCollection': 'tooltip message to display'}
   */
  disable?: { [key in keyof MediaActionHandlers]?: string };
  /**
   * To determine whether we should render the 'set creator countries' button or not
   */
  catalogCountries?: string[];
}

type Handler = (mediaList: MediaView[]) => void;

export interface MediaActionHandlers {
  addToCollection: Handler;
  removeFromCollection?: Handler;
  getRights: Handler;
  resetRights: Handler;
  setRightsAsAgreed: Handler;
  setCreatorCountries: Handler;
  /**
   * Handler to open the product linking view
   */
  linkProduct?: Handler;
  unlinkProduct?: Handler;
  /**
   * Handler to download the media
   */
  download: Handler;
}

interface Props extends MediaActionProps {
  services: {
    popups: IPopupsService;
    browsing: Browsing;
  };
  /**
   * Set to false to hide the button to open the product
   * linking view
   * Default to true
   */
  linkProduct?: boolean;
  /**
   * Handler to unlink a particular
   * If not provided, the button will not be displayed
   */
  unlinkProduct?: MediaActionHandlers['unlinkProduct'];
}

export function MediaActionButtonController({
  services,
  mediaList,
  inCollection,
  disable,
  linkProduct = true,
  unlinkProduct,
  catalogCountries,
}: PropsWithChildren<Props>) {
  const addAlert = useAddAlert();
  /// Queries
  const {
    mutate: deleteRightsRequest,
    isLoading: isDeletingRights,
    reset: resetDeleteRights,
  } = useMutationRightsRequestDelete();
  const {
    mutate: updateRightsRequest,
    isLoading: isUpdatingRights,
    reset: resetUpdateRights,
  } = useMutationRightsRequestUpdate();
  const {
    mutate: removeMedia,
    isLoading: isRemovingMedia,
    reset: resetRemoveMedia,
  } = useMutationCollectionRemoveMedia();

  /// Handlers
  const linkProductHandler = (list: MediaView[]) => {
    if (list.length === 1) {
      services.browsing.goToPage(goToMedia(window.location, list[0]._id, 'product'));
    }
  };
  const addToCollection = (list: MediaView[]) => {
    services.popups.openCollectOverlay('add', list);
  };
  const removeFromCollection = (list: MediaView[]) => {
    if (inCollection?.locked) {
      return;
    }
    if (inCollection !== undefined) {
      removeMedia(
        {
          collectionId: inCollection._id,
          mediaIds: list.map((media) => media._id),
        },
        {
          onError: (error) =>
            addAlert({
              text: getLangError(error.type),
              close: resetRemoveMedia,
            }),
        }
      );
    }
  };
  const getRights = (list: MediaView[]) => {
    // we can't get rights in bulk
    if (list.length === 1) {
      services.browsing.goToPage(goToGetRights(window.location, list[0]._id));
    }
  };
  const resetRights = (list: MediaView[]) => {
    if (list.length === 1) {
      deleteRightsRequest(
        { mediaId: list[0]._id },
        {
          onError: (error) =>
            addAlert({
              text: getLangError(error.type),
              close: resetDeleteRights,
            }),
        }
      );
    }
  };
  const setRightsAsAgreed = (list: MediaView[]) => {
    if (list.length === 1) {
      updateRightsRequest(
        {
          mediaId: list[0]._id,
          rightsRequest: {
            status: RightRequestStatus.Agreed,
            replyTag: '',
            message: '',
          },
        },
        {
          onError: (error) =>
            addAlert({
              text: getLangError(error.type),
              close: resetUpdateRights,
            }),
        }
      );
    }
  };
  const setCreatorCountries = (list: MediaView[]) => {
    if (list.length > 0) {
      services.popups.openSetCreatorCountriesOverlay(list);
    }
  }
  const download = () => {
    const mediaIds = mediaList.map(({ _id }) => _id);
    if (mediaIds.length === 1) {
      // directly download the media
      const url = DownloadHelper.getMediaDownloadUrl(mediaIds, API_URL);
      DownloadHelper.triggerDownload(url);
    } else {
      // open the download popin
      services.browsing.goToPage(goToMediaDownload(window.location, mediaIds));
    }
  };

  const handlers: MediaActionHandlers = {
    addToCollection,
    removeFromCollection,
    getRights,
    resetRights,
    setRightsAsAgreed,
    setCreatorCountries,
    linkProduct: linkProduct ? linkProductHandler : undefined,
    unlinkProduct,
    download,
  };

  const isLoading = isDeletingRights || isUpdatingRights || isRemovingMedia;

  const list: FloatingMenuProps['list'] = createActionButtonList(
    {
      inCollection,
      mediaList,
      disable,
      catalogCountries,
    },
    handlers
  );

  return (
    <>
      <MediaActionButton list={list} loading={isLoading} />
    </>
  );
}

export function createActionButtonList(
  props: MediaActionProps,
  handlers: MediaActionHandlers
): FloatingMenuProps['list'] {
  // Indicate when we are not selecting multiple media
  // to avoid displaying buttons that can't act in bulk
  const singleMedia = props.mediaList.length === 1;

  const rightStatus = props.mediaList[0].rightsRequestStatus;

  const downloadWarning = shouldDisplayDownloadWarning(props.mediaList);

  const disable = props.disable || {};

  // we want to disable the button when no media is downloadable
  // Note: We do only prevent the download if the media is not available, if rights are not agreed it should still be donwloadable.
  const mediaWarnings = getMediaWarnings(props.mediaList);
  const downloadableMedia = getDownloadableMedia(mediaWarnings);
  if (downloadableMedia.length === 0) {
    // The message is empty as we already display a warning
    disable.download = '';
  }

  /**
   * Create a FloatingMenu item with the correct handler
   * If provided, contentSuffix will be append to the content
   */
  function createItem(
    id: ItemKeys<typeof mediaActionLang>,
    contentSuffix?: React.ReactNode
  ): FloatingMenuItem {
    // get provided or default handler if optional
    const handler = handlers[id] || (() => {});
    return {
      id,
      content: (
        // The div lets the contenSuffix be vertically aligned
        <div>
          <span>{mediaActionLang[id].toString()}</span>
          {contentSuffix}
        </div>
      ),
      disabledReason: disable?.[id],
      onClick: () => handler(props.mediaList),
    };
  }

  const list: FloatingMenuProps['list'] = [createItem('addToCollection')];

  // add remove if it's already in a collection
  if (props.inCollection !== undefined && !props.inCollection.locked) {
    list.push(createItem('removeFromCollection'));
  }

  // Only display right requests buttons when acting on
  // a single media
  if (singleMedia) {
    list.push(MenuDivider);

    // add get rights only if not already requested
    if (singleMedia && rightStatus === undefined) {
      list.push(createItem('getRights'));
    }

    // add reset, set as agreed, and creator localization depending on the right status
    if (rightStatus === 'agreed') {
      list.push(createItem('resetRights'));
      if (props.catalogCountries?.length) {
        list.push(MenuDivider);
        list.push(createItem('setCreatorCountries'));
      }
    } else {
      list.push(createItem('setRightsAsAgreed'));
    }
  } else {
    if (props.catalogCountries?.length) {
      list.push(MenuDivider);
      list.push(createItem('setCreatorCountries'));
    }
  }

  let linkProduct = handlers.linkProduct !== undefined;
  const unlinkProduct = handlers.unlinkProduct !== undefined;

  const isLinkable = LINKABLE_TYPE.includes(props.mediaList[0].type);

  // Open detailed view for multiple media or non image media doesn't make sense
  if (!singleMedia || !isLinkable) {
    linkProduct = false;
  }

  if (linkProduct || unlinkProduct) {
    list.push(MenuDivider);
  }
  if (linkProduct) {
    // Add button open the product linking view
    list.push(createItem('linkProduct'));
  }
  if (unlinkProduct) {
    // Add a button to unlink a particular list of product from a media
    list.push(createItem('unlinkProduct'));
  }

  // Download button
  // It displays if there's at least one downloadable type of media
  const displayDownload = props.mediaList.find(isMediaDownlodableByType);
  const displayDownloadWarning = isDownloadWarningTrue(downloadWarning);
  if (displayDownload) {
    list.push(MenuDivider);
    list.push(
      createItem(
        'download',
        displayDownloadWarning && (
          <DownloadWarning
            availability={downloadWarning.available}
            rights={downloadWarning.rights}
          />
        )
      )
    );
  }

  return list;
}
