export class DomHelper {
  /**
     * Check if the given dom element is hovered by the mouse
     */
  public static elementIsHovered(e: Element | Text | null): boolean {
    if (!e || !e.parentElement) {
      return false;
    }
    return e.parentElement.querySelector(':hover') === e;
  }
}
