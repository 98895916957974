import React from 'react';
import LocalizedStrings from 'react-localization';
import { style } from 'typestyle';
import { ADALONG_COLORS } from '@src/styles';
import Tooltip from '@mui/material/Tooltip';
import TaskAlt from '@mui/icons-material/TaskAlt';

import { createTheme, ThemeProvider } from '@mui/material';

interface Props {
  username?: string;
  activeInfluencer?: boolean;
}

const theme = createTheme({
  components: {
    MuiSvgIcon: {
      styleOverrides: {
        root: {
          fontSize: '14px',
          marginRight: '5px',
        },
      },
    },
  },
});

export function MediaUsername({ username, activeInfluencer }: Props) {
  return (
    <ThemeProvider theme={theme}>
      <Tooltip
        title={username ? '' : lang.tooltip} // Show only if username is not available
        placement='top'
      >
        <span className={usernameClass}>
          {activeInfluencer ? (
            <>
              <TaskAlt />
              {username || lang.noUsername}
            </>
          ) : (
            username || lang.noUsername
          )}
        </span>
      </Tooltip>
    </ThemeProvider>
  );
}

const lang = new LocalizedStrings({
  en: {
    noUsername: 'username unavailable',
    tooltip:
      'Instagram restricts data on media collected by hashtag. Needs to be tagged or mentioned to have the username',
  },
});

const usernameClass = style({
  color: ADALONG_COLORS.LIGHT_WHITE,
  display: 'inline-block',
  fontWeight: 'bold',
  marginTop: 6,
  marginBottom: 2,
  verticalAlign: 'inherit',
});
