import React, {
  createRef, RefObject, useEffect, useState,
} from 'react';
import { style } from 'typestyle';
import { PerfectScrollbar } from '@src/components/PerfectScrollbar';
import { CheckBoxLine } from '../CheckBoxLine';
import {XCLUSION_SELECTIONS, XclusionSelection} from "@src/types/Filters";
import {XclusionUtility} from "@src/fragments/Filters/managers/XclusionUtility";

export interface Value {key: string; children: React.ReactElement, selected?: boolean}

export interface Props {
  values: Value[]
  selectedValues: Value[]
  onChange: (val: Value['key'][], selected: boolean, xclusion: XclusionSelection) => void
  exclude?: boolean
}

export function Suggestions({
  values, selectedValues, onChange, exclude,
}: Props) {
  const [xclusion, setXclusion] = useState(exclude ? XCLUSION_SELECTIONS[1] : XCLUSION_SELECTIONS[0])
  useEffect(() => {
    setXclusion(exclude ? XCLUSION_SELECTIONS[1] : XCLUSION_SELECTIONS[0])
  }, [exclude])

  const renderLines = (valuesList: Value[], xclusion: XclusionSelection) => valuesList.map(({ children, key, selected = false }) => (
    <CheckBoxLine
      key={key}
      checked={selected}
      onChange={() => onChange([key], !selected, xclusion)}
      negative={selected ? exclude : false}
    >
      {children}
    </CheckBoxLine>
  ));

  const checkDoubles = (elements: Value[]): Value[] => {
   const elementsSet = new Set(elements.map(el => XclusionUtility.getRawKey(el.key)))
   return [...elementsSet].map((key) => ({key: key,
     children: elements.find(el => XclusionUtility.getRawKey(el.key) === key)!.children,
     selected: elements.find(el => XclusionUtility.getRawKey(el.key) === key)!.selected
   }))
  }
  const mapToSelected = (elements: Value[], selected: Value[]): Value[] => elements.map((val: Value) => {
    if (selected.find((selectedValue: Value) =>
        XclusionUtility.getRawKey(selectedValue.key) === XclusionUtility.getRawKey(val.key))
    ) {
      return {
        ...val,
        selected: true,
      };
    }
    return {
      ...val,
      selected: false,
    };
  });
  const scrollbarRef: RefObject<PerfectScrollbar> = createRef<PerfectScrollbar>();
  const [renderedValues, setRenderedValues] = useState(
      renderLines(checkDoubles(mapToSelected(values, selectedValues)), xclusion)
  );

  useEffect(() => {
    setRenderedValues(
        renderLines(checkDoubles(mapToSelected([...values, ...selectedValues], selectedValues)), xclusion)
    )
  }, [xclusion, selectedValues, values])

  return (
    <PerfectScrollbar
      className={resultsClass}
      ref={scrollbarRef}
      options={{
        wheelSpeed: 0.6,
      }}
    >
      {renderedValues}
    </PerfectScrollbar>
  );
}

const resultsClass = style({
  marginTop: 10,
  maxHeight: 115,
  overflow: 'hidden',
  position: 'relative',
  fontWeight: 'inherit',
});
