import React, { Component } from 'react';
import { connect } from 'react-redux';
import { ICombinedReducers, ReduxStore } from '@src/types/redux';

import * as reduxHelpers from '@src/services/helpers/redux';

import { ApiTypes } from 'common';
import { UserSettings } from '@src/pages/Login/UserSettings';

import { EPopup, CommonState } from '@src/types/redux/common';
import { Pathname, Browsing } from '@src/types/Browsing';
import { Services } from '@src/services';
import { ServiceName } from '@src/types/Services';

import { Login } from '@src/types/Login';
import LocalizedStrings from 'react-localization';
import {
  style, classes, media, stylesheet,
} from 'typestyle';
import { ADALONG_COLORS } from '@src/styles';
import { Column, Row } from '../Utils';
import { SelectableButton, SelectableButtonStyle } from '../SelectableButton';
import decathlonLogo from '../../assets/img/site/decathlon-logo-white.png';
import logo from '../../assets/img/site/logo_alone_white.png';
import { REDUX_ACTIONS } from '../../actions/_types';
import * as actionListener from '../../actionlistener';
import * as CommonActions from '../../actions/common';

interface StateToProps {
  user: ApiTypes.ResGetMe['user'] | null
  account: boolean
}

const mapStateToProps = (state: ICombinedReducers): StateToProps => ({
  account: state.common.popups.account || false,
  user: state.common.user,
});

interface DispatchToProps {
  openAccount: (open: CommonState['popups']['account']) => any
}

const mapDispatchToProps = (dispatch: any): DispatchToProps => ({
  openAccount: (open: CommonState['popups']['account']) => dispatch(CommonActions.setPopup(EPopup.account, open)),
});

interface OwnProps {
  active: Pathname
}

type IProps = StateToProps & DispatchToProps & OwnProps;

interface IState {}

class NavBar extends Component<IProps, IState> {
  private browsingService: Browsing;

  private loginService: Login;

  constructor(props: IProps) {
    super(props);
    this.browsingService = Services.get<Browsing>(ServiceName.Browsing);
    this.loginService = Services.get<Login>(ServiceName.Login);
  }

  public setRoute(route: Pathname) {
    reduxHelpers.setRoute(route);
  }

  public render() {
    if (
      !this.props.user ||
      !this.props.user.email ||
      !this.props.user.group ||
      !this.props.user.group.name
    ) {
      return null;
    }

    const isDecathlonUser = this.props.user.email.toLowerCase().includes('@decathlon') || this.props.user.group.name.toLowerCase().includes('decathlon');
    const isShareDisabled = this.isDisabled(this.props.user, 'share');
    const isWidgetDisabled = this.isDisabled(this.props.user, 'widget');
    const isCreateDisabled = this.isDisabled(this.props.user, 'create');
    const isProductDisabled = this.isDisabled(this.props.user, 'product_catalog');
    const isAnalyzeDisabled = false
    const isCRMDisabled = this.isDisabled(this.props.user, "crm");
    return (
      <nav className={classes(sheet.navbar)}>
        <div className={sheet.logo} onClick={() => this.browsingService.goToPage(Pathname.discover)}>
          {isDecathlonUser ? (
            <>
              <img src={decathlonLogo} className={sheet.decathlonLogoImg} />
              <span className={sheet.decathlonMessage}>SMART CONTENT NETWORK</span>
            </>
          ) : (
            <img src={logo} className={sheet.logoImg} />
          )}
        </div>

        <Column className={sheet.verticalButtonsBar}>
          <Row className={classes(sheet.navButtons, sheet.buttonsBar)}>
            <SelectableButton
              classes={[buttonClass]}
              selected={this.activeClass(Pathname.discover)}
              buttonStyle={buttonStyle}
              onClick={() => this.browsingService.goToPage(Pathname.discover)}
            >
              {lang.library.toUpperCase()}
            </SelectableButton>
            <SelectableButton
              classes={isProductDisabled ? [buttonClass, sheet.disabled] : [buttonClass]}
              selected={this.activeClass(Pathname.product)}
              buttonStyle={buttonStyle}
              onClick={() => {
                this.browsingService.goToPage(Pathname.product);
              }}
              disable={isProductDisabled ? lang.premiumFeatureWarning : undefined}
            >
              {lang.product.toUpperCase()}
            </SelectableButton>
            <SelectableButton
              classes={[buttonClass]}
              selected={this.activeClass(Pathname.collect)}
              buttonStyle={buttonStyle}
              onClick={() => this.browsingService.goToPage(Pathname.collect)}
            >
              {lang.collections.toUpperCase()}
            </SelectableButton>
            <SelectableButton
              classes={isShareDisabled ? [buttonClass, sheet.disabled] : [buttonClass]}
              selected={this.activeClass(Pathname.share)}
              buttonStyle={buttonStyle}
              onClick={() => this.browsingService.goToPage(Pathname.share)}
              disable={isShareDisabled ? lang.premiumFeatureWarning : undefined}
            >
              {lang.share.toUpperCase()}
            </SelectableButton>
            <SelectableButton
              classes={isWidgetDisabled ? [buttonClass, sheet.disabled] : [buttonClass]}
              selected={this.activeClass(Pathname.widget)}
              buttonStyle={buttonStyle}
              onClick={() => this.browsingService.goToPage(Pathname.widget)}
              disable={isWidgetDisabled ? lang.premiumFeatureWarning : undefined}
            >
              {lang.widgets.toUpperCase()}
            </SelectableButton>
            <SelectableButton
              classes={isCreateDisabled ? [buttonClass, sheet.disabled] : [buttonClass]}
              selected={this.activeClass(Pathname.create)}
              buttonStyle={buttonStyle}
              onClick={() => {
                this.browsingService.goToPage(Pathname.create);
              }}
              disable={isCreateDisabled ? lang.premiumFeatureWarning : undefined}
            >
              {lang.editor.toUpperCase()}
            </SelectableButton>
            <SelectableButton
                classes={isAnalyzeDisabled ? [buttonClass, sheet.disabled] : [buttonClass]}
                selected={this.activeClass(Pathname.analyze)}
                buttonStyle={buttonStyle}
                onClick={() => {
                  this.browsingService.goToPage(Pathname.analyze);
                }}
                disable={isAnalyzeDisabled ? lang.premiumFeatureWarning : undefined}
            >
              {lang.analyze.toUpperCase()}
            </SelectableButton>
            <SelectableButton
              classes={
                isCRMDisabled ? [buttonClass, sheet.disabled] : [buttonClass]
              }
              selected={this.activeClass(Pathname.crm)}
              buttonStyle={buttonStyle}
              onClick={() => {
                this.browsingService.goToPage(Pathname.crm);
              }}
              disable={isCRMDisabled ? lang.premiumFeatureWarning : undefined}
            >
              {lang.crm.toUpperCase()}
            </SelectableButton>
          </Row>
        </Column>
        <Column className={sheet.accountButton}>
          <UserSettings
            user={this.props.user}
            onLogout={() => this.loginService.logout()}
            onSwitchGroup={(groupId) => this.loginService.switchGroup(groupId)}
          />
        </Column>
      </nav>
    );
  }

  private activeClass(tab: Pathname): boolean {
    return tab === this.props.active;
  }

  private isDisabled(
    user: ApiTypes.ResGetMe['user'],
    feature: keyof ApiTypes.ResGetMe['user']['groupFeatures'],
  ): boolean {
    if (!user.groupFeatures[feature]) {
      return true;
    }
    return false;
  }
}

const lang = new LocalizedStrings({
  en: {
    library: 'Discover',
    collections: 'Collect',
    editor: 'Create',
    analyze: 'Analyze',
    crm: 'CRM',
    share: 'Share',
    widgets: 'Widget',
    product: 'Products',
    logout: 'Log out',
    settings: 'Settings',
    premiumFeatureWarning: 'This is a premium feature. Please contact us for more information.',
  },
});

const sheet = stylesheet({
  navbar: {
    paddingTop: 25,
    paddingBottom: 10,
    flexShrink: 0,
    display: 'flex',
    flexDirection: 'row',
    justifyContent: 'space-between',
    $nest: {
      img: {
        cursor: 'pointer',
      },
    },
  },
  navButtons: {
    flexDirection: 'row',
    fontSize: 14,
    float: 'none',
  },
  decathlonMessage: {
    marginTop: 'auto',
    fontFamily: '\'Roboto Condensed\'',
    fontWeight: 'bold',
    paddingLeft: 20,
    display: 'inline-block',
    position: 'relative',
    top: '50%',
    transform: 'translate(0px, -50%)',
    verticalAlign: 'top',
    fontSize: '1rem',
    height: '0.75rem',
    lineHeight: '0.8rem',
  },

  logo: {
    paddingLeft: 13,
  },

  logoImg: {
    height: 60,
  },

  decathlonLogoImg: {
    width: 90,
    margin: 12,
  },

  disabled: {
    color: 'gray !important',
  },

  buttonsBar: {
    justifyContent: 'center',
    paddingLeft: 0,
    marginBottom: 0,
    listStyle: 'none',
    flexGrow: 0,
  },
  verticalButtonsBar: {
    justifyContent: 'center',
  },
  accountButton: {
    marginRight: 18,
    justifyContent: 'center',
    textAlign: 'right',
    flex: 0,
  },
});

const buttonClass = style({
  padding: '0 22px',
  fontWeight: 'bold',
}, media({
  maxWidth: 950,
}, {
  padding: '0 12px',
}), media({
  maxWidth: 800,
}, {
  padding: '0 4px',
}));

const buttonStyle: SelectableButtonStyle = {
  size: 14,
  borderColor: '#0041fa',
  textColor: ADALONG_COLORS.LIGHT_WHITE,
  borderSize: 3,
};

export default connect<StateToProps, any, OwnProps, ICombinedReducers>(
  mapStateToProps,
  mapDispatchToProps,
)(NavBar as any);

actionListener.on(REDUX_ACTIONS.COMMON.MOUSE_CLICK, (store: ReduxStore) => {
  store.dispatch(CommonActions.setPopup(EPopup.account, false));
});
