import React, { useState } from 'react';
import { SectionFolder } from '@src/components/SectionFolder';
import { SectionTitle } from '@src/components/SectionTitle';
import { ContentFiltersIcon } from '../../Icons/DropDownIcons/ContentFilters';
import { SectionProps } from '../types/Settings';
import LocalizedStrings from 'react-localization';
import {
  FORM_LINE_TITLE_SIZE,
  FORM_VALUE_TITLE_SIZE,
  INPUT_WIDTH,
  SECTION_TITLE_SIZE,
} from '../utils/variables';
import { SectionContent } from '../subcomponents/SectionContent';
import { style } from 'typestyle';
import { createChangeSettings } from '../utils/createChangeSettings';
import { FormLine } from '@src/components/Form/FormLine';
import { SubSectionContent } from '../subcomponents/SubSectionContent';
import { Input } from '@src/components/Input';
import { SettingsCheckbox } from '../subcomponents/SettingsCheckbox';
import { CheckboxNote } from '../subcomponents/CheckboxNote';
import { ADALONG_COLORS } from '@src/styles';

const ContentFilters = ({ opened, onOpen, editedWidget, setEditedWidget }: SectionProps) => {
  const changeSettings = createChangeSettings(editedWidget, setEditedWidget);

  function updateMinMedia(targetValue: HTMLInputElement['value']): void {
    const newValue = parseInt(targetValue, 10);
    if (newValue < 0) {
      return;
    }
    changeSettings({
      min_media: newValue,
    });
  }

  function updateMaxMedia(targetValue: HTMLInputElement['value']): void {
    const newValue = parseInt(targetValue, 10);
    changeSettings({
      max_media: newValue > 0 ? newValue : null,
    });
  }

  function updateMinExactProducts(targetValue: HTMLInputElement['value']): void {
    const newValue = parseInt(targetValue, 10);
    if (newValue < 0) {
      return;
    }
    changeSettings({
      min_exact_products: newValue,
    });
  }

  function updateMinSimilarProducts(targetValue: HTMLInputElement['value']): void {
    const newValue = parseInt(targetValue, 10);
    if (newValue < 0) {
      return;
    }
    changeSettings({
      min_similar_products: newValue,
    });
  }

  return (
    <SectionFolder
      title={
        <SectionTitle size={SECTION_TITLE_SIZE}>
          <ContentFiltersIcon />
          {lang.title}
        </SectionTitle>
      }
      opened={opened}
      onOpen={onOpen}
    >
      <SectionContent>
        <FormLine title={lang.fields.numberUGCs} size={FORM_LINE_TITLE_SIZE}>
          <SubSectionContent>
            <div className={minMaxClass}>
              <FormLine title={lang.fields.min} size={FORM_VALUE_TITLE_SIZE}>
                <Input
                  type='number'
                  onChange={({ target }) => updateMinMedia(target.value)}
                  value={editedWidget.settings.min_media}
                  title={lang.tooltips.minMedia}
                  style={{ width: INPUT_WIDTH, marginRight: '10px' }}
                />
              </FormLine>

              <FormLine title={lang.fields.max} size={FORM_VALUE_TITLE_SIZE}>
                <Input
                  type='number'
                  onChange={({ target }) => updateMaxMedia(target.value)}
                  value={editedWidget.settings.max_media || ''}
                  placeholder={lang.fields.maxPlaceholder}
                  title={lang.tooltips.maxMedia}
                  style={{ width: INPUT_WIDTH, marginRight: '10px' }}
                />
              </FormLine>
            </div>
          </SubSectionContent>
        </FormLine>

        <SubSectionContent>
          <SettingsCheckbox
            label={lang.fields.rightsAgreed}
            checked={editedWidget.settings.rights_agreed}
            onClick={() =>
              changeSettings({
                rights_agreed: !editedWidget.settings.rights_agreed,
              })
            }
          />
          <CheckboxNote marginBottom={8} fontSize={11} color={ADALONG_COLORS.LIGHT_GRAY}>
            {lang.checkboxNotes.rightsAgreed}
          </CheckboxNote>

          <p className={logicalConnectorClass}> {lang.fields.and} </p>

          <SettingsCheckbox
            label={lang.fields.diffusion}
            checked={editedWidget.settings.diffusion}
            onClick={() =>
              changeSettings({
                diffusion: !editedWidget.settings.diffusion,
              })
            }
          />

          <p className={logicalConnectorClass}> {lang.fields.and} </p>
        </SubSectionContent>

        <div className={productFilterClass}>
          {' '}
          <SubSectionContent>
            <SettingsCheckbox
              label={lang.fields.hasExact}
              checked={editedWidget.settings.has_exact_products}
              onClick={() =>
                changeSettings({
                  has_exact_products: !editedWidget.settings.has_exact_products,
                })
              }
            />
            <FormLine title={lang.fields.min} size={FORM_VALUE_TITLE_SIZE}>
              <Input
                type='number'
                onChange={({ target }) => updateMinExactProducts(target.value)}
                value={editedWidget.settings.min_exact_products || ''}
                placeholder={lang.fields.maxPlaceholder}
                title={lang.tooltips.maxMedia}
                style={{ width: INPUT_WIDTH, marginRight: '10px' }}
              />
            </FormLine>
            <p className={logicalConnectorClass}> {lang.fields.or} </p>
            <SettingsCheckbox
              label={lang.fields.hasSimilar}
              checked={editedWidget.settings.has_similar_products}
              onClick={() =>
                changeSettings({
                  has_similar_products: !editedWidget.settings.has_similar_products,
                })
              }
            />
            <FormLine title={lang.fields.min} size={FORM_VALUE_TITLE_SIZE}>
              <Input
                type='number'
                onChange={({ target }) => updateMinSimilarProducts(target.value)}
                value={editedWidget.settings.min_similar_products || ''}
                placeholder={lang.fields.maxPlaceholder}
                title={lang.tooltips.maxMedia}
                style={{ width: INPUT_WIDTH, marginRight: '10px' }}
              />
            </FormLine>
          </SubSectionContent>
        </div>
      </SectionContent>
    </SectionFolder>
  );
};

const lang = new LocalizedStrings({
  en: {
    title: 'Content Filters',
    fields: {
      and: 'AND',
      or: 'OR',
      numberUGCs: 'Number of UGCs',
      max: 'Max',
      maxPlaceholder: 'Infinite',
      min: 'Min',
      rightsAgreed: 'Rights agreed only',
      diffusion: 'Diffusion enabled only',
      hasExact: 'With EXACT products linked',
      hasSimilar: 'With SIMILAR products linked',
    },
    tooltips: {
      minMedia: 'Minimum number of media required for the widget to display',
      maxMedia: 'Maximum number of media to show inside the widget',
    },
    checkboxNotes: {
      rightsAgreed: '*Videos are only available as rights agreed',
    },
  },
});

const minMaxClass = style({
  display: 'flex',
  flexDirection: 'row',
});

const productFilterClass = style({
  backgroundColor: ADALONG_COLORS.SMOOTH_BLACK,
  borderRadius: 10,
  paddingTop: 10,
  paddingBottom: 10,
  color: 'white',
});

const logicalConnectorClass = style({
  fontSize: 12,
  fontWeight: 'bold',
});

export default ContentFilters;
