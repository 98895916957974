import React, { useState } from 'react';
import { SectionFolder } from '@src/components/SectionFolder';
import { SectionTitle } from '@src/components/SectionTitle';
import { LocalizationIcon } from '../../Icons/DropDownIcons/Localization';
import { SectionProps } from '../types/Settings';
import LocalizedStrings from 'react-localization';
import { SECTION_TITLE_SIZE } from '../utils/variables';
import { SectionContent } from '../subcomponents/SectionContent';
import { DropDown } from '@src/components/DropDown';
import { ProductsLocalizationMapping } from 'common';
import { style } from 'typestyle';
import { createChangeSettings } from '../utils/createChangeSettings';

interface LocalizationProps extends SectionProps {
  productCountryMappings?: ProductsLocalizationMapping[];
  setCurrentLocalization: (loc: string) => void;
}

const Localization = ({
  opened,
  onOpen,
  editedWidget,
  setEditedWidget,
  productCountryMappings,
  setCurrentLocalization,
}: LocalizationProps) => {
  const [displayDropdown, setDisplayDropdown] = useState<boolean>(false);
  const changeSettings = createChangeSettings(editedWidget, setEditedWidget);

  function selectLocalization(e: React.MouseEvent<HTMLDivElement, MouseEvent>, loc: string) {
    e.stopPropagation();
    changeSettings({
      localization: loc,
    });
    setDisplayDropdown(false);
    setCurrentLocalization(loc);
  }

  return (
    <SectionFolder
      title={
        <SectionTitle size={SECTION_TITLE_SIZE}>
          <LocalizationIcon />
          {lang.title}
        </SectionTitle>
      }
      opened={opened}
      onOpen={onOpen}
    >
      <SectionContent>
        {productCountryMappings?.length ? (
          <DropDown
            title={editedWidget.settings.localization || 'Select a localization'}
            display={displayDropdown}
            onDisplay={setDisplayDropdown}
          >
            {productCountryMappings.map((mapping, i) =>
              mapping.name !== editedWidget.settings.localization ? (
                <div
                  className={selectionClass}
                  key={i}
                  data-selected={mapping.name === editedWidget.settings.localization}
                  onClick={(e) => selectLocalization(e, mapping.name)}
                >
                  {mapping.name}
                </div>
              ) : null,
            )}
          </DropDown>
        ) : (
          lang.fields.noLocalizationAvailable
        )}
      </SectionContent>
    </SectionFolder>
  );
};

const lang = new LocalizedStrings({
  en: {
    title: 'Localization',
    fields: { noLocalizationAvailable: 'No localization available' },
  },
});

const selectionClass = style({
  $nest: {
    '&:hover': {
      cursor: 'pointer',
    },
  },
});

export default Localization;
