import React from 'react';
import { classes, stylesheet } from 'typestyle';
import LocalizedStrings from 'react-localization';
import { BarelyVisibleText } from './BarelyVisibleText';
import { CloseButton } from './CloseButton';
import { Row } from './Utils';

interface Props {
  /**
     * Number of selected media
     */
  count: number
  className?: string
  mediaActionButton: React.ReactNode
  onClear: () => void
}

/**
 * Display a button to show possible actions for
 * the selected media list
 */
export function BulkMediaActions({
  count,
  className,
  mediaActionButton,
  onClear,
}: Props) {
  if (!count) {
    return (
      <div className={classes(sheet.container, sheet.noMedia, className)}>
        <BarelyVisibleText>{lang.indication}</BarelyVisibleText>
      </div>
    );
  }
  const plural = count > 1 ? 's' : '';
  return (
    <Row className={classes(sheet.container, className)}>
      <div className={sheet.flexCell}>
        <span className={sheet.selectedNumber}>
          {count}
          {' '}
          {lang.formatString(lang.selected, plural)}
        </span>
      </div>
      <div className={sheet.flexCell}>
        {mediaActionButton}
      </div>
      <div className={sheet.flexCell}>
        <CloseButton
          classNames={[sheet.close]}
          onClick={onClear}
        />
      </div>
    </Row>
  );
}

const lang = new LocalizedStrings({
  en: {
    selected: 'content{0} selected',
    indication: 'Press shift+click to select multiple medias',
    unselect: 'unselect',
    collect: 'collect',
  },
});

const sheet = stylesheet({
  container: {
    minHeight: 25,
  },
  noMedia: {
    paddingTop: 2,
  },
  flexCell: {
    margin: '0px 5px',
  },
  selectedNumber: {
    fontSize: 11,
    display: 'inline',
    verticalAlign: 'text-top',
    marginRight: 10,
  },
  close: {
    verticalAlign: 'baseline',
    paddingTop: 2,
  },
});
