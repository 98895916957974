import { useQuery, UseQueryOptions } from 'react-query';
import { DEPRECATED_QUERY_KEYS } from '@src/query';
import { MediaView } from 'common';
import { ImageResolver } from '@src/services/helpers/imageResolver';
import { Dimensions } from '@src/types/Dimensions';

/**
 * Find products linked to any media
 */
export const useQueryMediaDimensions = (media: MediaView | undefined, config?: UseQueryOptions<Dimensions | undefined>) => useQuery({
  queryKey: [DEPRECATED_QUERY_KEYS.MEDIA_DIMENSIONS, media?._id],
  queryFn: async () => {
    if (!media?.cdn_image) {
      return undefined;
    }

    const res = await ImageResolver.load(media.cdn_image).catch((e) => {
      console.warn(`Can't load image ${media.cdn_image} from media ${media}: ${e.stack}`);
    });
    if (!res) {
      return undefined;
    }
    const dimensions: Dimensions = {
      width: res.imgElement.naturalWidth,
      height: res.imgElement.naturalHeight,
    };
    return dimensions;
  },
  ...config,
});
