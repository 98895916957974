import React, { PropsWithChildren } from 'react';
import { style, classes } from 'typestyle';
import { ADALONG_COLORS } from '@src/styles';

interface Props {
  selected?: boolean
  onClick?: () => void
  classes?: string[]
  buttonStyle?: SelectableButtonStyle
  disable?: string | false
}

export interface SelectableButtonStyle {
  size?: number
  borderSize?: number
  borderColor?: string
  weightWhenSelected?: number | 'inherit'

  /**
     * Text color when not selected
     */
  textColor?: string
}

export function SelectableButton(
  props: PropsWithChildren<Props>,
) {
  const {
    buttonStyle = {},
    classes: classNames = [],
    disable,
  } = props;
  const {
    size = 9,
    borderColor = 'white',
    borderSize = 2,
    weightWhenSelected = 700,
    textColor = ADALONG_COLORS.DARK_GRAY,
  } = buttonStyle;
  return (
    <div
      className={classes(buttonClass, ...classNames)}
      onClick={() => !disable && props.onClick?.()}
      style={{ fontSize: size, color: textColor }}
    >
      <div
        data-selected={props.selected}
        style={{
          fontWeight: props.selected ? weightWhenSelected : 'inherit',
        }}
      >
        {props.children}
        {props.selected && (
        <div
          className={lineClass}
          style={{
            backgroundColor: borderColor,
            height: borderSize,
          }}
        />
        )}
      </div>
    </div>
  );
}

const buttonClass = style({
  display: 'inline-block',
  cursor: 'pointer',
  $nest: {
    '& > div': {
      borderBottom: '3px solid transparent',
      display: 'inline-block',
      position: 'relative',
      $nest: {
        '&[data-selected="true"]': {
          color: 'white !important',
        },
      },
    },
  },
  $debugName: 'SelectableButton',
});

const lineClass = style({
  content: '',
  position: 'absolute',
  display: 'inline-block',
  top: '105%',
  left: 0,
  width: '100%',
  borderRadius: 5,
});
