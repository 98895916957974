import { useEffect, useRef } from 'react';

/**
 * Keep previous value in a functional react component
 * Useful to access previous props for example
 */
export default function usePrevious<T>(value: T) {
  const ref = useRef<T>();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
}
