/*
**	Share Action Creators
*/

import { IPRightsTemplate } from '@src/types/IPRights';
import { REDUX_ACTIONS } from './_types';

export function setRightsTemplateFr(fr: IPRightsTemplate['fr']) {
  return { type: REDUX_ACTIONS.RIGHTS_REQUESTS.SET_RIGHTSTEMPLATE_FR, fr };
}
