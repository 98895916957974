import React, {useCallback, useEffect, useRef, useState} from 'react';

/* Redux */
import {connect} from 'react-redux';
import {ICombinedReducers} from '@src/types/redux';
import * as reduxHelpers from '@src/services/helpers/redux';
import {bigStore} from '@src/store';

/* Styling & Icons */
import {style, stylesheet} from 'typestyle';
import EditIcon from '@mui/icons-material/Edit';
import InstagramIcon from '@mui/icons-material/Instagram';
import Chip from '@mui/material/Chip';
import {CircularProgress, createTheme, ThemeProvider} from '@mui/material';
import {ADALONG_COLORS} from '@src/styles';

/* Misc */
import {EPopup} from '@src/types/redux/common';
import LocalizedStrings from 'react-localization';

/* Api */
/* Components */
import {OverlayContainer} from '@src/components/OverlayContainer';
import {CloseButton} from '@src/components/CloseButton';
import {Row} from '@src/components/Utils';
import {Loader} from '@src/components/Loader';
import {PerfectScrollbar} from 'components/PerfectScrollbar';
import {ActionButton} from '@src/components/ActionButton';
import {Hide} from '@src/components/Hide';
import {FormError} from '@src/components/Form/FormError';

/* Types */
import {FacebookPage, CreatorView, UserView} from 'common';
import {Services} from '@src/types/Services';
import {SocialNetworkButton, SocialNetworkType} from '../../../../components/SocialNetworkButton';
import {useQueryInfluencersCreators, useQueryBlacklistedCreators} from '../../../../queries/influencers/useQueryCreators';
import {api} from '../../../../services/api';
import {DispatchType} from '../../../../store';
import * as CommonActions from '../../../../actions/common';
import styles from './styles.scss';

interface OwnProps {
  services: Services;
}

interface IProps {
  user: UserView | null;
  loadFacebookPages: () => void;
  focus?: boolean;
  loadUserInfo: () => Promise<{
    user: UserView;
  }>;
  close: () => void;
  loadGroupMentionPages: () => Promise<void>;
  oauth: Record<SocialNetworkType, boolean>;
  removeOauth: (social: SocialNetworkType) => void;
  facebookPages: FacebookPage[];
}

type Props = OwnProps & IProps;

const materialTheme = createTheme({
  palette: {
    primary: {
      main: ADALONG_COLORS.LIGHT_GRAY,
    },
  },
} as any);

export function Settings(props: Props) {
  const [mounted, setMounted] = useState(false);
  const [mentionFb, setMentionFb] = useState(false);
  const [refreshCount, setRefreshCount] = useState(0);
  const [selectingPage, setSelectingPage] = useState({
    pageId: '',
    status: false,
  });
  useEffect(() => {
    setMentionFb(props.oauth["facebook"] && !isEmpty(props.oauth["facebook"]));
  }, [props.oauth["facebook"]])

  const isEmpty = (obj: Object) => Object.keys(obj).length === 0;

  const { data: influencers = [], isLoading, error: errorLoading } = useQueryInfluencersCreators();
  const { data: blacklist = [], isLoading: blacklistedLoading, error: blacklistedError } = useQueryBlacklistedCreators();

  const whitelist = influencers.filter((inf) => !inf.isBlacklisted);

  const getCreatorCountries = (creator: CreatorView): string => {
    return creator.countries ? creator.countries.join(', ') : '';
  };

  const getInfluencerStatus = (influencer: CreatorView) => {
    if (influencer.isInfluencerDisabled) {
      return 'inactive';
    }
    return 'active';
  };

  const getInfluencerStatusVariant = (influencer: CreatorView) => {
    if (influencer.isInfluencerDisabled) {
      return 'outlined';
    }
    return undefined;
  };

  const influencersRef = useRef(null);

  const blacklistRef = useRef(null)

  const openAddInfluencer = () => {
    bigStore.dispatch(CommonActions.setPopup(EPopup.addInfluencer, true));
  };

  const openAddBlacklist = () => {
    bigStore.dispatch(CommonActions.setPopup(EPopup.addBlacklist, true))
  }

  const openEditInfluencer = (influencer: CreatorView) => {
    bigStore.dispatch(CommonActions.setPopup(EPopup.editInfluencer, influencer));
  };

  /* instagram pages */
  const instagramPages = (props.facebookPages || [])
    .filter((p) => p.instagram)
    .map((p) => ({
      page: p.instagram!,
      fbToken: p.token,
    }));
  const pages = useRef(null);

  const onSelectPage = (page: FacebookPage, fbPageToken: string) => {
    if (!page || selectingPage.pageId) {
      return;
    }
    setSelectingPage({ pageId: page.id, status: true });
    api
      .setMentionPage({
        id: page.id,
        token: page.token,
        name: page.name,
        fbPageToken, // For instagram, we need the facebook page token to subscribe to the app
        source: 'instagram',
      })
      .then(
        () => {
          setSelectingPage({ pageId: '', status: false });
          props.loadUserInfo();
          props.loadGroupMentionPages();
        },
        (err) => {
          console.error(err);
          setSelectingPage({ pageId: page.id, status: false });
          reduxHelpers.addAlert({
            type: 'danger',
            text: window.T('common.settings.mention.error_set'),
          });
        }
      );
  };

  const getPageStatus = (selected: boolean) => {
    if (selected) {
      return 'main';
    }
    return 'secondary';
  };

  const getPageStatusVariant = (selected: boolean) => {
    if (selected) {
      return undefined;
    }
    return 'outlined';
  };

  /* general functions */
  const refresh = useCallback((): void | NodeJS.Timeout => {
    const loop = () => setTimeout(
      () => setRefreshCount(refreshCount + 1),
      3000,
    )
    if (!props.focus) {
      return loop();
    }

    const facebookSigned = props.user?.oauth?.facebook;
    props.loadUserInfo().then(({ user }) => {
      if (!facebookSigned && user.oauth && user.oauth.facebook) {
        props.loadFacebookPages();
      }
      loop();
    });
  }, [refreshCount, props.user]);

  useEffect(() => {
    if (!mounted) {
      return;
    }
    refresh();
  }, [refreshCount])

  const close = useCallback(() => {
    props.close();
  }, []);

  useEffect(() => {
    setMounted(true);
    refresh();
    if (props.user?.oauth?.facebook) {
      props.loadFacebookPages();
    }
    return () => {
      setMounted(false);
    };
  }, []);

  if (!props.user) {
    return null; // TODO: loading
  }

  return (
    <>
      <ThemeProvider theme={materialTheme}>
        <OverlayContainer
          containerClasses={[styles.settingsContainer]}
          frameClasses={[styles.settings]}
          onClose={close}
        >
          <Row className={headClass}>
            <div className={sheet.title}>{lang.title}</div>
            <CloseButton size='large' classNames={[sheet.closeButton]} onClick={close} />
          </Row>
          <PerfectScrollbar className={contentClass}>
            <section id='oauth'>
              <div>
                <div className='title'>{window.T('common.settings.oauth.title')}</div>
                <div className='desc'>{window.T('common.settings.oauth.desc')}</div>
              </div>
              <div className='buttons'>
                {(['facebook'] as const).map((social) => (
                  <SocialNetworkButton
                    social={social}
                    key={social}
                    linked={props.oauth[social] && !isEmpty(props.oauth[social])}
                    onRemove={(social) => props.removeOauth(social)}
                  />
                ))}
              </div>
            </section>
            <section id='mention'>
              <div>
                <div className='title'>{window.T('common.settings.mention.title')}</div>
                <div className='desc'>
                  {window.T(`common.settings.mention.${mentionFb ? 'desc' : 'desc_link'}`)}
                </div>
              </div>
              {mentionFb && (
                <PerfectScrollbar
                  className='pages'
                  ref={pages}
                  data-emptytext={
                    instagramPages.length ? false : window.T('common.settings.mention.empty')
                  }
                >
                  {instagramPages.map(({ page, fbToken }, i) => {
                    const mention = props.user?.mention || {};
                    const selected = page.id === mention.instagram?.id;
                    const selecting = !!selectingPage.pageId;
                    return (
                      <div
                        className='page'
                        key={i}
                        title={page.name}
                        onClick={() => onSelectPage(page, fbToken)}
                        data-selected={selected}
                      >
                        <span>
                          <InstagramIcon
                            style={{
                              fontSize: 20,
                              marginRight: 5,
                            }}
                          />
                          {page.name}
                        </span>
                        <div className='page-selectors' data-selecting={selecting}>
                          <div
                            data-selected={selected}
                            onClick={(e) => {
                              e.stopPropagation();
                              onSelectPage(page, fbToken);
                            }}
                          >
                            <Chip
                              label={getPageStatus(selected)}
                              variant={getPageStatusVariant(selected)}
                              size='small'
                              color='primary'
                              sx={{ r: 5 }}
                            />
                          </div>
                        </div>
                        {!selecting ? null : (
                          <div className={styles.loading}>
                            <Loader size={12} />
                          </div>
                        )}
                      </div>
                    );
                  })}
                </PerfectScrollbar>
              )}
            </section>

            <section id='influencers'>
              <div>
                <div className='title'>{lang.influencerTitle}</div>
                <div className='desc'>{lang.influencerDesc}</div>
              </div>

              {whitelist.length > 0 && (
                <PerfectScrollbar className='influencer-list' ref={influencersRef}>
                  {isLoading ? (
                    <CircularProgress color='inherit' />
                  ) : (
                    whitelist.map((influencer) => (
                      <div className='influencer' key={influencer._id} title={influencer.username}>
                        <span className='influencer-info'>
                          <InstagramIcon
                            style={{
                              fontSize: 20,
                              marginRight: 5,
                            }}
                          />
                          {influencer.username}
                        </span>
                        <div className='influencer-info'>
                          {getCreatorCountries(influencer)}
                        </div>
                        <div>
                          <Chip
                            label={getInfluencerStatus(influencer)}
                            variant={getInfluencerStatusVariant(influencer)}
                            size='small'
                            color='primary'
                            sx={{ r: 5 }}
                          />
                          <EditIcon
                            onClick={() => openEditInfluencer(influencer)}
                            className={editClass}
                            style={{
                              fontSize: 20,
                            }}
                          />
                        </div>
                      </div>
                    ))
                  )}
                </PerfectScrollbar>
              )}

              <Hide hide={!errorLoading}>
                <FormError error={lang.error.refresh} />
              </Hide>

              <div className={sheet.button}>
                <ActionButton variant='blue' onClick={() => openAddInfluencer()} size='medium'>
                  {lang.button.toUpperCase()}
                </ActionButton>
              </div>
            </section>

            <section id='blacklist'>
              <div>
                <div className='title'>{lang.blacklistTitle}</div>
              </div>

              {blacklist.length > 0 && (
                  <PerfectScrollbar className='influencer-list' ref={blacklistRef}>
                    {blacklistedLoading ? (
                        <CircularProgress color='inherit' />
                    ) : (
                        blacklist.map((creator) => (
                            <div className='influencer' key={creator._id} title={creator.username}>
                        <span className='influencer-info'>
                          <InstagramIcon
                              style={{
                                fontSize: 20,
                                marginRight: 5,
                              }}
                          />
                          {creator.username}
                        </span>
                              <div className='influencer-info'>
                                {getCreatorCountries(creator)}
                              </div>
                              <div>
                                <Chip
                                    label={'blacklisted'}
                                    variant={'filled'}
                                    size='small'
                                    color='primary'
                                    sx={{ r: 5 }}
                                />
                                <EditIcon
                                    onClick={() => openEditInfluencer(creator)}
                                    className={editClass}
                                    style={{
                                      fontSize: 20,
                                    }}
                                />
                              </div>
                            </div>
                        ))
                    )}
                  </PerfectScrollbar>
              )}

              <Hide hide={!errorLoading && !blacklistedError}>
                <FormError error={lang.error.refresh} />
              </Hide>

              <div className={sheet.button}>
                <ActionButton variant='blue' onClick={() => openAddBlacklist()} size='medium'>
                  {lang.blackListButton.toUpperCase()}
                </ActionButton>
              </div>
            </section>

          </PerfectScrollbar>
        </OverlayContainer>
      </ThemeProvider>
    </>
  );
}

const lang = new LocalizedStrings({
  en: {
    title: 'Organization Settings',
    blacklistTitle: 'Blacklisted creators',
    influencerTitle: 'Instagram Influencer Whitelist',
    influencerDesc:
      "Add all your instagram influencer's usernames in this section to automatically set the rights agreed on their content, as well as to access special filter options.",
    button: 'Add Influencer',
    blackListButton: 'Blacklist a creator',
    cancel: 'Cancel',
    error: {
      refresh: 'Unable to get influencer list. Please try again or contact us.',
      unknown: 'An error occurred while removing the influencer, please try again or contact us.',
    },
  },
});

const headClass = style({
  flexShrink: 0,
});

const contentClass = style({
  paddingRight: 10,
});

const editClass = style({
  paddingLeft: 5,
  cursor: 'pointer',
  color: 'gray',
  transition: 'color 0.2s',
  $nest: {
    '&:hover': {
      color: 'white',
    },
  },
});

const sheet = stylesheet({
  title: {
    flex: 1,
    textAlign: 'center',
    fontSize: 30,
    marginBottom: 25,
  },
  closeButton: {
    flexShrink: 0,
  },
  button: {
    width: '100%',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
  },
});

export default connect(
  (state: ICombinedReducers) => ({
    focus: state.common.windowFocus,
    opened: state.common.settingsPopup,
    user: state.common.user,
    oauth: state.common.oauth,
    facebookPages: state.common.facebookPages,
    // admin: state.common.admin,
    influencers: state.common.influencers,
  }),
  (dispatch: DispatchType) => ({
    loadFacebookPages: () => dispatch(CommonActions.loadFacebookPages()),
    loadUserInfo: () => dispatch(CommonActions.loadUserInfo()),
    loadGroupMentionPages: () => dispatch(CommonActions.loadGroupMentionPages()),
    removeOauth: (social: SocialNetworkType) => dispatch(CommonActions.removeOauth(social)),
    setOauth: (social: SocialNetworkType, enabled: boolean) =>
      dispatch(CommonActions.setOauth(social, enabled)),
    close: () => dispatch(CommonActions.setPopup(EPopup.settings, false)),
  })
)(Settings);
