import React, { useState } from 'react';

import { PerfectScrollbar } from '@src/components/PerfectScrollbar';

import { MediaType, XCLUSION_SELECTIONS } from '@src/types/Filters';
import { SOURCE_NETWORKS } from '@src/types/SourceNetwork';
import LocalizedStrings from 'react-localization';
import { FeatureDisabler } from '@src/components/FeatureDisabler';
import { classes, stylesheet } from 'typestyle';
import { ADALONG_COLORS } from '@src/styles';
import { CollectionSuggestion, isInParam } from '@src/fragments/Filters/Search/Suggestions/CollectionSuggestion';
import { RightRequestMQLStatus } from '@adalong/schemas';
import { RightRequestStatusItem } from '@src/fragments/Filters/Search/FilterItem/RightRequestStatusItem';
import styles from './styles.scss';
import { FilterSection } from './FilterSection';
import { CheckBoxLine } from './CheckBoxLine';
import { DateFilter } from './DateFilter';
import { LightSearchBar } from '@src/components/LightSearchBar';
import { Row } from '@src/components/Utils';
import { XclusionSwitch } from './Search/XclusionSwitch';
import { LanguageSuggestion } from './Search/Suggestions/LanguageSuggestion';
import { CreatorsSuggestion } from './Search/Suggestions/CreatorsSuggestion';
import { OwnContentSuggestion } from './Search/Suggestions/OwnContentSuggestion';
import { getSelectedSuggestion, selectNetwork, SourcesSuggestion } from './Search/Suggestions/SourcesSuggestion';
import { MediaTypeItem } from './Search/FilterItem/MediaTypeItem';
import { FilterNote } from './FilterNote';
import { FilterSelections } from './FilterSelections';
import { addProductNameToSuggestions, ProductsSuggestion } from './Search/Suggestions/ProductsSuggestion';
import { InfluencerSuggestion } from '@src/fragments/Filters/Search/Suggestions/InfluencerSuggestion';
import { useRecoilState, useRecoilValue } from 'recoil';
import {
  defaultFilter,
  FacetState,
  filterCollectionInputState,
  filterCollectionXclusionState,
  filterCreatorInputState,
  filterCreatorCountriesInputState,
  filterCreatorCountriesXclusionState,
  filterCreatorXclusionState,
  filterLanguageInputState,
  filterLanguageXclusionState,
  filterOwnContentXclusionState,
  filterProductExactInputState,
  filterProductExactXclusionState,
  filterProductSimilarInputState,
  filterProductSimilarXclusionState,
  filterSourceInputState,
  FilterState,
} from '@src/pages/Discover/components/Atoms/Atoms';
import {
  clearFilter,
  onFilterChange,
  onXclusionSwitch,
  validMediaType,
  validRightRequestStatus,
} from '@src/fragments/Filters/utils/FilterStateUtility';
import { DiscoverQs } from '@src/types/Discover';
import { XclusionUtility } from '@src/fragments/Filters/managers/XclusionUtility';
import { useQueryCollections } from '@src/queries/collections/useQueryCollections';
import { allProducts, getFilter } from '@src/pages/Discover/components/Selectors/Selectors';
import { ExcludeLabelsSwitch } from '@src/components/ExcludeLabelsSwitch';
import { CreatorCountriesSuggestion } from '@src/fragments/Filters/Search/Suggestions/CreatorCountriesSuggestion';

interface CheckBoxProps {
  status: RightRequestMQLStatus;
  rrFilter: string[];
}

interface Props {
  enableProductFilter: boolean;
  creatorCountriesFilterValues: string[];
}

const excludeLabels = ['advertising', 'graphic design', 'graphics', 'trademark', 'publication', 'signage', 'drawing'];

export function FiltersView(props: Props) {
  const { enableProductFilter } = props;
  const { data: userCollections = [] } = useQueryCollections();
  const [filters, setFilters] = useRecoilState(FilterState);
  const [facetState] = useRecoilState(FacetState);
  const [filterProductExactInput, setFilterProductExactInput] = useRecoilState(filterProductExactInputState);
  const [filterProductExactXclusion, setfilterProductExactXclusion] = useRecoilState(filterProductExactXclusionState);
  const [filterProductSimilarInput, setFilterProductSimilarInput] = useRecoilState(filterProductSimilarInputState);
  const [filterProductSimilarXclusion, setfilterProductSimilarXclusion] = useRecoilState(filterProductSimilarXclusionState);
  const [filterLanguageInput, setFilterLanguageInput] = useRecoilState(filterLanguageInputState);
  const [filterLanguageXclusion, setFilterLanguageXclusion] = useRecoilState(filterLanguageXclusionState);
  const [filterCreatorXclusion, setFilterCreatorXclusion] = useRecoilState(filterCreatorXclusionState);
  const [filterOwnContentXclusion, setFilterOwnContentXclusion] = useRecoilState(filterOwnContentXclusionState);
  const [filterCollectionXclusion, setFilterCollectionXclusion] = useRecoilState(filterCollectionXclusionState);
  const [filterSourceInput, setFilterSourceInput] = useRecoilState(filterSourceInputState);
  const [filterCreatorInput, setFilterCreatorInput] = useRecoilState(filterCreatorInputState);
  const [filterCollectionInput, setFilterCollectionInput] = useRecoilState(filterCollectionInputState);
  const [filterCreatorCountriesInput, setFilterCreatorCountriesInput] = useRecoilState(filterCreatorCountriesInputState);
  const [filterCreatorCountriesXclusion, setFilterCreatorCountriesXclusion] = useRecoilState(filterCreatorCountriesXclusionState);
  const filterState = useRecoilValue(getFilter);
  const products = useRecoilValue(allProducts);
  const selectedSources = getSelectedSuggestion(filterState.source);

  // Exclude labels
  const [filterLabels, setFilterLabels] = useState(false);
  const handleLabelSwitchChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setFilterLabels(event.target.checked);
    if (event.target.checked) {
      // Include all labels
      onFilterChange(setFilters, 'label')(excludeLabels, true, XCLUSION_SELECTIONS[1]);
    } else {
      // Clear all labels
      clearFilter(setFilters, 'label');
    }
  };

  const isMySelectionSectionFilled = (filterState: DiscoverQs): boolean => {
    return Object.values(filterState).some((el) => el.length > 0);
  };

  const handleClearAll = () => {
    setFilters(defaultFilter);
  };

  const rrStatusList = [RightRequestMQLStatus.Agreed, RightRequestMQLStatus.Requested, RightRequestMQLStatus.Unrequested];
  const autoExcludeCollection = facetState.mediaConstraints.collections?.find((coll) => coll.name.includes('EXCLUDE'));
  const [autoXclusion, setAutoXclusion] = useState(false);
  const changeAutoXclusion = (key: string) => {
    const changeCollectionFilter = onFilterChange(setFilters, 'collection');
    changeCollectionFilter([key], !autoXclusion, XCLUSION_SELECTIONS[1]);
    setAutoXclusion(!autoXclusion);
  };

  const RightRequestCheckBox = ({ status, rrFilter }: CheckBoxProps) => (
    <CheckBoxLine checked={rrFilter.includes(status)} onChange={(checked: boolean) => onFilterChange(setFilters, 'rrStatus')([status], checked)}>
      <RightRequestStatusItem rrStatus={status} />
    </CheckBoxLine>
  );

  return (
    <PerfectScrollbar
      className={styles.filters}
      style={{ height: '100%' }}
      options={{
        wheelSpeed: 0.3,
        minScrollbarLength: 30,
      }}
    >
      <div className={classes('filters-container', sheet.container)}>
        <FilterSection
          displayClearButton={isMySelectionSectionFilled(filterState)}
          title={lang.selection.title}
          indentation={isMySelectionSectionFilled(filterState) ? 0 : 1}
          onClear={() => handleClearAll()}
        >
          <FilterSelections
            selected={{
              date: () => filters.date[0],
              source: () => getSelectedSuggestion(filters.source),
              ownContent: () => getSelectedSuggestion(filters.ownContent),
              lang: () => filters.lang.map((val) => ({ key: val, display: XclusionUtility.getRawKey(val) })),
              creators: () =>
                filters.creator.map((value) => ({
                  key: value,
                  display: `${XclusionUtility.getRawKey(value)}`,
                })),
              productsExact: () => {
                const selectedProductsIds = filterState.productExact.map((p) => ({
                  key: p,
                  display: XclusionUtility.getRawKey(p),
                }));
                return addProductNameToSuggestions(selectedProductsIds, products);
              },
              productsSimilar: () => {
                const selectedProductsIds = filterState.productSimilar.map((p) => ({
                  key: p,
                  display: XclusionUtility.getRawKey(p),
                }));
                return addProductNameToSuggestions(selectedProductsIds, products);
              },
              mediaType: () => filters.media.map((m) => validMediaType(m)).filter((m): m is MediaType => !!m),
              rightRequestStatus: () => filters.rrStatus.map((m) => validRightRequestStatus(m)).filter((m): m is RightRequestMQLStatus => !!m),
              collection: () => {
                const rawCollectionFilter = XclusionUtility.getRawKeys(filters.collection || []);
                const allCollectionDescs = userCollections.map(({ _id, name }) => ({
                  key: _id,
                  display: name,
                }));
                return allCollectionDescs
                  .filter(({ key, display }) => isInParam(key, rawCollectionFilter))
                  .map((val) => ({
                    key: XclusionUtility.xcludeKey(val.key, filterCollectionXclusion),
                    display: val.display,
                  }));
              },
              creatorCountries: () => getSelectedSuggestion(filters.creatorCountries),
            }}
            empty={!isMySelectionSectionFilled(filterState)}
            onRemove={{
              date: () => clearFilter(setFilters, 'date'),
              mediaType: (mediaType) => onFilterChange(setFilters, 'media')([mediaType], false),
              rightRequestStatus: (rightRequestStatus) => onFilterChange(setFilters, 'rrStatus')([rightRequestStatus], false),
              languages: (key) => onFilterChange(setFilters, 'lang')([key], false),
              sources: (key) => onFilterChange(setFilters, 'source')([key], false),
              creators: (key) => onFilterChange(setFilters, 'creator')([key], false, filterCreatorXclusion),
              productsExact: (key) => onFilterChange(setFilters, 'productExact')([key], false, filterProductExactXclusion),
              productsSimilar: (key) => onFilterChange(setFilters, 'productSimilar')([key], false, filterProductSimilarXclusion),
              collection: (key) => onFilterChange(setFilters, 'collection')([key], false, filterCollectionXclusion),
              ownContent: (key) => onFilterChange(setFilters, 'ownContent')([key], false, filterOwnContentXclusion),
              creatorCountries: (key) => onFilterChange(setFilters, 'creatorCountries')([key], false, filterCreatorCountriesXclusion),
            }}
          />
        </FilterSection>
        <FilterSection title={lang.dates.title} displayClearButton={!!filters.date.length} onClear={() => clearFilter(setFilters, 'date')}>
          <DateFilter />
        </FilterSection>
        <FilterSection title={lang.network.title} displayClearButton={!!filters.source.length} onClear={() => clearFilter(setFilters, 'source')}>
          {SOURCE_NETWORKS.map((network) => (
            <CheckBoxLine
              checked={!!selectedSources.find((s) => s.network === network)}
              onChange={() => selectNetwork(network, facetState.mediaConstraints.state, filters.source, setFilters)}
              key={network}
            >
              {lang.network.values[network]}
            </CheckBoxLine>
          ))}
        </FilterSection>
        <FilterSection title={lang.sources.title} displayClearButton={!!filters.source.length} onClear={() => clearFilter(setFilters, 'source')}>
          <Row>
            {/* TODO: get and set input from parents for queries */}
            <LightSearchBar
              onText={(v) => setFilterSourceInput(v)}
              value={filterSourceInput}
              isLoading={!!filterSourceInput.length}
              title={lang.sources.inputTitle}
            />
          </Row>
          <SourcesSuggestion />
        </FilterSection>
        <FilterSection
          title={lang.rightRequest.title}
          displayClearButton={!!filters.rrStatus.length}
          onClear={() => clearFilter(setFilters, 'rrStatus')}
        >
          {rrStatusList.map((status) => (
            <RightRequestCheckBox status={status} rrFilter={filters.rrStatus} />
          ))}
        </FilterSection>
        <FilterSection title={lang.media.title} displayClearButton={!!filters.media.length} onClear={() => clearFilter(setFilters, 'media')}>
          <CheckBoxLine
            checked={filters.media.includes('video')}
            onChange={(checked: boolean) => onFilterChange(setFilters, 'media')(['video'], checked)}
          >
            <MediaTypeItem type='video' />
          </CheckBoxLine>
          <CheckBoxLine
            checked={filters.media.includes('image')}
            onChange={(checked: boolean) => onFilterChange(setFilters, 'media')(['image'], checked)}
          >
            <MediaTypeItem type='image' />
          </CheckBoxLine>
          <CheckBoxLine
            checked={filters.media.includes('text')}
            onChange={(checked: boolean) => onFilterChange(setFilters, 'media')(['text'], checked)}
          >
            <MediaTypeItem type='text' />
          </CheckBoxLine>
        </FilterSection>
        {autoExcludeCollection && (
          <FilterSection
            title={lang.autoExclude.title}
            displayClearButton={!!filters.source.length}
            onClear={() => clearFilter(setFilters, 'source')}
          >
            <CheckBoxLine checked={autoXclusion} onChange={() => changeAutoXclusion(autoExcludeCollection?._id)} key={'autoExclude'}>
              {'Exclude Collection'}
            </CheckBoxLine>
          </FilterSection>
        )}
        <FilterSection
          title={lang.collection.title}
          displayClearButton={filters.collection.length > 0}
          onClear={() => clearFilter(setFilters, 'collection')}
        >
          <XclusionSwitch
            selected={filterCollectionXclusion}
            onChange={(val) => onXclusionSwitch(val, setFilterCollectionXclusion, 'collection', setFilters)}
          />
          <Row>
            <LightSearchBar onText={(v) => setFilterCollectionInput(v)} value={filterCollectionInput} title={lang.collection.inputTitle} />
          </Row>
          <CollectionSuggestion />
        </FilterSection>
        <FilterSection title={lang.language.title} displayClearButton={!!filters.lang.length} onClear={() => clearFilter(setFilters, 'lang')}>
          <XclusionSwitch
            selected={filterLanguageXclusion}
            onChange={(val) => onXclusionSwitch(val, setFilterLanguageXclusion, 'lang', setFilters)}
          />
          <Row>
            <LightSearchBar
              onText={(v) => setFilterLanguageInput(v)}
              value={filterLanguageInput}
              isLoading={!!filterLanguageInput.length}
              title={lang.language.inputTitle}
            />
          </Row>
          <LanguageSuggestion />
        </FilterSection>
        <FeatureDisabler disabled={!enableProductFilter} tooltip={lang.premiumFeatureWarning}>
          <FilterSection
            title={lang.products.title}
            displayClearButton={!!filters.productExact.length}
            onClear={() => clearFilter(setFilters, 'productExact')}
            disabled={!enableProductFilter}
          >
            <XclusionSwitch
              selected={filterProductExactXclusion}
              onChange={(val) => onXclusionSwitch(val, setfilterProductExactXclusion, 'productExact', setFilters)}
            />
            <Row>
              <LightSearchBar
                onText={(v) => setFilterProductExactInput(v)}
                value={filterProductExactInput}
                isLoading={!!filterProductExactInput.length}
                title={lang.products.inputTitle}
              />
            </Row>
            <ProductsSuggestion productType='productExact' />
          </FilterSection>
          <FilterSection
            title={lang.similarProducts.title}
            displayClearButton={!!filters.productSimilar.length}
            onClear={() => clearFilter(setFilters, 'productSimilar')}
            disabled={!enableProductFilter}
          >
            <XclusionSwitch
              selected={filterProductSimilarXclusion}
              onChange={(val) => onXclusionSwitch(val, setfilterProductSimilarXclusion, 'productSimilar', setFilters)}
            />
            <Row>
              <LightSearchBar
                onText={(v) => setFilterProductSimilarInput(v)}
                value={filterProductSimilarInput}
                isLoading={!!filterProductSimilarInput.length}
                title={lang.products.inputTitle}
              />
            </Row>
            <ProductsSuggestion productType='productSimilar' />
          </FilterSection>
        </FeatureDisabler>
        <FilterSection
          title={lang.influencers.title}
          displayClearButton={!!filters.creator.length}
          onClear={() => clearFilter(setFilters, 'creator')}
        >
          <XclusionSwitch
            selected={filterCreatorXclusion}
            onChange={(val) => onXclusionSwitch(val, setFilterCreatorXclusion, 'creator', setFilters)}
          />
          <InfluencerSuggestion />
          <FilterNote marginTop={15}>{lang.creators.info}</FilterNote>
        </FilterSection>
        <FilterSection title={lang.creators.title} displayClearButton={!!filters.creator.length} onClear={() => clearFilter(setFilters, 'creator')}>
          <XclusionSwitch
            selected={filterCreatorXclusion}
            onChange={(val) => onXclusionSwitch(val, setFilterCreatorXclusion, 'creator', setFilters)}
          />
          <Row>
            <LightSearchBar
              onText={(v) => setFilterCreatorInput(v)}
              value={filterCreatorInput}
              isLoading={!!filterCreatorInput.length}
              title={lang.creators.inputTitle}
            />
          </Row>
          <CreatorsSuggestion />
          <FilterNote marginTop={15}>{lang.creators.info}</FilterNote>
        </FilterSection>
        <FilterSection
          title={lang.selfaccounts.title}
          displayClearButton={!!filters.ownContent.length}
          onClear={() => clearFilter(setFilters, 'ownContent')}
        >
          <XclusionSwitch
            selected={filterOwnContentXclusion}
            onChange={(val) => onXclusionSwitch(val, setFilterOwnContentXclusion, 'ownContent', setFilters)}
          />
          <OwnContentSuggestion />
          <FilterNote marginTop={15}>{lang.selfaccounts.info}</FilterNote>
        </FilterSection>
        { props.creatorCountriesFilterValues.length > 0 ?
        <FilterSection
          title={lang.creatorCountries.title}
          displayClearButton={!!filters.creatorCountries.length}
          onClear={() => clearFilter(setFilters, 'creatorCountries')}
        >
          <XclusionSwitch
            selected={filterCreatorCountriesXclusion}
            onChange={(val) => onXclusionSwitch(val, setFilterCreatorCountriesXclusion, 'collection', setFilters)}
          />
          <Row>
            <LightSearchBar
              onText={(v) => setFilterCreatorCountriesInput(v)}
              value={filterCreatorCountriesInput}
              isLoading={!!filterCreatorCountriesInput.length}
              title={lang.creatorCountries.inputTitle}
            />
          </Row>
          <CreatorCountriesSuggestion availableCountries={props.creatorCountriesFilterValues} />
        </FilterSection> : null }
        <ExcludeLabelsSwitch checked={filterLabels} onLabelSwitchChange={handleLabelSwitchChange} />
      </div>
    </PerfectScrollbar>
  );
}

const lang = new LocalizedStrings({
  en: {
    selection: {
      title: 'Filters applied',
    },
    media: {
      title: 'Media',
    },
    exclude: {
      title: 'Exclude labels',
    },
    rightRequest: {
      title: 'Right Requests',
    },
    dates: {
      title: 'Dates',
    },
    collection: {
      title: 'Collections',
      inputTitle: 'filter by collection',
    },
    autoExclude: {
      title: 'Auto Exclude',
      inputTitle: 'exclude default collection',
    },
    language: {
      title: 'Language',
      inputTitle: 'filter by language',
    },
    network: {
      title: 'Network',
      values: {
        instagram: 'Instagram',
        twitter: 'Twitter',
        tiktok: 'TikTok',
      },
    },
    selfaccounts: {
      title: 'Your Content',
      info: 'filter your content',
    },
    sources: {
      title: 'Sources',
      inputTitle: 'filter by source',
    },
    products: {
      title: 'Exact Products Linked',
      inputTitle: 'filter by SKU or Reference',
    },
    similarProducts: {
      title: 'Similar Products Linked',
      inputTitle: 'filter by SKU or Reference',
    },
    creators: {
      title: 'Creators*',
      inputTitle: 'filter by username',
      info: '* N/A for Instagram hashtagged media',
    },
    influencers: {
      title: 'Influencers*',
      inputTitle: 'filter by username',
      info: '* N/A for Instagram hashtagged media',
    },
    creatorCountries: {
      title: 'Creator Countries',
      inputTitle: 'filter by creation countries',
    },
    premiumFeatureWarning: 'This is a premium feature. Please contact us for more information.',
  },
});

const sheet = stylesheet({
  container: {
    color: ADALONG_COLORS.LIGHT_GRAY,
    fontWeight: 400,
  },
});
