import React, { Component, RefObject } from 'react';

import { VideoMakerView, VideoElementView } from 'common';
import { ImageResolverResponse } from '@src/types/Image';
import { SizeImage, IVideoMakerService } from '@src/types/VideoMaker';
import { VideoState } from '@src/types/redux/video';
import { Point } from '../../../../../../utils/math/Point';
import { Rectangle } from '../../../../../../utils/math/Rectangle';

interface IState {
  currentImage?: VideoElementView
  play: boolean
}

interface Props {
  videoMakerService: IVideoMakerService
  selectImage: (idx: number) => void
  widthUpdate: (width: number) => void
  gif: VideoMakerView
  current_index: number
  width: number
  height: number
  isResize: boolean
  resolvedImages: VideoState['resolvedImages']
}

export class CanvasResizer extends Component<Props, IState> {
  public canvas: RefObject<HTMLCanvasElement>;

  public listener: any;

  public mouse: Point = new Point(0, 0);

  public startPoint: Point | undefined;

  public rules: Point[] = [];

  public currentAction: 'move' | 'crosshair' | 'default' | 'cell' | 'grab' | 'copy' | 'col-resize' | 'row-resize' = 'default';

  public timer = 0;

  public tempImage?: VideoElementView;

  private FRAMERATE = 25;

  private interval = setInterval(() => {
    const ctx = this.getContext();
    if (ctx) { this.refreshCanvas(ctx); }
  }, this.FRAMERATE);

  public constructor(props: Props) {
    super(props);
    this.canvas = React.createRef<HTMLCanvasElement>();
    this.state = {
      play: false,
    };
  }

  public componentWillUnmount() {
    clearInterval(this.interval);
  }

  public getContext() {
    const canvas = this.canvas.current;
    if (!canvas) { return null; }

    const ctx = canvas.getContext('2d');
    return ctx;
  }

  public clearCanvas(ctx: CanvasRenderingContext2D) {
    ctx.clearRect(0, 0, this.props.width, this.props.height);
    ctx.fillStyle = '#202020FF';
    ctx.fillRect(0, 0, this.props.width, this.props.height);
  }

  public getWindowRect(): Rectangle {
    const dim = this.props.gif.ratio >= 1 ? 3 / 5 : 7 / 9;
    return new Rectangle((this.props.width - dim * this.props.gif.ratio * this.props.height) / 2,
      (1 - dim) / 2 * this.props.height,
      dim * this.props.gif.ratio * this.props.height,
      dim * this.props.height);
  }

  public displayGrid(ctx: CanvasRenderingContext2D) {
    const rect = this.getWindowRect();

    // Background
    ctx.beginPath();
    ctx.fillStyle = this.props.isResize ? '#00000055' : '#151515FF';
    const adj = this.props.isResize ? 0 : 1;
    ctx.fillRect(0, 0, this.props.width, rect.y); // Top
    ctx.fillRect(0, rect.y - adj, rect.x, rect.height + adj * 2); // Left
    ctx.fillRect(rect.x + rect.width, rect.y - adj, rect.x, rect.height + adj * 2); // Right
    ctx.fillRect(0, rect.y + rect.height, this.props.width, this.props.height - (rect.y + rect.height)); // Bottom
    ctx.closePath();
    if (this.props.isResize) {
      // Box
      ctx.beginPath();
      this.setLineStyle(ctx, '#FFFFFF', 1);
      ctx.strokeRect(rect.x, rect.y, rect.width, rect.height);

      // Grid
      this.setLineStyle(ctx, '#FFFFFF77', 1);
      const size = 75;
      const orig = new Point(rect.x + rect.width * 0.5, rect.y + rect.height * 0.5);
      ctx.beginPath();
      this.setLineStyle(ctx, '#FFFFFF', 0.5);
      for (let x = orig.x % size; x < this.props.width; x += size) {
        if (x > rect.x && x < rect.x + rect.width) {
          ctx.moveTo(x, rect.y);
          ctx.lineTo(x, rect.y + rect.height);
        }
      }
      for (let y = orig.y % size; y < this.props.height; y += size) {
        if (y > rect.y && y < rect.y + rect.height) {
          ctx.moveTo(rect.x, y);
          ctx.lineTo(rect.x + rect.width, y);
        }
      }
      ctx.stroke();
      ctx.closePath();
      // Rules
      this.displayRules(ctx);
    }
  }

  public displayImage(ctx: CanvasRenderingContext2D) {
    if (!this.state.currentImage) { return; }
    if (this.getWindowRect().width === 0) { return; }
    const resolvedImage = this.getResolvedImage(this.state.currentImage);
    if (!resolvedImage) { return; }

    if ((!this.state.currentImage.height || !this.state.currentImage.width)) {
      this.initDefaultSize(this.state.currentImage);
    }

    if (this.state.currentImage.width && this.state.currentImage.height) {
      ctx.save();
      const rect: Rectangle = this.tempImage && this.tempImage.width && this.tempImage.height
        ? new Rectangle(this.tempImage.x, this.tempImage.y, this.tempImage.width, this.tempImage.height, this.tempImage.rot)
        : new Rectangle(this.state.currentImage.x, this.state.currentImage.y, this.state.currentImage.width, this.state.currentImage.height, this.state.currentImage.rot);

      ctx.translate(rect.x + rect.width * 0.5, rect.y + rect.height * 0.5);
      ctx.rotate(rect.rot * Math.PI / 180);
      ctx.drawImage(resolvedImage.imgElement, -rect.width * 0.5, -rect.height * 0.5, rect.width, rect.height);

      this.props.isResize && this.displayMarkers(ctx, rect);

      ctx.restore();
    }
  }

  public setLineStyle(ctx: CanvasRenderingContext2D, color: string, width: number) {
    ctx.strokeStyle = color;
    ctx.lineWidth = width;
  }

  public displayRules(ctx: CanvasRenderingContext2D) {
    const w = this.props.width;
    const h = this.props.height;

    this.setLineStyle(ctx, '#fff', 1);
    ctx.beginPath();

    this.rules.map((pt) => {
      ctx.moveTo(0, pt.y);
      ctx.lineTo(w, pt.y);

      ctx.moveTo(pt.x, 0);
      ctx.lineTo(pt.x, h);
    });
    ctx.stroke();
    ctx.closePath();
    ctx.beginPath();
    this.setLineStyle(ctx, '#ffffff', 1);
    ctx.lineWidth = 1;
    const step = 20;
    const stepHeight = 5;

    let oneTwo = 0;
    for (let i = 0; i < w; i += step) {
      oneTwo++;
      ctx.moveTo(i, 0);
      ctx.lineTo(i, stepHeight * (1 + (oneTwo % 2)));

      ctx.moveTo(i, h);
      ctx.lineTo(i, h - stepHeight * (1 + (oneTwo % 2)));
    }
    this.setLineStyle(ctx, '#ffffff', 1);
    for (let j = 0; j < h; j += step) {
      oneTwo++;
      ctx.moveTo(0, j);
      ctx.lineTo(stepHeight * (1 + (oneTwo % 2)), j);

      ctx.moveTo(w, j);
      ctx.lineTo(w - stepHeight * (1 + (oneTwo % 2)), j);
    }
    ctx.stroke();
    ctx.closePath();
  }

  public displayMarkers(ctx: CanvasRenderingContext2D, rect: Rectangle) {
    const size = 8;

    const points = [
      new Point(0, 0),
      new Point(rect.width, 0),
      new Point(0, rect.height),
      new Point(rect.width, rect.height),
    ];

    points.map((pt) => {
      ctx.beginPath();
      ctx.fillStyle = '#3b6ece';
      this.setLineStyle(ctx, '#888888', 1);
      ctx.ellipse(pt.x - rect.width * 0.5, pt.y - rect.height * 0.5, size, size, 0, 0, 2 * Math.PI);
      ctx.fill();
      ctx.stroke();
    });
  }

  public initDefaultSize(image: VideoElementView) {
    const resolvedImage = this.getResolvedImage(image);
    if (resolvedImage) {
      const img_obj = resolvedImage.imgElement;
      const ratio = img_obj.width / img_obj.height;
      const windowRect = this.getWindowRect();
      this.resizeImage({
        width: windowRect.width,
        height: windowRect.width / ratio,
        x: 0,
        y: 0,
      });
    }
  }

  public componentDidMount() {
    this.setCurrentImage(this.props.gif, this.props.current_index);
  }

  public videoPlayer(ctx: CanvasRenderingContext2D) {
    const dim = 50;
    this.setLineStyle(ctx, '#fff', 1);
    ctx.beginPath();
    if (this.state.play) {
      ctx.strokeRect(dim * 1 / 4, this.props.height - dim * 3 / 4, dim / 8, dim / 2);
      ctx.strokeRect(dim * 5 / 8, this.props.height - dim * 3 / 4, dim / 8, dim / 2);
    } else {
      ctx.moveTo(dim * 1 / 4, this.props.height - dim * 1 / 4);
      ctx.lineTo(dim * 3 / 4, this.props.height - dim * 2 / 4);
      ctx.lineTo(dim * 1 / 4, this.props.height - dim * 3 / 4);
      ctx.lineTo(dim * 1 / 4, this.props.height - dim * 1 / 4);
    }
    ctx.stroke();
  }

  public refreshCanvas(ctx: CanvasRenderingContext2D) {
    this.clearCanvas(ctx);
    this.displayImage(ctx);
    this.displayGrid(ctx);
    if (!this.props.isResize) {
      this.videoPlayer(ctx);
      this.state.play && this.playAnimation();
    }
  }

  public playAnimation() {
    if (this.timer === 0) { return; }
    this.timer += this.FRAMERATE / 1000;
    let current_index = Math.floor(this.timer / this.props.gif.duration * this.props.gif.elements.length);
    if (current_index >= this.props.gif.elements.length) {
      this.timer = 0;
      this.setState({ play: false });
      current_index = 0;
    }
    if (current_index !== this.props.current_index) { this.props.selectImage(current_index); }
  }

  // The component must completly refactored to remove
  // this function properly
  public UNSAFE_componentWillReceiveProps(next: Props) {
    if (next && next.current_index < next.gif.elements.length) {
      this.setCurrentImage(next.gif, next.current_index);
    }
  }

  public componentDidUpdate() {
    if (!this.listener) {
      const canvas = this.canvas.current;
      if (canvas) {
        this.listener = canvas.addEventListener('mousemove', this.handleMouseMove.bind(this));
        canvas.addEventListener('mousedown', (e: MouseEvent) => {
          if (this.state.currentImage) {
            this.tempImage = { ...this.state.currentImage };
            this.startPoint = new Point(e.offsetX, e.offsetY);
          }
          if (['row-resize', 'col-resize'].indexOf(this.currentAction) >= 0) {
            let idx = -1;

            this.rules.map((pt, i) => {
              if (Math.abs(pt.x - this.mouse.x) <= 15 || Math.abs(pt.y - this.mouse.y) <= 15) {
                idx = i;
              }
            });

            this.startPoint = idx >= 0 ? new Point(idx, 0) : undefined;
          }
        });
        canvas.addEventListener('mouseup', () => {
          this.startPoint = undefined;
          if (this.state.currentImage && this.tempImage) {
            const windowRect = this.getWindowRect();

            if (this.currentAction === 'crosshair') {
              const willPlay = !this.state.play;
              const idx = this.props.current_index + 1 === this.props.gif.elements.length ? 0 : this.props.current_index;
              this.timer = willPlay ? 0.001 + idx / this.props.gif.elements.length * this.props.gif.duration : 0;
              this.setState({
                play: willPlay,
              });
              this.currentAction = 'default';
            }

            if (this.currentAction === 'copy') {
              const rule = this.mouse.scale(1, 1);
              if (rule.x <= 20 || rule.x >= this.props.width - 20) {
                rule.x = -100;
              }
              if (rule.y <= 20 || rule.y >= this.props.height - 20) {
                rule.y = -100;
              }
              this.rules.push(rule);
            }

            this.setState({
              currentImage: { ...this.tempImage },
            }, () => {
              if (this.tempImage) {
                const copy = { ...this.tempImage };
                this.resizeImage({
                  ...copy,
                  x: copy.x - windowRect.x,
                  y: copy.y - windowRect.y,
                });
                this.tempImage = undefined;
              }
            });
          }
        });
      }
    }
  }

  public handleMouseMove(e: MouseEvent) {
    const canvas = this.canvas.current;
    if (!canvas) { return; }
    this.mouse.set(e.offsetX, e.offsetY);

    if (!this.props.isResize) {
      this.currentAction = 'default';
      const playButton = new Rectangle(0, this.props.height - 50, 50, 50);
      if (playButton.containsPoint(this.mouse)) {
        this.currentAction = 'crosshair';
      }
    } else if (this.state.currentImage) {
      if (this.startPoint && this.tempImage) {
        // Action ongoing
        if (this.currentAction === 'grab') {
          // Move image
          this.tempImage.x = this.state.currentImage.x + (this.mouse.x - this.startPoint.x);
          this.tempImage.y = this.state.currentImage.y + (this.mouse.y - this.startPoint.y);
        } else if (this.currentAction === 'cell' && this.state.currentImage.width && this.state.currentImage.height) {
          // Rotate / scale image
          const imgCenter = new Point(this.state.currentImage.x + this.state.currentImage.width * 0.5, this.state.currentImage.y + this.state.currentImage.height * 0.5);
          const startVec = this.startPoint.substract(imgCenter);
          const mouseVec = this.mouse.substract(imgCenter);
          const rotDiff = mouseVec.angleDeg() - startVec.angleDeg();
          const factor = (mouseVec.length * startVec.length) !== 0 ? mouseVec.length / startVec.length : 1;
          this.tempImage.x = this.state.currentImage.x + (1 - factor) * this.state.currentImage.width * 0.5;
          this.tempImage.y = this.state.currentImage.y + (1 - factor) * this.state.currentImage.height * 0.5;
          this.tempImage.width = this.state.currentImage.width * factor;
          this.tempImage.height = this.state.currentImage.height * factor;
          this.tempImage.rot = this.state.currentImage.rot + rotDiff;
        } else if (this.currentAction === 'col-resize') {
          const index = this.startPoint.x;
          this.rules[index].x = this.mouse.x;
        } else if (this.currentAction === 'row-resize') {
          const index = this.startPoint.x;
          this.rules[index].y = this.mouse.y;
        }
      } else {
        // Image actions starters
        this.currentAction = 'default';
        const imageRect = Rectangle.fromObject(this.state.currentImage);
        const pointSize = 25;
        if (imageRect) {
          if (imageRect.containsPoint(this.mouse)) {
            this.currentAction = 'grab';
          }
          const points = imageRect.getPoints();
          if (points.findIndex((pt) => pt.distance(this.mouse) < pointSize) >= 0) {
            this.currentAction = 'cell';
          }
        }

        // Rule actions starters
        if (this.mouse.x <= 20 || this.mouse.y <= 20 || this.mouse.x >= this.props.width - 20 || this.mouse.y >= this.props.height - 20) {
          this.currentAction = 'copy';
        }

        // Rule actions moving
        this.rules.map((pt) => {
          const diff = pt.substract(this.mouse);
          if (Math.abs(diff.x) < 15) {
            this.currentAction = 'col-resize';
          }
          if (Math.abs(diff.y) < 15) {
            this.currentAction = 'row-resize';
          }
        });
      }
    }
    canvas.style.cursor = this.currentAction;
  }

  public render() {
    return <canvas height={this.props.height} width={this.props.width} ref={this.canvas} />;
  }

  private resizeImage(size: SizeImage) {
    this.props.videoMakerService.onResizeImage(size);
    if (!this.state.play) {
      this.props.videoMakerService.update(this.props.gif);
    }
  }

  private getResolvedImage(image: VideoElementView): ImageResolverResponse | null {
    if (!image.image_url) {
      return null;
    }
    if (image.brandcontent) {
      const img = new Image();
      img.src = image.brandcontent.urls.file;
      return {
        finalUrl: img.src = image.brandcontent.urls.file,
        imgElement: img,
      };
    }
    return this.props.resolvedImages[image.image_url];
  }

  private async setCurrentImage(gif: VideoMakerView, currentIndex: number) {
    const image = { ...gif.elements[currentIndex] };
    setTimeout(() => {
      const windowRect = this.getWindowRect();
      const scale = windowRect.width / this.props.gif.width;
      if (scale !== 1 && windowRect.width !== 0) {
        this.props.widthUpdate(windowRect.width);
        if (image.width && image.height) {
          image.width *= scale;
          image.height *= scale;
        }
      }
      image.x += windowRect.x;
      image.y += windowRect.y;
      this.setState({
        currentImage: { ...image },
      });
    }, 15);
  }
}
