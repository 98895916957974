import React from 'react';
import { connect } from 'react-redux';
import { ICombinedReducers } from '@src/types/redux';
import { useQueryBrandContents } from '@src/queries/brandcontent/useQueryBrandContents';
import { IUploadingFile } from '../Upload/components/UploadingFile';
import { Upload } from '../Upload';

interface State {
  loading: boolean
}

interface OwnProps {
  onUpload: (files: File[]) => void
  uploadingFiles: IUploadingFile[]
}

const mapStateToProps = (state: ICombinedReducers) => ({
  brandassets: state.video.brandCollection,
});

type Props = ReturnType<typeof mapStateToProps> & OwnProps;

export function BrandAssets(props: Props) {
  const { data: brandAssets = [] } = useQueryBrandContents(undefined);

  return (
    <Upload
      canEdit
      content={{
        // The video maker only supports images
        brandcontents: brandAssets.filter((brandAsset) => brandAsset.type === 'image'),
      }}
      onUpload={props.onUpload}
      uploadingFiles={props.uploadingFiles}
    />
  );
}

export default connect(mapStateToProps)(BrandAssets);
