import React from 'react';
import { DropDownIcon } from './DropDownIcon';

export function InformationIcon() {
  return (
    <DropDownIcon
      childrenRender={() => (
        <>
          <rect width='50' height='50' rx='10' fill='#D9D9D9' />
          <path
            d='M23.2734 19.4883H27.0253C27.9046 19.4883 28.7066 19.8403 29.2912 20.4076L29.2975 20.4138C29.879 20.9811 30.2383 21.7652 30.2383 22.6216V41.1524C30.2383 42.0119 29.8774 42.7944 29.296 43.3633L29.2928 43.3663C28.7114 43.9337 27.9093 44.2857 27.0269 44.2857H23.2749C22.3957 44.2857 21.5936 43.9337 21.009 43.3663L21.0027 43.3602C20.4212 42.7913 20.062 42.0088 20.062 41.1524V26.4945C20.062 26.3545 20.0478 26.2331 20.0178 26.1209C19.9832 25.9994 19.9343 25.8841 19.8681 25.7719L19.0314 24.3574L19.0283 24.3589C18.7226 23.8439 18.5713 23.2888 18.5713 22.7354C18.5713 22.1803 18.7241 21.6269 19.0283 21.1118C19.3292 20.6045 19.7452 20.2001 20.2416 19.9203L20.24 19.9188C20.738 19.639 21.3052 19.4883 21.9088 19.4883L23.2734 19.4883ZM25.1485 5.71429C26.6849 5.71429 28.0763 6.32313 29.0832 7.30557C30.0901 8.288 30.7141 9.64402 30.7141 11.1446C30.7141 12.6436 30.0901 14.0011 29.0832 14.9836C28.0763 15.966 26.6865 16.5733 25.1485 16.5733C23.6121 16.5733 22.2207 15.9645 21.2138 14.9836C20.2069 14.0011 19.5829 12.6451 19.5829 11.1446C19.5829 9.64556 20.2069 8.288 21.2138 7.30557C22.2207 6.32313 23.6106 5.71429 25.1485 5.71429Z'
            fill='#343434'
          />
        </>
      )}
    />
  );
}
