import { createTheme } from '@mui/material';
import { ADALONG_COLORS } from '@src/styles';

const COLOR = ADALONG_COLORS.SMOOTH_WHITE;
const FOCUS_COLOR = ADALONG_COLORS.LIGHT_WHITE;

export const materialTheme = createTheme({
  components: {
    MuiInput: {
      styleOverrides: {
        underline: {
          '&:before': {
            borderBottom: '1px solid rgba(255, 255, 255, 0.9)',
          },
        },
        input: {
          '&::placeholder': {
            color: COLOR,
          },
          color: 'white', // if you also want to change the color of the input, this is the prop you'd use
        },
      },
    },
    MuiFormControl: {
      styleOverrides: {
        root: {
          color: COLOR,
        },
        marginNormal: {
          marginTop: 0,
          marginBottom: 0,
        },
      },
    },
    MuiFormLabel: {
      styleOverrides: {
        root: {
          color: COLOR,
          'Mui-focused': {
            color: FOCUS_COLOR,
          },
        },
      },
    },
    MuiIconButton: {
      styleOverrides: {
        root: {
          color: COLOR,
        },
      },
    },
    MuiInputBase: {
      styleOverrides: {
        root: {
          color: FOCUS_COLOR,
        },
      },
    },
  },
});
