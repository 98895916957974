import React, { useState } from 'react';
import { PerfectScrollbar } from '@src/components/PerfectScrollbar';
import { LightSearchBar } from '@src/components/LightSearchBar';
import { Column } from '@src/components/Utils';
import { stylesheet } from 'typestyle';
import LocalizedStrings from 'react-localization';
import { Category } from '@adalong/schemas';
import { TaxonomyTree } from './TaxonomyTree';
import { ProductGroup } from "common/dist/types/apiTypes";

interface Props {
  taxonomy: Category | undefined
  selectedCategoryPath: string[]
  setSelectedCategoryPath: (selected: string[]) => void
  productGroups?: ProductGroup[]
}

export function TaxonomySection({
  taxonomy,
  selectedCategoryPath,
  setSelectedCategoryPath,
  productGroups,
}: Props) {
  const [categoryFilter, setCategoryFilter] = useState('');

  return (
    <Column className={sheet.taxonomyContainer}>
      <LightSearchBar
        className={sheet.searchBarTaxonomyClass}
        onText={setCategoryFilter}
        value={categoryFilter}
        title={lang.categoryFilter}
      />
      <PerfectScrollbar
        options={{
          maxScrollbarLength: 300,
        }}
      >
        <TaxonomyTree
          textFilter={categoryFilter}
          taxonomy={taxonomy}
          selectedCategoryPath={selectedCategoryPath}
          onChangeCategoryPath={setSelectedCategoryPath}
          groups={productGroups}
        />
      </PerfectScrollbar>
    </Column>
  );
}

const lang = new LocalizedStrings({
  en: {
    categoryFilter: 'filter by category',
  },
});

const sheet = stylesheet({
  taxonomyContainer: {
    padding: '5px 0px 5px 10px',
    flex: 'auto',
    overflow: 'hidden',
  },
  searchBarTaxonomyClass: {
    flex: 'initial',
  },
});
