import React from 'react';
import { classes, stylesheet } from 'typestyle';

/**
 * Simple link with style
 */
export function Link({
  className,
  children,
  ...props
}: React.AnchorHTMLAttributes<HTMLAnchorElement>) {
  return (
    <a
      className={classes(className, sheet.link)}
      {...props}
    >
      {children}
    </a>
  );
}

const sheet = stylesheet({
  link: {
    color: '#007bff',
    cursor: 'pointer',
  },
});
