import React from 'react';
import MUISelect from '@mui/material/Select';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import MenuItem from '@mui/material/MenuItem';
import { grey } from '@mui/material/colors';
import { ADALONG_COLORS, ThemeStyle } from '@src/styles';
import { createTheme, ThemeOptions } from '@mui/material/styles';
import { ThemeProvider } from '@mui/material';

export interface SelectOption<Value> {
  value: Value
  text: string
}

interface Props<Value extends string> {
  options: SelectOption<Value>[]
  value: Value
  variant?: 'formLike' | 'adalong'
  onChange: (value: Value) => any
}

export function Select<T extends string>({
  options,
  value,
  variant = 'formLike',
  onChange,
}: Props<T>) {
  return (
    <ThemeProvider theme={materialTheme[variant]}>
      <MUISelect
        value={value}
        onChange={({ target }) => onChange(target.value as T)}
        IconComponent={variant === 'formLike' ? ArrowDropDownIcon : ExpandMoreIcon}
      >
        {options.map(({ value, text }) => <MenuItem key={value} value={value}>{text}</MenuItem>)}
      </MUISelect>
    </ThemeProvider>
  );
}

const ADALONG_BACKGROUND_COLOR = ADALONG_COLORS.LIGHT_BLACK;
const ADALONG_TEXT_COLOR = ADALONG_COLORS.LIGHT_WHITE;

const borderBottomForm = `2px solid ${ADALONG_COLORS.SMOOTH_WHITE}`;
const borderBottomAdalong = 'transparent';

const menuStyle: ThemeOptions['components'] = {
  MuiPaper: {
    styleOverrides: {
      root: {
        backgroundColor: ADALONG_BACKGROUND_COLOR,
        color: ADALONG_COLORS.LIGHT_GRAY,
      },
    },
  },
  MuiListItem: {
    styleOverrides: {
      button: {
        '&$selected, &$selected:hover': {
          backgroundColor: '#ffffff1c',
          color: ADALONG_COLORS.LIGHT_WHITE,
        },
        '&:hover': {
          backgroundColor: '#ffffff0a',
        },
      },
    },
  },
};

const materialTheme = {
  formLike: createTheme({
    palette: {
      primary: {
        main: grey[900],
      },
      background: {
        default: grey[900],
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            color: 'white',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: '#b1b1b1',
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            '&:before': {
              borderBottom: borderBottomForm,
            },
            '&:after': {
              borderBottom: borderBottomForm,
            },
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: `2px solid ${ADALONG_COLORS.LIGHT_WHITE}`,
            },
          },
        },
      },
      ...menuStyle,
    },
  }),
  adalong: createTheme({
    palette: {
      primary: {
        main: ADALONG_BACKGROUND_COLOR,
      },
      background: {
        default: ADALONG_BACKGROUND_COLOR,
      },
    },
    components: {
      MuiInputBase: {
        styleOverrides: {
          root: {
            height: 30,
            fontSize: ThemeStyle.textSizes.medium,
            fontWeight: 700,
            color: ADALONG_TEXT_COLOR,
            backgroundColor: ADALONG_BACKGROUND_COLOR,
            padding: '0px 10px 0px 18px',
            borderRadius: '30px !important',
          },
        },
      },
      MuiSelect: {
        styleOverrides: {
          icon: {
            color: ADALONG_TEXT_COLOR,
            right: 6,
            top: 'calc(50% - 13px)',
            width: 20,
          },
        },
      },
      MuiInput: {
        styleOverrides: {
          underline: {
            '&:before': {
              borderBottom: borderBottomAdalong,
            },
            '&:after': {
              borderBottom: borderBottomAdalong,
            },
            '&:hover:not(.Mui-disabled):before': {
              borderBottom: borderBottomAdalong,
            },
          },
        },
      },
      ...menuStyle,
    },
  }),
};
