import { MediaView } from 'common';
import { ServiceReference, ServiceName } from '@src/types/Services';
import { GlobalError } from '@src/types/GlobalError';
import LocalizedStrings from 'react-localization';
import {
  IMediaService, MediaInfoMark, MarkType, MarkRequiredInfo,
} from '@src/types/Media';
import { Dimensions } from '@src/types/Dimensions';
import { ServiceStore } from '@src/types/redux';
import { MEDIA_ACTION_CREATORS } from '@src/actions/media';
import { MARK_THEME } from '@src/config/mark';
import { Api } from '@src/types/api';
import { ArrayHelper } from './helpers/array';
import { ImageResolver } from './helpers/imageResolver';
import { DownloadHelper } from './helpers/download';

type MarkWithoutSymbol = Pick<MediaInfoMark, 'text' | 'type'>;

export class MediaService implements IMediaService {
  private globalErrorService?: GlobalError;

  private apiService?: Api;

  public constructor(
    servicesPromise: ServiceReference,
    private store: ServiceStore,
  ) {
    servicesPromise.then((services) => {
      this.globalErrorService = services.get<GlobalError>(ServiceName.GlobalError);
      this.apiService = services.get<Api>(ServiceName.Api);
    });
  }

  public async download(media: MediaView): Promise<void> {
    const url = media.cdn_video || media.cdn_image;

    if (url) {
      const file = await DownloadHelper.downloadBrandContentMedia(url, media._id);
      if (file) {
        DownloadHelper.triggerDownload(file.fileUrl);
      }
    } else {
      console.debug(`Can't download media ${JSON.stringify(media)}, no available url`);
      this.globalErrorService?.displayCustomError(
        lang.formatString(lang.downloadFail, { media: media.code }).toString(),
      );
    }
  }

  /**
     * Resolve media dimension and save it in the store
     * IMAGE ONLY
     * @param media
     */
  public async resolveMediaDimensions(media: MediaView): Promise<Dimensions | null> {
    if (!media.cdn_image) {
      return null;
    }
    const { media: mediaState } = this.store.getState();
    const existingDimensions = mediaState.dimensions[media._id];
    if (existingDimensions) {
      return existingDimensions;
    }
    const res = await ImageResolver.load(media.cdn_image).catch((e) => {
      throw Error(`Can't load image ${media.cdn_image} from media ${media}: ${e.stack}`);
    });
    if (!res) {
      return null;
    }
    const dimensions: Dimensions = {
      width: res.imgElement.naturalWidth,
      height: res.imgElement.naturalHeight,
    };
    this.store.dispatch(MEDIA_ACTION_CREATORS.setDimensions(media._id, dimensions));
    return dimensions;
  }

  public getMarks(
    media: MarkRequiredInfo,
    include: MarkType[],
  ): MediaInfoMark[] {
    const { common: { groupMentionPages } } = this.store.getState();
    const getGroupMentionPage = (t: string): string => groupMentionPages[t.split('_')[1]]?.name ?? '';
    const igMentions = media.mentions.map((t) => getGroupMentionPage(t)).filter((s): s is string => !!s);
    const tagged = media.tagged.map((t) => getGroupMentionPage(t)).filter((s): s is string => !!s);
    const ownContent = media.ownContent.map((t) => getGroupMentionPage(t)).filter((s): s is string => !!s);
    const deduplicatedIgMentions = ArrayHelper.uniqBy(igMentions);

    // Twitter hack to retrieve mentioned media through hashtags: twitter media having tags starting with @ are in reality mentioned media
    const twitterMentions = media.tags.flatMap((tag) => (tag.startsWith('@') ? [tag.slice(1)] : []));
    const mentions = [...twitterMentions, ...deduplicatedIgMentions];

    const marks: { [key: string]: MarkWithoutSymbol[] } = {
      mentions: mentions.map((t): MarkWithoutSymbol => ({ type: 'mentions', text: t })),
      tagged: tagged.map((t): MarkWithoutSymbol => ({ type: 'tagged', text: t })),
      ownContent: ownContent.map((t): MarkWithoutSymbol => ({ type: 'ownContent', text: t })),
      // Twitter hack to retrieve mentioned media through hashtags: twitter media having tags starting with @ are in reality mentioned media
      hashtags: media.tags.flatMap((t): MarkWithoutSymbol[] => (!t.startsWith('@') ? [{ type: 'hashtags', text: t }] : [])),
      labels: media.label_ids.map((t): MarkWithoutSymbol => ({ type: 'labels', text: t })),
    };
    const marksWithoutSymbol: MarkWithoutSymbol[] = [];

    Object.entries(marks).forEach(([key, values]) => {
      if ((include as string[]).includes(key)) {
        marksWithoutSymbol.push(...values);
      }
    });

    return marksWithoutSymbol.map(({ type, text }) => ({
      text,
      type,
      symbol: MARK_THEME[type].symbol,
    }));
  }

  /*
  Function to take media tags and filter them with the tags associated to the user Group,
  in order to show only relevant tags under the media preview. If no relevant tags are found,
  all tags are shown.
  */
  public filterTagsByGroup(tags: string[]): string[] {
    const { common: { user } } = this.store.getState();
    const groupTags = user?.group.tags;
    const relevantTags = tags.filter((t) => groupTags?.indexOf(t) !== -1);
    return relevantTags ?? [];
  }

  public async getCarouselMedia(
    target: MediaView,
  ): Promise<MediaView[] | undefined> {
    return this.apiService?.getCarouselMedias(target);
  }
}

const lang = new LocalizedStrings({
  en: {
    downloadFail: 'Can\'t download media {media}',
  },
});
