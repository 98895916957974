import React from 'react';
import LocalizedStrings from 'react-localization';
import { MediaType } from '@src/types/Filters';

interface Props {
  type: MediaType
}

export function MediaTypeItem({ type }: Props) {
  return (
    <>
      {lang[type] || null}
    </>
  );
}

const lang = new LocalizedStrings({
  en: {
    video: 'Video',
    image: 'Photography',
    text: 'Text',
  },
});
