import React, { PropsWithChildren } from 'react';
import { ModalContainer } from '@src/components/ModalContainer';

import { style } from 'typestyle';

export function Container({ children }: PropsWithChildren<{}>) {
  return (
    <ModalContainer className={containerClass}>
      {children}
    </ModalContainer>
  );
}

const containerClass = style({
  minWidth: 130,
  overflow: 'hidden',
  margin: 'auto',
  borderRadius: 10,
});
