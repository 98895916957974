import React from 'react';
import { SearchSuggestion, SourceSearchSuggestion, MediaType } from '@src/types/Filters';
import { LabelPill } from '@src/components/LabelPill';
import LocalizedStrings from 'react-localization';
import { PerfectScrollbar } from '@src/components/PerfectScrollbar';
import { style } from 'typestyle';
import { CollectionItem } from '@src/fragments/Filters/Search/FilterItem/CollectionItem';
import { RightRequestMQLStatus } from '@adalong/schemas';
import { RightRequestStatusItem } from '@src/fragments/Filters/Search/FilterItem/RightRequestStatusItem';
import { DateItem } from '../Search/FilterItem/DateItem';
import { MediaTypeItem } from '../Search/FilterItem/MediaTypeItem';
import { LanguageItem } from '../Search/FilterItem/LanguageItem';
import { SourceItem } from '../Search/FilterItem/SourceItem';
import { CreatorItem } from '../Search/FilterItem/CreatorItem';
import { FilterNote } from '../FilterNote';
import { ProductItem } from '../Search/FilterItem/ProductItem';
import { useRecoilValue } from 'recoil';
import {
  collectionExcludeState,
  creatorExcludeState,
  languageExcludeState,
  ownContentExcludeState,
  productExactExcludeState,
  productSimilarExcludeState,
  creatorCountriesExcludeState,
} from '@src/pages/Discover/components/Selectors/Selectors';

interface Props {
  selected: {
    date: () => string | undefined;
    source: () => SearchSuggestion<SourceSearchSuggestion>[];
    lang: () => SearchSuggestion[];
    creators: () => SearchSuggestion[];
    productsExact: () => SearchSuggestion[];
    productsSimilar: () => SearchSuggestion[];
    mediaType: () => MediaType[];
    rightRequestStatus: () => RightRequestMQLStatus[];
    collection: () => SearchSuggestion[];
    ownContent: () => SearchSuggestion<SourceSearchSuggestion>[];
    creatorCountries: () => SearchSuggestion[];
  };
  onRemove: {
    date: () => void;
    sources: (key: string) => void;
    languages: (key: string) => void;
    creators: (key: string) => void;
    productsExact: (key: string) => void;
    productsSimilar: (key: string) => void;
    mediaType: (mediaType: MediaType) => void;
    rightRequestStatus: (rightRequestStatus: RightRequestMQLStatus) => void;
    collection: (key: string) => void;
    ownContent: (key: string) => void;
    creatorCountries: (key: string) => void;
  };
  empty: boolean;
}

export function FilterSelections({ selected, onRemove, empty }: Props) {
  const date = selected.date();
  const languageInclusion = !useRecoilValue(languageExcludeState);
  const creatorInclusion = !useRecoilValue(creatorExcludeState);
  const collectionInclusion = !useRecoilValue(collectionExcludeState);
  const productExactInclusion = !useRecoilValue(productExactExcludeState);
  const productSimilarInclusion = !useRecoilValue(productSimilarExcludeState);
  const ownContentInclusion = !useRecoilValue(ownContentExcludeState);
  const creatorCountriesInclusion = !useRecoilValue(creatorCountriesExcludeState);

  return (
    <PerfectScrollbar
      options={{
        wheelSpeed: 0.6,
      }}
      className={selectionClass}
    >
      {empty && (
        <FilterNote>
          <i>{lang.empty}</i>
        </FilterNote>
      )}
      {date && (
        <LabelPill onRemove={() => onRemove.date()}>
          <DateItem date={date} />
        </LabelPill>
      )}
      {selected.rightRequestStatus().map((rightRequestStatus: RightRequestMQLStatus) => (
        <LabelPill key={rightRequestStatus} onRemove={() => onRemove.rightRequestStatus(rightRequestStatus)}>
          <RightRequestStatusItem rrStatus={rightRequestStatus} />
        </LabelPill>
      ))}
      {selected.mediaType().map((mediaType) => (
        <LabelPill key={mediaType} onRemove={() => onRemove.mediaType(mediaType)}>
          <MediaTypeItem type={mediaType} />
        </LabelPill>
      ))}
      {selected.lang().map((language) => (
        <LabelPill variant={languageInclusion ? 'positive' : 'negative'} onRemove={() => onRemove.languages(language.key)} key={language.key}>
          <LanguageItem suggestion={language} />
        </LabelPill>
      ))}
      {selected.source().map((source) => (
        <LabelPill onRemove={() => onRemove.sources(source.key)} key={source.key}>
          <SourceItem suggestion={source} />
        </LabelPill>
      ))}
      {selected.creators().map((creator) => (
        <LabelPill variant={creatorInclusion ? 'positive' : 'negative'} onRemove={() => onRemove.creators(creator.key)} key={creator.key}>
          <CreatorItem suggestion={creator} />
        </LabelPill>
      ))}
      {selected.productsExact().map((product) => (
        <LabelPill variant={productExactInclusion ? 'positive' : 'negative'} onRemove={() => onRemove.productsExact(product.key)} key={product.key}>
          <ProductItem suggestion={product} />
        </LabelPill>
      ))}
      {selected.productsSimilar().map((product) => (
        <LabelPill
          variant={productSimilarInclusion ? 'positive' : 'negative'}
          onRemove={() => onRemove.productsSimilar(product.key)}
          key={product.key}
        >
          <ProductItem suggestion={product} />
        </LabelPill>
      ))}
      {selected.ownContent().map((ownContent) => (
        <LabelPill variant={ownContentInclusion ? 'positive' : 'negative'} onRemove={() => onRemove.ownContent(ownContent.key)} key={ownContent.key}>
          <SourceItem suggestion={ownContent} />
        </LabelPill>
      ))}
      {selected.collection().map((collection) => (
        <LabelPill
          variant={collectionInclusion ? 'positive' : 'negative'}
          onRemove={() => onRemove.collection(collection.key)}
          key={collection.key}
        >
          <CollectionItem suggestion={collection} />
        </LabelPill>
      ))}
      {selected.creatorCountries().map((country) => (
        <LabelPill
          variant={creatorCountriesInclusion ? 'positive' : 'negative'}
          onRemove={() => onRemove.creatorCountries(country.key)}
          key={country.key}
        >
          <span>{country.key}</span>
        </LabelPill>
      ))}
    </PerfectScrollbar>
  );
}

const lang = new LocalizedStrings({
  en: {
    empty: 'No filters applied',
  },
});

const selectionClass = style({
  maxHeight: 145,
});
