import React from 'react';

const DEFAULT_SIZE = 15;

interface Props {
  size?: number
}

export function FilledCheckBoxSvgIcon({
  size,
}: Props) {
  return (
    <SvgContainer size={size}>
      <g id="Wireframes-V3" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Discover_-product-linked" transform="translate(-43.000000, -914.000000)">
          <g id="Group-27" transform="translate(-6.000000, 36.000000)">
            <g id="Group-26">
              <g id="Group-25">
                <g id="Box-filled" transform="translate(49.500000, 878.212501)">
                  <g id="Group">
                    <polyline id="Path-14-Copy-26" stroke="#F6F6F6" points="4 8.4460242 6.1051129 10.5511371 11.65625 5" />
                    <rect id="Rectangle-9-Copy-89" stroke="#DDDDDD" x="0.5" y="0.5" width={15} height={15} />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

export function FilledRedCheckBoxSvgIcon({
  size,
}: Props) {
  return (
    <SvgContainer size={size}>
      <g xmlns="http://www.w3.org/2000/svg" stroke="#BF4341" style={{ transform: 'translateX(0.5px)' }}>
        <path d="M0.5 0.5H15.5V15.5H0.5z" />
        <g>
          <path d="M6.541 7.25L1.146.75M.973 7.25L7.129.75" transform="translate(4 4)" />
        </g>
      </g>
    </SvgContainer>
  );
}

export function NonFilledRedCheckBoxSvgIcon({
  size,
}: Props) {
  return (
    <SvgContainer size={size}>
      <g xmlns="http://www.w3.org/2000/svg" stroke="#BF4341" style={{ transform: 'translateX(0.5px)' }}>
        <path d="M0.5 0.5H15.5V15.5H0.5z" />
      </g>
    </SvgContainer>
  );
}

export function NonFilledCheckBoxSvgIcon({
  size,
}: Props) {
  return (
    <SvgContainer size={size}>
      <g id="Wireframes-V3" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g id="Discover_-product-linked" transform="translate(-43.000000, -881.000000)" stroke="#565656">
          <g id="Group-27" transform="translate(-6.000000, 36.000000)">
            <g id="Group-26">
              <g id="Group-25">
                <g id="Box-_-filled-/-unfilled-" transform="translate(49.500000, 845.036339)">
                  <g id="Group">
                    <rect id="Rectangle-9-Copy-89" x="0.5" y="0.5" width={15} height={15} />
                  </g>
                </g>
              </g>
            </g>
          </g>
        </g>
      </g>
    </SvgContainer>
  );
}

function SvgContainer({
  children,
  size = DEFAULT_SIZE,
}: React.PropsWithChildren<{
  size?: number
}>) {
  return (
    <svg width={size} height={size} viewBox="0 0 17 17" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
      {children}
    </svg>
  );
}
