import React from 'react';
import { useLocationQS } from '@src/utils/useLocationQS';
import { Browsing, QsNames } from '@src/types/Browsing';
import { API_URL } from '@src/config';
import { DownloadHelper } from '@src/services/helpers/download';
import {
  getDownloadableMedia,
  getMediaWarnings,
  isMediaDownlodableByType,
} from '@src/services/Media/download';
import { useQueryMediaFind } from '@src/queries/media/useQueryMediaFind';
import { closeMediaDownload } from '../utils/mediaRoute';
import { MediaDownload } from './Download';

// This limit is fixed by web-app
const FETCH_MEDIA_LIMIT = 100;

interface Props {
  browsingService: Browsing;
}

export function MediaDownloadController(props: Props) {
  const { download: mediaIds } = useLocationQS([QsNames.mediaDownload]);

  // get media info to deduce warnings
  const { data: res, isLoading } = useQueryMediaFind({
    limit: FETCH_MEDIA_LIMIT,
    filter: { ids: mediaIds },
  });
  const responseMedias = res?.pages.flatMap((r) => r.media) || [];
  // Filter invalid types (e.g. text)
  const medias = responseMedias.filter(isMediaDownlodableByType);

  const mediaWarnings = getMediaWarnings(medias);
  const downloadableMedia = getDownloadableMedia(mediaWarnings);

  const availabilityWarning = mediaWarnings.find(({ warning }) => warning.available !== 'NA' && warning.available !== 'VCR');
  const rightWarning = mediaWarnings.find(({ warning }) => warning.rights === true);

  return (
    <MediaDownload
      displayWarning={{
        // MediaDownload needs to have the content of the message. We forced "NA" if we did not got any return value.
        availability: availabilityWarning?.warning?.available || 'NA',
        rights: rightWarning?.warning?.rights || false,
      }}
      isLoading={isLoading}
      downloadable={downloadableMedia.length}
      total={medias.length}
      handleDownload={handleDownload}
      handleClose={handleClose}
    />
  );

  async function handleDownload() {
    const downloadableIds = downloadableMedia.map((media) => media._id);
    // create the download url
    const url = DownloadHelper.getMediaDownloadUrl(downloadableIds, API_URL);
    DownloadHelper.triggerDownload(url);
  }

  function handleClose() {
    props.browsingService.goToPage(closeMediaDownload(window.location));
  }
}
