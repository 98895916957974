import React, { Component } from 'react';

import { ShareCollectionContentOverlay } from 'components/ShareCollectionContentOverlay';
import { IBrandContent } from 'common';
import DeleteIcon from '@mui/icons-material/Delete';
import PlayArrowRoundedIcon from '@mui/icons-material/PlayArrowRounded';

import Draggable from 'components/Draggable';
import { ShareInfo } from '@src/types/redux/share';
import { DownloadHelper } from '@src/services/helpers/download';
import { saveAs } from 'file-saver';
import styles from './styles.scss';

interface IProps {
  content: IBrandContent
  onShare: (info: ShareInfo) => any
  onDelete: () => void
}

export class BrandContent extends Component <IProps> {
  private downloading: boolean = false;

  public render() {
    return (
      <Draggable
        className="thumbnail"
        style={{ backgroundImage: `url("${this.props.content.urls.thumbnail}")` }}
        info={{
          origin: 'share',
          type: 'brandcontent',
          content: this.props.content,
        }}
      >
        {this.props.content.type === 'video'
          && (
          <div className="play-button">
            <PlayArrowRoundedIcon
              style={{ fontSize: '5rem', margin: 'auto' }}
            />
          </div>
          )
        }
        <ShareCollectionContentOverlay
          onShare={this.onShare.bind(this)}
          onDownload={() => this.download(this.props.content)}
          isBrandContent={true}
        >
          <DeleteIcon className={styles.delete} onClick={this.props.onDelete} />
        </ShareCollectionContentOverlay>
      </Draggable>
    );
  }

  public onShare() {
    this.props.onShare({
      image: this.props.content.urls.thumbnail,
      file: this.props.content.urls.file,
      fileType: this.props.content.type,
      _id: this.props.content._id, //media.id
    });
  }

  public async download(content: IBrandContent) {
    if (this.downloading) {
      return;
    }
    this.downloading = true;
    const fileUrl = toBucketDomain(content.urls.file);
    const file = await DownloadHelper.downloadBrandContentMedia(fileUrl, content._id);

    if (file) {
      DownloadHelper.triggerDownload(file.fileUrl);
    }
  }
}

// transform a URL like storage.googleapis.com/my-bucket to my-bucket.storage.googleapis.com
// Otherwise, CORS headers won't be present in the response
function toBucketDomain(gcsUrl: string): string {
  if (!gcsUrl.startsWith('https://storage.googleapis.com')) {
    return gcsUrl;
  }

  return gcsUrl.replace(
    /^https:\/\/storage.googleapis.com\/([^/]+)/,
    'https://$1.storage.googleapis.com',
  );
}
