import React, { PropsWithChildren } from 'react';

import { style } from 'typestyle';

export function PageTitle({
  children,
}: PropsWithChildren<{}>) {
  return (
    <h1 className={titleClass}>
      {children}
    </h1>
  );
}

const titleClass = style({
  fontWeight: 700,
  letterSpacing: '1.5px',
});
