import React from 'react';
import { classes, style } from 'typestyle';
import { styled } from '@mui/system';
import LinearProgress from '@mui/material/LinearProgress';

interface Props {
  classes?: string[];
  display?: boolean;
  height?: number;
}

export function LinearLoader(props: Props) {
  const {
    classes: classNames = [],
    display = true,
    height = 3,
  } = props;

  const ColorLinearProgress = styled(LinearProgress)(({ theme }) => ({
    height: height,
    '&.MuiLinearProgress-colorPrimary': {
      backgroundColor: '#3b393e',
    },
    '.MuiLinearProgress-barColorPrimary': {
      backgroundColor: '#b9b9b9',
    },
  }));

  return (
    <div className={classes(linearLoaderClass, ...classNames)} data-hide={!display}>
      <ColorLinearProgress />
    </div>
  );
}

const linearLoaderClass = style({
  $nest: {
    '&[data-hide="true"]': {
      visibility: 'hidden',
    },
  },
});
