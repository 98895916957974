import React from 'react';
import { style, classes } from 'typestyle';
import { ThemeStyle, ADALONG_COLORS } from '@src/styles';
import Tooltip from '@mui/material/Tooltip';
import { TOOLTIP_DELAY } from '@src/config';
import { Row } from '../Utils';

const INFO_LINE_HEIGHT = 17;

interface Props {
  thumbnail: JSX.Element
  thumbnailWidth: number
  height: number
  data: ProductCardData
  classNames?: string
  onClick?: () => void
}

export interface ProductCardData {
  description: JSX.Element | string
  price: string
  link?: string | null
  title: {
    text: string
    tooltip: string
  }
}

export function ProductCard({
  thumbnail,
  thumbnailWidth,
  data: {
    description,
    price,
    link,
    title,
  },
  height,
  classNames,
  onClick,
}: Props) {
  return (
    <Row
      className={classes(productCardClass, classNames, {
        [productCardClickableClass]: !!onClick,
      })}
      style={{ height }}
      onClick={onClick && handleClick}
    >
      <div
        className={productImageClass}
        style={{
          height,
          width: thumbnailWidth, // Square
        }}
        onClick={(e) => e.stopPropagation()}
      >
        {thumbnail}
      </div>
      <div className={productDetailsClass}>
        <Tooltip
          enterDelay={TOOLTIP_DELAY}
          title={title.tooltip}
          placement="right-start"
        >
          <a
            onClick={(e) => e.stopPropagation()}
            className={productTitleClass}
            href={link || undefined}
            target="_blank"
            rel="noreferrer"
          >
            {title.text}
          </a>
        </Tooltip>
        <div className={productInfoClass}>
          <Tooltip
            enterDelay={TOOLTIP_DELAY}
            title={description}
            placement="right-start"
          >
            <div className={productInfoLineClass}>
              {description}
            </div>
          </Tooltip>
          <div className={productInfoLineClass}>
            {price}
          </div>
        </div>
      </div>
    </Row>
  );

  function handleClick(e: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    e.stopPropagation();
    onClick?.();
  }
}

const productCardClass = style({
  textAlign: 'left',
  paddingRight: 10,
  borderRight: '2px solid transparent',
});

const productCardClickableClass = style({
  cursor: 'pointer',
});

const productImageClass = style({
  position: 'relative',
  flexShrink: 0,
  $nest: {
  },
  cursor: 'pointer',
});

const productDetailsClass = style({
  paddingLeft: 15,
  overflow: 'hidden',
  position: 'relative',
});

const productInfoClass = style({
  color: ADALONG_COLORS.LIGHT_GRAY,
});

const productInfoLineClass = style({
  overflow: 'hidden',
  fontSize: ThemeStyle.textSizes.small,
  maxHeight: INFO_LINE_HEIGHT * 2,
});

const productTitleClass = style({
  overflow: 'hidden',
  display: 'block',
  fontSize: ThemeStyle.textSizes.large,
  fontWeight: 200,
  flexShrink: 0,
  color: ADALONG_COLORS.LIGHT_WHITE,
  $nest: {
    '&[href=""], &:not([href])': {
      pointerEvents: 'none',
    },
  },
});
