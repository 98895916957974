import React from 'react';
import { DropDownIcon } from './DropDownIcon';

export function ContentFiltersIcon() {
  return (
    <DropDownIcon
      childrenRender={() => (
        <>
          <rect width='50' height='50' rx='10' fill='#D9D9D9' />
          <path
            d='M39.8892 11.3154C39.7062 10.9478 39.3349 10.7147 38.9218 10.7147H11.7921C10.9306 10.6889 10.3828 11.7737 10.9397 12.434L12.5138 14.4483C13.4015 14.4509 17.019 14.447 17.9786 14.4483C19.0127 14.4677 19.0075 15.9837 17.9786 16.0018C17.0739 16.0031 14.6043 16.0005 13.727 16.0018L15.4475 18.1974C16.3352 18.1987 19.9541 18.1974 20.9124 18.1974C21.3464 18.1974 21.6968 18.5496 21.6968 18.9742C21.6968 19.404 21.3464 19.751 20.9124 19.751H16.6608L18.3813 21.9517H23.8515C24.8791 21.9699 24.883 23.4832 23.8515 23.5052H19.5946L22.4499 27.1561V38.9355C22.4211 39.8119 23.565 40.3453 24.2279 39.7433L27.8887 36.6415C28.1241 36.4395 28.2653 36.1444 28.2653 35.8337L28.2705 27.1394L39.7741 12.4343C40.0251 12.108 40.0722 11.6779 39.8892 11.3154Z'
            fill='#343434'
          />
        </>
      )}
    />
  );
}
