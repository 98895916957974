import React, { Component } from 'react';
import { connect } from 'react-redux';

import Draggable from 'components/Draggable';
import './styles.scss';
import { MediaView } from 'common';
import { ICombinedReducers } from '@src/types/redux';
import { CollectionContentOverlay } from '../CollectionContentOverlay';

interface IProps {
  media: MediaView
}

class CollectionListContent extends Component<IProps, { url?: string }> {
  public constructor(props: IProps) {
    super(props);

    this.state = {
      url: this.props.media.cdn_image,
    };
  }

  public render() {
    return (
      <Draggable
        id={this.props.media._id}
        className="dndVideoContent"
        style={{ backgroundImage: `url("${this.state.url}")`, backgroundSize: 'cover', backgroundPosition: 'center' }}
        info={{
          origin: 'share',
          type: 'media',
          content: this.props.media,
          image: this.state.url,
        }}
      >
        <CollectionContentOverlay
          iconToApply=""
          classToApply=""
        />
      </Draggable>
    );
  }
}

export default connect((state: ICombinedReducers) => ({
  config: state.common.config,
}))(CollectionListContent);
