import React, { PropsWithChildren } from 'react';

interface Props {
  hide: boolean
}

/**
 * Hide or display children
 * Default to hide
 */
export function Hide({
  hide = false,
  children,
}: PropsWithChildren<Props>) {
  if (hide) {
    return null;
  }
  return (
    <>
      {children}
    </>
  );
}
